import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import { updateSetting } from "../api/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "25ch",
  },
}));

function NotificationSettings(props) {
  const classes = useStyles();

  const settings = props.setting.settings.items;
  const isAdmin = !props.user.details.isSuperUser;

  //Create Var for each setting
  let notificationPropertyOutage, notificationSendFrom;

  //Loop through settings and set state
  for (let setting of settings) {
    if (setting.name == "notificationSendFrom") {
      notificationSendFrom = setting;
    }
    if (setting.name == "notificationPropertyOutage") {
      notificationPropertyOutage = setting;
    }
  }

  const [state, setState] = useState({
    notificationPropertyOutage: notificationPropertyOutage,
    notificationSendFrom: notificationSendFrom,
  });

  //Dynamic change event to handle all inputs
  const handleChange = (evt) => {
    let value = evt.target.value;
    if (evt.target.type == "checkbox") value = evt.target.checked;

    const newState = {
      ...state,
      [evt.target.name]: {
        ...state[evt.target.name],
        value: value,
      },
    };
    setState(newState);
  };

  const handleUpdate = async (evt) => {
    try {
      let response = await props.updateSetting(evt.target.id, {
        value: state[evt.target.name].value,
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      {props.setting.isLoading ? (
        <CircularProgress />
      ) : (
        <Button
          disabled={props.setting.isLoading || isAdmin}
          size="small"
          variant="contained"
          color="secondary"
        >
          Save
        </Button>
      )}
      <div className={classes.root}>
        {props.setting.settings.items.length > 0 && (
          <div>
            <h3>
              Notifications for BILLS, DISCONNECTS, and PAYMENTS can not be
              turned off.
            </h3>
            <div>
              <FormControlLabel
                control={
                  <Switch
                    name="notificationPropertyOutage"
                    disabled={props.setting.isLoading || isAdmin}
                    onChange={handleChange}
                    id={state.notificationPropertyOutage.uuid}
                    onBlur={handleUpdate}
                    checked={
                      state.notificationPropertyOutage.value == 0 ? false : true
                    }
                  />
                }
                label="Property Outage email"
              />
              <FormHelperText>
                When an outage is sent to a property, each active account will
                recieve an email.
              </FormHelperText>
            </div>
            <TextField
              id={`${state.notificationSendFrom.uuid}`}
              label="Send From Email"
              disabled={props.setting.isLoading || isAdmin}
              style={{ margin: 8 }}
              helperText={`Last Updated: ${moment(
                state.notificationSendFrom.updated
              ).format("L LT")}`}
              placeholder="Send From"
              fullWidth
              name="notificationSendFrom"
              onChange={handleChange}
              onBlur={handleUpdate}
              value={state.notificationSendFrom.value}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <FormHelperText>
              This is the email that any recieptient of an email in our system
              will show "FROM"
            </FormHelperText>
          </div>
        )}
      </div>
    </div>
  );
}
NotificationSettings.propTypes = {
  // Store objects:
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  billing: PropTypes.object.isRequired,
  // Store object functions:
  getAllBillingDashboardInfo: PropTypes.func.isRequired,
  getBillInProgressDraft: PropTypes.func.isRequired,
  updateBillingWizardBillInProgress: PropTypes.func.isRequired,
  updateBillingWizardSelectedProperty: PropTypes.func.isRequired,
  resetBillingObject: PropTypes.func.isRequired,
  getBillingBatchById: PropTypes.func.isRequired,
};

// Component State
function NotificationSettingsState(state) {
  return {
    setting: state.setting,
    user: state.user,
  };
}

export default connect(NotificationSettingsState, { updateSetting })(
  NotificationSettings
);
