import {
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  CircularProgress as MuiCircularProgress,
  Divider as MuiDivider,
  Paper as MuiPaper,
} from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
//Material UI Components and Functions
import { makeStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
// Redux Components
import PropTypes from "prop-types";
import React from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import {
  EditableTable,
  newOnFieldChangeMethod,
} from "../../../components/EditableTable";
import { getAccountBalance, getAccountLedger } from "../../account/api/actions";
import StatusTag from "../../apl/components/StatusTag";
import {
  createNewAccountPayment,
  getUserPayments,
  pushUpdateToCurrentlySelectedAccountPaymentsList,
  setPayments,
  updateLocalBulkCheckAccountsItemsState,
} from "../api/actions";

const Button = styled(MuiButton)(spacing);

const DashboardToolBarButton = styled(Button)`
  padding: 4px;
  min-width: 0;
  svg {
    width: 2em;
    height: 2em;
  }
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CircularProgress = styled(MuiCircularProgress)(spacing);

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  percentDif: {
    textAlign: "right",
    color: "green",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  loadingIcon: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Spacer = styled.div`
  flex: 1 1 100%;
`;

//Editable Table Object Expects: {objectArray, fieldTitles(Array), fieldTypes(Array), fieldNames(Array), handleRowClickParentMethod}
// Method can take line items if they exist initially or starts as an empty array of nothing passed
function BulkCheckEntryTable(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const navTo = (page) => history.push(page);

  const PaymentRowStatusStyling = (account) => {
    // Horidly inefficiant but theres no better way to access this in the context i can think of
    if(!account.isOwner){
    account.name = account.lastName;
    if (account.firstName != null) {
      account.name = account.firstName + ", " + account.lastName;
    }
   } else {
    account.accountNum = account.glCode
   }

    account.accountStatus = (
      <>
        <StatusTag
          type={"accountStatus"}
          isLoading={props.accountsSearch.isLoading}
          typeId={account.accountStatusId}
          showTitle={false}
          condensed={true}
        />
        <StatusTag
          type={"customerType"}
          isLoading={props.accountsSearch.isLoading}
          typeId={account.customerTypeId}
          showTitle={false}
          condensed={true}
        />
      </>
    );
    if (props.isBulkCheckEntry) {
      if (account.stopPayment) account.StoppedStatus = "STOPPED PAYMENT";
    }
    return {};
  };

  const updateLocalItemsValues = (items) => {
    props.updateLocalBulkCheckAccountsItemsState(items);
  };

  const updateSingleLocalItemsValues = (item) => {
    if (props.payments.bulkCheckSelectedAccounts.items) {
      let index = props.payments.bulkCheckSelectedAccounts.items.findIndex(
        (currentItem) => currentItem.id == item.id
      );
      if (index != -1) {
        props.payments.bulkCheckSelectedAccounts.items[index] = item;
        updateLocalItemsValues(props.payments.bulkCheckSelectedAccounts);
      }
    }
    return item;
  };

  // To be used to save over the api in the database
  const saveAllLineItems = (payments) => { };

  const fieldTitles = [
    "Acct #",
    "Status",
    "Name",
    "Address",
    "Balance",
    "Amount Covered",
  ];
  const fieldTypes = [
    "label",
    "label",
    "label",
    ["labelSmall", "labelSmall"],
    "moneyLabel",
    "number",
  ];
  const fieldNames = [
    "accountNum",
    "accountStatus",
    "name",
    ["serviceAddress1", "serviceAddress2"],
    "balance",
    "amountCovered",
  ];

  const onPaymentSelected = (item) => {
    //window.open("/accountmanager/" + item.accountNum);
  };

  const onChangeFields = [
    newOnFieldChangeMethod("amountCovered", updateSingleLocalItemsValues),
  ];

  return (
    <>
      <EditableTable
        readOnly={false}
        canAddItems={false}
        canSave={false}
        saveCurrentTableData={saveAllLineItems}
        updateItemsLocalState={updateLocalItemsValues}
        isLoading={props.payments.newBulkCheckIsLoading}
        conditionalStylingMethod={PaymentRowStatusStyling}
        // Functional methods of table
        handleRowClickParentMethod={onPaymentSelected}
        // Properties of table
        tableTitle={"Selected Accounts"}
        objectArray={props.payments.bulkCheckSelectedAccounts}
        objectError={props.payments.error}
        fieldTitles={fieldTitles}
        fieldTypes={fieldTypes}
        customOnChangeFields={onChangeFields}
        fieldNames={fieldNames}
      />
    </>
  );
}
// Component Properties
BulkCheckEntryTable.propTypes = {
  // Store objects:
  payments: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  accountsSearch: PropTypes.object.isRequired,
  setPayments: PropTypes.func.isRequired,
  pushUpdateToCurrentlySelectedAccountPaymentsList: PropTypes.func.isRequired,
  getAccountBalance: PropTypes.func.isRequired,
  createNewAccountPayment: PropTypes.func.isRequired,
  getUserPayments: PropTypes.func.isRequired,
  getAccountLedger: PropTypes.func.isRequired,
  updateLocalBulkCheckAccountsItemsState: PropTypes.func.isRequired,
};

// Component State
function BulkCheckEntryTableState(state) {
  return {
    account: state.account,
    apl: state.apl,
    payments: state.payments,
    accountsSearch: state.accountsSearch,
  };
}
export default connect(BulkCheckEntryTableState, {
  setPayments,
  pushUpdateToCurrentlySelectedAccountPaymentsList,
  getAccountBalance,
  createNewAccountPayment,
  getUserPayments,
  getAccountLedger,
  updateLocalBulkCheckAccountsItemsState,
})(BulkCheckEntryTable);
