import {
  Button as MuiButton,
  Card,
  CardContent,
  Checkbox,
  Divider as MuiDivider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import TableContainer from "@material-ui/core/TableContainer";
import EditIcon from "@material-ui/icons/Edit";
import Skeleton from "@material-ui/lab/Skeleton";
import { spacing } from "@material-ui/system";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import style from "../../property/components/styling/style";
import PropertyUtilityForm from "./Form";

const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function UtilityDetailCard(props) {
  const useStyles = style;
  const classes = useStyles();

  const canAddOrUpdate = (props.user.details.billing || props.user.details.administration || props.user.details.isSuperUser) ? true : false;


  const [display, setDisplay] = React.useState(false);

  const toggleDisplay = () => {
    const shouldDisplay = !display;
    setDisplay(shouldDisplay);
  };

  function formatCurrency(s) {
    if(s)
      return "$" + Number(s).toFixed(2).toString();
    else
      return 0;
  }

  return (
    <Card mb={6}>
      <CardContent xs={12}>
        <Grid container xs={12}>
          <Grid item xs={9} sm={9} md={9} lg={9}>
            <Typography variant="h6" gutterBottom>
              Utilities
            </Typography>
          </Grid>
          <Grid
            item
            xs={3}
            sm={3}
            md={3}
            lg={3}
            className={classes.rightSideHeaderSubMenuToolBar}
          >
            <Button onClick={toggleDisplay}>
              <EditIcon
                hidden={!canAddOrUpdate}
                fontSize="medium"
                className={classes.SubMenuToolBarIcon}
              />
            </Button>
            <Dialog
              open={display}
              TransitionComponent={Transition}
              fullWidth={"lg"}
              maxWidth={"lg"}
              keepMounted
              onClose={toggleDisplay}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              {props.propertyUtilities.data.propertyUtility ? (
                <DialogTitle id="alert-dialog-slide-title">
                  Utilities {props.propertyUtilities.data.propertyUtility.name}{" "}
                </DialogTitle>
              ) : (
                <DialogTitle id="alert-dialog-slide-title">
                  Loading Utilities.....{" "}
                </DialogTitle>
              )}
              <DialogContent>
                {props.propertyUtilities.data.propertyUtility && (
                  <PropertyUtilityForm
                    onUpdate={toggleDisplay}
                    onCancel={toggleDisplay}
                    serviceOutage={
                      props.propertyUtilities.data.propertyUtility
                        .serviceOutage || 0
                    }
                    active={
                      props.propertyUtilities.data.propertyUtility.active || 0
                    }
                  />
                )}
              </DialogContent>
            </Dialog>
          </Grid>
        </Grid>
        <Divider className="mb-2 mt-2" my={1} />
        {!props.propertyUtilities.data.propertyUtility ? (
          <>
            <Skeleton
              variant="rect"
              height="40px"
              style={{
                backgroundColor: `#bdbfbe`,
                padding: 6,
                margin: 6,
                marginBottom: 3,
              }}
            />
            <Skeleton
              variant="rect"
              height="40px"
              style={{
                backgroundColor: `#bdbfbe`,
                padding: 6,
                margin: 6,
                marginBottom: 3,
              }}
            />
            <Skeleton
              variant="rect"
              height="40px"
              style={{
                backgroundColor: `#bdbfbe`,
                padding: 6,
                margin: 6,
                marginBottom: 3,
              }}
            />
            <Skeleton
              variant="rect"
              height="40px"
              style={{
                backgroundColor: `#bdbfbe`,
                padding: 6,
                margin: 6,
                marginBottom: 3,
              }}
            />
            <Skeleton
              variant="rect"
              height="40px"
              style={{
                backgroundColor: `#bdbfbe`,
                padding: 6,
                margin: 6,
                marginBottom: 3,
              }}
            />
            <Skeleton
              variant="rect"
              height="40px"
              style={{
                backgroundColor: `#bdbfbe`,
                padding: 6,
                margin: 6,
                marginBottom: 3,
              }}
            />
            <Skeleton
              variant="rect"
              height="40px"
              style={{
                backgroundColor: `#bdbfbe`,
                padding: 6,
                margin: 6,
                marginBottom: 3,
              }}
            />
            <Skeleton
              variant="rect"
              height="40px"
              style={{
                backgroundColor: `#bdbfbe`,
                padding: 6,
                margin: 6,
                marginBottom: 3,
              }}
            />
            <Skeleton
              variant="rect"
              height="40px"
              style={{
                backgroundColor: `#bdbfbe`,
                padding: 6,
                margin: 6,
                marginBottom: 3,
              }}
            />
            <Skeleton
              variant="rect"
              height="40px"
              style={{
                backgroundColor: `#bdbfbe`,
                padding: 6,
                margin: 6,
                marginBottom: 3,
              }}
            />
            <Skeleton
              variant="rect"
              height="40px"
              style={{
                backgroundColor: `#bdbfbe`,
                padding: 6,
                margin: 6,
                marginBottom: 3,
              }}
            />
            <Skeleton
              variant="rect"
              height="40px"
              style={{
                backgroundColor: `#bdbfbe`,
                padding: 6,
                margin: 6,
                marginBottom: 3,
              }}
            />
            <Skeleton
              variant="rect"
              height="40px"
              style={{
                backgroundColor: `#bdbfbe`,
                padding: 6,
                margin: 6,
                marginBottom: 3,
              }}
            />
            <Skeleton
              variant="rect"
              height="40px"
              style={{
                backgroundColor: `#bdbfbe`,
                padding: 6,
                margin: 6,
                marginBottom: 3,
              }}
            />
            <Skeleton
              variant="rect"
              height="40px"
              style={{
                backgroundColor: `#bdbfbe`,
                padding: 6,
                margin: 6,
                marginBottom: 3,
              }}
            />
          </>
        ) : (
          <Grid
            container
            spacing={2}
            lg={12}
            className={classes.PropertyInfoGrid}
          >
            <Grid item xs={12} lg={12} spacing={2}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1"> Active </Typography>
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          checked={
                            props.propertyUtilities.data.propertyUtility
                              .active == 1
                              ? true
                              : false
                          }
                          disabled={true}
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1"> $0.00 Balances </Typography>
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          checked={
                            props.propertyUtilities.data.propertyUtility
                              .zeroBalance === 1
                          }
                          disabled={true}
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1"> PM Balance Collection </Typography>
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          checked={
                            props.propertyUtilities.data.propertyUtility
                              .pMBalanceCollection === 1
                          }
                          disabled={true}
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1"> Customer Initiated Move Ins </Typography>
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          checked={
                            props.propertyUtilities.data.propertyUtility
                              .customerInitiatedMoveIns === 1
                          }
                          disabled={true}
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1"> Customer Initiated Move Outs </Typography>
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          checked={
                            props.propertyUtilities.data.propertyUtility
                              .customerInitiatedMoveOuts === 1
                          }
                          disabled={true}
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1"> Charge Owner Vacants </Typography>
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          checked={
                            props.propertyUtilities.data.propertyUtility
                              .chargeOwnerVacants === 1
                          }
                          disabled={true}
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1"> CCOS Pays Bill </Typography>
                      </TableCell>
                      <TableCell>
                        <Checkbox
                            checked={
                                props.propertyUtilities.data.propertyUtility.ccosPaysBill == 1
                            }
                            disabled={true}
                            inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1"> Vendor </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {props.propertyUtilities.data.propertyUtility
                            .vendorName || "N/A"}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1"> Vendor Account Number </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {props.propertyUtilities.data.propertyUtility
                            .vendorAcctNum || "N/A"}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1"> Billing Type </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {props.propertyUtilities.data.propertyUtility
                            .billingType}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1">
                          {" "}
                          Last Bill Date{" "}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {(props.propertyUtilities.data.propertyUtility.lastBillDate) ? new Date(
                            props.propertyUtilities.data.propertyUtility.lastBillDate
                          ).toLocaleDateString(): ""}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1">
                          {" "}
                          Service Fee{" "}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {formatCurrency(
                              props.propertyUtilities.data.propertyUtility
                                  .serviceFee
                          ) || "N/A"}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1">
                          {" "}
                          Credit Card Fee{" "}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {formatCurrency(
                            props.propertyUtilities.data.propertyUtility
                              .creditCardFee
                          ) || "N/A"}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1">
                          {" "}
                          Processing Fee{" "}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {formatCurrency(
                            props.propertyUtilities.data.propertyUtility
                              .processingFee
                          ) || "N/A"}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    {/*<TableRow>*/}
                    {/*  <TableCell component="th" scope="row">*/}
                    {/*    <Typography variant="body1">*/}
                    {/*      {" "}*/}
                    {/*      Utility Customer Charge{" "}*/}
                    {/*    </Typography>*/}
                    {/*  </TableCell>*/}
                    {/*  <TableCell>*/}
                    {/*    <Typography variant="body2">*/}
                    {/*      {formatCurrency(*/}
                    {/*        props.propertyUtilities.data.propertyUtility*/}
                    {/*          .utilCustomerCharge*/}
                    {/*      ) || "N/A"}*/}
                    {/*    </Typography>*/}
                    {/*  </TableCell>*/}
                    {/*</TableRow>*/}
                    {/*<TableRow>*/}
                    {/*  <TableCell component="th" scope="row">*/}
                    {/*    <Typography variant="body1"> EV Meter Rate </Typography>*/}
                    {/*  </TableCell>*/}
                    {/*  <TableCell>*/}
                    {/*    <Typography variant="body2">*/}
                    {/*      {formatCurrency(*/}
                    {/*        props.propertyUtilities.data.propertyUtility*/}
                    {/*          .EVMeterRate*/}
                    {/*      ) || "N/A"}*/}
                    {/*    </Typography>*/}
                    {/*  </TableCell>*/}
                    {/*</TableRow>*/}
                    {/*<TableRow>*/}
                    {/*  <TableCell component="th" scope="row">*/}
                    {/*    <Typography variant="body1"> Contract Rate </Typography>*/}
                    {/*  </TableCell>*/}
                    {/*  <TableCell>*/}
                    {/*    <Typography variant="body2">*/}
                    {/*      {formatCurrency(*/}
                    {/*        props.propertyUtilities.data.propertyUtility*/}
                    {/*          .contractRate*/}
                    {/*      ) || "N/A"}*/}
                    {/*    </Typography>*/}
                    {/*  </TableCell>*/}
                    {/*</TableRow>*/}
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1"> Estimated Usage </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {props.propertyUtilities.data.propertyUtility
                                  .estimatedUsage || 350} CF
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1"> Updated </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {new Date(
                            props.propertyUtilities.data.propertyUtility.updated
                          ).toLocaleDateString() || "N/A"}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
}


UtilityDetailCard.propTypes = {
  apl: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  propertyUtilities: PropTypes.object.isRequired,
};

function UtilityDetailCardState(state) {
  return {
    apl: state.apl,
    propertyUtilities: state.propertyUtilities,
    user: state.user
  };
}

export default connect(UtilityDetailCardState, {})(UtilityDetailCard);
