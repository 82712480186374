import {
    Button,
    Card as MuiCard,
    CardContent,
    Divider as MuiDivider,
    FormControl as MuiFormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField as MuiTextField,
    Typography,
    Checkbox
  } from "@material-ui/core";
  import Paper from "@material-ui/core/Paper";
  //Material UI Components and Functions
  import { makeStyles } from "@material-ui/core/styles";
  import Table from "@material-ui/core/Table";
  import TableBody from "@material-ui/core/TableBody";
  import TableCell from "@material-ui/core/TableCell";
  import TableContainer from "@material-ui/core/TableContainer";
  import TableRow from "@material-ui/core/TableRow";
  import MuiAlert from "@material-ui/lab/Alert";
  import { spacing } from "@material-ui/system";
  // Redux Components
  import PropTypes from "prop-types";
  import React, { useState, useEffect } from "react";
  
  import { connect, useDispatch } from "react-redux";
  import styled from "styled-components/macro";
  // Custom UI componets and functions:
  import { formatMoney } from "../../../setup/helpers";
  import { getAssociatedAutoPays, createNewAutoPay, setAutoPayments, updateAutoPayments,deleteAutoPay } from "../../payments/api/actions";

  import { EditableTable } from "../../../components/EditableTable";
  import validator from 'validator';


  
 
  import { setSnackbar } from "../../snackbar/api/snackbar";
  import moment from "moment";
  
  const CenteredContent = styled.div`
    text-align: center;
  `;
  
  const Card = styled(MuiCard)(spacing);
  const Divider = styled(MuiDivider)(spacing);
  const FormControl = styled(MuiFormControl)(spacing);
  const TextField = styled(MuiTextField)(spacing);
  
  const defaultCardFee = 3.25;
  const defaultProcessingFee = 1.00;
  
  // Custom Styles for property selection drop down
  
  const useStyles = makeStyles((theme) => ({
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    selectedPropertyLocationLabel: {
      fontSize: 50,
      backgroundColor: "#fffffff",
      borderColor: "#fffffff",
    },
    loadingIcon: {
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
    },
  }));
  
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  
  function getBasePaymentObject(userID, firstName = "N/A", lastName = "N/A") {
    const today = moment();
    // actual obj
    var baseObj = {
      paymentType: -1,
      paymentTypeDesc: null,
      paymentNote: `INTERNAL APPLICATION PAYMENT FROM USER: ${firstName}, ${lastName} (User ID: ${userID})`,
      paymentAmount: 0,
      
      bankName: "",
      bankAcctTypeId: 0,
      bankRoutingNum: "",
      bankAcctNum: "",
  
      firstName: "",
      lastName: "",
      nameOnCard: "",// also name on account
      ccName: "",
      ccNum: "",
      ccTypeId: "",
      ccExpMonth: "",
      ccExpYear: "",
      ccCvv: "",
      paymentDate: today,
     
      checkNumber: "",
      accountsCovered: [],
      billingAddress1: "",
      billingAddress2: "",
      billingCity: "",
      billingState: "",
      billingZip: "",
      glCode: ""
    }
    return baseObj;
  }
  const AutoPaymentForm = (props) => {
    // Handles vendor drop down
    const [paymentType, setPaymentType] = React.useState("");
    const [formType, setFormType] = React.useState("BANK");
    const [canPayEcheck, setCanPayEcheck] = React.useState(true);
    const [useAccountBillingAddress, setUseAccountBillingAddress] = React.useState(false);
    const [showBillingAddressToggle, setShowBillingAddressToggle] = React.useState(false);
    const [cardType, setCardType] = React.useState("");
    const [newPaymentFormObject, setNewPaymentFormObject] = React.useState({});
    const [paymentTypeDescription, setPaymentTypeDescription] = React.useState("");
    const [bankAcctNumberFormError, setbankAcctNumberFormError] = React.useState(false);
    const [bankAcctFormErrorText, setbankAcctFormErrorText] = React.useState(null);
    const [bankRoutingNumberFormError, setbankRoutingNumberFormError] = React.useState(false);
    const [bankRoutingFormErrorText, setbankRoutingFormErrorText] = React.useState(null);
    const [creditCardNumberFormError, setcreditCardNumberFormError] = React.useState(false);
    const [creditCardNumberFormErrorText, setcreditCardNumberFormErrorText] = React.useState(null);

  
    const [propUtilityIsLoaded, setPropUtilityIsLoaded] = React.useState(false);
    const [feeTotal, setFeeTotal] = React.useState(0);
    const [isOpen, setIsOpen] = React.useState(props.isOpen || false);
  
    const dispatch = useDispatch();
    const getPaymentTypeInfoFromSelectedId = (id) => {
      for (var x = 0; x < props.apl.genericTypes.paymentMethodType.data.length; x++) {
        if (props.apl.genericTypes.paymentMethodType.data[x].id == id) {
          return props.apl.genericTypes.paymentMethodType.data[x];
        }
      }
      return null;
    };
  
    const cardTypes = [
    {value: 4, label : "Visa"},
    {value: 3, label : "MasterCard"}
  ];
      

    const handleSelectedPaymentTypeChange = (event) => {
      var selectedType = getPaymentTypeInfoFromSelectedId(event.target.value);
      newPaymentFormObject.paymentType = event.target.value;
      if (selectedType != null) {
        setFormType(selectedType.type);
        setPaymentType(selectedType.id);
        setPaymentTypeDescription(selectedType.description);
      }
    };

    const handleSelectedCardTypeChange = (event) => {

      var selectedCardType = event.target.value;
      newPaymentFormObject.ccTypeId = event.target.value;
      setCardType(selectedCardType);
    
      };
  
  
    const EvaluateFormObject = () => {
      var newErrors = [];
      // Amount warnings
      if (newPaymentFormObject.paymentAmount <= 0) {
        newErrors.push("Payment Amount must be a positive value.");
      }
  
      // on error checking update the store and tell
      if (newErrors.length > 0) {
        props.updateNewAccountPaymentErrors(newErrors);
        return false;
      }
      props.updateNewAccountPaymentErrors(newErrors);
      return true;
    };
  
    useEffect(() => {
        if (props.user.details != null) {
        setNewPaymentFormObject(getBasePaymentObject(props.user.details.id, props.user.details.firstName, props.user.details.lastName));
      }
      if (props.account.data.addresses.length == 0) {
        setUseAccountBillingAddress(false);
        setShowBillingAddressToggle(false);
      }
      else {
        setShowBillingAddressToggle(true);
        setUseAccountBillingAddress(true);
      }
    }, [props.user.details, props.payments]);
  
    const classes = useStyles();
  
    const findPaymentTypeId = (type) => {
      for (var x = 0; x < props.apl.genericTypes.paymentMethodType.data.length; x++) {
        if (props.apl.genericTypes.paymentMethodType.data[x].description == type) {
          return props.apl.genericTypes.paymentMethodType.data[x].id;
        }
      }
      // defaults -1
      return -1;
    }
    const fieldTitles = [
      "ID",
      "Payment Type",
      "CC",
      "Bank Routing",
      "Bank Account",
      "Default",
      
    ];
    const fieldTypes = [
      "label",
      "label",
      "label",
      "label",
      "label",
     "switch"
    ];
    const fieldNames = [
      "id", 
      "description",
      "ccNum",
      "bankRoutingNum",
      "bankAcctNum",
      "isDefault"
    ];
  
    const submitAutoPaymentForm = () => {
     
      dispatch(setSnackbar(true, "warning", "Submitting Auto Pay"));
      let paymentObjectToSend = newPaymentFormObject;
      if (formType == "BANK") {
        paymentObjectToSend.nameOnCard = paymentObjectToSend.firstName + " " + paymentObjectToSend.lastName;
        paymentObjectToSend.bankAcctTypeId = 2;
      }
      var selectedType = getPaymentTypeInfoFromSelectedId(paymentType);
      paymentObjectToSend.paymentType = paymentType;
      paymentObjectToSend.paymentTypeDesc = selectedType.description;
      paymentObjectToSend.accountsCovered = [props.account.data.account];
      paymentObjectToSend.ccTypeId = cardType;
  
      if (useAccountBillingAddress) {
        paymentObjectToSend.billingAddress1 = props.account.data.addresses[0].address1;
        paymentObjectToSend.billingAddress2 = props.account.data.addresses[0].address2;
        paymentObjectToSend.billingCity = props.account.data.addresses[0].city;
        paymentObjectToSend.billingState = props.account.data.addresses[0].state;
        paymentObjectToSend.billingZip = props.account.data.addresses[0].zip;
      }
      const autoPaySubmissionPayload = {
        "payment": paymentObjectToSend,
        "accountId": props.account.data.account.uuid
      };

      props.createNewAutoPay(autoPaySubmissionPayload).then(() => {
        props.getAssociatedAutoPays(props.account.data.account.uuid)
      })

      
    };

    const updateLocalItemsValues = (item) => {
      props.setAutoPayments(item);
    }
  
    const cancelPaymentForm = () => {
      if (props.onCancel) {
        props.onCancel();
      }
    };
  const saveAllLineItems = (autoPayments) => {
    let _autoPayments = autoPayments.items;
    let autoPayToUpdate = []

    // check if _autopayments has more than one isDefault that equals true
    let isDefaultCount = 0;
    for (var x = 0; x < _autoPayments.length; x++) {
      if (_autoPayments[x].isDefault) {
        isDefaultCount++;
      }
    }
    if (isDefaultCount > 1) {
      dispatch(setSnackbar(true, "error", "You can only have one default autopayment."));
      return;
    }

    _autoPayments.map( (ap) => {
      let autoPay = {
            "uuid" : ap.uuid,
            "isDefault" : ap.isDefault,
          }
          autoPayToUpdate.push(autoPay);
    });
   
    props.updateAutoPayments(autoPayToUpdate).then(() => {
      if (props.payments.error == null || props.payments.error == "") {
        dispatch(setSnackbar(true, "success", "Auto payments updated successfully"));
      }
      else {
        dispatch(setSnackbar(true, "error", "Error Updating Auto Payments!"));
      }
    })
    
    
  }
    const handleSelectedAutoPayDeletion = (autoPaymentsToDelete) => {

      autoPaymentsToDelete.forEach(item => {
      dispatch(setSnackbar(true, "warning", "Deleting Auto Pay"));
        props.deleteAutoPay(item).then(() => {
          if (props.payments.error == null || props.payments.error == "") {
            dispatch(setSnackbar(true, "success", "Auto payments deleted successfully"));
          }
          else {
            dispatch(setSnackbar(true, "error", "Error Deleting Auto Payments!"));
          }
        })
    });
    

      
    }
  
    const handleBillingAddressToggle = (event) => {
      setUseAccountBillingAddress(!useAccountBillingAddress);
    };
  
    // Updates the local state object of "bill in progress" upon each modification
    const onFieldChange = (event) => {
        // Gets the current vendor info data and updates it with the event's name and value:
        if (event.target.type == "number") {

          let value = Number(event.target.value);
            if(event.target.name == "ccNum"){

              if(validator.isCreditCard(event.target.value)){

                setcreditCardNumberFormError(false)
                setcreditCardNumberFormErrorText(null)

              } else {

                setcreditCardNumberFormError(true)
                setcreditCardNumberFormErrorText("Must be a valid Credit Card")
                
              }

            }
            
            
            setNewPaymentFormObject({
              ...newPaymentFormObject,
              [event.target.name]: value
          });
        } else {

          if(event.target.name == "bankAcctNum"){

            if(event.target.value.match(/^(\d{1,17})$/)){
              setbankAcctNumberFormError(false);
              setbankAcctFormErrorText(null);
    
            }else{
              setbankAcctNumberFormError(true);
              setbankAcctFormErrorText("Bank Account Number must be valid");

            }
        }

          if(event.target.name == "bankRoutingNum"){
              
            if(event.target.value.match(/^\d{9}$/)){
              setbankRoutingNumberFormError(false);
              setbankRoutingFormErrorText(null);
      
            }else{
              setbankRoutingNumberFormError(true);
              setbankRoutingFormErrorText("Bank Routing Number must be 9 digits");

            }
          }
          setNewPaymentFormObject({
            ...newPaymentFormObject,
            [event.target.name]: event.target.value
        });
        }
    };
  

    if (props.account.isLoading) {
      return (
        <Card mb={6}>
          <CardContent>
            <Typography variant="h3" gutterBottom>
              Account Loading...
            </Typography>
          </CardContent>
        </Card>
      );
    }
  
    return (
      <Card mb={6} elevation={3}>
        <CardContent>
        <Grid container spacing={3}>
            <Grid item lg={4} md={6} sm={6}>
              <FormControl variant="outlined" fullWidth my={2}>
                <InputLabel id="paymentTypeDropDownLabel">
                  Payment Type
                </InputLabel>
                <Select
                  labelId="paymentTypeDropDownLabel"
                  id="paymentTypeDropDown"
                  value={paymentType}
                  onChange={handleSelectedPaymentTypeChange}
                  label="Payment Type"
                  name="paymentType"
                  fullWidth
                  my={2}
                >
                  {props.apl.genericTypes.paymentMethodType.data.length > 0 ? (
                    props.apl.genericTypes.paymentMethodType.data.map(
                      (item, key) => {
                        if (item.description == "Credit/Debit Card" ||
                        item.description == "eCheck"
                        ){
                          return (
                            <MenuItem value={item.id} id={key}>
                              {item.description}
                            </MenuItem>
                          );
                        }
                      }
                    )
                  ) : (
                    <MenuItem value={-1}>No Payment Method Types Found</MenuItem>
                  )}
                  
                </Select>
              </FormControl>
            </Grid>
            {formType == "BANK" && ( // Only show for ach type
              <>
              <Grid item lg={4} md={6} sm={6}>
              <TextField
                    fullWidth
                    type="text"
                    id="bankName"
                    variant="outlined"
                    label="Bank Name"
                    value={newPaymentFormObject.bankName}
                    onChange={onFieldChange}
                    name="bankName"
                  />
              </Grid>
                <Grid item lg={4} md={6} sm={6}>
                  <TextField
                    fullWidth
                    type="text"
                    id="bankRoutingNum"
                    variant="outlined"
                    label="Routing Number"
                    value={newPaymentFormObject.bankRoutingNum}
                    onChange={onFieldChange}
                    error={bankRoutingNumberFormError}
                    helperText={bankRoutingFormErrorText}
                    name="bankRoutingNum"
                  />
                </Grid>
                <Grid item lg={4} md={6} sm={6}>
                  <TextField
                    fullWidth
                    type="text"
                    id="firstName"
                    variant="outlined"
                    label="Account Holder First Name"
                    value={newPaymentFormObject.firstName}
                    onChange={onFieldChange}
                    name="firstName"
                  />
                </Grid>
                <Grid item lg={4} md={6} sm={6}>
                  <TextField
                    fullWidth
                    type="text"
                    id="lastName"
                    variant="outlined"
                    label="Account Holder Last Name"
                    value={newPaymentFormObject.lastName}
                    onChange={onFieldChange}
                    name="lastName"
                  />
                </Grid>
              </>
            )}
            {formType == "CARD" && ( // Only show for credit card type
              <Grid item lg={4} md={6} sm={6}>
              <FormControl variant="outlined" fullWidth my={2}>
                <InputLabel id="cardTypeDropDownLabel">
                  Card Type
                </InputLabel>
                <Select
                  labelId="cardTypeDropDownLabel"
                  id="cardTypeDropDown"
                  value={cardType}
                  onChange={handleSelectedCardTypeChange}
                  label="Payment Type"
                  name="cardTypeId"
                  fullWidth
                  my={2}
                >
                  {
                    cardTypes.map(
                      (cardType, key) => {
                          return (
                            <MenuItem
                             value={cardType.value}
                             id={key}
                             selected={cardType}>
                              {cardType.label}
                            </MenuItem>
                          );
                        }
                    )
                  }
                  
                </Select>
              </FormControl>
              </Grid>
            )}
            {formType == "BANK" && ( // Only show for ach type
              <Grid item lg={4} md={6} sm={6}>
                <TextField
                  fullWidth
                  type="text"
                  id="bankAcctNum"
                  variant="outlined"
                  label="Account Number"
                  // isValid={"^\w{1,17}$"}
                  value={newPaymentFormObject.bankAcctNum}
                  onChange={onFieldChange}
                  helperText={bankAcctFormErrorText}
                  error={bankAcctNumberFormError}
                  name="bankAcctNum"
                />
              </Grid>
            )}
            {formType == "CARD" && ( // Only show for credit card type
              <>
                <Grid item lg={4} md={6} sm={6}>
                  <TextField
                    my={2}
                    fullWidth
                    type="text"
                    id="nameOnCard"
                    variant="outlined"
                    label="Name On Card"
                    value={newPaymentFormObject.nameOnCard}
                    onChange={onFieldChange}
                    name="nameOnCard"
                  />
                </Grid>
  
                <Grid item lg={4} md={6} sm={6}>
                  <TextField
                    my={2}
                    fullWidth
                    type="text"
                    id="cardCvv"
                    variant="outlined"
                    label="CVV"
                    value={newPaymentFormObject.ccCvv}
                    onChange={onFieldChange}
                    name="ccCvv"
                  />
                </Grid>
              </>
            )}
    
            {formType == "CARD" && ( // Only show for credit card type
              <>
  
                <Grid item lg={4} md={6} sm={6}>
                  <TextField
                    my={2}
                    fullWidth
                    variant="outlined"
                    type="number"
                    id="ccNum"
                    label="Card Number"
                    value={newPaymentFormObject.ccNum}
                    error={creditCardNumberFormError}
                    helperText={creditCardNumberFormErrorText}
                    onChange={onFieldChange}
                    name="ccNum"
                  />
                </Grid>
                <Grid item lg={4} md={6} sm={6}>
                  <TextField
                    my={2}
                    fullWidth
                    type="number"
                    id="cardExpMonth"
                    label="Exp Month (MM)"
                    onChange={onFieldChange}
                    variant="outlined"
                    value={newPaymentFormObject.ccExpMonth}
                    name="ccExpMonth"
                  />
                </Grid>
                <Grid item lg={4} md={6} sm={6}>
                  <TextField
                    my={2}
                    fullWidth
                    type="number"
                    id="cardExpYear"
                    label="Exp Year (YYYY)"
                    onChange={onFieldChange}
                    variant="outlined"
                    value={newPaymentFormObject.ccExpYear}
                    name="ccExpYear"
                  />
                </Grid>
              </>
            )}
            {formType == "CHECK" && ( // Only show for credit card type
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <TextField
                  my={2}
                  fullWidth
                  type="text"
                  id="checkNumber"
                  label="Check Number"
                  value={newPaymentFormObject.checkNumber}
                  onChange={onFieldChange}
                  autoComplete={"off"}
                  //onBlur={() =>{ props.handleBillingProcessSubmission(props.billing.data.billInProgress, false); } }
                  variant="outlined"
                  name="checkNumber"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            )}
            
          </Grid>
          
          {!useAccountBillingAddress &&
            <>
              <Grid container spacing={4}>
                <Grid item lg={4} md={4} sm={6}>
                  <TextField
                    label="Billing Address"
                    type="text"
                    onChange={onFieldChange}
                    name="billingAddress1"
                    value={newPaymentFormObject.billingAddress1}
                    fullWidth
                    my={2}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6}>
                  <TextField
                    label="Billing Address (Apt)"
                    type="text"
                    onChange={onFieldChange}
                    name="billingAddress2"
                    value={newPaymentFormObject.billingAddress2}
                    fullWidth
                    my={2}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6}>
                  <TextField
                    label="Billing City"
                    type="text"
                    onChange={onFieldChange}
                    name="billingCity"
                    value={newPaymentFormObject.billingCity}
                    fullWidth
                    my={2}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6}>
                  <TextField
                    label="Billing State"
                    type="text"
                    onChange={onFieldChange}
                    name="billingState"
                    value={newPaymentFormObject.billingState}
                    fullWidth
                    my={2}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6}>
                  <TextField
                    label="Billing Zip"
                    type="text"
                    onChange={onFieldChange}
                    name="billingZip"
                    value={newPaymentFormObject.billingZip}
                    fullWidth
                    my={2}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
            </>
          }
          <Grid container spacing={4}>
            <Grid item xs={9} lg={9}>
              {props.payments.newPaymentSubmissionErrors.map((error) => {
                return <Alert severity="error">{error}</Alert>;
              })}
            </Grid>
            {showBillingAddressToggle &&
              <Grid item xs={3}>
                <Typography variant="h5">Saved Billing Address</Typography>
                <Checkbox
                  checked={useAccountBillingAddress}
                  onClick={handleBillingAddressToggle}
                />
              </Grid>
            }
            
            <Grid item xs={12} lg={6} md={6} sm={6}>
              <Button
                mr={1}
                variant="contained"
                color="secondary"
                fullWidth
                onClick={submitAutoPaymentForm}
                my={2}
              >
                Submit new auto payment
              </Button>
            </Grid>
            <Grid item xs={12} lg={6} md={6} sm={6}>
              <Button
                mr={1}
                variant="contained"
                color="primary"
                fullWidth
                onClick={cancelPaymentForm}
                my={2}
              >
                Cancel
              </Button>
            </Grid>
            <Grid  item xs={12} lg={12} md={12} sm={12}>
            <EditableTable
            readOnly={false}
            canAddItems={false}
            canSave={true}
            isLoading={props.payments.isLoading}
            tableTitle={"Auto Payments"}
            objectArray={props.payments.accountAutoPayments}
            updateItemsLocalState={updateLocalItemsValues}
            saveCurrentTableData={saveAllLineItems}
            canDelete={true}
            manualItemDeletionMethod={handleSelectedAutoPayDeletion}
            verifyDelete={true}
            fieldTitles={fieldTitles}
            fieldTypes={fieldTypes}
            fieldNames={fieldNames}
            />
          </Grid>
          </Grid>
        </CardContent>
      </Card >
    );
  };
  
  // Component Properties
  AutoPaymentForm.propTypes = {
    // Store objects:
    user: PropTypes.object.isRequired,
    apl: PropTypes.object.isRequired,
    payments: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    accountDisconnects: PropTypes.object.isRequired,
    propertyUtilities: PropTypes.object.isRequired,
  
    getAssociatedAutoPays: PropTypes.func.isRequired,
    createNewAutoPay: PropTypes.func.isRequired,
    setAutoPayments: PropTypes.func.isRequired,
    updateAutoPayments: PropTypes.func.isRequired,
    deleteAutoPay: PropTypes.func.isRequired,
  };
  
  // Component State
  function AutoPaymentFormState(state) {
    return {
      user: state.user,
      apl: state.apl,
      payments: state.payments,
      account: state.account,
      accountDisconnects: state.accountDisconnects,
      propertyUtilities: state.propertyUtilities
    };
  }
  export default connect(AutoPaymentFormState, {
    getAssociatedAutoPays,
    createNewAutoPay,
    setAutoPayments, 
    updateAutoPayments,
    deleteAutoPay
  })(AutoPaymentForm);
  