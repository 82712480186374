import {
  Button,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField as MuiTextField,
  Typography,
  Checkbox
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
//Material UI Components and Functions
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import MuiAlert from "@material-ui/lab/Alert";
import { spacing } from "@material-ui/system";
// Redux Components
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

import { connect, useDispatch } from "react-redux";
import styled from "styled-components/macro";
// Custom UI componets and functions:
import { formatMoney } from "../../../setup/helpers";
import { getAccountBalance, hydrateAccount } from "../../account/api/actions";
import { getAllGlCodes } from "../../apl/api/actions";
import validator from 'validator';

import {
  createNewAccountPayment,
  getUserPayments,
  updateNewAccountPaymentErrors,
  updateNewAccountPaymentInProgress,
  createNewPaymentWithExternalServices
} from "../../payments/api/actions";
import { setSnackbar } from "../../snackbar/api/snackbar";
import moment from "moment";
import { getByPropertyId as getPropertyUtilityByPropertyId } from "../../propertyUtility/api/actions";
import { Popovers } from "example-pages";

const CenteredContent = styled.div`
  text-align: center;
`;

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const FormControl = styled(MuiFormControl)(spacing);
const TextField = styled(MuiTextField)(spacing);

const defaultCardFee = 3.25;
const defaultProcessingFee = 1.00;

// Custom Styles for property selection drop down

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  selectedPropertyLocationLabel: {
    fontSize: 50,
    backgroundColor: "#fffffff",
    borderColor: "#fffffff",
  },
  loadingIcon: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function getBasePaymentObject(userID, firstName = "N/A", lastName = "N/A") {
  const today = moment();
  // actual obj
  var baseObj = {
    paymentType: -1,
    paymentTypeDesc: null,
    paymentNote: `INTERNAL APPLICATION PAYMENT FROM USER: ${firstName}, ${lastName} (User ID: ${userID})`,
    paymentAmount: 0,

    routingNumber: "",
    accountNumber: "",

    firstName: "",
    lastName: "",
    nameOnCard: "",// also name on account
    ccNum: "",
    cardType: "",
    ccExpMonth: "",
    ccExpYear: "20",
    cardCvv: "",
    paymentDate: today,
    transactionFee: 0,
    transactionTotal: 0,
    checkNumber: "",
    accountsCovered: [],
    billingAddress1: "",
    billingAddress2: "",
    billingCity: "",
    billingState: "",
    billingZip: "",
    glCode: ""
  }
  return baseObj;
}
const PaymentForm = (props) => {
  // Handles vendor drop down
  const [paymentType, setPaymentType] = React.useState("");
  const [glCode, setGlCode] = React.useState("");
  const [paymentTypeDescription, setPaymentTypeDescription] = React.useState("");
  const [formType, setFormType] = React.useState("CASH");
  const [canPayEcheck, setCanPayEcheck] = React.useState(true);
  const [canPayCC, setCanPayCC] = React.useState(true);
  const [isAutoPayForm, setIsAutopayForm] = React.useState(props.isAutoPayForm || false);
  const [hasAutoPay, setHasAutoPay] = React.useState(false);
  const [useAccountBillingAddress, setUseAccountBillingAddress] = React.useState(false);
  const [showBillingAddressToggle, setShowBillingAddressToggle] = React.useState(false);
  const [newPaymentFormObject, setNewPaymentFormObject] = React.useState(getBasePaymentObject());

  const [propUtilityIsLoaded, setPropUtilityIsLoaded] = React.useState(false);
  const [transactionTotal, setTransactionTotal] = React.useState(0);
  const [feeTotal, setFeeTotal] = React.useState(0);
  const [isOpen, setIsOpen] = React.useState(props.isOpen || false);
  const [disableForm, setDisableForm] = React.useState(true);

  const dispatch = useDispatch();
  const getPaymentTypeInfoFromSelectedId = (id) => {
    for (var x = 0; x < props.apl.genericTypes.paymentMethodType.data.length; x++) {
      if (props.apl.genericTypes.paymentMethodType.data[x].id == id) {
        return props.apl.genericTypes.paymentMethodType.data[x];
      }
    }
    return null;
  };
  const updateFormFeeAccordingly = (type) => {
    newPaymentFormObject.paymentAmount = newPaymentFormObject.paymentAmount - feeTotal;

    if (props.propertyUtilities.data.propertyUtility != null && props.propertyUtilities.data.propertyUtility.id) {
      if (type == "BANK") {
        newPaymentFormObject.paymentAmount = newPaymentFormObject.paymentAmount + defaultProcessingFee;
        setFeeTotal(defaultProcessingFee);
      }
      if (type == "CARD") {
        newPaymentFormObject.paymentAmount = newPaymentFormObject.paymentAmount + props.propertyUtilities.data.propertyUtility.creditCardFee;
        setFeeTotal(props.propertyUtilities.data.propertyUtility.creditCardFee);
      }
      if (type == "CASH") {
        setFeeTotal(0);
      }
    }
    else {
      if (type == "BANK") {
        newPaymentFormObject.paymentAmount = newPaymentFormObject.paymentAmount + defaultProcessingFee;
        ;
        setFeeTotal(defaultProcessingFee);
      }
      if (type == "CARD") {
        newPaymentFormObject.paymentAmount = newPaymentFormObject.paymentAmount + defaultCardFee;
        setFeeTotal(defaultCardFee);
      }
      if (type == "CASH") {
        setFeeTotal(0);
      }
    }
    setTransactionTotal(newPaymentFormObject.paymentAmount+feeTotal);
  }


  const handleSelectedPaymentTypeChange = (event) => {
    var selectedType = getPaymentTypeInfoFromSelectedId(event.target.value);
    newPaymentFormObject.paymentType = event.target.value;
    if (selectedType != null) {
      setFormType(selectedType.type);
      setPaymentType(selectedType.id);
      setPaymentTypeDescription(selectedType.description);
      if (selectedType.description != 'Account Credit') {
        setDisableForm(false);
      }else {
        setDisableForm(true);
      }
    }
    updateFormFeeAccordingly(selectedType.type);
  };

  const handleSelectedGlCodeTypeChange = (event) => {
    setGlCode(event.target.value);
    setDisableForm(false);
  };


  const EvaluateFormObject = () => {
    var newErrors = [];
    // Amount warnings
    if (
      newPaymentFormObject.paymentType == "Credit/Debit Card" &&
      newPaymentFormObject.paymentAmount < 5
    ) {
      newErrors.push(
        "Credit/Debit cards have a minimum transaction value of $5"
      );
    } else if (newPaymentFormObject.paymentAmount <= 0) {
      newErrors.push("Payment Amount must be a positive value.");
    }

    // on error checking update the store and tell
    if (newErrors.length > 0) {
      props.updateNewAccountPaymentErrors(newErrors);
      return false;
    }
    props.updateNewAccountPaymentErrors(newErrors);
    return true;
  };

  useEffect(() => {
    if (!props.account.isLoading && props.account.data.account && props.account.data.account.id ) {
      // Determines if an open disconnect is still on the account before allowing e-check option
      if (props.account.data.account.badChecks >= 3) {
        setCanPayEcheck(false);
      }

      if (props.account.data.account.badCards >= 3) {
        setCanPayCC(false);
      }
      if(props.account.data.account.autoPayments && props.payments.accountAutoPayments?.items.length > 0) {
        setHasAutoPay(true);
      }
      if (props.account.data.addresses.length == 0) {
        setUseAccountBillingAddress(false);
        setShowBillingAddressToggle(false);
      }
      else {
        setShowBillingAddressToggle(true);
        setUseAccountBillingAddress(true);
      }
      if (props.account.data.property && props.account.data.property.id && !propUtilityIsLoaded && props.propertyUtilities.data.propertyUtility == null) {
        setPropUtilityIsLoaded(true);
        props.getPropertyUtilityByPropertyId(props.account.data.property.id);
      }
    }
    if (props.user.details != null) {
      setNewPaymentFormObject(getBasePaymentObject(props.user.details.id, props.user.details.firstName, props.user.details.lastName));
    }
    // resets utility lookup loading state upon closing 
    if (!isOpen && propUtilityIsLoaded && props.propertyUtilities.data.propertyUtility != null) {
      setPropUtilityIsLoaded(false);
    }
    if (props.propertyUtilities.data.propertyUtility != null && props.propertyUtilities.data.propertyUtility.id) {
      updateFormFeeAccordingly("CASH");
    }
    if (props.apl.genericTypes.billingAccountGLCode.data == [] && !props.apl.genericTypes.billingAccountGLCode.isLoading) {
      props.getAllGlCodes();
    }
  }, [props.account.isLoading, props.user.details, props.propertyUtilities.data]);

  const classes = useStyles();

  const findPaymentTypeId = (type) => {
    for (var x = 0; x < props.apl.genericTypes.paymentMethodType.data.length; x++) {
      if (props.apl.genericTypes.paymentMethodType.data[x].description == type) {
        return props.apl.genericTypes.paymentMethodType.data[x].id;
      }
    }
    // defaults -1
    return -1;
  }

  const submitPaymentForm = async () => {

    dispatch(setSnackbar(true, "warning", "Submitting Payment"));
    const isProperForm = EvaluateFormObject();
    //if (isProperForm) {
    // If no errors are found
    let paymentObjectToSend = newPaymentFormObject;
    if (formType == "BANK") {
      paymentObjectToSend.nameOnCard = paymentObjectToSend.firstName + " " + paymentObjectToSend.lastName;
    }
    var selectedType = getPaymentTypeInfoFromSelectedId(paymentType);
    paymentObjectToSend.transactionFee = parseFloat(feeTotal.toFixed(2));
    paymentObjectToSend.transactionTotal = paymentObjectToSend.paymentAmount + paymentObjectToSend.transactionFee;
    paymentObjectToSend.transactionTotal = parseFloat(paymentObjectToSend.transactionTotal.toFixed(2));
    paymentObjectToSend.paymentType = paymentType;
    paymentObjectToSend.glCode = glCode;
    paymentObjectToSend.paymentTypeDesc = selectedType.description;
    paymentObjectToSend.accountsCovered = [props.account.data.account];

    if (useAccountBillingAddress) {
      paymentObjectToSend.billingAddress1 = props.account.data.addresses[0].address1;
      paymentObjectToSend.billingAddress2 = props.account.data.addresses[0].address2;
      paymentObjectToSend.billingCity = props.account.data.addresses[0].city;
      paymentObjectToSend.billingState = props.account.data.addresses[0].state;
      paymentObjectToSend.billingZip = props.account.data.addresses[0].zip;
    }
    let response = await props.createNewPaymentWithExternalServices(paymentObjectToSend);
      if(response.status == 200) {
        props.onUpdate();
      }
  };

  const cancelPaymentForm = () => {
    if (props.onCancel) {
      props.onCancel();
    }
  };


  const handleBillingAddressToggle = (event) => {
    setUseAccountBillingAddress(!useAccountBillingAddress);
  };

  // Updates the local state object of "bill in progress" upon each modification
  const onFieldChange =  (event)  =>  {

    if(event.target.name == "feeTotal") {
      setFeeTotal(Number(event.target.value));
      newPaymentFormObject.transactionFee = Number(event.target.value);
      // newPaymentFormObject.transactionTotal = newPaymentFormObject.paymentAmount + newPaymentFormObject.transactionFee;
      // setTransactionTotal(newPaymentFormObject.transactionTotal);
      // newPaymentFormObject.paymentAmount = feeTotal + newPaymentFormObject.paymentAmount;
      setTransactionTotal(parseFloat(newPaymentFormObject.transactionFee.toFixed(2)) + parseFloat(newPaymentFormObject.paymentAmount.toFixed(2)));


    }

    if (event.target.name == "paymentAmount") {
      newPaymentFormObject.paymentAmount = Number(event.target.value);
      setTransactionTotal(newPaymentFormObject.paymentAmount + feeTotal);
    }
    else {
      // Gets the current vendor info data and updates it with the event's name and value:
      if (event.target.type == "number") {
        newPaymentFormObject[event.target.name] = String(event.target.value);
      } else {
        newPaymentFormObject[event.target.name] = event.target.value;
      }
    }
  };

  if (props.account.isLoading) {
    return (
      <Card mb={6}>
        <CardContent>
          <Typography variant="h3" gutterBottom>
            Account Loading...
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card mb={6} elevation={3}>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item lg={4} md={6} sm={6}>
            <FormControl variant="outlined" fullWidth my={2}>
              <InputLabel id="paymentTypeDropDownLabel">
                Payment Type
              </InputLabel>
              <Select
                labelId="paymentTypeDropDownLabel"
                id="paymentTypeDropDown"
                value={paymentType}
                onChange={handleSelectedPaymentTypeChange}
                label="Payment Type"
                name="paymentType"
                fullWidth
                my={2}
              >
                {props.apl.genericTypes.paymentMethodType.data.length > 0 ? (
                  props.apl.genericTypes.paymentMethodType.data.map(
                    (item, key) => {
                      if ((canPayCC && item.description == "Credit/Debit Card") ||
                        item.description == "Account Credit" ||
                        item.description == "Check" ||
                        item.description == "Posting Error" ||
                        item.description == "WB Lockbox" ||
                        item.description == "Customer Refund" ||
                        item.description == "Write Off" ||
                        item.description == "Balance Transfer" ||
                        (hasAutoPay && item.description == "Auto-Pay account") ||
                        (canPayEcheck && item.description == "eCheck") ||
                        (!isAutoPayForm && item.description == "Cash")) {
                        return (
                          <MenuItem value={item.id} id={key}>
                            {item.description}
                          </MenuItem>
                        );
                      }
                    }
                  )
                ) : (
                  <MenuItem value={-1}>No Payment Method Types Found</MenuItem>
                )}
                ]
              </Select>
            </FormControl>
          </Grid>
          {formType == "BANK" && ( // Only show for ach type
            <>
              <Grid item lg={4} md={6} sm={6}>
                <TextField
                  fullWidth
                  type="number"
                  id="routingNumber"
                  variant="outlined"
                  label="Routing Number"
                  inputProps={{ maxLength: 9 }}
                  defaultValue={newPaymentFormObject.routingNumber}
                  onChange={onFieldChange}
                  name="routingNumber"
                />
              </Grid>
              <Grid item lg={4} md={6} sm={6}>
                <TextField
                  fullWidth
                  type="text"
                  id="firstName"
                  variant="outlined"
                  label="Account Holder First Name"
                  defaultValue={newPaymentFormObject.firstName}
                  onChange={onFieldChange}
                  name="firstName"
                />
              </Grid>
              <Grid item lg={4} md={6} sm={6}>
                <TextField
                  fullWidth
                  type="text"
                  id="lastName"
                  variant="outlined"
                  label="Account Holder Last Name"
                  defaultValue={newPaymentFormObject.lastName}
                  onChange={onFieldChange}
                  name="lastName"
                />
              </Grid>
            </>
          )}
          {formType == "CARD" && ( // Only show for credit card type
            <Grid item lg={4} md={6} sm={6}>
                <FormControl variant="outlined" fullWidth my={2}>
              <InputLabel id="paymentTypeDropDownLabel">
                Payment Type
              </InputLabel>
              <Select
                fullWidth
                type="text"
                id="cardType"
                variant="outlined"
                label="Card Type"
                defaultValue={newPaymentFormObject.cardType}
                onChange={onFieldChange}
                name="cardType"
              >
               <MenuItem value={"Visa"}>Visa</MenuItem>
              <MenuItem value={"Mastercard"}>Mastercard</MenuItem>
            </Select>
            </FormControl>
            </Grid>
          )}
          {formType == "BANK" && ( // Only show for ach type
            <Grid item lg={4} md={6} sm={6}>
              <TextField
                fullWidth
                id="accountNumber"
                variant="outlined"
                label="Account Number"
                type="number"
                inputProps={{ maxLength: 17 }}

                defaultValue={newPaymentFormObject.accountNumber}
                onChange={onFieldChange}
                name="accountNumber"
              />
            </Grid>
          )}
          {formType == "CARD" && ( // Only show for credit card type
            <>
              <Grid item lg={4} md={6} sm={6}>
                <TextField
                  my={2}
                  fullWidth
                  type="text"
                  id="nameOnCard"
                  variant="outlined"
                  label="Name On Card"
                  defaultValue={newPaymentFormObject.nameOnCard}
                  onChange={onFieldChange}
                  name="nameOnCard"
                />
              </Grid>
            </>
          )}
          <Grid item lg={4} md={6} sm={6}>
            <TextField
              id="paymentAmount"
              label="Payment Amount (USD)"
              type="number"
              fullWidth
              my={2}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                  inputProps: { 
                      max: 9999, min: 1 
                  }
              }}
              defaultValue={newPaymentFormObject.paymentAmount}
              onChange={onFieldChange}
              name="paymentAmount"
            />
          </Grid>
          {/*<Grid item lg={4} md={6} sm={6}>
              <TextField
                id="glCode"
                label="Gl Code"
                type="number"
                fullWidth
                my={2}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                defaultValue={newPaymentFormObject.glCode}
                onChange={onFieldChange}
                name="glCode"
              />
            </Grid>
            <Grid container spacing={3}>
              */}
          {(paymentTypeDescription == "Account Credit" || 
           paymentTypeDescription == "Posting Error"
          || paymentTypeDescription == "Customer Refund" ) && ( // Only show for credit card type //props.apl.genericTypes.billingAccountGLCode.data

            <Grid item lg={4} md={6} sm={6}>
              <FormControl variant="outlined" fullWidth my={2}>
                <InputLabel id="glCodeTypeDownLabel">
                  GL Code
                </InputLabel>
                <Select
                  labelId="glCodeTypeDownLabel"
                  id="glCodeTypeDropDown"
                  value={glCode}
                  onChange={handleSelectedGlCodeTypeChange}
                  label="GL Code"
                  name="glCode"
                  fullWidth
                  my={2}
                >
                  {props.apl.genericTypes.billingAccountGLCode.data.length > 0 ? (
                    props.apl.genericTypes.billingAccountGLCode.data.map(
                      (item, key) => {
                        {
                          if (paymentTypeDescription == "Customer Refund" || paymentTypeDescription == "Posting Error") {
                            if (item.accountDescription == "ACH / Web Payment Fee" || item.accountDescription == "Credit Card / Web Payment Fee") {
                              return (
                                <MenuItem value={item.id} id={key}>
                                  {item.accountDescription}
                                </MenuItem>
                              );
                            }
                            
                          }else if (paymentTypeDescription == "Account Credit") {
                            if (item.accountDescription == "NSF/Bank Fee Income"
                            || item.accountDescription == "Water Receipts" 
                            || item.accountDescription == "Late Fee / Penalty Income" 
                            || item.accountDescription == "Customer Service Charge"
                            || item.accountDescription == "Account Setup Fee") {
                              return (
                                <MenuItem value={item.id} id={key}>
                                  {item.accountDescription}
                                </MenuItem>
                              );
                            }
                          }
                          else{
                            return (
                              <MenuItem value={item.id} id={key}>
                                {item.accountDescription}
                              </MenuItem>
                            );
                          }
                          
                        }
                      }
                    )
                  ) : (
                    <MenuItem value={-1}>No GL Codes Found</MenuItem>
                  )}
                  ]
                </Select>
              </FormControl>
            </Grid>
          ) }

          {formType == "CARD" && ( // Only show for credit card type
            <>

              <Grid item lg={4} md={6} sm={6}>
                <TextField
                  my={2}
                  fullWidth
                  variant="outlined"
                  type="number"
                  id="ccNum"
                  label="Card Number"
                  inputProps={{ maxLength: 19 }}
                  defaultValue={newPaymentFormObject.ccNum}
                  onChange={onFieldChange}
                  name="ccNum"
                />
              </Grid>
              <Grid item lg={4} md={6} sm={6}>
                <TextField
                  my={2}
                  fullWidth
                  type="text"
                  id="ccExpMonth"
                  label="Exp Month (MM)"
                  onChange={onFieldChange}
                  variant="outlined"
                  defaultValue={newPaymentFormObject.ccExpMonth}
                  name="ccExpMonth"
                  inputProps={{ maxLength: 2 }}
                />
              </Grid>
              <Grid item lg={4} md={6} sm={6}>
                <TextField
                  my={2}
                  fullWidth
                  type="text"
                  id="ccExpYear"
                  label="Exp Year (YYYY)"
                  onChange={onFieldChange}
                  variant="outlined"
                  inputProps={{ maxLength: 4 }}
                  defaultValue={newPaymentFormObject.ccExpYear}
                  name="ccExpYear"
                />
              </Grid>
              <Grid item lg={4} md={6} sm={6}>
                <TextField
                  my={2}
                  fullWidth
                  type="text"
                  id="cardCvv"
                  variant="outlined"
                  label="CVV"
                  defaultValue={newPaymentFormObject.cardCvv}
                  onChange={onFieldChange}
                  name="cardCvv"
                  inputProps={{ maxLength: 4 }}
                />
              </Grid>
            </>
          )}
          {formType == "CHECK" && ( // Only show for credit card type
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <TextField
                my={2}
                fullWidth
                type="text"
                id="checkNumber"
                label="Check Number"
                value={newPaymentFormObject.checkNumber}
                onChange={onFieldChange}
                autoComplete={"off"}
                //onBlur={() =>{ props.handleBillingProcessSubmission(props.billing.data.billInProgress, false); } }
                variant="outlined"
                name="checkNumber"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          )}
          <Grid item lg={8} md={12} sm={12}>
            <TextField
              id="paymentNote"
              label="Payment Note"
              type="text"
              fullWidth
              my={2}
              multiline={true}
              rows={10}
              rowsMax={10}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              defaultValue={newPaymentFormObject.paymentNote}
              onChange={onFieldChange}
              name="paymentNote"
            />
          </Grid>
        </Grid>
        {!useAccountBillingAddress &&
          <>
            <Grid container spacing={4}>
              <Grid item lg={4} md={4} sm={6}>
                <TextField
                  label="Billing Address"
                  type="text"
                  onChange={onFieldChange}
                  name="billingAddress1"
                  defaultValue={newPaymentFormObject.billingAddress1}
                  fullWidth
                  my={2}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={6}>
                <TextField
                  label="Billing Address (Apt)"
                  type="text"
                  onChange={onFieldChange}
                  name="billingAddress2"
                  defaultValue={newPaymentFormObject.billingAddress2}
                  fullWidth
                  my={2}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={6}>
                <TextField
                  label="Billing City"
                  type="text"
                  onChange={onFieldChange}
                  name="billingCity"
                  defaultValue={newPaymentFormObject.billingCity}
                  fullWidth
                  my={2}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={6}>
                <TextField
                  label="Billing State"
                  type="text"
                  onChange={onFieldChange}
                  name="billingState"
                  defaultValue={newPaymentFormObject.billingState}
                  fullWidth
                  my={2}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={6}>
                <TextField
                  label="Billing Zip"
                  type="text"
                  onChange={onFieldChange}
                  name="billingZip"
                  defaultValue={newPaymentFormObject.billingZip}
                  fullWidth
                  my={2}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </>
        }
        <Grid container spacing={4}>
          <Grid item xs={9} lg={9}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableBody>
                  {(formType == "BANK" || formType == "CARD" || 
                  paymentTypeDescription == "Posting Error" ||
                   paymentTypeDescription == "Customer Refund" ||
                   paymentTypeDescription == "Auto-Pay account" 
                  ) && ( // Only show for credit card type
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="h5">Transaction Fee</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h5">
                          {parseFloat}
                        </Typography>
                        <TextField
                            id="feeTotal"
                            label="Transaction Fee"
                            type="number"
                            onChange={onFieldChange}
                            name="feeTotal"
                            defaultValue={feeTotal}
                            fullWidth
                            my={2}
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography variant="h5">Transaction Total</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h5">
                        {formatMoney(transactionTotal)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            {props.payments.newPaymentSubmissionErrors.map((error) => {
              return <Alert severity="error">{error}</Alert>;
            })}

            {!canPayEcheck && 
              <Alert severity="error">Too many bad checks, can not pay with echeck.</Alert>
            }
            {!canPayCC && 
              <Alert severity="error">Too many bad cards, can not pay with credit/debit card.</Alert>
            }
          </Grid>
          {showBillingAddressToggle &&
            <Grid item xs={3}>
              <Typography variant="h5">Saved Billing Address</Typography>
              <Checkbox
                checked={useAccountBillingAddress}
                onClick={handleBillingAddressToggle}
              />
            </Grid>
          }
          <Grid item xs={12} lg={6} md={6} sm={6}>
            <Button
              mr={1}
              variant="contained"
              color="secondary"
              fullWidth
              disabled={props.account.data.account.stopPayment 
                || (newPaymentFormObject.paymentAmount == 0 && paymentTypeDescription != "Customer Refund" && paymentTypeDescription != "Posting Error" )
                || disableForm}
              onClick={submitPaymentForm}
              my={2}
            >Submit new account payment
            </Button>
          </Grid>
          <Grid item xs={12} lg={6} md={6} sm={6}>
            <Button
              mr={1}
              variant="contained"
              color="primary"
              fullWidth
              onClick={cancelPaymentForm}
              my={2}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

// Component Properties
PaymentForm.propTypes = {
  // Store objects:
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  payments: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  accountDisconnects: PropTypes.object.isRequired,
  propertyUtilities: PropTypes.object.isRequired,

  createNewAccountPayment: PropTypes.func.isRequired,
  getPropertyUtilityByPropertyId: PropTypes.func.isRequired,
  updateNewAccountPaymentInProgress: PropTypes.func.isRequired,
  updateNewAccountPaymentErrors: PropTypes.func.isRequired,
  hydrateAccount: PropTypes.func.isRequired,
  getUserPayments: PropTypes.func.isRequired,
  getAccountBalance: PropTypes.func.isRequired,
  createNewPaymentWithExternalServices: PropTypes.func.isRequired,
  getAllGlCodes: PropTypes.func.isRequired,
};

// Component State
function PaymentFormState(state) {
  return {
    user: state.user,
    apl: state.apl,
    payments: state.payments,
    account: state.account,
    propertyUtilities: state.propertyUtilities
  };
}
export default connect(PaymentFormState, {
  getUserPayments,
  getAccountBalance,
  createNewAccountPayment,
  updateNewAccountPaymentInProgress,
  updateNewAccountPaymentErrors,
  hydrateAccount,
  getPropertyUtilityByPropertyId,
  createNewPaymentWithExternalServices,
  getAllGlCodes
})(PaymentForm);
