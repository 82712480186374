// Imports
import axios from "axios";
import { setSnackbar } from "modules/snackbar/api/snackbar";
import moment from "moment";
require("../../../setup/config/apiConfig");

export const RESET = "RESET";

export const GET_AGING = "GET_AGING";
export const GET_AGING_RESPONSE = "GET_AGING_RESPONSE";
export const GET_AGING_FAIL = "GET_AGING_FAIL";

export function get(
  searchQuery,
  isLoading = true,
  datasSet,
  functionName,
  params,
  list,
  email
) {
  return (dispatch) => {
    dispatch({
      type: GET_AGING,
      isLoading: isLoading,
    });
    let date = searchQuery.date || moment().format("yyyy/mm/dddd");
    let yearsBack = searchQuery.yearsBack || 1;
    return axios
      .put("/report/call", {
        function: functionName,
        params: params,
        email: email
      })
      .then((response) => {

        if(response.status == 200) {
          dispatch(setSnackbar(true, "sucess", `Report sent to ${email}. Please wait a few moments for the request to process`))
        }

        dispatch({
          type: GET_AGING_RESPONSE,
          aging: response.data[0],
          list: list,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_AGING_FAIL,
          isLoading: false,
          error: error,
        });
      });
  };
}
