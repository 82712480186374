import {
  Box,
  Button as MuiButton,
  Card,
  CardContent,
  Divider as MuiDivider,
  Grid,
  LinearProgress as MuiLinearProgress,
  Paper,
  Typography,
  Tooltip,
  TextField
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";
import DomainDisabledIcon from "@material-ui/icons/DomainDisabled";
import EditIcon from "@material-ui/icons/Edit";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Skeleton from "@material-ui/lab/Skeleton";
import { spacing } from "@material-ui/system";
import { LeftSidebar } from "layout-blueprints";
// Redux Components
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Clock, DollarSign, XOctagon } from "react-feather";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import StandardHeader from "../../components/global/StandardHeader";
import { ActionButtonWithImage } from "../../elements/Buttons/ActionButtonWithImage";
import { LeftSidebar as NavFrame } from "layout-blueprints"; // The top navigation bar and side navigation panel
import { setSnackbar } from "../../modules/snackbar/api/snackbar";
import {
  getAccountBalance,
  getAccountInfoByAccountUUID,
  getAccountLedger,
  hydrateAccount,
  lookupAccountMeterHistory,
  getAccountByAccountNumber,
  updateCurrentAccountInDB,
  updateSelectedAccount,
  } from "../../modules/account/api/actions";
import { getAssociatedAutoPays } from "../../modules/payments/api/actions";
import AccountLedgerLogTable from "../../modules/account/components/AccountLedgerLogTable";
import SelectedAccountLocationSummaryCard from "../../modules/account/components/AccountPropInfo/SelectedAccountLocationSummaryCard";
import SelectedAccountMeterCard from "../../modules/account/components/AccountPropInfo/SelectedAccountMeterCard";
import UserAndAccountControls from "../../modules/account/components/AccountToggleControlPanel";
import SelectedAccountInfoCard from "../../modules/account/components/SelectedAccountBasicInfoCard";
import UserRelatedAccountPreviewsTable from "../../modules/accountsSearch/components/UserRelatedAccountsPreviewTable";

import AccountEmailPopupEditor from "../../modules/notification/components/accountEmailPopup";
import AccountFilesTable from "../../modules/files/components/AccountFilesTable";
import {
  getAllAccountStatusCodes,
  getAllCustomerStatuses,
  getAllCustomerTypes,
  getAllGlCodes,
  getAllLetterTypes,
  getAllMeterReadTypes,
  getAllNoteTopicTypes,
  getAllPaymentMethodTypes,
} from "../../modules/apl/api/actions";
import GenericStatusTag from "../../modules/apl/components/GenericStatusTag";
import StatusTag from "../../modules/apl/components/StatusTag";
import AccountBillsTable from "../../modules/bills/components/AccountBillsTable";
import { getAccountTimesLateByAccountUuid } from "../../modules/bills/api/actions";
import UnitWorkOrdersTable from "../../modules/workOrders/components/UnitWorkOrderTable";
import { getAccountDelinquenciesByAccountID } from "../../modules/delinquencies/api/actions";
import {
  getAccountNotesByAccountUUID,
  setNotes,
} from "../../modules/notes/api/actions";
import ChatWindow from "../../modules/notes/components/AccountNotesChat";
import { getUserPayments } from "../../modules/payments/api/actions";
import AccountPaymentsTable from "../../modules/payments/components/AccountPaymentsTable";
import { handleUserLocalStorageVisitedAccounts } from "../../modules/user/api/actions";
import { getUsersByAccount, setSelectedUserProfile } from "../../modules/userProfiles/api/actions";
import UserDetailSummaryCard from "../../modules/userProfiles/components/UserDetailSummaryCard";
import UserPaymentMethodTypesTable from "../../modules/userProfiles/components/UserPaymentMethodTypesTable";
import { formatMoney, txtBoxDateFormatter } from "../../setup/helpers";
import ToolBar from "./ToolBar";
import { IosShare } from "@mui/icons-material";
import { ExitToApp } from "@material-ui/icons";
import { getByPropertyId as getPropertyUtilityByPropertyId } from "../../modules/propertyUtility/api/actions";

const LinearProgress = MuiLinearProgress;
const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AccountManagerPage = (props) => {
  const [selectedMeter, setSelectedMeter] = React.useState(null);
  const [selectedUser, setSelectedUser] = React.useState(null);
  const [LedgerTableOpen, setLedgerTableOpen] = React.useState(false);
  const [WorkOrdersTableOpen, setWorkOrdersTableOpen] = React.useState(false);
  const [AccountFilesTableOpen, setAccountFilesTableOpen] = React.useState(false);
  const [MeterReadingsTableOpen, setMeterReadingsTableOpen] = React.useState(false);
  const [UserProfileCardOpen, setUserProfileCardOpen] = React.useState(false);
  const [ContactAccountHolderCardOpen, setContactAccountHolderCardOpen] = React.useState(false);
  const [ArRecieptsTableOpen, setArRecieptsTableOpen] = React.useState(false);
  const [currentAccountUuid, setCurrentAccountUuid] = React.useState('');

  const [accountFound, setAccountFound] = React.useState(true);

  const history = useHistory();
  const dispatch = useDispatch();

  const handleLedgerTableClickOpen = () => {
    setLedgerTableOpen(true);
  };
  const handleLedgerTableClose = () => {
    setLedgerTableOpen(false);
  };

  const handleArRecieptsTableClickOpen = () => {
    setArRecieptsTableOpen(true);
  };
  const handleArRecieptsTableClose = () => {
    setArRecieptsTableOpen(false);
  };

  const handleContactAccountHolderClickOpen = () => {
    setContactAccountHolderCardOpen(true);
  };
  const handleContactAccountHolderClickClose = () => {
    setContactAccountHolderCardOpen(false);
  };

  const handleUserProfileClickOpen = (index) => {
    props.setSelectedUserProfile(props.userProfiles.details[index]);
    setUserProfileCardOpen(true);
  };
  const handleUserProfileClickClose = () => {
    props.setSelectedUserProfile(null);
    setUserProfileCardOpen(false);
  };

  const handleWorkOrdersTableClickOpen = () => {
    setWorkOrdersTableOpen(true);
  };
  const handleWorkOrdersTableClose = () => {
    setWorkOrdersTableOpen(false);
  };
  const handleAccountFilesTableClickOpen = () => {
    setAccountFilesTableOpen(true);
  };
  const handleAccountFilesTableClickClose = () => {
    setAccountFilesTableOpen(false);
  };

  const handleMeterReadingsTableClickOpen = () => {
    setMeterReadingsTableOpen(true);
  };
  const handleMeterReadingsTableClose = () => {
    setMeterReadingsTableOpen(false);
  };

  const onFieldChange = (event) => {
    props.account.data.account[event.target.name] = Number(event.target.value);
  }

  const saveAccount = (event) => {
    dispatch(setSnackbar(true, "warning", "Saving Account Detail Changes"));
    // Handle the account object updating
    props.updateSelectedAccount(props.account.data); // Updates local state item
    props.updateCurrentAccountInDB(props.account.data.account).then(() => {
      dispatch(setSnackbar(true, "success", "Saving Successful"));
    }); // Sends account data to db
  }

  const useStyles = makeStyles((theme) => ({
    title: {
      flexGrow: 1,
    },
    percentDif: {
      textAlign: "right",
      color: "green",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    loadingIcon: {
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
    },
    StatsIcon: {
      width: "32px",
      height: "32px",
      color: theme.palette.secondary.main,
      position: "absolute",
      right: "16px",
      top: "32px",
    },
    accountControlButtons: {
      flexDirection: "row",
      justifyContent: "center",
      textAlign: "center",
      alignItems: "center",
      alignSelf: "center",
      paddingTop: "5px",
      paddingBottom: "5px"
    },
    rightSideHeader: {
      display: "flex",
      alignItems: "flex-end",
      flexDirection: "row",
      justifyContent: "flex-end",
    },
    rightSideHeaderSubMenuToolBar: {
      display: "flex",
      alignItems: "flex-end",
      flexDirection: "row",
      justifyContent: "flex-end",
      margin: "0px",
      padding: "0px",
    },
    SubMenuToolBarIcon: {
      width: "25px",
      height: "25px",
      paddingBotom: "5px",
    },
    orange: {
      //color: theme.palette.getContrastText(deepOrange[500]),
    },
    UserBoxText: {
      textAlign: "center",
    },
    UserProfileDetailsButton: {
      padding: "5px",
    },
    AvatarText: {
      marginTop: "20%",
      height: "128px",
      width: "128px",
      textAlign: "center",
      alignSelf: "center",
    },
    buttonBoxCard: {
      textAlign: "center",
      alignSelf: "center",
    },
    AvatarIcon: {
      display: "inline-block",
      height: "128px",
      width: "128px",
      backgroundColor: "gray",
      borderColor: theme.palette.primary.main,
      borderStyle: "solid",
    },
    tableTitle: {
      paddingLeft: "24px",
    },
    ChartWrapper: {
      height: "200px",
      maxHeight: "200px",
      minHeight: "200px",
    },
    RowHeight: {
      display: "inline-block",
      height: "50%",
      maxHeight: "50%",
      minHeight: "50%",
    },
  }));

  const classes = useStyles();

  const UserPaymentMethods = () => {
    return (
      <Card mb={6}>
        <CardContent xs={12} sm={12} md={12} lg={12}>
          <Grid container xs={12} sm={12} md={12} lg={12}>
            <Grid item xs={9} sm={9} md={9} lg={9}>
              <Typography variant="h6" gutterBottom>
                Account Payment Methods
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              sm={3}
              md={3}
              lg={3}
              className={classes.rightSideHeaderSubMenuToolBar}
            >
              <Button>
                <EditIcon
                  fontSize="medium"
                  className={classes.SubMenuToolBarIcon}
                />
              </Button>
            </Grid>
          </Grid>
          <Divider mb={2} />
        </CardContent>
      </Card>
    );
  };

  const CurrentBalance = () => {
    // Loading bar should represent last payment val/ current balance
    return (
      <Box position="relative">
        <Card mb={6} pt={2}>
          <CardContent>
            {props.account.isLoading || props.payments.isLoading ? (
              <Skeleton
                variant="rect"
                height="60px"
                style={{ backgroundColor: `#bdbfbe`, padding: 6, margin: 6 }}
              />
            ) : (
              <>
                <Typography variant="h3" gutterBottom>
                  {props.account.data.ongoingAccountBalance.id ? (
                    <Box fontWeight="fontWeightRegular">
                      {formatMoney(
                        props.account.data.ongoingAccountBalance.balance
                      )}
                    </Box>
                  ) : (
                    <Box fontWeight="fontWeightRegular">N/A</Box>
                  )}
                </Typography>
                <Typography variant="body1" gutterBottom mt={3} mb={0}>
                  Current Balance
                </Typography>
                <DollarSign className={classes.StatsIcon} />
                <LinearProgress
                  variant="determinate"
                  value={75}
                  color="secondary"
                  mt={4}
                />
              </>
            )}
          </CardContent>
        </Card>
      </Box>
    );
  };

  const AmmountPastDue = () => {
    // Loading bar should represent last payment val/ current balance
    var progressVal = 0;
    if (!props.account.isLoading) {
      progressVal = (props.account.data.ongoingAccountBalance.pastDue / props.account.data.ongoingAccountBalance.balance) * 100;
    }
    return (
      <Box position="relative">
        <Card mb={6} pt={2}>
          <CardContent>
            {props.account.isLoading ||
              props.payments.isLoading ||
              props.bills.isLoading ? (
              <Skeleton
                variant="rect"
                height="60px"
                style={{ backgroundColor: `#bdbfbe`, padding: 6, margin: 6 }}
              />
            ) : (
              <>
                <Typography variant="h4" gutterBottom>
                  {props.account.data.ongoingAccountBalance.id ? (
                    <Box fontWeight="fontWeightRegular">
                      {formatMoney(
                        props.account.data.ongoingAccountBalance.pastDue
                      )}
                    </Box>
                  ) : (
                    <Box fontWeight="fontWeightRegular">N/A</Box>
                  )}
                </Typography>
                <Typography variant="body2" gutterBottom mt={3} mb={0}>
                  Amount Past Due{" "}
                  {props.bills.data.length > 0 &&
                    `(Last Due @ ${txtBoxDateFormatter(
                      props.bills.data[0].dueDate
                    )})`}
                </Typography>
                <AssignmentLateIcon className={classes.StatsIcon} />
                <LinearProgress
                  variant="determinate"
                  value={progressVal}
                  color="secondary"
                  mt={4}
                />
              </>
            )}
          </CardContent>
        </Card>
      </Box>
    );
  };

  // Loading bar is amount of late payments/ all payments made
  const LatePaymentCount = () => {
    var progressVal = 0;
    if (!props.bills.accountBillingTimesLateIsLoading) {
      progressVal = (props.bills.accountBillingTimesLate / 3) * 100;
      if (progressVal > 100) {
        progressVal = 100;
      }
    }
    return (
      <Box position="relative">
        <Card mb={6} pt={2}>
          <CardContent>
            {props.bills.accountBillingTimesLateIsLoading ? (
              <Skeleton
                variant="rect"
                height="60px"
                style={{ backgroundColor: `#bdbfbe`, padding: 6, margin: 6 }}
              />
            ) : (
              <>
                <Typography variant="h4" gutterBottom>
                  <Box fontWeight="fontWeightRegular">{props.bills.accountBillingTimesLate}</Box>
                </Typography>
                <Typography variant="body2" gutterBottom mt={3} mb={0}>
                  Times Late
                </Typography>

                <Clock className={classes.StatsIcon} />
                <LinearProgress
                  variant="determinate"
                  value={progressVal}
                  color="secondary"
                  mt={4}
                />
              </>
            )}
          </CardContent>
        </Card>
      </Box>
    );
  };


  const BadCardCount = () => {
    // Loading bar should represent times late paid
    var progressVal = 0;
    if (!props.account.isLoading) {
      progressVal = (props.account.data.account.badChecks / 3) * 100
      if (progressVal > 100) {
        progressVal = 100;
      }
    }
    return (
      <Box position="relative">
        <Card mb={6} pt={2}>
          <CardContent>
            {props.account.isLoading ? (
              <Skeleton
                variant="rect"
                height="60px"
                style={{ backgroundColor: `#bdbfbe`, padding: 6, margin: 6 }}
              />
            ) : (
              <>
                <Typography variant="h4" gutterBottom>
                  <Box fontWeight="fontWeightRegular">
                    <TextField
                      type="number"
                      style={{ width: "60%", fontSize: "30" }}
                      onChange={onFieldChange}
                      onBlur={saveAccount}
                      name="badCards"
                      defaultValue={props.account.data.account.badCards}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Box>
                </Typography>
                <Typography variant="body2" gutterBottom mt={3} mb={0}>
                  Bad Cards
                </Typography>
                <XOctagon className={classes.StatsIcon} />
                <LinearProgress
                  variant="determinate"
                  value={progressVal}
                  color="secondary"
                  mt={4}
                />
              </>
            )}
          </CardContent>
        </Card>
      </Box>
    );
  };

  const BadCheckCount = () => {
    // Loading bar should represent times late paid
    var progressVal = 0;
    if (!props.account.isLoading) {
      progressVal = (props.account.data.account.badChecks / 3) * 100
      if (progressVal > 100) {
        progressVal = 100;
      }
    }
    return (
      <Box position="relative">
        <Card mb={6} pt={2}>
          <CardContent>
            {props.account.isLoading ? (
              <Skeleton
                variant="rect"
                height="60px"
                style={{ backgroundColor: `#bdbfbe`, padding: 6, margin: 6 }}
              />
            ) : (
              <>
                <Typography variant="h4" gutterBottom>
                  <Box fontWeight="fontWeightRegular">
                    <TextField
                      type="number"
                      style={{ width: "60%", fontSize: "30" }}
                      onChange={onFieldChange}
                      onBlur={saveAccount}
                      name="badChecks"
                      defaultValue={props.account.data.account.badChecks}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Box>
                </Typography>
                <Typography variant="body2" gutterBottom mt={3} mb={0}>
                  Bad Checks
                </Typography>
                <XOctagon className={classes.StatsIcon} />
                <LinearProgress
                  variant="determinate"
                  value={progressVal}
                  color="secondary"
                  mt={4}
                />
              </>
            )}
          </CardContent>
        </Card>
      </Box>
    );
  };

  const LastPaymentInfo = () => {
    // Loading bar should represent time until next payment
    return (
      <Box position="relative">
        <Card mb={6} pt={2}>
          <CardContent>
            {props.account.isLoading || props.payments.isLoading ? (
              <Skeleton
                variant="rect"
                height="60px"
                style={{ backgroundColor: `#bdbfbe`, padding: 6, margin: 6 }}
              />
            ) : (
              <>
                <Typography variant="h4" gutterBottom>
                  <Box fontWeight="fontWeightRegular">
                    {props.payments.data.items && props.payments.data.items[0]
                      ? props.payments.data.items[0].id
                        ? formatMoney(
                          props.payments.data.items[0].paymentAmount +
                          props.payments.data.items[0].feeAmount
                        )
                        : "None found"
                      : "None found"}
                  </Box>
                </Typography>
                <Typography variant="body2" gutterBottom mt={3} mb={0}>
                  Last Payment{" "}
                  {props.payments.data.items && props.payments.data.items[0]
                    ? props.payments.data.items[0].id
                      ? `(${txtBoxDateFormatter(
                        props.payments.data.items[0].paymentDate
                      )})`
                      : ""
                    : ""}
                </Typography>
                <ShoppingCartIcon className={classes.StatsIcon} />
                <LinearProgress
                  variant="determinate"
                  value={50}
                  color="secondary"
                  mt={4}
                />
              </>
            )}
          </CardContent>
        </Card>
      </Box>
    );
  };

  const OtherTablesButtonBox = () => {
    // Loading bar should represent time until next payment
    return (
      <Grid container spacing={3} xs={12} sm={12} md={12} lg={12} className={classes.accountControlButtons}>
        {!props.account.isLoading ? (
          <>
            <Grid item>
              <ActionButtonWithImage
                title={"Ledger"}
                subtitle={props.account.ledgerIsLoading ? "Loading..." : props.account.ledger?.entries?.length || "0 Ledger Items Found"}
                iconName={"list-alt"}
                onClickMethod={handleLedgerTableClickOpen}
              />
            </Grid>
            {!props.account.isLoading && props.account.data.unit.id && props.account.data.unit.meters.length > 0
             && props.propertyUtilities?.data?.propertyUtility?.billingType  == 'BILL' ?
              props.account.data.unit.meters.map((meter, index) => {
                return (
                  <Grid item>
                    <ActionButtonWithImage
                      title={`Meter #${meter.meterNum}`}
                      subtitle={props.account.meterLookupIsLoading ? "Loading..." : (meter.readCount ? meter.readCount : 0) + " Meter Reads" || "0 Meter Reads Found"}
                      iconName={"chart-bar"}
                      onClickMethod={() => {
                        setSelectedMeter(meter.id);
                        handleMeterReadingsTableClickOpen(meter.id);
                      }}
                    />
                  </Grid>
                )
              })
              :
              <Grid item>
                <ActionButtonWithImage
                  title={"Meter"}
                  iconName={"chart-bar"}
                  disabled={"No Attached Unit"}
                  onClickMethod={handleMeterReadingsTableClickOpen}
                />
              </Grid>
            }
            {!props.account.isLoading && props.account.data.unit.id ?
              <Grid item>
                <ActionButtonWithImage
                  title={"Work Orders"}
                  subtitle={props.workOrders.isLoading ? "Loading..." : props.workOrders.unitWorkOrders.items.length + " Work Orders Found" || "0 Work Orders Found"
                  }
                  iconName={"clipboard"}
                  onClickMethod={handleWorkOrdersTableClickOpen}
                />
              </Grid>
              :
              <Grid item>
                <ActionButtonWithImage
                  title={"Work Orders"}
                  iconName={"clipboard"}
                  disabled={"No Attached Unit"}
                  onClickMethod={handleWorkOrdersTableClickOpen}
                />
              </Grid>
            }
            <Grid item>
              <ActionButtonWithImage
                title={"Files"}
                subtitle={props.files.isLoading ? "Loading..." : props.files.data.accountFiles.items.length + " Files On Record" || "Unknown"
                }
                iconName={"folder-open"}
                onClickMethod={handleAccountFilesTableClickOpen}
              />
            </Grid>
            {!props.userProfiles.isLoading && (props.userProfiles.details && props.userProfiles.details != null) && props.userProfiles.details.length != 0 ?
              props.userProfiles.details.map((user, index) => {
                return (
                  <Grid item>
                    <ActionButtonWithImage
                      title={user.firstName + ", " + user.lastName}
                      subtitle={"User Profile "}
                      iconName={"id-card"}
                      onClickMethod={() => { handleUserProfileClickOpen(index) }}
                    />
                  </Grid>
                )
              })
              :
              <Grid item>
                <ActionButtonWithImage
                  title={"User Profile"}
                  iconName={"id-card"}
                  showError={"No User Found"}
                  onClickMethod={handleUserProfileClickOpen}
                />
              </Grid>
            }
            <Grid item>
              <ActionButtonWithImage
                title={"Contact Account Holder"}
                iconName={"envelope"}
                onClickMethod={handleContactAccountHolderClickOpen}
              />
            </Grid>
          </>
        )
          :
          <>
            <Grid item xs={2}>
              <Skeleton
                variant="rect"
                height="60px"
                style={{ backgroundColor: `#bdbfbe`, padding: 6, margin: 6 }}
              />
            </Grid>
            <Grid item xs={2}>
              <Skeleton
                variant="rect"
                height="60px"
                style={{ backgroundColor: `#bdbfbe`, padding: 6, margin: 6 }}
              />
            </Grid>
            <Grid item xs={2}>
              <Skeleton
                variant="rect"
                height="60px"
                style={{ backgroundColor: `#bdbfbe`, padding: 6, margin: 6 }}
              />
            </Grid>
            <Grid item xs={2}>
              <Skeleton
                variant="rect"
                height="60px"
                style={{ backgroundColor: `#bdbfbe`, padding: 6, margin: 6 }}
              />
            </Grid>

            <Grid item xs={2}>
              <Skeleton
                variant="rect"
                height="60px"
                style={{ backgroundColor: `#bdbfbe`, padding: 6, margin: 6 }}
              />
            </Grid>

            <Grid item xs={2}>
              <Skeleton
                variant="rect"
                height="60px"
                style={{ backgroundColor: `#bdbfbe`, padding: 6, margin: 6 }}
              />
            </Grid>

            <Grid item xs={2}>
              <Skeleton
                variant="rect"
                height="60px"
                style={{ backgroundColor: `#bdbfbe`, padding: 6, margin: 6 }}
              />
            </Grid>

            <Grid item xs={2}>
              <Skeleton
                variant="rect"
                height="60px"
                style={{ backgroundColor: `#bdbfbe`, padding: 6, margin: 6 }}
              />
            </Grid>
          </>
        }
      </Grid>
    );
  };

  const RefreshPage = async () => {
    if (!props.account.isInitialAccountLoading && props.account.initialAccount == null) {
      let account = await props.getAccountByAccountNumber(props.match.params.acctNum);
      console.log(account)
      if(account.length <= 0) {
        setAccountFound(false);
      }
      else {
        setAccountFound(true)
      }
    }
    if (!props.account.isInitialAccountLoading && props.account.initialAccount != null 
      ) {
      props.getUsersByAccount(props.account.initialAccount.uuid);
      props.getAccountNotesByAccountUUID(props.account.initialAccount.uuid);
      props.getAccountTimesLateByAccountUuid(props.account.initialAccount.uuid);
      props.getAllMeterReadTypes();
      props.getAccountLedger(props.match.params.acctNum);
      props.getAllNoteTopicTypes();
      props.getAllGlCodes();
      props.getAllAccountStatusCodes();
      props.getAllCustomerTypes();
      props.getAllLetterTypes();
      props.getAllPaymentMethodTypes();
      props.hydrateAccount(props.account.initialAccount.uuid);
      props.getAssociatedAutoPays(props.account.initialAccount.uuid);
      if (props.account.data?.property?.id) props.getPropertyUtilityByPropertyId(props.account.data?.property?.id);
      setCurrentAccountUuid(props.account.initialAccount.uuid);

    }
  };

  const callbackModal = () => {
    setMeterReadingsTableOpen(false);
  }

  useEffect(() => {
    handleUserLocalStorageVisitedAccounts(props.match.params.acctNum);
    RefreshPage();
  }, [props.account.initialAccount]);

  var pageTitle = "Ubil+ Loading Acct...";
  var tabTitle = "Ubil+ Loading Acct...";
  // Sets the tab name based on the loaded account being worked on
  if (props.account.data.account) {
    if (
      props.account.data.account.firstName &&
      props.account.data.account.lastName
    ) {
      tabTitle =
        props.account.data.account.firstName[0] +
        ", " +
        props.account.data.account.lastName +
        " - " +
        "# " +
        props.account.data.account.accountNum;
      pageTitle =
        props.account.data.account.firstName +
        ", " +
        props.account.data.account.lastName +
        " - " +
        "# " +
        props.account.data.account.accountNum;
    } else {
      pageTitle = props.account.data.account.lastName + " - " + "# " + props.account.data.account.accountNum;
      tabTitle = props.account.data.account.lastName + " - " + "# " + props.account.data.account.accountNum;
    }
  }
  document.title = tabTitle;

  // Safety to make sure status ids are there before passing to tags (Should be a store function in the future, with accounts.data having default values for each of these)
  var acctStatusId = -1;
  var customerTypeId = -1;
  var email = "";
  //var customerStatus = -1;
  if (props.account.data.account) {
    acctStatusId = props.account.data.account.accountStatusId;
    customerTypeId = props.account.data.account.customerTypeId;
    email = props.account.data.account.email;
  }
  var currentHeaderTags = [
    <StatusTag
      type={"accountStatus"}
      isLoading={props.account.isLoading}
      typeId={acctStatusId}
    />,
    <StatusTag
      type={"customerType"}
      isLoading={props.account.isLoading}
      typeId={customerTypeId}
    />,
  ];
  var pageEmergency = false;
  if (!props.account.isLoading && props.account.data.account) {
    if (props.account.data.account.stopPayment) {
      currentHeaderTags.push(
        <GenericStatusTag
          customStyles={{ backgroundColor: "red" }}
          title={"Payments Stopped"}
        />
      );
      pageEmergency = true;
    }
    if (props.account.data.account.bankruptcy) {
      currentHeaderTags.push(
        <GenericStatusTag
          customStyles={{ backgroundColor: "red" }}
          title={"Bankruptcy"}
        />
      );
      pageEmergency = true;
    }
  }
  return (
    <LeftSidebar>
      <StandardHeader
        title={pageTitle}
        headerTags={currentHeaderTags}
        pageEmergency={pageEmergency}
        appPath={"Account Manager"}
        rightSideToolBar={<ToolBar handleRefreshClick={RefreshPage} />}
      />
      <Divider className="mb-3 mt-3" />
      { accountFound ? ( 
      <Grid container spacing={3} xs={12} sm={12} md={12} lg={12}>
        {/*               Left Column              */}
        <Grid item xs={12} lg={4} xl={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <SelectedAccountInfoCard />
            </Grid>
          </Grid>
        </Grid>
        {/*               Right Column              */}
        <Grid item xs={12} lg={8} xl={9}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={4}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                  <CurrentBalance />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <AmmountPastDue />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <LastPaymentInfo />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <SelectedAccountLocationSummaryCard refreshPage={RefreshPage} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <LatePaymentCount />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <BadCardCount />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <BadCheckCount />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <UserAndAccountControls />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <AccountBillsTable
              //accountUuid={props.account.newInitialAccount.uuid}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <AccountPaymentsTable
              //accountUuid={props.account.newInitialAccount.uuid}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <OtherTablesButtonBox />
          </Grid>
        </Grid>
        {/*               Notes at bottom              */}
        <Grid item xs={12} lg={12} xl={12} component={Paper}>
          <Grid item lg={12} md={12} sm={12}>
            <Grid container spacing={3}>
              <Grid item lg={12} md={12} sm={12}>
                <Typography
                  className={classes.tableTitle}
                  component={"span"}
                  ml={4}
                  variant="h5"
                  id="tableTitle"
                >
                  Account Notes
                </Typography>
              </Grid>
              <Grid item lg={12} md={12} sm={12}>
                <Divider className="mb-2 mt-2" my={2} />
                <ChatWindow />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/*               Different Form Dialogs              */}
        <Dialog
          open={LedgerTableOpen}
          TransitionComponent={Transition}
          fullWidth={true}
          maxWidth={"xl"}
          keepMounted
          onClose={handleLedgerTableClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <AccountLedgerLogTable />
          </DialogContent>
        </Dialog>
        <Dialog
          open={WorkOrdersTableOpen}
          TransitionComponent={Transition}
          fullWidth={true}
          maxWidth={"xl"}
          keepMounted
          onClose={handleWorkOrdersTableClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <UnitWorkOrdersTable unitWorkOrderTablesIsOpen={WorkOrdersTableOpen} />
          </DialogContent>
        </Dialog>

        <Dialog
          open={MeterReadingsTableOpen}
          TransitionComponent={Transition}
          fullWidth={true}
          maxWidth={"xl"}
          keepMounted
          onClose={handleMeterReadingsTableClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <SelectedAccountMeterCard callbackModal={callbackModal} isOpen={MeterReadingsTableOpen} selectedMeter={selectedMeter} />
          </DialogContent>
        </Dialog>
        <Dialog
          open={ContactAccountHolderCardOpen}
          TransitionComponent={Transition}
          fullWidth={"lg"}
          maxWidth={"lg"}
          keepMounted
          onClose={handleContactAccountHolderClickClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <AccountEmailPopupEditor onCloseFormMethod={handleContactAccountHolderClickClose} />
          </DialogContent>
        </Dialog>

        <Dialog
          open={AccountFilesTableOpen}
          TransitionComponent={Transition}
          fullWidth={true}
          maxWidth={"xl"}
          keepMounted
          onClose={handleAccountFilesTableClickClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <AccountFilesTable />
          </DialogContent>
        </Dialog>
        <Dialog
          open={UserProfileCardOpen}
          TransitionComponent={Transition}
          fullWidth={"lg"}
          maxWidth={"lg"}
          keepMounted
          onClose={handleUserProfileClickClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <UserDetailSummaryCard />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <UserPaymentMethodTypesTable accountUuid={currentAccountUuid}/>
              </Grid>
              {/* Not used any longer, added one card per linked user login
                <Grid item xs={12} sm={12} md={12} lg={12}>
                <UserRelatedAccountPreviewsTable />
              </Grid>
      */}
            </Grid>
          </DialogContent>
        </Dialog>
      </Grid>) : (<div>
      <Typography variant="h2" gutterBottom>
                     Unable to locate account
                   </Typography>
      </div>)

      }
    </LeftSidebar>
  );
};

// Component Properties
AccountManagerPage.propTypes = {
  // Store objects:
  user: PropTypes.object.isRequired,
  userProfiles: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  billing: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  payments: PropTypes.object.isRequired,
  propertyUtilities: PropTypes.object.isRequired,
  bills: PropTypes.object.isRequired,
  notes: PropTypes.object.isRequired,
  files: PropTypes.object.isRequired,
  meters: PropTypes.object.isRequired,
  workOrders: PropTypes.object.isRequired,
  arReceipts: PropTypes.object.isRequired,

  getAccountInfoByAccountUUID: PropTypes.func.isRequired,
  hydrateAccount: PropTypes.func.isRequired,
  getUserPayments: PropTypes.func.isRequired,
  getUsersByAccount: PropTypes.func.isRequired,
  getAccountBalance: PropTypes.func.isRequired,
  getAllGlCodes: PropTypes.func.isRequired,
  getAllCustomerTypes: PropTypes.func.isRequired,
  getAllLetterTypes: PropTypes.func.isRequired,
  getAllCustomerStatuses: PropTypes.func.isRequired,
  getAllNoteTopicTypes: PropTypes.func.isRequired,
  getAccountDelinquenciesByAccountID: PropTypes.func.isRequired,
  getAccountTimesLateByAccountUuid: PropTypes.func.isRequired,
  getAccountLedger: PropTypes.func.isRequired,
  setNotes: PropTypes.func.isRequired,
  getAccountNotesByAccountUUID: PropTypes.func.isRequired,
  getAllAccountStatusCodes: PropTypes.func.isRequired,
  lookupAccountMeterHistory: PropTypes.func.isRequired,
  getAllMeterReadTypes: PropTypes.func.isRequired,
  getAllPaymentMethodTypes: PropTypes.func.isRequired,
  getAccountByAccountNumber: PropTypes.func.isRequired,
  setSelectedUserProfile: PropTypes.func.isRequired,
  getAssociatedAutoPays: PropTypes.func.isRequired,
  getPropertyUtilityByPropertyId: PropTypes.func.isRequired,
};

// Component State
function IndividualAccountPageState(state) {
  return {
    user: state.user,
    apl: state.apl,
    files: state.files,
    billing: state.billing,
    account: state.account,
    payments: state.payments,
    arReceipts: state.arReceipts,
    bills: state.bills,
    userProfiles: state.userProfiles,
    notes: state.notes,
    workOrders: state.workOrders,
    meters: state.meters,
    propertyUtilities: state.propertyUtilities

  };
}
export default connect(IndividualAccountPageState, {
  setNotes,
  getAllNoteTopicTypes,
  getAccountLedger,
  getAccountInfoByAccountUUID,
  updateCurrentAccountInDB,
  updateSelectedAccount,
  hydrateAccount,
  getUserPayments,
  getUsersByAccount,
  getAccountBalance,
  getAllGlCodes,
  getAccountDelinquenciesByAccountID,
  getAccountNotesByAccountUUID,
  getAllAccountStatusCodes,
  getAllCustomerTypes,
  getAllLetterTypes,
  getAllCustomerStatuses,
  lookupAccountMeterHistory,
  getAllMeterReadTypes,
  getAllPaymentMethodTypes,
  getAccountByAccountNumber,
  getAccountTimesLateByAccountUuid,
  setSelectedUserProfile,
  getAssociatedAutoPays,
  getPropertyUtilityByPropertyId,
})(AccountManagerPage);
