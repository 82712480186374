import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormHelperText from "@material-ui/core/FormHelperText";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import { updateSetting } from "../api/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "25ch",
  },
}));

function AdvancedSettings(props) {
  const classes = useStyles();

  const settings = props.setting.settings.items;
  const isAdmin = !props.user.details.isSuperUser;

  //Create Var for each setting
  let notificationPropertyOutage, notificationSendFrom;

  //Loop through settings and set state
  for (let setting of settings) {
    if (setting.name == "notificationSendFrom") {
      notificationSendFrom = setting;
    }
    if (setting.name == "notificationPropertyOutage") {
      notificationPropertyOutage = setting;
    }
  }

  const [state, setState] = useState({
    notificationPropertyOutage: notificationPropertyOutage,
    notificationSendFrom: notificationSendFrom,
  });

  //Dynamic change event to handle all inputs
  const handleChange = (evt) => {
    let value = evt.target.value;
    if (evt.target.type == "checkbox") value = evt.target.checked;

    const newState = {
      ...state,
      [evt.target.name]: {
        ...state[evt.target.name],
        value: value,
      },
    };
    setState(newState);
  };

  const handleUpdate = async (evt) => {
    try {
      let response = await props.updateSetting(evt.target.id, {
        value: state[evt.target.name].value,
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      {props.setting.isLoading ? (
        <CircularProgress />
      ) : (
        <Button
          disabled={props.setting.isLoading || isAdmin}
          size="small"
          variant="contained"
          color="secondary"
        >
          Save
        </Button>
      )}
      <div className={classes.root}>
        {props.setting.settings.items.length > 0 && (
          <div>
            <h3>Tools</h3>
            <div>
              <Button
                size="small"
                disabled={true}
                variant="contained"
                color="secondary"
              >
                Force Auto Pays
              </Button>
              <FormHelperText>
                This will force the auto-pay process usually run at night in the
                daily process
              </FormHelperText>
              <Button
                size="small"
                disabled={true}
                variant="contained"
                color="secondary"
              >
                Force Late Fees
              </Button>
              <FormHelperText>
                This will force the late fee process usually run at night in the
                daily process
              </FormHelperText>
              <Button
                size="small"
                disabled={true}
                variant="contained"
                color="secondary"
              >
                Generate API Key
              </Button>
              <FormHelperText>Not currently available.</FormHelperText>
            </div>
            <FormHelperText>
              This is the email that any recieptient of an email in our system
              will show "FROM"
            </FormHelperText>
          </div>
        )}
      </div>
    </div>
  );
}
AdvancedSettings.propTypes = {
  // Store objects:
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  billing: PropTypes.object.isRequired,
  // Store object functions:
  getAllBillingDashboardInfo: PropTypes.func.isRequired,
  getBillInProgressDraft: PropTypes.func.isRequired,
  updateBillingWizardBillInProgress: PropTypes.func.isRequired,
  updateBillingWizardSelectedProperty: PropTypes.func.isRequired,
  resetBillingObject: PropTypes.func.isRequired,
  getBillingBatchById: PropTypes.func.isRequired,
};

// Component State
function AdvancedSettingsState(state) {
  return {
    setting: state.setting,
    user: state.user,
  };
}

export default connect(AdvancedSettingsState, { updateSetting })(
  AdvancedSettings
);
