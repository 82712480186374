// Imports
import axios from "axios";
import { GET, POST, PUT, DEL } from "../../genericAPI/api/actions";
import { setSnackbar } from "../../snackbar/api/snackbar";

// App Imports
require("../../../setup/config/apiConfig");

// Actions Types
export const SET_PAYMENT_LIST = "PAYMENT/SET_PAYMENT_LIST";
export const ADD_PAYMENT_TO_PAYMENT_LIST =
  "PAYMENT/ADD_PAYMENT_TO_PAYMENT_LIST";
export const PAYMENT_GET_ACCOUNT_PAYMENTS_REQUEST =
  "PAYMENT_GET_ACCOUNT_PAYMENTS_REQUEST";
export const PAYMENT_GET_ACCOUNT_PAYMENTS_RESPONSE =
  "PAYMENT_GET_ACCOUNT_PAYMENTS_RESPONSE";

export const PAYMENT_MAKE_ACCOUNT_PAYMENT_REQUEST =
  "PAYMENT_MAKE_ACCOUNT_PAYMENT_REQUEST";
export const PAYMENT_MAKE_ACCOUNT_PAYMENT_RESPONSE =
  "PAYMENT_MAKE_ACCOUNT_PAYMENT_RESPONSE";

export const PAYMENT_GET_ALL_ACCOUNT_PAYMENTS_REQUEST =
  "PAYMENT_GET_ALL_ACCOUNT_PAYMENTS_REQUEST";
export const PAYMENT_GET_ALL_ACCOUNT_PAYMENTS_RESPONSE =
  "PAYMENT_GET_ALL_ACCOUNT_PAYMENTS_RESPONSE";
export const PAYMENT_GET_ALL_ACCOUNT_PAYMENTS_FAILURE =
  "PAYMENT_GET_ALL_ACCOUNT_PAYMENTS_FAILURE";

export const SET_AUTO_PAYMENT_LIST = "SET_AUTO_PAYMENT_LIST";

export const PAYMENT_GET_ACCOUNT_PAYMENTS_FAILURE = "PAYMENT_GET_ACCOUNT_PAYMENTS_FAILURE";
export const PAYMENT_ACCOUNT_PAYMENTS_RESET = "PAYMENT_GET_ACCOUNT_PAYMENTS_RESET";

export const PAYMENT_UPDATE_NEW_ACCOUNT_PAYMENT_IN_PROGRESS = "PAYMENT_UPDATE_NEW_ACCOUNT_PAYMENT_IN_PROGRESS";

export const PAYMENT_UPDATE_NEW_ACCOUNT_PAYMENT_IN_PROGRESS_ERRORS = "PAYMENT_UPDATE_NEW_ACCOUNT_PAYMENT_IN_PROGRESS_ERRORS";

export const PAYMENT_UPDATE_ACCOUNT_EXISTING_PAYMENT_REQUEST = "PAYMENT_UPDATE_ACCOUNT_EXISTING_PAYMENT_REQUEST";
export const PAYMENT_UPDATE_ACCOUNT_EXISTING_PAYMENT_RESPONSE = "PAYMENT_UPDATE_ACCOUNT_EXISTING_PAYMENT_RESPONSE";
export const ADD_ACCOUNTS_TO_LIST = "ADD_ACCOUNTS_TO_LIST";
export const UPDATE_BULK_CHECK = "UPDATE_BULK_CHECK";
export const UPDATE_BULK_CHECK_ACCOUNTS = "UPDATE_BULK_CHECK_ACCOUNTS";
export const RESET_BULK_CHECK_ENTRY = "RESET_BULK_CHECK_ENTRY";

export const GET_BULK_CHECK_PAYMENT_DAILY_SUMMARY_REQ = "GET_BULK_CHECK_PAYMENT_DAILY_SUMMARY_REQ";
export const GET_BULK_CHECK_PAYMENT_DAILY_SUMMARY_RESP = "GET_BULK_CHECK_PAYMENT_DAILY_SUMMARY_RESP";

export const ADD_SELECTED_PM_PACKET_ACCOUNTS_TO_CHECK_REQUEST = "ADD_SELECTED_PM_PACKET_ACCOUNTS_TO_CHECK_REQUEST";
export const ADD_SELECTED_PM_PACKET_ACCOUNTS_TO_CHECK_RESPONSE = "ADD_SELECTED_PM_PACKET_ACCOUNTS_TO_CHECK_RESPONSE";

export const SET_SELECTED_PAYMENT = "SET_SELECTED_PAYMENT";


export const PAYMENT_GET_SELECTED_PAYMENT_LINE_ITEMS_REQUEST = "PAYMENT_GET_SELECTED_PAYMENT_LINE_ITEMS_REQUEST";
export const PAYMENT_GET_SELECTED_PAYMENT_LINE_ITEMS_RESPONSE = "PAYMENT_GET_SELECTED_PAYMENT_LINE_ITEMS_RESPONSE";
export const PAYMENT_GET_SELECTED_PAYMENT_LINE_ITEMS_FAILURE = "PAYMENT_GET_SELECTED_PAYMENT_LINE_ITEMS_FAILURE";

export const GET_ACCOUNT_PAYMENT_PLANS_REQUEST = "GET_ACCOUNT_PAYMENT_PLANS_REQUEST";
export const GET_ACCOUNT_PAYMENT_PLANS_RESPONSE = "GET_ACCOUNT_PAYMENT_PLANS_RESPONSE";
export const GET_ACCOUNT_PAYMENT_PLANS_FAILURE = "GET_ACCOUNT_PAYMENT_PLANS_FAILURE";

export const REVERSE_ACCOUNT_PAYMENTS_REQUEST = "REVERSE_ACCOUNT_PAYMENTS_REQUEST";
export const REVERSE_ACCOUNT_PAYMENTS_RESPONSE = "REVERSE_ACCOUNT_PAYMENTS_RESPONSE";
export const REVERSE_ACCOUNT_PAYMENTS_FAILURE = "REVERSE_ACCOUNT_PAYMENTS_FAILURE";

export const CREATE_PAYMENT_PLANS_REQUEST = "CREATE_PAYMENT_PLANS_REQUEST";
export const CREATE_PAYMENT_PLANS_RESPONSE = "CREATE_PAYMENT_PLANS_RESPONSE";
export const CREATE_PAYMENT_PLANS_FAILURE = "CREATE_PAYMENT_PLANS_FAILURE";

export const CREATE_AUTO_PAYMENTS_REQUEST = "CREATE_AUTO_PAYMENTS_REQUEST";
export const CREATE_AUTO_PAYMENTS_RESPONSE = "CREATE_AUTO_PAYMENTS_RESPONSE";
export const CREATE_AUTO_PAYMENTS_FAILURE = "CREATE_AUTO_PAYMENTS_FAILURE";

export const UPDATE_AUTO_PAYMENTS_REQUEST = "UPDATE_AUTO_PAYMENTS_REQUEST";
export const UPDATE_AUTO_PAYMENTS_RESPONSE = "UPDATE_AUTO_PAYMENTS_RESPONSE";
export const UPDATE_AUTO_PAYMENTS_FAILURE = "UPDATE_AUTO_PAYMENTS_FAILURE";

export const GET_AUTO_PAYMENTS_REQUEST = "GET_AUTO_PAYMENTS_REQUEST";
export const GET_AUTO_PAYMENTS_RESPONSE = "GET_AUTO_PAYMENTS_RESPONSE";
export const GET_AUTO_PAYMENTS_FAILURE = "GET_AUTO_PAYMENTS_FAILURE";


export const UPDATE_PAYMENT_PLANS_REQUEST = "UPDATE_PAYMENT_PLANS_REQUEST";
export const UPDATE_PAYMENT_PLANS_RESPONSE = "UPDATE_PAYMENT_PLANS_RESPONSE";
export const UPDATE_PAYMENT_PLANS_FAILURE = "UPDATE_PAYMENT_PLANS_FAILURE";


export const DELETE_PAYMENT_PLANS_REQUEST = "DELETE_PAYMENT_PLANS_REQUEST";
export const DELETE_PAYMENT_PLANS_RESPONSE = "DELETE_PAYMENT_PLANS_RESPONSE";
export const DELETE_PAYMENT_PLANS_FAILURE = "DELETE_PAYMENT_PLANS_FAILURE";

export const DELETE_AUTO_PAYMENTS_REQUEST = "DELETE_AUTO_PAYMENTS_REQUEST";
export const DELETE_AUTO_PAYMENTS_RESPONSE = "DELETE_AUTO_PAYMENTS_RESPONSE";
export const DELETE_AUTO_PAYMENTS_FAILURE = "DELETE_AUTO_PAYMENTS_FAILURE";

export const UPDATE_ACCOUNT_PAYMENT_PLANS_STATE = "UPDATE_ACCOUNT_PAYMENT_PLANS_STATE";

// Actions
export function setPayments(data) {
  return { type: SET_PAYMENT_LIST, data };
}

export function setSelectedPayment(selectedPayment) {
  return { type: SET_SELECTED_PAYMENT, selectedPayment };
}

export function setAutoPayments(data) {
  return { type: SET_AUTO_PAYMENT_LIST, data }
}

export function updateNewAccountPaymentInProgress(newPaymentData) {
  return {
    type: PAYMENT_UPDATE_NEW_ACCOUNT_PAYMENT_IN_PROGRESS,
    newPaymentData,
  };
}
export function updateNewAccountPaymentErrors(newErrors) {
  return {
    type: PAYMENT_UPDATE_NEW_ACCOUNT_PAYMENT_IN_PROGRESS_ERRORS,
    newErrors,
  };
}

export function addNewPaymentToList(payment) {
  return { type: ADD_PAYMENT_TO_PAYMENT_LIST, payment };
}

export function updateBulkCheck(newBulkCheck) {
  return { type: UPDATE_BULK_CHECK, newBulkCheck };
}

export function addAccountsSelectedToCheck(accounts) {
  return { type: ADD_ACCOUNTS_TO_LIST, accounts };
}
export function resetBulkCheckEntry() {
  return { type: RESET_BULK_CHECK_ENTRY };
}

export function updateLocalBulkCheckAccountsItemsState(accounts) {
  return { type: UPDATE_BULK_CHECK_ACCOUNTS, accounts };
}

export function updateAccountPaymentPlansItemsState(items) {
  return { type: UPDATE_ACCOUNT_PAYMENT_PLANS_STATE, items };
}

export function getAccountPaymentPlans(accountUuid) {
  return GET("paymentplan/account", accountUuid, GET_ACCOUNT_PAYMENT_PLANS_REQUEST, GET_ACCOUNT_PAYMENT_PLANS_RESPONSE, GET_ACCOUNT_PAYMENT_PLANS_FAILURE);
}
export function createAccountPaymentPlan(paymentPlan, accountUuid) {
  return POST(`paymentplan`, accountUuid, paymentPlan, CREATE_PAYMENT_PLANS_REQUEST, CREATE_PAYMENT_PLANS_RESPONSE, CREATE_PAYMENT_PLANS_FAILURE);
}
export function updateAccountPaymentPlan(paymentPlan, paymentPlanUuid) {
  return PUT("paymentplan", paymentPlanUuid, paymentPlan, UPDATE_PAYMENT_PLANS_REQUEST, UPDATE_PAYMENT_PLANS_RESPONSE, UPDATE_PAYMENT_PLANS_FAILURE);
}
export function deleteAccountPaymentPlan(paymentPlanUuid) {
  return DEL("paymentplan", paymentPlanUuid, "", DELETE_PAYMENT_PLANS_REQUEST, DELETE_PAYMENT_PLANS_RESPONSE, DELETE_PAYMENT_PLANS_FAILURE
  );
}

export function getPaymentLineItems(paymentId) {
  return GET("ARReceipt/PaymentId", paymentId, PAYMENT_GET_SELECTED_PAYMENT_LINE_ITEMS_REQUEST, PAYMENT_GET_SELECTED_PAYMENT_LINE_ITEMS_RESPONSE, PAYMENT_GET_SELECTED_PAYMENT_LINE_ITEMS_FAILURE);
}


export function getAllAccountPayments(
  page,
  pageLimit,
  accountUuid,
  isLoading = true,
  isReset = false
) {
  return (dispatch) => {
    dispatch({
      type: PAYMENT_GET_ALL_ACCOUNT_PAYMENTS_REQUEST,
      isLoading: isLoading,
      isReset: isReset,
    });
    let queryParams = {
      accountUuid,
      page: page,
      pageLimit: pageLimit,
    };
    return axios
      .get("/payments/account", { params: queryParams })
      .then((response) => {
        dispatch({
          type: PAYMENT_GET_ALL_ACCOUNT_PAYMENTS_RESPONSE,
          isLoading: false,
          data: response.data,
          isReset: isReset,
        });
      })
      .catch((error) => {
        dispatch({
          type: PAYMENT_GET_ALL_ACCOUNT_PAYMENTS_FAILURE,
          isLoading: false,
          error: error,
        });
      });
  };
}

export function getBulkCheckDailyPaymentsSummary(isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: GET_BULK_CHECK_PAYMENT_DAILY_SUMMARY_REQ,
      isLoading: isLoading,
    });
    return axios
      .get("/payment/bulkCheckSummary")
      .then((response) => {
        dispatch({
          type: GET_BULK_CHECK_PAYMENT_DAILY_SUMMARY_RESP,
          isLoading: false,
          bulkCheckCurrentSummary: response.data,
        });
      })
      .catch((error) => {
        console.log("ERROR");
        console.log(error);
        dispatch({
          type: GET_BULK_CHECK_PAYMENT_DAILY_SUMMARY_RESP,
          isLoading: false,
          error: error,
        });
      });
  };
}

export function addSelectedPmBillAccountsSelectedToCheck(
  pmBills,
  isLoading = true
) {
  return (dispatch) => {
    dispatch({
      type: ADD_SELECTED_PM_PACKET_ACCOUNTS_TO_CHECK_REQUEST,
      isLoading: isLoading,
    });
    return axios
      .post("/account/fullpreview/accountByPmPacket", pmBills)
      .then((response) => {
        dispatch({
          type: ADD_SELECTED_PM_PACKET_ACCOUNTS_TO_CHECK_RESPONSE,
          isLoading: false,
          data: response.data,
        });
      })
      .catch((error) => {
        console.log("ERROR");
        console.log(error);
        dispatch({
          type: ADD_SELECTED_PM_PACKET_ACCOUNTS_TO_CHECK_RESPONSE,
          isLoading: false,
          error: error,
        });
      });
  };
}
// Get Associated Auto Pays for account
export function getAssociatedAutoPays(accountUuid){
  return(dispatch) =>{
    dispatch({
      type: GET_AUTO_PAYMENTS_REQUEST
    });
    return axios
    .get(`/autopaytoaccount/${accountUuid}`)
    .then((response) => {
      let error = "";
        if (response.data.error) {
          error = response.data;
        }
      dispatch({
        type: GET_AUTO_PAYMENTS_RESPONSE,
        accountAutoPayments: response.data || [],
        error: error,
      });  
      return response.data;    
    })
    .catch((error) => {
      dispatch({
        type: GET_AUTO_PAYMENTS_FAILURE,
        error: error
      });
    });
  }
}
// Get all account payments made
export function getUserPayments(target, isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: PAYMENT_GET_ACCOUNT_PAYMENTS_REQUEST,
      isLoading: isLoading,
    });
    return axios
      .get("/payment/account/" + target)
      .then((response) => {
        let error = "";
        if (response.data.error) {
          error = response.data;
        }
        // Update/Check if user object is the obj state
        const updatedUser = JSON.parse(window.localStorage.getItem("user"));
        dispatch(
          setPayments({ items: response.data, toUpdate: [], toDelete: [] })
        );
        if (updatedUser) {
          //Updating the bills obj in state
          dispatch({
            type: PAYMENT_GET_ACCOUNT_PAYMENTS_RESPONSE,
            isLoading: false,
            error: error,
            data: response.data,
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: PAYMENT_GET_ACCOUNT_PAYMENTS_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: PAYMENT_GET_ACCOUNT_PAYMENTS_FAILURE,
          isLoading: false,
          error: error,
        });
      });
  };
}

export function reverseAccountPayments(payments) {
  let toSend = payments;
  if (!Array.isArray(toSend)) {
    toSend = [payments];
  }
  return POST("/reverse-payments-by-uuid", "", { payments: toSend }, REVERSE_ACCOUNT_PAYMENTS_REQUEST, REVERSE_ACCOUNT_PAYMENTS_RESPONSE, REVERSE_ACCOUNT_PAYMENTS_FAILURE);
}
export function updateAutoPayments(autoPayments) {
  let toSend = autoPayments;
  if (!Array.isArray(toSend)) {
    toSend = [autoPayments];
  }
  return POST("/update-autopayments", "", { autoPayments: toSend }, UPDATE_AUTO_PAYMENTS_REQUEST, UPDATE_AUTO_PAYMENTS_RESPONSE, UPDATE_AUTO_PAYMENTS_FAILURE);
}
// Make a new account payment
// NOTE: Expects {accountUuid, typeName, paymentSubmissionPayload (achUUID or ccUUID or new 1 time ach or new one time cc transaction)}
export function createNewPaymentWithExternalServices(paymentSubmissionPayload) {
  return (dispatch) => {
    dispatch({
      type: PAYMENT_MAKE_ACCOUNT_PAYMENT_REQUEST,
      isLoading: true,
    });
    return axios.post("/payment/handlePaymentSubmission", paymentSubmissionPayload).then((response) => {

      console.log(response);
      // Update/Check if user object is the obj state
      const updatedUser = JSON.parse(window.localStorage.getItem("user"));
      if (updatedUser) {
      

        if(response.status == 200) {
          // dispatch(setSnackbar(true, "success", "Payment Submitted"));

        if (response.items && response.transactionResult) {
          dispatch(setSnackbar(true, "success", "Payment Submitted"),{
            type: PAYMENT_MAKE_ACCOUNT_PAYMENT_RESPONSE,
            isLoading: false,
            error: response.error || null,
          });
        }
        else {
          dispatch({
            type: PAYMENT_MAKE_ACCOUNT_PAYMENT_RESPONSE,
            isLoading: false,
            error: response.error || null,
          });
        }
        return response;
      } else {
        // if no user is found then clear object because its an unauthorized access
        dispatch({
          type: PAYMENT_MAKE_ACCOUNT_PAYMENT_RESPONSE,
          isLoading: false,
          error: "ERROR! NO USER LOGGED IN!",
        });
      }
   


      }
    })
      .catch((error) => {
        dispatch({
          type: PAYMENT_MAKE_ACCOUNT_PAYMENT_RESPONSE,
          isLoading: false,
          error: error,
        });
        if(error.response.data?.AuthNetResult) {
          dispatch(setSnackbar(true, "error", `Transaction failure: ${error.response.data.AuthNetResult.message}`));
        }
        else {
          dispatch(setSnackbar(true, "error", `Transaction failure: ${error.response.data.transactionResult.result}`));

        }
        return error;
      });
  };
}
// Make a new Auto Pay
export function createNewAutoPay(autoPaySubmissionPayload) {
  return (dispatch) => {
    dispatch({
      type: CREATE_AUTO_PAYMENTS_REQUEST,
      isLoading: true,
    });
    return axios.post("/autopay", autoPaySubmissionPayload)
    .then((response) => {
      // Update/Check if user object is the obj state
      let error = "";
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }
        // Update/Check if user object is the obj state
        const updatedUser = JSON.parse(window.localStorage.getItem("user"));
        if (updatedUser) {
          dispatch(setSnackbar(true, "success", `"Auto Payent Submitted"`),{
            type: CREATE_AUTO_PAYMENTS_RESPONSE,
            isLoading: false,
            error: error,
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: CREATE_AUTO_PAYMENTS_FAILURE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          });
        }
    })
      .catch((error) => {
        dispatch(
          setSnackbar(true, "error", `${error.response.data.message || "Auto Pay Error"}`),{
          type: CREATE_AUTO_PAYMENTS_FAILURE,
          isLoading: false,
          error: error.response.data.message,
          
        });
      });
  };
}
export function deleteAutoPay(uuid) {
  return dispatch => {
    dispatch({
      type: DELETE_AUTO_PAYMENTS_REQUEST,
      error: null,
      isLoading: true
    })
   
      return axios.delete("/autopay/" + uuid).then(response => {
        let error = '';
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }
        dispatch({
          type: DELETE_AUTO_PAYMENTS_RESPONSE,
          error: error
        })
      }).catch((error) => {
        dispatch({
          type: DELETE_AUTO_PAYMENTS_FAILURE,
          isLoading: false,
          error: error.response.data.message
        });
      });
  
  };
}
// Make a new account payment
// NOTE: Expects {accountUuid, typeName, paymentSubmissionPayload (achUUID or ccUUID or new 1 time ach or new one time cc transaction)}
export function createNewAccountPayment(accountUUID, paymentSubmissionPayload) {
  const makeAccountPaymentSubmission = {
    accountUUID: accountUUID,
    transactionType: paymentSubmissionPayload.paymentType,
    paymentMethod: paymentSubmissionPayload,
  };
  return (dispatch) => {
    dispatch({
      type: PAYMENT_MAKE_ACCOUNT_PAYMENT_REQUEST,
      isLoading: true,
    });
    return axios
      .post("/payment/handlePaymentSubmission", makeAccountPaymentSubmission)
      .then((response) => {
        let error = "";
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }
        // Update/Check if user object is the obj state
        const updatedUser = JSON.parse(window.localStorage.getItem("user"));
        if (updatedUser) {
          dispatch(addNewPaymentToList(response.data));
          dispatch({
            type: PAYMENT_MAKE_ACCOUNT_PAYMENT_RESPONSE,
            isLoading: false,
            error: error,
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: PAYMENT_MAKE_ACCOUNT_PAYMENT_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: PAYMENT_MAKE_ACCOUNT_PAYMENT_RESPONSE,
          isLoading: false,
          error: error,
        });
      });
  };
}

export function pushUpdateToCurrentlySelectedAccountPaymentsList(payment) {
  return (dispatch) => {
    dispatch({
      type: PAYMENT_UPDATE_ACCOUNT_EXISTING_PAYMENT_REQUEST,
    });
    return axios
      .put("/payment/" + payment.uuid, payment)
      .then((response) => {
        let error = "";
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }
        // Update/Check if user object is the obj state
        const updatedUser = JSON.parse(window.localStorage.getItem("user"));
        if (updatedUser) {
          dispatch({
            type: PAYMENT_UPDATE_ACCOUNT_EXISTING_PAYMENT_RESPONSE,
            error: error,
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: PAYMENT_UPDATE_ACCOUNT_EXISTING_PAYMENT_RESPONSE,
            error: "ERROR! NO USER LOGGED IN!",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: PAYMENT_UPDATE_ACCOUNT_EXISTING_PAYMENT_RESPONSE,
          error: error,
        });
      });
  };
}
