import React, { useEffect } from "react";
import styled from "styled-components/macro";

import Autocomplete from "@material-ui/lab/Autocomplete"
import { setSnackbar } from "../../snackbar/api/snackbar";
import {
  Checkbox, Grid,
  ButtonGroup, IconButton, Breadcrumbs as MuiBreadcrumbs, Divider as MuiDivider, Paper as MuiPaper, 
  FormControlLabel, MenuItem, TextField as MuiTextField, Button as MuiButton,
  FormControl, InputLabel, Select, 
  Card as MuiCard,
  CardContent,
  CircularProgress as MuiCircularProgress,
} from "@material-ui/core";

//import OutlinedInput from '@material-ui/core/OutlinedInput';
import {
  useHistory} from "react-router-dom";
//Material UI Icons
import _ from "lodash";


//Material UI Components and Functions
import { makeStyles } from '@material-ui/core/styles';


import { spacing } from "@material-ui/system";

import {
  EditableTable, 
  newConditionalOnSelectButton
} from "../../../components/EditableTable";

import SearchIcon from '@material-ui/icons/Search';

// Redux Components
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { searchAccountsByFieldAndValue, updateFilters, searchForAccountsByFilters } from '../api/actions';
import { getAllPropertyPreviews } from '../../apl/api/actions';
import { addAccountsSelectedToCheck } from '../../payments/api/actions';
import { deleteAccounts } from "../../account/api/actions";
import StatusTag from "../../apl/components/StatusTag";
const Button = styled(MuiButton)(spacing);
const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)(spacing);
const DashboardToolBarButton = styled(Button)`
padding: 4px;
min-width: 0;
svg {
    width: 2em;
    height: 2em;
}
`;

const CircularProgress = styled(MuiCircularProgress)(spacing);

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  searchBarGrid: {
    //paddingBottom: "10px",
    fontSize: "90%",
    height: "5%"
  },
  searchDropDownButton: {
    height: "90%"
  },
  percentDif: {
    textAlign: 'right',
    color: 'green'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  loadingIcon: {
    position: 'absolute', left: '50%', top: '50%',
    transform: 'translate(-50%, -50%)'
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  active: {
    fontWeight: "bold",
    color: "#f9a825",
    borderColor: "#f9a825",
  },
  inactive: {
    fontWeight: "bold",
  },
  transfer: {
    fontWeight: "bold",
  },
}));

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Spacer = styled.div`
  flex: 1 1 100%;
`;

//Editable Table Object Expects: {objectArray, fieldTitles(Array), fieldTypes(Array), fieldNames(Array), handleRowClickParentMethod}
// Method can take line items if they exist initially or starts as an empty array of nothing passed
function AccountPreviewsTable(props) {
  const dispatch = useDispatch();
  const [accountStatusQueryType, setAccountStatusQueryType] = React.useState(0);

  const classes = useStyles();
  const history = useHistory();

  // Filters
  const [customerType, setCustomerType] = React.useState(null);
  const [accountStatusType, setAccountStatusType] = React.useState("Active");
  const [searchQuery, setSearchQuery] = React.useState(""); // should be all thats needed to reduce the disconnects shown to only that accounts
  const [property, setProperty] = React.useState(null);
  const [propertyName, setPropertyName] = React.useState(null);
  const [accountBalanceOwedFilter, setAccountBalanceOwedFilter] = React.useState(false);

  // Table Data management
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [resetCache, setResetCachedData] = React.useState(true);
  const [focus, setFocus] = React.useState(false);
  const [minAccountObj, setMinAccountObj] = React.useState(props.minAccountObj || false);
  const [SearchOnTypeInput, setSearchOnTypeInput] = React.useState(props.SearchOnTypeInput);
  const [currentAccountDPreviews, setCurrentAccountDPreviews] = React.useState([]);
  
  const canDelete = props.user.details.email == process.env.REACT_APP_ADMIN_EMAIL ? true : false;

  const handleChangeRowsPerPage = (rowsPerPage) => {
    setPage(0);
    setResetCachedData(false);
    setRowsPerPage(rowsPerPage);
  };

  const handleChangePage = (page) => {
    setResetCachedData(false);
    setPage(page);
  };

  const onAccountStatusTypeSelected = (selection) => {
    setAccountStatusType(selection);
    setResetCachedData(true);
    setPage(0);
  }

  const onCustomerTypeSelected = (selection) => {
    setCustomerType(selection);
    setResetCachedData(true);
    setPage(0);
  }

  const handleSelectedPropertyChange = (event) => {
    setPage(0);
    setResetCachedData(true);
    if (event.target.value == "" || event.target.value == null) {
      setPropertyName(null);
    }
    else {
      var target = props.apl.data.propertyPreviews.find(x => x.uuid === event.target.value);
      setPropertyName(target.name);
    }
    setProperty(event.target.value);
  };


  // When the textbox changes
  const handleSearchTargetChange = (event) => {
    setSearchQuery(event.target.value);
    if (SearchOnTypeInput) {
      setPage(0);
      setResetCachedData(true);
      getAccountsWithQuery();
    }
  }
  const IsAbleToDeleteAccount = (account) => {
    console.log("account: ", account)
    return account.accountStatusId == 7 ? true : false;
  }

  const HandleDeleteAccount = (accounts)  => {
   
        props.deleteAccounts(accounts)
       .then(()=> {
        if(props.account.error == '' || !props.account.error){
      
           dispatch(setSnackbar(true, "success", `Deleted account.`))
          
          }else {
             dispatch(setSnackbar(true, "error", `Failed to delete account.`))
          }
      })
   
  }

  const getAccountsWithQuery = () => {
    const propertyUuid = property;
    const query = { page, rowsPerPage, customerType, searchQuery, propertyUuid, accountStatusType, resetCache, accountBalanceOwedFilter, minAccountObj };
    props.searchForAccountsByFilters(query);
  };

  const handleSearchTargetButtonClicked = () => {
    setPage(0);
    setResetCachedData(true);
    getAccountsWithQuery();
  }


  useEffect(() => {
    if (props.accountsSearch.data.accountPreviews.items) {
      if (((page + 1) * rowsPerPage > props.accountsSearch.data.accountPreviews.items.length) || resetCache) {
        getAccountsWithQuery();
      }
    }
  }, [page, property, rowsPerPage, customerType, accountStatusType, accountBalanceOwedFilter, minAccountObj]);

  const CurrentLookupTypeButton = () => {
    return (
      <IconButton onClick={handleSearchTargetButtonClicked} style={{ width: "50px", height: "50px" }}>
        <SearchIcon style={{ width: "30px", height: "30px" }} />
      </IconButton>
    );
  }
  const handleTargetAccountBalancesChange = (event) => {
    setAccountBalanceOwedFilter(!accountBalanceOwedFilter);
  }

  const setSearchFocus = () => {
    setFocus(true);
  }
  const unsetSearchFocus = () => {
    setFocus(false);
  }

  const AccountRowStatusStyling = (account) => {
    account.name = account.lastName;
    if (account.firstName != null && account.firstName.trim() != "") {
      account.name = account.firstName + ", " + account.lastName;
    }
    account.accountStatus = <><StatusTag type={"accountStatus"} isLoading={props.accountsSearch.isLoading} typeId={account.accountStatusId} showTitle={false} condensed={true} /></>
    if (props.isBulkCheckEntry) {
      if (account.stopPayment)
        account.accountStatus = <><StatusTag type={"accountStatus"} isLoading={props.accountsSearch.isLoading} typeId={account.accountStatusId} showTitle={false} condensed={true} />
          <StatusTag type={"Alert"} label={"Stopped Payment"} condensed={true} /></>
    }
    else {
      account.customerType = <StatusTag type={"customerType"} isLoading={props.accountsSearch.isLoading} typeId={account.customerTypeId} showTitle={false} condensed={true} />
    }
    return {}; // Returns an empty style as to not effect each row
  };

  // Table Stuff:      
  const fieldTitles = ["Acct #",
    "Status",
    "Type",
    "Name",
    "Address",
    "Balance",
    "Property",
    "Email",
    "Phone #"];

  const fieldTypes = ["label",
    "label",
    "label",
    "label",
    ["labelSmall", "labelSmall"],
    "moneyLabel",
    "label",
    "label",
    "phoneLabel"];

  const fieldNames = ["accountNum",
    "accountStatus",
    "customerType",
    "name",
    ["serviceAddress1", "serviceAddress2"],
    "balance",
    "propertyName",
    "email",
    "phone1"];
  // Must be the exact name of the properties you wanna show


  const bulkCheckFieldTitles = ["Acct #",
    "Status",
    "Property",
    "Name",
    "Address",
    "Balance"];

  const bulkCheckFieldTypes = ["label",
    "label",
    "label",
    "label",
    ["labelSmall", "labelSmall"],
    "moneyLabel",];

  const bulkCheckFieldNames = ["accountNum",
    "accountStatus",
    "propertyName",
    "name",
    ["serviceAddress1", "serviceAddress2"],
    "balance"]; // Must be the exact name of the properties you wanna show



  const onAccountSelected = (item) => {
    window.open("/accountmanager/" + item.accountNum);
  }

  const handleSelectOptionClick = (event, value) => {
    if (value && value.accountNum && value.uuid) {
      history.push({ pathname: "/accountmanager/" + value.accountNum });
      window.location.reload();
    }
  }

  const Filters = () => {
    const classes = useStyles();

    return (
      <Grid container spacing={6}>
        <Grid item lg={8} md={8} sm={8}>
          {!props.isAccountPage &&
            <>
              <FormControl fullWidth my={2}>
                <InputLabel id="propertyToBillDropDownLabel">Location{"  "}
                  {props.apl.isLoading &&
                    <CircularProgress size={15} m={0} />
                  }
                </InputLabel>
                {property == "" && props.apl.isLoading ?
                  <Select
                    labelId="propertyToBillDropDownLabel"
                    id="propertyToBillDropDown"
                    value={property}
                    className={classes.selectedPropertyLocationLabel}
                    onChange={handleSelectedPropertyChange}
                    fullWidth
                    disabled
                    my={2}>
                  </Select>
                  :
                  <Select
                    labelId="propertyToBillDropDownLabel"
                    id="propertyToBillDropDown"
                    value={property}
                    className={classes.selectedPropertyLocationLabel}
                    onChange={handleSelectedPropertyChange}
                    fullWidth
                    my={2}>
                    {props.apl.data.propertyPreviews.length > 0 ?
                      [
                        <MenuItem value={""}>All</MenuItem>,
                        props.apl.data.propertyPreviews.map((item, key) => {
                          return (
                            <MenuItem value={item.uuid} id={key}>{item.name}</MenuItem>
                          )
                        })
                      ]
                      :
                      [
                        <MenuItem value={-1}>No Properties Found</MenuItem>
                      ]
                    }
                  </Select>
                }
              </FormControl>
            </>
          }
        </Grid>
        <Grid item lg={6} md={6} sm={6}>
          <ButtonGroup
            color="primary"
            aria-label="outlined primary buwtton group"
          >
            <Button
              onClick={() => { onAccountStatusTypeSelected(null) }}
              className={
                accountStatusType == null
                  ? classes.active
                  : classes.inactive
              }
            >
              All
            </Button>
            <Button
              onClick={() => { onAccountStatusTypeSelected("Active") }}
              className={
                accountStatusType == "Active"
                  ? classes.active
                  : classes.inactive
              }
            >
              Active
            </Button>
            <Button
              onClick={() => { onAccountStatusTypeSelected("Inactive") }}
              className={
                accountStatusType == "Inactive"
                  ? classes.active
                  : classes.inactive
              }
            >
              Inactive
            </Button>
            <Button
              onClick={() => { onAccountStatusTypeSelected("Inactive D") }}
              className={
                accountStatusType == "Inactive D"
                  ? classes.active
                  : classes.inactive
              }
            >
              Inactive D
            </Button>
            <Button
              onClick={() => { onAccountStatusTypeSelected("Transfer") }}
              className={
                accountStatusType == "Transfer"
                  ? classes.active
                  : classes.transfer
              }
            >
              Transfer
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item lg={6} md={6} sm={6}>
          <ButtonGroup
            color="primary"
            aria-label="outlined primary button group"
          >
            <Button
              onClick={() => { onCustomerTypeSelected(null) }}
              className={
                customerType == null
                  ? classes.active
                  : classes.inactive
              }
            >
              All
            </Button>
            <Button
              onClick={() => { onCustomerTypeSelected("PM") }}
              className={
                customerType == "PM"
                  ? classes.active
                  : classes.inactive
              }
            >
              PM
            </Button>
            <Button
              onClick={() => { onCustomerTypeSelected("Tenant") }}
              className={
                customerType == "Tenant"
                  ? classes.active
                  : classes.inactive
              }
            >
              Tenant
            </Button>
            <Button
              onClick={() => { onCustomerTypeSelected("Common") }}
              className={
                customerType == "Common"
                  ? classes.active
                  : classes.inactive
              }
            >
              Common
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item lg={4} md={4} sm={4}>
          <FormControlLabel control={<Checkbox
            checked={accountBalanceOwedFilter}
            onClick={handleTargetAccountBalancesChange}
            inputProps={{ "aria-labelledby": "Owed Balance" }}
          />} label="With Owed Balance" />
        </Grid>
      </Grid>
    );
  };
  const addAccountsSelectedToCheck = (items) => {
    for (var x = 0; x < items.length; x++) {
      items[x].amountCovered = 0;
      if (items[x].balance > 0) {
        items[x].amountCovered = items[x].balance;
      }
    }
    props.addAccountsSelectedToCheck(items);
  };


  const ConditionalOnSelectButtons = [
    newConditionalOnSelectButton("Add Accounts To Check", () => { return (true) }, addAccountsSelectedToCheck)
  ];

  if (props.isAccountSearchBar && props.isAccountSearchBar == true) {
    return (
      <>
        <div className={classes.search}>
          <Autocomplete
            id="combo-box"
            options={props.accountsSearch.data.accountPreviews.items || []}
            getOptionLabel={(option) => {
              if (option && option.lastName) {
                let name = "";
                if (option.firstName && option.firstName != null) {
                  name = option.firstName;
                }
                if (option.lastName && option.lastName != null) {
                  name = name + " " + option.lastName;
                }
                return option.accountNum + " : " + name
              }
            }}
            onChange={handleSelectOptionClick}
            renderInput={(params) => (
              <TextField
                label="Accounts Search"
                {...params}
                onChange={handleSearchTargetChange}
                value={searchQuery}
                size={"small"}
                variant={"outlined"}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                my={2}
              />
            )}
          />
        </div>
      </>
    )
  }


  return (
    <Card mb={6} elevation={3}>
      <CardContent>
        <Grid container xs={12} alignItems="center" spacing={2}>
          <Grid item lg={12} md={12} sm={12}>
            {/* <AccountSearchBar onChange={handleSearchTargetChange}/> */}
            <TextField
            id={"searchTarget"}
            label="Search For Accounts"
            type={"text"}
            autoFocus={focus}
            onChange={handleSearchTargetChange}
            name={"searchTarget"}
            defaultValue={searchQuery}
            onClick={setSearchFocus}
            fullWidth
            autoComplete={"off"}
            my={2}
            size={"small"}
            variant={"outlined"}
            InputLabelProps={{
              shrink: true,
            }}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                // Do code here
                ev.preventDefault();
                setPage(0);
                setResetCachedData(true);
                getAccountsWithQuery();
              }
            }}
            InputProps={{ endAdornment: <CurrentLookupTypeButton /> }}
            helperText="Currently searching by account characteristics. Use Comma to seperate search values"
          >
          </TextField>
          </Grid>
        </Grid>
        {(props.isBulkCheckEntry && props.isBulkCheckEntry == true) ?
          <EditableTable
            Filters={<Filters />}
            readOnly={false}
            canAddItems={false}
            canSave={false}
            canDelete={false}
            isLoading={props.accountsSearch.isLoading}
            conditionalStylingMethod={AccountRowStatusStyling}
            currentActiveFilters={[customerType, accountStatusType, propertyName, accountBalanceOwedFilter ? "Account Balance Owed" : null]}
            // Functional methods of table
            handleRowClickParentMethod={onAccountSelected}
            // Properties of table  
            tableTitle={"Managed Accounts On File"}
            objectArray={props.accountsSearch.data.accountPreviews}
            fieldTitles={bulkCheckFieldTitles}
            fieldTypes={bulkCheckFieldTypes}
            fieldNames={bulkCheckFieldNames}
            customChangePageRowsEvent={handleChangeRowsPerPage}
            customHandleChangePageEvent={handleChangePage}
            defaultItemCount={rowsPerPage}
            conditionalOnSelectButtons={ConditionalOnSelectButtons}
            totalRowCount={props.accountsSearch.data.previewsSummary == null ? 0 : props.accountsSearch.data.previewsSummary.totalRows}
          />
          :
          <EditableTable
            Filters={<Filters />}
            readOnly={false}
            canAddItems={false}
            canSave={false}
            canDelete={canDelete}
            verifyDelete={true}
            manualItemDeletionMethod={HandleDeleteAccount}
            isLoading={props.accountsSearch.isLoading}
            conditionalStylingMethod={AccountRowStatusStyling}
            currentActiveFilters={[customerType, accountStatusType, propertyName, accountBalanceOwedFilter ? "Account Balance Owed" : null]}
            // Functional methods of table
            handleRowClickParentMethod={onAccountSelected}
            // Properties of table  
            tableTitle={"Managed Accounts On File"}
            objectArray={props.accountsSearch.data.accountPreviews}
            fieldTitles={fieldTitles}
            fieldTypes={fieldTypes}
            fieldNames={fieldNames}
            customChangePageRowsEvent={handleChangeRowsPerPage}
            customHandleChangePageEvent={handleChangePage}
            defaultItemCount={rowsPerPage}
            customOnDeleteValidationMethod={IsAbleToDeleteAccount}
            totalRowCount={props.accountsSearch.data.previewsSummary == null ? 0 : props.accountsSearch.data.previewsSummary.totalRows}
          />
        }
      </CardContent>
    </Card>
  );
}
// Component Properties
AccountPreviewsTable.propTypes = {
  // Store objects:
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  billing: PropTypes.object.isRequired,
  accountsSearch: PropTypes.object.isRequired,
  searchAccountsByFieldAndValue: PropTypes.func.isRequired,
  getAllPropertyPreviews: PropTypes.func.isRequired,
  updateFilters: PropTypes.func.isRequired,
  searchForAccountsByFilters: PropTypes.func.isRequired,
  deleteAccounts: PropTypes.func.isRequired,
}

// Component State
function AccountPreviewsTableState(state) {
  return {
    user: state.user,
    apl: state.apl,
    billing: state.billing,
    accountsSearch: state.accountsSearch,
    account: state.account
  }
}
export default connect(AccountPreviewsTableState, { searchAccountsByFieldAndValue, getAllPropertyPreviews, updateFilters, searchForAccountsByFilters, addAccountsSelectedToCheck, deleteAccounts})(AccountPreviewsTable)


