import {
  Button,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  TextField as MuiTextField,
} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
//Material UI Components and Functions
import { makeStyles } from "@material-ui/core/styles";
import { ExitToApp } from "@material-ui/icons";
import MuiAlert from "@material-ui/lab/Alert";
import { spacing } from "@material-ui/system";
// Redux Components
import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components/macro";
import { txtBoxDateFormatter } from "../../../setup/helpers";
import {
  createNewAccountBill,
  getUserBills,
  finalizePrint,
  updateNewAccountBillInProgress,
  finalizeBill
} from "../../bills/api/actions";
import { setSnackbar } from "../../snackbar/api/snackbar";
// Custom UI componets and functions:
import AdditionalBillLineItemsManager from "./AdditionalBillLineIItemsChart";

const CenteredContent = styled.div`
  text-align: center;
`;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const FormControl = styled(MuiFormControl)(spacing);
const TextField = styled(MuiTextField)(spacing);

// Custom Styles for property selection drop down

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  selectedPropertyLocationLabel: {
    fontSize: 50,
    backgroundColor: "#fffffff",
    borderColor: "#fffffff",
  },
  loadingIcon: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  billForm: {
    display: "none",
  },
}));

const BillForm = (props) => {
  // Handles vendor drop down
  const [vendor, setVendor] = React.useState(props.bills.newBill.vendorID);
  const [newBillFormObject, setNewBillFormObject] = React.useState(
    props.bills.newBill
  );
  const [isFullBillDisplay, setIsFullBillDisplay] = useState("inline-block");
  const [successState, setSuccessState] = useState(false);
  const [errorState, setErrorState] = useState(false);

  const dispatch = useDispatch();
  const handleSuccess = () => {
    setSuccessState(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccessState(false);
    setErrorState(false);
  };

  // passed props
  const accountNum = props.accountNum;

  const handleSelectedVendorChange = (event) => {
    onFieldChange(event);
    setVendor(event.target.value);
  };

  const submitBillForm = async () => {
    try {

      /*

        Validate Bill before it's created.
      */
      if(props.bills.newBill.serviceEndDate == "" || props.bills.newBill.serviceEndDate == "") {
        dispatch(setSnackbar(true, "error", "Missing service dates on new bill"));
        return;
      }

      if(props.bills.newBill.newBillLineItems.items.length <= 0) {
        dispatch(setSnackbar(true, "error", "You must add a bill line item before a bill can be created."));
        return;
      }
      let validateItems = false;
      for(let bli of props.bills.newBill.newBillLineItems.items){
        if(bli.totalCharge <= 0) {
          dispatch(setSnackbar(true, "error", "Bill item must have a postive charge."));
          validateItems = false;
          break;
        }

        if(bli.itemDesc == "") {
          dispatch(setSnackbar(true, "error", "Bill cost type must be selected"));
          validateItems = false;
          break;
        }
        validateItems = true;
      }

      if(!validateItems)
        return


      dispatch(setSnackbar(true, "warning", "Submitting Account Bill"));
      // Replaces existing store object with new object with modified sub data
      const isFullBill = isFullBillDisplay == "inline-block" ? true : false;
      props.updateNewAccountBillInProgress(props.bills.newBill);
      for (
        var x = 0;
        x < props.bills.newBill.newBillLineItems.items.length;
        x++
      ) {
        if (props.bills.newBill.newBillLineItems.items[x].totalCharge) {
          delete props.bills.newBill.newBillLineItems.items[x].totalCharge;
        }
      }

      //Generated New Bill
      let newBill = await props.createNewAccountBill(
        props.bills.newBill,
        isFullBill,
        accountNum
      );
      dispatch(setSnackbar(true, "warning", "Getting data required to create printed bill."));
      let newBillBody = await props.finalizeBill(newBill)
      dispatch(setSnackbar(true, "warning", "Generating printed bill."));
      await props.finalizePrint(newBillBody)

      if (props.onUpdate) {
        props.onUpdate();
      }
      console.log("calling success state?!");
      dispatch(setSnackbar(true, "success", "Account Billed Successfully"));
      handleSuccess();
    } catch (err) {
      console.log("setting error state");
      dispatch(setSnackbar(true, "error", "Error Billing Account"));
      setErrorState(true);
    }
  };

  const cancelBillForm = () => {
    if (props.onCancel) {
      props.onCancel();
    }
  };

  const classes = useStyles();
  // Updates the local state object of "bill in progress" upon each modification
  const onFieldChange = (event) => {
    // Gets the current vendor info data and updates it with the event's name and value:
    if (event.target.type == "number") {
      props.bills.newBill[event.target.name] = Number(event.target.value);
    } else {
      props.bills.newBill[event.target.name] = event.target.value;
    }
  };

  const onCheckIsFullBill = (e) => {
    setIsFullBillDisplay(e.target.checked ? "inline-block" : "none");
  };

  let errorMessage =
    props.bills.error && props.bills.error.message
      ? props.bills.error.message
      : null;

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={successState}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          Successfully submitted billable items.
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={errorState}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      <Card mb={6} elevation={3}>
        <CardContent>
          <div style={{ display: isFullBillDisplay }}>
            <Grid container spacing={1}>
              <Grid item lg={6} md={12} sm={12}>
                <TextField
                  label="Next Read Date"
                  type="date"
                  onChange={onFieldChange}
                  name="nextReadDate"
                  defaultValue={txtBoxDateFormatter(
                    props.bills.newBill.billDate
                  )}
                  fullWidth
                  my={2}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item lg={6} md={12} sm={12}>
                <TextField
                  id="dueDate"
                  label="Due Date"
                  type="date"
                  fullWidth
                  my={2}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  defaultValue={txtBoxDateFormatter(
                    props.bills.newBill.dueDate
                  )}
                  onChange={onFieldChange}
                  name="dueDate"
                />
              </Grid>
              <Grid item lg={6} md={12} sm={12}>
                <TextField
                  id="serviceStartDate"
                  label="Service Start Date"
                  type="date"
                  fullWidth
                  my={2}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  defaultValue={txtBoxDateFormatter(
                    props.bills.newBill.serviceStartDate
                  )}
                  onChange={onFieldChange}
                  name="serviceStartDate"
                />
              </Grid>
              <Grid item lg={6} md={12} sm={12}>
                <TextField
                  id="serviceEndDate"
                  label="Service End Date"
                  type="date"
                  fullWidth
                  my={2}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  defaultValue={txtBoxDateFormatter(
                    props.bills.newBill.serviceEndDate
                  )}
                  onChange={onFieldChange}
                  name="serviceEndDate"
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12}>
                <TextField
                  label="Bill Message"
                  id="customBillMessage"
                  multiline={true}
                  rowsMax={10}
                  variant="outlined"
                  my={2}
                  fullWidth
                  defaultValue={""}
                  onChange={onFieldChange}
                  name="billMessage"
                />
              </Grid>
            </Grid>
          </div>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12} md={12} sm={12}>
              <AdditionalBillLineItemsManager props={props} />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6} md={6} sm={6}>
              <Button
                mr={1}
                variant="contained"
                color="secondary"
                fullWidth
                onClick={submitBillForm}
                my={2}
              >
                Submit new account bill
              </Button>
            </Grid>
            <Grid item xs={12} lg={6} md={6} sm={6}>
              <Button
                mr={1}
                variant="contained"
                color="primary"
                fullWidth
                onClick={cancelBillForm}
                my={2}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

// Component Properties
BillForm.propTypes = {
  // Store objects:
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  bills: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,

  createNewAccountBill: PropTypes.func.isRequired,
  updateNewAccountBillInProgress: PropTypes.func.isRequired,
  getUserBills: PropTypes.func.isRequired,
  finalizeBill: PropTypes.func.isRequired,
  finalizePrint: PropTypes.func.isRequired
};

// Component State
function BillFormState(state) {
  return {
    user: state.user,
    apl: state.apl,
    bills: state.bills,
    account: state.account,
  };
}
export default connect(BillFormState, {
  createNewAccountBill,
  updateNewAccountBillInProgress,
  finalizeBill,
  finalizePrint,
  getUserBills,
})(BillForm);
