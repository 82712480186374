import {
    Button,
    Card as MuiCard,
    CardContent,
    Grid,
    TextField as MuiTextField,
} from "@material-ui/core";

import {spacing} from "@material-ui/system";
// Redux Components
import PropTypes from "prop-types";
import React, {useState} from "react";

import {connect, useDispatch} from "react-redux";
import styled from "styled-components/macro";
import {updateCurrentAccountInDB, updateSelectedAccount} from "../api/actions";
import {setSnackbar} from "../../snackbar/api/snackbar";

const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)(spacing);


const PledgeAccountForm = (props) => {

    const dispatch = useDispatch();

    const [inputValue, setInputValue] = useState(props.account.data.account.pledgeSource)

    const onFieldChange = (e) => {
        setInputValue(e.target.value)
    }

    const handleUpdate = async () => {
        const updatedAccountData = {
            ...props.account.data,
            account: {
                ...props.account.data.account,
                pledgeSource: inputValue
            }
        };

        try {
            dispatch(updateSelectedAccount(updatedAccountData));
            await dispatch(updateCurrentAccountInDB(updatedAccountData.account));
            dispatch(setSnackbar(true, "success", "Pledge Source for Account updated successfully"));
        } catch (error) {
            console.error("Error updating account:", error);
            dispatch(setSnackbar(true, "error", "Error updating account"));
        }
    };


    return (
        <Card mb={6} elevation={3}>
            <CardContent>
                <Grid container spacing={4}>
                    <Grid item lg={12} md={12} sm={12}>
                        <TextField
                            label="Pledge Source"
                            type="text"
                            onChange={onFieldChange}
                            name="pledgeSource"
                            value={inputValue}
                            fullWidth
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />

                    </Grid>
                    <Grid item>
                        <Button
                            mr={1}
                            variant="contained"
                            color="secondary"
                            fullWidth
                            onClick={handleUpdate}
                        >
                            Update Account Info
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>)
}

PledgeAccountForm.propTypes = {
    account: PropTypes.object.isRequired,
};

function PledgeAccountPaymentFormState(state) {
    return {
        account: state.account,
    };
}

export default connect(PledgeAccountPaymentFormState)(PledgeAccountForm);
  