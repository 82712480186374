import {Divider as MuiDivider, Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { spacing } from "@material-ui/system";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import StandardHeader from "../../components/global/StandardHeader";
import { LeftSidebar as NavFrame } from "layout-blueprints";
import { getByUnitUuid as getMetersByUnitUuid } from "../../modules/meter/api/actions";
import MeterTable from "../../modules/meter/components/Table";
import { get as getOwners,
  getById as getOwnerById } from "../../modules/owner/api/actions";
import {
  get as getPropertyManagers,
  getById as getPropertyManagerById,
} from "../../modules/propertyManager/api/actions";
import PropertyManagerDetailCard from "../../modules/propertyManager/components/DetailCard";
import { get as getPropertyManagerStatuses } from "../../modules/propertyManagerStatus/api/actions";
import { getByUuid as getUnitByUuid, getUnitAllOccupants } from "../../modules/unit/api/actions";
import UnitDetailCard from "../../modules/unit/components/DetailCard";
import { get as getUnitTypes } from "../../modules/unitType/api/actions";
import ToolBar from "./ToolBar";
import OccupantsTable from "modules/unit/components/OccupantsTable";
import OwnerDetailCard from "../../modules/owner/components/OwnerDetailCard";
import moment from "moment/moment";

const Divider = styled(MuiDivider)(spacing);
const IndividualUnitPage = (props) => {
  const unitUuid = props.match.params.uuid;
  const propertyManagerId = props.match.params.propertyManagerId;

  const onUpdateMeters = () => {
    const unitUuid = props.match.params.uuid;
    props.getMetersByUnitUuid(unitUuid);
  };

  const refreshPage = () => {
    props.getMetersByUnitUuid(unitUuid);
    props.getUnitByUuid(unitUuid).then(() => {
      props.getPropertyManagerById(props.units.data?.unit.propertyManagerId);
      props.getOwnerById(props.units.data.unit.ownerId);
    });
    props.getPropertyManagers();
    props.getOwners();
    props.getUnitTypes();
    props.getPropertyManagerStatuses();
    props.getUnitAllOccupants(unitUuid, true);
  }

  useEffect(() => {
    refreshPage();
  }, [props.user.data]);


  if (!props.units.data?.unit.address2 || !props.units.data?.unit.address1) {
    document.title = "Ubil+ Unit";
  }
  else {
    document.title = props.units.data.unit.address2 + " " + props.units.data.unit.address1;
  }

  return (
    //   <NavFrame page={"IndividualPropertyPage"}>
    //   <StandardHeader title="Property Details" />
    //   <MuiDivider my={3} />
    //   <Grid container spacing={2} xs={12} sm={12} md={12} lg={12}>
    //     <Grid container item spacing={2} xs={12}>
    //       <Grid item spacing={2} xs={6}>
    //         <PropertyDetailCard />
    //       </Grid>
    //       <Grid item spacing={2} xs={6}>
    //         <PropertyManagerDetailCard />
    //       </Grid>
    //     </Grid>
    //     <Grid container item spacing={2} xs={12}>
    //       <Grid item spacing={2} xs={4}>
    //         <PropertyUtilityDetailCard />
    //       </Grid>
    //       <Grid item spacing={2} xs={8}>
    //         <Grid container item spacing={2} xs={12}>
    //           <Grid item spacing={2} xs={6}>
    //             <UnitCountCard />
    //           </Grid>
    //           <Grid item spacing={2} xs={6}>
    //             <TotalSqFtCard />
    //           </Grid>
    //           <Grid item spacing={2} xs={12}>
    //             <UnitTable />
    //           </Grid>
    //         </Grid>
    //       </Grid>
    //     </Grid>
    //   </Grid>
    // </NavFrame>

    <NavFrame page={"IndividualUnitPage"}>
      <StandardHeader title="Unit Info"
        rightSideToolBar={<ToolBar refreshPage={refreshPage} />}
      />
      <Typography
        variant="h5"
        color="textPrimary"
      >
        {props.units.data.unit?.doneByDate &&
          `Last Owner Transfer Date: ${moment.utc(props.units.data.unit.doneByDate).format('MM/DD/YYYY')}`}</Typography>
      <Divider className="mb-3 mt-3" />
      <Grid container spacing={1} xs={12} sm={12} md={12} lg={12}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container item spacing={2} xs={12}>
            <Grid item spacing={2} xs={12}>
              <UnitDetailCard unitUuid={unitUuid} />
            </Grid>
            <Grid item spacing={2} xs={6}>
              <PropertyManagerDetailCard />
            </Grid>
            <Grid item spacing={2} xs={6}>
              <OwnerDetailCard />
            </Grid>
            <Grid item spacing={2} xs={12}>
              <OccupantsTable unitUuid={unitUuid}></OccupantsTable>
            </Grid>
            <Grid item spacing={2} xs={12}>
              <MeterTable onUpdate={onUpdateMeters}></MeterTable>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </NavFrame>
  );
};

IndividualUnitPage.propTypes = {
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  units: PropTypes.object.isRequired,
  propertyManagers: PropTypes.object.isRequired,
  getUnitByUuid: PropTypes.func.isRequired,
  getPropertyManagerById: PropTypes.func.isRequired,
  getOwnerById: PropTypes.func.isRequired,
  getMetersByUnitUuid: PropTypes.func.isRequired,
  getPropertyManagers: PropTypes.func.isRequired,
  getOwners: PropTypes.func.isRequired,
  getUnitTypes: PropTypes.func.isRequired,
  getPropertyManagerStatuses: PropTypes.func.isRequired,
  getUnitAllOccupants: PropTypes.func.isRequired
};

// Component State
function IndividualUnitPageState(state) {
  return {
    user: state.user,
    apl: state.apl,
    units: state.units,
    owners: state.owners,
    unitTypes: state.unitTypes,
    propertyManagers: state.propertyManagers
  };
}

export default connect(IndividualUnitPageState, {
  getMetersByUnitUuid,
  getUnitByUuid,
  getPropertyManagerById,
  getOwnerById,
  getPropertyManagers,
  getOwners,
  getUnitTypes,
  getPropertyManagerStatuses,
  getUnitAllOccupants
})(IndividualUnitPage);
