// App Imports
import moment from "moment";
import {
  BILLING_OBJECT_RESET,
  BILLWIZ_DELETE_BILL_REQUEST,
  BILLWIZ_DELETE_BILL_RESPONSE,
  BILLWIZ_GET_BILL_EXPANDED_INFO_REQUEST,
  BILLWIZ_GET_BILL_EXPANDED_INFO_RESPONSE,
  BILLWIZ_GET_BILL_PROPERTY_INFO_REQUEST,
  BILLWIZ_GET_BILL_PROPERTY_INFO_RESPONSE,
  BILLWIZ_GET_UNSETTLED_PROPERTY_METER_READINGS_REQUEST,
  BILLWIZ_GET_UNSETTLED_PROPERTY_METER_READINGS_RESPONSE,
  BILLWIZ_UPDATE_APPLICATION_INSTANCE,
  BILLWIZ_UPDATE_BILL_IN_PROGRESS_APPLICATION_INSTANCE,
  BILLWIZ_UPDATE_SELECTED_PROPERTY_APPLICATION_INSTANCE,
  BILLWIZ_UPDATE_BILL_IN_PROGRESS_BILL_CALCULATION,
  GET_ALL_BILLS_IN_PROGRESS_REQUEST,
  GET_ALL_BILLS_IN_PROGRESS_FAILURE,
  GET_ALL_BILLS_IN_PROGRESS_RESPONSE,
  GET_BILLING_ANALYTICS_REQUEST,
  GET_BILLING_ANALYTICS_RESPONSE,
  GET_BILLING_BATCH_FAILURE,
  GET_BILLING_BATCH_REQUEST,
  GET_BILLING_BATCH_RESPONSE,
  GET_BILLING_PDF_FAILURE,
  GET_BILLING_PDF_REQUEST,
  GET_BILLING_PDF_RESPONSE,
  HANDLE_NEW_BILLING_PROCESS_FAILURE,
  HANDLE_NEW_BILLING_PROCESS_REQUEST,
  HANDLE_NEW_BILLING_PROCESS_RESPONSE,
  UPDATE_BILL_DASHBOARD_APPLICATION_INSTANCE,
  GET_BILLING_INFO_SUMMARY_REQUEST,
  GET_BILLING_INFO_SUMMARY_RESPONSE,
  GET_BILLING_INFO_SUMMARY_FAILURE

} from "./actions";

const today = moment().format("MM/DD/YYYY");
const dueDate = moment().add(21, "d").format("MM/DD/YYYY");
const nextReadDate = (billDate, fiscalMonth) => {
  const parsedBillDate = new Date(billDate);
  const lastDayOfMonth = new Date(parsedBillDate.getFullYear(), fiscalMonth + 1, 0);
  return lastDayOfMonth.toISOString().split('T')[0];
};

// Initial State
const billingInitialState = {
  // Generic loading flag
  isLoading: true,

  // Billing Dashboard loading flags
  billProgressHistoryIsLoading: true,
  billAnalyticsIsLoading: true,

  // Flags for the currently being edited bill draft
  billInProgressIsLoading: true,
  selectedPropertyIsLoading: true,
  selectedPropertyMetersAreLoading: true,
  billingBatchDataIsLoading: true,
  billExpandedViewIsLoading: true,
  billIsBeingSubmitted: false,
  billIsBeingDeleted: false,
  billingPDFsLoading: false,
  billingInfoSummaryLoading: false,

  readingTypes: [{ type: "EST" }, { type: "ACT" }],
  error: null,
  data: {
    billingWizardDashboard: {
      incompleteBatches: {
        items: [],
        toDelete: [], // Array of uuids
        toUpdate: [], // Array of uuids
      },
      incompleteWorkTables: {
        items: [],
        toDelete: [], // Array of uuids
        toUpdate: [], // Array of uuids
      },
    },
    selectedProperty: {},
    selectedBatch: {
      items: [],
      batchSet: {},
      batchSetStatus: { jobs: [] }
    },
    selectedBill: {
      bill: {},
      riders: {
        items: [],
      },
    },
    // To be sent to and recieved from db only
    billInProgress: {
      // Flags for the current state
      currentStep: 0,
      isExecuted: false,
      generateTrialBilling: false,
      executeTrialBills: false,
      // is set in the callback of execute bill batch, in that case on rerender and success it reroutes to dashboard
      successfulTrialBillExecutionAttempt: false,
      // Worktable's properties
      accountBillWorkTableUUID: -1,
      billInformation: {
        propertyUUID: "",
        billDate: today,
        dueDate: dueDate,
        nextReadDate: nextReadDate(today, 0),
        billMessage: "",
        runDate: today,
        hold: 0
      },
      vendorInformation: {
        vendorBillUUID: -1,
        vendorID: null,
        fiscalMonth: 0,
        dueDate: "",
        // tariffRateTotal: 0,
        // kwhUsedTotal: 0,
        fiscalYear: 0,
        billDate: today,
        periodStartDate: '',
        periodEndDate: '',
        // marketRateTotal: 0,
        // kwhTotal: 0,
        meterReadDate: '',
        billTotal: 0,
        // costPerKWH: 0, // Calculated on front end
        vendorBillPercentage: 1,
        //water usage
        cfUsage: 0,
        measureUnitType: "CF",
        usageTotal: 0,
        //water usage charge
        waterUsageCharge: 0,
        waterBaseCharge: 0,
        fireUsageBaseCharge: 0,
        backFlowCharge: 0,
        otherCharges: 0,
        waterUsageChargeTotal: 0,
        //Sewer Charge
        sewerCharge: 0,
        sewerServiceFee: 0,
        sewerUsageChargeTotal: 0,
        sewerRate: 0,
        //stormwater Charge
        stormWaterCharge: 0,
        stormWaterTotal: 0,
        //clean River Fee
        cleanRiverFee: 0,
        cleanRiverTotal: 0,
        //
        waterRate: 0,
        waterAndSewerChargeTotal: 0,
        // Each table consists of 3 sub arrays:
        vendorBillPassthroughItems: {
          items: [],
          toDelete: [], // Array of uuids
          toUpdate: [], // Array of uuids
        },
      },
      // Each table consists of 3 sub arrays:
      trialBillingItems: {
        items: [],
        toDelete: [], // Array of uuids
        toUpdate: [], // Array of uuids
      },
      billingSummary: {}, // The final totals of the bill work table line items after backend approval
      errors: [],
      vendorBillErrors: [],
      billInformationErrors: [],
    },
    billingPDFs: {
      items: [],
      toDelete: [], 
      toUpdate: []
    },
    billingInfoSummary: {
      items: [],
      toDelete: [],
      toUpdate: []
    }
  },
};

// State
export default (state = billingInitialState, action) => {
  switch (action.type) {
    case BILLING_OBJECT_RESET:
      return Object.assign({}, billingInitialState);
    // Called when the billing wizard page needs to be marked as a refresh
    case BILLWIZ_UPDATE_APPLICATION_INSTANCE:
      return {
        ...state,
      };
    // Called when the currently selected property on the billing wizard is changed
    case BILLWIZ_UPDATE_SELECTED_PROPERTY_APPLICATION_INSTANCE:
      //state.data.billInProgress.errors = action.errorItems;
      state.data.billInProgress.errors = [];
      // Merges the old and new property objects together
      const updatedSelectedPropertyObject = Object.assign(
        {},
        state.data.selectedProperty,
        action.newSelectedPropertyData
      );
      const updatedBillingObjectWithNewCurrentProperty = Object.assign(
        {},
        state.data,
        { selectedProperty: updatedSelectedPropertyObject }
      );
      return {
        ...state,
        data: updatedBillingObjectWithNewCurrentProperty,
      };
    // Called to save the form object to the current application's bill in progress's state
    case BILLWIZ_UPDATE_BILL_IN_PROGRESS_APPLICATION_INSTANCE:
      //state.data.billInProgress.errors = action.errorItems;
      state.data.billInProgress.errors = [];
      // Merges the old and new property objects together
      const updatedCurrentlySelectedBillObject = Object.assign(
        {},
        state.data.billInProgress,
        action.newBillData
      );
      const updatedBillingObjectWithNewCurrentBill = Object.assign(
        {},
        state.data,
        { billInProgress: updatedCurrentlySelectedBillObject }
      );
      return {
        ...state,
        data: updatedBillingObjectWithNewCurrentBill,
      };
    case UPDATE_BILL_DASHBOARD_APPLICATION_INSTANCE:
      //Replaces old draft array with new array
      var newDashboardData = state.data;
      var updatedDashboardInfo = newDashboardData.billingWizardDashboard;
      if (action.newBillDashboardInfo.incompleteBatches || action.newBillDashboardInfo.incompleteWorkTables) {
        updatedDashboardInfo.incompleteBatches = action.newBillDashboardInfo.incompleteBatches;
        updatedDashboardInfo.incompleteWorkTables = action.newBillDashboardInfo.incompleteWorkTables;
        newDashboardData.billingWizardDashboard = updatedDashboardInfo;
        return {
          ...state,
          billProgressHistoryIsLoading: false,
          data: newDashboardData,
        };
      }
      else {
        updatedDashboardInfo = Object.assign({}, newDashboardData.billingWizardDashboard, action.newBillDashboardInfo);
        newDashboardData.billingWizardDashboard = updatedDashboardInfo;
        return {
          ...state,
          billProgressHistoryIsLoading: false,
          data: newDashboardData,
        };
      }
    case BILLWIZ_UPDATE_BILL_IN_PROGRESS_BILL_CALCULATION:
      const { billInformation, vendorInformation } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          billInProgress: {
            ...state.data.billInProgress,
            billInformation: {
              ...state.data.billInProgress.billInformation,
              ...billInformation,
            },
            vendorInformation: {
              ...state.data.billInProgress.vendorInformation,
              ...vendorInformation,
            },
          },
        },
      };


    case BILLWIZ_GET_BILL_PROPERTY_INFO_REQUEST:
      state.data.billInProgress.errors = [];
      state.selectedPropertyIsLoading = action.isLoading;
      // Merges the old and new property objects together
      const updatedBillObject = Object.assign(
        {},
        state.data.billInProgress,
        action.newBillData
      );
      const updatedBillingObjectModule = Object.assign({}, state.data, {
        billInProgress: updatedBillObject,
      });
      return {
        ...state,
        data: updatedBillingObjectModule,
      };
    case BILLWIZ_GET_BILL_PROPERTY_INFO_RESPONSE:
      return {
        ...state,
        error: action.error,
        selectedPropertyIsLoading: false,
      };
    case BILLWIZ_GET_BILL_EXPANDED_INFO_REQUEST:
      return {
        ...state,
        error: null,
        billExpandedViewIsLoading: action.isLoading,
      };
    case BILLWIZ_GET_BILL_EXPANDED_INFO_RESPONSE:
      const newData = state.data;
      newData.selectedBill.riders.items = action.response.riders;
      newData.selectedBill.bill = action.selectedBill;
      return {
        ...state,
        error: action.error,
        data: newData,
        billExpandedViewIsLoading: false,
      };

    case BILLWIZ_DELETE_BILL_REQUEST:
      return {
        ...state,
      };
    case BILLWIZ_DELETE_BILL_RESPONSE:
      return {
        ...state,
        billIsBeingDeleted: true,
      };

    case BILLWIZ_GET_UNSETTLED_PROPERTY_METER_READINGS_REQUEST:
      return {
        ...state,
        error: null,
        selectedPropertyMetersAreLoading: action.isLoading,
      };
    case BILLWIZ_GET_UNSETTLED_PROPERTY_METER_READINGS_RESPONSE:
      return {
        ...state,
        error: action.error,
        selectedPropertyMetersAreLoading: false,
      };
    case GET_ALL_BILLS_IN_PROGRESS_REQUEST:
      return {
        ...state,
        error: null,
        billProgressHistoryIsLoading: action.isLoading,
      };
    case GET_ALL_BILLS_IN_PROGRESS_RESPONSE:
      return {
        ...state,
        error: action.error,
        billProgressHistoryIsLoading: false,
      };
    case GET_ALL_BILLS_IN_PROGRESS_FAILURE:
      console.log("GET_ALL_BILLS_IN_PROGRESS_FAILURE");
      console.log(action.error);
      return {
        ...state,
        error: action.error,
        billProgressHistoryIsLoading: false,
      };
    case GET_BILLING_ANALYTICS_REQUEST:
      return {
        ...state,
        error: null,
        billAnalyticsIsLoading: action.isLoading,
      };
    case GET_BILLING_ANALYTICS_RESPONSE:
      return {
        ...state,
        error: action.error,
        billAnalyticsIsLoading: false,
      };
    case GET_BILLING_BATCH_REQUEST:
      return {
        ...state,
        error: null,
        billingBatchDataIsLoading: action.isLoading,
        isLoading: action.isLoading,
      };
    case GET_BILLING_BATCH_RESPONSE:
      const data = state.data;
      data.selectedBatch.items = action.response.billingBatches;
      data.selectedBatch.batchSet = action.response.billingBatchSet;
      data.selectedBatch.batchSetStatus = action.response.batchStatus;
      return {
        ...state,
        data: data,
        error: action.error,
        billingBatchDataIsLoading: false,
      };
    case GET_BILLING_BATCH_FAILURE:
      return {
        ...state,
        error: "ERROR RETURNED FROM API",
        billingBatchDataIsLoading: false,
      };

    case HANDLE_NEW_BILLING_PROCESS_REQUEST:
      return {
        ...state,
        error: null,
        billIsBeingSubmitted: true,
        billInProgressIsLoading: action.isLoading,
      };
    case HANDLE_NEW_BILLING_PROCESS_RESPONSE:
      return {
        ...state,
        error: action.error,
        billInProgressIsLoading: false,
        billIsBeingSubmitted: false,
        isLoading: false,
      };
    case HANDLE_NEW_BILLING_PROCESS_FAILURE:
      return {
        ...state,
        billInProgressIsLoading: false,
        isLoading: false,
      };
    case GET_BILLING_PDF_REQUEST:
      return {
        ...state,
        billingPDFsLoading: action.isLoading
      }
    case GET_BILLING_PDF_RESPONSE:
      const billingData = state.data;
      billingData.billingPDFs.items = action.pdfs
      return {
        ...state,
        billingPDFsLoading: action.isLoading,
      }
    case GET_BILLING_PDF_FAILURE:
      return {
        ...state,
        billingPDFsLoading: action.isLoading,
        error: action.errors
      }
    case GET_BILLING_INFO_SUMMARY_REQUEST:
      return {
        ...state,
        billingInfoSummaryLoading: action.isLoading
      }
    case GET_BILLING_INFO_SUMMARY_RESPONSE:
      return {
        ...state,
        billingInfoSummaryLoading: action.isLoading,
        data: {
          ...state.data,
          billingInfoSummary: {
            ...state.data.billingInfoSummary,
            items: action.responseData
          }
        }
      };
    case GET_BILLING_INFO_SUMMARY_FAILURE:
      return {
        ...state,
        billingInfoSummaryLoading: action.isLoading,
        error: action.errors
      }

    default:
      return {
        ...state,
      };
  }
};
