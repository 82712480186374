import React, { useEffect, useState } from 'react';
import { Card as MuiCard, CardContent } from "@material-ui/core";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { EditableTable } from "../../../components/EditableTable";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { getOwnerJournals } from "../api/actions";

const Card = styled(MuiCard)(spacing);

const OwnerJournalsTable = ({ ownerId }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [resetCache, setResetCachedData] = useState(true);


  const dispatch = useDispatch();
  const ownerJournals = useSelector(state => state.ownerJournals);

  useEffect(() => {
    if (ownerJournals.data.ownerJournals.items) {
      if ((page + 1) * rowsPerPage > ownerJournals.data.ownerJournals.items.length || resetCache) {
        dispatch(getOwnerJournals(ownerId, page, rowsPerPage, resetCache));
      }
    }
  }, [ownerId, page, rowsPerPage]);

  const handleChangeRowsPerPage = (rowsPerPage) => {
    setPage(0);
    setResetCachedData(false);
    setRowsPerPage(rowsPerPage);
  };

  const handleChangePage = (page) => {
    setResetCachedData(false);
    setPage(page);
  };


  const fieldTitles = [
    "Date",
    "Amount",
    "Notes"
  ];

  const fieldTypes = [
    "dateLabelNoTime",
    "moneyLabel",
    "label"
  ];

  const fieldNames = [
    "journalDate",
    "amount",
    "notes",
  ];

  return (
    <Card mb={6} elevation={3}>
      <CardContent>
        <EditableTable
          readOnly={false}
          canAddItems={false}
          canSave={false}
          canDelete={false}
          tableTitle={"Owner Journals"}
          objectArray={ownerJournals.data.ownerJournals}
          fieldTitles={fieldTitles}
          fieldTypes={fieldTypes}
          fieldNames={fieldNames}
          customChangePageRowsEvent={handleChangeRowsPerPage}
          customHandleChangePageEvent={handleChangePage}
          defaultItemCount={rowsPerPage}
          isLoading={ownerJournals.loading}
          totalRowCount={
            !ownerJournals?.data.totalRows
              ? 0
              : ownerJournals?.data.totalRows
          }
        />
      </CardContent>
    </Card>
  );
};

OwnerJournalsTable.propTypes = {
  ownerId: PropTypes.number.isRequired,
};

export default OwnerJournalsTable;
