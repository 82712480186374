// Imports
import axios from "axios";
require("../../../setup/config/apiConfig");

export const RESET = "RESET";
export const GET_OWNERS = "GET_OWNERS";
export const GET_OWNERS_RESPONSE = "GET_OWNERS_RESPONSE";
export const GET_OWNERS_RESPONSE_FAIL = "GET_OWNERS_RESPONSE_FAIL";
export const GET_OWNER = "GET_OWNER";
export const GET_OWNER_RESPONSE = "GET_OWNER_RESPONSE";
export const GET_OWNER_RESPONSE_FAIL = "GET_OWNER_RESPONSE_FAIL";
export const SET_OWNERS = 'SET_OWNERS';
export const GET_OWNERS_STATEMENT_DATA_REQUEST = "GET_OWNERS_STATEMENT_DATA_REQUEST";
export const GET_OWNERS_STATEMENT_DATA_RESPONSE = "GET_OWNERS_STATEMENT_DATA_RESPONSE";
export const GET_OWNERS_STATEMENT_DATA_FAIL = "GET_OWNERS_STATEMENT_DATA_FAIL";
export const ADD_OWNERS_TO_LIST = "ADD_OWNERS_TO_LIST";

export function addOwnersSelectedToCheck(owners) {
  return { type: ADD_OWNERS_TO_LIST, owners };
}

export function get(isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: GET_OWNERS,
      isLoading: isLoading,
    });
    return axios
      .get(`/owner`)
      .then((response) => {
        dispatch({
          type: GET_OWNERS_RESPONSE,
          owners: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_OWNERS_RESPONSE_FAIL,
          isLoading: false,
          error: error,
        });
      });
  };
}
export function getOwnersWithStatementData(isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: GET_OWNERS_STATEMENT_DATA_REQUEST,
      isLoading: isLoading,
    });
    return axios
      .get(`/owner-statement-bulk-checks`)
      .then((response) => {
        dispatch({
          type: GET_OWNERS_STATEMENT_DATA_RESPONSE,
          ownersWithStatementData: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_OWNERS_STATEMENT_DATA_FAIL,
          isLoading: false,
          error: error,
        });
      });
  };
}

export function getByUuid(uuid, isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: GET_OWNER,
      isLoading: isLoading,
    });
    return axios
      .get(`/owner/${uuid}`)
      .then((response) => {
        dispatch({
          type: GET_OWNER_RESPONSE,
          owner: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_OWNER_RESPONSE_FAIL,
          isLoading: false,
          error: error,
        });
      });
  };
}

export function getById(id, isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: GET_OWNER,
      isLoading: isLoading,
    });
    return axios
      .get(`/owner-id/${id}`)
      .then((response) => {
        dispatch({
          type: GET_OWNER_RESPONSE,
          owner: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_OWNER_RESPONSE_FAIL,
          isLoading: false,
          error: error,
        });
      });
  };
}

export const setOwners = (owners) => ({
  type: SET_OWNERS,
  owners,
});