import {
  Button as MuiButton,
  Card,
  CardContent,
  CircularProgress as MuiCircularProgress,
  Divider as MuiDivider,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress as MuiLinearProgress,
  MenuItem,
  Select as MuiSelect,
  TextField as MuiTextField,
  CardHeader as MuiCardHeader,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
// Redux Components
import PropTypes from "prop-types";
import React from "react";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components/macro";
import { txtBoxDateFormatter, formatPhoneNumber } from "../../../setup/helpers";
import { setSnackbar } from "../../snackbar/api/snackbar";
import {
  updateCurrentAccountInDB,
  updateSelectedAccount,
} from "../api/actions";
import moment from "moment";
import AccountMoveInItemForm from "../../moves/components/moveInItemForm";
import AccountMoveOutItemForm from "../../moves/components/moveOutItemForm";
import BillingAddressForm from "./BillingAddressForm";
const LinearProgress = MuiLinearProgress;
const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Select = styled(MuiSelect)(spacing);
const CardHeader = styled(MuiCardHeader)(spacing);

const AccountForm = (props) => {
  const dispatch = useDispatch();
  const [AccountStatus, setAccountStatus] = React.useState(
    props.account.data.account ? props.account.data.account.accountStatusId : ""
  );
  const [CustomerType, setCustomerType] = React.useState(
    props.account.data.account ? props.account.data.account.customerTypeId : ""
  );
  const useStyles = makeStyles((theme) => ({
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    loadingIcon: {
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
    },
  }));

  // Handles vendor drop down
  const classes = useStyles();
  if (props.account.isLoading) {
    return (
      <Card mb={6}>
        <CardContent>
          <Typography variant="h3" gutterBottom>
            Account Loading...
          </Typography>
        </CardContent>
      </Card>
    );
  }
  if (!props.account.data.account) {
    return (
      <Card mb={6}>
        <CardContent>
          <Typography variant="h3" gutterBottom>
            ERROR! Unable to load current account
          </Typography>
        </CardContent>
      </Card>
    );
  }

  const handleSelectedAccountStatusChange = (event) => {
    props.account.data.account.accountStatusId = event.target.value;
    setAccountStatus(event.target.value);
  };
  const handleSelectedCustomerTypeChange = (event) => {
    props.account.data.account.customerTypeId = event.target.value;
    setCustomerType(event.target.value);
  }

  const sageUpdatedAccountInDB = () => {
    dispatch(setSnackbar(true, "warning", "Saving Account Detail Changes"));
    props.updateSelectedAccount(props.account.data);
    props.updateCurrentAccountInDB(props.account.data.account).then(() => {
      if (props.account.error == null || props.account.error == "") {
        dispatch(setSnackbar(true, "success", "Account updated successfully"));
      }
      else {
        dispatch(setSnackbar(true, "error", "Error Updating Account"));
      }
    });
  }

  const saveFormInStore = () => {
    // Replaces existing store object with new object with modified sub data
    props.updateSelectedAccount(props.account.data);
    if (props.onUpdate) {
      props.onUpdate();
    }
  };

  const cancelFormEdit = () => {
    props.updateSelectedAccount(props.account.data);
    if (props.onCancel) {
      props.onCancel();
    }
  };

  // Updates the local state object of "bill in progress" upon each modification
  const onFieldChange = (event) => {
    // Gets the current vendor info data and updates it with the event's name and value:
    if (event.target.type == "number") {
      props.account.data.account[event.target.name] = Number(
        event.target.value
      );
    } else {
      props.account.data.account[event.target.name] = event.target.value;
    }
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card className="mb-6">
            <CardHeader
              xs={12}
              sm={12}
              md={12}
              lg={12}
              title="Edit Basic Account Details"
            />
            <Divider className="mb-3" />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item lg={4} md={4} sm={6}>
                  <TextField
                    label="First Name"
                    type="text"
                    onChange={onFieldChange}
                    disabled={props.account.data.account?.customerTypeId == 4}
                    name="firstName"
                    defaultValue={props.account.data.account.firstName}
                    fullWidth
                    my={2}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6}>
                  <TextField
                    label="Email"
                    type="text"
                    onChange={onFieldChange}
                    name="email"
                    disabled={props.account.data.account?.customerTypeId == 4}

                    defaultValue={props.account.data.account.email}
                    fullWidth
                    my={2}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="selectedAccountStatusDropDownLabel">
                      Account Status
                    </InputLabel>
                    {!props.account.data.account.accountStatusId && <CircularProgress size={15} m={0} />}
                    {!props.account.data.account.accountStatusId ? (
                      <Select
                        labelId="selectedAccountStatusDropDownLabel"
                        id="selectedAccountStatusDropDown"
                        onChange={handleSelectedAccountStatusChange}
                        fullWidth
                        disabled
                        my={2}
                      >
                      </Select>
                    ) : (
                      <Select
                        labelId="selectedAccountStatusDropDownLabel"
                        id="selectedAccountStatusDropDown"
                        value={
                          props.account.data.account
                            ? props.account.data.account.accountStatusId
                            : AccountStatus
                        }
                        onChange={handleSelectedAccountStatusChange}
                        fullWidth
                        my={2}
                      >[
                        {props.apl.genericTypes.accountStatus.data.length > 0 ? (
                          props.apl.genericTypes.accountStatus.data.map(
                            (item, key) => {
                              return (
                                <MenuItem value={item.id} id={key}>
                                  {item.description}
                                </MenuItem>
                              );
                            }
                          )
                        ) : (
                          <MenuItem value={-1}>
                            No Account Status Types Found
                          </MenuItem>
                        )}
                        ]
                      </Select>
                    )}
                  </FormControl>
                </Grid>
                {/*             Col 2            */}
                <Grid item lg={4} md={4} sm={6}>
                  <TextField
                    label="Last Name"
                    type="text"
                    onChange={onFieldChange}
                    name="lastName"
                    defaultValue={props.account.data.account.lastName}
                    disabled={props.account.data.account?.customerTypeId == 4}
                    fullWidth
                    my={2}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6}>
                  <TextField
                    label="Phone #1"
                    type="phone"
                    onChange={onFieldChange}
                    name="phone1"
                    defaultValue={formatPhoneNumber(props.account.data.account.phone1)}
                    disabled={props.account.data.account?.customerTypeId == 4}
                    fullWidth
                    my={2}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6}>
                  <TextField
                    label=" Last 4 SSN"
                    type="number"
                    onChange={onFieldChange}
                    name="last4SSN"
                    defaultValue={props.account.data.account.last4SSN}
                    fullWidth
                    my={2}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                {/*             Col 3           */}
                <Grid item lg={4} md={4} sm={6}>
                  <TextField
                    label="Middle Name"
                    type="text"
                    onChange={onFieldChange}
                    name="middleName"
                    defaultValue={props.account.data.account.middleName}
                    disabled={props.account.data.account?.customerTypeId == 4}
                    fullWidth
                    my={2}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6}>
                  <TextField
                    label="Phone #2"
                    type="text"
                    onChange={onFieldChange}
                    name="phone2"
                    defaultValue={formatPhoneNumber(props.account.data.account.phone2)}
                    disabled={props.account.data.account?.customerTypeId == 4}
                    fullWidth
                    my={2}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6}>
                  <TextField
                    label="Lease End Date"
                    type="date"
                    onChange={onFieldChange}
                    name="leaseEndDate"
                    defaultValue={props.account.data.account.leaseEndDate ? txtBoxDateFormatter(
                      props.account.data.account.leaseEndDate
                    ): ""}
                    fullWidth
                    my={2}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6}>
                  <TextField
                    label="Move In Date"
                    type="date"
                    onChange={onFieldChange}
                    name="moveInDateOverride"
                    defaultValue={moment.utc(props.account.data.account.moveInDateOverride).add(1, 'd').format('YYYY-MM-DD') || ""}
                    fullWidth
                    my={2}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6}>
                <FormControl variant="outlined" fullWidth>
                    <InputLabel id="selectedCustomerTypeDropDownLabel">
                      Customer Type
                    </InputLabel>
                    {!props.account?.data?.account?.customerTypeId && <CircularProgress size={15} m={0} />}
                    {!props.account?.data?.account?.customerTypeId ? (
                      <Select
                        labelId="selectedCustomerTypeDropDownLabel"
                        id="selectedCustomerTypeDropDown"
                        onChange={handleSelectedCustomerTypeChange}
                        fullWidth
                        disabled
                        my={2}
                      >
                      </Select>
                    ) : (
                      <Select
                        labelId="selectedCustomerTypeDropDownLabel"
                        id="selectedCustomerTypeDropDown"
                        value={
                          props.account?.data?.account
                            ? props.account?.data?.account?.customerTypeId
                            : CustomerType
                        }
                        onChange={handleSelectedCustomerTypeChange}
                        fullWidth
                        my={2}
                      >[
                        {props.apl?.genericTypes?.customerType?.data?.length > 0 ? (
                          props.apl.genericTypes?.customerType?.data?.map(
                            (item, key) => {
                              return (
                                <MenuItem value={item.id} id={key}>
                                  {item.description}
                                </MenuItem>
                              );
                            }
                          )
                        ) : (
                          <MenuItem value={-1}>
                            No Customer Types Found
                          </MenuItem>
                        )}
                        ]
                      </Select>
                    )}
                  </FormControl>
                </Grid>

              </Grid>
              <Button
                className="mb-3 mt-3 mr-3"
                variant="contained"
                color="secondary"
                onClick={sageUpdatedAccountInDB}
              >
                Update Account Details
              </Button>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <BillingAddressForm />
        </Grid>
        {props.account.data.move &&
          <>
            {props.account.data.move.moveIn.id &&
              <Grid item xs={12}>
                <AccountMoveInItemForm />
              </Grid>
            }
            {props.account.data.move.moveOut.id &&
              <Grid item xs={12}>
                <AccountMoveOutItemForm />
              </Grid>
            }
          </>
        }
      </Grid>
    </>
  );
};

// Component Properties
AccountForm.propTypes = {
  // Store objects:
  account: PropTypes.object.isRequired,
  //userProfile: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  updateSelectedAccount: PropTypes.func.isRequired,
  updateCurrentAccountInDB: PropTypes.func.isRequired,
};

// Component State
function AccountFormState(state) {
  return {
    account: state.account,
    apl: state.apl,
  };
}
export default connect(AccountFormState, {
  updateSelectedAccount,
  updateCurrentAccountInDB,
})(AccountForm);
