//React Components and Hooks
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import React from "react";
//Material UI Components
import AnalyticCard from "../../components/cards/AnalyticCard";
//Custom Components

const DevPage = () => {
  return (
    <Container>
      <h1>Dev Page! Running Tests on loading data via react redux....</h1>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <AnalyticCard title="User Item" />
        </Grid>
      </Grid>
    </Container>
  );
};

export default DevPage;
