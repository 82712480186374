import React, {useEffect} from 'react';
import {
  CircularProgress as MuiCircularProgress,
  FormControl as MuiFormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import {spacing} from "@material-ui/system";
import styled from "styled-components/macro";
import {
  getByUuid as getPropertyByUuid,
} from "../../../../modules/property/api/actions";
import {getByPropertyId as getPropertyUtilityByPropertyId} from "../../../../modules/propertyUtility/api/actions";
import {
  getSelectedBillWizPropertyInfoByPropUUID,
  updateBillingWizardBillInProgress,
  updateBillingWizardSelectedProperty
} from "../../../../modules/billing/api/actions";
import {withRouter} from "react-router-dom";

const PropertySelect = (props) => {
  const CircularProgress = styled(MuiCircularProgress)(spacing);
  const FormControl = styled(MuiFormControl)(spacing);

  const [property, setProperty] = React.useState(props.billing.data.billInProgress.billInformation.propertyUUID);

  const useStyles = makeStyles(() => ({
    selectedPropertyLocationLabel: {
      fontSize: 30,
      backgroundColor: "#fffffff",
      borderColor: "#fffffff",
    },
  }));
  const classes = useStyles();

  const handleSelectedPropertyChange = (event) => {
    setProperty(event.target.value);
    const selectedProperty = props.apl.data.propertyPreviews.find(obj => {
      return obj.uuid === event.target.value
    })
    props.billing.data.billInProgress.billInformation.propertyUUID = event.target.value;
    props.updateBillingWizardBillInProgress(props.billing.data.billInProgress);
    props.updateBillingWizardSelectedProperty(selectedProperty);
    props.getSelectedBillWizPropertyInfoByPropUUID(selectedProperty.id);
    props.getPropertyByUuid(selectedProperty.uuid);
    props.getPropertyUtilityByPropertyId(selectedProperty.id);
  };

  useEffect(() => {
    if(props.billing.data.selectedProperty.id) {
      props.getPropertyUtilityByPropertyId(props.billing.data.selectedProperty.id);
    }
  }, [props.billing.data.selectedProperty.id]);

  const nonMeteredProperties = props.apl.data.propertyPreviews.filter(p => {
    return props.propertyUtilities.data.propertyUtilitiesList.items.some(item => item.propertyId === p.id && (item.billingType === 'RUBS' || item.billingType === 'SQFT'))
  })

  return (
    <>
      <Grid item lg={4} md={4} sm={12}>
        <FormControl fullWidth my={2}>
          <InputLabel id="propertyToBillDropDownLabel">Location{"  "}
            {props.apl.isLoading &&
              <CircularProgress size={15} m={0}/>
            }
          </InputLabel>
          {property === "" && props.apl.isLoading ?
            <Select
              labelId="propertyToBillDropDownLabel"
              id="propertyToBillDropDown"
              className={classes.selectedPropertyLocationLabel}
              onChange={handleSelectedPropertyChange}
              fullWidth
              disabled
              my={2}>
            </Select>
            :
            <Select
              labelId="propertyToBillDropDownLabel"
              id="propertyToBillDropDown"
              value={props.billing.data.billInProgress.billInformation.propertyUUID}
              disabled={props.modalType==='edit'}
              className={classes.selectedPropertyLocationLabel}
              onChange={handleSelectedPropertyChange}
              fullWidth
              my={2}>
              {props.apl.data.propertyPreviews.length > 0 ?
                [
                  nonMeteredProperties.map((item, key) => {
                    return (
                      <MenuItem value={item.uuid} id={key} key={item.uuid}>{item.name}</MenuItem>
                    )
                  })
                ]
                :
                [
                  <MenuItem value={-1}>No Properties Found</MenuItem>
                ]
              }
            </Select>
          }
        </FormControl>
      </Grid>
      <Grid item lg={6} md={6} sm={12}>
        {props.billing.data.billInProgress.billInformation?.propertyUUID && <Typography variant="h5" className={classes.typography}>
          Billing Type
          : {props.propertyUtilities.data.propertyUtility?.billingType && !props.propertyUtilities.propertyUtilityIsLoading && props.propertyUtilities.data.propertyUtility.billingType}
        </Typography>}
      </Grid>
    </>
  );
};

function PropertySelectState(state) {
  return {
    user: state.user,
    apl: state.apl,
    billing: state.billing,
    properties: state.properties,
    propertyUtilities: state.propertyUtilities
  }
}

export default connect(PropertySelectState, {
  getPropertyByUuid,
  getPropertyUtilityByPropertyId,
  updateBillingWizardSelectedProperty,
  updateBillingWizardBillInProgress,
  getSelectedBillWizPropertyInfoByPropUUID,
})(withRouter(PropertySelect))