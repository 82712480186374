import { Divider as MuiDivider } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { spacing } from "@material-ui/system";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import StandardHeader from "../../components/global/StandardHeader";
import { LeftSidebar as NavFrame } from "layout-blueprints";
import { get as getDevelopers } from "../../modules/developer/api/actions";
import { get as getProperties } from "../../modules/property/api/actions";
import PropertyTable from "../../modules/property/components/Table";
import { get as getPropertyManagers } from "../../modules/propertyManager/api/actions";
import { getAllPropertyStatuses } from "../../modules/apl/api/actions";
import { get as getOwners } from "../../modules/owner/api/actions";


const Divider = styled(MuiDivider)(spacing);
const PropertiesPage = (props) => {
  // On initial page load:
  useEffect(() => {
    props.getProperties(null, null);
    props.getDevelopers();
    props.getOwners();
    props.getPropertyManagers();
    props.getAllPropertyStatuses();
    document.title = "Ubil+ Properties Search";
  }, []);

  return (
    <NavFrame page={"PropertiesPage"}>
      <StandardHeader title="Properties Management" />
      <Divider className="mb-3 mt-3" />
      <Grid container spacing={1} xs={12} sm={12} md={12} lg={12}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <PropertyTable />
        </Grid>
      </Grid>
    </NavFrame>
  );
};

PropertiesPage.propTypes = {
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  getProperties: PropTypes.object.isRequired,
  properties: PropTypes.object.isRequired,
  getDevelopers: PropTypes.func.isRequired,
  getOwners: PropTypes.func.isRequired,
  getPropertyManagers: PropTypes.func.isRequired,
  getAllPropertyStatuses: PropTypes.func.isRequired,
};

// Component State
function PropertiesPageState(state) {
  return {
    user: state.user,
    apl: state.apl,
    properties: state.properties,
  };
}

export default connect(PropertiesPageState, {
  getProperties,
  getDevelopers,
  getOwners,
  getPropertyManagers,
  getAllPropertyStatuses,
})(PropertiesPage);
