import {
  GET_OWNER_STATEMENTS,
  GET_OWNER_STATEMENTS_RESPONSE,
  PRINT_OWNER_STATEMENT_REQUEST,
  PRINT_OWNER_STATEMENT_RESPONSE,
  PRINT_OWNER_STATEMENT_RESPONSE_FAIL,
  UPDATE_OWNER_STATEMENTS_FOUND} from "./actions";

const initialState = {
  loading: false,
  error: null,
  data: {
    ownerStatements: {
      items: [],
      toDelete: [],
      toUpdate: []
    },
    totalRows: null,
    lastStatementDate: null
  },
  searchQuery: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_OWNER_STATEMENTS:
      return {
        ...state,
        loading: action.loading,
        error: null
      };
    case GET_OWNER_STATEMENTS_RESPONSE:
      return {
        ...state,
        loading: action.loading
      };
    case PRINT_OWNER_STATEMENT_REQUEST:
      return {
        ...state,
        loading: action.loading,
        error: null,
      }
    case PRINT_OWNER_STATEMENT_RESPONSE:
      return {
        ...state,
        loading: action.loading,
        error: action.error,
      };
    case PRINT_OWNER_STATEMENT_RESPONSE_FAIL:
      return {
        ...state,
        loading: action.loading,
        error: action.error,
      }
    case UPDATE_OWNER_STATEMENTS_FOUND:
      let newStateData = state.data
      if(action.resetCache){
        newStateData.ownerStatements = {
          items: action.data.data,
          toDelete: [],
          toUpdate: [],
        };
      }else{
        newStateData.ownerStatements = {
          items: newStateData.ownerStatements.items.concat(action.data.data),
          toDelete: [],
          toUpdate: [],
        }
      }
      if (action.totalRows !== null) {
        newStateData.totalRows = action.data.totalRows;
      }
      newStateData.lastStatementDate = action.data.lastStatementDate
      return {
        ...state,
        data: newStateData,
      };
    default:
      return state;
  }
}
