import {
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  ButtonGroup,
  CircularProgress as MuiCircularProgress,
  Divider as MuiDivider,
  Grid,
  Paper as MuiPaper,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
//Material UI Components and Functions
import { makeStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
// Redux Components
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { EditableTable } from "../../../components/EditableTable";
import AccountLedgerPreviewPopup from "./AccountLedgerPreviewPopup";
import _ from "lodash";
const Button = styled(MuiButton)(spacing);

const DashboardToolBarButton = styled(Button)`
  padding: 4px;
  min-width: 0;
  svg {
    width: 2em;
    height: 2em;
  }
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CircularProgress = styled(MuiCircularProgress)(spacing);

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  percentDif: {
    textAlign: "right",
    color: "green",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  loadingIcon: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  active: {
    fontWeight: "bold",
    color: "#f9a825",
    borderColor: "#f9a825",
  },
  inactive: {
    fontWeight: "bold",
  },
}));

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Spacer = styled.div`
  flex: 1 1 100%;
`;

//Editable Table Object Expects: {objectArray, fieldTitles(Array), fieldTypes(Array), fieldNames(Array), handleRowClickParentMethod}
// Method can take line items if they exist initially or starts as an empty array of nothing passed
function AccountLedgerLogTable(props) {
  const classes = useStyles();
  const history = useHistory();
  const navTo = (page) => history.push(page);

  const [tableState, setTableState] = React.useState("Simple");
  const [ledgerFormOpen, setLedgerItemFormOpen] = React.useState(false);
  const [previewOpen, setPreviewOpen] = React.useState(false);

  const handlePaymentFormClickOpen = () => {
    setLedgerItemFormOpen(true);
  };

  const handlePaymentFormClose = () => {
    setLedgerItemFormOpen(false);
  };

  const handlePreviewOpen = () => {
    setPreviewOpen(true);
  };

  const handlePreviewClose = () => {
    setPreviewOpen(false);
  };

  const fieldTitles = [
    "#",
    "Process",
    "Date",
    "Amount",
    "Balance",
    "Description",
  ];
  const fieldTypes = [
    "label",
    "label",
    "dateLabel",
    "moneyLabel",
    "moneyLabel",
    "label",
  ];
  const fieldNames = [
    "transactionId",
    "entryTypeLabel",
    "transactionDate",
    "amount",
    "balance",
    "description",
  ];

  const onLedgerItemSelected = (item) => { };

  const handleTableTransition = (item) => {
    if (tableState == "Simple") {
      setTableState("Detailed");
    } else {
      setTableState("Simple");
    }
  };
  const Toolbar = () => {
    return (
      <Grid container xs={12} spacing={4} className={classes.rightSideHeaderSubMenuToolBar}>
        <Grid item xs={6} >
          <ButtonGroup color="primary" aria-label="outlined primary button group">
            <Button
              onClick={handleTableTransition}
              className={
                tableState == "Simple" ? classes.active : classes.inactive
              }
            >
              Simple
            </Button>
            <Button
              onClick={handleTableTransition}
              className={
                tableState == "Detailed" ? classes.active : classes.inactive
              }
            >
              Detailed
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item xs={6} >
          <Button
            mr={1}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handlePreviewOpen}
            my={2}
          >
            View Ledger Preview
          </Button>
        </Grid>
      </Grid>
    );
  };

  const LedgerRowStyling = (item) => {
    item.entryTypeLabel = item.entryType.replace(/([A-Z])/g, " $1").trim();
    return {};
  };
  // Filtering process locally: { items: props.account.ledger.entries, toDelete: [], toUpdate: [] }
  let toShow = {
    items: JSON.parse(JSON.stringify(props.account.ledger.entries || [])),
    toUpdate: [],
    toDelete: [],
  };
  // Loops through each item:
  for (var x = 0; x < toShow.items.length;) {
    // To show complex or noncomplex version of the list
    if (tableState == "Simple" && toShow.items[x].entryType == "BillDetail") {
      toShow.items.splice(x, 1);
      continue;
    }

    if (tableState == "Detailed" && toShow.items[x].entryType == "Bill") {
      toShow.items.splice(x, 1);
      continue;
    }

    // If the item is good to stay, only then should you advance as cutting an item naturally advances the cursors position.
    // Im a brick for this taking longer then it should have until i realized this was going on
    x++;
  }

  //Don't show the same ID
  toShow.items = _.uniqBy(toShow.items, (item) => {
    return item.transactionId;
  });

  return (
    <div>
      <EditableTable
        customToolbar={<Toolbar />}
        readOnly={true}
        canAddItems={false}
        canSave={false}
        isLoading={props.account.ledgerIsLoading}
        // Functional methods of table
        handleRowClickParentMethod={onLedgerItemSelected}
        conditionalStylingMethod={LedgerRowStyling}
        // Properties of table
        tableTitle={"Account History"}
        objectArray={toShow}
        objectError={props.account.ledgerError}
        fieldTitles={fieldTitles}
        fieldTypes={fieldTypes}
        fieldNames={fieldNames}
      />
      <Dialog
        open={previewOpen}
        TransitionComponent={Transition}
        fullWidth={"lg"}
        maxWidth={"lg"}
        keepMounted
        onClose={handlePreviewClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <AccountLedgerPreviewPopup ledgerType={tableState} />
        </DialogContent>
      </Dialog>
      </div>
  );
}
// Component Properties
AccountLedgerLogTable.propTypes = {
  // Store objects:
  account: PropTypes.object.isRequired,
};

// Component State
function AccountLedgerLogTableState(state) {
  return {
    account: state.account,
  };
}
export default connect(AccountLedgerLogTableState, {})(AccountLedgerLogTable);
