//React Components and Hooks
//Styles
import { ThemeProvider } from "@material-ui/core/styles";
import { LeftSidebar } from "layout-blueprints";
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import UbilSnackbar from "./modules/snackbar/components/snackbar";
import Accounting from "./pages/Accounting";
import Accounts from "./pages/Accounts";
import Batches from "./pages/Batches";
import BillingDashboard from "./pages/BillingDashboard";
import BillingWizard from "./pages/BillingWizard";
import BulkCheckEntry from "./pages/BulkCheckEntry";
import Dashboard from "./pages/Dashboard";
import DevPage from "./pages/Dev";
import Developers from "./pages/Developers";
import ForgotPassword from "./pages/ForgotPassword";
import Home from "./pages/Home";
import AccountManagerPage from "./pages/IndividualAccount";
import IndividualMeterPage from "./pages/IndividualMeter";
import IndividualPropertyPage from "./pages/IndividualProperty";
import IndividualPropertyManagerPage from "./pages/IndividualPropertyManager";
import IndividualReport from "./pages/IndividualReport";
import IndividualUnitPage from "./pages/IndividualUnit";
import Login from "./pages/Login";
import MeterReadingsUpload from "./pages/MeterReadingsUpload";
import Moves from "./pages/Moves";
import Owners from "./pages/Owners";
import PropertyManagerSearch from "./pages/PMSearch";
import PropertiesPage from "./pages/Properties";
import Reports from "./pages/Reports";
import ResetPassword from "./pages/ResetPassword";
import Settings from "./pages/Settings";
import WorkOrders from "./pages/WorkOrders";
import theme from "./theme";
//Custom Components
import RoutePrivate from "./utils/PrivateRoute";
import Routes from "./Routes"

const App = () => {
  console.log("~~~~~~~~~~~~~~~~~~~~~~~~~~In app component!!!!!");
  return (
    <React.Fragment>
      <Router>
        {Routes}
      </Router>
    </React.Fragment>
  );
};

export default withRouter(App);
