import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";

import Helmet from "react-helmet";

import {
  Checkbox,
  Grid,
  IconButton,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  FormControlLabel,
  Switch,
  Menu,
  MenuItem,
  TextField,
  Button as MuiButton,
  FormControl,
  InputLabel,
  Select,
  Input,
  Card,
  CircularProgress as MuiCircularProgress,
} from "@material-ui/core";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  Loop as LoopIcon,
} from "@material-ui/icons";

import Skeleton from "@material-ui/lab/Skeleton";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";

//Material UI Components and Functions
import { makeStyles } from "@material-ui/core/styles";

import { spacing } from "@material-ui/system";

import {
  EditableTable,
  newDropDownField,
} from "../../../components/EditableTable";

// Redux Components
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logout } from "../../user/api/actions";

const Button = styled(MuiButton)(spacing);

const DashboardToolBarButton = styled(Button)`
  padding: 4px;
  min-width: 0;
  svg {
    width: 2em;
    height: 2em;
  }
`;

const CircularProgress = styled(MuiCircularProgress)(spacing);

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  percentDif: {
    textAlign: "right",
    color: "green",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  loadingIcon: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Spacer = styled.div`
  flex: 1 1 100%;
`;

//Editable Table Object Expects: {objectArray, fieldTitles(Array), fieldTypes(Array), fieldNames(Array), handleRowClickParentMethod}
// Method can take line items if they exist initially or starts as an empty array of nothing passed
function BatchBillsTable(props) {
  const classes = useStyles();
  const history = useHistory();
  const navTo = (page) => history.push(page);
  const fieldTitles = [
    "Bill ID",
    "Account",
    "Posted",
    "Email",
    "PDF",
    "View Bill",
    "Print Bill",
    "Updated",
  ];
  const fieldTypes = [
    "labelSmall",
    "labelBold",
    "icon",
    "icon",
    "icon",
    "buttonLink",
    "icon",
    "dateTimeLabel",
  ];
  const fieldNames = [
    "accountBillId",
    "accountNum",
    "posted",
    "email",
    "pdf",
    "url",
    "printed",
    "updated",
  ]; // Must be the exact name of the properties you wanna show

  // const onBatchSelected = (item) => {
  //   props.getBillInProgressDraft(item.wtUUID).then(() => {
  //     props.updateBillingWizardSelectedProperty(item.property);
  //     navTo("/billingwizard");
  //   });
  // }

  // const onBatchSelect = (item) => {
  //   navTo(`batches/${item.id}`);
  // }

  // const createNewDraft = () => {
  //   props.resetBillingObject();
  //   navTo("/billingwizard");
  // }
  props.billing.data.selectedBatch.items.map((bill) => {

  });
  useEffect(() => { }, []);

  return (
    <Grid item xs={12}>
      {props.billing.data.selectedBatch.batchSet.status == "Complete" && (


      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={3}>
        <Button
          variant="contained"
          color="primary"
          href={props.billing.data.selectedBatch.batchSet.url}
          target="_blank"
        >
          Download All Bills
        </Button>
      </Grid>
      <Grid item xs={3}>
         <Button
         variant="contained"
         color="secondary"
         href={props.billing.data.selectedBatch.batchSet.printedUrl}
         target="_blank"
         >
         Download Bills for Print
         </Button>
      </Grid>
      <Grid item xs={3}>
        <Button
          variant="contained"
          color="secondary"
          disabled={true}
          onClick={() => {
            //TODO - Reprocess Failed Bills
          }}
        >
          Reprocess Failed Bills
        </Button>
        </Grid>
      </Grid>

       
      
      )}
      <Divider className="mb-3 mt-3" />

      <EditableTable
        // Functional methods of table
        // Properties of table
        isLoading={
          props.billing.billingBatchDataIsLoading &&
          props.billing.data.selectedBatch.items.length == 0
        }
        tableTitle={"Bills By Batch"}
        readOnly={true}
        canAddItems={false}
        canSave={false}
        objectArray={props.billing.data.selectedBatch}
        fieldTitles={fieldTitles}
        fieldTypes={fieldTypes}
        //handleRowClickParentMethod={}
        fieldNames={fieldNames}
        showButtons={false}
      />
    </Grid>
  );
}
// Component Properties
BatchBillsTable.propTypes = {
  // Store objects:
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  billing: PropTypes.object.isRequired,
  // Store object functions:
  getAllBillingDashboardInfo: PropTypes.func.isRequired,
  getBillInProgressDraft: PropTypes.func.isRequired,
  updateBillingWizardBillInProgress: PropTypes.func.isRequired,
  updateBillingWizardSelectedProperty: PropTypes.func.isRequired,
  resetBillingObject: PropTypes.func.isRequired,
  getBillingBatchById: PropTypes.func.isRequired,
};

// Component State
function BatchBillsTableState(state) {
  return {
    user: state.user,
    apl: state.apl,
    billing: state.billing,
  };
}
export default connect(BatchBillsTableState, { logout })(BatchBillsTable);
