//React Components and Hooks
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
//Material UI Components and Functions
import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import { Prompt } from 'react-router-dom'
import clsx from 'clsx';

import {
  DataGridPro,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  useGridApiRef,
  GridCellParams,
  GridToolbar
} from "@mui/x-data-grid-pro";
import MuiAlert from "@material-ui/lab/Alert";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import moment from "moment"
import {
  fetchPreviousLockboxUploads,
  handleError,
  handleLockboxsUploadSubmission,
  receivedReadingsSubmission,
} from "../../modules/lockbox-uploads/api/actions";
import { Card } from '@material-ui/core';
import CountUp from 'react-countup';
import { setSnackbar } from "modules/snackbar/api/snackbar";
import csvToArray from "./helpers/csvToArray";
import _ from "lodash"
import "./index.css";
import UploadsTable from "./UploadsTable";
import { styled } from '@mui/material/styles';
import LocalTable from "../Manage/LocalTable"

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`action-tabpanel-${index}`}
      aria-labelledby={`action-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pt={3} pl={-1}>
          {children}
        </Box>
      )}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `action-tab-${index}`,
    "aria-controls": `action-tabpanel-${index}`,
  };
}


function csvJSON(csv){

  let data = csv.trim()

  var lines=data.split("\n");

  var result = [];

  var headers=lines[0].split(",");

  for(var i=1;i<lines.length;i++){

	  var obj = {};
	  var currentline=lines[i].split(",");

	  for(var j=0;j<headers.length;j++){
		  obj[headers[j]] = currentline[j];
	  }

	  result.push(obj);

  }
  
  //return result; //JavaScript object
  return result //JSON
}

const useStyles = makeStyles({});

const Input = styled('input')({
  display: 'none',
});

const LockboxUpload = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const apiRef = useGridApiRef();
  const [value, setValue] = useState(0);
  const [file, setFile] = useState({});
  const [csvData, setCsvData] = useState({ rows: [], columns: [] });
  const [recentFile, setRecentFile] = useState("");
  const [successState, setSuccessState] = useState(false);
  const [errorState, setErrorState] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0.00)
  const [accountNums, setAccountNums] = useState([])
  const [loading, setLoading] = useState(false)

  const getUploads = () => {
    try {
      //props.fetchPreviousLockboxUploads();
    } catch (err) {
      setErrorState(true);
    }
  };
  

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSuccess = () => {
    setSuccessState(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccessState(false);
    setErrorState(false);
  };

  const fileInputChange = (e) => {
    let fileTypeRegEx = /(?:\.([^.]+))?$/;
    let fileType = fileTypeRegEx.exec(e.target.files[0].name)[1];

    if (fileType !== "txt") {
      props.handleError({ message: "Please upload a txt file." });
      setErrorState(true);
    } else {
      setFile(e.target.files[0])
      var file = e.target.files[0];
        var reader = new FileReader();
        reader.onload = function(e) {
          // The file's text will be printed here
          let final = "R,ignore1,AccountNumber,PaymentDate,Month,CheckNumber,ignore2,Amount,PaymentType,Fee\n" + e.target.result;
          console.log(final)
          let result = csvJSON(final)
          let formatted = []
          formatted = formatData(result, true);
          console.log(formatted)
          
          setCsvData(formatted);
        };

        reader.readAsText(file);
    };
  };

  const submitReadingsUpload = (e) => {
    e.preventDefault();

    let reader = new FileReader();
    setLoading(true);
    reader.onload = async function (evt) {
      
      try {
        // props.receivedReadingsSubmission(data); // if we implement preview feature
        for(let row of csvData.rows) {
          let result = await props.handleLockboxsUploadSubmission(
            [row],
            [row.AccountNumber]
          );
          if (result?.error == true) {
            console.log(apiRef.current)
            row.Uploaded = result.message
            setCsvData(csvData)
           // dispatch(setSnackbar(true, "error", result.message));
          }
          else {
            handleSuccess(`Successfully Uploaded ${result.length} meter reads.`);
            row.Uploaded = "Successful"
            setCsvData(csvData)
          //  setRecentFile(file.name);
          }
        }
        
      } catch (err) {
        setErrorState(true);
      }
    };
    setLoading(false);
    reader.readAsText(file);
  };

  useEffect(() => {
    getUploads();
  }, []);

  useEffect(() => {
    console.log(csvData)
  }, [csvData]);

  const formatData = (datas, caseChange) => {
    const keys = Object.keys(datas[0]);
    keys.push("Uploaded")
    let output = keys.map((key) => {

      if(key == "Uploaded") {
        
      }

      return {
        field: key,
        editable: false,
        hide:   key == "ignore1" ||
        key == "ignore2" ||
        key == "R" || key == "Month",
        width: 200,
        headerName: (caseChange) ? _.startCase(key) : key,
        cellClassName: (params) => {
          if (params.value == null) {
            return '';
          }
    
          return clsx('super-app', {
            negative: params.value.includes("Success"),
            positive: params.value.includes("Error"),
          });
        }
      };
      


    });



    let checksTotal = 0;
    let accountNumList = [];
    datas.forEach(function (element, index) {
      delete element.ignore1;
      delete element.ignore2;
      delete element.month;

      accountNumList.push(element.AccountNumber);
      element.PaymentDate = moment.utc(element.PaymentDate, 'YYMMDD').utc().subtract(moment().utcOffset(), "m").format("YYYY-MM-DD HH:mm:ss");
      checksTotal += Number(element.Amount);
      element.id = index + 1;
    });

    setAccountNums(accountNumList);

    setTotalAmount(checksTotal)
    return { rows: datas, columns: output };
  };

  

  const PromptOnLeave = () => (
      <Prompt
        when={props.lockBoxUpload.isLoading == true}
        message="Attention! Uploads have not finished. Please do not navigate away from page."
      />
  );

  let errorMessage = ''
  //   props.lockboxUpload.error && props.lockboxUpload.error.message
  //     ? props.lockboxUpload.error.message
  //     : null;

  return (
    <Grid className={classes.root} container item xs={12}>
          <PromptOnLeave/>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={successState}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          Successfully submitted lockbox upload.
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={errorState}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>

      <Grid container item>
        <Grid md={12} container item>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="primary"
            variant="fullWidth"
            aria-label="action tabs example"
          >
            <Tab label="Upload Lockbox" {...a11yProps(0)} />
            <Tab label="Previous Lockbox Uploads" {...a11yProps(1)} />
          </Tabs>
        </Grid>
      </Grid>

      <Grid container item>
        <Grid item md={12}>
          <TabPanel value={value} index={0} dir={theme.direction}>
            <form method="post" action="#" id="#">
              <div className="meter-readings-upload-form-container">
                <div className="meter-readings-upload-form">

                <input
                className={classes.input}
                style={{ display: 'none' }}
                onChange={fileInputChange}
                id="raised-button-file"
                multiple=""
                type="file"
              />
               <Card className="p-3 mb-4">
                  <div className="text-black-50 pb-2">Checks Total</div>
                  <div className="d-flex justify-content-between align-items-end">
                    <h3 className="display-4 mb-0 text-black">
                      <small className="pr-1 text-black-50">$</small>
                      <span className="text-success">
                        <CountUp
                          start={0}
                          end={totalAmount}
                          duration={3}
                          deplay={2}
                          separator=""
                          decimals={2}
                          decimal="."
                        />
                      </span>
                    </h3>
                  </div>
                </Card>
              <label htmlFor="raised-button-file">
                <Button color="primary" variant="contained" component="span">
                  Upload
                </Button>
              </label> 
                </div>
                {csvData.rows.length > 0 &&
                <Button
                  classes={classes.root}
                  disabled={
                    props.lockBoxUpload.isLoading
                  }
                  variant="contained"
                  color="secondary"
                  onClick={submitReadingsUpload}
                >
                  {props.lockBoxUpload.isLoading  ? (
                    <CircularProgress color="inherit" />
                  ) : (
                    "Submit"
                  )}
                </Button> }
              </div>
            </form>

            <div style={{ height: 850, width: "100%" }}>            
            <DataGridPro
                {...csvData}
                rowHeight={38}
                apiRef={apiRef}
                components={{ Toolbar: GridToolbar }}
                sx={{
                  width: '100%',
                  '& .super-app-theme--cell': {
                    backgroundColor: 'rgba(224, 183, 60, 0.55)',
                    color: '#1a3e72',
                    fontWeight: '600',
                  },
                  '& .super-app.negative': {
                    backgroundColor: 'rgba(157, 255, 118, 0.49)',
                    color: '#1a3e72',
                    fontWeight: '600',
                  },
                  '& .super-app.positive': {
                    backgroundColor: '#d47483',
                    color: '#1a3e72',
                    fontWeight: '600',
                  },
                }}
                />
            </div>
          </TabPanel>
        </Grid>

        <Grid>
          {/* <TabPanel value={value} index={1} dir={theme.direction}>
            <UploadsTable
              uploads={
                props.lockboxUpload.data.previousLockboxUploads
              }
            />
          </TabPanel> */}
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    lockBoxUpload: state.lockBoxUpload,
  };
};

export default connect(mapStateToProps, {
  handleLockboxsUploadSubmission,
  receivedReadingsSubmission,
  handleError,
  fetchPreviousLockboxUploads,
})(LockboxUpload);
