import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import { createTheme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
import { withRouter } from 'react-router-dom'
import axios from "axios";
import React from "react"
import { useDispatch } from "react-redux";
import { Dialog, Grid } from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import Stack from '@mui/material/Stack';
import { v4 as uuidv4 } from 'uuid';
import Box from "@mui/material/Box";
import { setSnackbar } from "modules/snackbar/api/snackbar";

function DataTable(props) {
  const [data, setData] = React.useState({ rows: [], columns: [] });
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [createdUnits, setCreatedUnits] = React.useState([])
  const [rows, setRows] = React.useState([]);
  const [showMenu, setShowMenu] = React.useState(false);
  const [validate, setValidate] = React.useState(true);

   const [columns, setColumns] = React.useState([
    { field: 'address1', headerName: 'Address 1', width: 150, editable: true},
    { field: 'address2', headerName: 'Address 2', width: 150, editable: true },
    { field: 'commonAccount', headerName: 'Common', type: 'boolean', width: 150, editable: true },
    { field: 'sqFt', headerName: 'SqFt', width: 150, editable: true },
    { field: 'bedrooms', headerName: 'Beds', width: 150, editable: true },
    { field: 'baths', headerName: 'Baths', width: 150, editable: true },
    { field: 'owner', headerName: 'Owner', width: 150, editable: false },
    { field: 'developer', headerName: 'Dev', width: 150, editable: false },
    { field: 'propertyManagerName', headerName: 'PM', width: 150, editable: false },
  ]);
  const [index, setIndex] = React.useState(0);
  const dispatch = useDispatch();
  const defaultTheme = createTheme();
  const apiRef = useGridApiRef();
  const [completed, setCompleted] = React.useState(0);
  const [buffer, setBuffer] = React.useState(1);
  const normalise = (value) => ((value - 0) * 100) / (data.rows.length - 0);
  let propertyUuid = props.match.params.propertyUuid;

  let billColumns = [
    { field: 'address1', headerName: 'Address 1', width: 150, editable: true},
    { field: 'address2', headerName: 'Address 2', width: 150, editable: true },
    { field: 'commonAccount', headerName: 'Common', type: 'boolean', width: 150, editable: true },
    { field: 'sqFt', headerName: 'SqFt', width: 150, editable: true },
    { field: 'bedrooms', headerName: 'Beds', width: 150, editable: true },
    { field: 'baths', headerName: 'Baths', width: 150, editable: true },
    { field: 'owner', headerName: 'Owner', width: 150, editable: false },
    { field: 'developer', headerName: 'Dev', width: 150, editable: false },
    { field: 'propertyManagerName', headerName: 'PM', width: 150, editable: false },
    { field: 'meterNumber', headerName: 'Meter', width: 150, editable: true },
    { field: 'ertNumber', headerName: 'ERT', width: 150, editable: true },
    { field: 'read', headerName: 'First Read', width: 150, editable: true },
    { field: 'description', headerName: 'Meter Desc.', width: 150, editable: true },
    { field: 'readSequence', headerName: 'Read Sequence', width: 150, editable: true },
    { field: 'readDecimals', headerName: 'Read Decimal', width: 150, editable: true },
    { field: 'meterCode', headerName: 'Meter Code', width: 150, editable: true },
    { field: 'multiplier', headerName: 'Multiplier', width: 150, editable: true },
  ]


  const handleDeleteRow = () => {
    const rowIds = apiRef.current.getSelectedRows();
    for(let rowId of rowIds) {
      apiRef.current.updateRows([{ id: rowId[0], _action: 'delete' }]);
    }
      
  
  };


  const handleMeterAddRow = () => {
    const rowIds = apiRef.current.getSelectedRows();
    for(let rowId of rowIds) {
       apiRef.current.updateRows([{ id: uuidv4(), 
        ...rowId[0],
        relatedAddressId: rowId[0], 
        address1: `${rowId[1].address1} - Extra Meter`, 
        address2: `${rowId[1].address2} - Extra Meter`, 
        extraMeter: true,
      }]);
    }
    apiRef.current.applySorting();


  };

  const handleLocalSave = () => {
    const localRows = apiRef.current.getRowModels();
    let save = [];
    console.log(localRows)
    if(localRows.size > 0) {
      for(let row of localRows) {
        save.push(row[1]);
      }



      localStorage.setItem(`batchSave-${propertyUuid}`, JSON.stringify(save))
      localStorage.setItem(`props.activeAccountStatus.id`, props.activeAccountStatus.id) 
      localStorage.setItem(`props.pmCustomerType.id`, props.pmCustomerType.id) 
      localStorage.setItem(`props.selectedPM`, JSON.stringify(props.selectedPM)); 

      dispatch(setSnackbar(true, "success", "Saved."))
    }
    else {
      dispatch(setSnackbar(true, "warning ", "Nothing to save..."))

    }

  }

  const handleRestore = () => {
    if(localStorage.getItem(`batchSave-${propertyUuid}`)) {
      let restoreRows = JSON.parse(localStorage.getItem(`batchSave-${propertyUuid}`));
      let formatted = formatRestoreCustomUnitData(restoreRows)
      setData(formatted);

    }

  }

  const [editRowsModel, setEditRowsModel] = React.useState({});

  const handleEditRowsModelChange = React.useCallback((model) => {
    setEditRowsModel(model);
  }, []);

  const handleRowEditStop = React.useCallback((model) => {
    setEditRowsModel(model);
  }, []);

  const handleAddRow = () => {
      const current = apiRef.current.getRowModels();
      if(current.size > 0) {
        const copy = current.entries();
        const newCopy = copy.next().value[1];
        const newObj = { 
           ...newCopy,
           id: uuidv4(),
           address2: "**NEW**",
          }

        apiRef.current.updateRows([newObj]);
      }
   
  };

  const progress = React.useRef(() => {});
  React.useEffect(() => {
    progress.current = () => {
      if (completed > data.rows.length) {
      } else {

        setCompleted(normalise(createdUnits.length));
        setBuffer(normalise(createdUnits.length + 1));
      }
    };
  });

  React.useEffect(() => {
    setLoading(true);
    let formated = [];

    if(props.data.length > 0) {
      formated = formatCustomUnitData(props.data);
      setData(formated);
    }

    setLoading(false);
  }, [props.data]);

  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  React.useEffect(() => {
    function tick() {
      progress.current();
    }
    const timer = setInterval(tick, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const useStyles = makeStyles(
    (theme) =>
      createStyles({
        root: {
          flexDirection: "column",
          "& .ant-empty-img-1": {
            fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
          },
          "& .ant-empty-img-2": {
            fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
          },
          "& .ant-empty-img-3": {
            fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
          },
          "& .ant-empty-img-4": {
            fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
          },
          "& .ant-empty-img-5": {
            fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
            fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
          },
        },
        label: {
          marginTop: theme.spacing(1),
        },
        title: {
          flexGrow: 1,
        },
        percentDif: {
          textAlign: "right",
          color: "green",
        },
        backdrop: {
          zIndex: theme.zIndex.drawer + 1,
          color: "#fff",
        },
        loadingIcon: {
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        },
        padding: {
          paddingTop: 10,
        },
      }),
    { defaultTheme }
  );
  const classes = useStyles();

  const handleChange = (event) => {};



  const createUnits = async () => {
    const records = apiRef.current.getRowModels();
    let installs = [];
    handleToggle()
    let index = 0;
    for(let record of records) {
      index++;
      if(!validateTable(record[1])) {
        dispatch(setSnackbar(true, "error", `Invalid table. Missing data at row ${index}`))
        handleToggle()
        return;
      }

      let activeAccountStatus = localStorage.getItem(`props.activeAccountStatus.id`);
      let pmCustomerType = localStorage.getItem(`props.pmCustomerType.id`) 
      let selectedPM = JSON.parse(localStorage.getItem(`props.selectedPM`));

      let install = {unit: {}, meters: [], account: {} }
      let body = record[1]
      let unit = {
        unitTypeId: Number(body.unitType),
        propertyId: body.propertyId,
        propertyManagerId: body.propertyManager,
        developerId: body.developerId,
        ownerId: body.ownerId,
        commonAccount: body.commonAccount == false ? 0 : 1,
        address1: body.address1,
        address2: body.address2,
        city: body.city,
        state: body.state,
        zip: body.zip,
        sqFt: body.sqFt,
        baths: body.baths,
        bedrooms: body.bedrooms,
        buildingNumber: body.buildingNumber
      }

      let meters = {
        meter: {
          meterNum: String(body.meterNumber) || "",
          ERTNum: String(body.ertNumber) || "",
          description: body.description || "",
          readSequence: String(body.readSequence) || "",
          meterCode: String(body.meterCode) || "",
          multiplier: Number(body.multiplier) || "",
          readDecimals: String(body.readDecimals) || "",
        },
        read: {
          readDate: body.moveInDate,
          readAmount: body.read
        }

      
    }
    let billType = props.props.properties?.data?.property?.propertyUtilityType?.billingType

    let account = {
      firstName: "",
      middleName: "",
      lastName: props.selectedPM?.name || selectedPM?.name || "PM",
      customerTypeId: props.pmCustomerType.id || Number(pmCustomerType) || 4, // set  Management Co as default
      phone1: props.selectedPM?.phone || selectedPM?.phone,
      email: props.selectedPM?.billingEmail || selectedPM?.billingEmail,
      accountStatusId: (billType == "BILL") ? props.activeAccountStatus.id || Number(activeAccountStatus) : 4,
      disconnected: 0,
      moveInDateOverride: body.moveInDate,
      medicalRequired: 0,
      stopPayment: 0,
      autoPayments: 0,
      last4SSN: 1234,
      eBills: 1,
      printedBills: 0,
      inCollections: 0
    }
    

        if(body.extraMeter) {
          installs.map(obj => {
            if(obj.id == body.relatedAddressId) {
              obj.meters.push(meters);
            }
          })
        } else {
          install.id = body.id;
          install.meters.push(meters);
          install.unit = unit;
          install.account = account;
          install.billingType = billType;
          installs.push(install);
        }

    }


    for(let install of installs) {
      try {      
          let response =  await axios.post("/property/unit/install", install)
            if(response.status = 200) 
              setCreatedUnits(createdUnits => [...createdUnits, response.data])
            else {
              setCreatedUnits(createdUnits => [...createdUnits, {message: `${install.unit.address1} ${install.unit.address2}`}])
            }
            setCompleted(normalise(completed+1))
            const rowIds = apiRef.current.getRowModels();
            for(let rowId of rowIds) {
              apiRef.current.updateRows([{ id: rowId[0], _action: 'delete' }]);
            }
      }
      catch(error) {
        setCompleted(normalise(completed+1))
        setCreatedUnits(createdUnits => [...createdUnits, {message: `${install.unit.address1} ${install.unit.address2}`}])
    }

  }
  handleClose()
  dispatch(setSnackbar(true, "success", `Successfully created ${installs.length} units`))

}

    const validateTable = (row) => {
          let valid = true;
          if(row.extraMeter){
            //require only meter information
            if(row.read === "" || row.read === null || row.read === undefined) {
              return false;
            }
            if(row.meterCode === "" || row.meterCode === null || row.meterCode === undefined) {
              return false;
            }
            if(row.multiplier === "" || row.multiplier === null || row.multiplier === undefined) {
              return false;
            }
            if(row.readDecimals === "" || row.readDecimals === null || row.readDecimals === undefined) {
              return false;
            }
            if(row.readSequence === "" || row.readSequence === null || row.readSequence === undefined) {
              return false;
            }
            if(row.ertNumber === "" || row.ertNumber === null || row.ertNumber === undefined) {
              return false;
            }
            if(row.meterNumber === "" || row.meterNumber === null || row.meterNumber === undefined) {
              return false;
            }
          }
          else {
            for(var key in row) {
              if(row[key] === "") {
                if(key == "startUnit" || key == "address2")
                {}
                  //nothing
                else 
                  valid = false;
              }
          }
          }
          return valid;
    }


  const formatCustomUnitData = (datas) => {

    const unitRows = datas.map(data => {
      return { id: uuidv4(), 
          ...data,
          ertNumber: "1",
          meterNumber: "1",
          description: "Meter",
          readSequence: "1",
          meterCode: "PRIMARY",
          read: 0,
          multiplier: 1.0000000,
          readDecimals: "NO",
          extraMeter: false,
       }
    });

    setRows(unitRows);
 

    return { rows: unitRows, columns: columns }
  }

  const formatRestoreCustomUnitData = (datas) => {

    const unitRows = datas.map(data => {
      return { 
          ...data,
       }
    });

    setRows(unitRows);
 

    return { rows: unitRows, columns: columns }
  }


  const getTreeDataPath = (row) => row.path;

const [sortModel, setSortModel] = React.useState([
    {
      field: 'address2',
      sort: 'asc',
    },
  ]);

  return (
      <div style={{ height: 850, width: "100%" }}>
          <Stack
              alignItems="flex-start"
              columnGap={1}
              direction="row"
              sx={{ width: '100%', mb: 1 }}
          >
              {showMenu == true && 
              <div>
                  <Button
                      onClick={handleDeleteRow}
                      size="small"
                  >
                      Delete Address
                  </Button>
                  <Button
                      onClick={handleAddRow}
                      size="small"
                  >
                      Add Address
                  </Button>
                  <Button
                      onClick={handleMeterAddRow}
                      size="small"
                  >
                      Add a Meter
                  </Button> 
              </div>}
              <Button
                  onClick={handleLocalSave}
                  size="small"
              >
                  Save Locally
              </Button>
              <Button
                  onClick={handleRestore}
                  size="small"
              >
                  Restore from Save Point
              </Button>
              {rows.length > 0 &&

              <Button
                  className="m-2"
                  color="primary"
                  onClick={createUnits}
                  variant="contained"
              >
                  CREATE ALL UNITS
              </Button> }
          </Stack>
          <Box
              sx={{
        height: "100%",
        width: 1,
        '& .meter': {
          backgroundColor: '#b9d5ff91',
          color: '#1a3e72',
        },
        '& .invalid': {
          backgroundColor: '#f90d0d75',
          color: '#1a3e72',
        },
        '& .Mui-error': {
          bgcolor: (theme) =>
            `rgb(126,10,15, ${theme.palette.mode === 'dark' ? 0 : 0.1})`,
          color: (theme) => (theme.palette.mode === 'dark' ? '#ff4343' : '#750f0f'),
        },
      }}
          >
              {/* <Alert severity="info" style={{ marginTop: 8 }}>
        <code>{`editRowsModel: ${validate} ${JSON.stringify(editRowsModel)}`}</code>
      </Alert> */}
              <DataGridPro
                  apiRef={apiRef}
                  checkboxSelection
                  columns={props.props.properties?.data?.property?.propertyUtilityType?.billingType === 'BILL' ? billColumns : columns}
                  disableSelectionOnClick
        // editMode="row"
                  editMode="cell"
                  editRowsModel={editRowsModel} 
                  getRowClassName={(params) => {

          let valid = true;

          if(params.row.extraMeter){
            //require only meter information
            if(params.row.read === "" || params.row.read === null || params.row.read === undefined) {
              return "invalid"
            }
            if(params.row.meterCode === "" || params.row.meterCode === null || params.row.meterCode === undefined) {
              return "invalid"
            }
            if(params.row.multiplier === "" || params.row.multiplier === null || params.row.multiplier === undefined) {
              return "invalid"
            }
            if(params.row.readDecimals === "" || params.row.readDecimals === null || params.row.readDecimals === undefined) {
              return "invalid"
            }
            if(params.row.readSequence === "" || params.row.readSequence === null || params.row.readSequence === undefined) {
              return "invalid"
            }
            if(params.row.ertNumber === "" || params.row.ertNumber === null || params.row.ertNumber === undefined) {
              return "invalid"
            }
            if(params.row.meterNumber === "" || params.row.meterNumber === null || params.row.meterNumber === undefined) {
              return "invalid"
            }
          }
          else {
            for(var key in params.row) {
              if(params.row[key] === "") {
                if(key == "startUnit" || key == "address2")
                {}
                  //nothing
                else 
                  valid = false;
              }
            }
            if(!valid)
              return 'invalid'
          }
        }}
                  onEditRowsModelChange={handleEditRowsModelChange}
                  onRowEditStop={handleRowEditStop}
                  onSelectionModelChange={(ids) => {
          const selectedIDs = new Set(ids);
          const selectedRowData = rows.filter((row) => {
            selectedIDs.has(row.id.toString());
          });

          if(selectedIDs.size > 0) {
            setShowMenu(true)
          }
          else 
            setShowMenu(false)
          
        }}
                  rows={rows}
                  sortModel={sortModel}
                  sx={{
          '& .MuiDataGrid-cell:hover': {
            color: 'primary.main',
          },
        }}
              />
          </Box>
          <Dialog
              maxWidth="md"
              open={open}
              scroll="body"
          >
              <LinearProgress
                  className="mb-4"
                  color="primary"
                  value={completed}
                  valueBuffer={buffer}
                  variant="buffer"
              />
              <div className="p-4">
                  <Grid
                      container
                      spacing={4}
                  >
                      <h1>
                          Creating Units...
                      </h1>
                      <Grid
                          item
                          lg={12}
                      >
                          <Button
                              onClick={handleToggle}
                              size="small"
                          >
                              Close
                          </Button>
                          <Stack
                              spacing={2}
                              sx={{ width: '100%' }}
                          >
                              {createdUnits.map(unit => (
                                  <div>
                                      { !unit.message ? ( 
                                          <MuiAlert severity="success">
                                              {`Successfully Created Unit: ${unit?.unit?.address1} ${unit?.unit?.address2}`}
                                          </MuiAlert>) : (
                                              <MuiAlert severity="error">
                                                  {`Error Creating Unit: ${unit.message}`}
                                              </MuiAlert>
                      )}
      
                                  </div>
              ))}
                          </Stack>
                      </Grid>
                  </Grid>
              </div>
          </Dialog>

      </div>
  );
}

export default withRouter(DataTable);