import {
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
// Custom Componenets
//Material UI Components and Functions
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { spacing } from "@material-ui/system";
// Redux Components
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import {
  handleBillingProcessSubmission,
  updateBillingWizardBillInProgress,
} from "../../../../modules/billing/api/actions";
import { formatMoney, numberWithCommas } from "../../../../setup/helpers";

const CenteredContent = styled.div`
  text-align: center;
`;

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const FormControl = styled(MuiFormControl)(spacing);
const TextField = styled(MuiTextField)(spacing);

// Custom Styles for property selection drop down

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  selectedPropertyLocationLabel: {
    fontSize: 50,
    backgroundColor: "#fffffff",
    borderColor: "#fffffff",
  },
  loadingIconContainer: {
    display: "flex",
    alignItems: "center",
  },
  loadingIcon: {
    position: "relative",
    padding: "50px",
  },
  meterReadingSuccessColor: {
    color: "#1e4620",
  },
  meterReadingFailColor: {
    color: "#611a15",
  },
  meterReadingValue: {
    fontWeight: "bolder",
  },
  totalsSubValue: {
    fontSize: 18,
  },
}));

const TrialBillingSummary = (props) => {
  const classes = useStyles();
  const [summary, setSummary] = React.useState(null);

  // On initial page load methods:
  useEffect(() => {
    if (!props.billing.billInProgressIsLoading) {
      // Totals
      let newSummary = {
        totalCharge: 0,
        CFUsageAmount: 0,
        tenentCFUsage: 0,
        tenentAccountCharges: 0,
        commonAccountCFUsage: 0,
        commonAccountCharges: 0,
        finalAccountCFUsage: 0,
        finalAccountCharges: 0
      }

      for (var x = 0; x < props.billing.data.billInProgress.trialBillingItems.items.length; x++) {

        newSummary.totalCharge = newSummary.totalCharge + props.billing.data.billInProgress.trialBillingItems.items[x].totalCharge;
        newSummary.CFUsageAmount = newSummary.CFUsageAmount + props.billing.data.billInProgress.trialBillingItems.items[x].usage;
        if (props.billing.data.billInProgress.trialBillingItems.items[x].commonAccount == 1) // Should make the default just an empty string
        {
          newSummary.commonAccountCharges = newSummary.commonAccountCharges + props.billing.data.billInProgress.trialBillingItems.items[x].totalCharge;
          newSummary.commonAccountCFUsage = newSummary.commonAccountCFUsage + props.billing.data.billInProgress.trialBillingItems.items[x].usage;
        }
        else {
          newSummary.tenentAccountCharges = newSummary.tenentAccountCharges + props.billing.data.billInProgress.trialBillingItems.items[x].totalCharge;
          newSummary.tenentCFUsage = newSummary.tenentCFUsage + props.billing.data.billInProgress.trialBillingItems.items[x].usage;
        }
        if (props.billing.data.billInProgress.trialBillingItems.items[x].finalRead == 1) // Should make the default just an empty string
        {
          newSummary.finalAccountCharges = newSummary.finalAccountCharges + props.billing.data.billInProgress.trialBillingItems.items[x].totalCharge;
          newSummary.finalAccountCFUsage = newSummary.finalAccountCFUsage + props.billing.data.billInProgress.trialBillingItems.items[x].usage;
        }
      }
      setSummary(newSummary)
    }

  }, [props.billing.data.billInProgress.trialBillingItems, props.billing.data.billInProgress.vendorInformation, props.billing.billInProgressIsLoading]);


  return (
    <Card mb={6} elevation={3}>
      <CardContent>
        {/* Main Content (Just imports the properties meter data manager) */}
        {props.billing.isLoading || summary == null ? (
          <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"
            spacing={4}
          >
            <Grid item xs={12} lg={12}>
              <div className={classes.loadingIcon}>
                <CircularProgress m={2} />
              </div>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={4} lg={12}>
            <Grid item xs={12} lg={12} spacing={4}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="h5">
                          {props.billingType !== 'BILL' ? 'Vendor Bill Value' : 'Water Usage Charge Total:'}
                        </Typography>
                        <Typography variant="h5">
                          Total To Be Billed:
                        </Typography>
                        <Typography variant="h5">Total Profit</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h5">
                          {props.billingType !== 'BILL' ?
                            formatMoney(props.billing.data.billInProgress.vendorInformation.billTotal)
                            :
                            formatMoney(props.billing.data.billInProgress.vendorInformation.waterUsageChargeTotal)}
                        </Typography>
                        <Typography variant="h5">
                          {formatMoney(
                            props.billing.data.billInProgress.billingSummary
                              .totalToBeBilled
                          )}
                        </Typography>
                        <Typography variant="h5">
                          {props.billingType !== 'BILL'
                            ? formatMoney(
                            props.billing.data.billInProgress.billingSummary
                              .totalToBeBilled -
                            props.billing.data.billInProgress
                              .vendorInformation.billTotal
                          ) :
                            formatMoney(
                              props.billing.data.billInProgress.billingSummary
                                .totalToBeBilled -
                              props.billing.data.billInProgress
                                .vendorInformation.waterUsageChargeTotal)
                          }
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="h5">
                          Vendor Bill Usage (CF):
                        </Typography>
                        <Typography variant="h5">
                          Usage To Be Billed (CF):
                        </Typography>
                        <Typography
                          variant="h5"
                          className={classes.totalsSubValue}
                        >
                          CF Usage Difference
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h5">
                          {numberWithCommas(
                            props.billing.data.billInProgress.vendorInformation
                              .usageTotal
                          )}{" "}
                          <i>(CF)</i>
                        </Typography>
                        <Typography variant="h5">
                          {numberWithCommas(summary.CFUsageAmount)} <i>(CF)</i>
                        </Typography>
                        <Typography
                          variant="h5"
                          className={classes.totalsSubValue}
                        >
                          {numberWithCommas(
                            props.billing.data.billInProgress.vendorInformation
                              .usageTotal - summary.CFUsageAmount
                          )}{" "}
                          <i>(CF)</i>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

// Component Properties
TrialBillingSummary.propTypes = {
  // Store objects:
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  billing: PropTypes.object.isRequired,
  // Store object functions:
  handleBillingProcessSubmission: PropTypes.func.isRequired,
  updateBillingWizardBillInProgress: PropTypes.func.isRequired,
};

// Component State
function TrialBillingSummaryState(state) {
  return {
    user: state.user,
    apl: state.apl,
    billing: state.billing,
  };
}
export default connect(TrialBillingSummaryState, {
  handleBillingProcessSubmission,
  updateBillingWizardBillInProgress,
})(TrialBillingSummary);
