//Material UI Components
import { makeStyles } from '@material-ui/core/styles';
//React Components and Hooks
import {
    Grid,
    Breadcrumbs as MuiBreadcrumbs,
    Divider as MuiDivider,
    Paper as MuiPaper,
    MenuItem,
    TextField,
    Card as MuiCard,
    CardContent,
    CircularProgress,
    FormControl,
    Typography,
    InputLabel,
    Select,
    Dialog,
    DialogContent,
    DialogTitle,
    select,
    Slide,
    IconButton,
    ButtonGroup,
    Button
} from "@material-ui/core";
import {
    Search as SearchIcon
} from "@material-ui/icons";
//Custom Context
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Breadcrumbs from "../../components/BreadCrumb";
//Context
//Custom Components
import { EditableTable } from "../../components/EditableTable";
//Custom Components
import { LeftSidebar as NavFrame } from "layout-blueprints";
// snackbar
import { setSnackbar } from "../../modules/snackbar/api/snackbar";
//modals
import EditDeveloper from './modals/EditDeveloper';
import NewDeveloper from './modals/NewDeveloper';
// utils
import filterDevelopers from './utils/filterDevelopers';







//Styles
const useStyles = makeStyles((theme) => ({
    root: {
        '& label.Mui-focused': {
            color: theme.palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: theme.palette.secondary.main,
        },
        '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
                borderColor: theme.palette.secondary.main,
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.secondary.main,
            },
        },
    },
    textcontainer: {
        backgroundColor: theme.palette.secondary.main,
        width: "98%",
        display: "flex",
        justifyContent: "flex-start",
        padding: "1rem",
    },
    headerText: {
        color: theme.palette.secondary.contrastText,
        fontWeight: "bold"
    },
    active: {
        fontWeight: "bold",
        color: "#f9a825",
        borderColor: "#f9a825",
    },
    inactive: {
        fontWeight: "bold",
    }
}))

const DevelopersPage = () => {
    const classes = useStyles();
    const [searchQuery, setSearchQuery] = useState("");
    const [developers, setDevelopers] = useState([]);
    const [developersToDisplay, setDevelopersToDisplay] = useState({ items: [] });
    const [developersLoading, setDevelopersLoading] = useState(true);
    const [developersStatusFilter, setDeveloperStatusFilter] = useState('Active');
    const [selectedDeveloper, setSelectedDeveloper] = useState({});

    const [refreshDataSwitch, setRefreshDataSwitch] = useState(false); // switches between true and false to trigger useEffect refetching of data

    const [editFormOpen, setEditFormOpen] = useState(false);
    const [newDeveloperFormOpen, setNewDeveloperFormOpen] = useState(false);

    const dispatch = useDispatch();



    const changeDevelopersDisplaying = (developers, term, status) => {
        let filteredDevelopers = filterDevelopers(developers, term, status);

        setDevelopersToDisplay(filteredDevelopers);
    }

    const asyncEffect = async () => {
        try {
            let headers = {
                'x-access-token': localStorage.getItem('token')
            }

            let data = await axios.get('/developer', { headers })

            if (data.status != 200)
                throw new Error('Fail.')

            setDevelopersLoading(false);
            setDevelopers(data.data);
            changeDevelopersDisplaying(data.data, searchQuery, developersStatusFilter)

        } catch (err) {
            setDevelopersLoading(false);
            dispatch(setSnackbar(true, "error", "Failed to fetch developers."))
        }

    }

    useEffect(() => {
        document.title = "Ubil+ Developers";
        asyncEffect()
    }, [refreshDataSwitch])


    const fieldTitles = ["Name", "Contact Name", "Status", "Address", ""];
    const fieldTypes = ["labelSmall", "labelSmall", "labelSmall", ["labelSmall", "labelSmall"], "editBtn"];
    const fieldNames = ["name", "contactName", "status", ["addrLine1", "addrLine2"], ""]; // Must be the exact name of the properties you wanna show

    const handleSearchQueryInput = (event) => {
        setSearchQuery(String(event.target.value));
        changeDevelopersDisplaying(developers, event.target.value, developersStatusFilter);
    };

    const CurrentLookupTypeButton = () => {
        return (
            <IconButton>
                <SearchIcon mr={2} />
            </IconButton>
        );
    };

    const SearchBar = () => {
        return (
            <TextField
                id={"searchTarget"}
                label="Search Developers"
                autoComplete={"off"}
                type={"text"}
                placeholder={"Search by name, contact name, address, and acct #..."}
                onChange={handleSearchQueryInput}
                autoFocus={"true"}
                //ref={inputElement}
                inputRef={text => text && text.focus()}
                name={"searchTarget"}
                defaultValue={searchQuery}
                fullWidth
                size={"small"}
                variant={"outlined"}
                my={4}
                ml={4}
                InputLabelProps={{
                    shrink: true,
                }}
                InputProps={{ endAdornment: <CurrentLookupTypeButton /> }}
            ></TextField>
        );
    };

    const changeDevelopersStatus = (val) => {
        setDeveloperStatusFilter(val);
        changeDevelopersDisplaying(developers, searchQuery, val);
    }

    const handleDeveloperEditFormOpen = (item) => {
        setSelectedDeveloper(item);
        setEditFormOpen(true);
    };

    const handleCloseEdit = () => {
        setEditFormOpen(false);
    };

    const handleNewDeveloperFormOpen = () => {
        setNewDeveloperFormOpen(true);
    }

    const handleNewDeveloperFormClose = () => {
        setNewDeveloperFormOpen(false);
    }


    const submitDeveloperEdit = async (originalDeveloperObj, editDeveloperObj) => {
        try {

            let headers = {
                'x-access-token': localStorage.getItem('token')
            }

            let data = await axios.put(`/developer/${originalDeveloperObj.uuid}`, editDeveloperObj, { headers })

            if (data.status != 200)
                throw new Error('Fail.')

            dispatch(setSnackbar(true, "success", "Successfully edited developer info."))
            setRefreshDataSwitch(!refreshDataSwitch);

        } catch (err) {
            dispatch(setSnackbar(true, "error", "Failed to edit developer."))
        }
    }

    const submitNewDeveloper = async (developerObj) => {
        try {

            let headers = {
                'x-access-token': localStorage.getItem('token')
            }

            let data = await axios.post(`/developer`, developerObj, { headers });

            if (data.status != 200)
                throw new Error('Fail.')

            dispatch(setSnackbar(true, "success", "Successfully created new developer info."))
            setRefreshDataSwitch(!refreshDataSwitch);

        } catch (err) {
            dispatch(setSnackbar(true, "error", "Failed to create new developer."))
        }
    }


    const Filters = () => {
        return (
            <Grid
                container
                item
                direction="row"
                xs={12}
                spacing={1}
                alignItems="center"
            >
                <Grid item lg={6} md={6} sm={6}>
                    <ButtonGroup
                        color="primary"
                    >
                        <Button
                            onClick={() => { changeDevelopersStatus("All") }}
                            className={
                                developersStatusFilter == "All"
                                    ? classes.active
                                    : classes.inactive
                            }
                        >
                            All
                        </Button>
                        <Button
                            onClick={() => { changeDevelopersStatus("Active") }}
                            className={
                                developersStatusFilter == "Active"
                                    ? classes.active
                                    : classes.inactive
                            }
                        >
                            Active
                        </Button>
                        <Button
                            onClick={() => { changeDevelopersStatus("Inactive") }}
                            className={
                                developersStatusFilter == "Inactive"
                                    ? classes.active
                                    : classes.inactive
                            }
                        >
                            Inactive
                        </Button>
                    </ButtonGroup>
                </Grid>
            </Grid>
        );
    };


    const toShowAll = { items: JSON.parse(JSON.stringify(developersToDisplay.items)), toUpdate: [], toDelete: [] };
    // Loops through each item:
    for (var x = 0; x < toShowAll.items.length;) {
        // Filtering by Search Query
        if (developersStatusFilter != "All") {
            if ((developersStatusFilter == "Active" && toShowAll.items[x].status != "Active") || (developersStatusFilter == "Inactive" && toShowAll.items[x].status != "Inactive")) {
                toShowAll.items.splice(x, 1);
                continue;
            }
        }
        x++;
    }

    return (
        <NavFrame>
            <EditDeveloper
                open={editFormOpen}
                handleClose={handleCloseEdit}
                developer={selectedDeveloper}
                submitDeveloperEdit={submitDeveloperEdit}
            />
            <NewDeveloper
                open={newDeveloperFormOpen}
                handleClose={handleNewDeveloperFormClose}
                submitNewDeveloper={submitNewDeveloper}
            />
            <Grid container className={classes.root}>
                <Grid container item direction="column" xs={12}>
                    <Grid container>
                        <Grid item container direction="row" xs={12} justify="space-between">
                            <Grid item xs={12} sm={12} md={6}>
                                <Typography variant="h4" gutterBottom>
                                    Manage Developers
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Breadcrumbs current={"Developers"} />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={6} justify="space-between">
                        <Grid item xs={2}></Grid>
                        <Grid item xs={4}>
                            <SearchBar />
                        </Grid>
                        <Grid item xs={4}>
                            <Button onClick={handleNewDeveloperFormOpen} size="small" variant="contained" color="secondary"> New Developer</Button>
                        </Grid>
                        <Grid item xs={2}></Grid>
                    </Grid>
                    <Grid container direction="column" spacing={1}>
                        <Grid container item direction="row" justify="space-between">
                            <EditableTable
                                // Functional methods of table
                                Filters={<Filters />}
                                canSave={false}
                                isLoading={developersLoading}
                                currentActiveFilters={[developersStatusFilter]}
                                canAddItems={false}
                                saveOnTextboxLeave={true}
                                defaultItemCount={10}
                                // Properties of table
                                tableTitle={"Developers"}
                                objectArray={toShowAll}
                                fieldTitles={fieldTitles}
                                fieldTypes={fieldTypes}
                                fieldNames={fieldNames}
                                canExport={true}
                                showItemNumber={true}
                                handlePreviewClickParentMethod={handleDeveloperEditFormOpen}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </NavFrame>
    )
}

export default DevelopersPage;