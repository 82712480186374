import {
  Button,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  Modal,
  Box,
  Typography,
  TextField as MuiTextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
// Custom Componenets
//Material UI Components and Functions
import { makeStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
import state from "modules/account/api/state";
// Redux Components
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import {
  handleBillingProcessRunning,
  handleBillingProcessSubmission,
  updateBillingWizardBillInProgress,
} from "../../../../modules/billing/api/actions";

const CenteredContent = styled.div`
  text-align: center;
`;

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const FormControl = styled(MuiFormControl)(spacing);
const TextField = styled(MuiTextField)(spacing);

// Custom Styles for property selection drop down
// Custom Styles for property selection drop down

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  selectedPropertyLocationLabel: {
    fontSize: 50,
    backgroundColor: "#fffffff",
    borderColor: "#fffffff",
  },
  loadingIcon: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

const FinalizeForm = (props) => {
  const history = useHistory();
  const navTo = (page) => history.push(page);
  const classes = useStyles();
  const [modalOpen, setModalOpen] = React.useState(false);

  const handleClose = () => {
      setModalOpen(false)
  }
  const handleOpen = () => {
    setModalOpen(true)
  }

  const saveTrialItemsAndRun = () => {
    props.billing.data.billInProgress.executeTrialBills = true;
    props.handleBillingProcessRunning(props.billing.data.billInProgress);
  };
  const saveTrialItemsAndExit = () => {
    props.updateBillingWizardBillInProgress(
      props.billing.data.billInProgress.currentStep
    );
    props.handleBillingProcessSubmission(props.billing.data.billInProgress);

    window.open("", "_self");
    window.close();
  };

  return (
    <>
    <Card mb={6} elevation={3}>
      <CardContent>
        {/* Main Content (Just imports the properties meter data manager) */}
        <Grid container spacing={4}>
          {/*             Col 1            */}
          <Grid item lg={6} md={12} sm={12}>
            <Button
              mr={1}
              variant="contained"
              color="secondary"
              fullWidth
              onClick={handleOpen}
              my={2}
            >
              Save Edits and Submit Work Table
            </Button>
          </Grid>
          {/*             Col 2            */}
          <Grid item lg={6} md={12} sm={12}>
            <Button
              mr={1}
              variant="contained"
              color="primary"
              fullWidth
              onClick={saveTrialItemsAndExit}
              my={2}
            >
              Save Edits and resume later
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
   
    <Dialog
    open={modalOpen}
    onClose={handleClose}
    >
       <Box>
        <DialogTitle id="modal-modal-title" variant="h6" component="h2">
           Please Confirm
          </DialogTitle>
         <DialogContent id="modal-modal-description" sx={{ mt: 2 }}>
          Do you want to save? 
          <br/>  
          <br/>
          <text style={{color: 'red'}}>*** This action cannot be undone ***</text>
         </DialogContent>
         <DialogActions>
         <Grid item lg={6} md={12} sm={12}>
         <Button
              mr={1}
              variant="contained"
              color="secondary"
              fullWidth
              onClick={saveTrialItemsAndRun}
              my={2}
            >
              Save
            </Button>
            </Grid>
            <Grid item lg={6} md={12} sm={12}>
            <Button
              mr={1}
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleClose}
              my={2}
            >
              Cancel
            </Button>
            </Grid>
            </DialogActions>
      </Box>
    </Dialog>
    </>
  );
};

// Component Properties
FinalizeForm.propTypes = {
  // Store objects:
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  billing: PropTypes.object.isRequired,
  // Store object functions:
  updateBillingWizardBillInProgress: PropTypes.func.isRequired,
  handleBillingProcessSubmission: PropTypes.func.isRequired,
  handleBillingProcessRunning: PropTypes.func.isRequired,
};

// Component State
function FinalizeFormState(state) {
  return {
    user: state.user,
    apl: state.apl,
    billing: state.billing,
  };
}
export default connect(FinalizeFormState, {
  updateBillingWizardBillInProgress,
  handleBillingProcessSubmission,
  handleBillingProcessRunning,
})(FinalizeForm);
