import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import EditDeveloperForm from "./EditDeveloperForm";

function getModalStyle() {
  const top = 40;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "auto",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
    maxHeight: "600px",
    overflow: "scroll",
  },
}));

export default (props) => {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  const open = props.open;
  const handleClose = props.handleClose;
  const developer = props.developer;
  const submitDeveloperEdit = props.submitDeveloperEdit;

  return (
    <Modal open={open} onClose={handleClose}>
      <div style={modalStyle} className={classes.paper}>
        <h1 style={{ textAlign: "left" }}>Edit Developer</h1>

        <EditDeveloperForm
          developer={developer}
          handleClose={handleClose}
          submitDeveloperEdit={submitDeveloperEdit}
        />
      </div>
    </Modal>
  );
};
