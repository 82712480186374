
import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import {
  Grid,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Paper as MuiPaper,
  MenuItem,
  TextField as MuiTextField,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  CircularProgress as MuiCircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  IconButton,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { spacing } from "@material-ui/system";
import { EditableTable } from "../../../components/EditableTable";

// Redux Components
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getAllAccountFiles, deleteAccountFile, updateAccountFilesState } from "../api/actions";
import Form from "./Form";

const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)(spacing);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AccountFilesTable(props) {
  const [display, setDisplay] = React.useState(false);
  const [searchFocus, setSearchFocus] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [accountFilesHaveBeenRequested, setAccountFilesHaveBeenRequested] = React.useState(false);

  useEffect(() => {
    if (props.account.data.account && props.account.data.account.id) {
      if (!accountFilesHaveBeenRequested && !props.account.isLoading && props.account.data.account.id) {
        setAccountFilesHaveBeenRequested(true);
        props.getAllAccountFiles(props.account.data.account.id);
      }
    }
  }, [props.account.isLoading]);

  const handleSearchTargetChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const setFocus = () => {
    setSearchFocus(true);
  };
  const unsetFocus = () => {
    setSearchFocus(false);
  };
  const CurrentLookupTypeButton = () => {
    return (
      <IconButton>
        <SearchIcon mr={2} />
      </IconButton>
    );
  };

  const SearchBar = () => {
    return (
      <Grid container xs={12} spacing={4}>
        <Grid item xs={12}>
          {
            searchFocus ?
              <TextField
                id={"searchTarget"}
                label="Search Account Files"
                type={"text"}
                placeholder={"Search by any file item field"}
                onChange={handleSearchTargetChange}
                autoFocus={"true"}
                onBlur={unsetFocus}
                inputRef={text => text && text.focus()}
                name={"searchTarget"}
                defaultValue={searchQuery}
                fullWidth
                size={"small"}
                variant={"outlined"}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{ endAdornment: <CurrentLookupTypeButton /> }}
              ></TextField>
              :
              <TextField
                id={"searchTarget"}
                label="Search Account Files"
                type={"text"}
                placeholder={"Search by any file item field"}
                onChange={handleSearchTargetChange}
                onClick={setFocus}
                name={"searchTarget"}
                defaultValue={searchQuery}
                fullWidth
                size={"small"}
                variant={"outlined"}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{ endAdornment: <CurrentLookupTypeButton /> }}
              ></TextField>
          }
        </Grid>
      </Grid>
    );
  };

  // Table Stuff:
  const fieldTitles = [
    "File Name",
    "Description",
    "Date Uploaded",
    "Download",
    "Uploaded By"
  ];
  const fieldTypes = ["label", "label", "dateLabel", "buttonLink","label"];
  const fieldNames = [
    "fileName",
    "description",
    "created",
    "url",
    "creatorName"
  ];

  const onSelected = (item) => {
    /*window.open(
      "/property/" + item.uuid + "/" + item.id + "/" + item.propertyManagerId
    );*/
  };

  const onUpdate = () => {
    hide();
  };

  const deleteSelectedFiles = async (itemUuids) => {
    for(let uuidToDelete of itemUuids) {
      await props.deleteAccountFile(uuidToDelete);
    };
    await props.getAllAccountFiles(props.account.data.account.id);
  };
  const show = () => {
    setDisplay(true);
  };

  const hide = () => {
    setDisplay(false);
  };

  const NewDialog = () => {
    return (
      <Dialog
        open={display}
        TransitionComponent={Transition}
        fullWidth={"lg"}
        maxWidth={"lg"}
        keepMounted
        onClose={hide}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">New Account File</DialogTitle>
        <DialogContent>
          <Form handleClose={hide} />
        </DialogContent>
      </Dialog>
    );
  };
  const toShow = { items: JSON.parse(JSON.stringify(props.files.data.accountFiles.items)), toUpdate: [], toDelete: [] };
  for (var x = 0; x < toShow.items.length;) {
    // Filtering by Search Query
    if (searchQuery != "") {
      if (!(toShow.items[x].fileName != null && toShow.items[x].fileName.includes(searchQuery)) &&
        !(toShow.items[x].description != null && toShow.items[x].description.includes(searchQuery)) &&
        !(toShow.items[x].creatorName != null && toShow.items[x].creatorName.includes(searchQuery))
      ) {
        toShow.items.splice(x, 1);
        continue;
      }
    }
    x++;
  }
  return (
    <Card mb={6} elevation={3}>
      <CardContent>
        <NewDialog />
        <SearchBar />
        <EditableTable
          updateItemsLocalState={updateAccountFilesState}
          readOnly={false}
          canSave={false}
          isLoading={props.files.isLoading}
          newItemCreationMethod={show}
          manualItemDeletionMethod={deleteSelectedFiles}
          handleRowClickParentMethod={onSelected}
          tableTitle={"Files Associated with this Account"}
          objectArray={toShow}
          fieldTitles={fieldTitles}
          fieldTypes={fieldTypes}
          fieldNames={fieldNames}
        />
      </CardContent>
    </Card>
  );
}

AccountFilesTable.propTypes = {
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  files: PropTypes.object.isRequired,
  getAllAccountFiles: PropTypes.func.isRequired,
  updateAccountFilesState: PropTypes.func.isRequired,
  deleteAccountFile: PropTypes.func.isRequired,
};

function AccountFilesTableState(state) {
  return {
    user: state.user,
    apl: state.apl,
    account: state.account,
    files: state.files,
  };
}

export default connect(AccountFilesTableState, { getAllAccountFiles, updateAccountFilesState, deleteAccountFile })(AccountFilesTable);
