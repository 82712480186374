export default (developers, searchTerm, activeFilter) => {
  // activeFilter => 'Active', 'Inactive', 'All'

  console.log("filtering....");
  let filteredDevelopers = developers.filter((developer) => {
    if (activeFilter == "Inactive" && developer.active == 1) return false;

    if (activeFilter == "Active" && developer.active == 0) return false;

    if (!searchTerm || searchTerm == "") return true;

    if (developer.name) {
      if (developer.name.toUpperCase().includes(searchTerm.toUpperCase()))
        return true;
    }

    if (developer.contactName) {
      if (
        developer.contactName.toUpperCase().includes(searchTerm.toUpperCase())
      )
        return true;
    }

    if (developer.address1) {
      if (developer.address1.toUpperCase().includes(searchTerm.toUpperCase()))
        return true;
    }

    if (developer.address2) {
      if (developer.address2.toUpperCase().includes(searchTerm.toUpperCase()))
        return true;
    }

    if (developer.id) {
      if (
        developer.id.toString().toUpperCase().includes(searchTerm.toUpperCase())
      )
        return true;
    }
  });

  return { items: filteredDevelopers };
};
