// App Imports
import {
  ACCOUNT_OBJECT_RESET,
  GET_ACCOUNT_BALANCE_REQUEST,
  GET_ACCOUNT_BALANCE_RESPONSE,
  GET_ACCOUNT_LEDGER_FAILURE,
  GET_ACCOUNT_LEDGER_REQUEST,
  GET_ACCOUNT_LEDGER_RESPONSE,
  GET_ACCOUNT_REQUEST,
  GET_ACCOUNT_RESPONSE,
  HYDRATE_ACCOUNT_REQUEST,
  HYDRATE_ACCOUNT_RESPONSE,
  METER_HISTORY_LOOKUP_REQUEST,
  METER_HISTORY_LOOKUP_RESPONSE,
  REFRESH_CLIENT_ACCOUNT_BALANCE,
  RESET_METER_ACCOUNT_READINGS,
  UPDATE_ACCOUNT_NOTES_ARRAY,
  UPDATE_ACCOUNT_REQUEST,
  UPDATE_ACCOUNT_RESPONSE,
  UPDATE_CLIENT_ACCOUNT_LEDGER,
  UPDATE_CLIENT_ACCOUNT_OBJECT,
  UPDATE_LOOKED_UP_METER,
  CREATE_ADDRESS_REQUEST,
  CREATE_ADDRESS_RESPONSE,
  CREATE_ADDRESS_FAILURE,
  UPDATE_ADDRESS_REQUEST,
  UPDATE_ADDRESS_RESPONSE,
  UPDATE_ADDRESS_FAILURE,
  DELETE_ADDRESS_REQUEST,
  DELETE_ADDRESS_RESPONSE,
  DELETE_ADDRESS_FAILURE,
  GET_ACCOUNT_BY_ACCTNUM_REQ,
  GET_ACCOUNT_BY_ACCTNUM_RESP,
  GET_ACCOUNT_BY_ACCTNUM_FAILURE,
  DELETE_ACCOUNT_REQUEST,
  DELETE_ACCOUNT_RESPONSE,
  DELETE_ACCOUNT_FAILURE,
  UPDATE_ACCOUNT_DISCOUNT_REQUEST,
  UPDATE_ACCOUNT_DISCOUNT_RESPONSE,
  UPDATE_ACCOUNT_DISCOUNT_FAILURE,
} from "./actions";
const today = new Date();
// Initial State
const accountsInitialState = {
  isLoading: true,
  error: null,
  data: {},
  // Keeps it apart of account but segregates it to its own loading state
  ledgerIsLoading: true,
  ledgerError: null,
  ledger: {},
  meterLookupIsLoading: true,
  meterReads: [],
  meterLookupError: null,
  isInitialAccountLoading: false,
  initialAccount: null,
  newForwardingAddress:
  {
    addressType: "Forwarding",
    //effectiveDate: today,
    address1: "",
    address2: "",
    city: "",
    zip: "",
    state: "",
  },
  newAddress:
  {
    addressType: "Billing",
    //effectiveDate: today,
    address1: "",
    address2: "",
    city: "",
    zip: "",
    state: "",
  }
};

// State
export default (state = accountsInitialState, action) => {
  switch (action.type) {
    case ACCOUNT_OBJECT_RESET:
      return Object.assign({}, accountsInitialState);
    case UPDATE_CLIENT_ACCOUNT_OBJECT:
      var newStateData = state.data;
      newStateData = action.newAccountObject;
      return {
        ...state,
        data: newStateData,
      };
    case METER_HISTORY_LOOKUP_REQUEST:
      return {
        ...state,
        meterLookupError: null,
        meterLookupIsLoading: true,
      };
    case RESET_METER_ACCOUNT_READINGS:
      state.meterReads = [];
      return {
        ...state,
      };
    case UPDATE_LOOKED_UP_METER:
      for (var x = 0; x < action.newMeterHistory.length; x++) {
        state.meterReads.push(action.newMeterHistory[x]);
      }

      const SortedMeterReads = state.meterReads.sort((a, b) => {
        return new Date(b.readDate) - new Date(a.readDate);
      });
      var newStateData = state.data;
      if (action.newMeterHistory.length > 0) {
        var meterId = action.newMeterHistory[0].meterId;
        for (var y = 0; y < state.data.unit.meters.length; y++) {
          if (state.data.unit.meters[y].id == meterId) {
            state.data.unit.meters[y].readCount = action.newMeterHistory.length;
          }
        }
      }
      newStateData.meterReads = SortedMeterReads;
      return {
        ...state,
        data: newStateData,
      };
    case METER_HISTORY_LOOKUP_RESPONSE:
      return {
        ...state,
        meterLookupError: action.error,
        meterLookupIsLoading: false,
      };
    case UPDATE_ACCOUNT_NOTES_ARRAY:
      //Sorts initial ledger by transaction date
      state.data.notes = action.error;
      const updatedData = state.data;
      return {
        ...state,
        data: updatedData,
      };
    case UPDATE_CLIENT_ACCOUNT_LEDGER:
      //Sorts initial ledger by transaction date
      const sortedLedger = action.newAccountLedger.entries
        .slice()
        .sort(
          (a, b) => new Date(b.transactionDate) - new Date(a.transactionDate)
        );
      action.newAccountLedger.entries = sortedLedger;
      return {
        ...state,
        ledger: action.newAccountLedger,
      };
    case CREATE_ADDRESS_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case CREATE_ADDRESS_RESPONSE:
      if (action.response.id) {
        if (action.response.addressType == "Billing") {
          state.data.addresses[0] = action.response;
        }
        else {
          state.data.forwardingAddress[0] = action.response;
        }
      }
      return {
        ...state,
        error: null,
        data: state.data,
        isLoading: false,
      };
    case CREATE_ADDRESS_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case GET_ACCOUNT_BY_ACCTNUM_REQ:
      return {
        ...state,
        error: null,
        isInitialAccountLoading: action.isLoading,
      };
    case GET_ACCOUNT_BY_ACCTNUM_RESP:
      var newInitialAccount = null;
      if (action.response && action.response.length > 0) {
        newInitialAccount = action.response[0];
      }
      return {
        ...state,
        isInitialAccountLoading: false,
        initialAccount: newInitialAccount
      };
    case GET_ACCOUNT_BY_ACCTNUM_FAILURE:
      return {
        ...state,
        error: action.error,
        isInitialAccountLoading: false,
      };
    case UPDATE_ADDRESS_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case UPDATE_ADDRESS_RESPONSE:
      if (action.response.id) {
        if (action.response.addressType == "Billing") {
          state.data.addresses[0] = action.response;
        }
        else {
          state.data.forwardingAddress[0] = action.response;
        }
      }
      return {
        ...state,
        error: null,
        data: state.data,
        isLoading: false,
      };
    case UPDATE_ADDRESS_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case GET_ACCOUNT_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case GET_ACCOUNT_RESPONSE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case GET_ACCOUNT_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case GET_ACCOUNT_RESPONSE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case DELETE_ADDRESS_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case DELETE_ADDRESS_RESPONSE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case DELETE_ADDRESS_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case GET_ACCOUNT_LEDGER_REQUEST:
      return {
        ...state,
        ledgerError: null,
        ledgerIsLoading: action.ledgerIsLoading,
      };
    case GET_ACCOUNT_LEDGER_RESPONSE:
      return {
        ...state,
        ledgerError: action.ledgerError,
        ledgerIsLoading: false,
      };
    case GET_ACCOUNT_LEDGER_FAILURE:
      return {
        ...state,
        ledgerError: action.ledgerError,
        ledgerIsLoading: false,
      };
    case REFRESH_CLIENT_ACCOUNT_BALANCE:
      state.data.ongoingAccountBalance = action.newBalanceObject;
      return {
        ...state,
        data: state.data,
      };
    case GET_ACCOUNT_BALANCE_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case GET_ACCOUNT_BALANCE_RESPONSE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case HYDRATE_ACCOUNT_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case HYDRATE_ACCOUNT_RESPONSE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case HYDRATE_ACCOUNT_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case UPDATE_ACCOUNT_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case UPDATE_ACCOUNT_RESPONSE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case DELETE_ACCOUNT_REQUEST:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case DELETE_ACCOUNT_RESPONSE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case DELETE_ACCOUNT_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case UPDATE_ACCOUNT_DISCOUNT_REQUEST:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case UPDATE_ACCOUNT_DISCOUNT_RESPONSE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case UPDATE_ACCOUNT_DISCOUNT_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };  
    default:
      return {
        ...state,
      };
  }
};
