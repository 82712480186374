import {
    Button as MuiButton,
    Card,
    CardContent,
    CircularProgress as MuiCircularProgress,
    Divider as MuiDivider,
    Grid,
    LinearProgress as MuiLinearProgress,
  } from "@material-ui/core";
  //import { login, logout } from '../../modules/user/api/actions';
  import Dialog from "@material-ui/core/Dialog";
  import DialogContent from "@material-ui/core/DialogContent";
  import DialogTitle from "@material-ui/core/DialogTitle";
  import Menu from "@material-ui/core/Menu";
  import MenuItem from "@material-ui/core/MenuItem";
  import Slide from "@material-ui/core/Slide";
  import { withStyles, makeStyles } from "@material-ui/core/styles";
  import { spacing } from "@material-ui/system";
  // Redux Components
  import PropTypes from "prop-types";
  import React, { useEffect } from "react";
  import { connect, useDispatch } from "react-redux";
  import { useHistory } from "react-router-dom";
  import styled from "styled-components/macro";
  import { EditableTable } from "../../../components/EditableTable";
  import StatusTag from "../../apl/components/StatusTag";
  import {
    createNewMeterReading,
    resetNewMeterRead,
  } from "../../meter/api/actions";
  import MeterReadForm from "../../meter/components/meterReadForm";
  import { setSnackbar } from "../../snackbar/api/snackbar";
  import {
    lookupMeterHistory,
    deleteMeterReading
  } from "../api/actions";
  import {
    getAllMeterReadTypes,
  } from "../../apl/api/actions";

  const LinearProgress = MuiLinearProgress;
  const Divider = styled(MuiDivider)(spacing);
  const Button = styled(MuiButton)(spacing);
  const CircularProgress = styled(MuiCircularProgress)(spacing);
  
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  
  const StyledMenu = withStyles({
    paper: {
      border: "1px solid #d3d4d5",
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      {...props}
    />
  ));

  const useStyles = makeStyles({
    chipTrue: {
      backgroundColor: 'green',
      marginRight: '2px',
      padding: '1px'
    },
  
    chipFalse: {
      backgroundColor: 'red',
      marginRight: '2px',
      padding: '1px'
    },
  
    chipIgnore: {
      marginRight: '2px'
    },
  
    actionIcons: {
      position: 'relative',
      top: '5px',
      fontSize: '18px',
      marginRight: '5px',
      cursor: 'pointer'
    },
  
    processedRow: {
      backgroundColor: 'rgba(211, 211, 211, 0.74)'
    },
    nonProcessedRow: {
      backgroundColor: 'none'
    },
  
    bgRed: {
      backgroundColor: '#F08080'
    },
    bgYellow: {
      backgroundColor: '#FFFACD'
    },
    bgBlue: {
      backgroundColor: '#ADD8E6'
    },
    bgNone: {
      backgroundColor: 'none'
    },
    active: {
      fontWeight: "bold",
      color: "#f9a825",
      borderColor: "#f9a825",
    },
    inactive: {
      fontWeight: "bold",
    },
  })
  
  const StyledNavMenuItem = withStyles((theme) => ({
    root: {
      "&:focus": {
        backgroundColor: theme.palette.primary.main,
        "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
          color: theme.palette.common.white,
        },
      },
    },
  }))(MenuItem);
  
  const SelectedAccountPropertyInformationCard = (props) => {

    
  const classes = useStyles();
   
    const history = useHistory();
    const dispatch = useDispatch();
    const [NewReadFormFormOpen, setNewReadFormOpen] = React.useState(false);
  
    const handleNewReadFormClickOpen = () => {
      setNewReadFormOpen(true);
    };
  
    const handleNewReadFormClose = () => {
      setNewReadFormOpen(false);
    };
  
    const handleSaveClick = () => {
      dispatch(
        setSnackbar(true, "warning", "Submitting New Account Meter Reading...")
      );
      // Handle the account object updating
      //const data = props.account.data;
      /*
      props.createNewMeterReading(props.meters.newMeterRead).then(() => {
        dispatch(
          setSnackbar(
            true,
            "success",
            "Meter Reading Creation Successful"
          )
        );
        dispatch(props.lookupMeterHistory());
        dispatch(resetNewMeterRead());
      }); // Sends account data to db
        */
  
      setNewReadFormOpen(false);
    };
  
    useEffect(() => {
      if (props.meters.data.meter.id){
        props.lookupMeterHistory(props.meters.data.meter.id);
      }
    }, [props.user, props.meters.meterIsLoading]);
  
  
    const onMeterReadSelected = (item) => { };
    const fieldTitles = [
      "#",
      "Date",
      "Read Type",
      "Read Amount",
      "Usage",
      "Billed",
    ];
    const fieldTypes = ["label", "dateLabel", "label", "label", "label", "label"];
    const fieldNames = [
      "id",
      "readDate",
      "meterReadType",
      "readAmount",
      "usage",
      "isBilled",
    ];
    
    const handleItemDeletion = (meterReads) => {
      
    dispatch(setSnackbar(true, "warning", "Deleting Meter Reads..."));
    console.log('deleteSelectedMeterReads');
    console.log(meterReads);
    meterReads.map((meterReadUuid) => {
      console.log(meterReadUuid);
      var canDelete = false;
      if (canDelete){
        props.deleteMeterReading(meterReadUuid, false).then(() => {
          if (!props.meters.meterLookupIsLoading) {
            if (props.meters.data.meter.id){
              props.lookupMeterHistory(props.meters.data.meter.id);
            }
          }
          if (props.meters.error != null) {
            dispatch(setSnackbar(true, "error", "Error Deleting Meter Reads!"));
          }
          else {
            dispatch(setSnackbar(true, "success", "Meter Reads Deleted"));
          }
        });
      }
    });
    };
    const MeterReadRowStatusStyling = (meterRead) => {
      if (meterRead.billed) {
        meterRead.isBilled = "YES";
      } else {
        meterRead.isBilled = "NO";
      }
      meterRead.meterReadType = (
        <StatusTag
          type={"meterReadingType"}
          isLoading={props.apl.genericTypes.meterReadingType.isLoading}
          typeId={meterRead.meterReadTypeId}
          showTitle={false}
          condensed={true}
        />
      );
      return {}; // Returns an empty style as to not effect each row
    };
  
    const createNewMeterRead = (meterReads) => {
      //props.resetSelectedAccountObject();
      //navTo("/billingwizard");
      //handleNewReadFormClickOpen();
    };
  
    return (
      <Card mb={6}>
        <CardContent xs={12} sm={12} md={12} lg={12}>
          <EditableTable
            readOnly={false}
            canAddItems={true}
            canDelete={true}
            manualItemDeletionMethod={handleItemDeletion}
            canSave={false}
            newItemCreationMethod={createNewMeterRead}
            isLoading={props.meters.meterLookupIsLoading}
            // Functional methods of table
            handleRowClickParentMethod={onMeterReadSelected}
            conditionalStylingMethod={MeterReadRowStatusStyling}
            // Properties of table
            tableTitle={"Meter History"}
            objectArray={props.meters.data.meterReads}
            objectError={props.meters.error}
            fieldTitles={fieldTitles}
            fieldTypes={fieldTypes}
            defaultItemCount={5}
            fieldNames={fieldNames}
          />
          <Dialog
            open={NewReadFormFormOpen}
            TransitionComponent={Transition}
            fullWidth={"lg"}
            maxWidth={"lg"}
            keepMounted
            onClose={handleNewReadFormClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>New Meter Reading</DialogTitle>
            <DialogContent>
              <MeterReadForm />
              <Grid item lg={6} md={12} sm={12}>
                {/* <Button
                  mr={2}
                  mb={2}
                  variant="contained"
                  color="secondary"
                  onClick={handleSaveClick}
                >
                  Submit Meter Reading
                </Button> */}
              </Grid>
            </DialogContent>
          </Dialog>
        </CardContent>
      </Card>
    );
  };
  
  // Component Properties
  SelectedAccountPropertyInformationCard.propTypes = {
    // Store objects:
    apl: PropTypes.object.isRequired,
    meters: PropTypes.object.isRequired,
  
    //createNewMeterReading: PropTypes.func.isRequired,
    //resetNewMeterRead: PropTypes.func.isRequired,
    deleteMeterReading: PropTypes.func.isRequired,
    lookupMeterHistory: PropTypes.func.isRequired,
    getAllMeterReadTypes: PropTypes.func.isRequired
  };
  
  // Component State
  function SelectedAccountPropertyInformationCardState(state) {
    return {
      apl: state.apl,
      meters: state.meters,
    };
  }
  export default connect(SelectedAccountPropertyInformationCardState, {
    //createNewMeterReading,
    //resetNewMeterRead,
    lookupMeterHistory,
    deleteMeterReading,
    getAllMeterReadTypes
  })(SelectedAccountPropertyInformationCard);
  