// Imports
import axios from "axios";
import { GET, POST, PUT, DEL } from "../../genericAPI/api/actions";
import { setSnackbar } from "../../snackbar/api/snackbar";

// App Imports
require("../../../setup/config/apiConfig");

// Actions Types
export const BILL_GET_ACCOUNT_BILLS_REQUEST = "BILL_GET_ACCOUNT_BILLS_REQUEST";
export const BILL_GET_ACCOUNT_BILLS_RESPONSE =
  "BILL_GET_ACCOUNT_BILLS_RESPONSE";
export const BILL_GET_ACCOUNT_BILLS_FAILURE = "BILL_GET_ACCOUNT_BILLS_FAILURE";

export const TIMES_LATE_REQUEST = "TIMES_LATE_REQUEST";
export const TIMES_LATE_RESPONSE = "TIMES_LATE_RESPONSE";
export const TIMES_LATE_FAILURE = "TIMES_LATE_FAILURE";

export const BILL_GET_ALL_ACCOUNT_BILLS_REQUEST =
  "BILL_GET_ALL_ACCOUNT_BILLS_REQUEST";
export const BILL_GET_ALL_ACCOUNT_BILLS_RESPONSE =
  "BILL_GET_ALL_ACCOUNT_BILLS_RESPONSE";
export const BILL_GET_ALL_ACCOUNT_BILLS_FAILURE =
  "BILL_GET_ALL_ACCOUNT_BILLS_FAILURE";

export const BILL_ACCOUNT_BILLS_RESET = "BILL_ACCOUNT_BILLS_RESET";
export const SET_BILL_LIST = "BILL/SET_BILL_LIST";

export const BILL_CREAT_NEW_ACCOUNT_BILL_REQUEST =
  "BILL_CREAT_NEW_ACCOUNT_BILL_REQUEST";
export const BILL_CREAT_NEW_ACCOUNT_BILL_RESPONSE =
  "BILL_CREAT_NEW_ACCOUNT_BILL_RESPONSE";

export const BILL_GET_PM_BILLS_REQUEST = "BILL_GET_PM_BILLS_REQUEST";
export const BILL_GET_PM_BILLS_RESPONSE = "BILL_GET_PM_BILLS_RESPONSE";
export const SET_PM_BILL_LIST = "BILL/SET_PM_BILL_LIST";

export const BILL_DELETE_ACCOUNT_BILLS_FAILURE = "BILL_DELETE_ACCOUNT_BILLS_FAILURE"

export const BILL_DELETE_ACCOUNT_BILLS_REQUEST =
  "BILL_DELETE_ACCOUNT_BILLS_REQUEST";
export const BILL_DELETE_ACCOUNT_BILLS_RESPONSE =
  "BILL_DELETE_ACCOUNT_BILLS_RESPONSE";

export const BILL_UPDATE_NEW_ACCOUNT_BILL_IN_PROGRESS =
  "BILL_UPDATE_NEW_ACCOUNT_BILL_IN_PROGRESS";
export const SET_ERROR = "SET_ERROR";

// Actions
export function updatePmBillPacketList(data, resetCache, isBaseGroup) {
  return { type: SET_PM_BILL_LIST, data, resetCache, isBaseGroup };
}
export function setBills(data) {
  return { type: SET_BILL_LIST, data };
}
export function updateNewAccountBillInProgress(newBillData) {
  return { type: BILL_UPDATE_NEW_ACCOUNT_BILL_IN_PROGRESS, newBillData };
}

export function setError(error) {
  return { type: SET_ERROR, error };
}

export function get(
  page,
  pageLimit,
  accountUuid,
  isLoading = true,
  isReset = false
) {
  return (dispatch) => {
    dispatch({
      type: BILL_GET_ALL_ACCOUNT_BILLS_REQUEST,
      isLoading: isLoading,
    });
    let queryParams = {
      accountUuid,
      page: page,
      pageLimit: pageLimit,
    };
    return axios
      .get("/bills/account", { params: queryParams })
      .then((response) => {
        dispatch({
          type: BILL_GET_ALL_ACCOUNT_BILLS_RESPONSE,
          isLoading: false,
          data: response.data,
          isReset: isReset,
        });
      })
      .catch((error) => {
        dispatch({
          type: BILL_GET_ALL_ACCOUNT_BILLS_FAILURE,
          isLoading: false,
          error: error,
        });
      });
  };
}

export function getAccountTimesLateByAccountUuid(accountUuid) {
  return GET("/account-bill-times-late", accountUuid, TIMES_LATE_REQUEST, TIMES_LATE_RESPONSE, TIMES_LATE_FAILURE);
}

export function deleteBill(uuid, isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: BILL_DELETE_ACCOUNT_BILLS_REQUEST,
      isLoading: isLoading,
    });
    return axios
      .delete("/accountbill/" + uuid)
      .then((response) => {
        console.log(response);
        dispatch({
          type: BILL_DELETE_ACCOUNT_BILLS_RESPONSE,
          isLoading: false,
          error: null,
        });
        dispatch(setSnackbar(true, "success", "Bill successfully deleted."))
      })
      .catch((error) => {
        dispatch({
          type: BILL_DELETE_ACCOUNT_BILLS_FAILURE,
          isLoading: false,
          error: error,
        });
        dispatch(setSnackbar(true, "error", "Bill unable to be deleted."))
        
      });
  };
}

export function searchForPmBillPacketsByFilters({
  page,
  rowsPerPage,
  pmUuid,
  property,
  searchQuery,
  isLoading = true,
  isBaseGroup = false,
  resetCache = false,
}) {
  // provide default values because api endpoint breaks otherwise
  var target = "?";
  if (page || page == 0) {
    target = target + "page=" + page + "&";
  }
  if ((rowsPerPage && rowsPerPage != null) || rowsPerPage == 0) {
    target = target + "pageLimit=" + rowsPerPage + "&";
  }
  if (pmUuid && pmUuid != null) {
    target = target + "pmUuid=" + pmUuid + "&";
  }
  if (property && property != null) {
    target = target + "property=" + property + "&";
  }
  if (property && property != null) {
    target = target + "property=" + property + "&";
  }
  if (searchQuery && searchQuery != "") {
    target = target + "searchQuery=" + encodeURIComponent(searchQuery) + "&";
  }
  return (dispatch) => {
    dispatch({
      type: BILL_GET_PM_BILLS_REQUEST,
      isLoading: isLoading,
    });
    return axios
      .get("/propertymanagerbills-filtered/" + target)
      .then((response) => {
        let error = "";
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }

        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem("user"));
        if (currentUser) {
          dispatch(
            updatePmBillPacketList(response.data, resetCache, isBaseGroup)
          );
          dispatch({
            type: BILL_GET_PM_BILLS_RESPONSE,
            isLoading: false,
            error: error,
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: BILL_GET_PM_BILLS_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: BILL_GET_PM_BILLS_RESPONSE,
          isLoading: false,
          error: error,
        });
      });
  };
}

// Login a user using credentials
export function getUserBills(target, isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: BILL_GET_ACCOUNT_BILLS_REQUEST,
      isLoading: isLoading,
    });
    return axios
      .get("/accountbill/accountnum/" + target)
      .then((response) => {
        let error = "";
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }
        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem("user"));
        if (currentUser) {
          //Updating the bills obj in state
          dispatch({
            type: BILL_GET_ACCOUNT_BILLS_RESPONSE,
            isLoading: false,
            error: error,
            data: response.data,
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: BILL_GET_ACCOUNT_BILLS_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: BILL_GET_ACCOUNT_BILLS_FAILURE,
          isLoading: false,
          error: error,
        });
      });
  };
}

export function createNewAccountBill(
  newAccountBill,
  isFullBill,
  accountNum,
  isLoading = true
) {
  console.log("newAccountBill");
  console.log(newAccountBill);
  return (dispatch) => {
    dispatch({
      type: BILL_CREAT_NEW_ACCOUNT_BILL_REQUEST,
      isLoading,
    });

    return axios
      .post("/generate-new-billableitems", {
        payload: newAccountBill,
        isFullBill,
        accountNum,
      })
      .then((response) => {
        let error = "";


        return response.data.billUuid;


      })
      .catch((error) => {
        dispatch(setError(error));
        throw new Error(error);
      });
  };
}

export function finalizeBill(
  billUuid,
  isLoading = true
) {
  return (dispatch) => {
    dispatch({
      type: BILL_CREAT_NEW_ACCOUNT_BILL_REQUEST,
      isLoading,
    });

    return axios
      .post(`/accountbill/finalize/${billUuid}`, { isPmBill: false, createPdf: false, isStandAlone: true })
      .then((response) => {
        let error = "";


        return response.data;


      })
      .catch((error) => {
        dispatch(setError(error));
        throw new Error(error);
      });
  };
}

export function regenerateBillPdf(
  billUuid,
  isLoading = true
) {
  return (dispatch) => {
    dispatch({
      type: BILL_CREAT_NEW_ACCOUNT_BILL_REQUEST,
      isLoading,
    });

    return axios
      .post(`/accountbill/finalize/${billUuid}`, { isPmBill: false })
      .then((response) => {
        let error = "";


        return response.data;


      })
      .catch((error) => {
        dispatch(setError(error));
        throw new Error(error);
      });
  };
}


export function finalizePrint(
  body,
  isLoading = true
) {
  return (dispatch) => {
    dispatch({
      type: BILL_CREAT_NEW_ACCOUNT_BILL_REQUEST,
      isLoading,
    });

    return axios
      .post(`/accountbill/finalizeprint`, body)
      .then((response) => {
        let error = "";
        return response;
      })
      .catch((error) => {
        dispatch(setError(error));
        throw new Error(error);
      });
  };
}
