import {
  Card as MuiCard,
  CardContent,
  CircularProgress as MuiCircularProgress,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core";
//Material UI Components and Functions
import {makeStyles} from "@material-ui/core/styles";
import {spacing} from "@material-ui/system";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
// Redux Components
import PropTypes from "prop-types";
import React from "react";
import {connect, useDispatch} from "react-redux";
import styled from "styled-components/macro";
import {getAllPropertyPreviews} from "../../../../modules/apl/api/actions";
import {
  getAllUnsettledPropertyMeterReadingsByPropertyUUID,
  handleBillingProcessSubmission,
  updateBillingWizardBillInProgress,
  updateBillingWizardSelectedProperty,
  updateBillInProgressBillCalculation,
} from "../../../../modules/billing/api/actions";
// Custom UI componets and functions:
import AdditionalBillLineItemsManager from "../../../../modules/billing/components/AdditionalBillLineIItemsChart";
import { logout } from "../../../../modules/user/api/actions";
import { formatMoney, txtBoxDateFormatter } from "../../../../setup/helpers";
import VendorBillForm from "../../../BillingInformationTable/Components/Forms/VendorBillForm";
import VendorSelect from "../../../BillingInformationTable/Components/Forms/VendorSelect";

const CenteredContent = styled.div`
    text-align: center;
`;

const CircularProgress = styled(MuiCircularProgress)(spacing);
const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const FormControl = styled(MuiFormControl)(spacing);
const TextField = styled(MuiTextField)(spacing);
const SpacedCurrencyTextField = styled(CurrencyTextField)(spacing);

// Custom Styles for property selection drop down

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  loadingIcon: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  typography: {
    flexGrow: 1,
    textAlign: "center",
  },
}));

const VendorInformationForm = (props) => {
  // Handles vendor drop down
  const [vendor, setVendor] = React.useState(
    props.billing.data.billInProgress.vendorInformation.vendorID
  );
  const [fiscalMonth, setFiscalMonth] = React.useState(
    props.billing.data.billInProgress.vendorInformation.fiscalMonth
  );
  const [vendorFormObject, setVendorFormObject] = React.useState(
    props.billing.data.billInProgress.vendorInformation
  );

  const [currentBillTotal, setBillTotal] = React.useState(
    props.billing.data.billInProgress.vendorInformation.billTotal
  );
  const [currentCostPerKWH, setCostPerKWH] = React.useState(
    props.billing.data.billInProgress.vendorInformation.costPerKWH
  );

  const dispatch = useDispatch();
  const handleSelectedVendorChange = (event) => {
    setVendor(event.target.value);
    onFieldChange(event);
  };

  const onFiscalMonthFieldChange = (event) => {
    setFiscalMonth(event.target.value);
    onFieldChange(event);
  };

  const classes = useStyles();

  const onFieldChange = (event) => {
    const {name, value} = event.target;
    const {vendorInformation} = props.billing.data.billInProgress;

    if (event.target.type === "number") {
      vendorInformation[name] = Number(value);
    } else if (event.target.id && event.target.id.includes("totalField_")) {
      let parsedValue = parseFloat(value.replace(/\,/g, ""));
      vendorInformation[name] = isNaN(parsedValue) ? 0 : parsedValue;
    } else {
      vendorInformation[name] = value;
    }
  };

  const HandleTextBoxDisselect = (event) => {
    // props.handleBillingProcessSubmission(
    //   props.billing.data.billInProgress,
    //   false
    // );
    console.log("onBlur")
  };

  // var billTotal = 0;
  // var costPerKWH = 0;
  // billTotal =
  //   billTotal +
  //   props.billing.data.billInProgress.vendorInformation.marketRateTotal +
  //   props.billing.data.billInProgress.vendorInformation.tariffRateTotal;
  // if (props.billing.data.billInProgress.vendorInformation.kwhUsedTotal > 0) {
  //   costPerKWH =
  //     billTotal /
  //     props.billing.data.billInProgress.vendorInformation.kwhUsedTotal;
  // }
  // props.billing.data.billInProgress.vendorInformation.billTotal = billTotal;
  // props.billing.data.billInProgress.vendorInformation.costPerKWH = costPerKWH;

  return (
    <Card mb={6} elevation={3}>
      <CardContent>
        {/* Main Grid */}
        {/*<Grid container spacing={4}>*/}
        {/*  <Grid item lg={4} md={12} sm={12}>*/}
        {/*    <FormControl fullWidth my={2}>*/}
        {/*      <InputLabel id="vendorIDDropDownLabel">*/}
        {/*        Vendor{"  "}*/}
        {/*        {props.apl.genericTypes.vendor.isLoading && (*/}
        {/*          <CircularProgress size={15} m={0}/>*/}
        {/*        )}*/}
        {/*      </InputLabel>*/}
        {/*      {vendor == "" && props.apl.genericTypes.vendor.isLoading ? (*/}
        {/*        <Select*/}
        {/*          labelId="vendorIDDropDownLabel"*/}
        {/*          id="vendorID"*/}
        {/*          name="vendorID"*/}
        {/*          className={classes.selectedPropertyLocationLabel}*/}
        {/*          onChange={handleSelectedVendorChange}*/}
        {/*          onBlur={HandleTextBoxDisselect}*/}
        {/*          fullWidth*/}
        {/*          disabled*/}
        {/*          my={2}*/}
        {/*        ></Select>*/}
        {/*      ) : (*/}
        {/*        <Select*/}
        {/*          labelId="vendorIDDropDownLabel"*/}
        {/*          id="vendorID"*/}
        {/*          name="vendorID"*/}
        {/*          defaultValue={vendorFormObject.vendorID}*/}
        {/*          className={classes.selectedPropertyLocationLabel}*/}
        {/*          onChange={handleSelectedVendorChange}*/}
        {/*          onBlur={HandleTextBoxDisselect}*/}
        {/*          fullWidth*/}
        {/*          my={2}*/}
        {/*        >*/}
        {/*          {props.apl.genericTypes.vendor.data.length > 0 ? [*/}
        {/*            props.apl.genericTypes.vendor.data.map((item, key) => {*/}
        {/*              return (*/}
        {/*                <MenuItem value={item.id} id={key}>*/}
        {/*                  {item.name}*/}
        {/*                </MenuItem>*/}
        {/*              );*/}
        {/*            })*/}
        {/*          ] : [*/}
        {/*            <MenuItem value={-1}>No Vendors Found</MenuItem>*/}
        {/*          ]}*/}
        {/*        </Select>*/}
        {/*      )}*/}
        {/*    </FormControl>*/}
        {/*  </Grid>*/}
        {/*  <Grid item lg={4} md={12} sm={12}>*/}
        {/*    <FormControl fullWidth my={2}>*/}
        {/*      <InputLabel id="fiscalMonthDropDownLabel">*/}
        {/*        Fiscal Month (MM)*/}
        {/*      </InputLabel>*/}
        {/*      <Select*/}
        {/*        labelId="fiscalMonthDropDownLabel"*/}
        {/*        id="fiscalMonth"*/}
        {/*        name="fiscalMonth"*/}
        {/*        defaultValue={fiscalMonth}*/}
        {/*        autoComplete={"off"}*/}
        {/*        onChange={onFiscalMonthFieldChange}*/}
        {/*        onBlur={HandleTextBoxDisselect}*/}
        {/*        fullWidth*/}
        {/*        my={2}*/}
        {/*      >*/}
        {/*        {[*/}
        {/*          <MenuItem value={1}>January</MenuItem>,*/}
        {/*          <MenuItem value={2}>February</MenuItem>,*/}
        {/*          <MenuItem value={3}>March</MenuItem>,*/}
        {/*          <MenuItem value={4}>April</MenuItem>,*/}
        {/*          <MenuItem value={5}>May</MenuItem>,*/}
        {/*          <MenuItem value={6}>June</MenuItem>,*/}
        {/*          <MenuItem value={7}>July</MenuItem>,*/}
        {/*          <MenuItem value={8}>August</MenuItem>,*/}
        {/*          <MenuItem value={9}>September</MenuItem>,*/}
        {/*          <MenuItem value={10}>October</MenuItem>,*/}
        {/*          <MenuItem value={11}>November</MenuItem>,*/}
        {/*          <MenuItem value={12}>December</MenuItem>*/}
        {/*        ]}*/}
        {/*      </Select>*/}
        {/*    </FormControl>*/}
        {/*  </Grid>*/}
        {/*  <Grid item lg={4} md={12} sm={12}>*/}
        {/*    <TextField*/}
        {/*      id="fiscalYear"*/}
        {/*      label="Fiscal Year (YYYY)"*/}
        {/*      type="number"*/}
        {/*      autoComplete={"off"}*/}
        {/*      fullWidth*/}
        {/*      my={2}*/}
        {/*      variant="outlined"*/}
        {/*      InputLabelProps={{*/}
        {/*        shrink: true,*/}
        {/*      }}*/}
        {/*      defaultValue={*/}
        {/*        vendorFormObject.fiscalYear != 0 && vendorFormObject.fiscalYear*/}
        {/*      }*/}
        {/*      onChange={onFieldChange}*/}
        {/*      onBlur={HandleTextBoxDisselect}*/}
        {/*      name="fiscalYear"*/}
        {/*    />*/}
        {/*  </Grid>*/}
        {/*  <Grid item lg={4} md={12} sm={12}>*/}
        {/*    <TextField*/}
        {/*      id="billDate"*/}
        {/*      label="Bill Date"*/}
        {/*      type="date"*/}
        {/*      fullWidth*/}
        {/*      my={2}*/}
        {/*      variant="outlined"*/}
        {/*      InputLabelProps={{*/}
        {/*        shrink: true,*/}
        {/*      }}*/}
        {/*      defaultValue={txtBoxDateFormatter(vendorFormObject.billDate)}*/}
        {/*      onChange={onFieldChange}*/}
        {/*      onBlur={HandleTextBoxDisselect}*/}
        {/*      name="billDate"*/}
        {/*    />*/}
        {/*  </Grid>*/}
        {/*  {props.billingType === 'BILL' &&*/}
        {/*    <Grid item lg={4} md={12} sm={12}>*/}
        {/*      <TextField*/}
        {/*        id="meterReadDate"*/}
        {/*        label="Meter Read Date"*/}
        {/*        type="date"*/}
        {/*        fullWidth*/}
        {/*        my={2}*/}
        {/*        variant="outlined"*/}
        {/*        InputLabelProps={{*/}
        {/*          shrink: true,*/}
        {/*        }}*/}
        {/*        defaultValue={txtBoxDateFormatter(vendorFormObject.meterReadDate)}*/}
        {/*        onChange={onFieldChange}*/}
        {/*        onBlur={HandleTextBoxDisselect}*/}
        {/*        name="meterReadDate"*/}
        {/*      />*/}
        {/*    </Grid>}*/}
        {/*  {(props.hold == 1 || props.billingType === 'BILL') && <VendorBillForm/>}*/}

        {/*  <Grid item lg={12} md={12} sm={12}>*/}
        {/*    <Divider className="mb-2 mt-2" my={1}/>*/}
        {/*  </Grid>*/}
        {/*</Grid>*/}
        <Grid container spacing={4} alignItems="flex-end">
        <VendorSelect/>
          {(props.hold === 1 || props.billingType==='BILL') &&
            <>
              <VendorBillForm/>
              <Grid item lg={12} md={12} sm={12}>
                <Divider className="mb-2 mt-2" my={1}/>
              </Grid>
            </>
          }
        </Grid>
        {/*Bottom of the page grid with additional line items*/}
        <Grid container spacing={4}>
          <Grid item lg={6} md={12} sm={12}>
            <Typography variant="h5" className={classes.typography}>
              Water Usage Total: CF {+(props.billing.data.billInProgress.vendorInformation.usageTotal || 0).toFixed(2)}
            </Typography>
            {props.billingType === 'BILL' &&
              <Typography variant="h5" className={classes.typography}>
                Water Usage Charge Total
                : {formatMoney(props.billing.data.billInProgress.vendorInformation.waterUsageChargeTotal)}
              </Typography>
            }
            {props.billingType !== 'BILL' &&
              <>
                <Typography variant="h5" className={classes.typography}>
                Vendor Bill Percentage
                : {props.billing.data.billInProgress.vendorInformation.vendorBillPercentage}
              </Typography>
                <Typography variant="h5" className={classes.typography}>
                  Water And Sewer
                  Charge: {formatMoney(props.billing.data.billInProgress.vendorInformation.billTotal)}
                </Typography>
                <Typography variant="h5" className={classes.typography}>
                  Water Rate, Cost / CF:
                  ${+(props.billing.data.billInProgress.vendorInformation.waterRate || 0)?.toFixed(2)}
                </Typography>
              </>
            }
          </Grid>
          <Grid item lg={12} md={12} sm={12}>
            <Divider className="mb-2 mt-2" my={1}/>
          </Grid>
          <AdditionalBillLineItemsManager props={props}/>
          {/*props.billing.data.billInProgress.currentStep == 1 &&
              <Grid item lg={12} md={12} sm={12}>
                <Button mr={1} variant="contained" color="secondary" fullWidth onClick={()=>{
                  props.updateBillingWizardBillInProgress(props.billing.data.billInProgress.currentStep);
                  props.handleBillingProcessSubmission(props.billing.data.billInProgress);
                  }} my={2}>Save And Continue</Button>
              </Grid>
                */}
        </Grid>
      </CardContent>
    </Card>
  );
};

// Component Properties
VendorInformationForm.propTypes = {
  // Store objects:
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  billing: PropTypes.object.isRequired,
  // Store object functions:
  getAllPropertyPreviews: PropTypes.func.isRequired,
  updateBillingWizardSelectedProperty: PropTypes.func.isRequired,
  updateBillingWizardBillInProgress: PropTypes.func.isRequired,
  getAllUnsettledPropertyMeterReadingsByPropertyUUID: PropTypes.func.isRequired,
  handleBillingProcessSubmission: PropTypes.func.isRequired,
};

// Component State
function VendorInformationFormState(state) {
  return {
    user: state.user,
    apl: state.apl,
    billing: state.billing,
    propertyUtilities: state.propertyUtilities
  };
}
export default connect(VendorInformationFormState, {
  logout,
  updateBillingWizardSelectedProperty,
  handleBillingProcessSubmission,
  updateBillingWizardBillInProgress,
  getAllPropertyPreviews,
  getAllUnsettledPropertyMeterReadingsByPropertyUUID,
})(VendorInformationForm);

//export default VendorInformationForm;
