import {
  Button as MuiButton,
  Card,
  CardContent,
  Divider as MuiDivider,
  Grid,
  Paper,
  Table,
  TableBody,
  Checkbox,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import TableContainer from "@material-ui/core/TableContainer";
import EditIcon from "@material-ui/icons/Edit";
import Skeleton from "@material-ui/lab/Skeleton";
import { spacing } from "@material-ui/system";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import MeterForm from "./Form";
import style from "./styling/style";

const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function DetailCard(props) {
  const useStyles = style;
  const classes = useStyles();

  const [display, setDisplay] = React.useState(false);

  const toggleDisplay = () => {
    const shouldDisplay = !display;
    setDisplay(shouldDisplay);
  };

  //if (!props.meters.data.meter ) return <div></div>

  return (
    <Card mb={6}>
      <CardContent xs={12}>
        <Grid container xs={12}>
          <Grid item xs={9} sm={9} md={9} lg={9}>
            <Typography variant="h6" gutterBottom>
              Meter Info
            </Typography>
          </Grid>
          <Grid
            item
            xs={3}
            sm={3}
            md={3}
            lg={3}
            className={classes.rightSideHeaderSubMenuToolBar}
          >
            <Button onClick={toggleDisplay}>
              <EditIcon
                fontSize="medium"
                className={classes.SubMenuToolBarIcon}
              />
            </Button>
            <Dialog
              open={display}
              TransitionComponent={Transition}
              fullWidth={"lg"}
              maxWidth={"lg"}
              keepMounted
              onClose={toggleDisplay}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              {props.meters.data.meter ? (
                <DialogTitle id="alert-dialog-slide-title">
                  Meter {props.meters.data.meter.name}{" "}
                </DialogTitle>
              ) : (
                <DialogTitle id="alert-dialog-slide-title">
                  Loading Meter.....{" "}
                </DialogTitle>
              )}
              <DialogContent>
                <MeterForm onUpdate={toggleDisplay} onCancel={toggleDisplay} />
              </DialogContent>
            </Dialog>
          </Grid>
        </Grid>
        <Divider className="mb-2 mt-2" my={1} />
        {!props.meters.data.meter ? (
          <>
            <Skeleton
              variant="rect"
              height="40px"
              style={{
                backgroundColor: `#bdbfbe`,
                padding: 6,
                margin: 6,
                marginBottom: 3,
              }}
            />
            <Skeleton
              variant="rect"
              height="40px"
              style={{
                backgroundColor: `#bdbfbe`,
                padding: 6,
                margin: 6,
                marginBottom: 3,
              }}
            />
            <Skeleton
              variant="rect"
              height="40px"
              style={{
                backgroundColor: `#bdbfbe`,
                padding: 6,
                margin: 6,
                marginBottom: 3,
              }}
            />
            <Skeleton
              variant="rect"
              height="40px"
              style={{
                backgroundColor: `#bdbfbe`,
                padding: 6,
                margin: 6,
                marginBottom: 3,
              }}
            />
          </>
        ) : (
          <Grid container spacing={2} lg={12} className={classes.MeterInfoGrid}>
            <Grid item xs={12} lg={12} spacing={2}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1"> Meter Number </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {props.meters.data.meter.meterNum || "N/A"}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1"> ERT Number </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {props.meters.data.meter.ERTNum || "N/A"}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1"> Description </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {props.meters.data.meter.description || "N/A"}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1"> Read Sequence </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {props.meters.data.meter.readSequence || "N/A"}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1"> Meter Code </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {props.meters.data.meter.meterCode == "1"
                            ? "PRIMARY"
                            : "SECONDARY"}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1"> Multiplier </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {props.meters.data.meter.multiplier || "N/A"}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1"> Read Decimals </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {props.meters.data.meter.readDecimals
                            ? "YES"
                            : "NO" || "N/A"}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1"> Physical </Typography>
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          checked={
                            props.meters.data.meter.physical == 1
                              ? true
                              : false
                          }
                          disabled={true}
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1"> Broken </Typography>
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          checked={
                            props.meters.data.meter.brokenMeter === 1
                          }
                          disabled={true}
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1"> No Meter </Typography>
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          checked={
                            props.meters.data.meter.noMeter === 1
                          }
                          disabled={true}
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1"> Backward Meter </Typography>
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          checked={
                            props.meters.data.meter.backwardMeter === 1
                          }
                          disabled={true}
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1"> Hold / Vacation </Typography>
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          checked={
                            props.meters.data.meter.hold === 1
                          }
                          disabled={true}
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
}

DetailCard.propTypes = {
  apl: PropTypes.object.isRequired,
  meters: PropTypes.object.isRequired,
};

function DetailCardState(state) {
  return {
    apl: state.apl,
    meters: state.meters,
  };
}

export default connect(DetailCardState, {})(DetailCard);
