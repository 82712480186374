import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import moment from "moment";
import React, { useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

export default ({
  setSnackbar,
  dispatch,
  incomeStatementsRan,
  setIncomeStatementsRan,
}) => {
  const classes = useStyles();
  let [email, setEmail] = useState("");
  let [postMonth, setPostMonth] = useState(1);
  let [postYear, setPostYear] = useState(moment().format("YYYY"));

  const runIncomeStatement = async () => {
    let headers = {
      "x-access-token": localStorage.getItem("token"),
    };

    try {
      await axios.post(
        "/run-income-statement",
        { email, postMonth, postYear },
        { headers }
      );
      dispatch(setSnackbar(true, "success", "Successfully posted batch."));
      let newStatementRan = [
        `${postMonth}-${postYear} for ${email}  @${moment().format(
          "HH:MM:SS"
        )}`,
      ];
      setIncomeStatementsRan(incomeStatementsRan.concat(newStatementRan));
    } catch (err) {
      dispatch(setSnackbar(true, "error", "Failed to post batch."));
    }
  };

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  return (
    <div className={classes.root}>
      <Card>
        <CardContent>
          <Typography variant="h6" mb={4}>
            Generate Cash Income Statement
          </Typography>
          <Grid container item direction="row" xs={12}>
            <Grid container item lg={4} md={6} xs={12}>
              <TextField
                id="email"
                label="Email"
                type="text"
                defaultValue={email}
                onChange={(e) => setEmail(e.target.value)}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid container item lg={2} md={6} xs={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="pm">Post Month</InputLabel>
                <Select
                  labelId="post month"
                  id="postMonth"
                  onChange={(e) => setPostMonth(e.target.value)}
                  defaultValue={postMonth}
                  name="postMonth"
                >
                  {months.map((item, index) => (
                    <MenuItem value={index + 1} key={index}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid container item lg={2} xs={12}></Grid>
            <Grid container item lg={4} md={6} xs={12}>
              <TextField
                id="py"
                label="Post Year"
                type="number"
                defaultValue={postYear}
                onChange={(e) => setPostYear(e.target.value)}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <div className={classes.root}>
            <Button
              disabled={email == "" || (postMonth == 0 && postYear == 0)}
              onClick={runIncomeStatement}
              variant="contained"
              color="primary"
            >
              Generate
            </Button>

            <div>
              {incomeStatementsRan.length > 0 ? (
                <p>Please allow 10 minutes for emails to be sent.</p>
              ) : null}
              {incomeStatementsRan.map((text, index) => {
                return (
                  <p key={index}>
                    {index + 1}. {text}
                  </p>
                );
              })}
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};
