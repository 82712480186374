import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getOwnerStatements, printOwnerStatement} from "../api/actions";
import PropTypes from "prop-types";
import {Card as MuiCard, CardContent, Grid} from "@material-ui/core";
import {EditableTable} from "../../../components/EditableTable";
import styled from "styled-components/macro";
import {spacing} from "@material-ui/system";
import {setSnackbar} from "../../snackbar/api/snackbar";

const Card = styled(MuiCard)(spacing);

const OwnerStatementsTable = ({ownerId}) => {

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [resetCache, setResetCachedData] = useState(true);

  const dispatch = useDispatch();
  const ownerStatements = useSelector(state => state.ownerStatements);

  const fetchOwnerStatements = async() => {
    if (ownerId) {
      if (ownerStatements.data.ownerStatements.items) {
        if ((page + 1) * rowsPerPage > ownerStatements.data.ownerStatements.items.length || resetCache) {
          await dispatch(getOwnerStatements(ownerId, page, rowsPerPage, resetCache));
        }
      }
    }
  }

  useEffect(()=>{
    fetchOwnerStatements();
  }, [ownerId, page, rowsPerPage]);

  const handleChangeRowsPerPage = (rowsPerPage) => {
    setPage(0);
    setResetCachedData(false);
    setRowsPerPage(rowsPerPage);
  };

  const handleChangePage = (page) => {
    setResetCachedData(false);
    setPage(page);
  };

  const onStatementSelected = (item) => {
    window.open(item.url);
  }

  const onStatementReprint = async (item) => {
    await dispatch(printOwnerStatement(item.id, true, (printError) => {
      if (!printError) {
        dispatch(setSnackbar(true, 'success', 'New PDF created'));
      } else {
        dispatch(setSnackbar(true, 'error', printError || 'Error printing PDF'));
      }
    }));
    await fetchOwnerStatements();
  };

  const fieldTitles = [
    "Period Start",
    "Period End",
    "Previous Balance",
    "Payment Total",
    "Billed Total",
    "Collected Total",
    "Fees Total",
    "Statement Total",
    "Statement File",
    "Print PDF"
  ];
  const fieldTypes = [
    "dateLabelNoTime",
    "dateLabelNoTime",
    "moneyLabel",
    "moneyLabel",
    "moneyLabel",
    "moneyLabel",
    "moneyLabel",
    "moneyLabel",
    "billPdfBtn",
    "reprintPdfBtn",

  ];
  const fieldNames = [
    "periodStart",
    "periodEnd",
    "prevBalance",
    "pmtTotal",
    "billedTotal",
    "collectedTotal",
    "feesTotal",
    "statementTotal",
    "url",
    "id"
  ];

  return (
    <Card mb={6} elevation={3}>
      <CardContent>
        <Grid container xs={12} alignItems="center" spacing={2}>
          <Grid item lg={12} md={12} sm={12}>
          </Grid>
        </Grid>
          <EditableTable
            readOnly={false}
            canAddItems={false}
            canSave={false}
            canDelete={false}
            isLoading={ownerStatements.loading}
            tableTitle={"Owner Statements"}
            objectArray={ownerStatements.data.ownerStatements}
            fieldTitles={fieldTitles}
            fieldTypes={fieldTypes}
            fieldNames={fieldNames}
            customChangePageRowsEvent={handleChangeRowsPerPage}
            customHandleChangePageEvent={handleChangePage}
            handlePreviewClickParentMethod={onStatementSelected}
            handleGenerateClickParentMethod={onStatementReprint}
            defaultItemCount={rowsPerPage}
            totalRowCount={
              !ownerStatements.data.totalRows
                ? 0
                : ownerStatements.data.totalRows
            }
          />
      </CardContent>
    </Card>
  );
};

OwnerStatementsTable.propTypes = {
  ownerId: PropTypes.number.isRequired,
}

export default OwnerStatementsTable;