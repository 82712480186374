import { AppBar, Box, Tab, Tabs, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PmUserLinkTable from "modules/propertyManager/components/PmUserLinkTable";
import PropTypes from "prop-types";
import React from "react";
import PmBillPacketTable from "../../modules/bills/components/PmBillPacketTable";
import PmDelinquencyTable from "../../modules/delinquencies/components/PmDelinquencyTable";
import ChatWindow from "../../modules/notes/components/AccountNotesChat";
import AccountRecentPaymentsTable from "../../modules/payments/components/AccountRecentPaymentsTable";
import PmAccountPreviewsTable from "../../modules/propertyManager/components/AccountsManagementTable";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function PropertyManagerDashboardTabedTables(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const getUserBills = props.getUserBills;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Account Management Tabs"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Accounts" {...a11yProps(0)} />
          <Tab label="Billing Packets" {...a11yProps(1)} />
          <Tab label="Payments" {...a11yProps(2)} />
          <Tab label="Delinqunecies" {...a11yProps(3)} />
          <Tab label="User PM Accounts" {...a11yProps(5)} />
          <Tab label="Notes" disabled {...a11yProps(6)} />
          <Tab label="Work Orders" disabled {...a11yProps(7)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <PmAccountPreviewsTable pmUuid={props.pmUuid} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <PmBillPacketTable pmUuid={props.pmUuid} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <AccountRecentPaymentsTable pmUuid={props.pmUuid} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <PmDelinquencyTable pmUuid={props.pmUuid} />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <PmUserLinkTable userProfile={props.userProfile} pmUuid={props.pmUuid}/>
      </TabPanel>
      <TabPanel value={value} index={6}>
        <ChatWindow />
      </TabPanel>
      <TabPanel value={value} index={7}>
        Work Orders
      </TabPanel>

    </div>
  );
}
