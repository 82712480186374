// Imports
import axios from "axios";
import cookie from "js-cookie";
import { setSnackbar } from "../../snackbar/api/snackbar";

require("../../../setup/config/apiConfig");
// Actions Types
export const GET_ALL_TRANSACTIONS_REQUEST = "USER/GET_ALL_DATA_REQUEST";
export const GET_ALL_TRANSACTIONS_RESPONSE = "USER/GET_ALL_DATA_RESPONSE";
export const UPDATE_SELECTED_USER_PROFILE = "UPDATE_SELECTED_USER_PROFILE";
export const SET_USER = "AUTH/SET_USER";
export const REGISTER_NEW_USER_REQUEST = "AUTH/REGISTER_NEW_USER_REQUEST";
export const REGISTER_NEW_USER_RESPONSE = "AUTH/REGISTER_NEW_USER_RESPONSE";
export const UPDATE_USER_REQUEST = "USER/UPDATE_USER_REQUEST";
export const UPDATE_USER_RESPONSE = "USER/UPDATE_USER_RESPONSE";
export const UPDATE_PASSWORD_REQUEST = "USER/UPDATE_PASSWORD_REQUEST";
export const UPDATE_PASSWORD_RESPONSE = "USER/UPDATE_PASSWORD_RESPONSE";
export const UPDATE_USER_EMAIL_REQUEST = "USER/UPDATE_USER_EMAIL_REQUEST";
export const UPDATE_USER_EMAIL_RESPONSE = "USER/UPDATE_USER_EMAIL_RESPONSE";
export const UPDATE_USER_EMAIL_FAILED = "USER/UPDATE_USER_EMAIL_FAILED";
export const UPDATE_USER_PASSWORD_REQUEST = "USER/UPDATE_USER_PASSWORD_REQUEST";
export const UPDATE_USER_PASSWORD_RESPONSE = "USER/UPDATE_USER_PASSWORD_RESPONSE";
export const UPDATE_USER_PASSWORD_FAILED = "USERR/UPDATE_USER_PASSWORD_FAILED";

export const GET_ALL_USER_ACCOUNTS_REQUEST = "GET_ALL_USER_ACCOUNTS_REQUEST";
export const GET_ALL_USER_ACCOUNTS_RESPONSE = "GET_ALL_USER_ACCOUNTS_RESPONSE";
export const GET_ALL_USER_ACCOUNTS_FAILED = "GET_ALL_USER_ACCOUNTS_FAILED";

export const DELETE_USER_ACCOUNT_REQUEST = "DELETE_USER_ACCOUNT_REQUEST";
export const DELETE_USER_ACCOUNT_RESPONSE = "DELETE_USER_ACCOUNT_RESPONSE";
export const DELETE_USER_ACCOUNT_FAILED = "DELETE_USER_ACCOUNT_FAILED";

export const CREATE_USER_ACCOUNT_REQUEST = "CREATE_USER_ACCOUNT_REQUEST";
export const CREATE_USER_ACCOUNT_RESPONSE = "CREATE_USER_ACCOUNT_RESPONSE";
export const CREATE_USER_ACCOUNT_FAILED = "CREATE_USER_ACCOUNT_FAILED";

export const CREATE_USER_PM_REQUEST = "CREATE_USER_PM_REQUEST";
export const CREATE_USER_PM_RESPONSE = "CREATE_USER_PM_RESPONSE";
export const CREATE_USER_PM_FAILED = "CREATE_USER_PM_FAILED";

export const DELETE_USER_PM_REQUEST = "DELETE_USER_PM_REQUEST";
export const DELETE_USER_PM_RESPONSE = "DELETE_USER_PM_RESPONSE";
export const DELETE_USER_PM_FAILED = "DELETE_USER_PM_FAILED";




export const PAYMENT_METHOD_GET_USER_PAYMENT_METHODS_REQUEST = 'USER/PAYMENT_METHOD_GET_USER_PAYMENT_METHODS_REQUEST'
export const PAYMENT_METHOD_GET_USER_PAYMENT_METHODS_RESPONSE = 'USER/PAYMENT_METHOD_GET_USER_PAYMENT_METHODS_RESPONSE'

export const NOTIFY_USER_PASSWORD_REQUEST = "USER/NOTIFY_USER_PASSWORD_REQUEST";
export const NOTIFY_USER_PASSWORD_RESPONSE =
  "USER/NOTIFY_USER_PASSWORD_RESPONSE";

export const SET_SELECTED_USER_PROFILE = "USER/SET_SELECTED_USER_PROFILE";

export const GET_USER_FROM_ACCOUNT_REQUEST = "GET_USER_FROM_ACCOUNT_REQUEST";
export const GET_USER_FROM_ACCOUNT_RESPONSE = "GET_USER_FROM_ACCOUNT_RESPONSE";

export const GET_ALL_USER_CHILD_ACCOUNTS_REQUEST = "GET_ALL_USER_CHILD_ACCOUNTS_REQUEST";
export const GET_ALL_USER_CHILD_ACCOUNTS_RESPONSE = "GET_ALL_USER_CHILD_ACCOUNTS_RESPONSE";
export const GET_ALL_USER_CHILD_ACCOUNTS_FAIL = "GET_ALL_USER_CHILD_ACCOUNTS_FAIL";

export const GET_EXTERNAL_USERS_TABLE_DATA_REQUEST = "GET_EXTERNAL_USERS_TABLE_DATA_REQUEST";
export const GET_EXTERNAL_USERS_TABLE_DATA_RESPONSE = "GET_EXTERNAL_USERS_TABLE_DATA_RESPONSE";
export const GET_EXTERNAL_USERS_TABLE_DATA_FAILED = "GET_EXTERNAL_USERS_TABLE_DATA_FAILED";

export const UPDATE_EXTERNAL_USER_STATUS_REQUEST = "UPDATE_EXTERNAL_USER_STATUS_REQUEST";
export const UPDATE_EXTERNAL_USER_STATUS_RESPONSE = "UPDATE_EXTERNAL_USER_STATUS_RESPONSE";
export const UPDATE_EXTERNAL_USER_STATUS_FAILED = "UPDATE_EXTERNAL_USER_STATUS_FAILED";

export const DELETE_EXTERNAL_USER_REQUEST = "DELETE_EXTERNAL_USER_REQUEST";
export const DELETE_EXTERNAL_USER_RESPONSE = "DELETE_EXTERNAL_USER_RESPONSE";
export const DELETE_EXTERNAL_USER_FAILED = "DELETE_EXTERNAL_USER_FAILED";


export const UPDATE_EXTERNAL_USERS_LOCAL_STATE = "UPDATE_EXTERNAL_USERS_LOCAL_STATE"
// Set updated user with new incoming payments in localStorage and state
export function setAccount(newData) {
  return {
    type: UPDATE_SELECTED_USER_PROFILE,
    newData,
  };
}

export function setSelectedUserProfile(profile) {
  return {
    type: SET_SELECTED_USER_PROFILE,
    profile,
  };
}
// Set user token and info in localStorage and cookie
export function loginSetUserLocalStorageAndCookie(token, user) {
  // Update token
  window.localStorage.setItem("token", token);
  window.localStorage.setItem("user", JSON.stringify(user));

  // Set cookie for SSR
  cookie.set("auth", { token, user }, { path: "/" }, { expires: 1 });
}

// Unset user token and info in localStorage and cookie
export function logoutUnsetUserLocalStorageAndCookie() {
  // Remove token
  window.localStorage.removeItem("token");
  window.localStorage.removeItem("user");
  // Remove cookie
  cookie.remove("auth");
}

///user-by-account/:accountUuid

export function getUsersByAccount(accountUUID, isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: GET_USER_FROM_ACCOUNT_REQUEST,
      isLoading,
    });
    return axios
      .get("/user-by-account/" + accountUUID)
      .then((response) => {
        let error = "";
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }

        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem("user"));
        if (currentUser) {
          dispatch(setAccount(response.data));
          dispatch({
            type: GET_USER_FROM_ACCOUNT_RESPONSE,
            error: error,
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: GET_USER_FROM_ACCOUNT_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          });
        }
      })
      .catch((error) => {
        //console.log(error);
        dispatch({
          type: GET_USER_FROM_ACCOUNT_RESPONSE,
          error: error,
        });
      });
  };
}

export function getUserPaymentMethodPreviews(userId, isLoading = true) {
  return dispatch => {
    dispatch({
      type: PAYMENT_METHOD_GET_USER_PAYMENT_METHODS_REQUEST,
      isLoading: isLoading
    });
    return axios.get('/savedpaymentmethods/user/' + userId)
      .then(response => {
        let error = '';
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message
        }
        // Update/Check if user object is the obj state
        const updatedUser = JSON.parse(window.localStorage.getItem('user'))
        if (updatedUser) {
          //Updating the bills obj in state
          dispatch({
            type: PAYMENT_METHOD_GET_USER_PAYMENT_METHODS_RESPONSE,
            isLoading: false,
            error: error,
            data: response.data
          })
        }
        else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: PAYMENT_METHOD_GET_USER_PAYMENT_METHODS_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!"
          })
        }
      })
      .catch(error => {
        dispatch({
          type: PAYMENT_METHOD_GET_USER_PAYMENT_METHODS_RESPONSE,
          isLoading: false,
          error: error
        })
      })
  }
}

export function updateUserPasswordEmailNotifier(userEmail) {
  const postBody = {
    email: userEmail,
  };
  return (dispatch) => {
    dispatch({
      type: NOTIFY_USER_PASSWORD_REQUEST,
      isLoading: true,
    });
    return axios
      .post(`/customer/password-reset`, postBody)
      .then((response) => {
        let error = "";
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }
        // Update/Check if user object is the obj state
        const currentInternalUser = JSON.parse(
          window.localStorage.getItem("user")
        );
        if (currentInternalUser) {
          dispatch({
            type: NOTIFY_USER_PASSWORD_RESPONSE,
            error: error,
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: NOTIFY_USER_PASSWORD_RESPONSE,
            error: "ERROR! NO current internal user LOGGED IN!",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: NOTIFY_USER_PASSWORD_RESPONSE,
          error: error,
        });
      });
  };
}
export function updateUserEmail(body) {
  
  return (dispatch) => {
    dispatch({
      type: UPDATE_USER_EMAIL_REQUEST,
      isLoading: true,
    });
    return axios
      .put(`/update-user-email`, body)
      .then((response) => {
        let error = "";
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }
        // Update/Check if user object is the obj state
        const currentInternalUser = JSON.parse(
          window.localStorage.getItem("user")
        );
        if (currentInternalUser) {
          dispatch(setSnackbar(true, "success", "Account Email Updated"),{
            type: UPDATE_USER_EMAIL_RESPONSE,
            error: error,
            isLoading: false,

          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type:  UPDATE_USER_EMAIL_RESPONSE,
            error: "ERROR! NO current internal user LOGGED IN!",
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        dispatch(setSnackbar(true, "error", `${error.response.data.message || 'Error Updating Email'}`),{
          type: UPDATE_USER_EMAIL_FAILED,
          error: error,
          isLoading: false,
        });
      });
  };
}

export function sendTemporaryPassword(body) {
  
  return (dispatch) => {
    dispatch({
      type: UPDATE_USER_PASSWORD_REQUEST,
      isLoading: true,
    });
    return axios
      .put(`/set-temporary-password`, body)
      .then((response) => {
        let error = "";
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }
        // Update/Check if user object is the obj state
        const currentInternalUser = JSON.parse(
          window.localStorage.getItem("user")
        );
        if (currentInternalUser) {
          dispatch(setSnackbar(true, "success", "Temporary Password Set"),{
            type: UPDATE_USER_PASSWORD_RESPONSE,
            error: error,
            isLoading: false
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type:  UPDATE_USER_PASSWORD_RESPONSE,
            error: "ERROR! NO current internal user LOGGED IN!",
            isLoading: false
          });
        }
      })
      .catch((error) => {
        dispatch(setSnackbar(true, "error", `${error.response.data.message || 'Error Setting Temp Password'}`),{
          type: UPDATE_USER_PASSWORD_FAILED,
          error: error,
          isLoading: false
        });
      });
  };
}


export function getAccountsByUser(userUuid, isLoading = false) {
  return dispatch => {
    dispatch({
      type: GET_ALL_USER_ACCOUNTS_REQUEST,
      isLoading: isLoading
    });
    return axios.get('/accounts-by-user/' + userUuid)
      .then(response => {
        let error = '';
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message
        }
       
        const updatedUser = JSON.parse(window.localStorage.getItem('user'))
        if (updatedUser) {
          dispatch({
            type: GET_ALL_USER_ACCOUNTS_RESPONSE,
            isLoading: false,
            error: error,
            data: response.data
          })
        }
        else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: GET_ALL_USER_ACCOUNTS_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!"
          })
        }
      })
      .catch(error => {
        dispatch({
          type: GET_ALL_USER_ACCOUNTS_FAILED,
          isLoading: false,
          error: error
        })
      })
  }
}
export function createAccountUserLink(userUuid, accountUuid, isLoading = false) {
  return dispatch => {
    dispatch({
      type: CREATE_USER_ACCOUNT_REQUEST,
      isLoading: isLoading
    });
    return axios.post(`/link/user/${userUuid}/account/${accountUuid}`)
      .then(response => {
        let error = '';
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message
        }
       
        const updatedUser = JSON.parse(window.localStorage.getItem('user'))
        if (updatedUser) {
          
          dispatch({
            type: CREATE_USER_ACCOUNT_RESPONSE,
            isLoading: false,
            error: error,
           })

           return response;
        }
        else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: CREATE_USER_ACCOUNT_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!"
          })

          return response;
        }
      })
      .catch(error => {
        dispatch({
          type: CREATE_USER_ACCOUNT_FAILED,
          isLoading: false,
          error: error
        })
        return error;
      })
  }
}


export function deleteAccountUserLink(userUuid, accountUuid,isLoading = false) {
  return dispatch => {
    dispatch({
      type: DELETE_USER_ACCOUNT_REQUEST,
      isLoading: isLoading
    });
    return axios.delete(`/link/user/${userUuid}/account/${accountUuid}`)
      .then(response => {
        let error = null;
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message
        }
       
        const updatedUser = JSON.parse(window.localStorage.getItem('user'))
        if (updatedUser) {
          
          dispatch({
            type: DELETE_USER_ACCOUNT_RESPONSE,
            isLoading: false,
            error: error,
          })
        }
        else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: DELETE_USER_ACCOUNT_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!"
          })
        }
      })
      .catch(error => {
        dispatch({
          type: DELETE_USER_ACCOUNT_FAILED,
          isLoading: false,
          error: error
        })
        return error;
      })
  }
}

export function deletePmUserLink(userUuid, pmUuid, isLoading = false) {
  return dispatch => {
    dispatch({
      type: DELETE_USER_PM_REQUEST,
      isLoading: isLoading
    });
    return axios.delete(`/link/propertyManager/${pmUuid}/user/${userUuid}`)
      .then(response => {
        let error = null;
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message
        }
       
        const updatedUser = JSON.parse(window.localStorage.getItem('user'))
        if (updatedUser) {
          
          dispatch({
            type: DELETE_USER_PM_RESPONSE,
            isLoading: false,
            error: error,
          })
        }
        else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: DELETE_USER_PM_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!"
          })
        }
      })
      .catch(error => {
        dispatch({
          type: DELETE_USER_PM_FAILED,
          isLoading: false,
          error: error
        })
      })
  }
}

export const findAllUserChildAccounts = (userUuid, isLoading = false) => {
    return (dispatch) => {
      dispatch({
      type: 'GET_ALL_USER_CHILD_ACCOUNTS_REQUEST',
      isLoading: isLoading
    });
    return axios
      .get(`/find-all-user-child-accounts/${userUuid}`)
      .then((response) => {
        let error = "";
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }
        // Update/Check if user object is the obj state
        const currentInternalUser = JSON.parse(
          window.localStorage.getItem("user")
        );
        if (currentInternalUser) {
          dispatch({
            type: GET_ALL_USER_CHILD_ACCOUNTS_RESPONSE,
            data: response.data.pmAccounts,
            error: error,
            isLoading: false
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type:  GET_ALL_USER_CHILD_ACCOUNTS_RESPONSE,
            error: "ERROR! NO current internal user LOGGED IN!",
            isLoading: false

          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_USER_CHILD_ACCOUNTS_FAIL,
          error: error,
          isLoading:false
        });
      });
  };

}

export function createPmUserLink( userUuid,pmUuid, isLoading = false) {
  return dispatch => {
    dispatch({
      type: CREATE_USER_PM_REQUEST,
      isLoading: isLoading
    });
    return axios.post(`/link/propertyManager/${pmUuid}/user/${userUuid}`)
      .then(response => {
        let error = '';
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message
        }
       
        const updatedUser = JSON.parse(window.localStorage.getItem('user'))
        if (updatedUser) {
          
          dispatch({
            type: CREATE_USER_PM_RESPONSE,
            isLoading: false,
            error: error,
           })

           return response;
        }
        else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: CREATE_USER_PM_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!"
          })

          return response;
        }
      })
      .catch(error => {
        dispatch({
          type: CREATE_USER_PM_FAILED,
          isLoading: false,
          error: error
        })
        return error;
      })
  }
}

export function getUserTableData ({page, rowsPerPage = 1000, search = '', isLoading = false, resetCache=false}) {

  return dispatch => {
    dispatch({
      type: GET_EXTERNAL_USERS_TABLE_DATA_REQUEST,
      isLoading: isLoading
    });
    return axios.get(`user-data-search?limit=${rowsPerPage}&page=${page}&search=${search}`)
    .then(response => {
      let error = '';
      if (response.data.errors && response.data.errors.length > 0) {
        error = response.data.errors[0].message
      }

      const updatedUser = JSON.parse(window.localStorage.getItem('user'))
      if (updatedUser) {
        dispatch({
          type: GET_EXTERNAL_USERS_TABLE_DATA_RESPONSE,
          isLoading: false,
          error: error,
          data: response.data,
          resetCache
        })
      }
      else {
        dispatch({
          type: GET_EXTERNAL_USERS_TABLE_DATA_RESPONSE,
          isLoading: false,
          error: "ERROR! NO USER LOGGED IN!"
        })
      }
    })
    .catch(error => {
      dispatch({
        type: GET_EXTERNAL_USERS_TABLE_DATA_FAILED,
        isLoading: false,
        error: error
      })
      throw error;
    })
  }
}

export function updateUserStatus(payload) {
  const { id } = payload;

  return (dispatch) => {
    dispatch({
      type: UPDATE_EXTERNAL_USER_STATUS_REQUEST,
      isLoading: false,
    });

    return axios
    .put(`/user-type-internal/${id}`, payload)
    .then((response) => {
      let error = "";
      if (response.data.errors && response.data.errors.length > 0) {
        error = response.data.errors[0].message;
      }

      const currentInternalUser = JSON.parse(window.localStorage.getItem("user"));
      if (currentInternalUser) {
        dispatch(setSnackbar(true, "success", "User Status Updated"));
        dispatch({
          type: UPDATE_EXTERNAL_USER_STATUS_RESPONSE,
          error: error,
          isLoading: false,
        });
      } else {
        dispatch({
          type: UPDATE_EXTERNAL_USER_STATUS_RESPONSE,
          error: "ERROR! NO current internal user LOGGED IN!",
          isLoading: false,
        });
      }
    })
    .catch((error) => {
      const errorMessage = error.response?.data?.message || 'Error Updating User';
      dispatch(setSnackbar(true, "error", errorMessage));
      dispatch({
        type: UPDATE_EXTERNAL_USER_STATUS_FAILED,
        error: error,
        isLoading: false,
      });
    });
  };
}


export const updateExternalUsers = (updatedUsers) => ({
  type: UPDATE_EXTERNAL_USERS_LOCAL_STATE,
  payload: updatedUsers,
});

export function deleteExternalUser(userUuid, isLoading = false) {
  return dispatch => {
    dispatch({
      type: DELETE_EXTERNAL_USER_REQUEST,
      isLoading: isLoading
    });
    return axios.delete(`/user-profile-link/${userUuid}`)
    .then(response => {
      let error = null;
      if (response.data.errors && response.data.errors.length > 0) {
        error = response.data.errors[0].message
      }

      const updatedUser = JSON.parse(window.localStorage.getItem('user'))
      if (updatedUser) {

        dispatch({
          type: DELETE_EXTERNAL_USER_RESPONSE,
          isLoading: false,
          error: error,
        })
        dispatch(setSnackbar(true, "success", "User Deleted Successfully"));

      }
      else {
        dispatch({
          type: DELETE_EXTERNAL_USER_RESPONSE,
          isLoading: false,
          error: "ERROR! NO USER LOGGED IN!"
        })
        dispatch(setSnackbar(true, "error", "ERROR! NO USER LOGGED IN!"));
      }
    })
    .catch(error => {
      dispatch({
        type: DELETE_EXTERNAL_USER_FAILED,
        isLoading: false,
        error: error
      })
      dispatch(setSnackbar(true, "error", "Error deleting user"));
    })
  }
}

