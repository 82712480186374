import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  percentDif: {
    textAlign: "right",
    color: "green",
  },
}));

export default function AnalyticCard(details) {
  const classes = useStyles();
  return (
    <Paper elevation={1} className={classes.paper}>
      <Typography className={classes.percentDif} variant="p" component="p">
        {details.diff}
      </Typography>
      <Typography variant="h4" component="h2">
        {details.stat}
      </Typography>
      <Typography variant="h6" component="h6">
        {details.title}
      </Typography>
    </Paper>
  );
}
