// Imports
import axios from "axios";

require("../../../setup/config/apiConfig");

// Actions Types
export const ACCOUNTS_OBJECT_RESET = "ACCOUNTS_OBJECT_RESET";
export const UPDATE_ACCOUNTS_FOUND = "UPDATE_ACCOUNTS_FOUND";
export const UPDATE_ACCOUNTS_FOUND_AND_SUMMARY =
  "UPDATE_ACCOUNTS_FOUND_AND_SUMMARY";
export const UPDATE_FILTERS = "UPDATE_FILTERS";
export const UPDATE_SELECTED_ACCOUNT = "UPDATE_SELECTED_ACCOUNT";

export const HANDLE_ACCOUNTS_UPDATED_SEARCH_REQUEST =
  "HANDLE_ACCOUNTS_UPDATED_SEARCH_REQUEST";
export const HANDLE_ACCOUNTS_UPDATED_SEARCH_RESPONSE =
  "HANDLE_ACCOUNTS_UPDATED_SEARCH_RESPONSE";
export const HANDLE_ACCOUNTS_UPDATED_SEARCH_FAILURE =
  "HANDLE_ACCOUNTS_UPDATED_SEARCH_FAILURE";

export const HANDLE_ACCOUNTS_SEARCH_REQUEST = "HANDLE_ACCOUNTS_SEARCH_REQUEST";
export const HANDLE_ACCOUNTS_SEARCH_RESPONSE =
  "HANDLE_ACCOUNTS_SEARCH_RESPONSE";
export const HANDLE_ACCOUNTS_SEARCH_FAILURE = "HANDLE_ACCOUNTS_SEARCH_FAILURE";

export const HANDLE_UPDATE_ACCOUNT_LIST_DATA =
  "HANDLE_UPDATE_ACCOUNT_LIST_DATA";
export const HANDLE_UPDATE_ACCOUNT_SEARCH_DATA =
  "HANDLE_UPDATE_ACCOUNT_SEARCH_DATA";

export const HANDLE_ACCOUNTS_HISTORY_SEARCH_REQUEST =
  "HANDLE_ACCOUNTS_HISTORY_SEARCH_REQUEST";
export const HANDLE_ACCOUNTS_HISTORY_SEARCH_RESPONSE =
  "HANDLE_ACCOUNTS_HISTORY_SEARCH_RESPONSE";

export const HYDRATE_ACCOUNT_REQUEST = "HYDRATE_ACCOUNT_REQUEST";
export const HYDRATE_ACCOUNT_RESPONSE = "HYDRATE_ACCOUNT_RESPONSE";

export const SET_QUICK_SEARCH_RESULT = "SET_QUICK_SEARCH_RESULT";
export const SET_QUICK_SEARCH_REQUEST = "SET_QUICK_SEARCH_REQUEST";
export const SET_QUICK_SEARCH_RESPONSE = "SET_QUICK_SEARCH_RESPONSE";


export function updateAccountsSearchData(updateAccountsSearchData) {
  return { type: HANDLE_UPDATE_ACCOUNT_SEARCH_DATA, updateAccountsSearchData };
}

export function updateSelectedAccountSearch(selectedAccount) {
  return { type: UPDATE_SELECTED_ACCOUNT, selectedAccount };
}

export function updateAccountsFoundInSearch(newAccountResults) {
  return { type: HANDLE_UPDATE_ACCOUNT_LIST_DATA, newAccountResults };
}
export function updateAccountListData(newList) {
  return { type: HANDLE_UPDATE_ACCOUNT_LIST_DATA, newList };
}
export function updateFilters(newFilters) {
  return (dispatch) => {
    dispatch({ type: UPDATE_FILTERS, newFilters });
  };
}
export function updateSelectedAccount(newSelectedAccountObject) {
  return { type: UPDATE_ACCOUNTS_FOUND, newSelectedAccountObject };
}

export function setAccountQuickSearchResults(result) {
  return { type: SET_QUICK_SEARCH_RESULT, result };
}

export function updateFilteredAccountsFoundInSearch(
  searchResponse,
  isBaseGroup,
  resetCache
) {
  return {
    type: UPDATE_ACCOUNTS_FOUND_AND_SUMMARY,
    searchResponse,
    isBaseGroup,
    resetCache,
  };
}

//filters: [{field:"name", target:""}],
function responseStillValid(responseFilters, stateFilters) {
  for (var x = 0; x < responseFilters.length; x++) {
    if (responseFilters[x] && stateFilters[x]) {
      if (
        responseFilters[x].field == stateFilters[x].field &&
        responseFilters[x].target == stateFilters[x].target
      ) {
        // Still valid
        console.log("Still VALLY");
      }
    } else {
      console.log("NOT VALLY");
      return false;
    }
  }

  return true;
}

export function get(isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: HANDLE_ACCOUNTS_UPDATED_SEARCH_REQUEST,
      isLoading: isLoading,
    });
    return axios
      .get("/accounts", {})
      .then((response) => {
        dispatch(updateAccountsFoundInSearch(response.data));
        dispatch({
          type: HANDLE_ACCOUNTS_UPDATED_SEARCH_RESPONSE,
          isLoading: false,
        });
      })
      .catch((error) => {
        dispatch({
          type: HANDLE_ACCOUNTS_UPDATED_SEARCH_FAILURE,
          isLoading: false,
          error: error,
        });
      });
  };
}

// Actions
export function searchAccountsByFieldAndValue(
  filters,
  range,
  isLoading = true
) {
  // provide default values because api endpoint breaks otherwise
  if (!filters) filters = [{ field: "account", target: "" }];
  if (!range) range = { start: 0, end: 25 };
  return (dispatch) => {
    dispatch({
      type: HANDLE_ACCOUNTS_UPDATED_SEARCH_REQUEST,
      isLoading: isLoading,
    });
    const payload = { filters, range };
    return axios
      .post("/searchAccountByFieldAndValue", payload)
      .then((response) => {
        let error = "";
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }

        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem("user"));
        if (currentUser) {
          if (responseStillValid(response.data.filters, filters)) {
            console.log("Is still valid response");
            dispatch(updateAccountsFoundInSearch(response.data.result));
          } else {
            console.log("Response no longer needed");
          }
          dispatch({
            type: HANDLE_ACCOUNTS_UPDATED_SEARCH_RESPONSE,
            isLoading: false,
            error: error,
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: HANDLE_ACCOUNTS_UPDATED_SEARCH_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: HANDLE_ACCOUNTS_UPDATED_SEARCH_FAILURE,
          isLoading: false,
          error: error,
        });
      });
  };
}

export function hydrateAccount(userUUID, isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: HYDRATE_ACCOUNT_REQUEST,
      isLoading,
    });
    return axios
      .get("/account/hydrate/" + userUUID)
      .then((response) => {
        let error = "";
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }

        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem("user"));
        if (currentUser) {
          console.log("response.data");
          console.log(response.data);
          dispatch(updateSelectedAccount(response.data));
          dispatch({
            type: HYDRATE_ACCOUNT_RESPONSE,
            error: error,
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: HANDLE_ACCOUNTS_UPDATED_SEARCH_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          });
        }
      })
      .catch((error) => {
        //console.log(error);
        dispatch({
          type: HYDRATE_ACCOUNT_RESPONSE,
          error: error,
        });
      });
  };
}
export function accountQuickSearch({
  searchQuery,
  isLoading = true
}) {
  // provide default values because api endpoint breaks otherwise
  var target = "?";
  if (searchQuery && searchQuery != "") {
    target = target + "searchQuery=" + encodeURIComponent(searchQuery);
  }
  return (dispatch) => {
    dispatch({
      type: SET_QUICK_SEARCH_REQUEST,
      isLoading: isLoading,
    });
    return axios
      .get("/quick-account-lookup" + target)
      .then((response) => {
        let error = "";
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }
        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem("user"));
        if (currentUser) {
          dispatch(setAccountQuickSearchResults(response.data));
          dispatch({
            type: SET_QUICK_SEARCH_RESPONSE,
            isLoading: false,
            error: error,
          });
          return response;
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: SET_QUICK_SEARCH_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: SET_QUICK_SEARCH_RESPONSE,
          isLoading: false,
          error: error,
        });
      });
  };
}

export function searchForAccountsByFilters({
  page,
  rowsPerPage,
  customerType,
  searchQuery,
  propertyUuid,
  accountStatusType,
  balanceStatus,
  pmUuid,
  userUuid,
  accountBalanceOwedFilter,
  serviceAddress,
  minAccountObj = false,
  isLoading = true,
  isBaseGroup = true,
  resetCache = false,
}) {
  // provide default values because api endpoint breaks otherwise
  var target = "?";
  if (page || page == 0) {
    target = target + "page=" + page + "&";
  }
  if (rowsPerPage && rowsPerPage != null) {
    target = target + "pageLimit=" + rowsPerPage + "&";
  }
  if (customerType && customerType != null) {
    target = target + "accountType=" + customerType + "&";
  }
  if (minAccountObj) {
    target = target + "minimumAccountObject=" + minAccountObj + "&";
  }
  if (searchQuery && searchQuery != "") {
    target = target + "searchQuery=" + encodeURIComponent(searchQuery) + "&";
  }
  if (propertyUuid && propertyUuid != null) {
    target = target + "propertyUuid=" + propertyUuid + "&";
  }
  if (accountStatusType && accountStatusType != null) {
    target = target + "status=" + accountStatusType + "&";
  }
  if (balanceStatus && balanceStatus != null) {
    target = target + "balanceStatus=" + balanceStatus + "&";
  }
  if (pmUuid && pmUuid != null) {
    target = target + "pmUuid=" + pmUuid + "&";
  }
  if (userUuid && userUuid != null) {
    target = target + "userUuid=" + userUuid + "&";
  }
  if (accountBalanceOwedFilter && accountBalanceOwedFilter == true) {
    target = target + "accountBalanceOwedFilter=" + accountBalanceOwedFilter + "&";
  }
  if (serviceAddress && serviceAddress != null) {
    target = target + "activeServiceAddress=1&";
  }
  return (dispatch) => {
    dispatch({
      type: HANDLE_ACCOUNTS_SEARCH_REQUEST,
      isLoading: isLoading,
    });
    return axios
      .get("/get-accounts-filtered/" + target)
      .then((response) => {
        let error = "";
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }

        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem("user"));
        if (currentUser) {
          dispatch(
            updateFilteredAccountsFoundInSearch(
              response.data,
              isBaseGroup,
              resetCache
            )
          );
          dispatch({
            type: HANDLE_ACCOUNTS_SEARCH_RESPONSE,
            isLoading: false,
            error: error,
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: HANDLE_ACCOUNTS_UPDATED_SEARCH_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: HANDLE_ACCOUNTS_UPDATED_SEARCH_FAILURE,
          isLoading: false,
          error: error,
        });
      });
  };
}

export function getRecentlyVisitedAccounts(acctNums, isLoading = true) {
  // provide default values because api endpoint breaks otherwise
  return (dispatch) => {
    dispatch({
      type: HANDLE_ACCOUNTS_HISTORY_SEARCH_REQUEST,
      isLoading: isLoading,
    });
    const payload = { accountNums: acctNums };
    return axios
      .post("/account/fullpreview/batch/acct-numbers", payload)
      .then((response) => {
        let error = "";
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }

        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem("user"));
        if (currentUser) {
          dispatch(updateFilteredAccountsFoundInSearch(true, true));
          dispatch({
            type: HANDLE_ACCOUNTS_HISTORY_SEARCH_RESPONSE,
            isLoading: false,
            accounts: response.data,
            error: error,
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: HANDLE_ACCOUNTS_HISTORY_SEARCH_RESPONSE,
            isLoading: false,
            accounts: [],
            error: "ERROR! NO USER LOGGED IN!",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: HANDLE_ACCOUNTS_HISTORY_SEARCH_RESPONSE,
          accounts: [],
          isLoading: false,
          error: error,
        });
      });
  };
}
