// App Imports
import {
  ACCOUNTS_OBJECT_RESET,
  HANDLE_ACCOUNTS_HISTORY_SEARCH_REQUEST,
  HANDLE_ACCOUNTS_HISTORY_SEARCH_RESPONSE,
  HANDLE_ACCOUNTS_SEARCH_REQUEST,
  HANDLE_ACCOUNTS_SEARCH_RESPONSE,
  HANDLE_ACCOUNTS_UPDATED_SEARCH_REQUEST,
  HANDLE_ACCOUNTS_UPDATED_SEARCH_RESPONSE,
  HANDLE_UPDATE_ACCOUNT_LIST_DATA,
  UPDATE_ACCOUNTS_FOUND,
  UPDATE_ACCOUNTS_FOUND_AND_SUMMARY,
  UPDATE_FILTERS,
  UPDATE_SELECTED_ACCOUNT,
  SET_QUICK_SEARCH_RESULT,
  SET_QUICK_SEARCH_REQUEST,
  SET_QUICK_SEARCH_RESPONSE,
  HANDLE_UPDATE_ACCOUNT_SEARCH_DATA
} from "./actions";
const today = new Date();
// Initial State
const accountsInitialState = {
  isLoading: false,
  error: null,
  accountHistoryPreviews: {
    items: [],
    toDelete: [],
    toUpdate: [],
  },
  data: {
    // For the current accounts cached locally
    accountPreviews: {
      items: [],
      toDelete: [],
      toUpdate: [],
    },
    previewsSummary: null,
    // The Current Account Management Filters(Default is by name with no calue):
    filters: [{ field: "account", target: "" }],
    range: { start: 0, end: 25 }, // Gets the first 25 accounts in the list
  },
  quickSearchResult: [],
  selectedAccount: {},
  quickSearchIsLoading: false
};

// State
export default (state = accountsInitialState, action) => {
  switch (action.type) {
    case ACCOUNTS_OBJECT_RESET:
      return Object.assign({}, accountsInitialState);
    case SET_QUICK_SEARCH_RESULT:
      if (action.result) {
        return {
          ...state,
          quickSearchResult: action.result,
        };
      }
    case HANDLE_UPDATE_ACCOUNT_SEARCH_DATA:
      let updatedAccounts = action.updateAccountsSearchData
      var newStateData = state.data;
      newStateData.accountPreviews = updatedAccounts;
      return {
        ...state,
        data: newStateData,
      };
    case UPDATE_ACCOUNTS_FOUND:
      let updatedAccountPreviews = {
        items: action.newAccountResults,
        toDelete: [],
        toUpdate: [],
      };
      var newStateData = state.data;
      newStateData.accountPreviews = updatedAccountPreviews;
      return {
        ...state,
        data: newStateData,
      };
    case HANDLE_UPDATE_ACCOUNT_LIST_DATA:
      var newStateData = state.data;
      let accountPreviews = {
        items: action.newList,
        toDelete: [],
        toUpdate: [],
      };
      newStateData.accountPreviews = accountPreviews;
      return {
        ...state,
        data: newStateData,
      };
    case UPDATE_ACCOUNTS_FOUND_AND_SUMMARY:
      var newStateData = state.data;
      if (action.resetCache) {
        let accountPreviews = {
          items: action.searchResponse.items,
          toDelete: [],
          toUpdate: [],
        };
        newStateData.accountPreviews = accountPreviews;
      } else {
        let accountPreviews = {
          items: newStateData.accountPreviews.items.concat(
            action.searchResponse.items
          ),
          toDelete: [],
          toUpdate: [],
        };
        newStateData.accountPreviews = accountPreviews;
      }
      if (action.isBaseGroup && action.searchResponse.summary != null) {
        newStateData.previewsSummary = action.searchResponse.summary;
      }
      return {
        ...state,
        data: newStateData,
      };
    case UPDATE_FILTERS:
      var newStateData = state.data;
      newStateData.filters = action.newFilters;
      return {
        ...state,
        data: newStateData,
      };
    case UPDATE_SELECTED_ACCOUNT:
      return {
        ...state,
        selectedAccount: action.selectedAccount,
      };

    case HANDLE_ACCOUNTS_HISTORY_SEARCH_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case HANDLE_ACCOUNTS_HISTORY_SEARCH_RESPONSE:
      let newAccountHistoryPreviews = {
        items: action.accounts,
        toDelete: [],
        toUpdate: [],
      };
      return {
        ...state,
        accountHistoryPreviews: newAccountHistoryPreviews,
        error: action.error,
        isLoading: false,
      };

    case HANDLE_ACCOUNTS_UPDATED_SEARCH_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case HANDLE_ACCOUNTS_UPDATED_SEARCH_RESPONSE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case HANDLE_ACCOUNTS_SEARCH_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case HANDLE_ACCOUNTS_SEARCH_RESPONSE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    default:
      return {
        ...state,
      };
  }
};
