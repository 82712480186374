//React Components and Hooks
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { deepOrange } from "@material-ui/core/colors";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
//Material UI Components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import HomeIcon from "@material-ui/icons/Home";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import MenuIcon from "@material-ui/icons/Menu";
import SettingsIcon from "@material-ui/icons/Settings";
//Contexts
// Redux Components
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { logout } from "../../modules/user/api/actions";

// End custom menu item styles

const TopBar = (props) => {
  // NOTE:
  // Should subclass these but testing them out for rn
  const StyledMenu = withStyles({
    paper: {
      border: "1px solid #d3d4d5",
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      {...props}
    />
  ));

  const StyledNavMenuItem = withStyles((theme) => ({
    root: {
      "&:focus": {
        backgroundColor: theme.palette.primary.main,
        "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
          color: theme.palette.common.white,
        },
      },
    },
  }))(MenuItem);

  // Styling:
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      backgrounColor: "#fafafa",
      display: "flex",
      spacing: 8,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    percentDif: {
      textAlign: "right",
      color: "green",
    },
    pad: {
      paddingTop: 16,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    chip: {
      paddingLeft: 10,
    },
    orange: {
      color: theme.palette.getContrastText(deepOrange[500]),
      backgroundColor: "black",
      borderColor: theme.palette.secondary.main,
      borderStyle: "solid",
      marginRight: "1em",
    },
    seperator: {
      marginLeft: "1em",
      marginRight: "1em",
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
  }));

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleUserDropDownClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleUserDropDownClose = () => {
    setAnchorEl(null);
  };

  const toSettings = () => {
    props.history.push("/settings");
  };
  const toDashboard = () => {
    props.history.push("/dashboard");
  };

  const logout = () => {
    props.logout();
    props.history.push("/");
  };
  return (
    <Toolbar>
      <div className={classes.title}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={props.handleToggle}
          className={classes.menuButton}
        >
          <MenuIcon />
          <Typography variant="h6" noWrap>
            Ubil+
          </Typography>
        </IconButton>
      </div>
      <div>
        <Button
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          color="inherit"
          onClick={handleUserDropDownClick}
        >
          <Divider
            className={classes.seperator}
            orientation="vertical"
            flexItem
          />
          <Avatar className={classes.orange}>
            {props.user.details
              ? `${props.user.details.firstName[0]}${props.user.details.lastName[0]}`
              : ""}
          </Avatar>
          <Typography>
            {props.user.details
              ? `${props.user.details.firstName} ${props.user.details.lastName}`
              : "CCOS Employee"}
          </Typography>
        </Button>
        <ClickAwayListener onClickAway={handleUserDropDownClose}>
          <StyledMenu
            id="menu-appbar"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleUserDropDownClose}
          >
            {/*List Item*/}
            <StyledNavMenuItem onClick={toDashboard}>
              <ListItemIcon>
                <HomeIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </StyledNavMenuItem>
            {/*List Item*/}
            <StyledNavMenuItem onClick={toSettings}>
              <ListItemIcon>
                <SettingsIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Settings" />
            </StyledNavMenuItem>
            {/*List Item*/}
            <StyledNavMenuItem onClick={logout}>
              <ListItemIcon>
                <MeetingRoomIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </StyledNavMenuItem>
          </StyledMenu>
        </ClickAwayListener>
      </div>
    </Toolbar>
  );
};
// Component Properties
TopBar.propTypes = {
  user: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
};

// Component State
function TopBarState(state) {
  return {
    user: state.user,
  };
}
export default connect(TopBarState, { logout })(withRouter(TopBar));
