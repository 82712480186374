import { Card, CardContent, Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

export default ({ acumaticaConnected, acumaticaStatusLoading }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Card>
        <CardContent>
          <Typography variant="h6" mb={4}>
            Acumatica Status
          </Typography>
          <div className={classes.root}>
            <Typography>
              {acumaticaStatusLoading == true
                ? "Loading"
                : acumaticaConnected == true
                ? "Acumatica connection is online and healthy."
                : "Failed to connect to Acumatica."}
            </Typography>
            {acumaticaStatusLoading == true ? (
              <div style={{ position: "relative" }}>
                {" "}
                <CircularProgress
                  className={classes.circularIcon}
                  size={20}
                  color="inherit"
                />
              </div>
            ) : acumaticaConnected == true ? (
              <CheckCircleIcon style={{ color: "green" }} />
            ) : (
              <NotInterestedIcon style={{ color: "red" }} />
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};
