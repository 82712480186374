import {
  RESET,
  GET_PROPERTY_MANAGERS,
  GET_PROPERTY_MANAGERS_RESPONSE,
  GET_PROPERTY_MANAGERS_RESPONSE_FAIL,
  GET_PROPERTY_MANAGER,
  GET_PROPERTY_MANAGER_RESPONSE,
  GET_PROPERTY_MANAGER_RESPONSE_FAIL,
  POST_PROPERTY_MANAGER,
  POST_PROPERTY_MANAGER_RESPONSE,
  POST_PROPERTY_MANAGER_FAIL,
  PUT_PROPERTY_MANAGER,
  PUT_PROPERTY_MANAGER_RESPONSE,
  PUT_PROPERTY_MANAGER_FAIL,

  UPDATE_PROPERTY_MANAGER_BALANCE_SUMMARY,
  GET_PROPERTY_MANAGER_BALANCE_SUMMARY_REQUEST,
  GET_PROPERTY_MANAGER_BALANCE_SUMMARY_RESPONSE,
  UPDATE_PROPERTY_MANAGER_ACCOUNT_SUMMARY,
  GET_PROPERTY_MANAGER_ACCOUNT_SUMMARY_REQUEST,
  GET_PROPERTY_MANAGER_ACCOUNT_SUMMARY_RESPONSE
} from "./actions";

const initialState = {
  propertyManagerIsLoading: true,
  propertyManagerBalanceSummaryIsLoading: false,
  propertyManagerAccountSummaryIsLoading: false,
  putPropertyManagerError: null,
  postPropertyManagerError: null,
  isLoading: false,
  error: null,
  data: {
    propertyManagersList: {
      items: [],
    },
    propertyManagerBalanceSummary: null,
    propertyManagerAccountSummary: null,
    propertyManager: null,
    searchQuery: null,
    range: { start: 0, end: 25 },
  },
};

// State
export default (state = initialState, action) => {
  var data = state.data;
  switch (action.type) {
    case RESET:
      return Object.assign({}, initialState);
    case GET_PROPERTY_MANAGERS:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case GET_PROPERTY_MANAGERS_RESPONSE:
      data.propertyManagersList.items = action.propertyManagers;
      return {
        ...state,
        data: data,
        error: action.error,
        isLoading: false,
      };

    case UPDATE_PROPERTY_MANAGER_BALANCE_SUMMARY:
      return {
        ...state,
        propertyManagerBalanceSummary: action.newSummaryData
      };
    case GET_PROPERTY_MANAGER_BALANCE_SUMMARY_REQUEST:
      return {
        ...state,
        error: null,
        propertyManagerBalanceSummaryIsLoading: action.isLoading,
      };
    case GET_PROPERTY_MANAGER_BALANCE_SUMMARY_RESPONSE:
      return {
        ...state,
        error: action.error,
        propertyManagerBalanceSummaryIsLoading: false,
      };


    case UPDATE_PROPERTY_MANAGER_ACCOUNT_SUMMARY:
      return {
        ...state,
        propertyManagerAccountSummary: action.newSummaryData
      };
    case GET_PROPERTY_MANAGER_ACCOUNT_SUMMARY_REQUEST:
      return {
        ...state,
        error: null,
        propertyManagerAccountSummaryIsLoading: action.isLoading,
      };
    case GET_PROPERTY_MANAGER_ACCOUNT_SUMMARY_RESPONSE:
      return {
        ...state,
        error: action.error,
        propertyManagerAccountSummaryIsLoading: false,
      };


    case GET_PROPERTY_MANAGER:
      return {
        ...state,
        error: null,
        propertyManagerIsLoading: action.isLoading,
      };
    case GET_PROPERTY_MANAGER_RESPONSE:
      data.propertyManager = action.propertyManager;
      return {
        ...state,
        data: data,
        error: action.error,
        propertyManagerIsLoading: false,
      };
    case POST_PROPERTY_MANAGER:
      return {
        ...state,
        error: null,
        propertyManagerIsLoading: action.isLoading,
      };
    case POST_PROPERTY_MANAGER_RESPONSE:
      data.propertyManager = action.propertyManager;
      return {
        ...state,
        data: data,
        error: action.error,
        propertyManagerIsLoading: false,
      };
    case POST_PROPERTY_MANAGER_FAIL:
      return {
        ...state,
        data: data,
        postPropertyManagerError: action.error,
      };
    case PUT_PROPERTY_MANAGER:
      return {
        ...state,
        error: null,
      };
    case PUT_PROPERTY_MANAGER_RESPONSE:
      data.propertyManager = action.propertyManager;
      return {
        ...state,
        data: data,
        putPropertyManagerError: action.error,
      };
    case PUT_PROPERTY_MANAGER_FAIL:
      return {
        ...state,
        data: data,
        putPropertyManagerError: action.error,
      };
    default:
      return {
        ...state,
      };
  }
};
