import {
  Card as MuiCard,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  TextField as MuiTextField,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
// Redux Components
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import { EditableTable } from "../../../components/EditableTable";
import { get as getMeters } from "../api/actions";
import Form from "./Form";

const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Table(props) {
  // Table Stuff:
  const fieldTitles = ["Meter", "ERT", "Backward Meter", "No  Meter", "Broken Meter", "Hold/ Vacation"];
  const fieldTypes = ["label", "label", "label", "label", "label", "label"];
  const fieldNames = ["meterNum", "ERTNum", "backwardMeter", "noMeter", "brokenMeter", "hold"];

  const meterRowStylingMethod = (meter) => {
    if(meter.backwardMeter){
      meter.backwardMeter = "Yes"
    }else{
      meter.backwardMeter = "";
    }
    if(meter.noMeter){
      meter.noMeter = "Yes"
    }else{
      meter.noMeter = "";
    }
    if(meter.brokenMeter){
      meter.brokenMeter = "Yes"
    }else{
      meter.brokenMeter = "";
    }
    if(meter.hold){
      meter.hold = "Yes"
    }else{
      meter.hold = "";
    }
  }


  const onSelected = (item) => {
    window.open("/meter/" + item.uuid);
  };

  const onUpdate = () => {
    hide();

    //this is terrible practice but due to time contraints....
    setTimeout(function () {
      props.onUpdate();
    }, 1000);
  };

  const [display, setDisplay] = React.useState(false);

  const show = () => {
    setDisplay(true);
  };

  const hide = () => {
    setDisplay(false);
  };

  const NewDialog = () => {
    return (
      <Dialog
        open={display}
        TransitionComponent={Transition}
        fullWidth={"lg"}
        maxWidth={"lg"}
        keepMounted
        onClose={hide}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">New Meter</DialogTitle>
        <DialogContent>
          <Form onUpdate={onUpdate} onCancel={hide} />
        </DialogContent>
      </Dialog>
    );
  };
  return (
    <Card mb={6} elevation={3}>
      <CardContent>
        <NewDialog />
        <EditableTable
          readOnly={false}
          isLoading={props.meters.isLoading}
          newItemCreationMethod={show}
          handleRowClickParentMethod={onSelected}
          tableTitle={"Meters"}
          objectArray={props.meters.data.metersList}
          fieldTitles={fieldTitles}
          fieldTypes={fieldTypes}
          fieldNames={fieldNames}
          conditionalStylingMethod={meterRowStylingMethod}
        />
      </CardContent>
    </Card>
  );
}
// Component meters
Table.propTypes = {
  // Store objects:
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  meters: PropTypes.object.isRequired,
  getMeters: PropTypes.func.isRequired,
};

// Component State
function State(state) {
  return {
    user: state.user,
    apl: state.apl,
    search: state.search,
    meters: state.meters,
  };
}
export default  connect(State, { getMeters })(Table);
