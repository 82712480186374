//React Components and Hooks
//Material UI Components
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import moment from "moment";
import React from "react";

const UploadsTable = (props) => {
  const rows = props.uploads;

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell>Date Uploaded</TableCell>
            <TableCell>Filename</TableCell>
            <TableCell>Lockbox Reading Count</TableCell>
            <TableCell>File</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => {
            return (
              <TableRow key={index}>
                <TableCell>
                  {moment(row.created).format("YYYY-MM-DD HH:MM:SS")}
                </TableCell>
                <TableCell>{row.fileName}</TableCell>
                <TableCell align="center">{row.readingCount}</TableCell>
                <TableCell>
                  <a target="_blank" href={`${row.url}`}>
                    <AttachFileIcon></AttachFileIcon>
                  </a>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UploadsTable;
