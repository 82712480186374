import React from "react";
import styled from "styled-components/macro";
import {
  Grid,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Paper as MuiPaper,
  MenuItem,
  TextField as MuiTextField,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  CircularProgress as MuiCircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  IconButton,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { spacing } from "@material-ui/system";
import { EditableTable, newDropDownField } from "../../../components/EditableTable";

// Redux Components
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { get as getProperties, updateFilters, updateResidualAmountsStoreObject, createPropertyRolloverItem, updateResidualAmountsTransactionsStoreObject, updatePropertyRolloverItem, deletePropertyRolloverItem, createPropertyRolloverItemTransaction, updatePropertyRolloverItemTransaction, deletePropertyRolloverItemTransaction } from "../api/actions";

const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FiscalMonthOptions =
  [{ label: "January", value: 1 },
  { label: "February", value: 2 },
  { label: "March", value: 3 },
  { label: "April", value: 4 },
  { label: "May", value: 5 },
  { label: "June", value: 6 },
  { label: "July", value: 7 },
  { label: "August", value: 8 },
  { label: "September", value: 9 },
  { label: "October", value: 10 },
  { label: "November", value: 11 },
  { label: "December", value: 12 }
  ];



function ResidualAmountsToAddTable(props) {

  const createResidualItemData = (id, propertyId, description, cost, kwh) => {
    return { id, propertyId, description, cost, kwh, transactions: [] };
  }

  const createBlankResidualItem = () => {
    return createResidualItemData(-1, props.propertyId, "New Roll Over Amount", 0, 0);
  }

  const createResidualTransactionItemData = (id, residualAmountToAddId, fiscalMonth, fiscalYear, kwhUsed, costUsed) => {
    return { id, residualAmountToAddId, fiscalMonth, fiscalYear, kwhUsed, costUsed };
  }

  const canAddOrUpdate = (props.user.details.billing || props.user.details.administration || props.user.details.isSuperUser) ? true : false;

  // Table Stuff 
  //MAIN TABLE:
  const fieldTitles = [
    "Description",
    "Original Cost",
    "Original KWH",
    "Remaining Cost",
    "Remaining KWH",
    "Created Date"
  ];
  const fieldTypes = ["label", "moneyLabel", "label", "moneyLabel", "label", "dateLabel"];
  const fieldNames = [
    "description",
    "cost",
    "kwh",
    "remainingCost",
    "remainingKWH",
    "created",
  ];

  // TRANSACTIONS TABLE
  const transactionTableFieldTitles = [
    "Fiscal Month",
    "Fiscal Year",
    "Cost Used (USD)",
    "Kwh Used"
  ];
  const transactionTableFieldTypes = ["dropDown", "number", "number", "number"];
  const transactionTableFieldNames = [
    "fiscalMonth",
    "fiscalYear",
    "costUsed",
    "kwhUsed",
  ];

  const transactionTableResourceFields = [newDropDownField("fiscalMonth", FiscalMonthOptions, "label", "value")];

  const [displayResidualDialog, setDisplayResidualDialog] = React.useState(false);
  const [currentResidualItem, setCurrentResidualItem] = React.useState(createBlankResidualItem());
  const [currentResidualItemIndex, setCurrentResidualItemIndex] = React.useState(0);


  const createBlankResidualTransactionItem = () => {
    return createResidualTransactionItemData(-1, currentResidualItem.id, null, null, 0, 0);
  }

  const addBlankTransactionToTransactionsList = () => {
    props.properties.propertyRolloverItemCurrentTransactions.items.push(createBlankResidualTransactionItem());
    props.updateResidualAmountsTransactionsStoreObject(props.properties.propertyRolloverItemCurrentTransactions);
  }

  const showResidualDialog = () => {
    setDisplayResidualDialog(true);
  };

  const hideResidualDialog = () => {
    setDisplayResidualDialog(false);
  };

  // On an item selection set the current item and items transactions as well as the current item index
  const onSelected = (item) => {
    setCurrentResidualItem(item)
    let index = props.properties.propertyResidualAmountsToAdd.items.findIndex(currentItem => (currentItem.id === item.id && currentItem.cost === item.cost && currentItem.kwh === item.kwh));
    setCurrentResidualItemIndex(index);
    props.updateResidualAmountsTransactionsStoreObject({ items: item.transactions, toDelete: [], toUpdate: [] });
    setDisplayResidualDialog(true);
  };

  const handleUpdatingStoreWithChangedRollOverItem = () => {
    let newCurrentResidualItem = currentResidualItem;
    newCurrentResidualItem.transactions = props.properties.propertyRolloverItemCurrentTransactions.items;
    // does this to keep a running total of all updated and deleted things
    if (!newCurrentResidualItem.transactionsToUpdate && !newCurrentResidualItem.transactionsToDelete) {
      newCurrentResidualItem.transactionsToUpdate = props.properties.propertyRolloverItemCurrentTransactions.toUpdate;
      newCurrentResidualItem.transactionsToDelete = props.properties.propertyRolloverItemCurrentTransactions.toDelete;
    }
    else {
      newCurrentResidualItem.transactionsToUpdate.concat(props.properties.propertyRolloverItemCurrentTransactions.toUpdate);
      newCurrentResidualItem.transactionsToDelete.concat(props.properties.propertyRolloverItemCurrentTransactions.toDelete);
    }
    props.properties.propertyResidualAmountsToAdd.items[currentResidualItemIndex] = newCurrentResidualItem;
    props.updateResidualAmountsStoreObject(props.properties.propertyResidualAmountsToAdd);
    hideResidualDialog();
  }

  const onFieldChange = (event) => {
    if (currentResidualItem) {
      let newCurrentResidualItem = currentResidualItem;
      if (event.target.type == "number") {
        newCurrentResidualItem[event.target.name] = Number(event.target.value);
        setCurrentResidualItem(newCurrentResidualItem);
      }
      else {
        newCurrentResidualItem[event.target.name] = event.target.value;
        setCurrentResidualItem(newCurrentResidualItem);
      }
      // Checks and sees if the item needs pushing to the updated array or if its already there or a new item
      if (currentResidualItem.id != -1 && !props.properties.propertyResidualAmountsToAdd.toUpdate.includes(currentResidualItem.uuid)) {
        props.properties.propertyResidualAmountsToAdd.toUpdate.push(currentResidualItem.uuid);
      }
    }
  }

  const ResidualAmountsToAddDialog = () => {
    return (
      <Dialog
        open={displayResidualDialog}
        //TransitionComponent={Transition}
        fullWidth={"lg"}
        maxWidth={"lg"}
        keepMounted
        onClose={hideResidualDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{currentResidualItem.id == -1 ?
          "New Residual Charge"
          :
          "Residual Charge"
        }</DialogTitle>
        <DialogContent>
          <CardContent>
            <Grid container lg={12} md={12} sm={12} spacing={3}>
              <Grid item lg={4} md={12} sm={12}>
                <TextField
                  id="description"
                  label="Description"
                  type="text"
                  fullWidth
                  my={2}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  defaultValue={currentResidualItem.description}
                  onChange={onFieldChange}
                  name="description"
                />
              </Grid>
              <Grid item lg={4} md={12} sm={12}>
                <TextField
                  id="cost"
                  label="Cost (USD)"
                  type="number"
                  fullWidth
                  my={2}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  defaultValue={currentResidualItem.cost}
                  onChange={onFieldChange}
                  name="cost"
                />
              </Grid>
              <Grid item lg={4} md={12} sm={12}>
                <TextField
                  id="kwh"
                  label="KWH"
                  type="number"
                  fullWidth
                  my={2}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  defaultValue={currentResidualItem.kwh}
                  onChange={onFieldChange}
                  name="kwh"
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12}>
                <Card mb={6} elevation={3}>
                  <CardContent>
                    <EditableTable
                      readOnly={false}
                      canSave={false}
                      canAddItems={true}
                      isLoading={props.properties.propertyResidualAmountsToAddIsLoading}
                      newItemCreationMethod={addBlankTransactionToTransactionsList}
                      updateItemsLocalState={props.updateResidualAmountsTransactionsStoreObject}
                      tableTitle={"Rollover Item Transactions"}
                      objectArray={props.properties.propertyRolloverItemCurrentTransactions}
                      fieldTitles={transactionTableFieldTitles}
                      fieldTypes={transactionTableFieldTypes}
                      fieldNames={transactionTableFieldNames}
                      dropDownFields={transactionTableResourceFields}
                    />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item lg={12} md={12} sm={12}>
                <MuiButton fullWidth variant="contained" onClick={handleUpdatingStoreWithChangedRollOverItem} color="secondary">
                  Update Item
                </MuiButton>
              </Grid>
            </Grid>
          </CardContent>
        </DialogContent>
      </Dialog>
    );
  };

  // To be used to save over the api in the database
  const saveResidualCharges = (ResidualCharges) => {
    //console.log("ResidualCharges");
    //console.log(ResidualCharges);
    ResidualCharges.toDelete.map(targetUuid => {
      props.deletePropertyRolloverItem(targetUuid);
    });
    // Needs to loop through, create or update transactions or send them all at once if its a new item with no id
    ResidualCharges.items.map((ResidualCharge) => {
      if (ResidualCharge.id == -1) {
        delete ResidualCharge.id;
        delete ResidualCharge.remainingCost;
        delete ResidualCharge.remainingKWH;
        delete ResidualCharge.transactionsToUpdate;
        delete ResidualCharge.transactionsToDelete;
        props.createPropertyRolloverItem(ResidualCharge); // This method also handles all new transactions passed with it
      }
      else {
        // if it is not a new item, we must loop through all transactions to update create or delete in db
        if (ResidualCharge.transactions) // checks if array even exists
        {
          ResidualCharge.transactions.map((transaction) => {
            if (transaction.id == -1) {
              delete transaction.id;
              props.createPropertyRolloverItemTransaction(transaction); // This method also handles all new transactions passed with it
              //console.log("Creating Transaction: " + ResidualCharge.id + " " + transaction.costUsed);
            }
          });
          // Checks if it exists and loops through all transactions marked for updating
          if (ResidualCharge.transactionsToUpdate) {
            ResidualCharge.transactionsToUpdate.map((transactionToUpdateUuid) => {
              let targetTransactionIndex = ResidualCharge.transactions.findIndex(x => x.uuid === transactionToUpdateUuid);
              if (targetTransactionIndex != -1) {
                if (ResidualCharge.transactions[targetTransactionIndex].id != -1) {
                  //console.log("Updating Transaction: " + ResidualCharge.id + " " + ResidualCharge.transactions[targetTransactionIndex].id);
                  props.updatePropertyRolloverItemTransaction(ResidualCharge.transactions[targetTransactionIndex]);
                }
              }
            });
          }
          if (ResidualCharge.transactionsToDelete) {
            ResidualCharge.transactionsToDelete.map((transactionToDeleteUuid) => {
              //console.log("Deleting Transaction: " + ResidualCharge.id + " " + transactionToDeleteUuid);
              props.deletePropertyRolloverItemTransaction(transactionToDeleteUuid);
            });
          }
        }
      }
    });
    ResidualCharges.toUpdate.map(targetUuid => {
      let targetItemIndex = ResidualCharges.items.findIndex(x => x.uuid === targetUuid);
      if (targetItemIndex != -1) {
        if (ResidualCharges.items[targetItemIndex].id != -1) {
          props.updatePropertyRolloverItem(ResidualCharges.items[targetItemIndex]);
        }
      }
    });
    //ResidualCharges.items = ResidualCharges.items.filter(ResidualCharge => ResidualCharge.id);
    //props.updateResidualAmountsStoreObject({ items: ResidualCharges.items, toDelete: [], toUpdate: [] });
  }

  const updateLocalItemsValues = (items) => {
    // Replaces existing store object with new object with modified sub data
    //console.log("updateLocalItemsValues");
    props.updateResidualAmountsStoreObject(items);
  }


  const handleNewResidualAmountCreation = () => {
    props.properties.propertyResidualAmountsToAdd.items.push(createBlankResidualItem());
    props.updateResidualAmountsTransactionsStoreObject({ items: [], toDelete: [], toUpdate: [] });
    setCurrentResidualItem(props.properties.propertyResidualAmountsToAdd.items[props.properties.propertyResidualAmountsToAdd.items.length - 1]);
    setCurrentResidualItemIndex(props.properties.propertyResidualAmountsToAdd.items.length - 1);
    showResidualDialog();
  }

  const Styling = (ResidualAmountToAdd) => {
    ResidualAmountToAdd.remainingCost = ResidualAmountToAdd.cost;
    ResidualAmountToAdd.remainingKWH = ResidualAmountToAdd.kwh;
    if (ResidualAmountToAdd.transactions) {
      for (var x = 0; x < ResidualAmountToAdd.transactions.length; x++) {
        if (ResidualAmountToAdd.transactions[x].kwhUsed != null && ResidualAmountToAdd.kwh != null) {
          ResidualAmountToAdd.remainingKWH = ResidualAmountToAdd.remainingKWH - ResidualAmountToAdd.transactions[x].kwhUsed;
        }
        if (ResidualAmountToAdd.transactions[x].costUsed != null && ResidualAmountToAdd.cost != null) {
          ResidualAmountToAdd.remainingCost = ResidualAmountToAdd.remainingCost - ResidualAmountToAdd.transactions[x].costUsed;
        }
      }
    }
    return {};
  };
  return (
    <Card mb={6} elevation={3}>
      <CardContent>
        <EditableTable
          readOnly={!canAddOrUpdate}
          canSave={canAddOrUpdate}
          canAddItems={canAddOrUpdate}
          isLoading={props.properties.propertyResidualAmountsToAddIsLoading}
          updateItemsLocalState={updateLocalItemsValues}
          saveCurrentTableData={saveResidualCharges}
          conditionalStylingMethod={Styling}
          newItemCreationMethod={handleNewResidualAmountCreation}
          handleRowClickParentMethod={onSelected}
          tableTitle={"Residual Amounts to Add"}
          objectArray={props.properties.propertyResidualAmountsToAdd}
          fieldTitles={fieldTitles}
          fieldTypes={fieldTypes}
          fieldNames={fieldNames}
        />
      </CardContent>
      <ResidualAmountsToAddDialog />
    </Card>
  );
}

ResidualAmountsToAddTable.propTypes = {
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  properties: PropTypes.object.isRequired,
  getProperties: PropTypes.func.isRequired,
  updateResidualAmountsStoreObject: PropTypes.func.isRequired,
  createPropertyRolloverItem: PropTypes.func.isRequired,
  updatePropertyRolloverItem: PropTypes.func.isRequired,
  deletePropertyRolloverItem: PropTypes.func.isRequired,
  updateResidualAmountsTransactionsStoreObject: PropTypes.func.isRequired,
  createPropertyRolloverItemTransaction: PropTypes.func.isRequired,
  updatePropertyRolloverItemTransaction: PropTypes.func.isRequired,
  deletePropertyRolloverItemTransaction: PropTypes.func.isRequired
};

function ResidualAmountsToAddTableState(state) {
  return {
    user: state.user,
    apl: state.apl,
    search: state.search,
    properties: state.properties,
  };
}

export default connect(ResidualAmountsToAddTableState, { getProperties, updateResidualAmountsStoreObject, createPropertyRolloverItem, updatePropertyRolloverItem, deletePropertyRolloverItem, updateResidualAmountsTransactionsStoreObject, createPropertyRolloverItemTransaction, updatePropertyRolloverItemTransaction, deletePropertyRolloverItemTransaction })(ResidualAmountsToAddTable);
