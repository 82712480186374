import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Grid, Snackbar} from "@material-ui/core";
import {EditableTable} from "../../../components/EditableTable";
import Alert from "@material-ui/lab/Alert";
import {setSnackbar} from "../../snackbar/api/snackbar";
import { updatePDFMessageSetting } from "../api/actions";
import PDFMessageSettingForm from "./PDFMessageSettingForm";

const PDFMessageSettingTable = () => {
  const [open, setOpen] = useState(false);
  const [item, setItem] = useState({});
  const [alert, setAlert] = useState({ open: false, message: "", type: "" });

  const user = useSelector((state) => state.user);
  const pdfMessages = useSelector((state) => state.setting.pdfSettings.messages);

  const dispatch = useDispatch();
  const onSettingSelected = (item) => {
    setItem(item);
    setOpen(!open);
  };

  const onSettingUpdate = (uuid, newItem) => {
    dispatch(updatePDFMessageSetting(uuid, newItem))
    .then(() => {
      dispatch(setSnackbar(true, "success", "Successfully updated setting"));
    })
    .catch(() => {
      dispatch(setSnackbar(true, "error", "Failed to update settings"));
    });
  };

  const handleClose = () => {
    setAlert({ open: false });
  };
  const fieldTitles = [
    "",
    "Template",
    "Message",
    "Status",
    "Updated",
  ];
  const fieldTypes = [
    "labelSmall",
    "labelBold",
    "label",
    "icon",
    "dateTimeLabel",
  ];
  const fieldNames = [
    "id",
    "description",
    "message",
    "active",
    "updated",
  ];
  return (
    <div>
      {!!(user.details.isSuperUser || user.details.administration) ?
        <Grid item xs={12}>
          <Snackbar
            open={alert.open}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity={alert.type}>
              {alert.message}
            </Alert>
          </Snackbar>
          <EditableTable
            tableTitle={"PDF Messages"}
            readOnly={true}
            canAddItems={false}
            canSave={false}
            objectArray={pdfMessages}
            fieldTitles={fieldTitles}
            fieldTypes={fieldTypes}
            handleRowClickParentMethod={onSettingSelected}
            fieldNames={fieldNames}
            showButtons={false}
          />
        </Grid> :
        <h3>You do not not have privileges</h3>
      }
      {open && (
        <PDFMessageSettingForm
          item={item}
          updateItem={onSettingUpdate}
          onChange={onSettingSelected}
          show={open}
          dialogText={`To update message in PDF, please enter your the desired message`}
        />
      )}
    </div>
  );
};

export default PDFMessageSettingTable;