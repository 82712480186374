//React Components and Hooks
import Box from "@material-ui/core/Box";
//Material UI Components
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
//Images
import logo from "../../assets/images/logo-dark.png";
import {
  login,
  logout,
  resetPasswordRequest,
} from "../../modules/user/api/actions";
//Context

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="">
        Columbus& Central Ohio Systems LLC
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    //backgroundColor: '#fff'
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Login = (props) => {
  const [open, setOpen] = useState(false);
  let [state, setState] = useState({
    isLoginError: false,
    loginErrorMessage: "",
    loginLoading: false,
    success: false,
  });
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const emailRef = useRef();
  const passwordRef = useRef();
  const resetRef = useRef();

  const handleClickOpen = () => {
    setOpen(!open);
  };

  function authenticate() {
    if (emailRef.current.value === "" || passwordRef.current.value === "") {
      setState({
        loginErrorMessage: "You must enter and email and password",
        isLoginError: true,
      });
      return;
    }
    setState({ loginLoading: true });
    props
      .login(emailRef.current.value, passwordRef.current.value)
      .then((response) => {
        setState({ loginLoading: false });
      })
      .catch((error) => {
        setState({ loginLoading: false });
      });
  }
  useEffect(() => {
    if (
      window.localStorage.getItem("token") &&
      window.localStorage.getItem("user")
    ) {
      props.history.push({ pathname: "/dashboard" });
    }

    const listener = (event) => {
      if (event.code === "Enter" || event.code === " ") {
        authenticate();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [props.user]);

  async function handleClose() {
    if (resetRef.current.value != "") {
      await props.resetPasswordRequest(resetRef.current.value);
      window.location.reload();
    }
  }

  const classes = useStyles();
  document.title = "Ubil+ Sign In";
  return (
    <Container className={classes.container} component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img src={logo} alt="apl" />
        <Typography component="h1" variant="h5">
          Columbus& Central Ohio Systems
        </Typography>
        <Typography component="h1" variant="h6">
          INTERNAL ONLY
        </Typography>
        {state.loginLoading ? (
          <CircularProgress color="inherit" />
        ) : (
          <div></div>
        )}
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            inputRef={emailRef}
            label="Email Address"
            name="email"
            error={state.isLoginError}
            autoComplete="email"
            autoFocus
            required
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            inputRef={passwordRef}
            error={state.isLoginError}
            name="password"
            label="Password"
            type="password"
            id="password"
            required
            autoComplete="current-password"
          />
          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={authenticate}
            disabled={loading}
          >
            {state.loginLoading ? "Loading..." : "Sign In"}{" "}
          </Button>
          <Grid container>
            <Grid item xs>
              <Button onClick={handleClickOpen} variant="body2">
                Forgot password?
              </Button>
            </Grid>
            <Grid item>
              <Link to="/signup" variant="body2">
                {"Don't have an account?"}
              </Link>
            </Grid>
          </Grid>
        </form>
        {props.user.error != null && (
          <Snackbar open={props.user.error.error} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error">
              {props.user.error.message}
            </Alert>
          </Snackbar>
        )}
        {state.success && (
          <Snackbar open={props.user.details} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
              Login Successful!
            </Alert>
          </Snackbar>
        )}
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Forgot Password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter email to receive link to reset password
          </DialogContentText>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            inputRef={resetRef}
            label="Email Address"
            name="email"
            error={state.isLoginError}
            autoComplete="email"
            autoFocus
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickOpen} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="secondary">
            Reset Password
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

// Component Properties
Login.propTypes = {
  user: PropTypes.object.isRequired,
  login: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

// Component State
function LoginState(state) {
  return {
    user: state.user,
  };
}
export default connect(LoginState, { login, resetPasswordRequest, logout })(
  Login
);
//Local
//Dev
