//React Components and Hooks
import {
  CircularProgress as MuiCircularProgress,
  Divider as MuiDivider,
  Typography as MuiTypography,
} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
//Material UI Components and Functions
import { makeStyles } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import { spacing } from "@material-ui/system";
// Redux Components
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components/macro";
import { LeftSidebar as NavFrame } from "layout-blueprints"; // The top navigation bar and side navigation panel
import { getReports } from "../../modules/settings/api/actions";
import {
  getAllPaymentPlanStatuses,
  getAllAccountStatusCodes,
} from "../../modules/apl/api/actions";
import { logout } from "../../modules/user/api/actions";
import Reports from "./Reports";

const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  percentDif: {
    textAlign: "right",
    color: "green",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  loadingIcon: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

const ReportsPage = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [currentDate, setCurrentDate] = useState(new Date());

  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(
    "Error retrieving data"
  );

  const logout = () => {
    props.logout();
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = (event, reason) => {
    setError(false);
  };

  // On initial page load:
  useEffect(() => {
    document.title = "Ubil+ Reports";
    if (props.setting.reports.items <= 0) dispatch(getReports());
    if (props.apl.genericTypes.paymentPlanStatus.data <= 0)
      dispatch(getAllPaymentPlanStatuses());
    if (props.apl.genericTypes.accountStatus.data <= 0)
      dispatch(getAllAccountStatusCodes());
  }, [dispatch]);

  // On Data Loaded:
  return (
    <NavFrame page={"Reports"}>
      <Snackbar open={error} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      <Reports />
    </NavFrame>
  );
};

// Component Properties
ReportsPage.propTypes = {
  setting: PropTypes.object.isRequired,
  getReports: PropTypes.func.isRequired,
  getAllPaymentPlanStatuses: PropTypes.func.isRequired,
  getAllAccountStatusCodes: PropTypes.func.isRequired,
};

// Component State
function ReportsPageState(state) {
  return {
    setting: state.setting,
    user: state.user,
    apl: state.apl,
  };
}
export default connect(ReportsPageState, {
  logout,
  getReports,
  getAllPaymentPlanStatuses,
  getAllAccountStatusCodes,
})(ReportsPage);
