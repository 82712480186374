import {
  Card as MuiCard,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Button,
  Select,
  Slide,
  TextField as MuiTextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import { spacing } from "@material-ui/system";
// Redux Components
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import { EditableTable } from "../../../components/EditableTable";
import { get as getaging } from "../api/actions";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../snackbar/api/snackbar";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Table(props) {
  const handleSearchTargetChangeDate = (event) => {
    props.accounting.data.searchQuery.date = event.target.value;
    let params = [
      props.accounting.data.searchQuery.date,
      props.accounting.data.searchQuery.yearsBack,
    ];
  };

  const dispatch = useDispatch();


  const handleSearchTargetChangeEmail = (event) => {
    props.accounting.data.searchQuery.email = event.target.value;
  };

  const sendRequest = () => {
    if (props.accounting.data.searchQuery.date == null || props.accounting.data.searchQuery.yearsBack == null || props.accounting.data.searchQuery.email == null) {
      dispatch(setSnackbar(true, "error", "Must fill out all fields."))
    }


    let params = [
      props.accounting.data.searchQuery.date,
      props.accounting.data.searchQuery.yearsBack,
    ];

    props.getaging(
      props.accounting.data.searchQuery,
      true,
      "",
      "CalculateAging",
      params,
      "agingList",
      props.accounting.data.searchQuery.email
    );
  }

  const handleSearchTargetChangeYearsBack = (event) => {
    props.accounting.data.searchQuery.yearsBack = event.target.value;
    let params = [
      props.accounting.data.searchQuery.date,
      props.accounting.data.searchQuery.yearsBack,
    ];

  };

  const CurrentLookupTypeButton = () => {
    return (
      <IconButton>
        <SearchIcon mr={2} />
      </IconButton>
    );
  };

  const SearchBar = () => {
    const classes = useStyles();

    return (
      <Grid container lg={12} spacing={1}>
        <Grid item lg={8} md={8} sm={8}>
          <TextField
            id={"searchTargetDate"}
            label="As of Date"
            type={"date"}
            onChange={handleSearchTargetChangeDate}
            autoFocus={"autofocus"}
            name={"searchTarget"}
            value={props.accounting.data.searchQuery.date}
            fullWidth
            my={2}
            size={"small"}
            variant={"outlined"}
            InputLabelProps={{
              shrink: true,
            }}
            helperText="Aging report as of date"
          ></TextField>
          {/* <TextField
            id={"searchTargetYears"}
            label="Years Back"
            type={"number"}
            onChange={handleSearchTargetChangeYearsBack}
            autoFocus={"autofocus"}
            name={"searchTarget"}
            value={props.accounting.data.searchQuery.yearsBack}
            fullWidth
            my={2}
            size={"small"}
            variant={"outlined"}
            InputLabelProps={{
              shrink: true,
            }}
          ></TextField> */}
          <FormControl className={classes.formControl}>
            <InputLabel>Age</InputLabel>

            <Select
              native
              label="Years Back"
              helperText="Years back to pull report"
              onChange={handleSearchTargetChangeYearsBack}
            >
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={3}>4</option>
              <option value={5}>5</option>
              ]
            </Select>
          </FormControl>
          <TextField
            id={"emailText"}
            value={props.accounting.data.searchQuery.email}
            label="Email"
            type={"text"}
            onChange={handleSearchTargetChangeEmail}
            name={"email"}
            fullWidth
            my={2}
            size={"small"}
            InputLabelProps={{
              shrink: true,
            }}
            helperText="This email will recieve the report requested."
          ></TextField>
          <Button
            variant="contained"
            color="primary"
            onClick={sendRequest}
          >
            Request Aging Report
          </Button>
        </Grid>

      </Grid>
    );
  };

  // Table Stuff:
  const fieldTitles = ["Account", "0-30", "31-60", "61-90", ">90"];
  const fieldTypes = ["label", "label", "label", "label", "label"];
  const fieldNames = [
    "AccountNum",
    "Aging_0_30",
    "Aging_31_60",
    "Aging_61_90",
    "Aging_Over_91",
  ];

  const onSelected = (item) => { };

  const onUpdate = () => {
    hide();
  };

  const [display, setDisplay] = React.useState(false);

  const show = () => {
    props.accounting.data.agingList = {}; // TODO: clear property on popup show
    setDisplay(true);
  };

  const hide = () => {
    setDisplay(false);
  };

  const NewDialog = () => {
    return (
      <Dialog
        open={display}
        TransitionComponent={Transition}
        fullWidth={"lg"}
        maxWidth={"lg"}
        keepMounted
        onClose={hide}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">New Aging</DialogTitle>
        <DialogContent></DialogContent>
      </Dialog>
    );
  };

  return (
    <Card mb={6} elevation={3}>
      <CardContent>
        <NewDialog />
        <SearchBar />
        {/* <EditableTable
          readOnly={false}
          isLoading={props.accounting.isLoading}
          handleRowClickParentMethod={onSelected}
          tableTitle={"Aging"}
          canAddItems={false}
          canSave={false}
          canExport={true}
          objectArray={props.accounting.data.agingList}
          fieldTitles={fieldTitles}
          fieldTypes={fieldTypes}
          fieldNames={fieldNames}
        /> */}
      </CardContent>
    </Card>
  );
}

Table.propTypes = {
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  accounting: PropTypes.object.isRequired,
  getaging: PropTypes.func.isRequired,
};

function State(state) {
  return {
    user: state.user,
    apl: state.apl,
    accounting: state.accounting,
    search: state.search,
  };
}

export default connect(State, { getaging })(Table);
