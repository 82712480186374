import React, { lazy, Suspense, Fragment } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

import { ThemeProvider } from '@material-ui/styles';

import { ClimbingBoxLoader } from 'react-spinners';

import MuiTheme from './theme';

// Layout Blueprints

import {
  LeftSidebar,
  CollapsedSidebar,
  MinimalLayout,
  PresentationLayout
} from './layout-blueprints';

// Example Pages
import PagesLogin from './example-pages/PagesLogin';
import PagesRegister from './example-pages/PagesRegister';
import PagesRecoverPassword from './example-pages/PagesRecoverPassword';
import PagesProfile from './example-pages/PagesProfile';
import PagesInvoice from './example-pages/PagesInvoice';
import PagesError404 from './example-pages/PagesError404';
import PagesError500 from './example-pages/PagesError500';
import PagesError505 from './example-pages/PagesError505';
import Buttons from './example-pages/Buttons';
import Dropdowns from './example-pages/Dropdowns';
import NavigationMenus from './example-pages/NavigationMenus';
import Avatars from './example-pages/Avatars';
import ProgressBars from './example-pages/ProgressBars';
import Ratings from './example-pages/Ratings';
import Pagination from './example-pages/Pagination';
import Ribbons from './example-pages/Ribbons';
import Scrollable from './example-pages/Scrollable';
import Badges from './example-pages/Badges';
import Icons from './example-pages/Icons';
import Timelines from './example-pages/Timelines';
import UtilitiesHelpers from './example-pages/UtilitiesHelpers';
import Countup from './example-pages/Countup';
import RegularTables1 from './example-pages/RegularTables1';
import RegularTables2 from './example-pages/RegularTables2';
import RegularTables3 from './example-pages/RegularTables3';
import RegularTables4 from './example-pages/RegularTables4';
import RegularTables5 from './example-pages/RegularTables5';
import FormsControls from './example-pages/FormsControls';
import FormsDualListbox from './example-pages/FormsDualListbox';
import FormsToggleSwitch from './example-pages/FormsToggleSwitch';
import DashboardServerStatus from "./example-pages/DashboardServerStatus";
import ApplicationsChat from './example-pages/ApplicationsChat';
import ApplicationsContacts from './example-pages/ApplicationsContacts';
import ApplicationsFileManager from './example-pages/ApplicationsFileManager';
import ApplicationsMail from './example-pages/ApplicationsMail';
import ApplicationsProjects from './example-pages/ApplicationsProjects';
import ApplicationsWidgets from './example-pages/ApplicationsWidgets';

//Pages Main Application
import UbilSnackbar from "./modules/snackbar/components/snackbar";
import Accounting from "./pages/Accounting";
import Accounts from "./pages/Accounts";
import Users from "./pages/Users"
import Batches from "./pages/Batches";
import BillingDashboard from "./pages/BillingDashboard";
import BillingWizard from "./pages/BillingWizard";
import BulkCheckEntry from "./pages/BulkCheckEntry";
import Dashboard from "./pages/Dashboard";
import DevPage from "./pages/Dev";
import Developers from "./pages/Developers";
import ForgotPassword from "./pages/ForgotPassword";
import Home from "./pages/Home";
import AccountManagerPage from "./pages/IndividualAccount";
import IndividualMeterPage from "./pages/IndividualMeter";
import IndividualPropertyPage from "./pages/IndividualProperty";
import IndividualPropertyManagerPage from "./pages/IndividualPropertyManager";
import IndividualReport from "./pages/IndividualReport";
import IndividualUnitPage from "./pages/IndividualUnit";
import Login from "./pages/Login";
import MeterReadingsUpload from "./pages/MeterReadingsUpload";
import LockboxUpload from './pages/LockboxUpload';
import Moves from "./pages/Moves";
import Owners from "./pages/Owners";
import PropertyManagerSearch from "./pages/PMSearch";
import PropertiesPage from "./pages/Properties";
import Reports from "./pages/Reports";
import ResetPassword from "./pages/ResetPassword";
import Settings from "./pages/Settings";
import WorkOrders from "./pages/WorkOrders";
import PageDownForMaintenance from "./pages/PageDownForMaintenance";
import BatchUnits from "./pages/BatchUnit";
import BillingPdfDownload from "./pages/BillingPdfDownload";


import RoutePrivate from "./utils/PrivateRoute";
import theme from "./theme";

//Custom Components
import DashboardDefault from './example-pages/DashboardDefault';
import DashboardAnalytics from './example-pages/DashboardAnalytics';
import DashboardSales from './example-pages/DashboardSales';
import DashboardReports from './example-pages/DashboardReports';
import DashboardRealEstate from './example-pages/DashboardRealEstate';
import DashboardProjects from './example-pages/DashboardProjects';
import DashboardHelpdesk from './example-pages/DashboardHelpdesk';
import DashboardCustomers from './example-pages/DashboardCustomers';
import DashboardCryptocurrency from './example-pages/DashboardCryptocurrency';
import DashboardCrmManager from './example-pages/DashboardCrmManager';
import DashboardContent from './example-pages/DashboardContent';
import ApplicationsCalendar from './example-pages/ApplicationsCalendar';
import Cards1 from './example-pages/Cards1';
import Cards2 from './example-pages/Cards2';
import Cards3 from './example-pages/Cards3';
import Cards4 from './example-pages/Cards4';
import Cards5 from './example-pages/Cards5';
import Cards6 from './example-pages/Cards6';
import Cards7 from './example-pages/Cards7';
import Cards8 from './example-pages/Cards8';
import Cards9 from './example-pages/Cards9';
import Cards10 from './example-pages/Cards10';
import Cards11 from './example-pages/Cards11';
import Cards12 from './example-pages/Cards12';
import LandingPage from './example-pages/LandingPage';
import FeatureSections from './example-pages/FeatureSections';
import IconBoxes from './example-pages/IconBoxes';
import PricingTables from './example-pages/PricingTables';
import HeaderSections from './example-pages/HeaderSections';
import TestimonialsSections from './example-pages/TestimonialsSections';
import BlogSections from './example-pages/BlogSections';
import PartnersSections from './example-pages/PartnersSections';
import CtaBoxes from './example-pages/CtaBoxes';
import FooterSections from './example-pages/FooterSections';
import HeroSections from './example-pages/HeroSections';
import Accordions from './example-pages/Accordions';
import Modals from './example-pages/Modals';
import Notifications from './example-pages/Notifications';
import DragDrop from './example-pages/DragDrop';
import Calendars from './example-pages/Calendars';
import Carousels from './example-pages/Carousels';
import Popovers from './example-pages/Popovers';
import TreeView from './example-pages/TreeView';
import Tabs from './example-pages/Tabs';
import GuidedTours from './example-pages/GuidedTours';
import ImageCrop from './example-pages/ImageCrop';
import LoadingIndicators from './example-pages/LoadingIndicators';
import ContextMenus from './example-pages/ContextMenus';
import DynamicTables2 from './example-pages/DynamicTables2';
import FormsWizard from './example-pages/FormsWizard';
import FormsClipboard from './example-pages/FormsClipboard';
import FormsColorpicker from './example-pages/FormsColorpicker';
import FormsDatepicker from './example-pages/FormsDatepicker';
import FormsInputMask from './example-pages/FormsInputMask';
import FormsInputSelect from './example-pages/FormsInputSelect';
import FormsSlider from './example-pages/FormsSlider';
import FormsTextareaAutosize from './example-pages/FormsTextareaAutosize';
import FormsTimepicker from './example-pages/FormsTimepicker';
import FormsTypeahead from './example-pages/FormsTypeahead';
import FormsUpload from './example-pages/FormsUpload';
import FormsValidation from './example-pages/FormsValidation';
import FormsWysiwygEditor from './example-pages/FormsWysiwygEditor';
import ApexCharts from './example-pages/ApexCharts';
import GaugesCharts from './example-pages/GaugesCharts';
import ChartjsCharts from './example-pages/ChartjsCharts';
import SparklinesCharts from './example-pages/SparklinesCharts';
import Maps from './example-pages/Maps';
import ListGroups from './example-pages/ListGroups';
import IndividualOwnerPage from "./pages/IndividualOwner";
import BillingInformationSummary from "./pages/BillingInformationTable";

// DEV TEMPLATE REVEALING
// Mark this to true to include dev template routes in compile
const isDev = false;

const Routes = () => {
  const location = useLocation();
  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99
    },
    in: {
      opacity: 1,
      scale: 1
    },
    out: {
      opacity: 0,
      scale: 1.01
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4
  };

  const SuspenseLoading = () => {
    return (
      <Fragment>
        <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
          <div className="d-flex align-items-center flex-column px-4">
            <ClimbingBoxLoader color={'#5383ff'} loading={true} />
          </div>
          <div className="text-muted font-size-xl text-center pt-3">
            Please wait while we load data for Ubuil+
            <span className="font-size-lg d-block text-dark">
              Loading...
            </span>
          </div>
        </div>
      </Fragment>
    );
  };


  const mainApplicationRoutes = [
    <Route exact path="/" component={Home} />,
    <Route exact path="/DevPage" component={DevPage} />,
    <Route
      path="/reset-password/:token/:email"
      component={ResetPassword}
    />,
    <Route path="/login" component={Login} />,
    <Route path="/forgotpassword" component={ForgotPassword} />,
    <RoutePrivate exact path="/dashboard" component={Dashboard} />,
    <RoutePrivate exact path="/accounts" component={Accounts} />,
    <RoutePrivate exact path="/users" component={Users} />,
    <RoutePrivate exact path="/accounting" component={Accounting} />,
    <RoutePrivate exact path="/billing-information" component={BillingInformationSummary} />,
    <RoutePrivate path="/page-maintenance" component={PageDownForMaintenance} />,
    <RoutePrivate
      exact
      path="/accountmanager/:acctNum/:uuid"
      component={AccountManagerPage}
    />,
    <RoutePrivate
      exact
      path="/accountmanager/:acctNum"
      component={AccountManagerPage}
    />,
    <RoutePrivate exact path="/batches/:id" component={Batches} />,
    <RoutePrivate
      exact
      path="/billingwizard"
      component={BillingWizard}
    />,
    <RoutePrivate
      exact
      path="/billingwizard/:wtUuid"
      component={BillingWizard}
    />,
    <RoutePrivate
      exact
      path="/propertyManager/:uuid/:id"
      component={IndividualPropertyManagerPage}
    />,
    <RoutePrivate
      exact
      path="/propertyOwner/:uuid/:id"
      component={IndividualOwnerPage}
    />,
    <RoutePrivate
      exact
      path="/billingdashboard"
      component={BillingDashboard}
    />,
    <RoutePrivate
      exact
      path="/billingdashboard/:querydelay"
      component={BillingDashboard}
    />,
    <RoutePrivate
      exact
      path="/property-manager-search"
      component={PropertyManagerSearch}
    />,
    <RoutePrivate path="/bulkcheckentry" component={BulkCheckEntry} />,
    <RoutePrivate path="/billingpdf" component={BillingPdfDownload}/>,
    <RoutePrivate path="/developers" component={Developers} />,
    <RoutePrivate path="/moves" component={Moves} />,
    <RoutePrivate path="/workorders" component={WorkOrders} />,
    <RoutePrivate path="/owners" component={Owners} />,
    <RoutePrivate path="/properties" component={PropertiesPage} />,
    <RoutePrivate
      path="/property/:uuid/:id/:propertyManagerId"
      component={IndividualPropertyPage}
    />,
    <RoutePrivate exact path="/batch-units/property/:propertyUuid" component={BatchUnits} />,
    <RoutePrivate
      path="/unit/:uuid/"
      component={IndividualUnitPage}
    />,
    <RoutePrivate path="/meter/:uuid" component={IndividualMeterPage} />,
    <RoutePrivate
      exact
      path="/meter-readings-upload"
      component={MeterReadingsUpload}
    />,
    <RoutePrivate
      exact
      path="/lockbox-upload"
      component={LockboxUpload}
    />,
    <RoutePrivate exact path="/reports" component={Reports} />,
    <RoutePrivate
      exact
      path="/reports/:reportName"
      component={IndividualReport}
    />,
    <RoutePrivate path="/settings" component={Settings} />
  ]


  return (
    <ThemeProvider theme={MuiTheme}>
      <UbilSnackbar />
      <AnimatePresence>
        <Suspense fallback={<SuspenseLoading />}>
          <Switch>
            {!isDev ?
              <>
                {mainApplicationRoutes}
                <Route path="/404" component={PagesError404} />
              </>
              :
              <>
                {mainApplicationRoutes}
                <RoutePrivate path={['/LandingPage']}>
                  <PresentationLayout>
                    <Switch location={location} key={location.pathname}>
                      <motion.div
                        initial="initial"
                        animate="in"
                        exit="out"
                        variants={pageVariants}
                        transition={pageTransition}>
                        <Route path="/LandingPage" component={LandingPage} />
                      </motion.div>
                    </Switch>
                  </PresentationLayout>
                </RoutePrivate>
                <Route
                  path={[
                    '/ApplicationsCalendar',
                    '/ApplicationsChat',
                    '/ApplicationsContacts',
                    '/ApplicationsFileManager',
                    '/ApplicationsMail'
                  ]}>
                  <CollapsedSidebar>
                    <Switch location={location} key={location.pathname}>
                      <motion.div
                        initial="initial"
                        animate="in"
                        exit="out"
                        variants={pageVariants}
                        transition={pageTransition}>
                        <Route
                          path="/ApplicationsCalendar"
                          component={ApplicationsCalendar}
                        />
                        <Route
                          path="/ApplicationsChat"
                          component={ApplicationsChat}
                        />
                        <Route
                          path="/ApplicationsContacts"
                          component={ApplicationsContacts}
                        />
                        <Route
                          path="/ApplicationsFileManager"
                          component={ApplicationsFileManager}
                        />
                        <Route
                          path="/ApplicationsMail"
                          component={ApplicationsMail}
                        />
                      </motion.div>
                    </Switch>
                  </CollapsedSidebar>
                </Route>
                <Route
                  path={[
                    '/PagesLogin',
                    '/PagesRegister',
                    '/PagesRecoverPassword',
                    '/PagesError404',
                    '/PagesError500',
                    '/PagesError505'
                  ]}>
                  <MinimalLayout>
                    <Switch location={location} key={location.pathname}>
                      <motion.div
                        initial="initial"
                        animate="in"
                        exit="out"
                        variants={pageVariants}
                        transition={pageTransition}>
                        <Route path="/PagesLogin" component={PagesLogin} />
                        <Route path="/PagesRegister" component={PagesRegister} />
                        <Route
                          path="/PagesRecoverPassword"
                          component={PagesRecoverPassword}
                        />
                        <Route path="/PagesError404" component={PagesError404} />
                        <Route path="/PagesError500" component={PagesError500} />
                        <Route path="/PagesError505" component={PagesError505} />
                      </motion.div>
                    </Switch>
                  </MinimalLayout>
                </Route>
                <Route exact path="/" component={Home} />
                <Route exact path="/DevPage" component={DevPage} />
                <Route
                  path="/reset-password/:token/:email"
                  component={ResetPassword}
                />
                <Route path="/forgotpassword" component={ForgotPassword} />
                <Route
                  path={[
                    '/DashboardDefault',
                    '/DashboardAnalytics',
                    '/DashboardSales',
                    '/DashboardReports',
                    '/DashboardRealEstate',
                    '/DashboardServerStatus',
                    '/DashboardProjects',
                    '/DashboardHelpdesk',
                    '/DashboardCustomers',
                    '/DashboardCryptocurrency',
                    '/DashboardCrmManager',
                    '/DashboardContent',
                    '/ApplicationsProjects',
                    '/ApplicationsWidgets',
                    '/PagesProfile',
                    '/PagesInvoice',
                    '/Buttons',
                    '/Dropdowns',
                    '/NavigationMenus',
                    '/Avatars',
                    '/ProgressBars',
                    '/Ratings',
                    '/Pagination',
                    '/Ribbons',
                    '/Scrollable',
                    '/Badges',
                    '/Icons',
                    '/Timelines',
                    '/UtilitiesHelpers',
                    '/Cards1',
                    '/Cards2',
                    '/Cards3',
                    '/Cards4',
                    '/Cards5',
                    '/Cards6',
                    '/Cards7',
                    '/Cards8',
                    '/Cards9',
                    '/Cards10',
                    '/Cards11',
                    '/Cards12',
                    '/FeatureSections',
                    '/IconBoxes',
                    '/PricingTables',
                    '/HeaderSections',
                    '/TestimonialsSections',
                    '/BlogSections',
                    '/PartnersSections',
                    '/CtaBoxes',
                    '/FooterSections',
                    '/HeroSections',
                    '/Accordions',
                    '/Modals',
                    '/Notifications',
                    '/DragDrop',
                    '/Calendars',
                    '/Carousels',
                    '/Popovers',
                    '/TreeView',
                    '/Tabs',
                    '/GuidedTours',
                    '/ImageCrop',
                    '/LoadingIndicators',
                    '/Countup',
                    '/ContextMenus',
                    '/RegularTables1',
                    '/RegularTables2',
                    '/RegularTables3',
                    '/RegularTables4',
                    '/RegularTables5',
                    '/DynamicTables2',
                    '/FormsControls',
                    '/FormsWizard',
                    '/FormsClipboard',
                    '/FormsColorpicker',
                    '/FormsDatepicker',
                    '/FormsDualListbox',
                    '/FormsInputMask',
                    '/FormsInputSelect',
                    '/FormsSlider',
                    '/FormsTextareaAutosize',
                    '/FormsTimepicker',
                    '/FormsToggleSwitch',
                    '/FormsTypeahead',
                    '/FormsUpload',
                    '/FormsValidation',
                    '/FormsWysiwygEditor',
                    '/ApexCharts',
                    '/GaugesCharts',
                    '/ChartjsCharts',
                    '/SparklinesCharts',
                    '/Maps',
                    '/ListGroups'
                  ]}>
                  <LeftSidebar>
                    <Switch location={location} key={location.pathname}>
                      <motion.div
                        initial="initial"
                        animate="in"
                        exit="out"
                        variants={pageVariants}
                        transition={pageTransition}>
                        <Route
                          path="/"
                          component={Home}
                        />
                        <Route
                          path="/DashboardAnalytics"
                          component={DashboardAnalytics}
                        />
                        <Route path="/DashboardSales" component={DashboardSales} />
                        <Route
                          path="/DashboardReports"
                          component={DashboardReports}
                        />
                        <Route
                          path="/DashboardRealEstate"
                          component={DashboardRealEstate}
                        />
                        <Route
                          path="/DashboardServerStatus"
                          component={DashboardServerStatus}
                        />
                        <Route
                          path="/DashboardProjects"
                          component={DashboardProjects}
                        />
                        <Route
                          path="/DashboardHelpdesk"
                          component={DashboardHelpdesk}
                        />
                        <Route
                          path="/DashboardCustomers"
                          component={DashboardCustomers}
                        />
                        <Route
                          path="/DashboardCryptocurrency"
                          component={DashboardCryptocurrency}
                        />
                        <Route
                          path="/DashboardCrmManager"
                          component={DashboardCrmManager}
                        />
                        <Route
                          path="/DashboardContent"
                          component={DashboardContent}
                        />
                        <Route
                          path="/ApplicationsProjects"
                          component={ApplicationsProjects}
                        />
                        <Route
                          path="/ApplicationsWidgets"
                          component={ApplicationsWidgets}
                        />
                        <Route path="/PagesProfile" component={PagesProfile} />
                        <Route path="/PagesInvoice" component={PagesInvoice} />
                        <Route path="/Buttons" component={Buttons} />
                        <Route path="/Dropdowns" component={Dropdowns} />
                        <Route
                          path="/NavigationMenus"
                          component={NavigationMenus}
                        />
                        <Route path="/Avatars" component={Avatars} />
                        <Route path="/ProgressBars" component={ProgressBars} />
                        <Route path="/Ratings" component={Ratings} />
                        <Route path="/Pagination" component={Pagination} />
                        <Route path="/Ribbons" component={Ribbons} />
                        <Route path="/Scrollable" component={Scrollable} />
                        <Route path="/Badges" component={Badges} />
                        <Route path="/Icons" component={Icons} />
                        <Route path="/Timelines" component={Timelines} />
                        <Route
                          path="/UtilitiesHelpers"
                          component={UtilitiesHelpers}
                        />
                        <Route path="/Cards1" component={Cards1} />
                        <Route path="/Cards2" component={Cards2} />
                        <Route path="/Cards3" component={Cards3} />
                        <Route path="/Cards4" component={Cards4} />
                        <Route path="/Cards5" component={Cards5} />
                        <Route path="/Cards6" component={Cards6} />
                        <Route path="/Cards7" component={Cards7} />
                        <Route path="/Cards8" component={Cards8} />
                        <Route path="/Cards9" component={Cards9} />
                        <Route path="/Cards10" component={Cards10} />
                        <Route path="/Cards11" component={Cards11} />
                        <Route path="/Cards12" component={Cards12} />
                        <Route
                          path="/FeatureSections"
                          component={FeatureSections}
                        />
                        <Route path="/IconBoxes" component={IconBoxes} />
                        <Route path="/PricingTables" component={PricingTables} />
                        <Route path="/HeaderSections" component={HeaderSections} />
                        <Route
                          path="/TestimonialsSections"
                          component={TestimonialsSections}
                        />
                        <Route path="/BlogSections" component={BlogSections} />
                        <Route
                          path="/PartnersSections"
                          component={PartnersSections}
                        />
                        <Route path="/CtaBoxes" component={CtaBoxes} />
                        <Route path="/FooterSections" component={FooterSections} />
                        <Route path="/HeroSections" component={HeroSections} />
                        <Route path="/Accordions" component={Accordions} />
                        <Route path="/Modals" component={Modals} />
                        <Route path="/Notifications" component={Notifications} />
                        <Route path="/DragDrop" component={DragDrop} />
                        <Route path="/Calendars" component={Calendars} />
                        <Route path="/Carousels" component={Carousels} />
                        <Route path="/Popovers" component={Popovers} />
                        <Route path="/TreeView" component={TreeView} />
                        <Route path="/Tabs" component={Tabs} />
                        <Route path="/GuidedTours" component={GuidedTours} />
                        <Route path="/ImageCrop" component={ImageCrop} />
                        <Route
                          path="/LoadingIndicators"
                          component={LoadingIndicators}
                        />
                        <Route path="/Countup" component={Countup} />
                        <Route path="/ContextMenus" component={ContextMenus} />
                        <Route path="/RegularTables1" component={RegularTables1} />
                        <Route path="/RegularTables2" component={RegularTables2} />
                        <Route path="/RegularTables3" component={RegularTables3} />
                        <Route path="/RegularTables4" component={RegularTables4} />
                        <Route path="/RegularTables5" component={RegularTables5} />
                        <Route path="/DynamicTables2" component={DynamicTables2} />
                        <Route path="/FormsControls" component={FormsControls} />
                        <Route path="/FormsWizard" component={FormsWizard} />
                        <Route path="/FormsClipboard" component={FormsClipboard} />
                        <Route
                          path="/FormsColorpicker"
                          component={FormsColorpicker}
                        />
                        <Route
                          path="/FormsDatepicker"
                          component={FormsDatepicker}
                        />
                        <Route
                          path="/FormsDualListbox"
                          component={FormsDualListbox}
                        />
                        <Route path="/FormsInputMask" component={FormsInputMask} />
                        <Route
                          path="/FormsInputSelect"
                          component={FormsInputSelect}
                        />
                        <Route path="/FormsSlider" component={FormsSlider} />
                        <Route
                          path="/FormsTextareaAutosize"
                          component={FormsTextareaAutosize}
                        />
                        <Route
                          path="/FormsTimepicker"
                          component={FormsTimepicker}
                        />
                        <Route
                          path="/FormsToggleSwitch"
                          component={FormsToggleSwitch}
                        />
                        <Route path="/FormsTypeahead" component={FormsTypeahead} />
                        <Route path="/FormsUpload" component={FormsUpload} />
                        <Route
                          path="/FormsValidation"
                          component={FormsValidation}
                        />
                        <Route
                          path="/FormsWysiwygEditor"
                          component={FormsWysiwygEditor}
                        />
                        <Route path="/ApexCharts" component={ApexCharts} />
                        <Route path="/GaugesCharts" component={GaugesCharts} />
                        <Route path="/ChartjsCharts" component={ChartjsCharts} />
                        <Route
                          path="/SparklinesCharts"
                          component={SparklinesCharts}
                        />
                        <Route path="/Maps" component={Maps} />
                        <Route path="/ListGroups" component={ListGroups} />
                      </motion.div>
                    </Switch>
                  </LeftSidebar>
                </Route>
              </>
            }
          </Switch>
        </Suspense>
      </AnimatePresence>
    </ThemeProvider>
  );
};

export default Routes;
