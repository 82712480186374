import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { setSnackbar } from "../../../modules/snackbar/api/snackbar";
import moment from 'moment';
import {
    Grid,
    IconButton,
    Breadcrumbs as MuiBreadcrumbs,
    Divider as MuiDivider,
    Paper as MuiPaper,
    MenuItem,
    TextField as MuiTextField,
    Button as MuiButton,
    Card as MuiCard,
    CardContent,
    CircularProgress as MuiCircularProgress,
    Dialog,
    DialogContent,
    DialogActions,
    DialogContentText,
    InputLabel,
    DialogTitle,
    Select,
    Typography,
    FormControl as MuiFormControl,
    Tooltip
} from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import SchoolIcon from '@material-ui/icons/School';
import SearchIcon from "@material-ui/icons/Search";
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { spacing } from "@material-ui/system";
import { EditableTable, newDropDownField } from "../../../components/EditableTable";

import { dateFormatter, dateTime, formatMoney, txtBoxDateFormatter, CalculateSelectedPropertyTotalCommonsCharge, buildRiders } from "../../../setup/helpers";
import MuiAlert from '@material-ui/lab/Alert';
// Redux Components
import PropTypes from "prop-types";
import { connect , useDispatch} from "react-redux";
import { updateLocalPropertyRiderRates, updateRiderRate, deleteRiderRate, createRiderRate, syncRiderRatesBetweenProperties, syncRiderRatesForAllProperties, getPropRiderRatesByPropByUuid } from "../api/actions";
import Form from "./Form";
import styles from './styling/style'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);
const Card = styled(MuiCard)(spacing);
const FormControl = styled(MuiFormControl)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function Table(props) {
    const [riderRatesFormOpen, setRiderRatesFormOpen] = React.useState(false);
    const [riderRatesPropSyncFormOpen, setRiderRatesPropSyncFormOpen] = React.useState(false);
    const [testRiderRatesFormOpen, setTestRiderRatesFormOpen] = React.useState(false);
    const [testRiderRatesFormOpenRefreshFire, setTestRiderRatesFormOpenRefreshFire] = React.useState(false);
    const [targetProperty, setTargetProperty] = React.useState("");
    const [currentTestKWH, setCurrentTestKWH] = React.useState(props.properties.currentRiderRateTestKwhCharge);
    const [riderRateTestProduct, setRiderRateTestProduct] = React.useState([]);
    const [riderRateTestProductSummary, setRiderRateTestProductSummary] = React.useState(null);
    const [riderRatesDialogAlertOpen, setriderRatesDialogAlertOpen] = React.useState(false);
    
    const canAddOrUpdate = (props.user.details.administration || props.user.details.billing || props.user.details.isSuperUser) ? true : false;
    const dispatch = useDispatch();

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1
        },
        container: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        loadingIcon: {
            position: 'relative',
        },
        typography: {
            textAlign: 'center'
        },
        rightSideHeaderSubMenuToolBar: {
            display: "flex",
            alignItems: 'flex-end',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            margin: "0px",
            padding: "0px",
        }
    }));
    const classes = useStyles();

    // Table Stuff:
    // Preview Table
    const readOnly_fieldTitles = ["Name", "Type", "Basis"];
    const readOnly_fieldTypes = ["label", "label", "label"];
    const readOnly_fieldNames = ["riderName", "riderType", "rateBasis"];

    // Editable Table
    const editable_fieldTitles = ["Name", "Type", "Min/Max Kwh", "Rate Basis", "Summer/Winter Rate", "Rate Type", "Start/End"];
    const editable_fieldTypes = ["text", "dropDown", ["number", "number"], "dropDown", ["number", "number"], "dropDown", ["dateField", "dateField"]];
    const editable_fieldNames = ["riderName", "riderType", ["minKwh", "maxKwh"], "rateBasis", ["rateSummer", "rateWinter"], "rateType", ["startDate", "endDate"]];
    const editable_ResourceFields = [
        newDropDownField("riderType", [{ "x": "Distribution" }, { "x": "Generation" }, { "x": "Transmission" },], "x", "x"),
        newDropDownField("rateBasis", [{ "x": "kWh" }, { "x": "Flat" }, { "x": "Base" },], "x", "x"),
        newDropDownField("rateType", [{ "x": "$" }, { "x": "%" }], "x", "x"),
    ];

    const updateLocalItemsValues = (items) => {
        props.updateLocalPropertyRiderRates(items);
    }

    const createLineItemData = (riderName, riderType, minKwh, maxKwh, rateBasis, rateSummer, rateWinter, rateType, startDate, endDate) => {
        return { riderName, riderType, minKwh, maxKwh, rateBasis, rateSummer, rateWinter, rateType, startDate, endDate };
    }

    const createBlankLineItem = () => {
        return createLineItemData("", "", 0, 0, "", 0, 0, "", "", "");
    }

    const addBlankItemToArray = () => {
        props.properties.propertyRiderRates.items.push(createBlankLineItem());
        props.updateLocalPropertyRiderRates(props.properties.propertyRiderRates);
    }



    // To be used to save over the api in the database
    const saveAllLineItems = (riderRates) => {
        props.updateLocalPropertyRiderRates(riderRates);
        riderRates.toUpdate.map(targetUuid => {
            let targetItemIndex = riderRates.items.findIndex(x => x.uuid === targetUuid);
            if (targetItemIndex != -1) {
                props.updateRiderRate(riderRates.items[targetItemIndex]);
            }
        });
        riderRates.toDelete.map(targetUuid => {
            props.deleteRiderRate(targetUuid, props.properties.data.property.uuid);
        });
        var ridersToCreate = [];
        var existingRiders = [];
        for (var x = 0; x < riderRates.items.length; x++) {
            if (!riderRates.items[x].id) {
                ridersToCreate.push(riderRates.items[x]);
            }
            else {
                existingRiders.push(riderRates.items[x]);
            }
        }
        if (ridersToCreate.length > 0) {
            props.createRiderRate(ridersToCreate, props.properties.data.property.uuid);
        }
        props.updateLocalPropertyRiderRates({
            items: existingRiders,
            toUpdate: [],
            toDelete: [],
        });
    }


    const handleTestRiderRatesFormClickOpen = () => {
        setTestRiderRatesFormOpen(true);
    };
    const handleTestRiderRatesFormClickClose = () => {
        setTestRiderRatesFormOpen(false);
        setTestRiderRatesFormOpenRefreshFire(false);
    };

    const handleRiderRatesPropSyncFormClickOpen = () => {
        setRiderRatesPropSyncFormOpen(true);
    };

    const handleRiderRatesPropSyncFormClose = () => {
        setRiderRatesPropSyncFormOpen(false);
    };

    const handleRiderRatesFormClickOpen = () => {
        setRiderRatesFormOpen(true);
    };

    const handleRiderRatesFormClose = () => {
        setRiderRatesFormOpen(false);
    };
    const handleSelectedPropertyChange = (event) => {
        setTargetProperty(event.target.value);
    };
    const onSyncAllRiderRatesComplete = async () => {
        let syncAllProps = await props.syncRiderRatesForAllProperties(props.properties.data.property.uuid);
        if(syncAllProps.data?.success == "true"){
        dispatch(setSnackbar(true , "success", "Sync All Property Rider Rates Successful"));
        }else{
        dispatch(setSnackbar(true , "error", "Sync All Property Rider Rates Error"));
        }
         handleRiderRatesDialogAlertClose();
    };
    const onRiderRatesTestKwhChange = (event) => {
        props.properties.currentRiderRateTestKwhCharge = Number(event.target.value);
    };

    const handleRefreshTestClick = () => {
        CalculateTestRiderRate();
    };
    const handleRiderRatesDialogAlertOpen = () => {
        setriderRatesDialogAlertOpen(true);
    };
    
    const handleRiderRatesDialogAlertClose = () => {
        setriderRatesDialogAlertOpen(false);
    };


    useEffect(() => {
        if (!props.properties.riderRatesAreLoading && !props.properties.propertyAdditionalCommonAreaExpensesIsLoading && !props.properties.propertyResidualAmountsToAddIsLoading) {
            //console.log("CalculateTestRiderRate");
            CalculateTestRiderRate();
        }
        if (testRiderRatesFormOpen && !testRiderRatesFormOpenRefreshFire) {
            props.getPropRiderRatesByPropByUuid(props.properties.data.property.uuid);
            setTestRiderRatesFormOpenRefreshFire(true);
        }
    }, [props.properties.riderRatesAreLoading, props.properties.propertyAdditionalCommonAreaExpensesIsLoading, props.properties.propertyResidualAmountsToAddIsLoading, testRiderRatesFormOpen]);

    const Toolbar = () => {
        return (
            <Grid container xs={12} sm={12} md={12} lg={12} spacing={4} className={classes.rightSideHeaderSubMenuToolBar}><Grid item>
                <Tooltip title="Test Rider Rates Outputs">
                    <Button onClick={handleTestRiderRatesFormClickOpen} aria-label="Test rider rate outputs with different kwh usages">
                        <AssignmentTurnedInIcon fontSize="medium" className={classes.SubMenuToolBarIcon} />
                    </Button>
                </Tooltip>
            </Grid>
                <Grid item>
                    <Tooltip title="Sync Rider Rates With Other Property">
                        <Button hidden={!canAddOrUpdate} onClick={handleRiderRatesPropSyncFormClickOpen} aria-label="Sync Rider Rates With Other Property">
                            <FileCopyIcon fontSize="medium" className={classes.SubMenuToolBarIcon} />
                        </Button>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip title="Edit Rider Rates">
                        <Button hidden={!canAddOrUpdate}  onClick={handleRiderRatesFormClickOpen} aria-label="Edit Rider Rates">
                            <EditIcon fontSize="medium" className={classes.SubMenuToolBarIcon} />
                        </Button>
                    </Tooltip>
                </Grid>
            </Grid>
        )
    }

    const CalculateTestRiderRate = () => {
        let currentTestKwhValue = props.properties.currentRiderRateTestKwhCharge || 1;
        // Common area total for kwh and usd
        let commonsTotal = 0.00;

        if (!props.properties.propertyResidualAmountsToAddIsLoading && !props.properties.propertyAdditionalCommonAreaExpensesIsLoading && !props.properties.isLoading) {
            //console.log(" props.properties.data.property ");
            //console.log(props.properties.data.property);
            commonsTotal = CalculateSelectedPropertyTotalCommonsCharge(props.properties.propertyAdditionalCommonAreaExpenses, props.properties.propertyResidualAmountsToAdd, props.properties.data.property.totalUnits);
        }

        const today = moment().format("MM/DD/YYYY");
        // Roder rates summary totals (subject to change)
        var testRiderResult = buildRiders(
            currentTestKwhValue,
            props.propertyUtilities.data.propertyUtility,
            props.properties.data.propertyRiderRates.items,
            0,
            today,
            props.properties.data.property.totalUnits,
            currentTestKwhValue
        );
        setCurrentTestKWH(props.properties.currentRiderRateTestKwhCharge);
        setRiderRateTestProductSummary(testRiderResult.bill);
        setRiderRateTestProduct(testRiderResult.riders);
    }
    var currentCustomerCharge = 0;
    var currentEnergyCharge = 0;
    if (props.propertyUtilities.data.propertyUtility) {
        currentCustomerCharge = props.propertyUtilities.data.propertyUtility.utilCustomerCharge;
        currentEnergyCharge = props.propertyUtilities.data.propertyUtility.utilEnergyCharge;
    }
    return (
        <Card mb={6} elevation={3}>
            <CardContent>
                <EditableTable
                    customToolbar={<Toolbar />}
                    readOnly={true}
                    canSave={false}
                    canAddItems={false}
                    isLoading={props.properties.riderRatesAreLoading}
                    tableTitle={"Property Rider Rates"}
                    objectArray={props.properties.propertyRiderRates}
                    fieldTitles={readOnly_fieldTitles}
                    fieldTypes={readOnly_fieldTypes}
                    fieldNames={readOnly_fieldNames}
                />
            </CardContent>

            {/* Rider Rates Editor Dialog */}
            <Dialog
                open={riderRatesFormOpen}
                TransitionComponent={Transition}
                fullWidth={true}
                maxWidth={"xl"}
                keepMounted
                onClose={handleRiderRatesFormClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent>
                    <EditableTable
                        hideRowFieldTitles={true}
                        canExport={true}
                        newItemCreationMethod={addBlankItemToArray}
                        isLoading={props.properties.riderRatesAreLoading}
                        tableTitle={"Property Rider Rates"}
                        objectArray={props.properties.propertyRiderRates}
                        fieldTitles={editable_fieldTitles}
                        fieldTypes={editable_fieldTypes}
                        fieldNames={editable_fieldNames}
                        dropDownFields={editable_ResourceFields}
                        isPagedLocally={false}
                        saveCurrentTableData={saveAllLineItems}
                        updateItemsLocalState={updateLocalItemsValues}
                    />
                </DialogContent>
            </Dialog>

            {/* Test Rider Rates Dialog  */}
            <Dialog
                open={testRiderRatesFormOpen}
                TransitionComponent={Transition}
                fullWidth={"lg"}
                maxWidth={"lg"}
                keepMounted
                onClose={handleTestRiderRatesFormClickClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    Test Rider Rates Output
                </DialogTitle>
                <DialogContent>{props.properties.riderRatesAreLoading || props.properties.propertyAdditionalCommonAreaExpensesIsLoading || props.properties.propertyResidualAmountsToAddIsLoading || riderRateTestProductSummary == null ?
                    <Grid container spacing={0}>
                        <Grid item lg={12} md={12} sm={12}>
                            <Divider className="mb-2 mt-2" my={1} />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6}>
                            <Typography variant="h5" >
                                Loading Property Rider Rates and Common Charges
                            </Typography>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6}>
                            <CircularProgress disableShrink className={classes.loadingIcon} />
                        </Grid>
                    </Grid>
                    :
                    <>
                        <Grid container xs={12} sm={12} md={12} lg={12} spacing={2} className={classes.rightSideHeaderSubMenuToolBar}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Grid item lg={12} md={12} sm={12}>
                                    <TextField
                                        id="kwh"
                                        label="KWH"
                                        type="number"
                                        fullWidth
                                        my={2}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        defaultValue={props.properties.currentRiderRateTestKwhCharge}
                                        onChange={onRiderRatesTestKwhChange}
                                        name="kwh"
                                    />
                                </Grid>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12}>
                                <MuiButton fullWidth variant="contained" onClick={handleRefreshTestClick} color="secondary">
                                    Refresh Test
                                </MuiButton>
                            </Grid>
                            {(riderRateTestProductSummary && riderRateTestProductSummary != null) &&
                                <Grid container xs={12} sm={12} md={12} lg={12} spacing={1}>
                                    <Grid item lg={12} md={12} sm={12}>
                                        <Divider className="mb-2 mt-2" my={1} />
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12}>
                                        <Grid container xs={12} sm={12} md={12} lg={12} spacing={3}>
                                            <Grid item lg={4} md={4} sm={4}>
                                                <Typography variant="h5">
                                                    Total Charge: $ {(riderRateTestProductSummary.totalCharge || 0).toFixed(4)}
                                                </Typography>
                                                <Typography variant="body2">
                                                    Rider Rates + Customer Charge + Energy Charge
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={4} md={4} sm={4}>
                                                <Typography variant="h5">
                                                    Cost / KWH: $ {((riderRateTestProductSummary.totalCharge || 0) / currentTestKWH).toFixed(4)}
                                                </Typography>
                                                <Typography variant="body2">
                                                    (Total Charge) / kWh
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={4} md={4} sm={4}>
                                                <Typography variant="h5">
                                                    Complete Bill: $ {((riderRateTestProductSummary.totalCharge || 0) + (riderRateTestProductSummary.commonAreaTotal || 0)).toFixed(4)}
                                                </Typography>
                                                <Typography variant="body2">
                                                    Rider Rates + Customer Charge + Common Charge
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12}>
                                        <Divider className="mb-2 mt-2" my={1} />
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={4}>
                                        <Typography variant="h6" >
                                            Riders Total: $ {(riderRateTestProductSummary.utilRidersTotal || 0).toFixed(4)}
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={4}>
                                        <Typography variant="h6" >
                                            Energy Total: $ {(riderRateTestProductSummary.energyCharge || 0).toFixed(4)}
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={4}>
                                        <Typography variant="h6" >
                                            Common Charge Total: $  {(riderRateTestProductSummary.commonAreaTotal || 0).toFixed(4)}
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={4}>
                                        <Typography variant="h6" >
                                            Customer Charge: $ {currentCustomerCharge}
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={4}>
                                        <Typography variant="h6" >
                                            Transmission Total: $ {(riderRateTestProductSummary.utilTransmissionTotal || 0).toFixed(4)}
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={4}>
                                        <Typography variant="h6" >
                                            Distribution Total: $ {(riderRateTestProductSummary.utilDistributionTotal || 0).toFixed(4)}
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12}>
                                        <Divider className="mb-2 mt-2" my={1} />
                                    </Grid>
                                </Grid>
                            }
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Grid container xs={12} sm={12} md={12} lg={12}>
                                    <Grid item lg={4} md={4} sm={4}>
                                        <Typography variant="h5" >
                                            Rider Name
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={2} md={2} sm={2}>
                                        <Typography variant="h5" >
                                            Type
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={2} md={2} sm={2}>
                                        <Typography variant="h5" >
                                            Value Type
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={2} md={2} sm={2}>
                                        <Typography variant="h5" >
                                            Rider Rate
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={2} md={2} sm={2}>
                                        <Typography variant="h5" >
                                            Rider Cost On Bill
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Divider className="mb-2 mt-2" my={2} />
                                {riderRateTestProduct.map((rider, index) => {
                                    return (<>
                                        <Grid container xs={12} sm={12} md={12} lg={12}>
                                            <Grid item lg={4} md={4} sm={4}>
                                                <Typography variant="body2" >
                                                    {rider.riderName}
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={2} md={2} sm={2}>
                                                <Typography variant="body2" >
                                                    {rider.riderType}
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={2} md={2} sm={2}>
                                                <Typography variant="body2" >
                                                    {rider.rateType}, {rider.rateBasis}
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={2} md={2} sm={2}>
                                                <Typography variant="body2" >
                                                    {rider.effectiveRate}
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={2} md={2} sm={2}>
                                                <Typography variant="body2" >
                                                    $ {rider.riderAmount}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Divider className="mb-2 mt-2" my={1} />
                                    </>)
                                })
                                }
                            </Grid>
                        </Grid>
                        <Divider className="mb-2 mt-2" my={4} />
                    </>
                }
                </DialogContent>
            </Dialog>
            {/* Property Sync Dialog */}
            <Dialog
                open={riderRatesPropSyncFormOpen}
                TransitionComponent={Transition}
                fullWidth={"md"}
                maxWidth={"md"}
                keepMounted
                onClose={handleRiderRatesPropSyncFormClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    Sync Properties Rider Rates with Current Property
                </DialogTitle>
                <DialogContent>
                    <Grid container xs={12} sm={12} md={12} lg={12} spacing={4} className={classes.rightSideHeaderSubMenuToolBar}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <FormControl fullWidth my={2}>
                                <InputLabel id="propertyToBillDropDownLabel">Target Property{"  "}
                                    {props.apl.isLoading &&
                                        <CircularProgress size={15} m={0} />
                                    }
                                </InputLabel>
                                {targetProperty == "" && props.apl.isLoading ?
                                    <Select
                                        labelId="propertyToBillDropDownLabel"
                                        id="propertyToBillDropDown"
                                        className={classes.selectedPropertyLocationLabel}
                                        onChange={handleSelectedPropertyChange}
                                        fullWidth
                                        disabled
                                        my={2}>
                                        ]
                                    </Select>
                                    :
                                    <Select
                                        labelId="propertyToBillDropDownLabel"
                                        id="propertyToBillDropDown"
                                        value={targetProperty}
                                        className={classes.selectedPropertyLocationLabel}
                                        onChange={handleSelectedPropertyChange}
                                        fullWidth
                                        my={2}>
                                        {props.apl.data.propertyPreviews.length > 0 ?
                                            [
                                                props.apl.data.propertyPreviews.map((item, key) => {
                                                    return (
                                                        <MenuItem value={item.uuid} id={key}>{item.name}</MenuItem>
                                                    )
                                                })
                                            ]
                                            :
                                            [
                                                <MenuItem value={-1}>No Properties Found</MenuItem>
                                            ]
                                        }
                                    </Select>
                                }
                            </FormControl>
                            <Divider className="mb-2 mt-2" my={4} />
                                <Button variant="contained" color="secondary" onClick={handleRiderRatesDialogAlertOpen}>Override All Rider Rates</Button>
                                <Dialog
                                    open={riderRatesDialogAlertOpen}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle id="alert-dialog-title">

                                    </DialogTitle>
                                    <DialogContent>
                                    <DialogContentText color="black">
                                        Are you sure you want to override all rider rates?
                                    </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                    <Button onClick={handleRiderRatesDialogAlertClose}>Close</Button>
                                    <Button  variant="contained" color="secondary"  onClick={() => {
                                            onSyncAllRiderRatesComplete();
                                     
                                    }} autoFocus>
                                        Yes
                                    </Button>
                                    </DialogActions>
                                </Dialog>
                        </Grid>
                        {targetProperty != "" &&
                            <Grid item xs={12} sm={12} md={12} lg={12} className={classes.rightSideHeaderSubMenuToolBar}>
                                <Button variant="contained" color="secondary" onClick={() => {
                                    props.syncRiderRatesBetweenProperties(props.properties.data.property.uuid, targetProperty);
                                    handleRiderRatesPropSyncFormClose();
                                }} my={0}>Override Target Property's Rider Rates</Button>
                                
                            </Grid>}
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                        </Grid>
                    </Grid>
                    <Divider className="mb-2 mt-2" my={4} />
                </DialogContent>
            </Dialog>
        </Card>
    );
}
// Component units
Table.propTypes = {
    // Store objects:
    user: PropTypes.object.isRequired,
    apl: PropTypes.object.isRequired,
    properties: PropTypes.object.isRequired,
    propertyUtilities: PropTypes.object.isRequired,
    updateLocalPropertyRiderRates: PropTypes.func.isRequired,
    updateRiderRate: PropTypes.func.isRequired,
    deleteRiderRate: PropTypes.func.isRequired,
    createRiderRate: PropTypes.func.isRequired,
    syncRiderRatesBetweenProperties: PropTypes.func.isRequired,
    syncRiderRatesForAllProperties: PropTypes.func.isRequired,
    getPropRiderRatesByPropByUuid: PropTypes.func.isRequired,
};

// Component State
function State(state) {
    return {
        user: state.user,
        apl: state.apl,
        properties: state.properties,
        propertyUtilities: state.propertyUtilities
    };
}
export default connect(State, { syncRiderRatesForAllProperties,syncRiderRatesBetweenProperties, updateLocalPropertyRiderRates, updateRiderRate, deleteRiderRate, createRiderRate, getPropRiderRatesByPropByUuid })(Table);
