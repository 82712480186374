import { GET_OWNERS, GET_OWNERS_RESPONSE, RESET, GET_OWNER, GET_OWNER_RESPONSE, SET_OWNERS, GET_OWNERS_STATEMENT_DATA_REQUEST, GET_OWNERS_STATEMENT_DATA_RESPONSE, GET_OWNERS_STATEMENT_DATA_FAIL, ADD_OWNERS_TO_LIST } from "./actions";

const initialState = {
  isLoading: false,
  error: null,
  data: {
    ownersList: {
      items: [],
    },
    ownersStatementDataList:{
      items: [],
    },
    bulkCheckSelectedOwners:{
      items: []
    },
    owner: null,
    searchQuery: null,
    range: { start: 0, end: 25 },
  },
};

// State
export default (state = initialState, action) => {
  var data = state.data;
  switch (action.type) {
    case RESET:
      return Object.assign({}, initialState);
    case GET_OWNERS:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case GET_OWNERS_RESPONSE:
      data.ownersList.items = action.owners;
      return {
        ...state,
        data: data,
        error: action.error,
        isLoading: false,
      };
    case SET_OWNERS:
      return {
        ...state,
        data: {
          ...state.data,
          ownersList: {
            items: action.owners,
          },
        },
      };
    case GET_OWNERS_STATEMENT_DATA_REQUEST:
      return {
        ...state,
        isLoading: action.isLoading
      }
    case GET_OWNERS_STATEMENT_DATA_RESPONSE:
      return {
        ...state,
        data: {
          ...state.data,
          ownersStatementDataList :{
            items: action.ownersWithStatementData
            
          },
        },
        error: action.error,
        isLoading: false,
      };
    case GET_OWNERS_STATEMENT_DATA_FAIL:
      return {
        ...state,
        error: action.error,
        isLoading: false
      }
    case GET_OWNER:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case GET_OWNER_RESPONSE:
      return {
        ...state,
        data: {
          ...state.data,
          owner: action.owner,
        },
        error: action.error,
        isLoading: false,
      };
    case ADD_OWNERS_TO_LIST:
      state.bulkCheckSelectedOwners.items.map((owner) => {
        for (var x = 0; x < action.owners.length; x++) {
          if (action.owners[x].id == owner.id) {
            action.owners.splice(x, 1);
          }
        }
      });
      let newbulkCheckSelectedOwners = {
        toDelete: [],
        toUpdate: [],
        items: state.bulkCheckSelectedOwners.items.concat(action.owners),
      };
      return {
        ...state,
        bulkCheckSelectedOwners: newbulkCheckSelectedOwners,
      };
    return {}
      default:
      return {
        ...state,
      };
  }
};
