import React, { useEffect } from "react";
import styled, { withTheme } from "styled-components/macro";
import { NavLink } from "react-router-dom";

import Helmet from "react-helmet";

//import "../../vendor/roundedBarCharts";
import { Bar } from "react-chartjs-2";

import { red, green, blue } from "@material-ui/core/colors";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";

import {
  CircularProgress as MuiCircularProgress,
  Paper,
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card,
  CardContent,
  Chip,
  Divider as MuiDivider,
  Grid,
  LinearProgress as MuiLinearProgress,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Switch,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
} from "@material-ui/core";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import TableContainer from "@material-ui/core/TableContainer";
// Redux Components
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import {
  updateSelectedAccount,
  updateCurrentAccountInDB,
  deleteForwardingAddress,
} from "../api/actions";
import ForwardingAddressForm from "./ForwardingAddressForm";
import { setSnackbar } from "../../snackbar/api/snackbar";
import AccountPaymentPlanTable from "./AccountPaymentPlanTable";
import PaymentForm from "../../payments/components/PaymentForm";
import AutoPaymentForm from "../../payments/components/AutoPaymentForm";
import {getAssociatedAutoPays} from "../../payments/api/actions";

import Skeleton from "@material-ui/lab/Skeleton";
import EditIcon from "@material-ui/icons/Edit";
import { useHistory } from "react-router-dom";
import styles from "./styling/accountManager";
import { dateFormatter, txtBoxDateFormatter } from "../../../setup/helpers";
import moment from 'moment';
import PledgeAccountForm from "./PledgeAccountForm";

const LinearProgress = MuiLinearProgress;
const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const today = moment();

const UserAndAccountControls = (props) => {

  const [PaymentPlanFormOpen, setPaymentPlanFormOpen] = React.useState(false);
  const [AutoPayFormOpen, setAutoPayFormOpen] = React.useState(false);
  const [ForwardAddressFormOpen, setForwardAddressFormOpen] = React.useState(false);
  const [pledgeAccountFormOpen, setPledgeAccountFormOpen] = React.useState(false);

  const dispatch = useDispatch();
  const PaymentPlanFormOpenClick = () => {
    setPaymentPlanFormOpen(true);
  };
  const PaymentPlanFormClose = () => {
    setPaymentPlanFormOpen(false);
  };

  const PledgeAccountFormOpenClick = () => {
    setPledgeAccountFormOpen(true);
  };
  const PledgeAccountFormCloseClick = () => {
    setPledgeAccountFormOpen(false);
  };

  const AutoPayFormOpenClick = () => {
    setAutoPayFormOpen(true);
  };
  const AutoPayFormCloseClick = () => {
    setAutoPayFormOpen(false);
  };

  const ForwardAddressFormOpenClick = () => {
    setForwardAddressFormOpen(true);
  };
  const ForwardAddressFormCloseClick = () => {
    setForwardAddressFormOpen(false);
  };
  const onAccountToggleChange = (event) => {
    props.account.data.account[event.target.name] = event.target.checked;
    if (event.target.name == "inCollections") {
      if (event.target.checked) {
        props.account.data.account.inCollectionsDate = today;
      }
      else {
        props.account.data.account.inCollectionsDate = null;
      }
    }
    // Form Openers
    else if (event.target.name == "paymentPlan") {
      if (event.target.checked) {
        PaymentPlanFormOpenClick();
      }
    }
    else if (event.target.name == "autoPayments") {
      if (event.target.checked) {
        AutoPayFormOpenClick();
      }
    }
    else if (event.target.name == "pledgeAccount") {
      if (event.target.checked) {
        PledgeAccountFormOpenClick();
      }
    }

    else if (event.target.name == "fowardingAddress") {
      if (event.target.checked) {
        props.account.data.forwardingAddress.push(props.account.newForwardingAddress);
        ForwardAddressFormOpenClick();
      }
      else {
        if (props.account.data.forwardingAddress && props.account.data.forwardingAddress.length > 0) {
          if (props.account.data.forwardingAddress[0].id) {
            props.deleteForwardingAddress(props.account.data.forwardingAddress[0]);
          }
          props.account.data.forwardingAddress = [];
        }
      }
    }
    
    props.updateSelectedAccount(props.account.data);
    setSnackbar(true, "warning", "Saving Account Detail Changes");
    props.updateCurrentAccountInDB(props.account.data.account).then(() => {
      dispatch(setSnackbar(true, "success", "Saving Successful"));
    });
  }

  const onAccountBillableStatusChange = (event) => {
    props.account.data.account[event.target.name] = event.target.value;
    setSnackbar(true, "warning", "Saving Account Detail Changes");
    props.updateCurrentAccountInDB({
      uuid: props.account.data.account.uuid,
      billableStatus: props.account.data.account.billableStatus,
    })
      .then(() => {
        dispatch(setSnackbar(true, "success", "Saving Successful"));
      }); // Sends account data to db
  };

  // Dont show account control panel if account cant be loaded
  if (!props.account.data.account) {
    return <></>;
  }
  return (
    <Card className="mb-6">
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Account Controls
        </Typography>
        <Divider className="mb-3 mt-1" />
        <Grid container item direction="column">
          <Grid
            container
            item
            direction="row"
            justify="flex-start"
            alignItems="center"
            xs={12}
          >
            <Grid item xs={3}>
              <Switch
                name="printedBills"
                checked={Boolean(props.account.data.account.printedBills)}
                onChange={onAccountToggleChange}
              />
            </Grid>
            <Grid item xs={9}>
              <Typography variant="caption">RECEIVES PRINTED BILLS</Typography>
            </Grid>
          </Grid>
          <Divider className="mb-1 mt-1" />
          <Grid
            container
            item
            direction="row"
            justify="flex-start"
            alignItems="center"
            xs={12}
          >
            <Grid item xs={3}>
              <Switch
                name="eBills"
                checked={Boolean(props.account.data.account.eBills)}
                onChange={onAccountToggleChange}
              />
            </Grid>
            <Grid item xs={9}>
              <Typography variant="caption">RECEIVES EBILLS</Typography>
            </Grid>
          </Grid>
          <Divider className="mb-1 mt-1" />
          <Grid
            container
            item
            direction="row"
            justify="flex-start"
            alignItems="center"
            xs={12}
          >
            <Grid item xs={3}>
              <Switch
                name="stopPayment"
                checked={Boolean(props.account.data.account.stopPayment)}
                onChange={onAccountToggleChange}
              />
            </Grid>
            <Grid item xs={9}>
              <Typography variant="caption">STOP PAYMENT</Typography>
            </Grid>
          </Grid>
          <Divider className="mb-1 mt-1" />
          <Grid
            container
            item
            direction="row"
            justify="flex-start"
            alignItems="center"
            xs={12}
          >
            <Grid item xs={3}>
              <Switch
                name="bankruptcy"
                checked={Boolean(props.account.data.account.bankruptcy)}
                onChange={onAccountToggleChange}
              />
            </Grid>
            <Grid item xs={9}>
              <Typography variant="caption">BANKRUPTCY</Typography>
            </Grid>
          </Grid>
          <Divider className="mb-1 mt-1" />
          <Grid
            container
            item
            direction="row"
            justify="flex-start"
            alignItems="center"
            xs={12}
          >
            <Grid item xs={3}>
              <Switch
                name="inCollections"
                checked={Boolean(props.account.data.account.inCollections)}
                onChange={onAccountToggleChange}
              />
            </Grid>
            <Grid item xs={9}>
              <Typography variant="caption" className="mr-4">IN COLLECTIONS </Typography> {(props.account.data.account.inCollectionsDate != null || props.account.data.account.inCollections == 1) && dateFormatter(props.account.data.account.inCollectionsDate || dateFormatter(today))}
            </Grid>
          </Grid>
          <Divider className="mb-1 mt-1" />
          <Grid
            container
            item
            direction="row"
            justify="flex-start"
            alignItems="center"
            xs={12}
          >
            <Grid item xs={3}>
              <Switch
                name="paymentPlan"
                checked={(!props.payments.isLoading && props.account.data.account.paymentPlan)}
                onChange={onAccountToggleChange}
              />
            </Grid>
            <Grid item xs={9}>
              <Typography variant="caption">PAYMENT PLAN</Typography>
              {(!props.payments.isLoading && props.account.data.account.paymentPlan) ?
              (
                <Button onClick={PaymentPlanFormOpenClick}>
                  <EditIcon
                    fontSize="medium"
                  />
                </Button>) : null
              }
            </Grid>
          </Grid>
          <Divider className="mb-1 mt-1" />

          <Grid
            container
            item
            direction="row"
            justify="flex-start"
            alignItems="center"
            xs={12}
          >
            <Grid item xs={3}>
              <Switch
                name="pledgeAccount"
                checked={Boolean(props.account.data.account.pledgeAccount)}
                onChange={onAccountToggleChange}
              />
            </Grid>
              <Grid item xs={9}>
                  <Typography variant="caption" className="mr-2">PLEDGE ACCOUNT</Typography>
                  {props.account.data.account.pledgeAccount == 1 &&
                      <>
                          <Button onClick={PledgeAccountFormOpenClick} className='mr-2'>
                              <EditIcon
                                  fontSize="medium"
                              />
                          </Button>
                          {props.account.data.account.pledgeSource}
                      </>
                  }
              </Grid>
          </Grid>
          <Divider className="mb-1 mt-1" />
          <Grid
            container
            item
            direction="row"
            justify="flex-start"
            alignItems="center"
            xs={12}
          >
            <Grid item xs={3}>
              <Switch
                name="autoPayments"
                checked={Boolean(props.account.data.account.autoPayments)}
                onChange={onAccountToggleChange}
              />
            </Grid>
            <Grid item xs={9}>
              <Typography variant="caption" className="mr-2">AUTO-PAYMENTS</Typography>
              {props.account.data.account.autoPayments == 1 &&
                <Button onClick={AutoPayFormOpenClick}>
                  <EditIcon
                    fontSize="medium"
                  />
                </Button>
              }
            </Grid>
          </Grid>
          <Divider className="mb-1 mt-1" />
          <Grid
            container
            item
            direction="row"
            justify="flex-start"
            alignItems="center"
            xs={12}
          >
            <Grid item xs={3}>
              <Switch
                name="fowardingAddress"
                checked={props.account.data.forwardingAddress.length > 0 ? true : false}
                onChange={onAccountToggleChange}
              />
            </Grid>
            <Grid item xs={9}>
              <Typography variant="caption" className="mr-2">FORWARDING ADDRESS</Typography>
              {props.account.data.forwardingAddress.length > 0 &&
                <Button onClick={ForwardAddressFormOpenClick}>
                  <EditIcon
                    fontSize="medium"
                  />
                </Button>
              }
            </Grid>
          </Grid>
          <Divider mb={3} />
          <Grid
              container
              item
              direction="row"
              justify="flex-start"
              alignItems="center"
              xs={12}
          >
            <Grid item xs={3}>
              <Switch
                  name="hold"
                  checked={props.meters?.data.metersList.items.some(meter => meter.hold == 1)}
              />
            </Grid>
            <Grid item xs={9}>
              <Typography variant="caption">HOLD / VACATION</Typography>
            </Grid>
          </Grid>
          <Divider className="mb-1 mt-1" />
          {(props.account.data.account.customerType == "Common Area" ||
            props.account.data.account.customerType == "Management Co") && (
              <Grid
                container
                item
                direction="row"
                justify="flex-start"
                alignItems="center"
                xs={12}
              >
                <FormControl fullWidth my={2}>
                  <InputLabel id="commonAccountTypeToggleLabel">
                    Common Account Billing Type
                  </InputLabel>
                  <Select
                    labelId="commonAccountTypeToggleLabel"
                    id="billableStatus"
                    name="billableStatus"
                    defaultValue={props.account.data.account.billableStatus}
                    onChange={onAccountBillableStatusChange}
                    fullWidth
                    my={2}
                  >{[
                    <MenuItem value={0}>Billable to PM</MenuItem>,
                    <MenuItem value={1}>Common Line Item</MenuItem>,
                    <MenuItem value={2}>Meter-less Common</MenuItem>,
                  ]}
                  </Select>
                </FormControl>
              </Grid>
            )}
        </Grid>
      </CardContent>
      <Dialog
        open={PaymentPlanFormOpen}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth={"xl"}
        keepMounted
        onClose={PaymentPlanFormClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <AccountPaymentPlanTable isAutoPayForm={true} />
        </DialogContent>
      </Dialog>
      <Dialog
        open={AutoPayFormOpen}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth={"xl"}
        keepMounted
        onClose={AutoPayFormCloseClick}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <AutoPaymentForm />
        </DialogContent>
      </Dialog>
      <Dialog
        open={pledgeAccountFormOpen}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth={"xl"}
        keepMounted
        onClose={PledgeAccountFormCloseClick}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <PledgeAccountForm/>
        </DialogContent>
      </Dialog>
      <Dialog
        open={ForwardAddressFormOpen}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth={"xl"}
        keepMounted
        onClose={ForwardAddressFormCloseClick}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <ForwardingAddressForm />
        </DialogContent>
      </Dialog>
    </Card>
  );
};

// Component Properties
UserAndAccountControls.propTypes = {
  // Store objects:
  account: PropTypes.object.isRequired,
  meters: PropTypes.object.isRequired,
  payments: PropTypes.object.isRequired,
  //userProfile: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  updateSelectedAccount: PropTypes.func.isRequired,
  updateCurrentAccountInDB: PropTypes.func.isRequired,
  deleteForwardingAddress: PropTypes.func.isRequired,
  getAssociatedAutoPays: PropTypes.func.isRequired,
};

// Component State
function UserAndAccountControlsState(state) {
  return {
    meters: state.meters,
    account: state.account,
    payments: state.payments,
    apl: state.apl,
  };
}
export default connect(UserAndAccountControlsState, {
  updateSelectedAccount,
  updateCurrentAccountInDB,
  deleteForwardingAddress,
  getAssociatedAutoPays,
})(UserAndAccountControls);
