import {
  Button as MuiButton,
  Card,
  CardContent,
  CardHeader as MuiCardHeader,
  CircularProgress as MuiCircularProgress,
  Divider as MuiDivider,
  Grid,
  LinearProgress as MuiLinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
//import { login, logout } from '../../modules/user/api/actions';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import TableContainer from "@material-ui/core/TableContainer";
import EditIcon from "@material-ui/icons/Edit";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import Skeleton from "@material-ui/lab/Skeleton";
import { spacing } from "@material-ui/system";
// Redux Components
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import StatusTag from "modules/apl/components/StatusTag";
import styled from "styled-components/macro";
import { dateFormatter, dateFormatterNoTime, formatPhoneNumber } from "../../../setup/helpers";
import AccountForm from "./AccountForm";
//import accountEmailPopup from "../../../modules/notification/components/accountEmailPopup";
import styles from "./styling/accountManager";

const LinearProgress = MuiLinearProgress;
const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);

const CardHeader = styled(MuiCardHeader)(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SelectedAccountInformationCard = (props) => {
  const history = useHistory();
  const useStyles = styles;
  const classes = useStyles();
  const [accountFormOpen, setAccountFormOpen] = React.useState(false);

  const handleAccountFormClickOpen = () => {
    setAccountFormOpen(true);
  };

  const handleAccountFormClose = () => {
    setAccountFormOpen(false);
  };

  return (
    <Card mb={6}>
      <CardHeader
        xs={12}
        sm={12}
        md={12}
        lg={12}
        title="Account Info"
        action={
          <>
            <Button onClick={handleAccountFormClickOpen}>
              <EditIcon
                fontSize="medium"
                className={classes.SubMenuToolBarIcon}
              />
            </Button>
            <Dialog
              open={accountFormOpen}
              TransitionComponent={Transition}
              fullWidth={"lg"}
              maxWidth={"lg"}
              keepMounted
              onClose={handleAccountFormClose}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              {props.account.data.account ? (
                <DialogTitle id="alert-dialog-slide-title">
                  Account #{props.account.data.account.accountNum}{" "}
                </DialogTitle>
              ) : (
                <DialogTitle id="alert-dialog-slide-title">
                  LOADING ACCOUNT.....{" "}
                </DialogTitle>
              )}
              <DialogContent>
                <AccountForm
                  onUpdate={handleAccountFormClose}
                  onCancel={handleAccountFormClose}
                />
              </DialogContent>
            </Dialog>
          </>
        }
      />
      <CardContent xs={12} sm={12} md={12} lg={12}>
        {/*
                <Grid container xs={12} sm={12} md={12} lg={12}>
                    <Grid item xs={9} sm={9} md={9} lg={9}>
                        <Typography variant="h6" gutterBottom>
                            Account Info
                        </Typography>
                    </Grid>
                    <Grid item xs={3} sm={3} md={3} lg={3} className={classes.rightSideHeaderSubMenuToolBar}>
                        
                    </Grid>
                </Grid>
                */}
        <Divider className=" mb-2" />
        {props.account.isLoading ? (
          <>
            <Skeleton
              variant="rect"
              height="40px"
              style={{
                backgroundColor: `#bdbfbe`,
                padding: 6,
                margin: 6,
                marginBottom: 3,
              }}
            />
            <Skeleton
              variant="rect"
              height="40px"
              style={{
                backgroundColor: `#bdbfbe`,
                padding: 6,
                margin: 6,
                marginBottom: 3,
              }}
            />
            <Skeleton
              variant="rect"
              height="40px"
              style={{
                backgroundColor: `#bdbfbe`,
                padding: 6,
                margin: 6,
                marginBottom: 3,
              }}
            />
            <Skeleton
              variant="rect"
              height="40px"
              style={{
                backgroundColor: `#bdbfbe`,
                padding: 6,
                margin: 6,
                marginBottom: 3,
              }}
            />
            <Skeleton
              variant="rect"
              height="40px"
              style={{
                backgroundColor: `#bdbfbe`,
                padding: 6,
                margin: 6,
                marginBottom: 3,
              }}
            />
          </>
        ) : (
          <Grid
            container
            spacing={2}
            lg={12}
            className={classes.AccountInfoGrid}
          >
            <Grid item xs={12} lg={12}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableBody>
                    {props.account.data.account.email && props.account.data.account.email.includes("@") ?
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography variant="body1">Account Email</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {props.account.data.account.email || ""}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Button onClick={() => { window.open(`mailto:${props.account.data.account.email}?subject=CCOS%20Account%20Notice%20-%20${props.account.data.account.accountNum}&body=`) }}>
                            <ContactMailIcon
                              fontSize="small"
                              className={classes.SubMenuToolBarIcon}
                            />
                          </Button>
                        </TableCell>
                      </TableRow>
                      :
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography variant="body1">Account Email</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {props.account.data.account.email || ""}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    }
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1">Billing Address</Typography>
                      </TableCell>
                      {props.account.data.addresses.length > 0 ? (
                        <TableCell>
                          <Typography variant="body2">
                            {props.account.data.addresses[0].address1 != "" &&
                              props.account.data.addresses[0].address1 + ","}
                          </Typography>
                          <Typography variant="body2">
                            {props.account.data.addresses[0].address2 != "" &&
                              props.account.data.addresses[0].address2 != null &&
                              props.account.data.addresses[0].address2 + ","}
                          </Typography>
                          <Typography variant="body2">
                            {props.account.data.addresses[0].city != "" &&
                              props.account.data.addresses[0].city + ","}
                          </Typography>
                          <Typography variant="body2">
                            {props.account.data.addresses[0].state != "" &&
                              props.account.data.addresses[0].state + ", "}
                            {props.account.data.addresses[0].zip != "" &&
                              props.account.data.addresses[0].zip}
                          </Typography>
                        </TableCell>
                      ) : (
                        <TableCell>
                          <Typography variant="body2">
                            No Billing Address Found
                          </Typography>
                        </TableCell>
                      )}
                    </TableRow>
                    {/*
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                <Typography variant="body1">
                                                    SSN
                                                </Typography>
                                            </TableCell>
                                            <TableCell >
                                                <Typography variant="body2">
                                                    {props.account.data.account.last4SSN || ""}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        */}
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1">Phone #</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {formatPhoneNumber(props.account.data.account.phone1) || ""}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {/*
                      <TableRow>
                          <TableCell component="th" scope="row">
                              <Typography variant="body1">
                                  Phone # 2
                              </Typography>
                          </TableCell>
                          <TableCell >
                              <Typography variant="body2">
                                  {props.account.data.account.phone2 || ""}
                              </Typography>
                          </TableCell>
                      </TableRow>
                      */}
                    {/*
                      <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1">Customer Move In Date</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {props.account.data.account.moveInDate
                            ? dateFormatter(
                              props.account.data.account.moveInDate
                            )
                            : ""}
                        </Typography>
                      </TableCell>
                    </TableRow>*/}

                    {(props.account.data.account.moveInDateOverride != null || (props.account.data.move.moveIn.length > 0)) ?
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography variant="body1">Move In Date</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {props.account.data.account.moveInDateOverride != null ?
                              dateFormatter(props.account.data.account.moveInDateOverride)
                              :
                              <>
                                {props.account.data.move.moveIn[0].moveInDate != null ?
                                  dateFormatterNoTime(props.account.data.move.moveIn[0].moveInDate)
                                  :
                                  "Current Occupant"
                                }
                              </>
                            }
                          </Typography>
                        </TableCell>
                      </TableRow>
                      :
                      <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1">Move In Date (Created)</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {props.account.data.account.moveInDateOverride != null ?
                            dateFormatter(props.account.data.account.moveInDateOverride)
                            :
                            <>
                              {props.account.data.account.created != null ?
                                `${dateFormatter(props.account.data.account.created)}`
                                :
                                "Current Occupant"
                              }
                            </>
                          }
                        </Typography>
                      </TableCell>
                    </TableRow>
                    }
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1">Move Out Date</Typography>
                      </TableCell>
                      <TableCell>
                      {(Object.keys(props.account.data.move.moveOut).length > 0 && props.account.data.move.moveOut.processed == 0 ) &&<StatusTag type={"Warning"} label={`Pending`} condensed={true} />}
                        <Typography style={{whiteSpace: 'pre-line'}} variant="body2">
                          {props.account.data.move.moveOut.moveOutDate
                            ? (props.account.data.account.accountStatusId == 1 && props.account.data.account.customerTypeId == 4) ? 
                            `Current Occupant
                            (Last Move Out Date: ${dateFormatterNoTime(props.account.data.move.moveOut.moveOutDate)})` :  
                            dateFormatterNoTime(
                              props.account.data.move.moveOut.moveOutDate
                            )
                            : "Current Occupant"}          
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {/* 
                    Removed at Allie's request
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1">Lease End Date</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {dateFormatter(
                            props.account.data.account.leaseEndDate
                          ) || ""}
                        </Typography>
                      </TableCell>
                    </TableRow> */}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

// Component Properties
SelectedAccountInformationCard.propTypes = {
  // Store objects:
  account: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
};

// Component State
function SelectedAccountInformationCardState(state) {
  return {
    account: state.account,
    apl: state.apl,
  };
}
export default connect(
  SelectedAccountInformationCardState,
  {}
)(SelectedAccountInformationCard);
