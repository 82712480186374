// Imports
import axios from "axios";
import { setSnackbar } from "../../snackbar/api/snackbar";
import { GET, POST, PUT, DEL } from "../../genericAPI/api/actions"
require("../../../setup/config/apiConfig");

export const RESET = "RESET";
export const SET_UPLOAD_FILES = "SET_UPLOAD_FILES";

export const GET_ACCOUNT_FILES_REQUEST = "GET_ACCOUNT_FILES_REQUEST";
export const GET_ACCOUNT_FILES_RESPONSE = "GET_ACCOUNT_FILES_RESPONSE";
export const GET_ACCOUNT_FILES_FAILURE = "GET_ACCOUNT_FILES_FAILURE";


export const DELETE_ACCOUNT_FILES_REQUEST = "DELETE_ACCOUNT_FILES_REQUEST";
export const DELETE_ACCOUNT_FILES_RESPONSE = "DELETE_ACCOUNT_FILES_RESPONSE";
export const DELETE_ACCOUNT_FILES_FAILURE = "DELETE_ACCOUNT_FILES_FAILURE";

export const UPLOAD_ACCOUNT_FILE_REQUEST = "UPLOAD_ACCOUNT_FILE_REQUEST";
export const UPLOAD_ACCOUNT_FILE_RESPONSE = "UPLOAD_ACCOUNT_FILE_RESPONSE";
export const UPLOAD_ACCOUNT_FILE_FAILURE = "UPLOAD_ACCOUNT_FILE_FAILURE";

export const UPDATE_ACCOUNT_FILES_LOCAL_STATE = "UPDATE_ACCOUNT_FILES_LOCAL_STATE";

export function setUploadFiles(files) {
  return { type: SET_UPLOAD_FILES, files: files };
}
export function updateAccountFilesState(files) {
  return { type: UPDATE_ACCOUNT_FILES_LOCAL_STATE, files: files };
}
export function getAllAccountFiles(accountId, isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: GET_ACCOUNT_FILES_REQUEST,
      isLoading: isLoading,
    });
    return axios.get(`/accountfile/account/${accountId}`,).then((response) => {
      dispatch({
        type: GET_ACCOUNT_FILES_RESPONSE,
        filesFound: response.data,
      });
    }).catch((error) => {
      dispatch({
        type: GET_ACCOUNT_FILES_FAILURE,
        isLoading: false,
        error: error,
      });
    });
  };
}


export function uploadFiles(file, isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: UPLOAD_ACCOUNT_FILE_REQUEST,
      isLoading: isLoading,
    });
    dispatch(
      setSnackbar(true, "warning", "Uploading Account Files...")
    );
     axios.post(`/accountfile/`, file).then((response) => {

      const formData = new FormData();
      formData.append("file", file.file);
      console.log(file)
      axios({
        method:'put',
        url: response.data.upload,
        baseURL: '',
        data: file.file,
        headers: { "Content-Type": file.file.type}
       })
       .then(response => {
        dispatch(
          setSnackbar(true, "success", "File Uploaded")
        );
        dispatch({
          type: UPLOAD_ACCOUNT_FILE_RESPONSE,
          response: response.data,
        });
       })
       .catch(error => {
           console.log(error);
       });

    }).catch((error) => {
      dispatch({
        type: UPLOAD_ACCOUNT_FILE_FAILURE,
        isLoading: false,
        error: error,
      });
    });
  }
}


export function deleteAccountFile(uuid) {
  return DEL("accountfile", uuid, DELETE_ACCOUNT_FILES_REQUEST, DELETE_ACCOUNT_FILES_RESPONSE, DELETE_ACCOUNT_FILES_FAILURE);
}
