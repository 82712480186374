import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React from "react";
// utils
import organizePayments from "../utils/organizePayments";

export default ({ paymentsInfo, organizeType }) => {
  const payments = organizePayments(organizeType, paymentsInfo);
  const fieldNames =
    payments && payments.length > 0 ? Object.keys(payments[0]) : [];

  return (
    <div>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            {fieldNames.map((name, index) => {
              return <TableCell key={index}>{name}</TableCell>;
            })}
          </TableRow>
        </TableHead>

        <TableBody>
          {payments.map((payment, index) => {
            return (
              <TableRow key={index}>
                {fieldNames.map((name, ind) => {
                  const data =
                    name == "AmountPosted"
                      ? `$${payment[name].toFixed(2)}`
                      : payment[name];
                  return <TableCell key={ind}>{data}</TableCell>;
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};
