import {
    Button as MuiButton,
    Card,
    CardContent,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select as MuiSelect,
    TextField as MuiTextField,
    Typography,
    List, ListItem,
    Fab
  } from "@material-ui/core";

  import { makeStyles } from "@material-ui/core/styles";
  import { spacing } from "@material-ui/system";
import AccountQuickSearchBoxDataPull from "modules/accountsSearch/components/AccountQuickSearchBoxDataPull";
  import PropTypes from "prop-types";
  import React, { useEffect, useState } from "react";
  import { connect, useDispatch } from "react-redux";
  import { createAccountUserLink, getAccountsByUser } from "../../userProfiles/api/actions"
  import styled from "styled-components/macro";
import { setSnackbar } from "modules/snackbar/api/snackbar";
  
  const TextField = styled(MuiTextField)(spacing);
  const Select = styled(MuiSelect)(spacing);
  
  const AccountUserForm = (props) => {
  
  
    const useStyles = makeStyles((theme) => ({
      container: {
        display: "flex",
        flexWrap: "wrap",
      },
      loadingIcon: {
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      },
    }));
  
    const [selectedAccount, setSelectedAccount] = useState("");
  
    const dispatch = useDispatch();
    const classes = useStyles();
  
    const save = async ()  => {
        try {
            let link = await props.createAccountUserLink(props.userProfiles.selectedUserProfile.uuid, props.accountsSearch.selectedAccount.uuid);
            if(link.status == 200) {
                dispatch(setSnackbar(true, "success", "Account linked."))
                await props.getAccountsByUser(props.userProfiles.selectedUserProfile.uuid);
            }
            else {
                dispatch(setSnackbar(true, "error", "Failed to create link."))
            }
            props.handleClose();
        } catch(e)
        {
            dispatch(setSnackbar(true, "error", "Failed to create link."))

        }

    };
  
    return (
      <Card>
        <CardContent>
          {/* Meter Details */}
          <Grid container spacing={2}>
            <Grid container item spacing={2}>
              <Grid item md={12} xs={12}>
                <AccountQuickSearchBoxDataPull />
              </Grid>
            </Grid>
            <Grid container item spacing={2}>
              <Grid container spacing={4} className="mt-4">
                <Grid
                  item
                  xs={12}
                  className="d-flex justify-content-center align-items-center">
                </Grid>
              </Grid>
            </Grid>
            <Grid container item spacing={2} justify={"flex-end"}>
              <Grid item>
                <MuiButton variant="contained" onClick={save} color="secondary">
                  Save
                </MuiButton>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  };
  
  AccountUserForm.propTypes = {
    user: PropTypes.object.isRequired,
    apl: PropTypes.object.isRequired,
    accountsSearch: PropTypes.object.isRequired,
    userProfiles: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    createAccountUserLink: PropTypes.func.isRequired,
    getAccountsByUser: PropTypes.func.isRequired
  };
  
  function AccountUserFormState(state) {
    return {
      user: state.user,
      apl: state.apl,
      account: state.account,
      userProfiles: state.userProfiles,
      accountsSearch: state.accountsSearch
    };
  }
  
  export default connect(AccountUserFormState, { createAccountUserLink, getAccountsByUser })(AccountUserForm);
  