import axios from "axios";
import { GET, POST, PUT, DEL } from "../../genericAPI/api/actions";
import _ from "lodash";
import { setSnackbar } from "../../../modules/snackbar/api/snackbar";


require("../../../setup/config/apiConfig");;

export const FETCHED_MOVES = "FETCHED_MOVES";
export const SET_LOADING = "SET_LOADING";
export const HANDLE_ERROR = "HANDLE_ERROR";

export const DELETE_MOVE_IN = "DELETE_MOVE_IN";
export const DELETE_MOVE_OUT = "DELETE_MOVE_OUT";

export const UPDATED_MOVE_INS = "UPDATED_MOVE_INS";
export const UPDATED_MOVE_OUTS = "UPDATED_MOVE_OUTS";

export const EDITED_MOVE_IN = "EDITED_MOVE_IN";
export const EDITED_MOVE_OUT = "EDITED_MOVE_OUT";

export const PROCESSED_MOVE_INS = "PROCESSED_MOVE_INS";
export const PROCESSED_MOVE_OUTS = "PROCESSED_MOVE_OUTS";

export const CREATED_MOVE_IN = "CREATED_MOVE_IN";

export const SET_TAB_STATE = "SET_TAB_STATE";


export const CREATE_MOVE_IN_REQUEST = "CREATE_MOVE_IN_REQUEST";
export const CREATE_MOVE_IN_RESPONSE = "CREATE_MOVE_IN_RESPONSE";
export const CREATE_MOVE_IN_FAILURE = "CREATE_MOVE_IN_FAILURE"

export const UPDATE_MOVE_IN_REQUEST = "UPDATE_MOVE_IN_REQUEST";
export const UPDATE_MOVE_IN_RESPONSE = "UPDATE_MOVE_IN_RESPONSE";
export const UPDATE_MOVE_OUT_REQUEST = "UPDATE_MOVE_OUT_REQUEST";
export const UPDATE_MOVE_OUT_RESPONSE = "UPDATE_MOVE_OUT_RESPONSE";
export const UPDATE_MOVE_FAILED = "UPDATE_MOVE_FAILED";


export const START_BULK_MOVES_LOADING = "START_BULK_MOVES_LOADING";

export const GET_MOVES_REQUEST = "GET_MOVES_REQUEST";
export const GET_MOVES_FAILED = "GET_MOVES_FAILED";
export const GET_MOVE_OUTS_RESPONSE = "GET_MOVE_OUTS_RESPONSE";
export const GET_MOVE_INS_RESPONSE = "GET_MOVE_INS_RESPONSE";

export function setTabState(tabName) {
  return { type: SET_TAB_STATE, tabName };
}

export function fetchedMoves(moves) {
  return { type: FETCHED_MOVES, moves };
}

export function handleError(error) {
  return { type: HANDLE_ERROR, error };
}

export function deleteMoveIn(uuid) {
  return { type: DELETE_MOVE_IN, uuid };
}

export function deleteMoveOut(uuid) {
  return { type: DELETE_MOVE_OUT, uuid };
}

export function updatedMoveIns(uuids, keyValPair) {
  return { type: UPDATED_MOVE_INS, uuids, keyValPair };
}

export function updatedMoveOuts(uuids, keyValPair) {
  return { type: UPDATED_MOVE_OUTS, uuids, keyValPair };
}

export function startBulkMovesLoading(itemsCount) {
  return { type: START_BULK_MOVES_LOADING, itemsCount };
}

export function editedMoveIn(newObj) {
  return { type: EDITED_MOVE_IN, newObj };
}

export function editedMoveOut(newObj) {
  return { type: EDITED_MOVE_OUT, newObj };
}

export function processedMoveIns(uuids) {
  return { type: PROCESSED_MOVE_INS, uuids };
}

export function processedMoveOuts(uuids) {
  return { type: PROCESSED_MOVE_OUTS, uuids };
}

export function createdNewMoveIn(moveIn) {
  return { type: CREATED_MOVE_IN, moveIn };
}

// Actions
export function fetchMoves(monthsOut) {
  if (!monthsOut || monthsOut == null || monthsOut == undefined) monthsOut = 1;

  return (dispatch) => {
    dispatch({
      type: SET_LOADING,
      isLoading: true,
    });

    return axios
      .get(`/customer-moves-outs-unprocessed?monthsOut=.1`)
      .then((moveOuts) => {
        axios
          .get(`/customer-moves-ins-unprocessed?monthsOut=.1`)
          .then((moveIns) => {
            let moves = { ...moveIns.data, ...moveOuts.data }
            dispatch(fetchedMoves(moves));
          });
      })
      .catch((error) => {
        dispatch({
          type: HANDLE_ERROR,
          error,
        });

        //throw new Error(error);
      });
  };
}


export function getMoveOutsInDateRange(range, page, pageLimit) {

  return GET(`customer-moves-outs-unprocessed?monthsOut=3&page=${page || 0}&pageLimit=${pageLimit || 1000}`, "", GET_MOVES_REQUEST, GET_MOVE_OUTS_RESPONSE, GET_MOVES_FAILED);
} 
export function getMoveInsInDateRange(range, page, pageLimit) {
  return GET(`customer-moves-ins-unprocessed?monthsOut=3&page=${page|| 0}&pageLimit=${pageLimit|| 1000}`, "", GET_MOVES_REQUEST, GET_MOVE_INS_RESPONSE, GET_MOVES_FAILED);
}

export function deleteMove(uuid, type) {
  return (dispatch) => {
    dispatch({
      type: SET_LOADING,
      isLoading: true,
    });

    const url =
      type == "movein" ? `/customermovein/${uuid}` : `/customermoveout/${uuid}`;

    return axios
      .delete(url)
      .then((response) => {
        let action = type == "movein" ? deleteMoveIn : deleteMoveOut;
        dispatch(action(uuid));
      })
      .catch((error) => {
        dispatch({
          type: HANDLE_ERROR,
          error,
        });

        //throw new Error(error);
      });
  };
}


export function existingMoveInUpdate(moveIn) {
  return PUT("customermovein", moveIn.uuid, moveIn, UPDATE_MOVE_IN_REQUEST, UPDATE_MOVE_IN_RESPONSE, UPDATE_MOVE_FAILED);
}

export function existingMoveOutUpdate(moveOut) {
  return PUT("customermoveout", moveOut.uuid, moveOut, UPDATE_MOVE_OUT_REQUEST, UPDATE_MOVE_OUT_RESPONSE, UPDATE_MOVE_FAILED);
}

export function updateBatchMoves(uuids, type, keyValPair) {
  return (dispatch) => {
    dispatch({
      type: SET_LOADING,
      isLoading: true,
    });

    const url =
      type == "movein"
        ? "/update-batch-customermoveins"
        : "/update-batch-customermoveouts";
    const keyName = type == "movein" ? "moveIns" : "moveOuts";

    const fieldToUpdate = Object.keys(keyValPair)[0];
    const value = keyValPair[fieldToUpdate];

    const moveObjs = uuids.map((uuid) => {
      return {
        uuid,
        [fieldToUpdate]: value,
      };
    });

    return axios
      .put(url, { [keyName]: moveObjs })
      .then((response) => {
        let action = type == "movein" ? updatedMoveIns : updatedMoveOuts;

        if (fieldToUpdate == "valid") {
          uuids.forEach((uuid) => {
            let deleteMove = type == "movein" ? deleteMoveIn : deleteMoveOut;
            dispatch(deleteMove(uuid));
          });
        } else dispatch(action(uuids, keyValPair));
      })
      .catch((error) => {
        dispatch({
          type: HANDLE_ERROR,
          error,
        });
      });
  };
}

export function editMove(originalObj, editObj, type, isLoading = true) {
  if (Array.isArray(originalObj)) {
    originalObj.map((obj, index) => {
      delete obj.isRemoteFlagLabel;
      delete obj.movesToolbar;
      delete obj.statusTags;
      delete obj.accountNumDisplay;
      delete obj.currentCreateBill;
    });
  }
  else {
    delete originalObj.isRemoteFlagLabel;
    delete originalObj.movesToolbar;
    delete originalObj.statusTags;
    delete originalObj.accountNumDisplay;
    delete originalObj.currentCreateBill;
  }
  return (dispatch) => {
    dispatch(setSnackbar(true, "warning", "Updating move."))
    dispatch({
      type: SET_LOADING,
      isLoading: isLoading,
    });
    const url = type == "movein" ? `/edit-customermovein` : `/edit-customermoveout`;
    return axios
      .put(url, { originalObj, editObj })
      .then((response) => {
        var updatedMoves = response.data.updatedMoves;

        // pass newly modified objects to respoective state method
        if (type == "movein") {
          dispatch(editedMoveIn(updatedMoves));
          dispatch(setSnackbar(true, "success", "Successfully updated move."));
        }
        else {
          dispatch(editedMoveOut(updatedMoves));
          //dispatch(editedMoveOut(originalObj));
        }
      })
      .catch((error) => {
        // dispatch(setSnackbar(true, "error", "Failed to edit move."));
        dispatch({
          type: HANDLE_ERROR,
          error,
        });

        throw new Error(error);
      });
  };
}

export function processMoves(movesToProcess, type) {
  return (dispatch) => {
    dispatch({
      type: SET_LOADING,
      isLoading: true,
    });

    const url =
      type == "movein"
        ? "/admin/accountcreation"
        : "/customermoveout/batch/process";
    const keyName = type == "movein" ? "moveIns" : "moveOuts";

    //the movesToProcess did not seem to be unique
    movesToProcess = _.uniqBy(movesToProcess, 'uuid')
   //console.log(movesToProcess)
    return axios
      .post(url, { [keyName]: movesToProcess })
      .then((response) => {
        if(response.status == 200) {
          let action = type == "movein" ? processedMoveIns : processedMoveOuts;

          //dispatch(action(response.data.processedCustomers));
          return response;
        }

      })
      .catch((error) => {

        dispatch({
          type: HANDLE_ERROR,
          error,
        });
        return {error: true};

        //throw new Error(error);
      });
  };
}

export function createNewMoveIn(moveIn) {
  return (dispatch) => {
    dispatch({
      type: SET_LOADING,
      isLoading: true,
    });

    moveIn.source = "Internal";

    return axios
      .post("/customermovein", moveIn)
      .then((response) => {
        dispatch(createdNewMoveIn(response.data.createdMoveIn));
        if(response.status == 200){
          dispatch(
            setSnackbar(true, "success", "Successfully created new move in.")
          );
        }
        
      })
      .catch((error) => {
        dispatch(setSnackbar(true, "error", "Failed to create new move in."));
        dispatch({
          type: HANDLE_ERROR,
          error,
        });

        //throw new Error(error);
      });
  };
}

export function moveOutCustomer(moveOut, account) {
  console.log(moveOut, account)


  return POST("customermoveout", "", {     
    moveOutDate: moveOut.moveOutDate,
    accountId: account.account.id,
    finalBill: 1,
    fwdAddress1: moveOut.fwdAddress1,
    fwdAddress2: moveOut.fwdAddress2,
    fwdCity: moveOut.fwdCity,
    fwdState: moveOut.fwdState,
    fwdZip: moveOut.fwdZip
  }, 
    CREATE_MOVE_IN_REQUEST, CREATE_MOVE_IN_RESPONSE, CREATE_MOVE_IN_FAILURE);
}


export function createNewMoveOut(forwardingAddress) {
  return POST("accountaddress", "", forwardingAddress, CREATE_MOVE_IN_REQUEST, CREATE_MOVE_IN_RESPONSE, CREATE_MOVE_IN_FAILURE
  );
}