import {
  Card as MuiCard,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Slide,
  TextField as MuiTextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import { spacing } from "@material-ui/system";
// Redux Components
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import { EditableTable } from "../../../components/EditableTable";
import { get as getaging } from "../api/actions";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Table(props) {
  const handleSearchTargetChangeDate = (event) => {
    props.accounting.data.searchQuery.dateAR = event.target.value;
    let params = [props.accounting.data.searchQuery.dateAR];
    props.getaging(
      props.accounting.data.searchQuery,
      true,
      "",
      "GetARReport",
      params,
      "arList"
    );
  };

  const CurrentLookupTypeButton = () => {
    return (
      <IconButton>
        <SearchIcon mr={2} />
      </IconButton>
    );
  };

  const SearchBar = () => {
    const classes = useStyles();
    return (
      <Grid container lg={12} spacing={1}>
        <Grid item lg={8} md={8} sm={8}>
          <TextField
            id={"searchTargetARDate"}
            label="As of Date"
            type={"date"}
            onChange={handleSearchTargetChangeDate}
            autoFocus={"autofocus"}
            name={"searchTarget"}
            value={props.accounting.data.searchQuery.dateAR}
            fullWidth
            my={2}
            size={"small"}
            variant={"outlined"}
            InputLabelProps={{
              shrink: true,
            }}
            helperText="Receipts report as of date"
          ></TextField>
        </Grid>
      </Grid>
    );
  };

  // Table Stuff:
  const fieldTitles = [
    "PropertyCode",
    "PropertyName",
    "AccountNum",
    "TenantName",
    "Address1",
    "Address2",
    "OwnerCode",
    "OwnerName",
    "AmountPosted",
    "GLCode",
    "DatePosted",
    "PaymentMethod",
  ];
  const fieldTypes = [
    "label",
    "label",
    "label",
    "label",
    "label",
    "label",
    "label",
    "label",
    "label",
    "label",
    "label",
    "label",
  ];
  const fieldNames = [
    "PropertyCode",
    "PropertyName",
    "AccountNum",
    "TenantName",
    "Address1",
    "Address2",
    "OwnerCode",
    "OwnerName",
    "AmountPosted",
    "GLCode",
    "DatePosted",
    "PaymentMethod",
  ];

  const onSelected = (item) => {};

  const onUpdate = () => {
    hide();
  };

  const [display, setDisplay] = React.useState(false);

  const show = () => {
    props.accounting.data.agingList = {}; // TODO: clear property on popup show
    setDisplay(true);
  };

  const hide = () => {
    setDisplay(false);
  };

  const NewDialog = () => {
    return (
      <Dialog
        open={display}
        TransitionComponent={Transition}
        fullWidth={"lg"}
        maxWidth={"lg"}
        keepMounted
        onClose={hide}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">New Aging</DialogTitle>
        <DialogContent></DialogContent>
      </Dialog>
    );
  };

  return (
    <Card mb={6} elevation={3}>
      <CardContent>
        <NewDialog />
        <SearchBar />
        <EditableTable
          readOnly={false}
          isLoading={props.accounting.isLoading}
          handleRowClickParentMethod={onSelected}
          tableTitle={"Accounts Receiveable"}
          canAddItems={false}
          canSave={false}
          canExport={true}
          objectArray={props.accounting.data.arList}
          fieldTitles={fieldTitles}
          fieldTypes={fieldTypes}
          fieldNames={fieldNames}
        />
      </CardContent>
    </Card>
  );
}

Table.propTypes = {
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  accounting: PropTypes.object.isRequired,
  getaging: PropTypes.func.isRequired,
};

function State(state) {
  return {
    user: state.user,
    apl: state.apl,
    accounting: state.accounting,
    search: state.search,
  };
}

export default connect(State, { getaging })(Table);
