import axios from "axios";

export const FETCH_LOCKBOX_UPLOADS = "FETCH_LOCKBOX_UPLOADS";
export const SET_LOADING = "SET_LOADING";
export const HANDLE_ERROR = "HANDLE_ERROR";
export const RECEIVED_READINGS_SUBMISSION = "RECEIVED_READINGS_SUBMISSION";

export function fetchedLockboxUploads(previousLockboxUploads) {
  return { type: FETCH_LOCKBOX_UPLOADS, previousLockboxUploads };
}

export function receivedReadingsSubmission(previewUploadedLockboxs) {
  return { type: RECEIVED_READINGS_SUBMISSION, previewUploadedLockboxs };
}

export function handleError(error) {
  return { type: HANDLE_ERROR, error };
}

// Actions
export function handleLockboxsUploadSubmission(
  fileData,
  accountNums
) {
  return (dispatch) => {
    dispatch({
      type: SET_LOADING,
      isLoading: true,
    });

    return axios
      .post("/handle-lockbox-upload", {
        data: fileData,
        accountNums: accountNums
      })
      .then((response) => {
        if (response.data.error == true) {
        dispatch({
          type: HANDLE_ERROR,
          error: response.data,
        });
        return response.data; 
      }
        else {
          
          dispatch(receivedReadingsSubmission(response.data));
          return response.data;
        }
      })
      .catch((error) => {
        dispatch({
          type: HANDLE_ERROR,
          error: error,
        });
      });
  };
}

export function fetchPreviousLockboxUploads() {
  return (dispatch) => {
    return axios
      .get("/lockbox-upload")
      .then((response) => {
        dispatch(fetchedLockboxUploads(response.data));
      })
      .catch((error) => {
        dispatch({
          type: HANDLE_ERROR,
          error: error,
        });

        throw new Error(error);
      });
  };
}
