import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField as MuiTextField,
  Card as MuiCard,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  ButtonGroup,
  Button,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress as MuiCircularProgress,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  Loop as LoopIcon,
  PowerOffSharp,
  Search as SearchIcon,
} from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import { EditableTable } from "../../../components/EditableTable";
import { setSnackbar } from "../../snackbar/api/snackbar";
import StatusTag from "../../apl/components/StatusTag";
import {
  newDropDownField,
  newFieldLinkUrl,
  newFieldPopupModel,
  newOnFieldChangeMethod,
} from "../../../components/EditableTable";
// Redux Components
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import { get as getAllPropManagers } from "../api/actions";
import { get as getAllDevelopers } from "../../developer/api/actions";
import PropertyManagerForm from "./NewForm";

const CircularProgress = styled(MuiCircularProgress)(spacing);
const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function PropertyManagerSearchTable(props) {
  const dispatch = useDispatch();

  const CurrentLookupTypeButton = () => {
    return (
      <IconButton>
        <SearchIcon mr={2} />
      </IconButton>
    );
  };


  const SearchBar = () => {
    return (
      <TextField
        id={"searchTarget"}
        label="Search Property Managers"
        type={"text"}
        autoFocus={true}
        placeholder={"name, number, email...."}
        onChange={handleSearchQueryInput}
        name={"searchTarget"}
        defaultValue={searchQuery}
        fullWidth
        my={2}
        size={"small"}
        variant={"outlined"}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{ endAdornment: <CurrentLookupTypeButton /> }}
      ></TextField>
    );
  };

  // Table Stuff:
  // Account Holder 	Address 	Meter Info 	Letter Sent Dates 	Disconnect Date 	Last Paid 	Disconnect Amt 	Account Balance
  const fieldTitles = [
    "Account Holder",
    "Status",
    "Address",
    "Phone #",
    "Billing Email",
  ];
  const fieldTypes = [
    "label",
    "label",
    "label",
    "phoneLabel",
    "label"
  ];
  const fieldNames = [
    "name",
    "pmStatus",
    "address1",
    "phone",
    "billingEmail"
  ];

  // Should open a tab to a account disconnect details page
  const onSelected = (item) => {
    window.open("/propertyManager/" + item.uuid + "/" + item.id);
  };

  // Used for form refreshing after completion
  const onUpdate = () => {
    hide();
    props.getAllPropManagers();
  };

  const [display, setDisplay] = React.useState(false);

  const show = () => {
    setDisplay(true);
  };

  const hide = () => {
    setDisplay(false);
  };

  const toggleDisplay = () => {
    const shouldDisplay = !display;
    setDisplay(shouldDisplay);
  };


  const useStyles = makeStyles({
    active: {
      fontWeight: "bold",
      color: "#f9a825",
      borderColor: "#f9a825",
    },
    inactive: {
      fontWeight: "bold",
    },
  });


  const classes = useStyles();

  const [pmStatusQueryType, setPmStatusQueryType] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [developerPMs, setDeveloperPMs] = React.useState(false); // should be all thats needed to reduce the disconnects shown to only that accounts
  const [searchQuery, setSearchQuery] = React.useState(""); // should be all thats needed to reduce the disconnects shown to only that accounts

  const [visiblePMs, setVisiblePMs] = React.useState([]);

  // Bad system only refers to the one pm id on the prop preview list. Probs will be replacing this with a multi pm link system
  const [property, setProperty] = React.useState("");

  const handleChangePage = (page) => {
    setPage(page);
  };

  const handleSelectedPropertyChange = (event) => {
    setProperty(event.target.value);
  };

  const handleSearchQueryInput = (event) => {
    setSearchQuery(String(event.target.value));
  };

  const handleAllTabClick = () => {
    handleChangePage(0);
    setPmStatusQueryType(0);
  };

  const handleActiveTabClick = () => {
    handleChangePage(0);
    setPmStatusQueryType(4);
  };

  const handleInActiveTabClick = () => {
    handleChangePage(0);
    setPmStatusQueryType(5);
  };
  const handleDeveloperPMsClick = () => {
    handleChangePage(0);
    setDeveloperPMs(!developerPMs);
  }

  const ShowDeveloperPMsCheckBox = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={developerPMs}
            onChange={handleDeveloperPMsClick}
            name="developerPMs"
            color="primary"
          />
        }
        label="Developer PMs"
      />
    );
  }
  const Filters = () => {
    const classes = useStyles();

    return (
      <Grid container lg={12}>
        <Grid item lg={8} md={8} sm={8}>
          <ButtonGroup
            color="primary"
            aria-label="outlined primary button group"
          >
            <Button
              onClick={handleAllTabClick}
              className={
                pmStatusQueryType == 0
                  ? classes.active
                  : classes.inactive
              }
            >
              All
            </Button>
            <Button
              onClick={handleActiveTabClick}
              className={
                pmStatusQueryType == 4
                  ? classes.active
                  : classes.inactive
              }
            >
              Active
            </Button>
            <Button
              onClick={handleInActiveTabClick}
              className={
                pmStatusQueryType == 5
                  ? classes.active
                  : classes.inactive
              }
            >
              In Active
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    );
  };

  const conditionalStyling = (item) => {
    item.pmStatus = <StatusTag type={"propertyManagerStatus"} isLoading={props.propertyManagers.isLoading} typeId={item.statusId} showTitle={false} condensed={true} />
    return {};
  };

  useEffect(() => {
    props.getAllPropManagers();
    props.getAllDevelopers()
  }, [])

  // Filtering process locally: 
  const toShow = { items: JSON.parse(JSON.stringify(props.propertyManagers.data.propertyManagersList.items)), toUpdate: [], toDelete: [] };
  // Loops through each item:
  for (var x = 0; x < toShow.items.length;) {
    // Filtering by Search Query
    if (searchQuery != "") {
      if (!(toShow.items[x].name != null && toShow.items[x].name.toLowerCase().includes(searchQuery.toLowerCase())) &&
        !(toShow.items[x].phone != null && toShow.items[x].phone.toLowerCase().includes(searchQuery.toLowerCase())) &&
        !(toShow.items[x].billingEmail != null && toShow.items[x].billingEmail.toLowerCase().includes(searchQuery.toLowerCase())) &&
        !(toShow.items[x].address1 != null && toShow.items[x].address1.toLowerCase().includes(searchQuery.toLowerCase()))
      ) {
        toShow.items.splice(x, 1);
        continue;
      }
    }
    // Filtering By Property
    if (property != "") {
      if (property != toShow.items[x].id) {
        //console.log("FILTERING BY PROPERTY: "  + toShow.items[x].name);
        toShow.items.splice(x, 1);
        continue;
      }
    }
    // Filtering by Active/inActive/All
    // Bad practice for me to hardcode it like this but i really cant think of a better way rn because the current Id's always change upon refresh and how else can i standardize it?
    // Only show active
    if (pmStatusQueryType != 0 && toShow.items[x].statusId != pmStatusQueryType) {
      toShow.items.splice(x, 1);
      continue;
    }
    if(developerPMs && toShow.items[x].developerId == null){
      toShow.items.splice(x, 1);
      continue;
    }
    // If the item is good to stay, only then should you advance as cutting an item naturally advances the cursors position.
    // Im a brick for this taking longer then it should have until i realized this was going on
    x++;
  }
  return (
    <Card mb={6} elevation={3}>
      <CardContent>
        <Dialog
          open={display}
          TransitionComponent={Transition}
          fullWidth={"lg"}
          maxWidth={"lg"}
          keepMounted
          onClose={hide}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            New Property Manager
          </DialogTitle>
          <DialogContent>
            <PropertyManagerForm
              onUpdate={toggleDisplay}
              oncCancel={toggleDisplay}
            />
          </DialogContent>
        </Dialog>
        <Grid container xs={12} alignItems="center" spacing={2}>
          <Grid item xs={6}>
            <Filters />
          </Grid>
          <Grid item xs={6}>
            <ShowDeveloperPMsCheckBox/>
          </Grid>
          {!props.isAccountPage &&
            <>
              <Grid item xs={6}>
                <FormControl fullWidth my={2}>
                  <InputLabel id="propertyToBillDropDownLabel">Location{"  "}
                    {props.apl.isLoading &&
                      <CircularProgress size={15} m={0} />
                    }
                  </InputLabel>
                  {property == "" && props.apl.isLoading ?
                    <Select
                      labelId="propertyToBillDropDownLabel"
                      id="propertyToBillDropDown"
                      value={property}
                      className={classes.selectedPropertyLocationLabel}
                      onChange={handleSelectedPropertyChange}
                      fullWidth
                      disabled
                      my={2}>
                    </Select>
                    :
                    <Select
                      labelId="propertyToBillDropDownLabel"
                      id="propertyToBillDropDown"
                      value={property}
                      className={classes.selectedPropertyLocationLabel}
                      onChange={handleSelectedPropertyChange}
                      fullWidth
                      my={2}>
                      {props.apl.data.propertyPreviews.length > 0 ?
                        [
                          <MenuItem value={""}>All</MenuItem>,
                          props.apl.data.propertyPreviews.map((item, key) => {
                            return (
                              <MenuItem value={item.propertyManagerId} id={key}>{item.name}</MenuItem>
                            )
                          })
                        ]
                        :
                        [
                          <MenuItem value={-1}>No Properties Found</MenuItem>
                        ]
                      }
                    </Select>
                  }
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <SearchBar />
              </Grid>
            </>
          }
        </Grid>
        <EditableTable
          readOnly={true}
          canSave={false}
          conditionalStylingMethod={conditionalStyling}
          isLoading={props.propertyManagers.isLoading}
          newItemCreationMethod={toggleDisplay}
          handleRowClickParentMethod={onSelected}
          tableTitle={"Property Managers Search"}
          objectArray={toShow}
          fieldTitles={fieldTitles}
          fieldTypes={fieldTypes}
          fieldNames={fieldNames}
          customHandleChangePageEvent={handleChangePage}
        />
        <Grid
          container
          style={{ "justify-content": "end", "margin-top": "1em" }}
        >
        </Grid>
      </CardContent>
    </Card>
  );
}
// Component accountDisconnects
PropertyManagerSearchTable.propTypes = {
  // Store objects:
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  propertyManagers: PropTypes.object.isRequired,
  getAllPropManagers: PropTypes.func.isRequired,
  getAllDevelopers: PropTypes.func.isRequired,
};

// Component State
function State(state) {
  return {
    user: state.user,
    apl: state.apl,
    propertyManagers: state.propertyManagers,
    developers: state.developers,
  };
}
export default connect(State, { getAllPropManagers, getAllDevelopers })(
  PropertyManagerSearchTable
);
