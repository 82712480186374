import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField as MuiTextField
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import styled from "styled-components/macro";
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
//Material UI Components and Functions
import { makeStyles } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import Skeleton from "@material-ui/lab/Skeleton";
import React from "react";
import PropTypes from 'prop-types';
// Custom Components
import { EnhancedTableHead, EnhancedTableToolbar } from "./header";
import SearchBar from "material-ui-search-bar";

import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import { EditableTableRow } from "./row";
import { useEffect } from "react";

const TextField = styled(MuiTextField)(spacing);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function isNumber(n) { return !isNaN(parseFloat(n)) && !isNaN(n - 0) }

// A helper method that im adding to the editable table object to standardize dropdown field creation
export function newDropDownField(
  targetFieldName,
  values,
  displayValueName,
  selectValueName,
  onChangeMethod,
) {
  return { targetFieldName, values, displayValueName, selectValueName, onChangeMethod };
}

export function newConditionalOnSelectButton(
  buttonLabel,
  conditionalShowStatement,
  onSelectMethod
) {
  return { buttonLabel, conditionalShowStatement, onSelectMethod };
}

export function newFieldLinkUrl(targetFieldName, method) {
  return { targetFieldName, method };
}

export function newFieldPopupModel(targetFieldName, popupModel) {
  return { targetFieldName, popupModel };
}

export function newOnFieldChangeMethod(targetFieldName, method) {
  return { targetFieldName, method };
}

// A method that checks the object before it is allowed to be deleted
// When Calls, is handed that current row obj to be evaluated.
/*export function newPreDeleteEvalationMethod(method) {
  return { method };
}*/



// new order by item (sortColumns) {fieldname: name, direction: dir (asc, desc)}
function stableSort(array, sortColumns) {
  const stabilizedThis = (array).map((el, index) => [el, index]);
  if (sortColumns.length > 0) {
    /*console.log("sortColumns");
     console.log(sortColumns);
     console.log("array");
     console.log(array);*/
  }
  stabilizedThis.sort((a, b) => {
    let i = 0, result = 0;
    while (i < sortColumns.length && result === 0) {
      if (a[0][sortColumns[i].fieldname] && b[0][sortColumns[i].fieldname]) {
        if (isNumber(a[0][sortColumns[i].fieldname]) && isNumber(b[0][sortColumns[i].fieldname])) {
          result = (sortColumns[i].direction == "asc" ? 1 : -1) * (a[0][sortColumns[i].fieldname]
            <
            b[0][sortColumns[i].fieldname] ? -1
            :
            (a[0][sortColumns[i].fieldname] > b[0][sortColumns[i].fieldname] ? 1 : 0));
        }
        else {
          result = (sortColumns[i].direction == "asc" ? 1 : -1) * (a[0][sortColumns[i].fieldname].toString().toLowerCase()
            <
            b[0][sortColumns[i].fieldname].toString().toLowerCase().replace(/\s/g, '') ? -1
            :
            (a[0][sortColumns[i].fieldname].toString().toLowerCase().replace(/\s/g, '') > b[0][sortColumns[i].fieldname].toString().toLowerCase().replace(/\s/g, '') ? 1 : 0));
        }
      }
      else {
        if ((sortColumns[i].direction == "asc" && !a[0][sortColumns[i].fieldname]) || (sortColumns[i].direction == "desc" && !b[0][sortColumns[i].fieldname])) {
          return -1;
        }
        else {
          return 1;
        }
      }

      i++;
    }
    return result;
  });
  return stabilizedThis.map((el) => el[0]);
}




function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const onCustomPageChange = (event) => {
    var newPage = event.target.value - 1
    if (newPage > 0) {
      if (newPage > Math.max(0, Math.ceil(count / rowsPerPage) - 1))// greater value then last page
      {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
      }
      else {
        onPageChange(event, event.target.value - 1);
      }
    }
    else {
      onPageChange(event, 0);
    }
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <TextField
        onChange={onCustomPageChange}
        style={{ width: "40px" }}
        type="number"
        value={page + 1}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.fieldnameTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

// Table Expects: {tableTitle, objectArray, fieldTitles(Array), fieldTypes(Array), fieldNames(Array), handleRowClick, isRowSelectedParentMethod}
// Row Expects: {currentObject, fieldTitles(Array), fieldTypes(Array), fieldNames(Array), handleRowClick, isRowSelectedTableMethod}
// Tool Bar Expects: {tableTitle, fieldTitles(Array), fieldTypes(Array), fieldNames(Array) }
// Header Expects: {fieldTitles(Array), fieldTypes(Array), fieldNames(Array) }

// TODO: object.RowError checker that displays different styled rows
export function EditableTable({
  newItemCreationMethod,
  manualItemDeletionMethod,
  hideRowFieldTitles = false,
  readOnly = false,
  canSave = true,
  canAddItems = true,
  defaultItemCount,
  canDelete = true,
  verifyDelete = false,
  isLoading = false,
  canExport = false,
  canSearch = false,
  handlePreviewClickParentMethod,
  handleGenerateClickParentMethod,
  saveOnTextboxLeave = false,
  isPagedLocally = true,
  // Parent methods passed down
  handleRowClickParentMethod,
  updateItemsLocalState,
  saveCurrentTableData,
  conditionalStylingMethod,
  // Table Data
  tableTitle,
  objectArray,
  objectError = null,
  fieldTitles,
  fieldTypes,
  fieldNames,
  dropDownFields,

  // Custom Inherited operators on objects
  customOnChangeFields,
  customUrlFields,
  customOnDeleteValidationMethod,

  popupModelFields,
  currentActiveFilters = [],
  showSearchBar,
  showItemNumber = false,
  /* optional pagination fields */
  customChangePageRowsEvent,
  customHandleChangePageEvent,
  totalRowCount,
  selectItemAction,
  denseTable = false,
  customToolbar,
  selectItemActionButtonLabel,
  conditionalOnSelectButtons,
  ConditionalOnSelectToolbar,
  Filters,
  customExportFields,
}) {
  const useStyles = makeStyles((theme) => ({
    title: {
      flexGrow: 1,
    },
  }));
  if (!defaultItemCount) {
    defaultItemCount = 10;
  }

  //const [order, setOrder] = React.useState("asc");
  //const [orderBy, setOrderBy] = React.useState("none");


  // new order by item {fieldname: name, direction: }
  // order by is an array of 
  const [orderBy, setOrderBy] = React.useState([]);
  const [orderByUpdated, setOrderByUpdated] = React.useState(true);
  const [selected, setSelected] = React.useState([]);
  const [show, setShow] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [pageLocation, setPageLocation] = React.useState([]);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(defaultItemCount);
  //const [currentObjectArray, setArray] = React.useState(objectArray);
  const [searched, setSearched] = React.useState("");
  const [rows, setRows] = React.useState(objectArray);


  // new order by item {fieldname: name, direction: dir (asc, desc)}
  const handleRequestSort = (event, propertyIndex) => {
    var currentOrderBy = orderBy;
    var target = null;
    var fieldName = "";
    /*
    console.log("propertyIndex");
    console.log(propertyIndex);
    console.log("fieldNames");
    console.log(fieldNames);
    console.log("fieldNames[propertyIndex]");
    console.log(fieldNames[propertyIndex]);
    */

    //console.log("currentOrderBy");
    //console.log(currentOrderBy);
    if (Array.isArray(fieldNames[propertyIndex])) {
      if (fieldNames[propertyIndex].length > 2) {
        // Sorts by the middle item if thats an option
        target = currentOrderBy.find(x => x.fieldname === fieldNames[propertyIndex][1]);
      }
      else {
        target = currentOrderBy.find(x => x.fieldname === fieldNames[propertyIndex][0]);
      }
      fieldName = fieldNames[propertyIndex][0];
    }
    else {
      target = currentOrderBy.find(x => x.fieldname === fieldNames[propertyIndex]);
      fieldName = fieldNames[propertyIndex];
    }
    if (target && target != null && fieldName && fieldName != "") {
      //console.log("target");
      //console.log(target);
      var targetIndex = currentOrderBy.indexOf(target);
      if (target.direction == "asc") {
        target.direction = "desc";
        currentOrderBy[targetIndex] = target;
      }
      else if (target.direction == "desc") {
        if (targetIndex > -1) {
          currentOrderBy.splice(targetIndex, 1);
        }
      }
    }
    else {
      currentOrderBy.push({ fieldname: fieldName, direction: "asc" });
    }
    setOrderBy(currentOrderBy);
    setOrderByUpdated(!orderByUpdated);
    /*
    const isAsc = orderBy === fieldNames[property] && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(fieldNames[property]);
    */
  };

  const requestSearch = (searchedVal, newlySetRowsPerPage) => {
    let toshow = [];
    let pages = [];
    var currentRowsPerPage = newlySetRowsPerPage || rowsPerPage;

    console.log("currentRowsPerPage");
    if (objectArray.items?.length > 0) {
      let counter = 0;
      let start = 0;
      objectArray.items.map((row, index) => {
        var itemString = "";
        for (const [key, value] of Object.entries(row)) {
          if (!key.toLowerCase().includes("uuid")) {
            itemString = itemString + " " + String(value).toLowerCase();
          }
        }
        let searchItems = searchedVal.split(",");
        for (var x = 0; x < searchItems.length; x++) {
          if (itemString.includes(searchItems[x].toLowerCase())) {
            counter++;
            if (counter == currentRowsPerPage) {
              pages.push({ start: start, end: index });
              counter = 0;
              start = index;
            }
            toshow.push(index);
            break;
          }
        }
      })
    }
    if (newlySetRowsPerPage) {
      setRowsPerPage(currentRowsPerPage);
    }
    setPage(0);
    setShow(toshow);
    setPageLocation(pages);
  };

  useEffect(() => {
    if (canSearch) {
      requestSearch(searched)
    }
  }, [searched, objectArray])

  const cancelSearch = () => {
    setSearched("");
  };

  const classes = useStyles();

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = objectArray.items.map((item) => item);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleSelectItemAction = () => {
    selectItemAction(selected);
    setSelected([]);
  };

  // Custom parent passed method which can be used as a segway for another page/location/model
  const handleRowClick = (item) => {
    if (handleRowClickParentMethod) {
      handleRowClickParentMethod(item);
    }
  };

  const handlePreviewClick = (item) => {
    if (handlePreviewClickParentMethod) {
      handlePreviewClickParentMethod(item);
    }
  };


  const handleGenerateClick = (item) => {
    if (handleGenerateClickParentMethod) {
      handleGenerateClickParentMethod(item);
    }
  };

  // For selecting a row for internal table use (deleting)
  const handleRowSelected = (item) => {
    const selectedIndex = selected.indexOf(item);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, item);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    event.preventDefault();
    if (customHandleChangePageEvent) {
      customHandleChangePageEvent(newPage);
      // setSelected([]);
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log("Handle Change rows per page");
    var currentRowsPerPage = parseInt(event.target.value);
    //var oldPlaceStart = rowsPerPage * page;

    console.log("currentRowsPerPage");

    console.log("event.target");
    console.log(event.target);
    console.log("searched");
    console.log(searched);

    if (customChangePageRowsEvent) { customChangePageRowsEvent(currentRowsPerPage); }
    let toshow = [];
    let pages = [];

    if (canSearch && searched != "") {
      requestSearch(searched, currentRowsPerPage);
    }
    else {
      if (objectArray.items?.length > 0) {
        let counter = 0;
        let start = 0;
        console.log("no current search target");
        objectArray.items.map((row, index) => {
          counter++;
          if (counter == currentRowsPerPage) {
            pages.push({ start: start, end: index });
            console.log("new Page: ");
            console.log(pages[pages.length - 1]);
            counter = 0;
            start = index;
          }
          toshow.push(index);
        });
        setPage(0);
        setRowsPerPage(currentRowsPerPage);
        setShow(toshow);
        setPageLocation(pages);
        //setRowsPerPage(parseInt(event.target.value, 10));
        //setSelected([]);
      }
    }
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const markItemAsEdited = (item) => {
    if (item.uuid && objectArray.toUpdate) {
      const itemUuidIndex = objectArray.toUpdate.indexOf(item.uuid);
      if (itemUuidIndex == -1) {
        objectArray.toUpdate.push(item.uuid);
      }
    }
  };

  const handleStandardFieldChange = (item) => {
    markItemAsEdited(item);
    if (saveOnTextboxLeave && saveCurrentTableData) {
      //console.log("SAVING");
      saveCurrentTableData(objectArray);
    } else {
      updateItemsLocalState(objectArray);
    }
  };


  const isSelected = (item) => selected.indexOf(item) !== -1;
  var rowCount = 0;
  var emptyRows = rowsPerPage;
  var emptyLoadingRows = [];
  if (isLoading || !objectArray || !objectArray.items) {
    objectArray = { items: [] };
    for (var x = 0; x < emptyRows; x++) {
      emptyLoadingRows.push(
        <Skeleton
          key={fieldNames[0] + "LoadingSkelly" + x}
          variant="rect"
          height="40px"
          style={{
            backgroundColor: `#bdbfbe`,
            padding: 6,
            margin: 6,
            marginBottom: 3,
          }}
        />
      );
    }
  } else if (totalRowCount && objectArray.items.length == 0) {
    setPage(0);
  } else {
    // If an array is there but filtering put your location farther away then the size of the list
    if (!isLoading && page > 0 && objectArray.items.length < page * rowsPerPage) {
      setPage(0);
    }
    if (totalRowCount) {
      rowCount = totalRowCount;
    } else {
      rowCount = objectArray.items.length;
    }
    var minVal = Math.min(rowsPerPage, rowCount - (page * rowsPerPage));
    emptyRows = rowsPerPage - minVal;
  }

  const handleNewItemCreation = () => {
    if (newItemCreationMethod) {
      newItemCreationMethod();
    }
  };



  const isAbleToDeleteItem = (item) => {
    if (customOnDeleteValidationMethod){
        if (!customOnDeleteValidationMethod(item)){
            return false;
        }
        return true;
    }
    return true;
  }

  const handleSelectedItemDeletion = () => {
    let deleteItemFlag = false;
    if (objectArray.toDelete && !manualItemDeletionMethod) {
      rowCount = rowCount - selected.length;
      for (var x = 0; x < selected.length; x++) {

        deleteItemFlag = isAbleToDeleteItem(selected[x])
        // Evalaute if item can be deleted
        if (selected[x].uuid && deleteItemFlag) {

          // if the item has been saved already
          objectArray.toDelete.push(selected[x].uuid); // If it exists in the db, push item id to toDelete array for removal
        }

        if(deleteItemFlag) {
          // Then remove item from local store and refresh store to rerender shortened list:
          const selectedIndex = objectArray.items.indexOf(selected[x]);
          if (selectedIndex > -1) {
            objectArray.items.splice(selectedIndex, 1);
          }
        }

      }
      let newSelected = [];
      setSelected(newSelected);
      updateItemsLocalState(objectArray);
    }

    // Method added to handle deletion without having to save,
    // checks if method is passed adn then resets the object array's to delete array
    if (manualItemDeletionMethod) {
      var toDelete = [];
      for (var x = 0; x < selected.length; x++) {
        deleteItemFlag = isAbleToDeleteItem(selected[x])
        if (selected[x].uuid && deleteItemFlag) {
          // if the item has been saved already
          toDelete.push(selected[x].uuid); // If it exists in the db, push item id to toDelete array for removal
        }
        if(deleteItemFlag) {
          // Then remove item from local store and refresh store to rerender shortened list:
          const selectedIndex = objectArray.items.indexOf(selected[x]);
          if (selectedIndex > -1) {
            objectArray.items.splice(selectedIndex, 1);
          }
        }
      }
      let newSelected = [];
      setSelected(newSelected);
      manualItemDeletionMethod(toDelete);
    }
  };

  const handleSavingOfCurrentData = () => {
    saveCurrentTableData(objectArray);
  };

  const handleCondOnSelectButtonOnClickMethod = (onClickMethod) => {
    onClickMethod(selected);
    setSelected([]);
  };

  var sliceStart;
  var sliceEnd;

  if (canSearch) {
    sliceStart = pageLocation[page]?.start || 0;
    sliceEnd = pageLocation[page]?.end || rowsPerPage;
    rowCount = show?.length
  } else {
    sliceStart = page * rowsPerPage;
    sliceEnd = page * rowsPerPage + rowsPerPage;
    if (!isPagedLocally) {
      sliceStart = 0;
      sliceEnd = rowCount;
    }
  }

  if (!Array.isArray(objectArray.items)) {
    if (objectError == null) {
      objectError = "ERROR: Items passed to table are not in a proper format - Object.items must be an array";
    }
    else {
      objectError = objectError + ", ERROR: Items passed to table are not in a proper format - Object.items must be an array";
    }
    console.log("ERROR: Items passed to table are not in a proper format - Object.items must be an array");
    console.log(objectArray);
  }
  return (
    <Grid item lg={12} component={Paper}>
      <EnhancedTableToolbar
        CustomToolbar={customToolbar}
        ConfirmDeletion={verifyDelete || false}
        readOnly={readOnly}
        ConditionalOnSelectToolbar={ConditionalOnSelectToolbar}
        canSave={canSave}
        canAddItems={canAddItems}
        canDelete={canDelete}
        canDeleteEvaluator={isAbleToDeleteItem}
        tableTitle={tableTitle}
        numSelected={selected.length}
        handleDeletData={handleSelectedItemDeletion}
        handleNewItemCreation={handleNewItemCreation}
        handleSaveData={handleSavingOfCurrentData}
        fieldTitles={fieldTitles}
        fieldTypes={fieldTypes}
        fieldNames={fieldNames}
        selectedItems={selected}
        exportData={objectArray.items}
        canExport={canExport}
        onSelectAllClick={handleSelectAllClick}
        customSelectItemActionButtonLabel={selectItemActionButtonLabel}
        customHandleSelectItemAction={
          selectItemAction ? handleSelectItemAction : null
        }
        CustomFilters={Filters}
        activeFilters={currentActiveFilters}
        condOnSelectButtons={conditionalOnSelectButtons}
        handleCondOnSelectButtonOnClickMethodMethod={
          handleCondOnSelectButtonOnClickMethod
        }
      />
      {canSearch &&
        <SearchBar
          value={searched}
          onChange={(searchVal) => setSearched(searchVal)}
          onCancelSearch={() => cancelSearch()}
        />}
      <TableContainer>
        <Table
          aria-labelledby="tableTitle"
          size={denseTable ? "small" : "medium"}
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            tableTitle={tableTitle}
            fieldTitles={fieldTitles}
            fieldTypes={fieldTypes}
            fieldNames={fieldNames}
            numSelected={selected.length}
            showNumber={showItemNumber}
            orderBy={orderBy}
            readOnly={readOnly}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rowCount}
          />
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={fieldNames.length * 2}>
                  {emptyLoadingRows}
                </TableCell>
              </TableRow>
            ) : (
              <>
                {stableSort(objectArray.items, orderBy)
                  .slice(sliceStart, sliceEnd)
                  .map((currentObject, index) => {
                    const isItemSelected = isSelected(currentObject);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    const currentGlobalIndex = sliceStart + index;
                    var style = {};
                    // for styling rows based on the objects status
                    if (conditionalStylingMethod && currentObject) {
                      // Passes a pointer to the current object of that row
                      style = conditionalStylingMethod(currentObject);
                    }
                    if ((currentObject && canSearch && show.includes(currentGlobalIndex)) || (currentObject && !canSearch)) {
                      return (
                        <>
                          <EditableTableRow
                            currentObject={currentObject}
                            conditionalStyle={style}
                            itemSaveOnTextboxLeave={saveOnTextboxLeave}
                            readOnly={readOnly}
                            showNumber={showItemNumber}
                            globalIndex={currentGlobalIndex}
                            isItemSelected={isItemSelected}
                            labelId={labelId}
                            dropDownFields={dropDownFields}
                            onChangeFields={customOnChangeFields}
                            urlFields={customUrlFields}
                            popupModelFields={popupModelFields}
                            fieldTitles={fieldTitles}
                            fieldTypes={fieldTypes}
                            fieldNames={fieldNames}
                            handlePreviewClick={handlePreviewClick}
                            handleGenerateClick={handleGenerateClick}
                            handleRowClick={handleRowClick}
                            handleRowSelected={handleRowSelected}
                            handleStandardItemChange={handleStandardFieldChange}
                            markItemAsEdited={markItemAsEdited}
                            hideRowFieldTitles={hideRowFieldTitles}
                            isDenseTable={denseTable}
                          />
                        </>
                      );
                    }
                    else {
                      return (<></>);
                    }

                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{ height: (denseTable ? 33 : 53) * emptyRows }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </>
            )}
          </TableBody>
        </Table>
        {objectError != null && objectError.message && (
          <Alert severity="error">{objectError.message}</Alert>
        )}
      </TableContainer>
      {isPagedLocally && (
        (objectArray.items.length == rowCount || !customHandleChangePageEvent) ?
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100, rowCount]}
            component="span"
            count={rowCount}
            rowsPerPage={rowsPerPage}
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            }}
            page={page}
            onPageChange={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
          :
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100, rowCount]}
            component="span"
            count={rowCount}
            rowsPerPage={rowsPerPage}
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            }}
            page={page}
            onPageChange={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
      )}
    </Grid>
  );
}
