import CircularProgress from "@material-ui/core/CircularProgress";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(1),
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// isProcessingForm, isFormProcessingError
export default function CustomBillWizardFormProcessingSnackbar(props) {
  const classes = useStyles();
  const [open, isOpen] = React.useState(
    props.message != null && props.severity != null
  );
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    isOpen(false);
  };
  var message = "UNKNOWN FORM STATUS";
  /*
  var severity = "error";
  var autoHideDuration = 6000;
  if (props.isProcessingForm){
    severity = "warning";
    message = "Processing Current Billing Form Progress....";
  }
  else if (props.isFormProcessingError)
  {
    severity = "error";
    message = "WARNING: There was an error retrieving or maintaining information on the batch table. ";
  }
  else if (!props.isFormProcessingError && !props.isProcessingForm)
  {
    severity = "success";
    message = "Form saving/validation completed successfully";
  }
*/
  return (
    <>
      {props.severity && props.severity == "success" ? (
        <Snackbar
          open={open}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          autoHideDuration={6000}
        >
          <Alert
            elevation={6}
            variant="filled"
            severity={props.severity || "warning"}
            style={{ color: "white", fontSize: "14px" }}
          >
            {props.isProcessingForm && <CircularProgress size={20} />}
            {" " + props.message || message}
          </Alert>
        </Snackbar>
      ) : (
        <Snackbar
          open={open}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            elevation={6}
            variant="filled"
            severity={props.severity || "warning"}
            style={{ color: "white", fontSize: "14px" }}
          >
            {props.isProcessingForm && <CircularProgress size={20} />}
            {" " + props.message || message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}
