// Imports
import axios from "axios";
require("../../../setup/config/apiConfig");

export const GET_ERROR = "GET_ERROR";
export const POST_ERROR = "POST_ERROR";
export const PUT_ERROR = "PUT_ERROR";
export const DEL_ERROR = "DEL_ERROR";

export function GET(
  route,
  param,
  requestState,
  responseState,
  failState = GET_ERROR,
  isLoading = true
) {
  let url;
  if(param)
    url = `${route}/${param || ""}`
  else 
    url = route;

  return (dispatch) => {
    dispatch({
      type: requestState,
      isLoading: isLoading,
    });
    return axios
      .get(url)
      .then((response) => {
        let error = "";
        if (response.data.error) {
          error = response.data.errors[0].message;
        }
        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem("user"));
        if (currentUser) {
          // Sets the result message of the response to the state object
          dispatch({
            type: responseState,
            response: response.data,
            error: error,
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: responseState,
            response: response.data,
            error: "ERROR! NO USER LOGGED IN!",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: failState,
          isLoading: false,
          error: error,
        });
      });
  };
}

export function POST(
  route,
  param,
  body,
  requestState,
  responseState,
  failState = POST_ERROR,
  isLoading = true
) {
  return (dispatch) => {
    dispatch({
      type: requestState,
      isLoading: isLoading,
    });
    return axios
      .post(`${route}/${param}`, body)
      .then((response) => {
        let error = "";
        if (response.data.error) {
          error = response.data.errors[0].message;
        }
        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem("user"));
        if (currentUser) {
          // Sets the result message of the response to the state object
          dispatch({
            type: responseState,
            response: response.data,
            error: error,
          });
          return response.data;
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: responseState,
            response: response.data,
            error: "ERROR! NO USER LOGGED IN!",
          });
          return response;
        }
      })
      .catch((error) => {
        dispatch({
          type: failState,
          isLoading: false,
          error: error,
        });
        return error;
      });
  };
}

export function PUT(
  route,
  param,
  body,
  requestState,
  responseState,
  failState = PUT_ERROR,
  isLoading = true
) {
  return (dispatch) => {
    dispatch({
      type: requestState,
      isLoading: isLoading,
    });
    return axios
      .put(`${route}/${param}`, body)
      .then((response) => {
        let error = "";
        if (response.data.error) {
          error = response.data.errors[0].message;
        }
        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem("user"));
        if (currentUser) {
          // Sets the result message of the response to the state object
          dispatch({
            type: responseState,
            response: response.data,
            error: error,
          });
          return response.data;
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: responseState,
            response: response.data,
            error: "ERROR! NO USER LOGGED IN!",
          });
          return response;
        }
      })
      .catch((error) => {
        dispatch({
          type: failState,
          isLoading: false,
          error: error,
        });
        return error;

      });
  };
}

export function DEL(
  route,
  param,
  requestState,
  responseState,
  failState = DEL_ERROR,
  isLoading = true
) {
  return (dispatch) => {
    dispatch({
      type: requestState,
      isLoading: isLoading,
    });
    return axios
      .delete(`${route}/${param}`)
      .then((response) => {
        let error = "";
        if (response.data.error) {
          error = response.data.errors[0].message;
        }
        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem("user"));
        if (currentUser) {
          // Sets the result message of the response to the state object
          dispatch({
            type: responseState,
            response: response.data,
            error: error,
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: responseState,
            response: response.data,
            error: "ERROR! NO USER LOGGED IN!",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: failState,
          isLoading: false,
          error: error,
        });
      });
  };
}
