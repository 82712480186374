export default (owners, searchTerm, activeFilter) => {
  // activeFilter => 'Active', 'Inactive', 'All'

  console.log("filtering....");
  let filteredOwners = owners.filter((owner) => {
    if (activeFilter == "Inactive" && owner.active == 1) return false;

    if (activeFilter == "Active" && owner.active == 0) return false;

    if (!searchTerm || searchTerm == "") return true;

    if (owner.name) {
      if (owner.name.toUpperCase().includes(searchTerm.toUpperCase()))
        return true;
    }

    if (owner.contactName) {
      if (owner.contactName.toUpperCase().includes(searchTerm.toUpperCase()))
        return true;
    }

    if (owner.address1) {
      if (owner.address1.toUpperCase().includes(searchTerm.toUpperCase()))
        return true;
    }

    if (owner.address2) {
      if (owner.address2.toUpperCase().includes(searchTerm.toUpperCase()))
        return true;
    }

    if (owner.id) {
      if (owner.id.toString().toUpperCase().includes(searchTerm.toUpperCase()))
        return true;
    }
    if (owner.glCode) {
      if (owner.glCode.toString().toUpperCase().includes(searchTerm.toUpperCase()))
        return true;
    }

  });

  return { items: filteredOwners };
};
