import { GET_UNIT_TYPES, GET_UNIT_TYPES_RESPONSE, RESET } from "./actions";

const initialState = {
  isLoading: false,
  error: null,
  data: {
    unitTypesList: {
      items: [],
    },
    unitType: null,
    searchQuery: null,
    range: { start: 0, end: 25 },
  },
};

// State
export default (state = initialState, action) => {
  var data = state.data;
  switch (action.type) {
    case RESET:
      return Object.assign({}, initialState);
    case GET_UNIT_TYPES:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case GET_UNIT_TYPES_RESPONSE:
      data.unitTypesList.items = action.unitTypes;
      return {
        ...state,
        data: data,
        error: action.error,
        isLoading: false,
      };
    default:
      return {
        ...state,
      };
  }
};
