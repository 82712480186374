import { NEW_EMAIL_REQUEST, NEW_EMAIL_RESPONSE, NEW_EMAIL_FAILURE, RESET, SET_TEMPLATE, UPDATE_EMAIL_STATE } from "./actions";

const initialState = {
  isLoading: false,
  error: null,
  data: {
    // Standardized templates
    returnedPayment: {
      subject: "Returned Payments",
      message: "A payment has been returned!"
    },
    returnedMail: {
      subject: "Returned Mail",
      message: "Mail that has bee sent in has been returned"
    },
    winterReconnect: {
      subject: "Winter Reconnect Order",
      message: "You are being notified of a winter reconnect order"
    },
    paymentPlan: {
      subject: "Payment Plan",
      message: "You have been put on a payment plan"
    },
    medicalForm: {
      subject: "Medical Form",
      message: "A medical form must be returned in order for the account to be properly processed"
    }
  },
  // Item being modified to be sent out
  outgoingEmail: {
    subject: "",
    message: "",
    email: ""
  },
};

// State
export default (state = initialState, action) => {
  switch (action.type) {
    case RESET:
      return Object.assign({}, initialState);
    case SET_TEMPLATE: // expects string of type in action
      var newOutgoing = state.outgoingEmail;
      newOutgoing.message = state.data[action.templateType].message || "";
      newOutgoing.subject = state.data[action.templateType].subject || "";
      return {
        ...state,
        outgoingEmail: newOutgoing
      }
    case UPDATE_EMAIL_STATE:
      return {
        ...state,
        outgoingEmail: action.currentEmail,
      };
    case NEW_EMAIL_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case NEW_EMAIL_RESPONSE:
      return {
        ...state,
        error: null,
        isLoading: false,
      };
    case NEW_EMAIL_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    default:
      return {
        ...state,
      };
  }
};
