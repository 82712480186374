import {
  Button,
  ButtonGroup,
  Card as MuiCard,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  TextField as MuiTextField,
  FormControlLabel,
  Checkbox,
  DialogActions
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import { spacing } from "@material-ui/system";
import Alert from '@material-ui/lab/Alert';
// Redux Components
import PropTypes from "prop-types";
import React from "react";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components/macro";
import { EditableTable, newConditionalOnSelectButton } from "../../../components/EditableTable";
import StatusTag from "../../apl/components/StatusTag";
import { getByPropertyId as getUnitsByPropertyId, transferUnitsToNewPm, transferUnitsToOwner, deleteUnit } from "../api/actions";
import Form from "./Form";
import {useHistory, useParams} from 'react-router-dom';
import { setSnackbar } from "../../snackbar/api/snackbar";
import {  } from "modules/apl/api/actions";
import moment from "moment";
import _ from "lodash";

const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function Table(props) {

  const [unitsToDisplayType, setUnitsToDisplayType] = React.useState('Tenant Housing');
  const [targetUnits, setTargetUnits] = React.useState(null);
  const [targetPm, setTargetPm] = React.useState(null);
  const [targetOwner, setTargetOwner] = React.useState(null);
  const [transferPMDisplay, setTransferPMDisplay] = React.useState(false);
  const [transferOwnerDisplay, setTransferOwnerDisplay] = React.useState(false);
  const [understandingCheck, setUnderStandingCheck] = React.useState(false);
  const [doneByDate, setDoneByDate] = React.useState(moment().format("YYYY-MM-DD"));
  const [buildingNumberDisplay, setBuildingNumberDisplay] = React.useState(false);
  const [targetBuildingNumber, setTargetBuildingNumber] = React.useState(null);
  const [buildingNumbers, setBuildingNumbers] = React.useState([]);

  const useStyles = makeStyles({
    active: {
      fontWeight: "bold",
      color: "#f9a825",
      borderColor: "#f9a825",
    },
    inactive: {
      fontWeight: "bold",
    },
  });

  const classes = useStyles();
  const dispatch = useDispatch();

  const billingType = props.propertyUtilities.data.propertyUtility?.billingType

  const transferUnit = async () => {
    if (targetUnits != null && targetUnits.length > 0 && targetPm != null) {

        let index = 0;
        dispatch(setSnackbar(true, "warning", `Transfering ${(targetUnits.length)} unit(s) to new PM`));
        
        for(let unit of targetUnits) {
          index++;
          let response = await props.transferUnitsToNewPm([unit.uuid], targetPm, doneByDate, billingType)
            if (!props.units.error || props.units.error == null || props.units.error == "") {
              dispatch(setSnackbar(true, "warning", `Transfered unit(s) ${index}/${(targetUnits.length)} to new PM`));

            }
            else {
              dispatch(setSnackbar(true, "error", "Error transfering unit, please try again later"));
            }
        }
      }
      else {
        dispatch(setSnackbar(true, "error", "Target units Or PM not set!"));
      }
      if (!props.units.error) {
        dispatch(setSnackbar(true, "success", "Unit Transfer(s) Successful"));
      }
      hideTransferPMWindow();
      props.getUnitsByPropertyId(props.properties.data.property.id, null);
  };

  const transferUnitToOwner = async () => {
    if (targetUnits != null && targetUnits.length > 0 && targetOwner != null) {
      try {
        dispatch(setSnackbar(true, "warning", `Transferring ${targetUnits.length} unit(s) to new Owner`));

        const unitOwnerUuids = targetUnits.map(unit => ({
          uuid: unit.uuid,
          ownerUuid: unit.ownerUuid
        }));

        const res = await props.transferUnitsToOwner(unitOwnerUuids, targetOwner, doneByDate);
        if (res.response?.status && res.response.status !== 200) {
          dispatch(setSnackbar(true, "error", res.response?.data?.ERROR || "Error transferring units, please try again later"));
        } else {
          dispatch(setSnackbar(true, "success", "Unit Transfer(s) Successful"));
          hideTransferOwnerWindow();
          props.getUnitsByPropertyId(props.properties.data.property.id, null);
        }

      } catch (error) {
        dispatch(setSnackbar(true, "error", "Error transferring units, please try again later"));
      }
    } else {
      dispatch(setSnackbar(true, "error", "Target units or Owner not set!"));
    }
  };


  const canAddOrUpdate = ( props.user.details.administration || props.user.details.billing || props.user.details.isSuperUser ) ? true : false;
 const canDelete = props.user.details.email == process.env.REACT_APP_ADMIN_EMAIL ? true : false;
 
 const setUnitsByBuildingNumber = () => {
    const items = _.cloneDeep(props.units.data.unitsList.items);
    const filteredUnits = items.filter(unit => unit.buildingNumber == targetBuildingNumber );
    
    setTargetUnits(_.cloneDeep(filteredUnits));
    hideBuildingNumberDisplay();
    setTransferPMDisplay(true);
 } 


  const handleSearchTargetChange = (event) => {
    const searchQuery = (props.units.data.searchQuery = event.target.value);
    const propertyId = props.properties.data.property.id;
    props.getUnitsByPropertyId(propertyId, searchQuery);
  };
  const handleDoneByDateChange = (event) => {
    setDoneByDate(event.target.value);
    
}

  const IsAbleToDeleteUnit = (unit) => {
    return unit.unitTypeId == 5 ? true : false
  }

  const HandleDeleteUnit = async (units) => {
    if (units != null && units.length > 0) {
      let index = 0;
      dispatch(setSnackbar(true, "warning", `Deleting ${(units.length)} unit(s)`));
      
      for(const unit of units) {
        index++;
        const response = await props.deleteUnit(unit)
          if (!props.units.error || props.units.error == null || props.units.error == "") {
            dispatch(setSnackbar(true, "warning", `Deleted unit(s) ${index}/${(units.length)}`));

          }
          else {
            dispatch(setSnackbar(true, "error", "Error deleting unit, please try again later"));
          }
      }
    }
    else {
      dispatch(setSnackbar(true, "error", "Target units not set!"));
    }
    dispatch(setSnackbar(true, "success", "Unit Deletion(s) Successful"));
    props.getUnitsByPropertyId(props.properties.data.property.id, null);
  }
  const CurrentLookupTypeButton = () => {
    return (
      <IconButton>
        <SearchIcon mr={2} />
      </IconButton>
    );
  };

  const SearchBar = () => {
    return (
      <Grid container lg={12} spacing={1}>
        <Grid item lg={8} md={8} sm={8}>
          <TextField
            id={"searchTarget"}
            label="Search units"
            type={"text"}
            onChange={handleSearchTargetChange}
            name={"searchTarget"}
            value={props.units.data.searchQuery}
            fullWidth
            my={2}
            size={"small"}
            variant={"outlined"}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{ endAdornment: <CurrentLookupTypeButton /> }}
            helperText="Search by unit characteristics"
          ></TextField>
        </Grid>
      </Grid>
    );
  };

  // Table Stuff:
  const fieldTitles = ["Address", "Address 2", "Occupant Name", "Owner", "Property Manager"];
  const fieldTypes = ["label", "label", "label", "label", "label"];
  const fieldNames = ["address1", "address2", "occupantName", "ownerName", "propertyManagerName"];


  const fieldTitlesTransfer = ["Current PM", "Address", "Address 2", "Unit Type", "Occupant Name"];
  const fieldTypesTransfer = [["labelSmall", "labelSmall"], "label", "label", "label", "label"];
  const fieldNamesTransfer = [["propertyManagerName", "propertyManagerBillingEmail"], "address1", "address2", "unitType", "occupantName"];

  const fieldTitlesOwnerTransfer = ["Current Owner", "Address", "Address 2", "Unit Type", "Occupant Name"];
  const fieldTypesOwnerTransfer = ["label", "label", "label", "label", "label"];
  const fieldNamesOwnerTransfer = ["ownerName", "address1", "address2", "unitType", "occupantName"];
  const history = useHistory();

  const onSelected = (item) => {
    window.open(
      "/unit/" + item.uuid
    );
  };

  const onSetTargetBuildingNumber = (event) => {
    setTargetBuildingNumber(event.target.value);
  }

  const showTransferPMWindow = (items) => {
    if (items && items.length > 0) {
      setTargetUnits(items);
    }
    setTransferPMDisplay(true);
  };
  const showTransferOwnerWindow = (items) => {
    console.log(items, 'items')
    if (items && items.length > 0) {
      setTargetUnits(items);
    }
    setTransferOwnerDisplay(true);
  };

  const hideTransferPMWindow = () => {
    setTargetUnits(null);
    setUnderStandingCheck(false);
    setTransferPMDisplay(false);
  };

  const hideTransferOwnerWindow = () => {
    setTargetUnits(null);
    setUnderStandingCheck(false);
    setTransferOwnerDisplay(false);
  };

  const onSetTargetPm = (event) => {
    setTargetPm(event.target.value);
  };

  const onSetTargetOwner = (event) => {
    setTargetOwner(event.target.value);
  };

  const handleUnderstandCheckBoxChange = () => {
    var checkedValue = understandingCheck;
    setUnderStandingCheck(!checkedValue);
  };
  const onUpdate = () => {
    hide();

    //this is terrible practice but due to time contraints....
    setTimeout(function () {
      props.onUpdate();
    }, 1000);
  };

  const [display, setDisplay] = React.useState(false);
  const [add, setAdd] = React.useState(false);


  const show = () => {
    //props.properties.data.property = {}; // TODO: clear property on popup show
    setDisplay(true);
  };
  const {uuid} = useParams();
  const showBatch = () => {

    window.open(
      `/batch-units/property/${uuid}`
    );

  }

  const hide = () => {
    setDisplay(false);
  };
  const openBuildingNumberDisplay = () => {
    let buildingNums = [];
    for (let unit of props.units.data.unitsList.items) {
      buildingNums.push(unit.buildingNumber);
    }
    buildingNums = [...new Set(buildingNums)];
    setBuildingNumbers(buildingNums);
    setBuildingNumberDisplay(true);
  }
  const hideBuildingNumberDisplay = () => {
    setBuildingNumberDisplay(false);
  }

  const UnitRowStatusStyling = (unit) => {
    unit.occupantName = "";
    if (
      unit.currentOccupantFirstName != "" &&
      unit.currentOccupantFirstName != null
    ) {
      unit.occupantName = unit.currentOccupantFirstName;
    }
    if (
      unit.currentOccupantLastName != "" &&
      unit.currentOccupantLastName != null
    ) {
      unit.occupantName =
        unit.occupantName + " - " + unit.currentOccupantLastName;
    }
    unit.unitType = (
      <StatusTag
        type={"unitType"}
        isLoading={props.units.isLoading}
        typeId={unit.unitTypeId}
        showTitle={false}
        condensed={true}
      />
    );
    return {}; // Returns an empty style as to not effect each row
  };

  const handleAllClick = () => {
    setUnitsToDisplayType('All');
  };
  const handleTenantClick = () => {
    setUnitsToDisplayType("Tenant Housing");
  };
  const handleCommonClick = () => {
    setUnitsToDisplayType("Common Area");
  };
  const handleInactiveDCClick = () => {
    setUnitsToDisplayType("Inactive D");
  }

  const Filters = () => {
    const classes = useStyles();
    return (
      <Grid container lg={12} className="badge-wrapper m-3">
        <Grid item lg={12} md={12} sm={12}>
          <ButtonGroup
            color="primary"
            aria-label="outlined primary button group"
          >
            <Button
              onClick={handleAllClick}
              className={
                unitsToDisplayType == 'All'
                  ? classes.active
                  : classes.inactive
              }
            >
              All
            </Button>
            <Button
              onClick={handleTenantClick}
              className={
                unitsToDisplayType == "Tenant Housing"
                  ? classes.active
                  : classes.inactive
              }
            >
              Tenant Housing
            </Button>
            <Button
              onClick={handleCommonClick}
              className={
                unitsToDisplayType == "Common Area"
                  ? classes.active
                  : classes.inactive
              }
            >
              Common Area
            </Button>
            {canDelete &&
               <Button
               onClick={handleInactiveDCClick}
               className={
                 unitsToDisplayType == "Inactive D"
                   ? classes.active
                   : classes.inactive
               }
             >
               Inactive D
             </Button>
            }
           
          </ButtonGroup>
        </Grid>
      </Grid>
    );
  };

  const BuildingNumberDialog = () => {
    return (
      <Dialog
      open={buildingNumberDisplay}
      TransitionComponent={Transition}
      fullWidth={"lg"}
      maxWidth={"lg"}
      keepMounted
      onClose={hideBuildingNumberDisplay}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Select Building Number</DialogTitle>
        <DialogContent>
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel id="selectedBuildingNumberDropDownLabel" className={classes.selectedTopicLabel}>
              Building Number
            </InputLabel>
            <Select
              labelId="selectedBuildingNumberDropDownLabel"
              id="selectedBuildingNumberDropDown"
              InputLabelProps={{
                shrink: true,
              }}
              value={targetBuildingNumber}
              onChange={onSetTargetBuildingNumber}
              name="buildingNumber"
              fullWidth
              ty
              my={2}>
              {buildingNumbers.map(
                (item, index) => (
                  <MenuItem id={"buildingNumberOption" + index} value={item}>
                    {item}
                  </MenuItem>)  
                )}
              </Select>
              
          </FormControl>
        </DialogContent>
        <DialogActions>
            {
                 targetBuildingNumber  &&
                <Button onClick={setUnitsByBuildingNumber}>Submit</Button>
            }
        </DialogActions>
      </Dialog>
    )
  }

  const NewDialog = () => {
    return (
      <Dialog
        open={display}
        TransitionComponent={Transition}
        fullWidth={"lg"}
        maxWidth={"lg"}
        keepMounted
        onClose={hide}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">New Unit</DialogTitle>
        <DialogContent>
          <Form onUpdate={onUpdate} onCancel={hide} />
        </DialogContent>
      </Dialog>
    );
  };

   let toShowAll = { items: JSON.parse(JSON.stringify(props.units.data.unitsList.items)), toUpdate: [], toDelete: [] };

  // Unit List - Make list Automatically Sorted by Address 1 then Address 2- so we can find the units
 if (toShowAll.items.length > 0) {
   toShowAll.items.sort((a, b) => {
     const address1Comparison = a.address1?.localeCompare(b.address1);
     if (address1Comparison === 0) {
       return a.address2?.localeCompare(b.address2);
     }
 
     return address1Comparison;
   });
 }


  // // Loops through each item:
  // for (var x = 0; x <= toShowAll.items.length + 1; x++) {
  //   // Filtering by Search Query
  //   if (unitsToDisplayType != "All") {
  //     if (unitsToDisplayType == 'Tenant Housing' && toShowAll.items[x]?.commonAccount) {
  //       toShowAll.items.splice(x, 1);
  //       continue;
  //     }
  //     else if (unitsToDisplayType == 'Common Area' && !toShowAll.items[x]?.commonAccount) {
  //       toShowAll.items.splice(x, 1);
  //       continue;
  //     }
  //   }

  // }

  if (unitsToDisplayType == 'Tenant Housing') {
    toShowAll.items = toShowAll.items.filter(item => {
      return item.commonAccount != 1
    })
  }

  if (unitsToDisplayType == 'Common Area') {
    toShowAll.items = toShowAll.items.filter(item => {
      return item.commonAccount == 1
    })
  }
  if(unitsToDisplayType == 'Inactive D'){
    toShowAll.items = toShowAll.items.filter(item => {
      return item.unitTypeId == 5
    })
  }


  const ConditionalOnSelectButtons = [
    newConditionalOnSelectButton(
      "Set New PM",
      () => {
        return (true);
      },
      showTransferPMWindow
    ),
    newConditionalOnSelectButton(
      "Set New Owner",
      () => true,
      showTransferOwnerWindow
    ),
  ];


  return (
    <Card mb={6} elevation={3}>
      <CardContent>
        <NewDialog />
       <BuildingNumberDialog />
        <Grid container spacing={4}>
          <Grid item lg={6} md={12} sm={12}>
            <Button hidden={!canAddOrUpdate} onClick={showBatch} variant="contained" color="primary" className="badge-wrapper m-3">
              Add Units
            </Button>
            <Button hidden={!canAddOrUpdate} onClick={openBuildingNumberDisplay} variant="contained" color="primary" className="badge-wrapper m-3">
              Set PM By Building No.
              </Button>
          </Grid>
          <Grid item lg={6} md={12} sm={12}>
        
          </Grid>
          <Grid item lg={6} md={12} sm={12}>
            <Filters />
          </Grid>
        </Grid>
        <EditableTable
          showItemNumber={true}
          readOnly={!canAddOrUpdate}
          canAddItems={canAddOrUpdate}
          canDelete={canDelete}
          canSave={canAddOrUpdate}
          conditionalStylingMethod={UnitRowStatusStyling}
          isLoading={props.units.isLoading}
          newItemCreationMethod={show}
          handleRowClickParentMethod={onSelected}
          tableTitle={"Units"}
          objectArray={toShowAll}
          verifyDelete={true}
          manualItemDeletionMethod={HandleDeleteUnit}
          currentActiveFilters={[unitsToDisplayType]}
          fieldTitles={fieldTitles}
          fieldTypes={fieldTypes}
          fieldNames={fieldNames}
          customOnDeleteValidationMethod={IsAbleToDeleteUnit}
          conditionalOnSelectButtons={ConditionalOnSelectButtons}
        />
      </CardContent>
      {/*PM Transfer*/}
      <Dialog
        open={transferPMDisplay}
        TransitionComponent={Transition}
        fullWidth={"lg"}
        maxWidth={"lg"}
        keepMounted
        onClose={hideTransferPMWindow}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Transfer Units To New Property Manager</DialogTitle>
        <DialogContent>
          <Grid container xs={12} spacing={2} className="pb-10">
            <Grid item md={12} xs={12}>
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel id="selectedPMDropDownLabel" className={classes.selectedTopicLabel}>
                  New Property Manager
                </InputLabel>
                <Select
                  labelId="selectedPMDropDownLabel"
                  id="selectedPMDropDown"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={targetPm}
                  onChange={onSetTargetPm}
                  name="propertyManagerUuid"
                  ty
                  fullWidth
                  my={2}
                >
                  {props.propertyManagers.data.propertyManagersList.items.map(
                    (item, index) => (
                     
                      <MenuItem id={"pmOption" + index} value={item.uuid}>
                        {item.name}  {item.developerId &&  <StatusTag
                                                            className="ml-3"
                                                            type={"Default"}
                                                            label={"Developer"}
                                                          />
                                                          }
                      </MenuItem>
                     
                    )
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={12} xs={12}>
               <TextField
                  label="Done By Date"
                  type="date"
                  onChange={handleDoneByDateChange}
                  name="doneByDate"
                  defaultValue={moment(doneByDate).format(
                    "YYYY-MM-DD"
                  )}
                  //required
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
            </Grid>
            

            {(targetPm != null && targetUnits != null) &&
              <>
                <Grid item md={12} xs={12}>
                  <Alert severity="error">Warning!!! Transfering units will deactivate old property manager default accounts as well as make the new property manager account the current occupant if a Tenant is not currently occupying the unit. This cannot be undone.</Alert>
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    labelPlacement="start"
                    control={
                      <Checkbox
                        checked={understandingCheck}
                        onChange={handleUnderstandCheckBoxChange}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="I understand the ramifications of what I am doing"
                  />
                </Grid>
                <Grid item xs={6}>
                  {understandingCheck ?
                    <Button variant="contained" onClick={transferUnit} color="primary" fullWidth>
                      Transfer Unit(s)
                    </Button>
                    :
                    <Button variant="contained" onClick={transferUnit} color={"primary"} disabled={true} fullWidth>
                      Transfer Unit(s)
                    </Button>
                  }
                </Grid>
              </>
            }
            {(targetUnits != null && targetUnits.length > 0) &&
              <>
                <EditableTable
                  readOnly={true}
                  canSave={false}
                  canAddItems={false}
                  conditionalStylingMethod={UnitRowStatusStyling}
                  isLoading={props.units.isLoading}
                  tableTitle={"Units To Transfer"}
                  objectArray={{ items: targetUnits, toUpdate: [], toDelete: [] }}
                  fieldTitles={fieldTitlesTransfer}
                  fieldTypes={fieldTypesTransfer}
                  fieldNames={fieldNamesTransfer}
                />
              </>
            }
          </Grid>
        </DialogContent>
      </Dialog>
      {/*Owner Transfer*/}
      <Dialog
          open={transferOwnerDisplay}
          TransitionComponent={Transition}
          fullWidth={"lg"}
          maxWidth={"lg"}
          keepMounted
          onClose={hideTransferOwnerWindow}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Transfer Units To New Owner</DialogTitle>
        <DialogContent>
          <Grid container xs={12} spacing={2} className="pb-10">
            <Grid item md={12} xs={12}>
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel
                    id="selectedOwnerDropDownLabel"
                    className={classes.selectedTopicLabel}>
                  New Owner
                </InputLabel>
                <Select
                    labelId="selectedOwmerDropDownLabel"
                    id="selectedOwnerDropDown"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={targetOwner}
                    onChange={onSetTargetOwner}
                    name="ownerUuid"
                    ty
                    fullWidth
                    my={2}
                >
                  {props.owners.data.ownersList.items.map(
                      (item, index) => (
                          <MenuItem id={"ownerOption" + index} value={item.uuid} key={"ownerOption" + index}>
                            {item.name}
                          </MenuItem>
                      )
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                  label="Done By Date"
                  type="date"
                  onChange={handleDoneByDateChange}
                  name="doneByDate"
                  defaultValue={moment(doneByDate).format(
                      "YYYY-MM-DD"
                  )}
                  //required
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
              />
            </Grid>


            {(targetOwner != null && targetUnits != null) &&
                <>
                  <Grid item md={12} xs={12}>
                    {/*todo: add alert message*/}
                    {/*<Alert severity="error">Warning!!! Transfering units will deactivate old property owner default accounts as well as make the new property owner account the current occupant if a Tenant is not currently occupying the unit. This cannot be undone.</Alert>*/}
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                        labelPlacement="start"
                        control={
                          <Checkbox
                              checked={understandingCheck}
                              onChange={handleUnderstandCheckBoxChange}
                              name="checkedB"
                              color="primary"
                          />
                        }
                        label="I understand the ramifications of what I am doing"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    {understandingCheck ?
                        <Button variant="contained" onClick={transferUnitToOwner} color="primary" fullWidth>
                          Transfer Unit(s)
                        </Button>
                        :
                        <Button variant="contained" onClick={transferUnitToOwner} color={"primary"} disabled={true} fullWidth>
                          Transfer Unit(s)
                        </Button>
                    }
                  </Grid>
                </>
            }
            {(targetUnits != null && targetUnits.length > 0) &&
                <>
                  <EditableTable
                      readOnly={true}
                      canSave={false}
                      canAddItems={false}
                      conditionalStylingMethod={UnitRowStatusStyling}
                      isLoading={props.units.isLoading}
                      tableTitle={"Units To Transfer"}
                      objectArray={{ items: targetUnits, toUpdate: [], toDelete: [] }}
                      fieldTitles={fieldTitlesOwnerTransfer}
                      fieldTypes={fieldTypesOwnerTransfer}
                      fieldNames={fieldNamesOwnerTransfer}
                  />
                </>
            }
          </Grid>
        </DialogContent>
      </Dialog>
    </Card>
  );
}
// Component units
Table.propTypes = {
  // Store objects:
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  property: PropTypes.object.isRequired,
  propertyManagers: PropTypes.object.isRequired,
  owners: PropTypes.object.isRequired,
  units: PropTypes.object.isRequired,
  getUnitsByPropertyId: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  transferUnitsToNewPm: PropTypes.func.isRequired,
  transferUnitsToOwner: PropTypes.func.isRequired,
  deleteUnit: PropTypes.func.isRequired,
  getAllUnitTypes: PropTypes.func.isRequired,
};

// Component State
function State(state) {
  return {
    user: state.user,
    apl: state.apl,
    search: state.search,
    units: state.units,
    properties: state.properties,
    propertyManagers: state.propertyManagers,
    propertyUtilities: state.propertyUtilities,
    owners: state.owners
  };
}
export default connect(State, { getUnitsByPropertyId, transferUnitsToNewPm, transferUnitsToOwner, deleteUnit,})(Table);
