//React Components and Hooks
import {
  Box,
  CircularProgress as MuiCircularProgress,
  Divider as MuiDivider,
  Grid,
  Typography as MuiTypography,
} from "@material-ui/core";
//Material UI Components and Functions
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Tab, Tabs,   CardContent, Card,} from "@material-ui/core";
import { spacing } from "@material-ui/system";
// Redux Components
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import SimpleStatCard from "../../components/cards/SimpleStatCard"; // The stat cards displaying simple analytic numbers
//import DropDownCard from '../../components/cards/DropDownCard';
import StandardHeader from "../../components/global/StandardHeader";
// Custom application component imports:
import { LeftSidebar as NavFrame } from "layout-blueprints"; // The top navigation bar and side navigation panel
import { getAnalyticalData } from "../../modules/apl/api/actions";
import {
  getAllBillingDashboardInfo,
  getAllCompanyBillingAnalytics,
  resetBillingObject,
} from "../../modules/billing/api/actions";
import CurrentBillBatchesManagerTable from "../../modules/billing/components/CurrentBillBatchesManagerTable";
import CurrentBillDraftsManagerTable from "../../modules/billing/components/CurrentBillDraftsManagerTable";
import { logout } from "../../modules/user/api/actions";
// Helper methods:
import { formatMoney } from "../../setup/helpers";
import AnalyticsRange_DD from "./AnalyticsRange_DD"; // Dropdown to set the scope of the analytics data
import {useHistory} from 'react-router-dom';

const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  percentDif: {
    textAlign: "right",
    color: "green",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  loadingIcon: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  padding: {
    paddingTop: 10,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return <div {...other}>{value === index && <Box>{children}</Box>}</div>;
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const BillingDashboard = (props) => {
  const classes = useStyles();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [value, setValue] = useState(0);
  const [tabLabels, setTabLabels] = useState(['SQFT', 'BILL', 'RUBS']);
  const [billingType, setBillingType] = useState('')

  const [queryDelay, setQueryDelay] = useState(
    props.match.params.querydelay || null
  );
  const [isLoaded, setIsLoaded] = useState(false);
  const [delayedIsLoaded, setDelayedIsLoaded] = useState(false);

  const logout = () => {
    props.logout();
  };

  const history = useHistory();

  const queryParams = new URLSearchParams(window.location.search);
  const currentParam = queryParams.get('billingType')

  useEffect(() => {
    const initialTabIndex = currentParam ? tabLabels.indexOf(currentParam?.toUpperCase()) : 0;
    setValue(initialTabIndex);
    setBillingType(tabLabels[initialTabIndex])
  }, [currentParam, tabLabels]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    const selectedTabLabel = tabLabels[newValue];
    setBillingType(selectedTabLabel);
    history.push(`/billingdashboard?billingType=${selectedTabLabel}`);
  };

  const getCompanyBillingDataAndRefreshPage = (isLoading = true) => {
    props.getAllBillingDashboardInfo(isLoading);
    props.getAllCompanyBillingAnalytics(isLoading);
  };

  // On initial page load:
  useEffect(() => {
    if (props.user.details.isSuperUser ||props.user.details.billing || props.user.details.administration) {
      if (!isLoaded) {
        getCompanyBillingDataAndRefreshPage();
        setIsLoaded(true);
      }
      if (queryDelay != null) {
        const interval = setInterval(() => {
          if (!delayedIsLoaded) {
            getCompanyBillingDataAndRefreshPage(false);
            clearInterval(interval);
            setDelayedIsLoaded(true);
          }
        }, queryDelay);
        //return () =>
      }
    }
  }, [queryDelay]);
  var pageTitle = "Billing Dashboard";
  document.title = "Ubil+ Billing Dashboard";
  // On Data Loaded:
  return (
    <NavFrame page={"BillingDashboard"}>
      <StandardHeader
        title={pageTitle}
        rightSideToolBar={
          !!(props.user.details.billing || props.user.details.administration || props.user.details.isSuperUser)  && 
            <AnalyticsRange_DD
              handleRefreshClick={getCompanyBillingDataAndRefreshPage}
            />
          
        }
      />
      <Divider className="mb-3 mt-3" />
      {props.user.details.billing || props.user.details.administration || props.user.details.isSuperUser ? (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <SimpleStatCard
              title="Properties Billed"
              amount={
                props.billing.data.billingWizardDashboard.propertiesBilled ||
                "-"
              }
              chip="This Month"
              isLoaded={!props.billing.billAnalyticsIsLoading}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <SimpleStatCard
              title="Total $ "
              amount={
                formatMoney(
                  props.billing.data.billingWizardDashboard.totalCharged
                ) || "-"
              }
              chip="This Month"
              isLoaded={!props.billing.billAnalyticsIsLoading}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <SimpleStatCard
              title="Total Usage, CF"
              amount={props.billing.data.billingWizardDashboard.usageTotal || "0"}
              chip="This Month"
              isLoaded={!props.billing.billAnalyticsIsLoading}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3}>
            {/*<DropDownCard
            title="Remaining Properties to Bill this Month"
            amount={props.billing.data.billingWizardDashboard.estimatedUsage || "-"}
            chip="This Month"
            isLoaded={!props.billing.billAnalyticsIsLoading}
          />*/}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <AppBar position="static">
              <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="Billing Type Drafts and Batches Tabs"
                  variant="scrollable"
                  scrollButtons="auto"
              >
                {tabLabels.map((label, index) => (
                    <Tab key={index} label={label} {...a11yProps(index)} />
                ))}
              </Tabs>
            </AppBar>
            {tabLabels.map((tab, index) => (
                <TabPanel key={index} value={value} index={index}>
                  <Grid container spacing={2}  xs={12} className={classes.padding}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Card mb={6} elevation={3}>
                        <CardContent>
                          <CurrentBillDraftsManagerTable billingType={billingType}/>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Card mb={6} elevation={3}>
                        <CardContent>
                          <CurrentBillBatchesManagerTable billingType={billingType}/>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </TabPanel>
            ))}
          </Grid>
        </Grid>
      ) : (
        <h3>You do not have billing privileges</h3>
      )}
    </NavFrame>
  );
};

// Component Properties
BillingDashboard.propTypes = {
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  billing: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  getAllBillingDashboardInfo: PropTypes.func.isRequired,
  resetBillingObject: PropTypes.func.isRequired,
  getAllCompanyBillingAnalytics: PropTypes.func.isRequired,
};

// Component State
function BillingDashboardPageState(state) {
  return {
    user: state.user,
    apl: state.apl,
    billing: state.billing,
  };
}
export default connect(BillingDashboardPageState, {
  logout,
  getAnalyticalData,
  getAllBillingDashboardInfo,
  resetBillingObject,
  getAllCompanyBillingAnalytics,
})(BillingDashboard);
