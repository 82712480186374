import {
  Button as MuiButton,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components/macro";
import { getByUuid as getPropertyByUuid } from "../../../modules/property/api/actions";
import { txtBoxDateFormatter } from "../../../setup/helpers";
import {
  put as updateUtilityRiderRate,
  post as createUtilityRiderRate,
  updatePropertyUtilityLocalState,
  getByPropertyId as getPropertyUtility
} from "../api/actions";
import { UnsubscribeOutlined } from "@material-ui/icons";
import { setSnackbar } from "../../snackbar/api/snackbar";
import { post, put } from "../api/actions";

const TextField = styled(MuiTextField)(spacing);
const Select = styled(MuiSelect)(spacing);
const Button = styled(MuiButton)(spacing);

const PropertyUtilityForm = (props) => {
  const useStyles = makeStyles((theme) => ({
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    loadingIcon: {
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
    },
    selectedDropDownLabel: {
      backgroundColor: "#fffffff",
      borderColor: "#fffffff",
    },
  }));

  const billingTypes = ['SQFT', 'BILL', 'RUBS'];

  //form data
  const formData = {
    active: !!props.propertyUtilities.data.propertyUtility.active,
    zeroBalance: !!props.propertyUtilities.data.propertyUtility.zeroBalance,
    pMBalanceCollection: !!props.propertyUtilities.data.propertyUtility.pMBalanceCollection,
    customerInitiatedMoveIns: !!props.propertyUtilities.data.propertyUtility.customerInitiatedMoveIns,
    customerInitiatedMoveOuts: !!props.propertyUtilities.data.propertyUtility.customerInitiatedMoveOuts,
    chargeOwnerVacants: !!props.propertyUtilities.data.propertyUtility.chargeOwnerVacants,
    ccosPaysBill: !!props.propertyUtilities.data.propertyUtility.ccosPaysBill,
    vendorId: props.propertyUtilities.data.propertyUtility.vendorId,
    billingType: props.propertyUtilities.data.propertyUtility.billingType,
    vendorAcctNum: props.propertyUtilities.data.propertyUtility.vendorAcctNum,
    lastBillDate: props.propertyUtilities.data.propertyUtility.lastBillDate,
    serviceFee: props.propertyUtilities.data.propertyUtility.serviceFee,
    creditCardFee: props.propertyUtilities.data.propertyUtility.creditCardFee,
    processingFee: props.propertyUtilities.data.propertyUtility.processingFee,
    estimatedUsage: props.propertyUtilities.data.propertyUtility.estimatedUsage,
  };

  const [formFields, setFormFields] = React.useState(formData);

  const onFieldToggle = (field) => {
    setFormFields((prev) => ({
      ...prev,
      [field]: prev[field] === 1 ? 0 : 1,
    }));

    // props.propertyUtilities.data.propertyUtility[field] =
    //     formFields[field] === 1 ? 0 : 1;
  };

  const onFieldChange = (event) => {
    const { name, value, type } = event.target;
    setFormFields((prev)=> ({
      ...prev,
      [name]: type === "number" ? Number(value) : value,
    }))
    // if (event.target.type == "number") {
    //   props.propertyUtilities.data.propertyUtility[name] = Number(
    //     event.target.value
    //   );
    // } else {
    //   props.propertyUtilities.data.propertyUtility[event.target.name] =
    //     event.target.value;
    // }
    console.log(props.propertyUtilities.data.propertyUtility)
  };
  const onFieldExit = () => {
    props.updatePropertyUtilityLocalState(
      props.propertyUtilities.data.propertyUtility
    );
  };


  // const toggleServiceOuttage = () => {
  //   setServiceOuttage(!serviceOuttage);
  // };

  const toggleActive = () => {
    setActive(!active);
    props.propertyUtilities.data.propertyUtility.active = !active;
  };

  const [active, setActive] = React.useState(props.propertyUtilities.data.propertyUtility.active == 1 ? true : false);

  useEffect(() => { }, []);

  const dispatch = useDispatch();

  const classes = useStyles();

  const save = async () => {
    let newPropertyUtil = props.propertyUtilities.data.propertyUtility;
    if (!newPropertyUtil.id) {
       await props.createUtilityRiderRate(newPropertyUtil);
    } else {
      let propUtil = {
        ...formFields,
        //active: newPropertyUtil.active,
        //ownerId: newPropertyUtil.ownerId,
        //vendorId: newPropertyUtil.vendorId,
        //vendorAcctNum: newPropertyUtil.vendorAcctNum,
        //lastBillDate: newPropertyUtil.lastBillDate,
        //creditCardFee: newPropertyUtil.creditCardFee,
        //utilCustomerCharge: newPropertyUtil.utilCustomerCharge,
        //contractRate: newPropertyUtil.contractRate,
        //EVMeterRate: newPropertyUtil.EVMeterRate,
        uuid: newPropertyUtil.uuid,
        updated: new Date()
      }

      await props.updateUtilityRiderRate(propUtil);
      await props.getPropertyUtility(newPropertyUtil.propertyId)
    }

    props.onUpdate();
  };

  if (
    props.owners.isLoading ||
    props.vendors.isLoading ||
    props.propertyUtilities.propertyUtilityIsLoading ||
    !props.propertyUtilities.data.propertyUtility
  ) {
    return (
      <Card mb={6}>
        <CardContent>
          <Typography variant="h3" gutterBottom>
            Loading...
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardContent>
        <Grid container spacing={4}>
          <Grid item md={4} xs={6}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formFields.active}
                    onChange={()=>onFieldToggle('active')}
                    name="active"
                  />
                }
                label="Active"
              />
            </FormGroup>
          </Grid>
          <Grid item md={4} xs={6}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formFields.zeroBalance}
                    onChange={()=>onFieldToggle('zeroBalance')}
                    name="zeroBalance"
                  />
                }
                label="$0.00 Balances"
              />
            </FormGroup>
          </Grid>
          <Grid item md={4} xs={6}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formFields.pMBalanceCollection}
                    onChange={()=>onFieldToggle('pMBalanceCollection')}
                    name="PMBalanceCollection"
                  />
                }
                label="PM Balance Collection"
              />
            </FormGroup>
          </Grid>
          <Grid item md={4} xs={6}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                      checked={formFields.customerInitiatedMoveIns}
                      onChange={()=>onFieldToggle('customerInitiatedMoveIns')}
                    name="customerInitiatedMoveIns"
                  />
                }
                label="Customer Initiated Move Ins"
              />
            </FormGroup>
          </Grid>
          <Grid item md={4} xs={6}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formFields.customerInitiatedMoveOuts}
                    onChange={()=>onFieldToggle('customerInitiatedMoveOuts')}
                    name="customerInitiatedMoveOuts"
                  />
                }
                label="Customer Initiated Move Outs"
              />
            </FormGroup>
          </Grid>
          <Grid item md={4} xs={6}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formFields.chargeOwnerVacants}
                    onChange={()=>onFieldToggle('chargeOwnerVacants')}
                    name="chargeOwnerVacants"
                  />
                }
                label="Charge Owner Vacants"
              />
            </FormGroup>
          </Grid>
          <Grid item md={4} xs={6}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formFields.ccosPaysBill}
                    onChange={()=>onFieldToggle('ccosPaysBill')}
                    name="ccosPaysBill"
                  />
                }
                label="CCOS Pays Bills"
              />
            </FormGroup>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl fullWidth>
              <InputLabel id="selectedAccountStatusDropDownLabel">
                Vendor
              </InputLabel>
              <Select
                  className={classes.selectedDropDownLabel}
                  labelId="selectedAccountStatusDropDownLabel"
                  id="selectedAccountStatusDropDown"
                  defaultValue={
                    formData.vendorId
                  }
                  onChange={onFieldChange}
                  onBlur={onFieldExit}
                  name="vendorId"
              >
                {[props.vendors.data.vendorsList.items.map((item, index) => (
                    <MenuItem id="AccountStatusOption1" value={item.id}>
                      {item.name}
                    </MenuItem>
                ))]}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl fullWidth>
              <InputLabel id="selectedAccountStatusDropDownLabel">
                BILLING TYPE
              </InputLabel>
              <Select
                className={classes.selectedDropDownLabel}
                labelId="selectedBillingTypeDropDownLabel"
                id="selectedBillingTypeDropDown"
                defaultValue={formData.billingType || ''}
                onChange={onFieldChange}
                onBlur={onFieldExit}
                name="billingType"
              >
                {[billingTypes.map((item, index) => (
                  <MenuItem id="billingType" value={item}>
                    {item}
                  </MenuItem>
                ))]}
              </Select>
            </FormControl>
          </Grid>
          <Grid item sm={4} xs={6}>
            <TextField
              variant="outlined"
              className={classes.margin}
              defaultValue={
                formData.vendorAcctNum
              }
              onChange={onFieldChange}
              onBlur={onFieldExit}
              name="vendorAcctNum"
              fullWidth
              id="input-with-icon-textfield"
              label="Vendor Account Number"
            />
          </Grid>
          <Grid item sm={4} xs={6}>
            <TextField
              label="Last Bill Date"
              type="date"
              onChange={onFieldChange}
              onBlur={onFieldExit}
              defaultValue={
                props.propertyUtilities.data.propertyUtility
                  .lastBillDate !=
                props.propertyUtilities.data.propertyUtility
                  .defaultDateValue &&
                txtBoxDateFormatter(
                  props.propertyUtilities.data.propertyUtility
                    .lastBillDate
                )
              }
              name="lastBillDate"
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <TextField
              type="number"
              variant="outlined"
              className={classes.margin}
              defaultValue={
                  formData.serviceFee
              }
              fullWidth
              onChange={onFieldChange}
              onBlur={onFieldExit}
              id="input-with-icon-textfield"
              label="Service Fee"
              name="serviceFee"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <TextField
              type="number"
              variant="outlined"
              className={classes.margin}
              defaultValue={
                  formData.creditCardFee
              }
              fullWidth
              onChange={onFieldChange}
              onBlur={onFieldExit}
              id="input-with-icon-textfield"
              label="Credit Card Fee"
              name="creditCardFee"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <TextField
              type="number"
              variant="outlined"
              className={classes.margin}
              defaultValue={
                  formData.processingFee
              }
              fullWidth
              onChange={onFieldChange}
              onBlur={onFieldExit}
              id="input-with-icon-textfield"
              label="Processing Fee"
              name="processingFee"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <TextField
              type="number"
              variant="outlined"
              onChange={onFieldChange}
              onBlur={onFieldExit}
              className={classes.margin}
              defaultValue={
                  formData.estimatedUsage
              }
              name="estimatedUsage"
              fullWidth
              id="input-with-icon-textfield"
              label="Estimated Usage"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">CF</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid container item spacing={2} justify={"flex-end"}>
            <Grid item>
              <MuiButton variant="contained" onClick={save} color="secondary">
                Save
              </MuiButton>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

// Component Properties
PropertyUtilityForm.propTypes = {
  propertyUtilities: PropTypes.object.isRequired,
  owners: PropTypes.object.isRequired,
  vendors: PropTypes.object.isRequired,
  updateUtilityRiderRate: PropTypes.func.isRequired,
  createUtilityRiderRate: PropTypes.func.isRequired,
  getPropertyByUuid: PropTypes.func.isRequired,
  updatePropertyUtilityLocalState: PropTypes.func.isRequired,
  getPropertyUtility: PropTypes.func.isRequired
};

function PropertyUtilityFormState(state) {
  return {
    propertyUtilities: state.propertyUtilities,
    owners: state.owners,
    vendors: state.vendors,
    user: state.user,
    properties: state.properties
  };
}

export default connect(PropertyUtilityFormState, {
  updateUtilityRiderRate,
  createUtilityRiderRate,
  getPropertyUtility,
  getPropertyByUuid,
  updatePropertyUtilityLocalState,
})(PropertyUtilityForm);
