//React Components and Hooks
import {
  CircularProgress as MuiCircularProgress,
  Divider as MuiDivider,
  Grid,
  Typography as MuiTypography,
} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Snackbar from "@material-ui/core/Snackbar";
//Material UI Components and Functions
import { makeStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import MuiAlert from "@material-ui/lab/Alert";
import { spacing } from "@material-ui/system";
import { LeftSidebar } from "layout-blueprints";
// Redux Components
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import StandardHeader from "../../components/global/StandardHeader";
// Custom application component imports:
import { LeftSidebar as NavFrame } from "layout-blueprints"; // The top navigation bar and side navigation panel
import {
  getActiveUsers,
  getMessageSettings,
  getSettings,
  getPDFMessageSettings
} from "../../modules/settings/api/actions";
import ActiveUsers from "../../modules/settings/components/ActiveUsers";
// import CurrentBillDraftsManagerTable from '../../modules/billing/components/CurrentBillDraftsManagerTable';
// import CurrentBillBatchesManagerTable from '../../modules/billing/components/CurrentBillBatchesManagerTable';
// Helper methods:
import MessageSettingTable from "../../modules/settings/components/MessageSettingTable";
import Profile from "../../modules/settings/components/Profile";
import SettingsList from "../../modules/settings/components/Settings";
import { logout } from "../../modules/user/api/actions";
import PDFMessageSettingTable from "../../modules/settings/components/PDFMessageSettingTable";
const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  percentDif: {
    textAlign: "right",
    color: "green",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  loadingIcon: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

function Settings(props) {
  const classes = useStyles();

  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(
    "Error retrieving data"
  );

  const logout = () => {
    props.logout();
  };

  const query = new URLSearchParams(props.location.search);
  const tab = query.get('tab')

  const getMessageSettings = async () => {
    setIsLoaded(false);
    try {
      if (props.setting.items.length <= 0) await props.getMessageSettings();
      if (props.setting.settings.items.length <= 0) await props.getSettings();
      if (props.setting.activeUsers.items.length <= 0) await props.getActiveUsers();
      if (props.setting.pdfSettings.messages.items.length <= 0) await props.getPDFMessageSettings();
      if (props.setting.settings.items.length.length <= 0) {
        setIsLoaded(true);
      }
    } catch (error) {
      console.log(error);
      // setIsLoaded(true);
      // setErrorMessage(props.setting.error.toString())
      // setError(true);
    }
  };

  const [value, setValue] = React.useState(Number(tab) || 0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div {...other}>{value === index && <Box p={3}>{children}</Box>}</div>
    );
  }

  const handleClose = (event, reason) => {
    setError(false);
  };

  // On initial page load:
  useEffect(() => {
    setIsLoaded(false);
    getMessageSettings();
    getSettings();
  }, []);

  document.title = "UBIL+ Settings";
  // On Data Loaded:
  return (
    <LeftSidebar page="Settings">
      <StandardHeader title="Site Settings" />

      <Divider className="mb-3 mt-3" />

      <Snackbar autoHideDuration={6000} onClose={handleClose} open={error}>
        <Alert onClose={handleClose} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>

      <AppBar position="static">
        <Tabs
          aria-label="simple tabs example"
          onChange={handleChange}
          value={value}
        >
          <Tab label="Settings" />

          <Tab label="Users" />

          <Tab label="Profile" />

          <Tab label="Customer Site" />

          <Tab label="PDF" />
        </Tabs>
      </AppBar>

      <TabPanel index={0} value={value}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <SettingsList user={props.user} />
        </Grid>
      </TabPanel>

      <TabPanel index={1} value={value}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <ActiveUsers user={props.user} />
        </Grid>
      </TabPanel>

      <TabPanel index={2} value={value}>
        <Profile />
      </TabPanel>

      <TabPanel index={3} value={value}>
        <Grid container lg={12} md={12} sm={12} spacing={2} xs={12}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <MessageSettingTable />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel index={4} value={value}>
        <Grid container lg={12} md={12} sm={12} spacing={2} xs={12}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <PDFMessageSettingTable />
          </Grid>
        </Grid>
      </TabPanel>
    </LeftSidebar>
  );
}

// Component Properties
Settings.propTypes = {
  setting: PropTypes.object.isRequired,
  getMessageSettings: PropTypes.func.isRequired,
  getSettings: PropTypes.func.isRequired,
  getPDFMessageSettings: PropTypes.func.isRequired,
};

// Component State
function SettingsPageState(state) {
  return {
    setting: state.setting,
    user: state.user,
  };
}
export default connect(SettingsPageState, {
  logout,
  getMessageSettings,
  getActiveUsers,
  getSettings,
  getPDFMessageSettings,
})(Settings);
