import { LeftSidebar } from 'layout-blueprints'
import BillingFilesTable from 'modules/billing/components/BillingFilesTable'
import PropTypes from 'prop-types'
import React from 'react'
import styled, { withTheme } from "styled-components/macro";
import { connect } from 'react-redux'
import { spacing } from "@material-ui/system";
import StandardHeader from '../../components/global/StandardHeader';
import {
  Card, Tab, Tabs,
  Divider as MuiDivider,
  Typography as MuiTypography,
  CircularProgress as MuiCircularProgress,
  TextField as MuiTextField, Box,
} from "@material-ui/core";

const Divider = styled(MuiDivider)(spacing);



export const BillingPdfDownload = (props) => {
  return (
    <div>
      <LeftSidebar>
        <StandardHeader title="Billing Pdf Download"/>
          <Divider className="mb-3 mt-3" />
          <BillingFilesTable>
        </BillingFilesTable>
      </LeftSidebar>
    </div>
  )
}

BillingPdfDownload.propTypes = {
  apl: PropTypes.object.isRequired,
  billing: PropTypes.object.isRequired,
  getBillingPdfs: PropTypes.func.isRequired
}

const BillingPdfDownloadState = (state) => ({
  apl: state.apl,
  billing: state.billing
})



export default connect(BillingPdfDownloadState,{})(BillingPdfDownload)