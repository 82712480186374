import { GET_AGING, GET_AGING_RESPONSE, RESET } from "./actions";

const defaultDateValue = "01-01-1900";

const initialState = {
  isLoading: false,
  defaultDateValue: defaultDateValue,
  data: {
    // For the current accounts cached locally
    agingList: {
      items: [],
    },
    arList: {
      items: [],
    },
    searchQuery: {
      date: null,
      yearsBack: 1,
      dateAR: null,
      email: null,
    },
    range: { start: 0, end: 25 },
  },
};

// State
export default (state = initialState, action) => {
  var data = state.data;
  switch (action.type) {
    case RESET:
      return Object.assign({}, initialState);
    case GET_AGING:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case GET_AGING_RESPONSE:
      data[action.list] = {
        items: action.aging,
      };
      return {
        ...state,
        data: data,
        error: action.error,
        isLoading: false,
      };
    default:
      return {
        ...state,
      };
  }
};
