//React Components and Hooks
import {
  CircularProgress as MuiCircularProgress,
  Divider as MuiDivider,
  Grid,
  Typography as MuiTypography,
} from "@material-ui/core";
//Material UI Components and Functions
import { makeStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
// Redux Components
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import StandardHeader from "../../components/global/StandardHeader";
// Custom application component imports:
import { LeftSidebar as NavFrame } from "layout-blueprints"; // The top navigation bar and side navigation panel
import { searchAccountsByFieldAndValue } from "../../modules/accountsSearch/api/actions";
import AccountPreviewsTable from "../../modules/accountsSearch/components/AccountPreviewsTable";
import {
  getAllAccountStatusCodes,
  getAllCustomerTypes,
  getAllPropertyPreviews,
} from "../../modules/apl/api/actions";
import { logout } from "../../modules/user/api/actions";
import AdvanceFilterToolbar from "./AdvanceFilterToolbar"; // Dropdown to set the scope of the analytics data
import { LeftSidebar } from "layout-blueprints";
const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  percentDif: {
    textAlign: "right",
    color: "green",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  loadingIcon: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

const AccountsPage = (props) => {
  const classes = useStyles();
  const [currentDate, setCurrentDate] = useState(new Date());

  const logout = () => {
    props.logout();
  };

  const GetCurrentAccountsByExistingFilters = () => { };

  const RefreshPage = () => {
    GetCurrentAccountsByExistingFilters();
  };

  // On initial page load:
  useEffect(() => {
    RefreshPage();
    props.getAllCustomerTypes();
    props.getAllAccountStatusCodes();
    props.getAllPropertyPreviews();
    document.title = "Ubil+ Account Search";
  }, []);

  // On Data Loaded:
  return (
    <LeftSidebar>
      <StandardHeader
        title="Account Management"
      />
      <Divider className="mb-3 mt-3" />
      <Grid container spacing={1} xs={12} sm={12} md={12} lg={12}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <AccountPreviewsTable minAccountObj={true} clickToSearch={true} />
        </Grid>
      </Grid>
    </LeftSidebar>
  );
};

/* 
<Grid item xs={12} sm={12} md={12} lg={5} spacing={3}>
    <AccountsByPropertyTable/>
</Grid>
*/
// Component Properties
AccountsPage.propTypes = {
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  billing: PropTypes.object.isRequired,
  accountsSearch: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  searchAccountsByFieldAndValue: PropTypes.func.isRequired,
  getAllPropertyPreviews: PropTypes.func.isRequired,
  getAllAccountStatusCodes: PropTypes.func.isRequired,
  getAllCustomerTypes: PropTypes.func.isRequired,
};

// Component State
function AccountsPageState(state) {
  return {
    user: state.user,
    apl: state.apl,
    billing: state.billing,
    accountsSearch: state.accountsSearch,
  };
}
export default connect(AccountsPageState, {
  logout,
  searchAccountsByFieldAndValue,
  getAllPropertyPreviews,
  getAllCustomerTypes,
  getAllAccountStatusCodes,
})(AccountsPage);
