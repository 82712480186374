//React Components and Hooks
import {
  Card as MuiCard,
  CardContent,
  CircularProgress as MuiCircularProgress,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField as MuiTextField,
  Typography,
  Button
} from "@material-ui/core";
//Material UI Components and Functions
import { makeStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
// Redux Components
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components/macro";
import {
  getAllMeterReadTypes
} from "../../apl/api/actions";
import { setSnackbar } from "../../snackbar/api/snackbar";

import {
  createNewMeterReading,
  lookupMeterHistory
} from "../../meter/api/actions";

const CenteredContent = styled.div`
  text-align: center;
`;
const CircularProgress = styled(MuiCircularProgress)(spacing);
const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const FormControl = styled(MuiFormControl)(spacing);
const TextField = styled(MuiTextField)(spacing);

// Custom Styles for property selection drop down

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dropDownLabel: {
    backgroundColor: "#000000",
  },
  loadingIcon: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  dropDownLoadingIcon: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

const NewMeterReadForm = (props) => {
  const [meterReadingType, setmeterReadingType] = React.useState(15);
  const [SelectedMeter, setSelectedMeter] = React.useState("");

  const [finalRead, setFinalRead] = React.useState(0);
  const [firstRead, setFirstRead] = React.useState(0);
  const dispatch = useDispatch();


  // Updates the local state object of "bill in progress" upon each modification
  const onFieldChange = (event) => {
    if (event.target.type == "number") {
      props.meters.newMeterRead[event.target.name] = Number(event.target.value);
    } else {
      props.meters.newMeterRead[event.target.name] = event.target.value;
    }
  };

  const handleSelectedMeterChange = (event) => {
    props.meters.newMeterRead.meterId = event.target.value;
    setSelectedMeter(event.target.value);
  };

  const handlemeterReadingTypeChange = (event) => {
    props.meters.newMeterRead.meterReadTypeId = event.target.value;
    setmeterReadingType(event.target.value);
  };

  const onToggleChange = (event) => {
    if (event.target.name == "finalRead") {
      props.meters.newMeterRead.finalRead = event.target.checked;
      setFinalRead(event.target.checked);
      if (event.target.checked) {
        props.meters.newMeterRead.firstRead = !event.target.checked;
        setFirstRead(!event.target.checked);
      }
    } else {
      props.meters.newMeterRead.firstRead = event.target.checked;
      setFirstRead(event.target.checked);
      if (event.target.checked) {
        props.meters.newMeterRead.finalRead = !event.target.checked;
        setFinalRead(!event.target.checked);
      }
    }
  };

  const handleSaveClick = async () => {
    dispatch(
      setSnackbar(true, "warning", "Submitting New Account Meter Reading...")
    );
    // Handle the account object updating
    //const data = props.account.data;
    let response = await props.createNewMeterReading(props.meters.newMeterRead)
      console.log(response)
    if(response.status == 200) {
      dispatch(
        setSnackbar(
          true,
          "success",
          "Meter Reading Creation Successful"
        )
      );
      await  props.lookupMeterHistory(props.meters.data.meter.id)
    } else {
      dispatch(
        setSnackbar(
          true,
          "error",
          "Error on Meter Reading Creation"
        )
      );
    }


   

  };


  // On initial page load:
  useEffect(() => {
    props.getAllMeterReadTypes();
  }, []);

  const classes = useStyles();
  return (
    <Card mb={6} elevation={3}>
      <CardContent>
        {/* Main Grid */}
        <Grid container spacing={4}>
          {/*             Col 1            */}
          <Grid item lg={6} md={12} sm={12}>
            <FormControl fullWidth my={2}>
              <InputLabel id="selectedMeterDropDownLabel" my={2}>
                Meter Number
              </InputLabel>
              {props.account.meterLookupIsLoading ? (
                <Select
                  labelId="selectedMeterDropDownLabel"
                  id="selectedMeterDropDown"
                  onChange={handleSelectedMeterChange}
                  fullWidth
                  disabled
                  my={2}
                >
                </Select>
              ) : (
                <Select
                  labelId="selectedMeterDropDownLabel"
                  id="selectedMeterDropDown"
                  value={SelectedMeter}
                  onChange={handleSelectedMeterChange}
                  fullWidth
                  my={2}
                >
                  {props.meters.data.metersList &&
                    props.meters.data.metersList.items.length > 0 ? [
                    props.meters.data.metersList.items.map((item, key) => {
                      return (
                        <MenuItem value={item.id} id={key}>
                          {item.meterNum}
                        </MenuItem>
                      );
                    })
                  ] : [
                    <MenuItem value={props.meters.data.meter.id} id={props.meters.data.meter.uuid}>
                    {props.meters.data.meter.meterNum}
                  </MenuItem>
                  ]}
                </Select>
              )}
              
             
            </FormControl>
            <TextField
              my={2}
              fullWidth
              type="number"
              id="readAmount"
              label="Read Ammount"
              defaultValue={props.meters.newMeterRead.readAmount}
              onChange={onFieldChange}
              name="readAmount"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          {/*             Col 2            */}
          <Grid item lg={6} md={12} sm={12}>
            <FormControl fullWidth my={2}>
              <InputLabel id="readingTypeDropDownLabel" my={2}>
                Read Type
              </InputLabel>
              {props.account.meterLookupIsLoading ||
                props.apl.genericTypes.meterReadingType.isLoading ? (
                <Select
                  labelId="readingTypeDropDownLabel"
                  id="readingTypeDropDown"
                  onChange={handlemeterReadingTypeChange}
                  fullWidth
                  disabled
                  my={2}
                >
                </Select>
              ) : (
                <Select
                  labelId="readingTypeDropDownLabel"
                  id="readingTypeDropDown"
                  value={meterReadingType}
                  onChange={handlemeterReadingTypeChange}
                  fullWidth
                  my={2}
                >
                  {props.apl.genericTypes.meterReadingType.data.length > 0 ? [
                    props.apl.genericTypes.meterReadingType.data.map(
                      (item, key) => {
                        return (
                          <MenuItem value={item.id} id={key}>
                            {item.description}
                          </MenuItem>
                        );
                      }
                    )
                  ] : [
                    <MenuItem value={-1}>No Meter Reading Types Found</MenuItem>
                  ]}
                </Select>
              )}
            </FormControl>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              xs={12}
            >
              <Grid item xs={3}>
                <Switch
                  name="finalRead"
                  checked={Boolean(props.meters.newMeterRead.finalRead)}
                  onChange={onToggleChange}
                />
              </Grid>
              <Grid item xs={9}>
                <Typography variant="body1">Final Read</Typography>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              xs={12}
            >
              <Grid item xs={3}>
                <Switch
                  name="firstRead"
                  checked={Boolean(props.meters.newMeterRead.firstRead)}
                  onChange={onToggleChange}
                />
              </Grid>
              <Grid item xs={9}>
                <Typography variant="body1">First Read</Typography>
              </Grid>
            </Grid>
            <Grid item xs={9}>
            <TextField
                    label="Read Date"
                    type="date"
                    onChange={onFieldChange}
                    name="readDate"
                    //required
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
            </Grid>
          </Grid>
          <Grid item lg={6} md={12} sm={12}>
              <Button
                mr={2}
                mb={2}
                variant="contained"
                color="secondary"
                onClick={props.handleSaveClick}
              >
                Submit Meter Reading
              </Button>
            </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
// Component Properties
NewMeterReadForm.propTypes = {
  // Store objects:
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  meters: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  getAllMeterReadTypes: PropTypes.func.isRequired,
  createNewMeterReading: PropTypes.func.isRequired,
  lookupMeterHistory: PropTypes.func.isRequired


};

// Component State
function NewMeterReadFormState(state) {
  return {
    user: state.user,
    account: state.account,
    apl: state.apl,
    meters: state.meters,

  };
}
export default connect(NewMeterReadFormState, {getAllMeterReadTypes, createNewMeterReading,lookupMeterHistory})(withRouter(NewMeterReadForm));
