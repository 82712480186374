import {
  Box,
  Button as MuiButton,
  Card,
  CardContent,
  CircularProgress as MuiCircularProgress,
  Divider as MuiDivider,
  Grid,
  LinearProgress as MuiLinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  Link,
  TableRow,
  Typography,
} from "@material-ui/core";
//Material UI Icons
import Avatar from "@material-ui/core/Avatar";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import BusinessIcon from "@material-ui/icons/Business";
import ChatIcon from "@material-ui/icons/Chat";
import RepeatIcon from "@material-ui/icons/Repeat";
import SettingsIcon from "@material-ui/icons/Settings";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import Skeleton from "@material-ui/lab/Skeleton";
import { spacing } from "@material-ui/system";
// Redux Components
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import AccountNewMoveOutForm from "../../../moves/components/AccountNewMoveOutForm";
import TransferUnitForm from "../../../moves/components/TransferUnitForm";
import { connect } from "react-redux";
//import { login, logout } from '../../modules/user/api/actions';
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import styles from "../styling/accountManager";
import {
  getById as getOwnerById,
  get as getOwners,
} from "../../../owner/api/actions";

const LinearProgress = MuiLinearProgress;
const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledNavMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const SelectedAccountPropertyInformationCard = (props) => {
  const useStyles = styles;
  const classes = useStyles();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [MoveOutFormOpen, setMoveOutFormOpen] = React.useState(false);
  const [TransferUnitFormOpen, setTransferUnitFormOpen] = React.useState(false);
  const [unitOwner, setUnitOwner] = React.useState(null);

  const isAdmin =
    props.user.details.administration || props.user.details.isSuperUser
      ? true
      : false;

  const handleMessagePropertyManagerClick = () => {
    console.log("Message Property Manager Clicked");
    console.log(props.account.data.propertyManager);
    window.open(
      `mailto:${props.account.data.propertyManager.email}?subject=CCOS%20Account%20Message%20-%20${props.account.data.account.accountNum}&body=`
    );
  };

  const handleMoveOutFormOpenClick = () => {
    setMoveOutFormOpen(true);
  };
  const handleMoveOutFormClose = () => {
    setMoveOutFormOpen(false);
  };

  const handleTransferUnitFormOpenClick = () => {
    setTransferUnitFormOpen(true);
  };
  const handleTransferUnitFormClose = () => {
    setTransferUnitFormOpen(false);
  };
  const handleRefreshPage = () => {
    if (props.refreshPage) {
      props.refreshPage();
    }
  };
  const handleAccountPropertyDropDownClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAccountPropertyDropDownClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    props.getOwners().then(() => {
      console.log("meters:", props.account.data.unit?.meters)
      setUnitOwner(
        props.owners.data.ownersList?.items?.find(
          (owner) => owner.id === props.account.data.unit?.ownerId
        )
      );
    });
  }, [props.account.data.unit]);

  const onManagePropertySelected = () => {
    window.open(
      "/property/" +
        props.account.data.property.uuid +
        "/" +
        props.account.data.property.id +
        "/" +
        props.account.data.property.propertyManagerId
    );
  };

  const onSeeUnitHistorySelected = () => {
    window.open(
      "/unit/" +
        props.account.data.unit.uuid +
        "/" +
        props.account.data.unit.id +
        "/" +
        props.account.data.property.propertyManagerId
    );
  };

  return (
    <Card mb={6}>
      <CardContent xs={12} sm={12} md={12} lg={12}>
        <Grid container xs={12} sm={12} md={12} lg={12}>
          <Grid item xs={9} sm={9} md={9} lg={9}>
            <Typography variant="h6" gutterBottom>
              Account Property Summary
            </Typography>
          </Grid>
          <Grid
            item
            xs={3}
            sm={3}
            md={3}
            lg={3}
            className={classes.rightSideHeaderSubMenuToolBar}
          >
            {!props.account.isLoading && props.account.data.property.id ? (
              <Button
                onClick={handleAccountPropertyDropDownClick}
                aria-controls="userProfiles-act-settings"
                aria-haspopup="true"
              >
                <SettingsIcon
                  fontSize="medium"
                  className={classes.SubMenuToolBarIcon}
                />
              </Button>
            ) : (
              <Button
                disabled
                onClick={handleAccountPropertyDropDownClick}
                aria-controls="userProfiles-act-settings"
                aria-haspopup="true"
              >
                <SettingsIcon
                  fontSize="medium"
                  className={classes.SubMenuToolBarIcon}
                />
              </Button>
            )}
            <ClickAwayListener onClickAway={handleAccountPropertyDropDownClose}>
              <StyledMenu
                id="userProfiles-act-settings"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleAccountPropertyDropDownClose}
              >
                {/*List Item*/}
                {/*
                  <StyledNavMenuItem onClick={onManagePropertySelected}>
                  <ListItemIcon>
                    <BusinessIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Manage Property" />
                </StyledNavMenuItem>
                */}
                {/*List Item*/}
                <StyledNavMenuItem onClick={handleMessagePropertyManagerClick}>
                  <ListItemIcon>
                    <ChatIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Message Property Manager" />
                </StyledNavMenuItem>
                {/*List Item*/}
                {/*!props.account.isLoading &&
                  props.account.data.unit &&
                  props.account.data.unit.id && (
                    <StyledNavMenuItem onClick={onSeeUnitHistorySelected}>
                      <ListItemIcon>
                        <FolderOpenIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="See Unit History" />
                    </StyledNavMenuItem>
                  )*/}
                {props.account.data.account && (
                  <>
                    {props.account.data.account.customerType !=
                      "Management Co" &&
                    props.account.data.account.customerType != "Common Area" ? (
                      <>
                        {!props.account.data.move.moveOut.id ? ( // checks and see if a move out already exists for the account before giving the option
                          <StyledNavMenuItem
                            onClick={handleMoveOutFormOpenClick}
                          >
                            <ListItemIcon>
                              <RepeatIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Move Out Tenant" />
                          </StyledNavMenuItem>
                        ) : (
                          <StyledNavMenuItem disabled>
                            <ListItemIcon>
                              <RepeatIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText
                              primary="Move Out Tenant"
                              secondary="Cannot Move out Account. Processing or Customer has been moved out."
                            />
                          </StyledNavMenuItem>
                        )}
                      </>
                    ) : (
                      <StyledNavMenuItem disabled>
                        <ListItemIcon>
                          <RepeatIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary="Move Out Tenant"
                          secondary="Cannot Move out Default Pm Account"
                        />
                      </StyledNavMenuItem>
                    )}
                  </>
                )}
                <StyledNavMenuItem
                  onClick={handleTransferUnitFormOpenClick}
                  disabled={!isAdmin}
                >
                  <ListItemIcon>
                    <SwapHorizIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Change Account Unit" />
                </StyledNavMenuItem>
              </StyledMenu>
            </ClickAwayListener>
          </Grid>
        </Grid>
        <Divider className="mb-2 mt-2" my={1} />
        {props.account.isLoading ? (
          <>
            <Skeleton
              variant="rect"
              height="40px"
              style={{
                backgroundColor: `#bdbfbe`,
                padding: 6,
                margin: 6,
                marginBottom: 3,
              }}
            />
            <Skeleton
              variant="rect"
              height="40px"
              style={{
                backgroundColor: `#bdbfbe`,
                padding: 6,
                margin: 6,
                marginBottom: 3,
              }}
            />
            <Skeleton
              variant="rect"
              height="40px"
              style={{
                backgroundColor: `#bdbfbe`,
                padding: 6,
                margin: 6,
                marginBottom: 3,
              }}
            />
            <Skeleton
              variant="rect"
              height="40px"
              style={{
                backgroundColor: `#bdbfbe`,
                padding: 6,
                margin: 6,
                marginBottom: 3,
              }}
            />
            <Skeleton
              variant="rect"
              height="40px"
              style={{
                backgroundColor: `#bdbfbe`,
                padding: 6,
                margin: 6,
                marginBottom: 3,
              }}
            />
          </>
        ) : !props.account.isLoading && props.account.data.property.id ? (
          <Grid
            container
            spacing={2}
            lg={12}
            className={classes.AccountInfoGrid}
          >
            <Grid item xs={12} lg={12}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableBody>
                    {props.account.data.unit ? (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography
                            variant="body1"
                            style={{ fontWeight: "bold" }}
                          >
                            Service Address
                          </Typography>
                          <Typography
                            variant="body1"
                            style={{ fontStyle: "italic" }}
                          >
                            (Unit)
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Link
                            target="_blank"
                            href={`unit/${props.account.data.unit.uuid}`}
                            color="inherit"
                          >
                            <Typography
                              variant="body2"
                              style={{ fontWeight: "bold" }}
                            >
                              {props.account.data.unit.address1 != "" &&
                                props.account.data.unit.address1 + ","}
                            </Typography>
                            <Typography
                              variant="body2"
                              style={{ fontWeight: "bold" }}
                            >
                              {props.account.data.unit.address2 != "" &&
                                props.account.data.unit.address2 != null &&
                                props.account.data.unit.address2 + ","}
                            </Typography>
                            <Typography
                              variant="body2"
                              style={{ fontWeight: "bold" }}
                            >
                              {props.account.data.unit.city != "" &&
                                props.account.data.unit.city + ","}
                            </Typography>
                            <Typography
                              variant="body2"
                              style={{ fontWeight: "bold" }}
                            >
                              {props.account.data.unit.state != "" &&
                                props.account.data.unit.state + ", "}
                              {props.account.data.unit.zip != "" &&
                                props.account.data.unit.zip}
                            </Typography>
                          </Link>
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography
                            variant="body1"
                            style={{ fontWeight: "bold" }}
                          >
                            Service Address
                          </Typography>
                          <Typography
                            variant="body1"
                            style={{ fontStyle: "italic" }}
                          >
                            (Property)
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="body2"
                            style={{ fontWeight: "bold" }}
                          >
                            {props.account.data.property.city != "" &&
                              props.account.data.property.city + ","}
                          </Typography>
                          <Typography
                            variant="body2"
                            style={{ fontWeight: "bold" }}
                          >
                            {props.account.data.property.state != "" &&
                              props.account.data.property.state + ", "}
                            {props.account.data.property.zip != "" &&
                              props.account.data.property.zip}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography
                          variant="body1"
                          style={{ fontWeight: "bold" }}
                        >
                          Property Name
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">
                          {props.account.data.property.name}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography
                          variant="body2"
                          style={{ fontWeight: "bold" }}
                        >
                          Meter #
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {props.account.data.unit.meters &&
                        props.account.data.unit.meters.length > 0 ? (
                          <>
                            {props.account.data.unit.meters?.map(
                              (meter, index) => {
                                return (
                                  <Typography variant="body2">
                                    {meter.meterNum}
                                  </Typography>
                                );
                              }
                            )}
                          </>
                        ) : props.account.data?.property?.propertyUtility?.billingType === 'SQFT'
                          ? "SQFT Billing"
                          : props.account.data?.property?.propertyUtility?.billingType === 'RUBS'
                            ?"Flat Rate Water" : ''
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography
                          variant="body2"
                          style={{ fontWeight: "bold" }}
                        >
                          ERT #
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {(props.account.data.unit.meters &&
                        props.account.data.unit.meters.length > 0) ? (
                          <>
                            {props.account.data.unit.meters?.map(
                              (meter, index) => {
                                return (
                                  <Typography variant="body2">
                                    {meter.ERTNum}
                                  </Typography>
                                );
                              }
                            )}
                          </>
                        ) : props.account.data?.property?.propertyUtility?.billingType === 'SQFT'
                          ? "SQFT Billing"
                          : props.account.data?.property?.propertyUtility?.billingType === 'RUBS'
                          ?"Flat Rate Water" : ''
                        }
                        
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography
                          variant="body2"
                          style={{ fontWeight: "bold" }}
                        >
                          Property Manager(s)
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {props.account.data.propertyManager?.name}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography
                          variant="body2"
                          style={{ fontWeight: "bold" }}
                        >
                          Unit Owner
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {unitOwner?.name || "No Unit Owner"}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            direction="column"
            alignItems="center"
            justify="center"
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              spacing={1}
              direction="row"
            >
              <Avatar
                className={classes.AvatarIcon}
                variant="rounded"
                direction="column"
                alignItems="center"
                justify="center"
              >
                <BusinessIcon className={classes.AvatarBusinessIcon} />
              </Avatar>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} spacing={1}>
              <Typography
                variant="h6"
                component="div"
                gutterBottom
                className={classes.UserBoxText}
              >
                <Box fontWeight="fontWeightMedium">
                  Account Property Not Found
                </Box>
              </Typography>
              <Typography
                variant="body1"
                component="div"
                gutterBottom
                className={classes.UserBoxText}
              >
                <Box fontWeight="fontWeightRegular">
                  (No associated account property)
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} spacing={1}>
              <Button
                className="mb-3 mt-3 mr-3"
                variant="contained"
                color="secondary"
                onClick={handleTransferUnitFormOpenClick}
              >
                Transfer Account Into Vacant Unit
              </Button>
            </Grid>
          </Grid>
        )}
      </CardContent>
      <Dialog
        open={MoveOutFormOpen}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth={"xl"}
        keepMounted
        onClose={handleMoveOutFormClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <AccountNewMoveOutForm
            refreshPage={handleRefreshPage}
            onClose={handleMoveOutFormClose}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={TransferUnitFormOpen}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth={"xl"}
        keepMounted
        onClose={handleTransferUnitFormClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <TransferUnitForm
            isOpen={TransferUnitFormOpen}
            onClose={handleTransferUnitFormClose}
          />
        </DialogContent>
      </Dialog>
    </Card>
  );
};

// Component Properties
SelectedAccountPropertyInformationCard.propTypes = {
  // Store objects:
  account: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  owners: PropTypes.object.isRequired,
  getOwnerById: PropTypes.func.isRequired,
  getOwners: PropTypes.func.isRequired,
};

// Component State
function SelectedAccountPropertyInformationCardState(state) {
  return {
    account: state.account,
    apl: state.apl,
    user: state.user,
    owners: state.owners,
  };
}
export default connect(
  SelectedAccountPropertyInformationCardState,

  {
    getOwnerById,
    getOwners,
  }
)(SelectedAccountPropertyInformationCard);
