import React from "react";
import styled from "styled-components/macro";
import {
  Grid,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Paper as MuiPaper,
  MenuItem,
  TextField as MuiTextField,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  CircularProgress as MuiCircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  IconButton,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { spacing } from "@material-ui/system";
import { EditableTable } from "../../../components/EditableTable";

// Redux Components
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { get as getProperties, updateFilters, updatePropertyCommonChargesStoreObject, createPropertyCommonCharge, updatePropertyCommonCharges, deletePropertyCommonCharges } from "../api/actions";

const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function CommonAreaExpenseTable(props) {
  // Table Stuff:
  const fieldTitles = [
    "Expense Name",
    "Cost Per Account (USD)",
    "Active"
  ];
  const fieldTypes = ["text", "number", "switch"];
  const fieldNames = [
    "expenseName",
    "expenseValue",
    "active",
  ];

  const canAddOrUpdate = (props.user.details.billing || props.user.details.administration || props.user.details.isSuperUser) ? true : false;


  const onSelected = (item) => {
  };

  const [display, setDisplay] = React.useState(false);


  const createLineItemData = (id, propertyId, expenseName, expenseValue, active) => {
    return { id, propertyId, expenseName, expenseValue, active };
  }

  const createBlankLineItem = () => {
    return createLineItemData(-1, props.propertyId, "New Expense", 0, 0);
  }

  const addBlankItemToArray = () => {
    props.properties.propertyAdditionalCommonAreaExpenses.items.push(createBlankLineItem());
    props.updatePropertyCommonChargesStoreObject(props.properties.propertyAdditionalCommonAreaExpenses);
  }

  const updateLocalItemsValues = (items) => {
    // Replaces existing store object with new object with modified sub data
    props.updatePropertyCommonChargesStoreObject(items);
  }

  // To be used to save over the api in the database
  const saveAllLineItems = (commonChages) => {
    commonChages.toUpdate.map(targetUuid => {
      let targetItemIndex = commonChages.items.findIndex(x => x.uuid === targetUuid);
      if (targetItemIndex != -1) {
        if (commonChages.items[targetItemIndex].id != -1) {
          props.updatePropertyCommonCharges(commonChages.items[targetItemIndex]);
        }
      }
    });
    commonChages.toDelete.map(targetUuid => {
      props.deletePropertyCommonCharges(targetUuid);
    });
    commonChages.items.map((commonChage) => {
      if (commonChage.id == -1) {
        delete commonChage.id;
        props.createPropertyCommonCharge(commonChage);
      }
    });
  }

  return (
    <Card mb={6} elevation={3}>
      <CardContent>
        <EditableTable
          readOnly={!canAddOrUpdate}
          canSave={canAddOrUpdate}
          canAddItems={canAddOrUpdate}
          isLoading={props.properties.propertyAdditionalCommonAreaExpensesIsLoading}
          newItemCreationMethod={addBlankItemToArray}
          saveCurrentTableData={saveAllLineItems}
          updateItemsLocalState={updateLocalItemsValues}
          tableTitle={"Common Area Expenses"}
          objectArray={props.properties.propertyAdditionalCommonAreaExpenses}
          fieldTitles={fieldTitles}
          fieldTypes={fieldTypes}
          fieldNames={fieldNames}
        />
      </CardContent>
    </Card>
  );
}

CommonAreaExpenseTable.propTypes = {
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  properties: PropTypes.object.isRequired,
  getProperties: PropTypes.func.isRequired,
  updatePropertyCommonChargesStoreObject: PropTypes.func.isRequired,
  createPropertyCommonCharge: PropTypes.func.isRequired,
  updatePropertyCommonCharges: PropTypes.func.isRequired,
  deletePropertyCommonCharges: PropTypes.func.isRequired,
};

function CommonAreaExpenseTableState(state) {
  return {
    user: state.user,
    apl: state.apl,
    search: state.search,
    properties: state.properties,
  };
}

export default connect(CommonAreaExpenseTableState, { getProperties, updatePropertyCommonChargesStoreObject, createPropertyCommonCharge, updatePropertyCommonCharges, deletePropertyCommonCharges })(CommonAreaExpenseTable);
