import {
  Card,
  CardContent,
  Grid,
  Select as MuiSelect,
  TextField as MuiTextField,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { spacing } from "@material-ui/system";
import React, { useState } from "react";
import styled from "styled-components/macro";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {useSelector} from "react-redux";

const Select = styled(MuiSelect)(spacing);
const TextField = styled(MuiTextField)(spacing);

export default (props) => {
  const owner = props.owner;
  const ownerList = useSelector(state => state.owners.data.ownersList);
  const handleClose = props.handleClose;
  const submitNewOwner = props.submitNewOwner;

  const [editOwnerObj, setEditOwnerObj] = useState({});
  const [editOwnerAddrObj, setEditOwnerAddrObj] = useState({});
  const [isGlCodeExists, setIsGlCodeExists] = useState(false);

  const onOwnerFieldChange = (e) => {
    const newValue = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setEditOwnerObj(
      Object.assign({}, editOwnerObj, { [e.target.name]: newValue })
    );
    if (e.target.name === 'glCode' && newValue) {
      setIsGlCodeExists(ownerList.items.some(owner => owner.glCode === newValue.trim()))
    }
  };

  const onAddrFieldChange = (e) => {
    setEditOwnerAddrObj(
      Object.assign({}, editOwnerAddrObj, { [e.target.name]: e.target.value })
    );
  };

  const action = async () => {
    let editObj = Object.assign(
      {},
      { addressInfo: editOwnerAddrObj },
      { ownerInfo: editOwnerObj }
    );
    if (!isGlCodeExists) {
      await submitNewOwner(editObj);
    }
  };

  return (
    <div>
      <Card>
        <CardContent>
          {/* Owner Details */}
          <Grid container spacing={2}>
            <h3>Owner Info:</h3>
            <Grid container item spacing={2}>
              <Grid item sm={6} xs={12}>
                <TextField
                  label="Owner Name"
                  type="text"
                  onChange={onOwnerFieldChange}
                  name="name"
                  //required
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  label="Contact Name"
                  type="text"
                  onChange={onOwnerFieldChange}
                  name="contactName"
                  //required
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  label="Address 1"
                  type="text"
                  onChange={onAddrFieldChange}
                  name="address1"
                  //required
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  label="Address 2"
                  type="text"
                  onChange={onAddrFieldChange}
                  name="address2"
                  //required
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  label="City"
                  type="text"
                  onChange={onAddrFieldChange}
                  name="city"
                  //required
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item sm={3} xs={6}>
                <TextField
                    label="State"
                    type="text"
                    onChange={onAddrFieldChange}
                    name="state"
                    //required
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                />
              </Grid>
              <Grid item sm={3} xs={6}>
                <TextField
                    label="Zip"
                    type="text"
                    onChange={onAddrFieldChange}
                    name="zip"
                    //required
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                    label="Phone"
                    type="text"
                    onChange={onAddrFieldChange}
                    name="phone"
                    //required
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                    label="Email"
                    type="text"
                    onChange={onAddrFieldChange}
                    name="email"
                    //required
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                    label="Accounting ID"
                    error={isGlCodeExists}
                    type="text"
                    onChange={onOwnerFieldChange}
                    name="glCode"
                    helperText={isGlCodeExists ? 'This Accounting ID is already in use.' : ''}
                    //required
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                />
              </Grid>
              <Grid item sm={3} xs={6}>
                <FormControlLabel
                    control={
                      <Checkbox
                          defaultChecked={false}
                          onChange={onOwnerFieldChange}
                          name="active"
                          value="Active"
                      />
                    }
                    label="Active"
                />
              </Grid>
            </Grid>


            {/*<Grid item sm={3} xs={6}>*/}
            {/*  <TextField*/}
            {/*    label="Customer Service Amount"*/}
            {/*    type="number"*/}
            {/*    onChange={onOwnerFieldChange}*/}
            {/*    name="customerServiceAmount"*/}
            {/*    //required*/}
            {/*    fullWidth*/}
            {/*    variant="outlined"*/}
            {/*    InputLabelProps={{*/}
            {/*      shrink: true,*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</Grid>*/}
            {/*<Grid item sm={3} xs={6}>*/}
            {/*  <TextField*/}
            {/*    label="Distribution Fee Amount"*/}
            {/*    type="number"*/}
            {/*    onChange={onOwnerFieldChange}*/}
            {/*    name="distFeeAmt"*/}
            {/*    //required*/}
            {/*    fullWidth*/}
            {/*    variant="outlined"*/}
            {/*    InputLabelProps={{*/}
            {/*      shrink: true,*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</Grid>*/}
            {/*<Grid item sm={3} xs={6}>*/}
            {/*  <TextField*/}
            {/*    label="Profit Share %"*/}
            {/*    type="number"*/}
            {/*    onChange={onOwnerFieldChange}*/}
            {/*    name="profitSharePercent"*/}
            {/*    //required*/}
            {/*    fullWidth*/}
            {/*    variant="outlined"*/}
            {/*    InputLabelProps={{*/}
            {/*      shrink: true,*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</Grid>*/}

            <h3>Mailing Address Info:</h3>

            <Grid container item spacing={2}>
              <Grid item xs={12} />
              <Grid item sm={6} xs={12}>
                <TextField
                  label="Mailing Name"
                  type="text"
                  onChange={onOwnerFieldChange}
                  name="mailingName"
                  //required
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  label="Mailing Contact Name"
                  type="text"
                  onChange={onOwnerFieldChange}
                  name="mailingContactName"
                  //required
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  label="Mailing Address 1"
                  type="text"
                  onChange={onOwnerFieldChange}
                  name="mailingAddress1"
                  //required
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  label="Mailing Address 2"
                  type="text"
                  onChange={onOwnerFieldChange}
                  name="mailingAddress2"
                  //required
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  label="Mailing City"
                  type="text"
                  onChange={onOwnerFieldChange}
                  name="mailingCity"
                  //required
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item sm={3} xs={6}>
                <TextField
                  label="Mailing State"
                  type="text"
                  onChange={onOwnerFieldChange}
                  name="mailingState"
                  //required
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item sm={3} xs={6}>
                <TextField
                  label="Mailing Zip"
                  type="text"
                  onChange={onOwnerFieldChange}
                  name="mailingZip"
                  //required
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  label="Mailing Email"
                  type="text"
                  onChange={onOwnerFieldChange}
                  name="mailingEmail"
                  //required
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12}>
                <TextField
                  label="Notes"
                  type="text"
                  onChange={onOwnerFieldChange}
                  name="notes"
                  multiline
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>

            <Grid container item spacing={2} justify={"flex-end"}>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    action();
                    if (!isGlCodeExists) handleClose();
                  }}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};
