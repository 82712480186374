//React Components and Hooks
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import { makeStyles } from "@material-ui/core/styles";
//Material UI Components
import Toolbar from "@material-ui/core/Toolbar";
//Utils
import clsx from "clsx";
import React from "react";
import logo from "../../assets/images/logo-dark.png";

const SideBar = (props) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      backgrounColor: "#fafafa",
      spacing: 8,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      boxShadow:
        "0px 2px 4px -1px rgba(0,0,0,0.2)0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
      minHeight: "68px",
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    percentDif: {
      textAlign: "right",
      color: "green",
    },
    pad: {
      paddingTop: 16,
    },

    chip: {
      paddingLeft: 10,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    fixedHeight: {
      height: 240,
    },
    media: {
      height: 50,
      width: 300,
      paddingRight: 100,
    },
  }));
  const classes = useStyles();

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  return (
    <div>
      <Toolbar color="inherit" className={classes.appBar}>
        <img className={classes.media} src={logo} alt="apl" />
      </Toolbar>
      <Divider />
      <List
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            {props.page}
          </ListSubheader>
        }
      >
        <props.navList />
      </List>
    </div>
  );
};

export default SideBar;
