import React, {useEffect, useState} from 'react';
import {LeftSidebar as NavFrame} from "layout-blueprints";
import StandardHeader from "../../components/global/StandardHeader";
import Paper from "@mui/material/Paper";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress as MuiCircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {connect, useDispatch} from "react-redux";
import VendorBillForm from "./Components/Forms/VendorBillForm";
import {
  getAllPropertyPreviews,
  getAllVendors,
} from "../../modules/apl/api/actions";
import {
  deleteBillDraftByUUID,
  getAllBillingDashboardInfo,
  getBillInProgressDraft,
  handleBillingProcessSubmission,
  resetBillingObject,
} from "../../modules/billing/api/actions";
import {get as getPropertyUtility} from "../../modules/propertyUtility/api/actions";
import Divider from "@material-ui/core/Divider";
import PropertySelect from "./Components/Forms/PropertySelect";
import VendorSelect from "./Components/Forms/VendorSelect";
import WorkTable from "./Components/Forms/WorkTable";
import ToolBar from "../BillingWizard/ToolBar";
import moment from "moment/moment";
import styled from "styled-components/macro";
import {spacing} from "@material-ui/system";
import { setSnackbar } from "modules/snackbar/api/snackbar";

const CircularProgress = styled(MuiCircularProgress)(spacing);



const useStyles = makeStyles(() => ({
  title: {
    flexGrow: 1,
  },
  paper: {
    padding: "1%",
  },
}));

const BillingInformationSummary = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [workTableTableModalOpen, setWorkTableModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");

  const dispatch = useDispatch();
  const handleClose = () => {
    setModalOpen(false)
    setWorkTableModalOpen(false)
  }
  const handleOpen = () => {
    setModalOpen(true)
  }

  const refreshPage = async () => {
    await props.getAllPropertyPreviews();
    await props.getAllVendors();
    await props.getPropertyUtility();
    await props.getAllBillingDashboardInfo();
  };

  useEffect(() => {
    props.resetBillingObject()
    refreshPage();
  }, [workTableTableModalOpen]);

  const classes = useStyles();

  const message = modalType === 'create' ? "Create" : "Update";
  const onSave = async () => {
    try {
      dispatch(setSnackbar(true, "warning", 'In Progress'))
      props.billing.data.billInProgress.currentStep = 2;
      await props.handleBillingProcessSubmission(props.billing.data.billInProgress, false);
      dispatch(setSnackbar(true, "success", `Successfully ${message}d Work Table`));
      handleClose();
    } catch (error) {
      dispatch(setSnackbar(true, "error", `Failed to ${message} Work Table.`));
    }
  }

  const createWorkTable = async () => {
    setModalType('create')
    props.resetBillingObject()
    setWorkTableModalOpen(true)
  }

  const editWorkTable = async (item) => {
    setModalType('edit')
    setWorkTableModalOpen(true)
    await props.getBillInProgressDraft(item.uuid)
  }

  const deleteBillingDraftAndCloseTab = async () => {
    await props.deleteBillDraftByUUID(props.billing.data.billInProgress.accountBillWorkTableUUID)
    await refreshPage()
    setWorkTableModalOpen(false)
  }

  let currentPath = 'Billing / Billing Information'
  if (props.billing.data.billInProgress.accountBillWorkTableUUID !== -1) {
    currentPath = currentPath + ` -  Created: ${props.billing.data.billInProgress.createdDate || moment().format("MM/DD/YYYY")}`
  }

  return (
    <NavFrame page={"Billing / Billing Information Summary"}>
      <StandardHeader
        appPath="Billing Information"
        title={"Billing Information"}
      />
      <Divider className="mb-3 mt-3"/>

          <WorkTable
            createWorkTable={createWorkTable}
            editWorkTable={editWorkTable}
          />

          <Dialog
            maxWidth="xl"
            open={workTableTableModalOpen}
            onClose={handleClose}
          >
            <DialogTitle id="modal-modal-title1" variant="h6" component="h2">
              <StandardHeader
                title={`${message} Billing Information`}
                rightSideToolBar={props.billing.data.billInProgress.accountBillWorkTableUUID !== -1 &&
                  <ToolBar
                    deleteDraft={deleteBillingDraftAndCloseTab}
                    tableType={'BillingInformation'}
                  />}
                appPath={currentPath}
              />
            </DialogTitle>
                {props.billing.isLoading && modalType === 'edit' ?
                    <CircularProgress size={25} m={50}/> :
                    <Paper elevation={1} className={classes.paper}>
                  <Card mb={6} elevation={3}>
                    <CardContent>
                      <Grid container spacing={4} alignItems="flex-end">
                        <PropertySelect modalType={modalType}/>
                        <Grid item lg={12} md={12} sm={12}>
                          <Divider className="mb-3 mt-3"/>
                        </Grid>
                        <VendorSelect/>
                        <Divider className="mb-3 mt-3"/>
                        <VendorBillForm/>
                        <Grid item lg={12} md={12} sm={12}>
                          <Divider className="mb-2 mt-2" my={1}/>
                        </Grid>
                        <DialogActions>
                          <Grid item lg={6} md={12} sm={12}>
                            <Button variant="contained" color="secondary" onClick={handleOpen}>
                              Save
                            </Button>
                          </Grid>
                          <Grid item lg={6} md={12} sm={12}>
                            <Button
                              mr={1}
                              variant="contained"
                              color="primary"
                              fullWidth
                              onClick={handleClose}
                              my={2}
                            >
                              Cancel
                            </Button>
                          </Grid>
                        </DialogActions>
                      </Grid>
                    </CardContent>
                  </Card>
                  <Dialog
                    open={modalOpen}
                    onClose={handleClose}
                  >
                    <Box>
                      <DialogTitle id="modal-modal-title" variant="h6" component="h2">
                        Please Confirm
                      </DialogTitle>
                      <DialogContent id="modal-modal-description" sx={{mt: 2}}>
                        Do you want to {modalType === 'create' ? "save" : " update"} Billing Information for {props.billing.data.selectedProperty?.name}?
                      </DialogContent>
                      <DialogActions>
                        <Grid item lg={6} md={12} sm={12}>
                          <Button
                            mr={1}
                            variant="contained"
                            color="secondary"
                            fullWidth
                            onClick={onSave}
                            my={2}
                          >
                            Save
                          </Button>
                        </Grid>
                        <Grid item lg={6} md={12} sm={12}>
                          <Button
                            mr={1}
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={handleClose}
                            my={2}
                          >
                            Cancel
                          </Button>
                        </Grid>
                      </DialogActions>
                    </Box>
                  </Dialog>
                </Paper>}
          </Dialog>
    </NavFrame>
  )
    ;
};

BillingInformationSummary.propTypes = {
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  billing: PropTypes.object.isRequired,
  getAllBillingDashboardInfo: PropTypes.func.isRequired,
  deleteBillDraftByUUID: PropTypes.func.isRequired,
  resetBillingObject: PropTypes.func.isRequired,
  getBillInProgressDraft: PropTypes.func.isRequired,
  getPropertyUtility: PropTypes.func.isRequired,
  handleBillingProcessSubmission: PropTypes.func.isRequired,
};

function BillingInformationSummaryState(state) {
  return {
    user: state.user,
    apl: state.apl,
    billing: state.billing,
    propertyUtilities: state.propertyUtilities
  };
}

export default connect(BillingInformationSummaryState, {
  getAllPropertyPreviews,
  getAllVendors,
  handleBillingProcessSubmission,
  getPropertyUtility,
  getBillInProgressDraft,
  getAllBillingDashboardInfo,
  deleteBillDraftByUUID,
  resetBillingObject
})(BillingInformationSummary);