import { GET_ACCOUNT_FILES_REQUEST, GET_ACCOUNT_FILES_RESPONSE, GET_ACCOUNT_FILES_FAILURE, RESET, SET_UPLOAD_FILES, UPLOAD_ACCOUNT_FILE_RESPONSE, UPDATE_ACCOUNT_FILES_LOCAL_STATE,DELETE_ACCOUNT_FILES_RESPONSE } from "./actions";

const initialState = {
  isLoading: false,
  accountFilesAreLoading: false,
  error: null,
  data: {
    filesToUpload: { items: [], toDelete: [], toUpdate: [] },
    files: { items: [], toDelete: [], toUpdate: [] },
    accountFiles: { items: [], toDelete: [], toUpdate: [] },
    selectedAccountFileIndex: null,
    selectedFileIndex: null
  }
};

// State
export default (state = initialState, action) => {
  switch (action.type) {
    case RESET:
      return Object.assign({}, initialState);

    case SET_UPLOAD_FILES:
      var newData = state.data;
      newData.filesToUpload = { items: action.files, toDelete: [], toUpdate: [] };
      return {
        ...state,
        error: null,
        data: newData,
        isLoading: false,
      };
    case GET_ACCOUNT_FILES_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case UPDATE_ACCOUNT_FILES_LOCAL_STATE:
      var newData = state.data;
      if (action.filesFound && action.filesFound.length > 0) {
        newData.accountFiles = { items: action.filesFound, toDelete: [], toUpdate: [] };
      }
      return {
        ...state,
        error: null,
        data: newData,
        isLoading: false,
      };
    case GET_ACCOUNT_FILES_RESPONSE:
      var newData = state.data;
      if (action.filesFound && action.filesFound.length > 0) {
        newData.accountFiles = { items: action.filesFound, toDelete: [], toUpdate: [] };
      }
      return {
        ...state,
        error: null,
        data: newData,
        isLoading: false,
      };
    case DELETE_ACCOUNT_FILES_RESPONSE:
      let accountFiles = state.data;
      console.log(action)
      return {
        ...state,
        error: null,
        data: accountFiles.items.filter(item => item.uuid !== action.response.invalidated),
        isLoading: false,
      };
    case GET_ACCOUNT_FILES_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case UPLOAD_ACCOUNT_FILE_RESPONSE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    
    default:
      return {
        ...state,
      };
  }
};
