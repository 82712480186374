import moment from "moment";
import {
  CREATE_METER_READ_FAIL,
  CREATE_METER_READ_REQUEST,
  CREATE_METER_READ_RESPONSE,
  GET_METER,
  GET_METERS,
  GET_METERS_FAIL,
  GET_METERS_RESPONSE,
  GET_METER_FAIL,
  GET_METER_RESPONSE,
  POST_METER,
  POST_METER_FAIL,
  METER_HISTORY_LOOKUP_REQUEST,
  METER_HISTORY_LOOKUP_RESPONSE,
  UPDATE_LOOKED_UP_METER_HISTORY_DATA,
  POST_METER_RESPONSE,
  PUT_METER,
  PUT_METER_FAIL,
  PUT_METER_RESPONSE,
  DELETE_READING_REQUEST,
  DELETE_READING_FAILURE,
  DELETE_READING_RESPONSE,
  UPDATE_METER_READ_REQUEST,
  UPDATE_METER_READ_RESPONSE,
  UPDATE_METER_READ_FAIL,
  RESET,
  RESET_NEW_METER_READ,
  UPDATE_METER,
} from "./actions";
let blankMeterRead = {
  meterId: -1,
  readAmount: 0,
  meterReadTypeId: "",
  firstRead: 0,
  finalRead: 0,
};
const today = moment().format("MM/DD/YYYY");
const initialState = {
  isLoading: false,
  meterIsLoading: false,
  meterLookupIsLoading: true,
  error: null,
  postMeterError: null,
  putMeterError: null,
  data: {
    metersList: {
      items: [],
    },
    meter: {},
    meterReads: {toUpdate: [], toDelete: [], items: []},
    searchQuery: null,
  },
  newMeterRead: blankMeterRead,
};

// State
export default (state = initialState, action) => {
  var data = state.data;
  switch (action.type) {
    case RESET:
      return Object.assign({}, initialState);
    case UPDATE_METER:
      data.metersList.items = action.meters;
      return {
        ...state,
        data: data,
        error: action.error,
        isLoading: false,
      };
    case RESET_NEW_METER_READ:
      let revertedMeterRead = {
        meterId: -1,
        readAmount: 0,
        meterReadTypeId: "",
        firstRead: 0,
        finalRead: 0,
      };
      return {
        ...state,
        newMeterRead: revertedMeterRead,
      };
    case GET_METER:
      return {
        ...state,
        error: null,
        meterIsLoading: action.isLoading,
      };
    case GET_METER_RESPONSE:
      data.meter = action.meter;
      console.log("state");
      console.log(state);
      return {
        ...state,
        data: data,
        error: action.error,
        meterIsLoading: false,
      };
    case GET_METER_FAIL:
      data.metersList.items = null;
      return {
        ...state,
        data: data,
        error: action.error,
        meterIsLoading: false,
      };
      case DELETE_READING_REQUEST:
      return {
        ...state,
        error: null,
        meterLookupIsLoading: true,
      };
      case DELETE_READING_RESPONSE:
      return {
        ...state,
        error: null,
        meterLookupIsLoading: false,
      };
      case DELETE_READING_FAILURE:
      return {
        ...state,
        error: action.error || "Error Deleting Meter Read",
        meterLookupIsLoading: false,
      };
    case CREATE_METER_READ_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case CREATE_METER_READ_RESPONSE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
      case METER_HISTORY_LOOKUP_REQUEST:
      return {
        ...state,
        error: null,
        meterLookupIsLoading: true,
      };
      
    case UPDATE_LOOKED_UP_METER_HISTORY_DATA:
      var newReads = action.newMeterHistory;
      const SortedMeterReads = newReads.sort((a, b) => {
        return new Date(b.readDate) - new Date(a.readDate);
      });
      var newStateData = state.data;
      if (action.newMeterHistory.length > 0) {
        newStateData.meterReads = {toUpdate: [], toDelete: [], items: SortedMeterReads};
      }
      return {
        ...state,
        data: newStateData,
      };
    case METER_HISTORY_LOOKUP_RESPONSE:
      return {
        ...state,
        error: action.error,
        meterLookupIsLoading: false,
      };

    case CREATE_METER_READ_FAIL:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };

    case GET_METERS:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case GET_METERS_RESPONSE:
      data.metersList.items = action.meters;
      return {
        ...state,
        data: data,
        error: action.error,
        isLoading: false,
      };
    case GET_METERS_FAIL:
      data.metersList.items = null;
      return {
        ...state,
        data: data,
        error: action.error,
        isLoading: false,
      };
    case POST_METER:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case POST_METER_RESPONSE:
      return {
        ...state,
        data: data,
        postMeterError: action.error,
        isLoading: false,
      };
    case POST_METER_FAIL:
      return {
        ...state,
        data: data,
        postMeterError: action.error,
        isLoading: false,
      };
    case PUT_METER:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case PUT_METER_RESPONSE:
      return {
        ...state,
        data: data,
        error: action.error,
        isLoading: false,
      };
    case PUT_METER_FAIL:
      return {
        ...state,
        data: data,
        putMeterError: action.error,
        isLoading: false,
      };
    case UPDATE_METER_READ_REQUEST:
      return {
        ...state,
        isLoading: action.isLoading,
      }
    case UPDATE_METER_READ_RESPONSE:
      return {
        ...state,
        isLoading: action.isLoading 
      }
    case UPDATE_METER_READ_FAIL:
      return {
        ...state,
        error: action.error,
        isLoading: action.isLoading
      }

    default:
      return {
        ...state,
      };
  }
};
