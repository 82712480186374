import {
  Button as MuiButton,
  Card,
  CardContent,
  CircularProgress as MuiCircularProgress,
  Divider as MuiDivider,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress as MuiLinearProgress,
  MenuItem,
  Select as MuiSelect,
  TextField as MuiTextField,
  Typography,
  CardHeader
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
// Redux Components
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components/macro";
import { txtBoxDateFormatter } from "../../../setup/helpers";
import { setSnackbar } from "../../snackbar/api/snackbar";
import {
  updateCurrentAccountInDB,
  updateSelectedAccount,
  updateAccountAddress,
  createNewAccountAddress
} from "../api/actions";


const LinearProgress = MuiLinearProgress;
const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Select = styled(MuiSelect)(spacing);

const ForwardingAddressForm = (props) => {
  const dispatch = useDispatch();
  const [isNewAddress, setIsNewAddress] = React.useState(false);
  const useStyles = makeStyles((theme) => ({
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    loadingIcon: {
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
    },
  }));

  // Handles vendor drop down
  const classes = useStyles();

  useEffect(() => {
    /*
    if (!props.account.isLoading) {
      if (props.account.data.forwardingAddress && props.account.data.forwardingAddress.length != 0) {
        setIsNewAddress(false);
      }
      else {
        setIsNewAddress(true);
        props.account.data.forwardingAddress.push(props.account.newForwardingAddress);
      }
    }*/
  }, [props.account.isLoading])



  const updateAccountAddress = () => {
    // Replaces existing store object with new object with modified sub data

    dispatch(setSnackbar(true, "warning", "Updating Account Forwarding Address"));
    props.updateSelectedAccount(props.account.data);
    props.updateAccountAddress(props.account.data.forwardingAddress[0]).then(() => {
      if (props.account.error == null || props.account.error == "") {
        dispatch(setSnackbar(true, "success", "Forwarding Address updated successfully"));
      }
      else {
        dispatch(setSnackbar(true, "error", "Error Updating Account Forwarding Address"));
      }
    });
    if (props.onUpdate) {
      props.onUpdate();
    }
  };

  const createForwardingAddress = () => {
    if (props.account.data.forwardingAddress[0]) {

      dispatch(setSnackbar(true, "warning", "Creating Account Forwarding Address"));
      props.account.data.forwardingAddress[0].accountId = props.account.data.account.id || -1;
      props.createNewAccountAddress(props.account.data.forwardingAddress[0]).then(() => {
        if (props.account.error == null || props.account.error == "") {
          dispatch(setSnackbar(true, "success", "Forwarding Address created successfully"));
        }
        else {
          dispatch(setSnackbar(true, "error", "Error Creating Account Forwarding Address"));
        }
      });
      if (props.onUpdate) {
        props.onUpdate();
      }
    }
  };

  const onFieldChange = (event) => {
    if (props.account.data.forwardingAddress && props.account.data.forwardingAddress.length != 0)
      if (event.target.type == "number") {
        props.account.data.forwardingAddress[0][event.target.name] = Number(
          event.target.value
        );
      } else {
        props.account.data.forwardingAddress[0][event.target.name] = event.target.value;
      }
  };


  if (props.account.isLoading) {
    return (
      <Card mb={6}>
        <Typography variant="h6" gutterBottom>
          Forwarding Address Form
        </Typography>
        <CardContent>
          <Typography variant="h3" gutterBottom>
            Account Loading...
          </Typography>
        </CardContent>
      </Card>
    );
  }

  if (!props.account.data.account) {
    return (
      <Card mb={6}>
        <Typography variant="h6" gutterBottom>
          Forwarding Address Form
        </Typography>
        <CardContent>
          <Typography variant="h3" gutterBottom>
            ERROR! Unable to load current account
          </Typography>
        </CardContent>
      </Card>
    );
  }

  if (!props.account.data.forwardingAddress.length > 0) {
    return (
      <Card mb={6}>
        <Typography variant="h6" gutterBottom>
          Forwarding Address Form
        </Typography>
        <CardContent>
          <Typography variant="h3" gutterBottom>
            ERROR! No generated forward address found on account object
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className="mb-6">
      {props.account.data.forwardingAddress && !props.account.data.forwardingAddress[0].id ?
        <Typography variant="h6" gutterBottom>
          New Account Forwarding Address
        </Typography>
        :
        <Typography variant="h6" gutterBottom>
          Account Forwarding Address
        </Typography>
      }
      <Divider className="mb-3 mt-3" />
      <CardContent>
        <Grid container spacing={3}>{/*
          <Grid item lg={4} md={4} sm={6}>
            <TextField
              label="Effective Date"
              type="date"
              onChange={onFieldChange}
              name="effectiveDate"
              defaultValue={txtBoxDateFormatter(props.account.data.forwardingAddress[0].effectiveDate || "N/A")}
              fullWidth
              my={2}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
            */}
          <Grid item lg={4} md={4} sm={6}>
            <TextField
              label="Forward Address"
              type="text"
              onChange={onFieldChange}
              name="address1"
              defaultValue={props.account.data.forwardingAddress[0].address1}
              fullWidth
              my={2}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={6}>
            <TextField
              label="Forward Address (Apt)"
              type="text"
              onChange={onFieldChange}
              name="address2"
              defaultValue={props.account.data.forwardingAddress[0].address2}
              fullWidth
              my={2}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={6}>
            <TextField
              label="Forward City"
              type="text"
              onChange={onFieldChange}
              name="city"
              defaultValue={props.account.data.forwardingAddress[0].city}
              fullWidth
              my={2}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={6}>
            <TextField
              label="Forward State"
              type="text"
              onChange={onFieldChange}
              name="state"
              defaultValue={props.account.data.forwardingAddress[0].state}
              fullWidth
              my={2}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={6}>
            <TextField
              label="Forward Zip"
              type="text"
              onChange={onFieldChange}
              name="zip"
              defaultValue={props.account.data.forwardingAddress[0].zip}
              fullWidth
              my={2}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
        {props.account.data.forwardingAddress && !props.account.data.forwardingAddress[0].id ?
          <Button
            className="mb-3 mt-3 mr-3"
            variant="contained"
            color="secondary"
            onClick={createForwardingAddress}
          >
            Creating New Forwarding Address
          </Button>
          :
          <Button
            className="mb-3 mt-3 mr-3"
            variant="contained"
            color="secondary"
            onClick={updateAccountAddress}
          >
            Update Forwarding Address
          </Button>
        }
      </CardContent>
    </Card>
  );
};

// Component Properties
ForwardingAddressForm.propTypes = {
  // Store objects:
  account: PropTypes.object.isRequired,
  //userProfile: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  updateSelectedAccount: PropTypes.func.isRequired,
  updateCurrentAccountInDB: PropTypes.func.isRequired,
  updateAccountAddress: PropTypes.func.isRequired,
  createNewAccountAddress: PropTypes.func.isRequired,
};

// Component State
function ForwardingAddressFormState(state) {
  return {
    account: state.account,
    apl: state.apl,
  };
}
export default connect(ForwardingAddressFormState, {
  updateSelectedAccount,
  updateCurrentAccountInDB,
  updateAccountAddress,
  createNewAccountAddress
})(ForwardingAddressForm);
