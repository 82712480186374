// App Imports
import {
  DELINQUENCY_ACCOUNT_DELINQUENCYS_RESET,
  DELINQUENCY_GET_ACCOUNT_DELINQUENCYS_FAILURE,
  DELINQUENCY_GET_ACCOUNT_DELINQUENCYS_REQUEST,
  DELINQUENCY_GET_ACCOUNT_DELINQUENCYS_RESPONSE,
  DELINQUENCY_GET_FILTERED_GROUPED_DELINQUENCIES_REQUEST,
  DELINQUENCY_GET_FILTERED_GROUPED_DELINQUENCIES_RESPONSE,
  DELINQUENCY_UPDATE_NEW_ACCOUNT_DELINQUENCY_IN_PROGRESS,
  SET_DELINQUENCY_LIST,
  SET_FILTERED_GROUPED_DELINQUENCIES_LIST,
} from "./actions";

// Initial State
const delinquenciesInitialState = {
  isLoading: true,
  delinquencyGroupsAreLoading: true,
  error: null,
  data: [],
  delinquencyGroups: {
    items: [],
    toDelete: [],
    toUpdate: [],
  },
  initialDelinquencyGroupsSummary: null,
  currentDelinquencyGroupsSummary: null,
  newDelinquency: {},
};

// State
export default (state = delinquenciesInitialState, action) => {
  switch (action.type) {
    case SET_DELINQUENCY_LIST:
      return {
        ...state,
        data: action.data,
      };
    case SET_FILTERED_GROUPED_DELINQUENCIES_LIST:
      var newStateData = state.delinquencyGroups.items;
      if (action.clearCache) {
        let delinquencyGroups = {
          items: action.data.items,
          toDelete: [],
          toUpdate: [],
        };
        newStateData = delinquencyGroups;
      } else {
        let delinquencyGroups = {
          items: newStateData.concat(action.data.items),
          toDelete: [],
          toUpdate: [],
        };
        newStateData = delinquencyGroups;
      }
      if (action.isBaseGroup) {
        return {
          ...state,
          initialDelinquencyGroupsSummary: action.data.summary,
        };
      } else {
        return {
          ...state,
          delinquencyGroups: newStateData,
          currentDelinquencyGroupsSummary: action.data.summary,
        };
      }

    case DELINQUENCY_GET_FILTERED_GROUPED_DELINQUENCIES_REQUEST:
      return {
        ...state,
        delinquencyGroupsAreLoading: action.isLoading,
        error: null,
      };

    case DELINQUENCY_GET_FILTERED_GROUPED_DELINQUENCIES_RESPONSE:
      return {
        ...state,
        delinquencyGroupsAreLoading: false,
        error: action.error,
      };

    case DELINQUENCY_GET_ACCOUNT_DELINQUENCYS_REQUEST:
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
      };

    case DELINQUENCY_GET_ACCOUNT_DELINQUENCYS_RESPONSE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        data: action.data,
      };

    case DELINQUENCY_GET_ACCOUNT_DELINQUENCYS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case DELINQUENCY_UPDATE_NEW_ACCOUNT_DELINQUENCY_IN_PROGRESS:
      const newDelinquencyUpdated = Object.assign(
        {},
        state.newDelinquency,
        action.newDelinquency
      );
      return {
        ...state,
        newBill: newDelinquencyUpdated,
      };
    case DELINQUENCY_ACCOUNT_DELINQUENCYS_RESET:
      return Object.assign({}, delinquenciesInitialState);

    default:
      return state;
  }
};
