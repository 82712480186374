import {
  Button as MuiButton,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  TextField as MuiTextField,
  Typography,
  List, ListItem,
  Fab
} from "@material-ui/core";
import Dropzone from 'react-dropzone';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components/macro";
import { setSnackbar } from "../../snackbar/api/snackbar";
import { getAllAccountFiles, setUploadFiles, uploadFiles } from "../api/actions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fabClasses } from "@mui/material";

const TextField = styled(MuiTextField)(spacing);
const Select = styled(MuiSelect)(spacing);

const AccountFileForm = (props) => {

  const [currentFiles, setCurrentFiles] = React.useState(props.files.data.filesToUpload.items);
  const [currentUpload, setCurrentUpload] = React.useState({
    description: "",
    fileName: "",
    bucketName: "",
    file: null
  });

  const useStyles = makeStyles((theme) => ({
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    loadingIcon: {
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
    },
  }));


  useEffect(() => {

  }, [currentFiles]);

  const onFieldChange = (event) => {
    var upload = currentUpload;
    upload[event.target.name] = event.target.value;
  };

  const dispatch = useDispatch();
  const classes = useStyles();

  const onDrop = (files) => {
    setCurrentFiles(files);
  }
  const onCancel = () => {
    setCurrentFiles([]);
    props.setUploadFiles([]);
  }

  const removeItem = async (itemIndex) => {
    var files = currentFiles;
    files.splice(itemIndex, 1);
    setCurrentFiles(files);
    props.setUploadFiles(currentFiles);
  }
  const save = async ()  => {
    if (!props.account.isLoading && props.account.data.account.id) {
      if (props.handleClose) {
        props.handleClose();
      }
      console.log("uploaded")
      var upload = currentUpload;
      upload.accountId = props.account.data.account.id;
      for(let file of currentFiles)  {
        upload.file = file;
        upload.fileName = file.name;
       await props.uploadFiles(upload)
    }
            await props.getAllAccountFiles(props.account.data.account.id);

      onCancel();
    }
  };

  if (props.files.isLoading) {
    return (
      <Card mb={6}>
        <CardContent>
          <Typography variant="h3" gutterBottom>
            Loading...
          </Typography>
        </CardContent>
      </Card>
    );
  }
  return (
    <Card>
      <CardContent>
        {/* Meter Details */}
        <Grid container spacing={2}>
          <Grid container item spacing={2}>
            <Grid item md={12} xs={12}>
              <TextField
                label="Description"
                type="text"
                onChange={onFieldChange}
                defaultValue={currentUpload.description}
                name="description"
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={2}>
            <Grid container spacing={4} className="mt-4">
              <Grid
                item
                xs={12}
                className="d-flex justify-content-center align-items-center">
                <div className="w-100">
                  {currentFiles.length === 0 && (
                    <MuiAlert severity="success">
                      Files uploaded will appear here!
                    </MuiAlert>
                  )}
                  {currentFiles.length > 0 && (
                    <>
                      <div className="font-weight-bold text-center">
                        Uploaded Files
                      </div>
                      <Grid container spacing={2}>
                        {
                          currentFiles.map((file, index) => (
                            <Grid item>
                              <a
                                href="#/"
                                onClick={(e) => {
                                  e.preventDefault();
                                }}
                                className="card card-box btn rounded text-left mb-4 p-3">
                                <div className="d-flex align-items-center flex-column flex-sm-row">
                                  <div>
                                    <div className="text-center text-first display-2 d-50 rounded-circle mb-3 mb-sm-2">
                                      <FontAwesomeIcon icon={['far', 'file-alt']} />
                                    </div>
                                  </div>
                                  <div className="pl-0 pl-sm-3">
                                    <div className="d-block text-center d-sm-flex align-items-center">
                                      <span className="font-size-md">{file.name}</span>
                                    </div>
                                    <p className="text-black-50 opacity-8 mb-0">
                                      <b>{file.size} bytes</b>
                                    </p>
                                  </div>
                                  <div>
                                    <div className="text-center text-first display-2 d-50 rounded-circle mb-3 mb-sm-2">
                                      <Fab onClick={() => removeItem(index)} size="small" color="primary">
                                        <FontAwesomeIcon icon={['far', 'trash-alt']} />
                                      </Fab>
                                    </div>
                                  </div>
                                </div>
                              </a>
                            </Grid>
                          ))
                        }
                      </Grid>
                    </>
                  )}
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="dropzone">
                  <Dropzone
                    fullWidth
                    onDrop={onDrop}
                    onFileDialogCancel={onCancel}>
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div className="dz-message">
                          <div className="dx-text">
                            Try dropping some files here, or click to select files
                            to upload.
                          </div>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item spacing={2} justify={"flex-end"}>
            <Grid item>
              <MuiButton variant="contained" onClick={onCancel} color="primary">
                Remove All
              </MuiButton>
            </Grid>
            <Grid item>
              <MuiButton variant="contained" onClick={save} color="secondary">
                Save
              </MuiButton>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

AccountFileForm.propTypes = {
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  files: PropTypes.object.isRequired,
  getAllAccountFiles: PropTypes.func.isRequired,
  setUploadFiles: PropTypes.func.isRequired,
  uploadFiles: PropTypes.func.isRequired
};

function AccountFileFormState(state) {
  return {
    user: state.user,
    apl: state.apl,
    account: state.account,
    files: state.files,
  };
}

export default connect(AccountFileFormState, { getAllAccountFiles, setUploadFiles, uploadFiles })(AccountFileForm);
