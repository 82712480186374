import React from 'react';
import StandardHeader from "../../components/global/StandardHeader";
import {Divider, Grid} from "@material-ui/core";
import {LeftSidebar} from "../../layout-blueprints";
import UsersPreviewTable from "../../modules/userProfiles/components/UsersPreviewTable";
const UsersPage = () => {

  return (
    <LeftSidebar>
      <StandardHeader
        title="Users"
      />
      <Divider className="mb-3 mt-3" />
      <Grid container spacing={1} xs={12} sm={12} md={12} lg={12}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <UsersPreviewTable/>
        </Grid>
      </Grid>
    </LeftSidebar>
  );
};

export default UsersPage;