import { AppBar, Box, Tab, Tabs, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import PropertyCommonAreaAccountsTable from "../../modules/accountsSearch/components/PropertyCommonAreaAccountsTable";
import PropertyCommonAreaCalculatorForm from "../../modules/billing/components/PropertyCommonsCalculator";
import CommonAreaExpenseTable from "../../modules/property/components/CommonAreaExpenses";
import ResidualAmountsToAddTable from "../../modules/property/components/ResidualAmountsToAddTable";
import PropertyRiderRates from "../../modules/property/components/RiderRatesTable";
import UnitTable from "../../modules/unit/components/Table";
import BillingInformationSummaryTable from "../../modules/billing/components/BillingInformationSummaryTable";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function PropertyDashboardTabedTables(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const getUserBills = props.getUserBills;

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Account Management Tabs"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Units" {...a11yProps(0)} />
          {/*<Tab label="Rider Rates" {...a11yProps(1)} />*/}
          {/*<Tab label="Commons Charge Calculator" {...a11yProps(2)} />*/}
          <Tab label="Common Area Accounts" {...a11yProps(1)} />
          {/*<Tab label="Residual Amounts to Add" {...a11yProps(4)} />*/}
          {/*<Tab label="Common Area Expenses" {...a11yProps(5)} />*/}
          <Tab label="Billing Information Summary" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <UnitTable />
      </TabPanel>
      {/*<TabPanel value={value} index={1}>*/}
      {/*  <PropertyRiderRates />*/}
      {/*</TabPanel>*/}
      {/*<TabPanel value={value} index={2}>*/}
      {/*  <PropertyCommonAreaCalculatorForm propertyId={props.propertyId} />*/}
      {/*</TabPanel>*/}
      <TabPanel value={value} index={1}>
        <PropertyCommonAreaAccountsTable propertyUuid={props.propertyUuid} />
      </TabPanel>
      {/*<TabPanel value={value} index={4}>*/}
      {/*  <ResidualAmountsToAddTable*/}
      {/*    propertyUuid={props.propertyUuid}*/}
      {/*    propertyId={props.propertyId}*/}
      {/*  />*/}
      {/*</TabPanel>*/}
      {/*<TabPanel value={value} index={5}>*/}
      {/*  <CommonAreaExpenseTable propertyId={props.propertyId} />*/}
      {/*</TabPanel>*/}
        <TabPanel value={value} index={2}>
         <BillingInformationSummaryTable/>
        </TabPanel>
    </div>
  );
}
