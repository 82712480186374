 import { AppBar, Box, Tab, Tabs, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import OwnerStatementsTable from "../../modules/ownerStatement/components/OwnerStatementsTable";
 import OwnerJournalsTable from "../../modules/ownerJournal/components/OwnerJournalsTable";
 import {useLocation} from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function PropertyManagerDashboardTabedTables() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const location = useLocation();
  const ownerId = location.pathname.split("/")[3];
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Owner Tabs"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Owner Statements" {...a11yProps(0)} />
          <Tab label="Owner Journals" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <OwnerStatementsTable ownerId={ownerId}/>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <OwnerJournalsTable ownerId={ownerId}/>
      </TabPanel>
    </div>
  );
}
