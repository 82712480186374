// Imports
import axios from "axios";
require("../../../setup/config/apiConfig");

export const RESET = "RESET";
export const GET_UNIT_RATES = "GET_UNIT_RATES";
export const GET_UNIT_RATES_RESPONSE = "GET_UNIT_RATES_RESPONSE";
export const GET_UNIT_RATES_RESPONSE_FAIL = "GET_UNIT_RATES_RESPONSE_FAIL";

export function get(isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: GET_UNIT_RATES,
      isLoading: isLoading,
    });
    return axios
      .get(`/unitRate`)
      .then((response) => {
        dispatch({
          type: GET_UNIT_RATES_RESPONSE,
          unitRates: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_UNIT_RATES_RESPONSE_FAIL,
          isLoading: false,
          error: error,
        });
      });
  };
}
