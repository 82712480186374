import React, { useEffect, useState } from "react";
import styled, { withTheme } from "styled-components/macro";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Select as MuiSelect,
  TextField as MuiTextField,
  Button as MuiButton,
  FormControl,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import { put, post } from "../api/actions";
import { setSnackbar } from "../../snackbar/api/snackbar";
import { get as getPMs } from "../api/actions"

const TextField = styled(MuiTextField)(spacing);
const Select = styled(MuiSelect)(spacing);

const PropertyManagerForm = (props) => {
  const useStyles = makeStyles((theme) => ({
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    loadingIcon: {
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
    },
  }));

  const [newPm, setNewPM] = useState({
    name: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    statusId: "",
    billingEmail: "",
    delinquencyEmail: "",
    contactName:"",
    email:"",
    notes: ""
  });

  console.log(props)
  const handleSelectedAccountStatusChange = (event) => {
    onFieldChange(event);
    //setAccountState(event.target.value);
  };

  const save = async () => {
    await props.post(newPm)
    await props.getPMs();
    await props.onUpdate();

  };

  const onFieldChange = (event) => {
    let value = event.target.value;
    setNewPM({
      ...newPm,
      [event.target.name]: value,
    });
  };

  // Handles vendor drop down
  const [PropertyManagerState, setPropertyManagerState] = React.useState("");
  const classes = useStyles();
  const dispatch = useDispatch();


  return (
    <Card>
      <CardContent>
        {/* PropertyManager Details */}
        <Grid container spacing={2}>
          <Grid container item spacing={2}>
            <Grid item sm={6} xs={12}>
              <TextField
                label="Name"
                type="text"
                onChange={onFieldChange}
                name="name"
                //required
                value={newPm.name}
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                label="Phone"
                type="text"
                onChange={onFieldChange}
                name="phone"
                value={newPm.phone}
                //required
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={2}>
            <Grid item sm={6} xs={12}>
              <TextField
                label="Address 1"
                type="text"
                onChange={onFieldChange}
                name="address1"
                value={newPm.address1}
                //required
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                label="Address 2"
                type="text"
                onChange={onFieldChange}
                name="address2"
                value={newPm.address2}
                //required
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={2}>
            <Grid item sm={4} xs={12}>
              <TextField
                label="City"
                type="text"
                onChange={onFieldChange}
                name="city"
                value={newPm.city}
                //required
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField
                label="State"
                type="text"
                onChange={onFieldChange}
                value={newPm.state}
                name="state"
                //required
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField
                label="Zip"
                type="text"
                onChange={onFieldChange}
                name="zip"
                value={newPm.zip}
                //required
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField
                label="Contact Name"
                type="text"
                onChange={onFieldChange}
                name="contactName"
                value={newPm.contactName}
                required
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField
                label="Email"
                type="text"
                onChange={onFieldChange}
                name="email"
                value={newPm.email}
                required
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField
                label="Billing Email"
                type="text"
                onChange={onFieldChange}
                name="billingEmail"
                value={newPm.billingEmail}
                required
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField
                label="Delinquency Email"
                type="text"
                onChange={onFieldChange}
                name="delinquencyEmail"
                value={newPm.delinquencyEmail}
                required
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="developerIdLabel">Developer</InputLabel>
                <Select
                  labelId="developerId"
                  id="developerId"
                  onChange={onFieldChange}
                  defaultValue={newPm.developerId}
                  name="developerId"
                >
                  {[props.developers?.data?.developersList?.items?.map(
                    (item, index) => (
                      <MenuItem id="DeveloperOption" value={item.id}>
                        {item.name}
                      </MenuItem>
                    )
                  )]}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={4} xs={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="statusIdLabel">Status</InputLabel>
                <Select
                  labelId="statusId"
                  id="statusId"
                  onChange={onFieldChange}
                  name="statusId"
                >
                  {[props.apl.genericTypes.propertyManagerStatus.data.map(
                    (item, index) => (
                      <MenuItem id="AccountStatusOption1" value={item.id}>
                        {item.description}
                      </MenuItem>
                    )
                  )]}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container item spacing={2}>
            <Grid item sm={8} xs={12}>
              <TextField
                label="Notes"
                type="text"
                onChange={onFieldChange}
                name="notes"
                value={newPm.notes}
                multiline
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>

          <Grid container item spacing={2} justify={"flex-end"}>
            <Grid item>
              <MuiButton variant="contained" onClick={save} color="secondary">
                Save
              </MuiButton>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

// Component Properties
PropertyManagerForm.propTypes = {
  propertyManagers: PropTypes.object.isRequired,
  owners: PropTypes.object.isRequired,
  propertyManagerStatuses: PropTypes.object.isRequired,
  put: PropTypes.object.isRequired,
  post: PropTypes.object.isRequired,
  getPMs: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired
};

function PropertyManagerFormState(state) {
  return {
    propertyManagers: state.propertyManagers,
    owners: state.owners,
    propertyManagerStatuses: state.propertyManagerStatuses,
    apl: state.apl,
    developers: state.developers
  };
}
export default connect(PropertyManagerFormState, { put, post , getPMs})(
  PropertyManagerForm
);
