// App Imports
import moment from "moment";
import {
  ADD_ACCOUNTS_TO_LIST,
  ADD_PAYMENT_TO_PAYMENT_LIST,
  ADD_SELECTED_PM_PACKET_ACCOUNTS_TO_CHECK_REQUEST,
  ADD_SELECTED_PM_PACKET_ACCOUNTS_TO_CHECK_RESPONSE,
  GET_BULK_CHECK_PAYMENT_DAILY_SUMMARY_REQ,
  GET_BULK_CHECK_PAYMENT_DAILY_SUMMARY_RESP,
  PAYMENT_ACCOUNT_PAYMENTS_RESET,
  PAYMENT_GET_ACCOUNT_PAYMENTS_FAILURE,
  PAYMENT_GET_ACCOUNT_PAYMENTS_REQUEST,
  PAYMENT_GET_ACCOUNT_PAYMENTS_RESPONSE,
  PAYMENT_GET_ALL_ACCOUNT_PAYMENTS_FAILURE,
  PAYMENT_GET_ALL_ACCOUNT_PAYMENTS_REQUEST,
  PAYMENT_GET_ALL_ACCOUNT_PAYMENTS_RESPONSE,
  PAYMENT_MAKE_ACCOUNT_PAYMENT_REQUEST,
  PAYMENT_MAKE_ACCOUNT_PAYMENT_RESPONSE,
  PAYMENT_UPDATE_ACCOUNT_EXISTING_PAYMENT_REQUEST,
  PAYMENT_UPDATE_ACCOUNT_EXISTING_PAYMENT_RESPONSE,
  PAYMENT_UPDATE_NEW_ACCOUNT_PAYMENT_IN_PROGRESS_ERRORS,
  RESET_BULK_CHECK_ENTRY,
  SET_PAYMENT_LIST,
  SET_AUTO_PAYMENT_LIST,
  UPDATE_BULK_CHECK,
  UPDATE_BULK_CHECK_ACCOUNTS,
  SET_SELECTED_PAYMENT,
  GET_ACCOUNT_PAYMENT_PLANS_FAILURE,
  GET_ACCOUNT_PAYMENT_PLANS_RESPONSE,
  GET_ACCOUNT_PAYMENT_PLANS_REQUEST,
  UPDATE_ACCOUNT_PAYMENT_PLANS_STATE,
  PAYMENT_GET_SELECTED_PAYMENT_LINE_ITEMS_REQUEST,
  PAYMENT_GET_SELECTED_PAYMENT_LINE_ITEMS_RESPONSE,
  PAYMENT_GET_SELECTED_PAYMENT_LINE_ITEMS_FAILURE,
  REVERSE_ACCOUNT_PAYMENTS_REQUEST,
  REVERSE_ACCOUNT_PAYMENTS_RESPONSE,
  REVERSE_ACCOUNT_PAYMENTS_FAILURE,
  CREATE_AUTO_PAYMENTS_REQUEST,
  CREATE_AUTO_PAYMENTS_RESPONSE,
  CREATE_AUTO_PAYMENTS_FAILURE,
  GET_AUTO_PAYMENTS_REQUEST,
  GET_AUTO_PAYMENTS_RESPONSE,
  GET_AUTO_PAYMENTS_FAILURE,
  UPDATE_AUTO_PAYMENTS_REQUEST,
  UPDATE_AUTO_PAYMENTS_RESPONSE,
  UPDATE_AUTO_PAYMENTS_FAILURE,
  DELETE_AUTO_PAYMENTS_REQUEST,
  DELETE_AUTO_PAYMENTS_RESPONSE,
  DELETE_AUTO_PAYMENTS_FAILURE
} from "./actions";

const today = moment().format("MM/DD/YYYY");
// Initial State
const paymentsInitialState = {
  isLoading: true,
  error: null,
  accountPaymentsTotalRows: null,
  data: {
    items: [],
    toDelete: [],
    toUpdate: [],
  },
  bulkCheckCurrentSummary: null,
  bulkCheckCurrentSummaryIsLoading: true,
  bulkCheckSelectedAccounts: {
    items: [],
    toDelete: [],
    toUpdate: [],
  },
  newBulkCheckIsLoading: false,
  newBulkCheck: {
    checkNumber: "",
    amountPaid: 0,
  },
  paymentPlans: {
    items: [],
    toDelete: [],
    toUpdate: [],
  },
  accountAutoPayments: {
    items: [],
    toDelete: [],
    toUpdate: [],
  },

  newPaymentError: null,
  accountAutoPaymentIsLoading: false,
  accountPaymentPlansIsLoading: false,
  accountAutoPaymentsItemsError: null,
  accountPaymentPlansItemsError: null,

  selectedPaymentLineItemsAreLoading: true,
  selectedPaymentLineItemsError: null,
  selectedPaymentLineItems: {
    items: [],
    toDelete: [],
    toUpdate: [],
  },
  selectedPayment: null,
  selectedPaymentDataIsLoading: false,
  accountPaymentsSummary: null,
  newPayment: {
    paymentType: "Credit Card",
    paymentNote: "",
    paymentAmount: 0,
    checkNumber: "",
    routingNumber: "",
    accountNumber: "",

    nameOnCard: "",
    cardNumber: "",
    cardType: "",
    cardExpMonth: "",
    cardExpYear: "",
    cardCvv: "",
    paymentDate: today,
    transactionFee: 0,
    transactionTotal: 0,
  },
  newAutoPayError: null,
  newAutoPayIsLoading: false,
  newPaymentSubmissionErrors: [],
  paymentUpdateSubmissionErrors: [],
};

// State
export default (state = paymentsInitialState, action) => {
  switch (action.type) {
    case SET_SELECTED_PAYMENT:
      return {
        ...state,
        selectedPayment: action.selectedPayment,
      };
    case SET_PAYMENT_LIST:
      return {
        ...state,
        data: action.data,
      };
    case SET_AUTO_PAYMENT_LIST:
      return {
        ...state,
        accountAutoPayments: action.data,
      }
    case UPDATE_BULK_CHECK_ACCOUNTS:
      return {
        ...state,
        bulkCheckSelectedAccounts: action.accounts,
      };
    case ADD_ACCOUNTS_TO_LIST:
      state.bulkCheckSelectedAccounts.items.map((account) => {
        for (var x = 0; x < action.accounts.length; x++) {
          if (action.accounts[x].id == account.id) {
            action.accounts.splice(x, 1);
          }
        }
      });
      let newbulkCheckSelectedAccounts = {
        toDelete: [],
        toUpdate: [],
        items: state.bulkCheckSelectedAccounts.items.concat(action.accounts),
      };
      return {
        ...state,
        bulkCheckSelectedAccounts: newbulkCheckSelectedAccounts,
      };
    case ADD_SELECTED_PM_PACKET_ACCOUNTS_TO_CHECK_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case ADD_SELECTED_PM_PACKET_ACCOUNTS_TO_CHECK_RESPONSE:
      if (action.data) {
        state.bulkCheckSelectedAccounts.items.map((account) => {
          for (var x = 0; x < action.data.length; x++) {
            if (action.data[x].id == account.id) {
              action.data.splice(x, 1);
            }
          }
        });
        action.data.map((account) => {
          if (!account.amountCovered && account.balance > 0) {
            account.amountCovered = account.balance;
          } else {
            account.amountCovered = 0;
          }
        });
        let newSelectedAccounts = {
          toDelete: [],
          toUpdate: [],
          items: state.bulkCheckSelectedAccounts.items.concat(action.data),
        };
        return {
          ...state,
          bulkCheckSelectedAccounts: newSelectedAccounts,
        };
      } else {
        return {
          ...state,
        };
      }
    case UPDATE_AUTO_PAYMENTS_REQUEST:
    return {
      ...state,
    }
    case UPDATE_AUTO_PAYMENTS_REQUEST:
      return{
        ...state,
        error: action.error
      }
    case UPDATE_AUTO_PAYMENTS_FAILURE:
      return{
        ...state,
        error: action.error
      }
    case DELETE_AUTO_PAYMENTS_REQUEST:
      return{
        ...state,
        isLoading: action.isLoading
      }
    case DELETE_AUTO_PAYMENTS_RESPONSE:
      return{
        ...state,
        error: action.error,
        isLoading: action.isLoading
      }
    case DELETE_AUTO_PAYMENTS_FAILURE:
      return{
        ...state,
        error: action.error,
        isLoading: action.isLoading,
      }
    case RESET_BULK_CHECK_ENTRY:
      return {
        ...state,
        bulkCheckSelectedAccounts: {
          toDelete: [],
          toUpdate: [],
          items: [],
        },
        newBulkCheck: {
          checkNumber: "",
          amountPaid: 0,
        },
      };
    case UPDATE_BULK_CHECK:
      return {
        ...state,
        newBulkCheck: action.newBulkCheck,
      };
    case PAYMENT_UPDATE_NEW_ACCOUNT_PAYMENT_IN_PROGRESS_ERRORS:
      return {
        ...state,
        newPaymentSubmissionErrors: action.newErrors,
      };
    case ADD_PAYMENT_TO_PAYMENT_LIST:
      if (action.payment) {
        state.data.unshift(action.payment);
      }
      return {
        ...state,
        data: state.data,
      };

    case PAYMENT_GET_ALL_ACCOUNT_PAYMENTS_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case PAYMENT_GET_ALL_ACCOUNT_PAYMENTS_RESPONSE:
      if (action.isReset) {
        state.data.items = action.data.items;
      } else {
        var newArray = state.data.items.concat(action.data.items); //jQuery.extend(true, state.data.accountPayments.items, action.data.items);
        state.data.items = newArray;
      }
      return {
        ...state,
        data: state.data,
        error: action.error,
        isLoading: false,
        accountPaymentsSummary: action.data.summary,
        accountPaymentsTotalRows: action.data.summary.totalRows,
      };

    case PAYMENT_GET_ALL_ACCOUNT_PAYMENTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case PAYMENT_GET_SELECTED_PAYMENT_LINE_ITEMS_REQUEST:
      return {
        ...state,
        selectedPaymentLineItemsAreLoading: true,
        selectedPaymentLineItems: {
          items: [],
          toDelete: [],
          toUpdate: [],
        },
        selectedPaymentLineItemsError: null,
      };
    case PAYMENT_GET_SELECTED_PAYMENT_LINE_ITEMS_RESPONSE:
      var newSelectedPaymentLineItems = {
        items: [],
        toDelete: [],
        toUpdate: [],
      };
      if (action.response && action.response.length > 0) {
        newSelectedPaymentLineItems.items = action.response;
      }
      return {
        ...state,
        selectedPaymentLineItemsAreLoading: false,
        selectedPaymentLineItems: newSelectedPaymentLineItems,
        selectedPaymentLineItemsError: null,
      };
    case PAYMENT_GET_SELECTED_PAYMENT_LINE_ITEMS_FAILURE:
      return {
        ...state,
        selectedPaymentLineItemsAreLoading: false,
        selectedPaymentLineItemsError: action.error,
      };
    case PAYMENT_GET_ACCOUNT_PAYMENTS_REQUEST:
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
      };

    case PAYMENT_GET_ACCOUNT_PAYMENTS_RESPONSE:
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
      };
    case GET_ACCOUNT_PAYMENT_PLANS_REQUEST:
      return {
        ...state,
        accountPaymentPlansIsLoading: true,
        accountPaymentPlansItemsError: null,
      };
    case UPDATE_ACCOUNT_PAYMENT_PLANS_STATE:
      return {
        ...state,
        paymentPlans: action.items,
        accountPaymentPlansItemsError: null,
      };
    case GET_ACCOUNT_PAYMENT_PLANS_RESPONSE:
      var newPaymentPlans = {
        items: action.response,
        toDelete: [],
        toUpdate: []
      };
      if (action.response && action.response.length > 0) {
        newPaymentPlans = {
          items: action.response,
          toDelete: [],
          toUpdate: []
        };
      }
      return {
        ...state,
        paymentPlans: newPaymentPlans,
        accountPaymentPlansIsLoading: false,
        accountPaymentPlansItemsError: null,
      };

    case GET_ACCOUNT_PAYMENT_PLANS_FAILURE:
      return {
        ...state,
        accountPaymentPlansIsLoading: false,
        accountPaymentPlansItemsError: action.error,
      };

    case REVERSE_ACCOUNT_PAYMENTS_REQUEST:
      return {
        ...state,
        isLoading: action.isLoading || false,
        error: null,
      };

    case REVERSE_ACCOUNT_PAYMENTS_RESPONSE:
      return {
        ...state,
        error: null,
      };
    case REVERSE_ACCOUNT_PAYMENTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case GET_BULK_CHECK_PAYMENT_DAILY_SUMMARY_REQ:
      return {
        ...state,
        bulkCheckCurrentSummaryIsLoading: action.isLoading || false,
        error: null,
      };

    case GET_BULK_CHECK_PAYMENT_DAILY_SUMMARY_RESP:
      return {
        ...state,
        bulkCheckCurrentSummary: action.bulkCheckCurrentSummary || null,
        bulkCheckCurrentSummaryIsLoading: false,
        error: action.error || null,
      };

    case PAYMENT_MAKE_ACCOUNT_PAYMENT_REQUEST:
      return {
        ...state,
        isLoading: action.isLoading,
        newPaymentError: null,
      };
    case CREATE_AUTO_PAYMENTS_REQUEST:
      return {
        ...state,
        newAutoPayError: null,
        newAutoPayIsLoading: action.isLoading
      };
    case CREATE_AUTO_PAYMENTS_RESPONSE:
      return {
        ...state,
        newAutoPayError: action.error,
        newAutoPayIsLoading: action.isLoading
      };
      case CREATE_AUTO_PAYMENTS_FAILURE:
        // state.newAutoPayError = action.error;
        return {
          ...state,
          newAutoPayError: action.error,
          newAutoPayIsLoading: action.isLoading
        };
    case GET_AUTO_PAYMENTS_REQUEST:
      return {
        ...state,
        autoPaymentsIsLoading: action.isLoading,
        autoPaymentsError: action.error || null,
      }
    case GET_AUTO_PAYMENTS_RESPONSE:
      state.accountAutoPayments.items = [];
      action.accountAutoPayments.map(item => {
        state.accountAutoPayments.items.push(item);
      })  
      return {
        ...state,
        accountAutoPayments: state.accountAutoPayments,
      }
    case GET_AUTO_PAYMENTS_FAILURE:
      return {
        ...state,
        autoPaymentsIsLoading: false,
        autoPaymentsError: action.error
      }
    case PAYMENT_MAKE_ACCOUNT_PAYMENT_RESPONSE:
      return {
        ...state,
        isLoading: action.isLoading,
        newPaymentError: action.error,
      };

    case PAYMENT_UPDATE_ACCOUNT_EXISTING_PAYMENT_REQUEST:
      return {
        ...state,
      };

    case PAYMENT_UPDATE_ACCOUNT_EXISTING_PAYMENT_RESPONSE:
      state.paymentUpdateSubmissionErrors.push(action.error);
      return {
        ...state,
        paymentUpdateSubmissionErrors: state.paymentUpdateSubmissionErrors,
      };

    case PAYMENT_GET_ACCOUNT_PAYMENTS_RESPONSE:
      //Sorts initial ledger by transaction date
      const sortedPayments = action.data
        .slice()
        .sort((a, b) => new Date(b.paymentDate) - new Date(a.paymentDate));
      action.data = sortedPayments;

      return {
        ...state,
        isLoading: false,
        error: action.error,
        data: action.data,
      };

    case PAYMENT_GET_ACCOUNT_PAYMENTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case PAYMENT_ACCOUNT_PAYMENTS_RESET:
      return Object.assign({}, paymentsInitialState);

    default:
      return state;
  }
};
