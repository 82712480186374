import { Grid } from '@material-ui/core'
import { EditableTable } from 'components/EditableTable'
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { getBillingPdfs } from '../api/actions';

import { connect, useDispatch } from "react-redux";

const BillingFilesTable = (props) => {

  const [billingPDFs, setbillingPDFs] = React.useState([]);

  useEffect(() => {
    console.log("billings props: ", props)
    if (props.billing.data.billingPDFs.items.length < 1) {
      props.getBillingPdfs().then(() => {
        setbillingPDFs(props.billing.data.billingPDFs)
      })

    }

  }, [props.billing.data])
  
  const fieldTitles = [
        "Type",
        "File Name",
        "Created",
        "Download"
      ];

  const fieldTypes = [
      "label",
      "label",
      "label",
      "previewBtn"
  ];

  const fieldNames = [
    "type",
    "fileName",
    "created",
    ""
  ]

  const onBillSelected = (item) => {
    window.open(item.url);
  };
  return (
   <>
      <Grid container>
          <Grid item xs={12}>
            <EditableTable
            tableTitle={"Billing PDFs On File"}
            objectArray={billingPDFs}
            canAddItems={false}
            isLoading={props.billing.billingPDFsLoading}
            canSave={false}
            fieldTitles={fieldTitles}
            fieldTypes={fieldTypes}
            fieldNames={fieldNames}
            readOnly={true}
            handlePreviewClickParentMethod={onBillSelected}
            />
          </Grid>
      </Grid>
   </>
  )
}
BillingFilesTable.propTypes = {
  apl: PropTypes.object.isRequired,
  billing: PropTypes.object.isRequired,
  getBillingPdfs: PropTypes.func.isRequired
};
function BillingFilesTableState(state){
  return {
    apl: state.apl,
    billing: state.billing
  };
}
export default connect(BillingFilesTableState, {getBillingPdfs})(BillingFilesTable);