//React Components and Hooks
import React, { useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import styled, { withTheme } from "styled-components/macro";
import { spacing } from "@material-ui/system";
//Material UI Components
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Breadcrumbs from "../../components/BreadCrumb"
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import moment from 'moment';
import Toolbar from '@material-ui/core/Toolbar';
import Skeleton from "@material-ui/lab/Skeleton";
import { LeftSidebar } from "layout-blueprints";
import { CSVLink, CSVDownload } from "react-csv";
import {
  Loop as LoopIcon,
  FilterList as FilterListIcon,
} from "@material-ui/icons";
//Custom Components
import BulkCheckEntryTable from '../../modules/payments/components/BulkCheckEntry';


import { setSnackbar } from "../../modules/snackbar/api/snackbar";
import { connect, useDispatch } from 'react-redux';
import {
  Card, Tab, Tabs,
  Divider as MuiDivider,
  Typography as MuiTypography,
  CircularProgress as MuiCircularProgress,
  TextField as MuiTextField, Box,
} from "@material-ui/core";


import { dateFormatter, txtBoxDateFormatter, formatMoney } from "../../setup/helpers";
// Redux Components
import PropTypes from 'prop-types';
import { login, logout } from '../../modules/user/api/actions';
import AccountPreviewsTable from '../../modules/accountsSearch/components/AccountPreviewsTable';
import PmBillPacketTable from '../../modules/bills/components/PmBillPacketTable';
import StandardHeader from '../../components/global/StandardHeader';
import { searchAccountsByFieldAndValue } from '../../modules/accountsSearch/api/actions';
import { getAnalyticalData, getAllPropertyPreviews, getAllAccountStatusCodes, getAllCustomerTypes, getAllPaymentMethodTypes } from '../../modules/apl/api/actions';
import { updateBulkCheck, createNewAccountPayment, resetBulkCheckEntry, getBulkCheckDailyPaymentsSummary } from '../../modules/payments/api/actions';
import { getOwnersWithStatementData } from '../../modules/owner/api/actions';
import { createOwnerJournal } from '../../modules/ownerJournal/api/actions';
//Custom Components
import NavFrame from "../../components/NavFrame"
import OwnersTable from 'modules/owner/components/OwnersTable';
//Custom Context
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const DashboardToolBarButton = styled(Button)`
  padding: 4px;
  min-width: 0;
  svg {
    width: 2em;
    height: 2em;
  }
`;

const emptyAccountExport = {
  AccountNumber: "",
  AccountName: "",
  Address: "",
  PaymentDate: "",
  CheckNumber: "",
  PaymentAmount: ""
}

const AccountTotalExport = (total) => {
  return {
    AccountNumber: "",
    AccountName: "",
    Address: "",
    PaymentDate: "",
    CheckNumber: "Total: ",
    PaymentAmount: formatMoney(total)
  }
}

const todayFileFormat = moment().format('MM-DD-YYYY');
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
//Styles
const useStyles = makeStyles((theme) => ({
  totalContainer: {
    backgroundColor: theme.palette.secondary.main,
    width: "97%",
    display: "flex",
    justifyContent: "flex-end",
    padding: "1.5rem",
  },
  toolbarButtons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  totalText: {
    color: theme.palette.secondary.contrastText
  },
  link: {
    textDecoration: "none",
    color: 'white',
    '&:hover': {
      fontWeight: "bold",
      textDecoration: "underline"
    }
  },
}))

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const TextField = styled(MuiTextField)(spacing);
const Divider = styled(MuiDivider)(spacing);

const BulkCheckEntryPage = (props) => {

  const [tabValue, setTabValue] = React.useState(0);
  const [isOwners, setIsOwners] = React.useState(false);
  const classes = useStyles()
  const dispatch = useDispatch();
  const onFieldChange = (event) => {
    if (event.target.type == "number") {
      props.payments.newBulkCheck[event.target.name] = Number(event.target.value);
    }
    else {
      props.payments.newBulkCheck[event.target.name] = event.target.value;
    }
    props.updateBulkCheck(props.payments.newBulkCheck);
  }

  const addAllPayments = (payments) => {
    if (payments.length > 0) {
      dispatch(setSnackbar(true, "warning", "Creating Account Payments"));
    }
    let typeID = -1;
    for (var x = 0; x < props.apl.genericTypes.paymentMethodType.data.length; x++) {
      if (props.apl.genericTypes.paymentMethodType.data[x].description == "Check") {
        typeID = props.apl.genericTypes.paymentMethodType.data[x].id;
      }
    }
    let ownerPayments = payments.filter(e => e.isOwner);
    console.log({isOwners})
    console.log(ownerPayments)
    if(ownerPayments.length > 0){
      const newJournals = ownerPayments.map( owner =>{
        const today = moment().format("YYYY/MM/DD");
        const newJournal =  {
          journalDate: today,
          ownerId: owner.id,
          statementId: owner.statementId,
          type: 'CRD',
          amount: (owner.amountCovered * -1) || 0,
          notes: 'Payment from Owner',

        };
          props.createOwnerJournal(newJournal, true).then(() => {
          props.getBulkCheckDailyPaymentsSummary();
          dispatch(setSnackbar(true, "success", "Journals created successfully"));
          })
      });
      
    } 
    if(!isOwners){
      payments.map(account => {
        const today = moment().format("MM/DD/YYYY");
        if (account.amountCovered && account.amountCovered != 0 && account.accountNum != "") {
          let paymentNote = "BULK CHECK PAYMENT - Check# " + props.payments.newBulkCheck.checkNumber;
          var newPayment = {
            acctNum: account.accountNum,
            paymentType: typeID,
            paymentNote: paymentNote,
            paymentAmount: (account.amountCovered || 0),
            paymentTypeDesc: "Check",
            routingNumber: "",
            accountNumber: "", //Bank account #
            nameOnCard: "",
            cardNumber: "",
            cardType: "",
            cardExpMonth: "",
            cardExpYear: "",
            cardCvv: "",
            status: "Posted",
            checkNumber: props.payments.newBulkCheck.checkNumber,
            paymentDate: today,
            transactionFee: 0,
            transactionTotal: 0,
          };
          props.createNewAccountPayment(account.uuid, newPayment).then(() => {
            props.getBulkCheckDailyPaymentsSummary();
            dispatch(setSnackbar(true, "success", "Payment created successfully"));
          });
        }
  
      });
    }
   
    dispatch(setSnackbar(true, "warning", "Finished processing new account payments, waiting on response...."));
    props.resetBulkCheckEntry();
  }
  const handleTabChange = (event, newValue) => {
    console.log({newValue})
    if(newValue != 2){
      setIsOwners(false)
    }
    setTabValue(newValue);
  };

  const handleRefreshClick = () => {
    props.getBulkCheckDailyPaymentsSummary();
  };

  const handleOwnerSelect = () => {
    props.resetBulkCheckEntry()
    setIsOwners(true)
  }

  useEffect(() => {
    props.getAllCustomerTypes();
    props.getAllAccountStatusCodes();
    props.getAllPaymentMethodTypes();
    props.getBulkCheckDailyPaymentsSummary();
    props.getAllPropertyPreviews();
    props.getOwnersWithStatementData();
    document.title = "Ubil+ Bulk Check Entry";
  }, [props.user])

  const exportCheckData = () => {
  };

  const ToolbarInfo = () => {
    if (props.payments.bulkCheckCurrentSummary != null) {
      return (
        <Grid container xs={12} spacing={1}>
          <Grid item xs={12}>
            <Grid container xs={12} spacing={1} className={classes.toolbarButtons}>
              <Grid item>
                <DashboardToolBarButton size="small" mr={2}
                  onClick={handleRefreshClick}>
                  <LoopIcon />
                </DashboardToolBarButton>
              </Grid >
              <Grid item>
                <Button variant="contained" color="secondary"><CSVLink className={classes.link} filename={`BulkCheckPaymentDailyReport_${props.payments.newBulkCheck.checkNumber}_${todayFileFormat}.csv`} data={props.payments.bulkCheckCurrentSummary.previewData}>Daily Report</CSVLink></Button>
              </Grid>
            </Grid>
          </Grid >

          <Grid item xs={3}>
            <Typography variant="h6" className={classes.typography}>
              {props.payments.bulkCheckCurrentSummary.checksPosted}
            </Typography>
            <Typography variant="body2" className={classes.typography}>
              Posted Checks:
            </Typography>
          </Grid >
          <Grid item xs={3}>
            <Typography variant="h6" className={classes.typography}>
              {formatMoney(props.payments.bulkCheckCurrentSummary.TotalValuePaid)}
            </Typography>
            <Typography variant="body2" className={classes.typography}>
              Amount Posted :
            </Typography>
          </Grid >
          <Grid item xs={3}>
            <Typography variant="h6" className={classes.typography}>
              {props.payments.bulkCheckCurrentSummary.accountsEffected}
            </Typography>
            <Typography variant="body2" className={classes.typography}>
              Accounts Effected :
            </Typography>
          </Grid >
          <Grid item xs={3}>
            <Typography variant="h6" className={classes.typography}>
              {props.payments.bulkCheckCurrentSummary.totalRows}
            </Typography>
            <Typography variant="body2" className={classes.typography}>
              Account Payments Made :
            </Typography>
          </Grid >
        </Grid >
      )
    }
    else if (props.payments.bulkCheckCurrentSummary == null && props.payments.bulkCheckCurrentSummaryIsLoading) {
      return (<>
        <Grid container xs={12} spacing={1}>
          <Grid item xs={12}>
            <Skeleton
              variant="rect"
              height="60px"
              style={{
                backgroundColor: `#bdbfbe`,
                padding: 6,
                margin: 6,
                marginBottom: 3,
              }}
            />
          </Grid>
        </Grid >
      </>)
    }
    else {
      return (<></>)
    }
  };
  var totalRemaining = props.payments.newBulkCheck.amountPaid;
  var tableData = [];
  if (props.payments.bulkCheckSelectedAccounts.items.length > 0) {
    var today = moment().format('YYYY/MM/DD HH:mm:ss');
    for (var x = 0; x < props.payments.bulkCheckSelectedAccounts.items.length; x++) {
      if (props.payments.bulkCheckSelectedAccounts.items[x].amountCovered > 0) {
        totalRemaining = (totalRemaining - props.payments.bulkCheckSelectedAccounts.items[x].amountCovered).toFixed(2);
        var name = props.payments.bulkCheckSelectedAccounts.items[x].lastName;
        if (props.payments.bulkCheckSelectedAccounts.items[x].firstName != null) {
          name = props.payments.bulkCheckSelectedAccounts.items[x].firstName + ", " + props.payments.bulkCheckSelectedAccounts.items[x].lastName;
        }
        var address = "NOT LISTED";
        if (props.payments.bulkCheckSelectedAccounts.items[x].serviceAddress1 != null) {
          address = props.payments.bulkCheckSelectedAccounts.items[x].serviceAddress1;
        }
        if (props.payments.bulkCheckSelectedAccounts.items[x].serviceAddress2 != null) {
          address = props.payments.bulkCheckSelectedAccounts.items[x].serviceAddress1 + ", " + props.payments.bulkCheckSelectedAccounts.items[x].serviceAddress2;
        }
        var newAccountExport = {
          AccountNumber: props.payments.bulkCheckSelectedAccounts.items[x].accountNum,
          AccountName: name,
          Address: address,
          PaymentDate: today,
          // Needs to be done so excel wont auto, format to scientific notation
          CheckNumber: `# ${props.payments.newBulkCheck.checkNumber}`,
          PaymentAmount: formatMoney(props.payments.bulkCheckSelectedAccounts.items[x].amountCovered)
        }
        tableData.push(newAccountExport);
      }
    }
    tableData.push(emptyAccountExport);
    tableData.push(AccountTotalExport(props.payments.newBulkCheck.amountPaid - totalRemaining));
  }
  return (
    <LeftSidebar>
      <StandardHeader title="Bulk Check Entry" rightSideToolBar={!!(props.user.details.administration || props.user.details.isSuperUser) && <ToolbarInfo />} />
      <Divider className="mb-3 mt-3" />
      {(props.user.details.accounting || props.user.details.administration || props.user.details.isSuperUser) ? (
      <Grid container >
        <Grid container xs={12} spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <TextField
                  my={2}
                  fullWidth
                  type="text"
                  id="checkNumber"
                  label="Check Number"
                  value={props.payments.newBulkCheck.checkNumber}
                  onChange={onFieldChange}
                  autoComplete={"off"}
                  //onBlur={() =>{ props.handleBillingProcessSubmission(props.billing.data.billInProgress, false); } }
                  variant="outlined"
                  name="checkNumber"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <TextField
                  my={2}
                  fullWidth
                  type="number"
                  id="amountPaid"
                  label="Amount Paid"
                  value={props.payments.newBulkCheck.amountPaid}
                  onChange={onFieldChange}
                  autoComplete={"off"}
                  //onBlur={() =>{ props.handleBillingProcessSubmission(props.billing.data.billInProgress, false); } }
                  variant="outlined"
                  name="amountPaid"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            {(totalRemaining == 0 && props.payments.newBulkCheck.amountPaid > 0 && props.payments.newBulkCheck.checkNumber != "" && tableData.length > 0) ?
              <Grid container spacing={2}>
                <Grid item >
                  <Button variant="contained" color="secondary"><CSVLink className={classes.link} filename={`BulkCheckPayment_${props.payments.newBulkCheck.checkNumber}_${todayFileFormat}.csv`} data={tableData}>Export</CSVLink></Button>
                </Grid>
                <Grid item >
                  <Button variant="contained" color="secondary" onClick={() => {
                    addAllPayments(props.payments.bulkCheckSelectedAccounts.items)
                  }}>POST PAYMENTS</Button>
                </Grid>
              </Grid>
              :
              <Grid container spacing={2}>
                <Grid item >
                  <Button variant="contained" color="secondary" disabled>EXPORT</Button>
                </Grid>
                <Grid item >
                  <Button variant="contained" color="secondary" disabled onClick={() => {
                    addAllPayments(props.payments.bulkCheckSelectedAccounts.items)
                  }}>POST PAYMENTS</Button>
                </Grid>
              </Grid>
            }
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Typography variant="h5" className={classes.typography}>
              Check Total Remaining: {formatMoney(totalRemaining)}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <AppBar position="static">
              <Tabs value={tabValue}
                onChange={handleTabChange}
                aria-label="Account Management Tabs"
                variant="scrollable"
                scrollButtons="auto">
                <Tab label="Accounts" {...a11yProps(0)} />
                <Tab label="PM Packets" {...a11yProps(1)} />
                <Tab label="Owners" {...a11yProps(2)} onClick={handleOwnerSelect} />
              </Tabs>
            </AppBar>
            <TabPanel value={tabValue} index={0}>
              <AccountPreviewsTable isBulkCheckEntry={true} minAccountObj={true} SearchOnTypeInput={false} />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <PmBillPacketTable isBulkCheckEntry={true}  />
            </TabPanel>
            <TabPanel value={tabValue} index={2} >
            <OwnersTable isBulkCheckEntry={true}/>
            </TabPanel>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <BulkCheckEntryTable />
          </Grid>

        </Grid>
      </Grid>) : (<h3>You do not have Bulk Check privileges</h3>)
                }
    </LeftSidebar >
  )
}

BulkCheckEntryPage.propTypes = {
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  billing: PropTypes.object.isRequired,
  accountsSearch: PropTypes.object.isRequired,
  payments: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  searchAccountsByFieldAndValue: PropTypes.func.isRequired,
  getAllPropertyPreviews: PropTypes.func.isRequired,
  getAllAccountStatusCodes: PropTypes.func.isRequired,
  getAllCustomerTypes: PropTypes.func.isRequired,
  updateBulkCheck: PropTypes.func.isRequired,
  getAllPaymentMethodTypes: PropTypes.func.isRequired,
  createNewAccountPayment: PropTypes.func.isRequired,
  resetBulkCheckEntry: PropTypes.func.isRequired,
  getBulkCheckDailyPaymentsSummary: PropTypes.func.isRequired,
  getOwnersWithStatementData: PropTypes.func.isRequired,
  createOwnerJournal : PropTypes.func.isRequired
}

// Component State
function BulkCheckEntryPageState(state) {
  return {
    user: state.user,
    apl: state.apl,
    billing: state.billing,
    accountsSearch: state.accountsSearch,
    payments: state.payments
  }
}
export default connect(BulkCheckEntryPageState, { logout, searchAccountsByFieldAndValue, getAllPropertyPreviews, getAllCustomerTypes, getAllAccountStatusCodes, updateBulkCheck, getAllPaymentMethodTypes, createNewAccountPayment, resetBulkCheckEntry, getBulkCheckDailyPaymentsSummary, getOwnersWithStatementData, createOwnerJournal })(BulkCheckEntryPage)