
import React, { useState, useEffect } from "react";

import {
  Button,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";

import { ExampleWrapperSimple } from '../../../layout-components';
import { setEmailTemplate, newEmailNotification, updateEmailState } from '../api/actions';

import FormsWysiwygEditorDraftJs from '../../../example-components/FormsWysiwygEditor/FormsWysiwygEditorDraftJs';

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const FormControl = styled(MuiFormControl)(spacing);
const TextField = styled(MuiTextField)(spacing);
const emptyEmail = {
  subject: "",
  message: "",
  email: ""
};

function AccountEmailPopupEditor(props) {
  const [sendingEmail, setSendingEmail] = React.useState(false);
  const [newEmail, setNewEmail] = React.useState(props.notification.outgoingEmail);

  const [targetAccountEmailLoaded, setTargetAccountEmailLoaded] = React.useState(false);
  const [targetEmail, setNewTargetEmail] = React.useState("");
  const [selectedTemplate, setSelectedTemplate] = React.useState("");
  const [subject, setNewSubject] = React.useState("");
  const [message, setNewMessage] = React.useState("");

  const dispatch = useDispatch();
  const onFieldChange = (event) => {
    // Gets the current vendor info data and updates it with the event's name and value:
    if (event.target.type == "number") {
      props.notification.outgoingEmail[event.target.name] = Number(event.target.value);
    } else {
      props.notification.outgoingEmail[event.target.name] = event.target.value;
    }
    if (event.target.name == "subject") {
      setNewSubject(event.target.value);
    }
    else if (event.target.name == "message") {
      setNewMessage(event.target.value);
    }
  };

  const handleSelectedTemplateChange = (event) => {
    setSelectedTemplate(event.target.value);
    props.notification.outgoingEmail.message = props.notification.data[event.target.value].message || "";
    props.notification.outgoingEmail.subject = props.notification.data[event.target.value].subject || "";
    setNewSubject(props.notification.outgoingEmail.subject);
    setNewMessage(props.notification.outgoingEmail.message);
  };

  const handleSendAccountEmail = () => {
    var outgoingEmail = props.notification.outgoingEmail;

    setSendingEmail(true);
    if (outgoingEmail.message != "" && outgoingEmail.subject != "" && outgoingEmail.email != "") {
      outgoingEmail.firstName = props.account.data.account.firstName;
      outgoingEmail.lastName = props.account.data.account.lastName;
      props.newEmailNotification(outgoingEmail).then(() => {
        if (props.notification.error == null) {
          setSelectedTemplate("");
          setNewSubject("");
          setNewMessage("");
          if (props.onCloseFormMethod) {
            props.onCloseFormMethod();
          }
        }
      });
    }
  };


  useEffect(() => {
    if (!props.account.isLoading && props.account.data.account && props.account.data.account.id) {
      if (!targetAccountEmailLoaded && props.account.data.account.id) {
        props.notification.outgoingEmail.email = props.account.data.account.email;
        setNewEmail(props.notification.outgoingEmail);
        setTargetAccountEmailLoaded(true)
      }
    }
  }, [props.account.isLoading]);

  return (
    <ExampleWrapperSimple sectionHeading="Custom Account Email">
      <Grid container spacing={2} lg={12}>
        <Grid item xs={12} lg={6}>
          <TextField
            label="Email"
            type="email"
            value={props.notification.outgoingEmail.email}
            required
            onChange={onFieldChange}
            name="email"
            fullWidth
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            error={props.notification.outgoingEmail["email"] == "" && sendingEmail}
            helperText={props.notification.outgoingEmail["email"] == "" && sendingEmail ? "Email required" : ""}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            label="Email Subject"
            value={subject}
            required
            onChange={onFieldChange}
            name="subject"
            fullWidth
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            error={props.notification.outgoingEmail["subject"] == "" && sendingEmail}
            helperText={props.notification.outgoingEmail["subject"] == "" && sendingEmail ? "Subject required" : ""}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Email Message"
            id="message"
            multiline={true}
            rows={10}
            autoComplete={"off"}
            rowsMax={10}
            variant="outlined"
            my={2}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            value={message}
            onChange={onFieldChange}
            name="message"
            error={props.notification.outgoingEmail["message"] == "" && sendingEmail}
            helperText={props.notification.outgoingEmail["message"] == "" && sendingEmail ? "Message required" : ""}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider className="my-4" />
        </Grid>
        <Grid item xs={12} lg={6}>
          <FormControl variant="outlined" fullWidth my={2}>
            <InputLabel id="selectedTemplateDropDownLabel">
              Email Template
            </InputLabel>
            <Select
              labelId="selectedTemplateDropDownLabel"
              id="selectedTemplateDropDown"
              onChange={handleSelectedTemplateChange}
              label="Preset Email Template"
              name="template"
              fullWidth
              value={selectedTemplate}
              my={2}
            >{[
              <MenuItem id="template1" value={"returnedPayment"}>Returned Payments</MenuItem>,
              <MenuItem id="template2" value={"returnedMail"}>Returned Mail</MenuItem>,
              <MenuItem id="template3" value={"winterReconnect"}>Winter Reconnect Order</MenuItem>,
              <MenuItem id="template4" value={"paymentPlan"}>Payment Plan</MenuItem>,
              <MenuItem id="template5" value={"medicalForm"}>Medical Form</MenuItem>,
            ]}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleSendAccountEmail}
          >
            Send Email
          </Button>
        </Grid>
      </Grid>
    </ExampleWrapperSimple>
  );
};

// Component Properties
AccountEmailPopupEditor.propTypes = {
  // Store objects:
  payments: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  notification: PropTypes.object.isRequired,
  newEmailNotification: PropTypes.func.isRequired,
  setEmailTemplate: PropTypes.func.isRequired,
  updateEmailState: PropTypes.func.isRequired,

};

// Component State
function AccountEmailPopupEditorState(state) {
  return {
    account: state.account,
    notification: state.notification,
    apl: state.apl,
  };
}
export default connect(AccountEmailPopupEditorState, { newEmailNotification, setEmailTemplate, updateEmailState })(AccountEmailPopupEditor);
