import {
  Button as MuiButton,
  Card,
  CardContent,
  CircularProgress as MuiCircularProgress,
  Divider as MuiDivider,
  Grid,
  LinearProgress as MuiLinearProgress,
  Typography
} from "@material-ui/core";

import { formatMoney } from "../../../setup/helpers";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import { spacing } from "@material-ui/system";
// Redux Components
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { EditableTable } from "../../../components/EditableTable";
import BillPreview from "./BillPreview";

const styles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  percentDif: {
    textAlign: "right",
    color: "green",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  loadingIcon: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  StatsIcon: {
    width: "32px",
    height: "32px",
    color: theme.palette.secondary.main,
    position: "absolute",
    right: "16px",
    top: "32px",
  },
  rightSideHeader: {
    display: "flex",
    alignItems: "flex-end",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  rightSideHeaderSubMenuToolBar: {
    display: "flex",
    alignItems: "flex-end",
    flexDirection: "row",
    justifyContent: "flex-end",
    margin: "0px",
    padding: "0px",
  },
  SubMenuToolBarIcon: {
    width: "25px",
    height: "25px",
    paddingBotom: "5px",
  },
  orange: {
    //color: theme.palette.getContrastText(deepOrange[500]),
  },
  UserBoxText: {
    textAlign: "center",
  },
  UserProfileDetailsButton: {
    padding: "5px",
  },
  AvatarText: {
    marginTop: "20%",
    height: "128px",
    width: "128px",
    textAlign: "center",
    alignSelf: "center",
  },
  AvatarIcon: {
    display: "inline-block",
    height: "128px",
    width: "128px",
    backgroundColor: "white",
    borderColor: theme.palette.primary.main,
    borderStyle: "solid",
  },
  AvatarBusinessIcon: {
    height: "128px",
    width: "128px",
    color: theme.palette.primary.main,
  },
  ChartWrapper: {
    height: "200px",
    maxHeight: "200px",
    minHeight: "200px",
  },
  AccountInfoGrid: {
    height: "100%",
    overflowY: "auto",
  },
  MissingAccountPropInfoGrid: {
    height: "100%",
  },
  BuildingIconText: {
    marginTop: "20%",
    height: "128px",
    width: "128px",
    textAlign: "center",
    alignSelf: "center",
  },
  BuildingIcon: {
    display: "inline-block",
    height: "150px",
    width: "150px",
    backgroundColor: "white",
    borderColor: theme.palette.primary.main,
    borderStyle: "solid",
    alignSelf: "center",
  },
}));

const LinearProgress = MuiLinearProgress;
const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SelectedBillingInformationCard = (props) => {
  const history = useHistory();
  const useStyles = styles;
  const classes = useStyles();

  const fieldTitles = ["Name", "Type", "Basis", "Rate", "Amt"];
  const fieldTypes = [
    "label",
    "label",
    "label",
    "label",
    "moneyLabel",
  ];
  const fieldNames = [
    "riderName",
    "riderType",
    "rateBasis",
    "effectiveRate",
    "riderAmount",
  ]; // Must be the exact name of the properties you wanna show
  if (props.billing.billExpandedViewIsLoading) {
    return (
      <>
        <Skeleton
          variant="rect"
          height="400px"
          style={{
            backgroundColor: `#bdbfbe`,
            padding: 6,
            margin: 6,
            marginBottom: 3,
          }}
        />
      </>
    )
  };

  const riders = props.billing.data.selectedBill.riders || props.bill.riders || [];
  const selectedBill = props.billing.data.selectedBill.bill || props.bill;
  return (
    <Card mb={6}>
      <CardContent xs={12} sm={12} md={12} lg={12}>
        <Grid container xs={12} sm={12} md={12} lg={12}>
        </Grid>
        <Divider className="mb-2 mt-2" my={1} />
        <Grid
          container
          spacing={4}
          lg={12}
          className={classes.AccountInfoGrid}
        >
          <Grid item xs={12} lg={12} >
            <BillPreview />
          </Grid>
          <Grid item lg={12} md={12} sm={12}>
            <Grid container spacing={4}>
              <Grid item lg={6} md={6} sm={6}>
                <Typography variant="h5" className={classes.typography}>
                  Riders Total: {formatMoney(selectedBill.utilRidersTotal) || 0}
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6}>
                <Typography variant="h5" className={classes.typography}>
                  Energy Charge: {formatMoney(selectedBill.utilEnergyCharge) || 0}
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6}>
                <Typography variant="h5" className={classes.typography}>
                  Customer Charge: {formatMoney(selectedBill.utilCustomerCharge) || 0}
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6}>
                <Typography variant="h5" className={classes.typography}>
                  Total Utility Charge: {formatMoney(selectedBill.totalCharge) || 0}
                </Typography>
              </Grid>
              <Grid item lg={12} md={12} sm={12}>
                <Divider className="mb-2 mt-2" my={1} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={12}>
            <EditableTable
              // Functional methods of table
              // Properties of table
              tableTitle={"Rider Rates"}
              readOnly={true}
              canAddItems={false}
              canExport={true}
              canSave={false}
              objectArray={riders}
              fieldTitles={fieldTitles}
              fieldTypes={fieldTypes}
              fieldNames={fieldNames}
              showButtons={false}
              isPagedLocally={false}
              denseTable={false}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

// Component Properties
SelectedBillingInformationCard.propTypes = {
  // Store objects:
  account: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  billing: PropTypes.object.isRequired,
};

// Component State
function SelectedBillingInformationCardState(state) {
  return {
    account: state.account,
    apl: state.apl,
    billing: state.billing,
  };
}
export default connect(
  SelectedBillingInformationCardState,
  {}
)(SelectedBillingInformationCard);
