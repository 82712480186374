//React Components and Hooks
//Material UI Components
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import MuiAlert from "@material-ui/lab/Alert";
import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
//API
//Images
import logo from "../../assets/images/logo-dark.png";
//Context

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#fff",
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
const ForgotPassword = () => {
  let [state, setState] = useState({
    fail: false,
    success: false,
    errorMessage: "",
  });
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const emailRef = useRef();
  const classes = useStyles();

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/customer/password-reset`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: emailRef.current.value,
          }),
        }
      );
      if (response.status === 200) {
        setState({ ...state, success: true });
        setOpen(true);
      } else {
        setState({ ...state, fail: true, errorMessage: response.message });
        setOpen(true);
      }
    } catch (err) {
      throw err;
    }
  };
  return (
    <Container className={classes.container} component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img src={logo} alt="apl" />
        <Typography component="h1" variant="h1">
          Ubil+
        </Typography>
        <Typography component="h1" variant="h5">
          Columbus& Central Ohio Systems
        </Typography>
        <Typography component="h1" variant="h6">
          INTERNAL ONLY
        </Typography>
        {state.success ? (
          <Typography
            align="center"
            color="secondary"
            component="h3"
            variant="h4"
          >
            A link was sent to the email associated with your account
          </Typography>
        ) : (
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            inputRef={emailRef}
            label="Email Address"
            name="email"
            error={state.fail}
            autoComplete="email"
            autoFocus
            required
          />
        )}
      </div>
      {state.fail ? (
        <Typography
          align="center"
          color="secondary"
          component="h3"
          variant="h4"
        >
          {state.errorMessage}
        </Typography>
      ) : (
        <div />
      )}
      <Grid container justify="space-around">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={() => history.push("/login")}
        >
          BACK
        </Button>
        {state.success ? (
          <div />
        ) : (
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            className={classes.submit}
            onClick={handleClick}
          >
            submit
          </Button>
        )}
      </Grid>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        {state.fail ? (
          <MuiAlert onClose={handleClose} severity="success">
            A link was sent to the email associated with your account
          </MuiAlert>
        ) : (
          <MuiAlert onClose={handleClose} severity="error">
            ERROR MESSAGE WILL GO HERE{state.errorMessage}
          </MuiAlert>
        )}
      </Snackbar>
    </Container>
  );
};
export default ForgotPassword;
//URL: "/customer/password-reset"
//
