
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import { FilterList as FilterListIcon } from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import React from "react";
import { CSVLink } from "react-csv";
import styled from "styled-components/macro";

import {
  Checkbox, Grid, IconButton, Link, Breadcrumbs as MuiBreadcrumbs, Divider as MuiDivider, Paper as MuiPaper, Table, TableBody, TableContainer, TableCell,
  TableHead, TablePagination, Chip, TableRow, TableSortLabel, Toolbar, Tooltip, Typography, FormControlLabel, Switch, Menu, MenuItem, TextField as MuiTextField, Button as MuiButton,
  FormControl, InputLabel, Select, Input,
} from "@material-ui/core";

const Button = styled(MuiButton)(spacing);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// Tool Bar Expects: {tableTitle, fieldTitles(Array), fieldTypes(Array), fieldNames(Array), numSelected }
export function EnhancedTableToolbar({
  fieldTitles,
  fieldTypes,
  fieldNames,
  handleNewItemCreation,
  handleSaveData,
  handleRefreshData,
  handleDeletData,
  tableTitle,
  numSelected = 0,
  itemsSelected = null,
  readOnly,
  canSave,
  canAddItems,
  canExport,
  selectedItems,
  exportData,
  CustomToolbar,
  customSelectItemActionButtonLabel,
  customHandleSelectItemAction,
  handleCondOnSelectButtonOnClickMethodMethod,
  CustomFilters,
  activeFilters,
  condOnSelectButtons,
  ConditionalOnSelectToolbar,
  canDelete,
  canDeleteEvaluator,
  ConfirmDeletion,
  toUpdateLength = 0
}) {
  const useStyles = makeStyles((theme) => {
    return {
      dropdown: {
        width: "14rem",
      },
      modalcontainer: {
        width: "60%",
        height: "60%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "15px",
        padding: "3rem",
      },
      link: {
        textDecoration: "none",
        color: "#ffffff",
        "&:hover": {
          fontWeight: "bold",
          textDecoration: "underline",
        },
      },

      rightSideHeader: {
        display: "flex",
        alignItems: "flex-end",
        flexDirection: "row",
        justifyContent: "flex-end",
      },

      leftSideHeader: {
        display: "flex",
        justifyContent: "left",
        alignItems: "left",
      },
      tableHeaderText: {
        fontWeight: "bold",
      },
      exportBtn: {
        paddingRight: 2,
      },
      filterPopupContent: {
        marginBottom: "20px",
        overflowY: "visible",
      },
      filterPopup: {
        height: "auto",
      },
    };
  });
  const classes = useStyles();

  const handleDeleteClick = (event) => {
    handleDeletData();
    setConfirmDeltionOpen(false);
  };

  const handleAddNewItem = () => {
    handleNewItemCreation();
  };

  const handleSaveClick = () => {
    handleSaveData();
  };

  const handleRefresh = () => {
    handleRefreshData();
  };

  const [filtersOpen, setFiltersOpen] = React.useState(false);
  const [confirmDeltionOpen, setConfirmDeltionOpen] = React.useState(false);

  const handleFiltersClickOpen = () => {
    setFiltersOpen(true);
  };

  const handleFiltersClose = () => {
    setFiltersOpen(false);
  };


  const handleConfirmDeletionClose = () => {
    setConfirmDeltionOpen(false);
  };
  const handleConfirmDeletionClickOpen = () => {
    setConfirmDeltionOpen(true);
  };


  let deletableItems = 0;
  return (
    <>
      <Toolbar>
        <Grid container>
          <Grid item lg={5} md={12} sm={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography component={"span"} variant="h5" id="tableTitle">
                  {tableTitle}
                </Typography>
                {numSelected > 0 && (
                  <Typography component={"span"} color="inherit" variant="h6">
                    {" "}
                    {numSelected} selected{" "}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} className={"mb-2"}>
                <Grid container spacing={1}>
                  {activeFilters.map((filter, index) => {
                    if (filter != null && filter != "All") {
                      return (
                        <Grid item>
                          <Chip
                            label={filter}
                            size="small"
                            onClick={handleFiltersClickOpen}
                            color="primary"
                          />
                        </Grid>
                      )
                    }
                  })
                  }
                </Grid>

              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={7} md={12} sm={12} >
            <Grid container spacing={2} className={classes.rightSideHeader}>
              {CustomFilters && (
                <>
                  <Grid item>
                    <Tooltip title="Filter list">
                      <IconButton
                        aria-label="Filter list"
                        onClick={handleFiltersClickOpen}
                      >
                        <FilterListIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </>
              )}
              {canAddItems && tableTitle !== 'Units' &&(
                <Grid item>
                  <Button mr={1} variant="contained" onClick={handleAddNewItem}>
                    New Item
                  </Button>
                </Grid>
              )}
              {(canSave /* showing of save button only when toUpdate size not 0? */) && (
                <Grid item>
                  <Button
                    mr={1}
                    variant="contained"
                    color="secondary"
                    onClick={handleSaveClick}
                  >
                    Save
                  </Button>
                </Grid>
              )}
              {numSelected > 0 && customHandleSelectItemAction ? (
                <Grid item>
                  <Button
                    mr={1}
                    variant="contained"
                    color="primary"
                    onClick={customHandleSelectItemAction}
                  >
                    {customSelectItemActionButtonLabel ||
                      "Unknown Custom Action Button LBL"}
                  </Button>
                </Grid>
              ) : (
                <></>
              )}
              {numSelected > 0 &&
                condOnSelectButtons &&
                condOnSelectButtons.map((condButton, key) => {
                  if (condButton.conditionalShowStatement()) {
                    return (
                      <Grid item>
                        <Button
                          mr={1}
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            handleCondOnSelectButtonOnClickMethodMethod(
                              condButton.onSelectMethod
                            )
                          }
                        >
                          {condButton.buttonLabel ||
                            "Unknown Conditional Button LBL"}
                        </Button>
                      </Grid>
                    );
                  } else {
                    return <></>;
                  }
                })}
              {(numSelected > 0 && canDelete) && (
                <Grid item>
                  {ConfirmDeletion ?
                    <Button
                      mr={1}
                      variant="contained"
                      color="primary"
                      onClick={handleConfirmDeletionClickOpen}
                    >
                      Delete
                    </Button>
                    :
                    <Button
                      mr={1}
                      variant="contained"
                      color="primary"
                      onClick={handleDeleteClick}
                    >
                      Delete
                    </Button>

                  }
                </Grid>
              )}
              {(numSelected > 0 && ConditionalOnSelectToolbar) && (
                <ConditionalOnSelectToolbar />
              )}
              {(canExport && exportData.length > 0) && (
                <Grid item>
                  <Button variant="contained" color="secondary">
                    <CSVLink
                      filename={`${tableTitle}-${moment.utc().format('MMMM Do YYYY, h:mm:ss a')}.csv`}
                      data={exportData}
                    >
                      Export
                    </CSVLink>
                  </Button>
                </Grid>
              )}
            </Grid>
            {CustomToolbar && CustomToolbar}
          </Grid>
        </Grid>
      </Toolbar>
      {ConfirmDeletion && (
        <Dialog
          className={classes.filterPopup}
          open={confirmDeltionOpen}
          TransitionComponent={Transition}
          fullWidth={"lg"}
          maxWidth={"lg"}
          keepMounted
          onClose={handleConfirmDeletionClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            Are you sure you would like to delete the following items? (Red Items are unable to be deleted at this time)
          </DialogTitle>
          <DialogContent className={classes.filterPopupContent}>
            <Grid container xs={12} spacing={3}>
              <Grid item xs={12}>
                <TableContainer>
                  <Table
                    aria-labelledby="tableTitle"
                    size={"small"}
                    aria-label="enhanced table"
                  >
                    <TableHead>
                      <TableRow>
                        {fieldTitles.map((title, index) => {
                          return (
                            <TableCell
                              component="th"
                              key={index}
                              align={"left"}
                              padding={"default"} // Padding changing not yet implemented
                              className={classes.tableHeaderText}
                            >
                              {fieldNames[index] && fieldNames[index] != "" &&
                                <TableSortLabel>
                                  {title}
                                </TableSortLabel>
                              }
                            </TableCell>
                          )
                        }
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <>
                        {selectedItems.map((currentObject, index) => {
                          let canDelete = canDeleteEvaluator(currentObject);
                          if (canDelete) {
                            deletableItems++;
                            return (
                              <TableRow hover tabIndex={-1} key={"validateDelete_" + currentObject.id}>
                                {fieldNames.map((fieldName) => {
                                  if (!Array.isArray(fieldName)) {
                                    return (
                                      <TableCell align="left" key={"validateDelete_" + currentObject.id + "Cell" + index}>
                                        <Grid container direction="column" justify="left" alignItems="stretch" spacing={0}>
                                          <Grid item>
                                            <Typography component={'span'} name={fieldName}>
                                              {currentObject[fieldName]}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </TableCell>
                                    )
                                  }
                                  else {
                                    return (
                                      <TableCell align="left" key={"validateDelete_" + currentObject.id + "Cell" + index}>
                                        <Grid container direction="column" justify="left" alignItems="stretch" spacing={0}>
                                          <Grid item>
                                            {fieldName.map((field) => {
                                              return (
                                                <Typography component={'span'} name={field}>
                                                  {currentObject[field]}
                                                </Typography>
                                              )
                                            })
                                            }
                                          </Grid>
                                        </Grid>
                                      </TableCell>
                                    )
                                  }
                                })
                                }
                              </TableRow>
                            )
                          }
                          else {
                            return (
                              <TableRow hover tabIndex={-1} key={"validateDelete_" + currentObject.id} style={{ backgroundColor: '#ffaaaa' }}>
                                {fieldNames.map((fieldName) => {
                                  if (!Array.isArray(fieldName)) {
                                    return (
                                      <TableCell align="left" key={"validateDelete_" + currentObject.id + "Cell" + index}>
                                        <Grid container direction="column" justify="left" alignItems="stretch" spacing={0}>
                                          <Grid item>
                                            <Typography component={'span'} name={fieldName}>
                                              {currentObject[fieldName]}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </TableCell>
                                    )
                                  }
                                  else {
                                    return (
                                      <TableCell align="left" key={"validateDelete_" + currentObject.id + "Cell" + index}>
                                        <Grid container direction="column" justify="left" alignItems="stretch" spacing={0}>
                                          <Grid item>
                                            {fieldName.map((field) => {
                                              return (
                                                <Typography component={'span'} name={field}>
                                                  {currentObject[field]}
                                                </Typography>
                                              )
                                            })
                                            }
                                          </Grid>
                                        </Grid>
                                      </TableCell>
                                    )
                                  }
                                })
                                }
                              </TableRow>
                            )
                          }
                        })
                        }
                      </>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              {deletableItems > 0 ?
                <Grid item xs={6}>
                  <Button
                    mr={1}
                    variant="contained"
                    color="primary"
                    onClick={handleDeleteClick}
                  >
                    Confirm
                  </Button>
                </Grid>
                :
                <Grid item xs={6}>
                  <Button
                    mr={1}
                    variant="contained"
                    fullWidth={true}
                    color="primary"
                    disabled={true}
                  >
                    No valid items selected for deletion
                  </Button>
                </Grid>
              }
              <Grid item xs={6}>
                <Button
                  mr={1}
                  variant="contained"
                  fullWidth={true}
                  color="secondary"
                  onClick={handleConfirmDeletionClose}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      )
      }
      {CustomFilters && (
        <Dialog
          className={classes.filterPopup}
          open={filtersOpen}
          TransitionComponent={Transition}
          fullWidth={"lg"}
          maxWidth={"lg"}
          keepMounted
          onClose={handleFiltersClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">Filters</DialogTitle>
          <DialogContent className={classes.filterPopupContent}>
            {CustomFilters}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}

export function EnhancedTableHead({
  fieldTitles,
  showNumber,
  fieldTypes,
  fieldNames,
  onSelectAllClick,
  // new order by item {fieldname: name, direction: dir (asc, desc)}
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  readOnly,
}) {
  const useStyles = makeStyles((theme) => {
    return {
      tableHeaderText: {
        fontSize: "105%",
      },
    };
  });
  const classes = useStyles();


  // new order by item {fieldname: name, direction: dir (asc, desc)}
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const handleSelectAllClicked = (event) => {
    if (onSelectAllClick) {
      onSelectAllClick(event);
    }
  };

  return (
    <TableHead>
      <TableRow>
        {!readOnly && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={handleSelectAllClicked}
              inputProps={{ "aria-label": "select all line items" }}
            />
          </TableCell>
        )}
        {showNumber && <TableCell style={{ paddingLeft: "0px" }}>#</TableCell>}
        {fieldTitles.map((title, index) => {
          // new order by item {fieldname: name, direction: dir (asc, desc)}
          // Must see if the current field name is a sorted one
          var isColumnForSort = false;
          if (Array.isArray(fieldNames[index])) {
            isColumnForSort = orderBy.find(x => x.fieldname === fieldNames[index][0]);
          }
          else {
            isColumnForSort = orderBy.find(x => x.fieldname === fieldNames[index]);
          }
          return (
            <TableCell
              component="th"
              key={index}
              align={"left"}
              padding={!readOnly ? "none" : "default"} // Padding changing not yet implemented
              sortDirection={isColumnForSort ? isColumnForSort.direction : "asc"}
              className={classes.tableHeaderText}
            >
              {fieldNames[index] && fieldNames[index] != "" &&
                <TableSortLabel
                  active={isColumnForSort ? true : false}
                  direction={isColumnForSort ? isColumnForSort.direction : "asc"}
                  onClick={createSortHandler(index)}
                >
                  {title}
                </TableSortLabel>
              }
            </TableCell>
          )
        }
        )}
      </TableRow>
    </TableHead>
  );
}
