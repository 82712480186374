import {
  handleBillingProcessSubmission,
} from "../../../../modules/billing/api/actions";

import {formatMoney} from "../../../../setup/helpers";

import {
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core";

import React from "react";
import {connect, useDispatch} from "react-redux";
import {updateBillInProgressBillCalculation} from "../../../../modules/billing/api/actions";
import styled from "styled-components/macro";
import {spacing} from "@material-ui/system";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import PropTypes from "prop-types";

const Divider = styled(MuiDivider)(spacing);
const FormControl = styled(MuiFormControl)(spacing);
const TextField = styled(MuiTextField)(spacing);
const SpacedCurrencyTextField = styled(CurrencyTextField)(spacing);

const VendorBillForm = (props) => {

  const [vendorFormObject, setVendorFormObject] = React.useState(
    props.billing.data.billInProgress.vendorInformation
  );

  const [measureTypeValue, setMeasureTypeValue] = React.useState(
    props.billing.data.billInProgress.vendorInformation.measureUnitType
  );

  const dispatch = useDispatch();

  const onMeasureTypeChange = (event) => {
    setMeasureTypeValue(event.target.value);
    onFieldChange(event);
  };

  const onFieldChange = (event) => {
    const {name, value} = event.target;
    const {vendorInformation} = props.billing.data.billInProgress;

    if (event.target.type === "number" || event.target.type === "text") {
      vendorInformation[name] = Number(value.replace(/,/g, ''));
    }  else {
      vendorInformation[name] = value;
    }

    let {
      vendorBillPercentage,
      measureUnitType,
      cfUsage,
      waterUsageCharge,
      waterBaseCharge,
      fireUsageBaseCharge,
      backFlowCharge,
      otherCharges,
      sewerCharge,
      sewerServiceFee,
      stormWaterCharge,
      cleanRiverFee,
    } = vendorInformation;

    let measureUnit;
    if (measureUnitType === 'CF') measureUnit = 1;
    else if (measureUnitType === 'CcF') measureUnit = 100;
    else if (measureUnitType === 'GAL') measureUnit = 1 / 7.48;
    else {
      measureUnit = 1;
    }

    let usageTotal = cfUsage * measureUnit * vendorBillPercentage;
    let waterUsageChargeTotal = (parseFloat(waterUsageCharge) + parseFloat(waterBaseCharge) + parseFloat(fireUsageBaseCharge) + parseFloat(backFlowCharge) + parseFloat(otherCharges)) * vendorBillPercentage;
    let sewerUsageChargeTotal = (parseFloat(sewerCharge) + parseFloat(sewerServiceFee)) * vendorBillPercentage;
    let stormWaterTotal = parseFloat(stormWaterCharge) * vendorBillPercentage;
    let cleanRiverTotal = parseFloat(cleanRiverFee) * vendorBillPercentage;
    let waterRate = usageTotal !== 0 ? (waterUsageChargeTotal / usageTotal) : 0;
    let billTotal = waterUsageChargeTotal + sewerUsageChargeTotal + stormWaterTotal + cleanRiverTotal;
    let waterAndSewerChargeTotal = waterUsageChargeTotal + sewerUsageChargeTotal + stormWaterTotal + cleanRiverTotal

    Object.assign(vendorInformation, {
      usageTotal,
      waterUsageChargeTotal,
      sewerUsageChargeTotal,
      stormWaterTotal,
      cleanRiverTotal,
      billTotal,
      waterRate,
      waterAndSewerChargeTotal,
    });

    dispatch(updateBillInProgressBillCalculation({vendorInformation}));
    setVendorFormObject(vendorInformation)
  };

  return (
    <>
      <Grid item lg={4} md={4} sm={12}>
          <TextField
            my={2}
            fullWidth
            type="number"
            id="vendorBillPercentage"
            autoComplete={"off"}
            label="Vendor Bill Percentage"
            defaultValue={vendorFormObject.vendorBillPercentage}
            onChange={onFieldChange}
            name="vendorBillPercentage"
          />

      </Grid>
      <Grid item lg={12} md={12} sm={12}>
        <Divider className="mb-3 mt-3"/>
      </Grid>
      {/*Water Usage*/}
      <Grid item lg={12} md={12} sm={12}>
        <Typography variant="body1">
          Water Usage Total: CF {(props.billing.data.billInProgress.vendorInformation.usageTotal || 0).toFixed(2)}
        </Typography>
      </Grid>
      <Grid item lg={4} md={4} sm={12}>
        <TextField
          my={2}
          fullWidth
          type="number"
          id="cfUsage"
          autoComplete={"off"}
          label="Water Usage, Actual Usage Amount"
          defaultValue={vendorFormObject.cfUsage}
          onChange={onFieldChange}
          name="cfUsage"
        />
      </Grid>
      <Grid item lg={4} md={4} sm={12}>
        <FormControl fullWidth my={2}>
          <InputLabel id="fiscalMonthDropDownLabel">
            Measure Unit
          </InputLabel>
          <Select
            labelId="measureUnitType"
            id="measureUnitType"
            name="measureUnitType"
            autoComplete={"off"}
            defaultValue={measureTypeValue}
            fullWidth
            my={2}
            onChange={onMeasureTypeChange}
          >
            <MenuItem value={'CF'}>CF</MenuItem>
            <MenuItem value={'CcF'}>CcF</MenuItem>
            <MenuItem value={'GAL'}>GAL</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item lg={12} md={12} sm={12}>
        <Divider className="mb-3 mt-3"/>
      </Grid>
      {/*Water Usage Charge*/}
      <Grid item lg={12} md={12} sm={12}>
        <Typography variant="body1">
          Water Usage Charge
          Total: {formatMoney(props.billing.data.billInProgress.vendorInformation.waterUsageChargeTotal)}
        </Typography>
      </Grid>
      <Grid item lg={4} md={4} sm={12}>
        <SpacedCurrencyTextField
          id="waterUsageCharge"
          label="Water Usage Charge (USD)"
          onChange={onFieldChange}
          autoComplete={"off"}
          name="waterUsageCharge"
          variant="standard"
          defaultValue={vendorFormObject.waterUsageCharge}
          currencySymbol="$"
          outputFormat="number"
          decimalCharacter="."
          digitGroupSeparator=","
          minimumValue="0"
          fullWidth
          my={2}
        />
      </Grid>
      <Grid item lg={4} md={4} sm={12}>
        <SpacedCurrencyTextField
          id="waterBaseCharge"
          label="Water Base Charge (USD)"
          onChange={onFieldChange}
          autoComplete={"off"}
          name="waterBaseCharge"
          variant="standard"
          defaultValue={vendorFormObject.waterBaseCharge}
          currencySymbol="$"
          outputFormat="number"
          decimalCharacter="."
          digitGroupSeparator=","
          minimumValue="0"
          fullWidth
          my={2}
        />
      </Grid>
      <Grid item lg={4} md={4} sm={12}>
        <SpacedCurrencyTextField
          id="fireUsageBaseCharge"
          label="Fire Usage Base Charge (USD)"
          onChange={onFieldChange}
          autoComplete={"off"}
          name="fireUsageBaseCharge"
          variant="standard"
          defaultValue={vendorFormObject.fireUsageBaseCharge}
          currencySymbol="$"
          outputFormat="number"
          decimalCharacter="."
          digitGroupSeparator=","
          minimumValue="0"
          fullWidth
          my={2}
        />
      </Grid>
      <Grid item lg={4} md={4} sm={12}>
        <SpacedCurrencyTextField
          id="backFlowCharge"
          label="Backflow Charge (USD)"
          onChange={onFieldChange}
          autoComplete={"off"}
          name="backFlowCharge"
          variant="standard"
          defaultValue={vendorFormObject.backFlowCharge}
          currencySymbol="$"
          outputFormat="number"
          decimalCharacter="."
          digitGroupSeparator=","
          minimumValue="0"
          fullWidth
          my={2}
        />
      </Grid>
      <Grid item lg={4} md={4} sm={12}>
        <SpacedCurrencyTextField
          id="otherCharges"
          label="Other Charges (USD)"
          onChange={onFieldChange}
          autoComplete={"off"}
          name="otherCharges"
          variant="standard"
          defaultValue={vendorFormObject.otherCharges}
          currencySymbol="$"
          outputFormat="number"
          decimalCharacter="."
          digitGroupSeparator=","
          minimumValue="0"
          fullWidth
          my={2}
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12}>
        <Divider className="mb-3 mt-3"/>
      </Grid>
      {/*Water Rate for BILL type*/}
      {props.propertyUtilities.data.propertyUtility?.billingType === 'BILL' &&
        <>
          <Grid item lg={12} md={12} sm={12}>
            <Typography variant="body1">
              Water Rate: {props.billing.data.billInProgress.vendorInformation.waterRate}
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12}>
            <Divider className="mb-3 mt-3"/>
          </Grid>
        </>
      }
      {/*Sewer Usage Charge*/}
      <Grid item lg={12} md={12} sm={12}>
        <Typography variant="body1">
          Sewer Usage Charge
          Total: {formatMoney(props.billing.data.billInProgress.vendorInformation.sewerUsageChargeTotal)}
        </Typography>
      </Grid>
      <Grid item lg={4} md={4} sm={12}>
        <SpacedCurrencyTextField
          id="sewerCharge"
          label="Sewer Charge (USD)"
          onChange={onFieldChange}
          autoComplete={"off"}
          name="sewerCharge"
          variant="standard"
          defaultValue={vendorFormObject.sewerCharge}
          currencySymbol="$"
          outputFormat="number"
          decimalCharacter="."
          digitGroupSeparator=","
          minimumValue="0"
          fullWidth
          my={2}
        />
      </Grid>
      <Grid item lg={4} md={4} sm={12}>
        <SpacedCurrencyTextField
          id="sewerServiceFee"
          label="Sewer Service Fee (USD)"
          onChange={onFieldChange}
          autoComplete={"off"}
          name="sewerServiceFee"
          variant="standard"
          defaultValue={vendorFormObject.sewerServiceFee}
          currencySymbol="$"
          outputFormat="number"
          decimalCharacter="."
          digitGroupSeparator=","
          minimumValue="0"
          fullWidth
          my={2}
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12}>
        <Divider className="mb-3 mt-3"/>
      </Grid>
      {/*Storm Water Total*/
      }
      <Grid item lg={12} md={12} sm={12}>
        <Typography variant="body1">
          Storm Water Total: {formatMoney(props.billing.data.billInProgress.vendorInformation.stormWaterTotal)}
        </Typography>
      </Grid>
      <Grid item lg={4} md={4} sm={12}>
        <SpacedCurrencyTextField
          id="stormWaterCharge"
          label="Storm Water Charge (USD)"
          onChange={onFieldChange}
          autoComplete={"off"}
          name="stormWaterCharge"
          variant="standard"
          defaultValue={vendorFormObject.stormWaterCharge}
          currencySymbol="$"
          outputFormat="number"
          decimalCharacter="."
          digitGroupSeparator=","
          minimumValue="0"
          fullWidth
          my={2}
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12}>
        <Divider className="mb-3 mt-3"/>
      </Grid>
      {/*Clean River Total*/
      }
      <Grid item lg={12} md={12} sm={12}>
        <Typography variant="body1">
          Clean River Total: {formatMoney(props.billing.data.billInProgress.vendorInformation.cleanRiverTotal)}
        </Typography>
      </Grid>
      <Grid item lg={4} md={4} sm={12}>
        <SpacedCurrencyTextField
          id="cleanRiverFee"
          label="Clean River Fee (USD)"
          onChange={onFieldChange}
          autoComplete={"off"}
          name="cleanRiverFee"
          variant="standard"
          defaultValue={vendorFormObject.cleanRiverFee}
          currencySymbol="$"
          outputFormat="number"
          decimalCharacter="."
          digitGroupSeparator=","
          minimumValue="0"
          fullWidth
          my={2}
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12}>
        <Divider className="mb-3 mt-3"/>
      </Grid>
      <Grid item lg={12} md={12} sm={12}>
        <Typography variant="body1">
          Vendor Bill Total: {formatMoney(props.billing.data.billInProgress.vendorInformation.billTotal)}
        </Typography>
      </Grid>
    </>
  )
    ;
};

VendorBillForm.propTypes = {
  billing: PropTypes.object.isRequired,
  handleBillingProcessSubmission: PropTypes.func.isRequired,
};

function VendorBillCalculationState(state) {
  return {
    billing: state.billing,
    propertyUtilities: state.propertyUtilities
  };
}

export default connect(VendorBillCalculationState, {
  handleBillingProcessSubmission,
})(VendorBillForm);