//React Components and Hooks
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
//Material UI Components
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { useHistory } from "react-router-dom";

//Custom Components
//Styles
//const useStyles = makeStyles((theme) => ()

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "& label.Mui-focused": {
      color: theme.palette.secondary.main,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: theme.palette.secondary.main,
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: theme.palette.secondary.main,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.secondary.main,
      },
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const ReportResults = (props) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Paper>
      <Grid container>
        <AppBar
          position="static"
          color="secondary"
          style={{ marginBottom: "2rem" }}
        >
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              Report Results
            </Typography>
          </Toolbar>
        </AppBar>
      </Grid>
    </Paper>
  );
};

export default ReportResults;
