import React, { useState, useEffect } from 'react';
import { compose, withProps } from "recompose"
//import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow, Circle } from "react-google-maps"
import {
    Grid,
    Breadcrumbs as MuiBreadcrumbs,
    Divider as MuiDivider,
    Paper as MuiPaper,
    MenuItem,
    TextField as MuiTextField,
    Button as MuiButton,
    Card as MuiCard,
    CardContent,
    CircularProgress as MuiCircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Slide,
    IconButton,
    Box,
  } from "@material-ui/core";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import GoogleMapReact from 'google-map-react';
import moment from "moment"
import styled, { withTheme } from "styled-components/macro";
import { red, green, blue } from "@material-ui/core/colors";
import { spacing } from "@material-ui/system";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import BusinessIcon from '@material-ui/icons/Business';

import {getAddress} from "../../../setup/geocode"
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Skeleton from '@material-ui/lab/Skeleton';
import ApartmentIcon from '@material-ui/icons/Apartment';
import { FaBeer } from 'react-icons/fa'
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import { Typography } from '@material-ui/core';
import { getCurrentUserLocation } from '../../user/api/actions'
//import PropertySummaryPins from './PropertySummaryPins'
import { get as getProperties, updateFilters } from "../api/actions";
//import GoogleMap from '../../../components/GoogleMap';
import PropertiesTable from './Table';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
    parentDivScrollBarHider: {
        width: "100%",
        height: `500px`,
        overflow: "hidden",
        position: "relative",
    },
    childDivScrollBarHider:{
        position: "absolute",
        top: 0,
        left: 0,
        bottom: "-20px",
        right: "-20px", 
        overflow: "scroll",
    },
    PropertyIcon: {
        width: "40px",
        height: "40px",
        color: theme.palette.secondary.main,
      },
    propertyListItem: {
        "&:hover": {
            background: "#efefef",
            color: theme.palette.secondary.main,
          },
    },
    propertyMapIcon: {
        "&:hover": {
            fontWeight:"bold",
          },
    }
}));


// Defaults
const COLUMBUS_DEFAULT_CENTER = { lat: 39.97530357101782,  lng: -82.99487041223843 };
const DEFAULT_ZOOM = 12;
const MINIMUM_PIN_DISTANCE_THRESHOLD = 0.005;
const DEFAULT_MAP_STYLING = { height: '600px', width: '600px' };

function newPinData(Properties) {
    let Title = Properties[0].name || "N/A";
    let latAvg = 0;
    let lngAvg = 0;
    if (Properties.length > 1){
        let latSum = 0;
        let lngSum = 0;
        let latCount = 0;
        let lngCount = 0;
        for (var x=1; x<Properties.length; x++){
            Title = Title + " / " + Properties[x].name;
            if (Properties[x].coords){
                latSum = latSum + Properties[x].coords.lat;
                lngSum = lngSum + Properties[x].coords.lng;
                latCount++;
                lngCount++;
            }
        }
        lngAvg = lngSum/lngCount;
        latAvg = latSum/latCount;
    }
    else{
        lngAvg = Properties[0].coords.lng;
        latAvg = Properties[0].coords.lat;
    }
    // Coords of cluster pin is average lat and long of all cluster properties
    let coords = { lat: latAvg,  lng: lngAvg } 
    return { Title, Properties, coords };
}

const PropertiesOverviewMap = (props) => {
// Default config stuff:
const [mapCurrentCenter, setMapCurrentCenter] = useState(COLUMBUS_DEFAULT_CENTER);
const [mapCurrentZoom, setMapCurrentZoom] = useState(DEFAULT_ZOOM);

const [isError, setIsError] = useState({error: false, message: ""});
const classes = useStyles();
useEffect(()=>{
  props.getCurrentUserLocation();
  props.getProperties({}, true, true);
},[]);


function handleClose(message) {

}

function handlePropertySelection(){

}

function handlePropertyListSelection(propertyIndex){
    if (props.properties.data.propertiesList.items[propertyIndex].coords){
        setMapCurrentCenter(props.properties.data.propertiesList.items[propertyIndex].coords);
    }
}

function PropertyPinComponent(props){
    return (
        <Box>
            {props.propertyPin.Properties.map((property, index)=>
                <Button color="primary" variant="contained" className={classes.propertyMapIcon}>
                    <ApartmentIcon className={classes.PropertyIcon}/>
                    <Typography>{property.name}</Typography>
                </Button>
            )
            }
        </Box>
    );
}

function renderUserMarker (map, maps, userLocation){
    let marker = new maps.Marker({
      position: userLocation,
      map,
      title: "You Are Here"
    });
    return marker;
};
// {lat: 40.0897495, lng: -82.85383209999999}
// {lat: 40.0902106, lng: -82.8543026}
// 0.0004611         0.00047050000001
function pinsTooClose(coords1, coords2){
    if (!coords1.lat || !coords2.lat || !coords1.lng || !coords2.lng) {return false;}
    let latDiff =  Math.abs(coords1.lat - coords2.lat);
    let lngDiff = Math.abs(coords1.lng - coords2.lng);
    //console.log("pinsTooClose");
    //console.log("latDiff " + latDiff + " lngDiff " + lngDiff + " MINIMUM_PIN_DISTANCE_THRESHOLD " + MINIMUM_PIN_DISTANCE_THRESHOLD);
    if (latDiff < MINIMUM_PIN_DISTANCE_THRESHOLD && lngDiff < MINIMUM_PIN_DISTANCE_THRESHOLD){
        return true;
    }
    return false;
}

function propertyAlreadyGrouped (property, propertyPins){
    let mapped = false;
    propertyPins.map((propertyPin, propertyPinsIndex)=>{
        // once to add to existing group if in range
        propertyPin.Properties.map((pinsProperty2, index)=>{
            if (pinsProperty2.id == property.id) // then it exits if its already apart of a property cluster
            {
                mapped = true;
                return propertyPins;
            }
            if (pinsTooClose(property.coords, pinsProperty2.coords))
            {
                mapped = true;
                propertyPin.Properties.push(property);
                let newPropertyPin = newPinData(propertyPin.Properties);
                propertyPins[propertyPinsIndex] = newPropertyPin;
                return propertyPins;
            }
        });
    });
    if (!mapped){
        let newPinItem = newPinData([property]);
        propertyPins.push(newPinItem);
    }
    return propertyPins;
};


let propertyPins = [];
// Chart pins to see if they are too close and need to be a single item
if (props.properties.data.propertiesList.items.length > 1 && !props.properties.isLoading){
    props.properties.data.propertiesList.items.map((property, index) =>
    {
        if (property.coords && property.coords.lat && property.coords.lng){
            if (index == 0){
                let newPinItem = newPinData([property]);
                propertyPins.push(newPinItem);
            }
            else{
                let CurrentPins = propertyAlreadyGrouped(property, propertyPins);
                propertyPins = CurrentPins;
            }
        }
    })
}

console.log(propertyPins);
//let currentPropPins = <PropertySummaryPins/>
return (
   <div>
     { isError.error ?
        <Snackbar open={isError.error}  onClose={handleClose}>
            <Alert onClose={handleClose} severity="error">
               Unable to load map locations
            </Alert>
        </Snackbar>
        :
        <Grid container xs={12} alignItems="stretch">
            <Grid item xs={2} className={classes.parentDivScrollBarHider}>
                <div  className={classes.childDivScrollBarHider}>
                    <List xs={12} >
                    {
                        props.properties.data.propertiesList.items.map((property, key) =>{
                            return (
                            <>
                                    <ListItem id={key} className={classes.propertyListItem} alignItems="flex-start" onClick={()=>{handlePropertyListSelection(`${key}`)}} value={key}>
                                        <ListItemText
                                            primary={property.name}
                                            secondary={
                                                <React.Fragment>
                                                    <Typography
                                                        component="span"
                                                        variant="body2"
                                                        className={classes.inline}
                                                        color={property.serviceOutage == 1 ? "error" : "textPrimary"}
                                                    >
                                                        Address: {property.coords? property.coords.address : "N/A"}
                                                    </Typography>
                                                    {property.serviceOutage == 1  &&
                                                    <Typography
                                                        component="span"
                                                        variant="body2"
                                                        className={classes.inline}
                                                        color={property.serviceOutage == 1 ? "error" : "textPrimary"}
                                                    >
                                                       {` Outage reported at ${moment(property.outageReportedDate).format("LLLL")}`} 
                                                    </Typography> }
                                                </React.Fragment>
                                            }
                                        />
                                    </ListItem>
                                    <Divider component="li" />
                            </>);
                        })
                    }
                    </List>
                </div>
            </Grid>
            <Grid item xs={10}>
                <div className="user-map" style={{height: "500px"}}>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: "AIzaSyDmVWMr5vZTfXCQ8NNWSBPHg2mSW2ws3EY" }} // My Google API is stored in the .env file in front-end
                        center={mapCurrentCenter}
                        zoom={mapCurrentZoom}
                        yesIWantToUseGoogleMapApiInternals={true}
                        onGoogleApiLoaded={({ map, maps }) => {
                            if (maps != null && props.user.userLocation && props.user.userLocation != null){
                                renderUserMarker(map, maps, props.user.userLocation);
                                map.setCenter(props.user.userLocation);
                            }
                        }}
                    >
                    {propertyPins.map((propertyPin, key) =>
                        {
                            //console.log("Rendering property pin");
                            if (propertyPin.coords)
                            {
                                //console.log("property.coords");
                                //console.log(propertyPin.coords);
                                return (<PropertyPinComponent propertyPin={propertyPin} index={"property" + key} lat={propertyPin.coords.lat} lng={propertyPin.coords.lng}/>)
                            }
                        })
                    }
                    </GoogleMapReact>
                </div>            
            </Grid>
        </Grid>
      }
  </div>
  )
}

  // Component Properties
PropertiesOverviewMap.propTypes = {
    user: PropTypes.object.isRequired,
    apl: PropTypes.object.isRequired,
    properties: PropTypes.object.isRequired,
    getCurrentUserLocation: PropTypes.func.isRequired,
    getProperties: PropTypes.func.isRequired
}

function PropertiesOverviewMapState(state) {
    return {
      user: state.user,
      apl: state.apl,
      properties: state.properties
    }
  }
export default connect(PropertiesOverviewMapState, { getCurrentUserLocation, getProperties })(PropertiesOverviewMap)
