import {
  RESET,
  GET_PROPERTY_MANAGER_STATUSES,
  GET_PROPERTY_MANAGER_STATUSES_RESPONSE,
  GET_PROPERTY_MANAGER_STATUSES_RESPONSE_FAIL,
} from "./actions";

const initialState = {
  isLoading: false,
  error: null,
  data: {
    propertyManagerStatusesList: {
      items: [],
    },
    searchQuery: null,
    range: { start: 0, end: 25 },
  },
};

// State
export default (state = initialState, action) => {
  var data = state.data;
  switch (action.type) {
    case RESET:
      return Object.assign({}, initialState);
    case GET_PROPERTY_MANAGER_STATUSES:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case GET_PROPERTY_MANAGER_STATUSES_RESPONSE:
      data.propertyManagerStatusesList.items = action.propertyManagerStatuses;
      return {
        ...state,
        data: data,
        error: action.error,
        isLoading: false,
      };
    default:
      return {
        ...state,
      };
  }
};
