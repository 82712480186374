import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import { updateSetting } from "../../settings/api/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "25ch",
  },
}));

function DisconnectSettings(props) {
  const classes = useStyles();

  const settings = props.setting.settings.items;
  const isAdmin = !props.user.details.isSuperUser;

  //Create Var for each setting
  let email, disconnect, tenDayStartDate, tenDayEndDate, reconnectFee;

  //Loop through settings and set state
  for (let setting of settings) {
    if (setting.name == "disconnectEmail") {
      email = setting;
    }
    if (setting.name == "reconnectFee") {
      reconnectFee = setting;
    }
    if (setting.name == "tenDayStartDate") {
      tenDayStartDate = setting;
    }
    if (setting.name == "tenDayEndDate") {
      tenDayEndDate = setting;
    }
    if (setting.name == "disconnect") {
      disconnect = setting;
    }
  }

  const [state, setState] = useState({
    email: email,
    reconnectFee: reconnectFee,
    tenDayStartDate: tenDayStartDate,
    tenDayEndDate: tenDayEndDate,
    disconnect: disconnect,
  });

  //Dynamic change event to handle all inputs
  const handleChange = (evt) => {
    let value = evt.target.value;
    if (evt.target.type == "checkbox") value = evt.target.checked;

    const newState = {
      ...state,
      [evt.target.name]: {
        ...state[evt.target.name],
        value: value,
      },
    };
    setState(newState);
  };

  const handleUpdate = async (evt) => {
    try {
      let response = await props.updateSetting(evt.target.id, {
        value: state[evt.target.name].value,
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      {props.setting.isLoading ? (
        <CircularProgress />
      ) : (
        <Button
          disabled={props.setting.isLoading || isAdmin}
          size="small"
          variant="contained"
          color="secondary"
        >
          Save
        </Button>
      )}
      <div className={classes.root}>
        {props.setting.settings.items.length > 0 && (
          <div>
            <FormControlLabel
              control={
                <Switch
                  name="disconnect"
                  onChange={handleChange}
                  disabled={props.setting.isLoading || isAdmin}
                  id={state.disconnect.uuid}
                  onBlur={handleUpdate}
                  checked={state.disconnect.value == 0 ? false : true}
                />
              }
              label="Disconnects"
            />
            <TextField
              id={`${state.email.uuid}`}
              label="Disconnect Email"
              disabled={props.setting.isLoading || isAdmin}
              style={{ margin: 8 }}
              helperText={`Last Updated: ${moment(state.email.updated).format(
                "L LT"
              )}`}
              placeholder="Disconnect Email"
              fullWidth
              name="email"
              onChange={handleChange}
              onBlur={handleUpdate}
              value={state.email.value}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              label="Reconnect Fee"
              variant="standard"
              value={state.reconnectFee.value}
              helperText={`Last Updated: ${moment(
                state.reconnectFee.updated
              ).format("L LT")}`}
              onChange={handleChange}
              type="number"
              onBlur={handleUpdate}
              disabled={props.setting.isLoading || isAdmin}
              id={`${state.reconnectFee.uuid}`}
              placeholder="Reconnect Fee"
              style={{ margin: 8 }}
            />
            <TextField
              id={`${state.tenDayStartDate.uuid}`}
              label="Start Date"
              disabled={props.setting.isLoading || isAdmin}
              style={{ margin: 8 }}
              helperText={`Last Updated: ${moment(
                state.tenDayStartDate.updated
              ).format("L LT")}`}
              placeholder="Start Date"
              name="tenDayStartDate"
              onChange={handleChange}
              type="date"
              onBlur={handleUpdate}
              value={state.tenDayStartDate.value}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id={`${state.tenDayEndDate.uuid}`}
              label="Start Date"
              disabled={props.setting.isLoading || isAdmin}
              style={{ margin: 8 }}
              helperText={`Last Updated: ${moment(
                state.tenDayEndDate.updated
              ).format("L LT")}`}
              placeholder="Start Date"
              name="tenDayEndDate"
              onChange={handleChange}
              type="date"
              onBlur={handleUpdate}
              value={state.tenDayEndDate.value}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
DisconnectSettings.propTypes = {
  // Store objects:
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  billing: PropTypes.object.isRequired,
  // Store object functions:
  getAllBillingDashboardInfo: PropTypes.func.isRequired,
  getBillInProgressDraft: PropTypes.func.isRequired,
  updateBillingWizardBillInProgress: PropTypes.func.isRequired,
  updateBillingWizardSelectedProperty: PropTypes.func.isRequired,
  resetBillingObject: PropTypes.func.isRequired,
  getBillingBatchById: PropTypes.func.isRequired,
};

// Component State
function DisconnectSettingsState(state) {
  return {
    setting: state.setting,
    user: state.user,
  };
}

export default connect(DisconnectSettingsState, { updateSetting })(
  DisconnectSettings
);
