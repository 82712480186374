import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Autocomplete from '@mui/material/Autocomplete';

import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { setSnackbar } from "../../snackbar/api/snackbar";
import { getActiveUsers } from "../../settings/api/actions";
import WorkOrderNotes from "./WorkOrderNotes"
import { modalUnstyledClasses } from "@mui/base";
import { ClassNames } from "@emotion/react";
import { CancelScheduleSendSharp } from "@material-ui/icons";
import { useReactToPrint } from "react-to-print";

function UpdateWorkOrder(props) {
  const [users, setUsers] = useState(props.setting.activeUsers.items || [{ id: "", label: "" }]);

  const options = users.map(user => {
    return { label: `${user.firstName} ${user.lastName}`, id: user.id }
  })

  const assignedTo = options.find(v => v.label == props.item.assignedTo);

  const [open, setOpen] = useState(props.show);
  const [item, setItem] = useState(props.item);
  const [state, setState] = useState({
    workOrderTitle: props.item.workOrderTitle || "",
    assignedTo: assignedTo?.id || "",
    workOrderStatusId: props.item.workOrderStatusId || ""
  });

  const [status, setStatus] = useState(props.apl.genericTypes.workOrderStatus.data || [{ id: "", label: "" }]);
  const [user, setUser] = useState(props.user.details || {})

  const dispatch = useDispatch();

  const workOrderStatusOptions = status.map(status => {
    return { label: `${status.description}`, id: status.id }
  })

  const handleClickOpen = () => {
    setOpen(true);
  };

  const styles = {
    noprint: {
      '@media print': {
        display: 'none'
      }
    }
  }

  const addNote = async (newNote) => {
    let body = {
      workOrderId: item.id,
      note: newNote
    }
    try {
      let repsonse = await axios.post(`/workordernote`, body)
      if (repsonse.status == 200) {
        dispatch(setSnackbar(true, "Success", `Note created.`))
      }
    } catch (e) {
      dispatch(setSnackbar(true, "error", `Failed to create note..`))

    }

  }

  const handleSave = async () => {
    //If New, call register user,

    setOpen(false);
    props.onChange(false);
    let newNote;
    if (item?.assignedTo != state.assignedTo) {
      const orginalAssignee = options.find(v => v.label == item.assignedTo);
      const newName = options.find(v => v.id == state.assignedTo);
      if (orginalAssignee?.label && newName?.label) {
        newNote = `Work order has been reassigned from ${orginalAssignee?.label} to ${newName.label}`;
      }
      else if (newName?.label) {
        newNote = `Work order has been assigned to ${newName.label}`
      }
      else {
        newNote = `Work order has been unassigned.`
      }

      await addNote(newNote)

    }
    if (item?.workOrderStatusId != state.workOrderStatusId) {
      let newDescription = workOrderStatusOptions.find(v => v.id == state.workOrderStatusId)
      newNote = `Status Change: ${item.description} -> ${newDescription.label}`
      await addNote(newNote)
    }

    props.updateItem(item.uuid, state);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);

  }

  const handleChange = (evt) => {
    let value = evt.target.value;
    if (evt.target.name == "isActive") value = evt.target.checked;

    setState({
      ...state,
      [evt.target.name]: value,
    });
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    if (props.setting.activeUsers.items.length <= 0) {
      props.getActiveUsers();
    }
  }, [])

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="md"
        disableBackdropClick
      >
        <DialogTitle id="form-dialog-title">
          {!props.new ? `Work Order: #${props.item.id}` : "New Work Order"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="workOrderTitle"
            name="workOrderTitle"
            value={state.workOrderTitle}
            label="Work Order Title"
            type=""
            fullWidth
            onChange={handleChange}
            required
          />
          <Autocomplete
            disablePortal
            isOptionEqualToValue={(option, value) => option.id === value.id}
            id="assignedTo"
            name="assignedTo"
            defaultValue={options.find(v => v.id == state.assignedTo)}
            onChange={(event, value) => setState({ ...state, assignedTo: value?.id || "" })}
            options={options}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Assigned To" />}
          />
          <Autocomplete
            disablePortal
            isOptionEqualToValue={(option, value) => option.id === value.id}
            id="workOrderStatusId"
            name="workOrderStatusId"
            defaultValue={workOrderStatusOptions.find(v => v.label == item.description)}
            onChange={(event, value) => setState({ ...state, workOrderStatusId: value?.id || "" })}
            options={workOrderStatusOptions}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Status" />}
          />
          <DialogActions>
            <Button onClick={handleCancel} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSave} color="secondary">
              Save
            </Button>
          </DialogActions>
          <WorkOrderNotes workOrder={props.item} />
        </DialogContent>
      </Dialog>
    </div>
  );
}
UpdateWorkOrder.propTypes = {
  // Store objects:
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  billing: PropTypes.object.isRequired,
  getActiveUsers: PropTypes.func.isRequired
  // Store object functions:
};

// Component State
function UpdateWorkOrderState(state) {
  return {
    setting: state.setting,
    apl: state.apl,
    user: state.user
  };
}

export default connect(UpdateWorkOrderState, { getActiveUsers })(
  UpdateWorkOrder
);
