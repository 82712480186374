import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import React, { useState } from "react";

export default function FormDialog(props) {
  const [open, setOpen] = useState(props.show);
  const [item, setItem] = useState(props.item);
  const [state, setState] = useState({
    message: props.item.message || "",
    status: props.item.status || 0,
    name: props.item.name || "Setting",
    type: props.item.type || "info",
    title: props.item.title || "title",
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.onChange(false);
    props.updateItem(item.uuid, state);
  };

  const handleCancel = () => {
    setOpen(false);
    props.onChange(false);
  };

  const handleChange = (evt) => {
    let value = evt.target.value;
    if (evt.target.name == "status") value = evt.target.checked;

    setState({
      ...state,
      [evt.target.name]: value,
    });
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open form dialog
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
      >
        <DialogTitle id="form-dialog-title">{state.name}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To update the CCOS website, please enter your the desired message in
            an HTML format.
          </DialogContentText>
          <FormControlLabel
            control={
              <Switch
                checked={state.status}
                onChange={handleChange}
                name="status"
                color="primary"
              />
            }
            label="Status"
          />
          <Select
            native
            value={state.type}
            onChange={handleChange}
            inputProps={{
              name: "type",
            }}
          >
            <option aria-label="None" value="" />
            <option value="info">info</option>
            <option value="danger">danger</option>
            <option value="success">success</option>
            <option value="warning">warning</option>
            ]
          </Select>
          <TextField
            autoFocus
            margin="dense"
            id="title"
            name="title"
            value={state.title}
            label="Title"
            type=""
            fullWidth
            onChange={handleChange}
            multiline
          />
          <TextField
            autoFocus
            margin="dense"
            id="message"
            name="message"
            value={state.message}
            label="Message"
            type="email"
            fullWidth
            onChange={handleChange}
            multiline
            rows={6}
            rowsMax={8}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="secondary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
