import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";

import Autocomplete from "@material-ui/lab/Autocomplete"
import Helmet from "react-helmet";
import { Chip } from "@material-ui/core";
import {
  Checkbox, Grid,
  ButtonGroup, IconButton, Link, Breadcrumbs as MuiBreadcrumbs, Divider as MuiDivider, Paper as MuiPaper, Table, TableBody, TableContainer, TableCell,
  TableHead, TablePagination, TableRow, TableSortLabel, Toolbar, Tooltip, Typography, FormControlLabel, Switch, Menu, MenuItem, TextField as MuiTextField, Button as MuiButton,
  FormControl, InputLabel, Select, Input,
  Card as MuiCard,
  CardContent,
  CircularProgress as MuiCircularProgress,
} from "@material-ui/core";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  Loop as LoopIcon,
} from "@material-ui/icons";
//import OutlinedInput from '@material-ui/core/OutlinedInput';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";
//Material UI Icons
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

//Material UI Components and Functions
import { makeStyles } from '@material-ui/core/styles';


import { spacing } from "@material-ui/system";

import {
  EditableTable, newDropDownField,
  newConditionalOnSelectButton
} from "../../../components/EditableTable";

import {
  updateSelectedUnitForTransfer
} from "../api/actions";

import {
  hydrateAccount
} from "../api/actions";


import SearchIcon from '@material-ui/icons/Search';
import OutlinedInput from '@material-ui/core/OutlinedInput';

// Redux Components
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { logout } from '../../user/api/actions';
import { searchAccountsByFieldAndValue, updateFilters, searchForAccountsByFilters } from '../../accountsSearch/api/actions';
import { getAllPropertyPreviews } from '../../apl/api/actions';
import StatusTag from "../../apl/components/StatusTag";
const Button = styled(MuiButton)(spacing);
const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)(spacing);
const DashboardToolBarButton = styled(Button)`
padding: 4px;
min-width: 0;
svg {
    width: 2em;
    height: 2em;
}
`;

const CircularProgress = styled(MuiCircularProgress)(spacing);

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  searchBarGrid: {
    //paddingBottom: "10px",
    fontSize: "90%",
    height: "5%"
  },
  searchDropDownButton: {
    height: "90%"
  },
  percentDif: {
    textAlign: 'right',
    color: 'green'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  loadingIcon: {
    position: 'absolute', left: '50%', top: '50%',
    transform: 'translate(-50%, -50%)'
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  active: {
    fontWeight: "bold",
    color: "#f9a825",
    borderColor: "#f9a825",
  },
  inactive: {
    fontWeight: "bold",
  },
}));

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Spacer = styled.div`
  flex: 1 1 100%;
`;

//Editable Table Object Expects: {objectArray, fieldTitles(Array), fieldTypes(Array), fieldNames(Array), handleRowClickParentMethod}
// Method can take line items if they exist initially or starts as an empty array of nothing passed
function VacantUnitSearchTable(props) {
  const dispatch = useDispatch();
  const [accountStatusQueryType, setAccountStatusQueryType] = React.useState(0);

  const classes = useStyles();
  const history = useHistory();

  // Filters
  const [customerType, setCustomerType] = React.useState("PM");
  const [accountStatusType, setAccountStatusType] = React.useState("Active");
  const [searchQuery, setSearchQuery] = React.useState(""); // should be all thats needed to reduce the disconnects shown to only that accounts
  const [property, setProperty] = React.useState(null);
  const [propertyName, setPropertyName] = React.useState(null);
  const [accountBalanceOwedFilter, setAccountBalanceOwedFilter] = React.useState(false);

  // Table Data management
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [resetCache, setResetCachedData] = React.useState(true);
  const [focus, setFocus] = React.useState(false);
  const [minAccountObj, setMinAccountObj] = React.useState(props.minAccountObj || false);
  const [SearchOnTypeInput, setSearchOnTypeInput] = React.useState(props.SearchOnTypeInput);

  const handleChangeRowsPerPage = (rowsPerPage) => {
    setPage(0);
    setResetCachedData(false);
    setRowsPerPage(rowsPerPage);
  };

  const handleChangePage = (page) => {
    setResetCachedData(false);
    setPage(page);
  };

  const onAccountStatusTypeSelected = (selection) => {
    setAccountStatusType(selection);
    setResetCachedData(true);
    setPage(0);
  }

  const onCustomerTypeSelected = (selection) => {
    setCustomerType(selection);
    setResetCachedData(true);
    setPage(0);
  }

  const handleSelectedPropertyChange = (event) => {
    setPage(0);
    setResetCachedData(true);
    if (event.target.value == "" || event.target.value == null) {
      setPropertyName(null);
    }
    else {
      var target = props.apl.data.propertyPreviews.find(x => x.uuid === event.target.value);
      setPropertyName(target.name);
    }
    setProperty(event.target.value);
  };


  // When the textbox changes
  const handleSearchTargetChange = (event) => {
    setSearchQuery(event.target.value);
    if (SearchOnTypeInput) {
      setPage(0);
      setResetCachedData(true);
      getAccountsWithQuery();
    }
  }



  const getAccountsWithQuery = () => {
    const propertyUuid = property;
    const query = { page, rowsPerPage, customerType, searchQuery, propertyUuid, accountStatusType, resetCache, accountBalanceOwedFilter, minAccountObj, serviceAddress: true };
    props.searchForAccountsByFilters(query);
  };

  const handleSearchTargetButtonClicked = () => {
    setPage(0);
    setResetCachedData(true);
    getAccountsWithQuery();
  }


  useEffect(() => {
    if (props.isOpen) {
      props.getAllPropertyPreviews()
      if (props.accountsSearch.data.accountPreviews.items) {
        if (((page + 1) * rowsPerPage > props.accountsSearch.data.accountPreviews.items.length) || resetCache) {
          getAccountsWithQuery();
        }
      }
    }
  }, [page, property, rowsPerPage, customerType, accountStatusType, accountBalanceOwedFilter, minAccountObj, props.isOpen]);

  const CurrentLookupTypeButton = () => {
    return (
      <IconButton onClick={handleSearchTargetButtonClicked} style={{ width: "50px", height: "50px" }}>
        <SearchIcon style={{ width: "30px", height: "30px" }} />
      </IconButton>
    );
  }

  const setSearchFocus = () => {
    setFocus(true);
  }
  const unsetSearchFocus = () => {
    setFocus(false);
  }

  const AccountSearchBar = () => {
    return (
      <>
        {focus ?
          <TextField
            id={"searchTarget"}
            label="Search For Accounts"
            type={"text"}
            onChange={handleSearchTargetChange}
            autoFocus={true}
            name={"searchTarget"}
            defaultValue={searchQuery}
            onBlur={unsetSearchFocus}
            fullWidth
            my={2}
            autoComplete={"off"}
            size={"small"}
            variant={"outlined"}
            InputLabelProps={{
              shrink: true,
            }}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                // Do code here
                ev.preventDefault();
                setPage(0);
                setResetCachedData(true);
                getAccountsWithQuery();
              }
            }}
            InputProps={{ endAdornment: <CurrentLookupTypeButton /> }}
            helperText="Currently searching by account characteristics. Use Comma to seperate search values"
          >
          </TextField>
          :
          <TextField
            id={"searchTarget"}
            label="Search For Accounts"
            type={"text"}
            onChange={handleSearchTargetChange}
            name={"searchTarget"}
            defaultValue={searchQuery}
            onClick={setSearchFocus}
            fullWidth
            autoComplete={"off"}
            my={2}
            size={"small"}
            variant={"outlined"}
            InputLabelProps={{
              shrink: true,
            }}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                // Do code here
                ev.preventDefault();
                setPage(0);
                setResetCachedData(true);
                getAccountsWithQuery();
              }
            }}
            InputProps={{ endAdornment: <CurrentLookupTypeButton /> }}
            helperText="Currently searching by account characteristics. Use Comma to seperate search values"
          >
          </TextField>
        }

      </>
    );
  }

  const AccountRowStatusStyling = (account) => {
    account.name = account.lastName;
    if (account.firstName != null) {
      account.name = account.firstName + ", " + account.lastName;
    }
    return {}; // Returns an empty style as to not effect each row
  };

  // Table Stuff:      
  const fieldTitles = [
    "Property",
    "Address",
    "Unit",
    "Current Occupant"
  ];

  const fieldTypes = [
    "label",
    "label",
    "label",
    ["label", "label"]
  ];

  const fieldNames = [
    "propertyName",
    "serviceAddress1",
    "serviceAddress2",
    ["name", "accountNum"]
  ];
  // Must be the exact name of the properties you wanna show

  const onAccountSelected = (item) => {
    props.updateSelectedUnitForTransfer(item);
  }

  const handleSelectOptionClick = (event, value) => {
    if (value && value.accountNum && value.uuid) {
      history.push({ pathname: "/accountmanager/" + value.accountNum + "/" + value.uuid });
      window.location.reload();
    }
  }

  const Filters = () => {
    const classes = useStyles();

    return (
      <Grid container spacing={6}>
        <Grid item lg={8} md={8} sm={8}>
          {!props.isAccountPage &&
            <>
              <FormControl fullWidth my={2}>
                <InputLabel id="propertyToBillDropDownLabel">Location{"  "}
                  {props.apl.isLoading &&
                    <CircularProgress size={15} m={0} />
                  }
                </InputLabel>
                {property == "" && props.apl.isLoading ?
                  <Select
                    labelId="propertyToBillDropDownLabel"
                    id="propertyToBillDropDown"
                    value={property}
                    className={classes.selectedPropertyLocationLabel}
                    onChange={handleSelectedPropertyChange}
                    fullWidth
                    disabled
                    my={2}>
                  </Select>
                  :
                  <Select
                    labelId="propertyToBillDropDownLabel"
                    id="propertyToBillDropDown"
                    value={property}
                    className={classes.selectedPropertyLocationLabel}
                    onChange={handleSelectedPropertyChange}
                    fullWidth
                    my={2}>
                    {props.apl.data.propertyPreviews.length > 0 ?
                      [
                        <MenuItem value={""}>All</MenuItem>,
                        props.apl.data.propertyPreviews.map((item, key) => {
                          return (
                            <MenuItem value={item.uuid} id={key}>{item.name}</MenuItem>
                          )
                        })
                      ]
                      :
                      [
                        <MenuItem value={-1}>No Properties Found</MenuItem>
                      ]
                    }
                  </Select>
                }
              </FormControl>
            </>
          }
        </Grid>
      </Grid>
    );
  };


  if (props.isAccountSearchBar && props.isAccountSearchBar == true) {
    return (
      <>
        <div className={classes.search}>
          <Autocomplete
            id="combo-box"
            options={props.accountsSearch.data.accountPreviews.items || []}
            getOptionLabel={(option) => {
              if (option && option.lastName) {
                let name = "";
                if (option.firstName && option.firstName != null) {
                  name = option.firstName;
                }
                if (option.lastName && option.lastName != null) {
                  name = name + " " + option.lastName;
                }
                return option.accountNum + " : " + name
              }
            }}
            onChange={handleSelectOptionClick}
            renderInput={(params) => (
              <TextField
                label="Accounts Search"
                {...params}
                variant="outlined"
                onChange={handleSearchTargetChange}
                value={searchQuery}
                size={"small"}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                my={2}
              />
            )}
          />
        </div>
      </>
    )
  }
  return (
    <Card mb={6} elevation={3}>
      <CardContent>
        <Grid container xs={12} alignItems="center" spacing={2}>
          <Grid item lg={12} md={12} sm={12}>
            <AccountSearchBar />
          </Grid>
          <EditableTable
            Filters={<Filters />}
            readOnly={false}
            canAddItems={false}
            canSave={false}
            canDelete={false}
            defaultItemCount={5}
            isLoading={props.accountsSearch.isLoading}
            conditionalStylingMethod={AccountRowStatusStyling}
            // Properties of table  
            tableTitle={"Vacant Units"}
            objectArray={props.accountsSearch.data.accountPreviews}
            fieldTitles={fieldTitles}
            fieldTypes={fieldTypes}
            fieldNames={fieldNames}
            customChangePageRowsEvent={handleChangeRowsPerPage}
            handleRowClickParentMethod={onAccountSelected}
            customHandleChangePageEvent={handleChangePage}
            totalRowCount={props.accountsSearch.data.previewsSummary == null ? 0 : props.accountsSearch.data.previewsSummary.totalRows}
          />
        </Grid>
      </CardContent>
    </Card>
  );
}
// Component Properties
VacantUnitSearchTable.propTypes = {
  // Store objects:
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  billing: PropTypes.object.isRequired,
  accountsSearch: PropTypes.object.isRequired,
  units: PropTypes.object.isRequired,

  searchAccountsByFieldAndValue: PropTypes.func.isRequired,
  getAllPropertyPreviews: PropTypes.func.isRequired,
  updateFilters: PropTypes.func.isRequired,
  searchForAccountsByFilters: PropTypes.func.isRequired,
  updateSelectedUnitForTransfer: PropTypes.func.isRequired,
}

// Component State
function VacantUnitSearchTableState(state) {
  return {
    user: state.user,
    apl: state.apl,
    billing: state.billing,
    units: state.units,
    accountsSearch: state.accountsSearch
  }
}
export default connect(VacantUnitSearchTableState, { searchAccountsByFieldAndValue, getAllPropertyPreviews, updateFilters, searchForAccountsByFilters, updateSelectedUnitForTransfer })(VacantUnitSearchTable)


