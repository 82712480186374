import {
  Button as MuiButton,
  CircularProgress as MuiCircularProgress,
  Grid,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import { Loop as LoopIcon } from "@material-ui/icons";
import PageviewSharpIcon from "@material-ui/icons/PageviewSharp";
import SearchIcon from "@material-ui/icons/Search";
import MuiAlert from "@material-ui/lab/Alert";
import { spacing } from "@material-ui/system";
import React from "react";
import styled from "styled-components/macro";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CircularProgress = styled(MuiCircularProgress)(spacing);
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(1),
      backgroundColor: "red",
    },
  },
  alertMain: {
    width: "600px",
  },
  loadingIcon: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  errorItem: {
    backgroundColor: "red",
    color: "white",
  },
}));

const Button = styled(MuiButton)(spacing);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CustomErrorSnackbar(props) {
  const classes = useStyles();

  const [errorDialogOpen, setErrorDialogOpen] = React.useState(false);
  if (!props.errors) {
    props.errors = [];
  }
  /*console.log("Errors in property:");
  console.log(props.errors );*/
  const ActionDialogButton = (props) => {
    return (
      <IconButton onClick={handleErrorDialogClickOpen}>
        <PageviewSharpIcon style={{ color: "white", fontSize: "25px" }} />
      </IconButton>
    );
  };

  const ActionButton = (props) => {
    return (
      <IconButton
        onClick={() => {
          window.open(props.location);
        }}
      >
        <SearchIcon style={{ color: "white", fontSize: "25px" }} />
      </IconButton>
    );
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
  };

  const handleRefresh = () => {
    if (props.refreshPage) {
      props.refreshPage();
    }
  };

  const handleErrorDialogClickOpen = () => {
    setErrorDialogOpen(true);
  };

  const handleErrorDialogClose = () => {
    setErrorDialogOpen(false);
  };

  return (
    <>
      <Snackbar
        className={classes.alertMain}
        open={props.errors.length > 0}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClick={handleErrorDialogClickOpen}
          elevation={6}
          variant="filled"
          severity={"error"}
          style={{ color: "white" }}
        >
          Error! Cannot bill property until the following issues are resolved
          <ActionDialogButton />
        </Alert>
      </Snackbar>
      <Dialog
        open={errorDialogOpen}
        TransitionComponent={Transition}
        fullWidth={"sm"}
        maxWidth={"sm"}
        keepMounted
        onClose={handleErrorDialogClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        className={classes.dialogRoot}
        overlayStyle={{ backgroundColor: "transparent" }}
      >
        <DialogTitle id="alert-dialog-slide-title">
          <Grid container spacing={2}>
            <Grid item lg={6} md={12} sm={12}>
              Property Error Manager
            </Grid>
            <Grid item lg={6} md={12} sm={12}>
              {!props.isLoading && (
                <Button
                  size="small"
                  mr={2}
                  onClick={handleRefresh}
                  variant="contained"
                  color="primary"
                >
                  Refresh for error revisal <LoopIcon />
                </Button>
              )}
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {!props.isLoading ? (
              props.errors.map((error, key) => {
                return (
                  <Grid item lg={12} md={12} sm={12}>
                    {/*<SnackbarContent className={classes.errorItem} onClick={()=>{window.open(error.url); }} message={error.message} action={<ActionButton location={error.url}/>} key={key} />*/}
                    <Button
                      variant="contained"
                      className={classes.errorItem}
                      startIcon={<SearchIcon />}
                      onClick={() => {
                        window.open(error.url);
                      }}
                    >
                      {error.message}
                    </Button>
                  </Grid>
                );
              })
            ) : (
              <Grid item lg={12} md={12} sm={12}>
                <CircularProgress className={classes.loadingIcon} />
              </Grid>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
