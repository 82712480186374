import {
  Divider as MuiDivider,
  Grid, Typography,

} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { get as getDevelopers } from "../../modules/developer/api/actions";
import styled from "styled-components/macro";
import StandardHeader from "../../components/global/StandardHeader";
import { LeftSidebar as NavFrame } from "layout-blueprints";
import { getAllPropertyPreviews } from "../../modules/apl/api/actions";
import { searchForDelinquencyGroupsByFilters } from "../../modules/delinquencies/api/actions";
import { get as getOwners } from "../../modules/owner/api/actions";
import {
  getByUuid as getOwnerByUuid
} from "../../modules/owner/api/actions"
import { getBillingPdfs } from "../../modules/billing/api/actions";
import OwnerDetailCard from "../../modules/owner/components/OwnerDetailCard";
import { get as getUnitTypes } from "../../modules/unitType/api/actions";
import OwnerDashboardTabedTables from "./OwnerTabedTables";
import {makeStyles} from "@material-ui/core/styles";
import {getOwnerStatements} from "../../modules/ownerStatement/api/actions";
import moment from "moment";

const Divider = styled(MuiDivider)(spacing);

const IndividualOwnerPage = (props) => {

  let pageTitle = "Ubil+ Loading Owner...";
  let tabTitle = "Ubil+ Loading Owner...";
  if (props.owners?.data.owner != null) {
    if (props.owners?.data.owner.name) {
      tabTitle = "Owner: " + props.owners?.data.owner.name;
      pageTitle =
        "Owner: " + props.owners?.data.owner.name;
    } else {
      pageTitle = "Owner";
      tabTitle = "Owner";
    }
  }
  document.title = tabTitle;

  const useStyles = makeStyles((theme) => {
    return {
      pageEmergencyHeaderFontColor: {
        fontWeight: "bold",
        marginTop: "30px"
      }
    }
  })
  const classes = useStyles();

  useEffect(() => {
    props.getOwnerByUuid(props.match.params.uuid);
    props.getOwners();
  }, []);

  return (
    <NavFrame page={"IndividualOwnerPage"}>
      <StandardHeader
        appPath="Properties / Owner"
        title={pageTitle}
        //headerTags={currentHeaderTags}
        //rightSideToolBar={<ToolBar handleRefreshClick={refreshPage} />}
      />

      <Typography
        variant="h5"
        color="textPrimary"
        className={(classes.pageEmergencyHeaderFontColor)}
      >
        {props.ownerStatements.data.lastStatementDate !== null &&
          `Last Statement Date: ${moment.utc(props.ownerStatements.data.lastStatementDate).format('MM/DD/YYYY')}`}</Typography>
      <Divider className="mb-3 mt-3" />
      <Grid container spacing={2} xs={12} sm={12} md={12} lg={12}>
        <Grid item xs={4}>
          <Grid container spacing={2} xs={12}>
            <Grid item xs={12}>
              <OwnerDetailCard edit={true} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={2} xs={12}>
            <Grid item xs={12}>
              <OwnerDashboardTabedTables
                // pmUuid={props.match.params.uuid}
                // userProfile={props.owners?.data.owner != null
                //   ? props.owners?.data.owner.user
                //   : null}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </NavFrame>
  );
};

IndividualOwnerPage.propTypes = {
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  units: PropTypes.object.isRequired,
  delinquencies: PropTypes.object.isRequired,
  propertyManagers: PropTypes.object.isRequired,
  billing: PropTypes.object.isRequired,
  getUnitTypes: PropTypes.func.isRequired,
  getAllPropertyPreviews: PropTypes.func.isRequired,
  searchForDelinquencyGroupsByFilters: PropTypes.func.isRequired,
  getBillingPdfs: PropTypes.func.isRequired,
};

// Component State
function IndividualOwnerPageState(state) {
  return {
    user: state.user,
    apl: state.apl,
    units: state.units,
    owners: state.owners,
    ownerStatements: state.ownerStatements,
    unitTypes: state.unitTypes,
    propertyManagers: state.propertyManagers,
    delinquencies: state.delinquencies,
    billing: state.billing,
    getDevelopers: PropTypes.func.isRequired,
    getOwnerByUuid: PropTypes.func.isRequired,
    getOwnerStatements: PropTypes.func.isRequired
  };
}

export default connect(IndividualOwnerPageState, {
  getOwners,
  getUnitTypes,
  getOwnerByUuid,
  getAllPropertyPreviews,
  searchForDelinquencyGroupsByFilters,
  getBillingPdfs,
  getDevelopers,
  getOwnerStatements,
})(IndividualOwnerPage);
