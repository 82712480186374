import {
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  ButtonGroup,
  Card as MuiCard,
  CardContent,
  CircularProgress as MuiCircularProgress,
  Divider as MuiDivider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper as MuiPaper,
  Select,
  TextField as MuiTextField,
} from "@material-ui/core";
//Material UI Components and Functions
import { makeStyles } from "@material-ui/core/styles";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import { spacing } from "@material-ui/system";
// Redux Components
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { EditableTable } from "../../../components/EditableTable";
import { getAllPropertyPreviews } from "../../apl/api/actions";
import StatusTag from "../../apl/components/StatusTag";
import {
  searchAccountsByFieldAndValue,
  searchForAccountsByFilters,
  updateFilters,
} from "../api/actions";

const Button = styled(MuiButton)(spacing);
const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)(spacing);
const DashboardToolBarButton = styled(Button)`
  padding: 4px;
  min-width: 0;
  svg {
    width: 2em;
    height: 2em;
  }
`;

const CircularProgress = styled(MuiCircularProgress)(spacing);

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  searchBarGrid: {
    //paddingBottom: "10px",
    fontSize: "90%",
    height: "5%",
  },
  searchDropDownButton: {
    height: "90%",
  },
  percentDif: {
    textAlign: "right",
    color: "green",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  loadingIcon: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  active: {
    fontWeight: "bold",
    color: "#f9a825",
    borderColor: "#f9a825",
  },
  inactive: {
    fontWeight: "bold",
  },
}));

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Spacer = styled.div`
  flex: 1 1 100%;
`;

//Editable Table Object Expects: {objectArray, fieldTitles(Array), fieldTypes(Array), fieldNames(Array), handleRowClickParentMethod}
// Method can take line items if they exist initially or starts as an empty array of nothing passed
function UserRelatedAccountPreviewsTable(props) {
  const [accountStatusQueryType, setAccountStatusQueryType] = React.useState(0);

  const classes = useStyles();
  const history = useHistory();

  // Filters
  const [customerType, setCustomerType] = React.useState(null);
  const [accountStatusType, setAccountStatusType] = React.useState(null);
  const [searchQuery, setSearchQuery] = React.useState(""); // should be all thats needed to reduce the disconnects shown to only that accounts

  const [property, setProperty] = React.useState(null);
  const [propertyName, setPropertyName] = React.useState(null);

  // Table Data management
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [resetCache, setResetCachedData] = React.useState(true);
  const [initialLoading, setInitialLoading] = React.useState(false);

  const handleChangeRowsPerPage = (rowsPerPage) => {
    setPage(0);
    setResetCachedData(false);
    setRowsPerPage(rowsPerPage);
  };

  const handleChangePage = (page) => {
    setResetCachedData(false);
    setPage(page);
  };

  const onAccountStatusTypeSelected = (selection) => {
    setAccountStatusType(selection);
    setResetCachedData(true);
    setPage(0);
  };

  const onCustomerTypeSelected = (selection) => {
    setCustomerType(selection);
    setResetCachedData(true);
    setPage(0);
  };

  const handleSelectedPropertyChange = (event) => {
    setPage(0);
    setResetCachedData(true);
    if (event.target.value == "" || event.target.value == null) {
      setPropertyName(null);
    }
    else {
      var target = props.apl.data.propertyPreviews.find(x => x.uuid === event.target.value);
      setPropertyName(target.name);
    }
    setProperty(event.target.value);
  };


  // When the textbox changes
  const handleSearchTargetChange = (event) => {
    setPage(0);
    setResetCachedData(true);
    setSearchQuery(event.target.value);
  };

  const getAccountsWithQuery = () => {
    if (props.userProfiles && props.userProfiles.details) {
      const propertyUuid = property;
      const userUuid = props.userProfiles.details.uuid;
      const query = {
        page,
        rowsPerPage,
        customerType,
        searchQuery,
        propertyUuid,
        accountStatusType,
        userUuid,
        resetCache,
      };
      props.searchForAccountsByFilters(query);
    }
  };

  useEffect(() => {
    if (
      (page + 1) * rowsPerPage >
      props.accountsSearch.data.accountPreviews.items.length ||
      resetCache
    ) {
      getAccountsWithQuery();
    }
  }, [
    page,
    property,
    rowsPerPage,
    searchQuery,
    customerType,
    accountStatusType,
  ]);

  const CurrentLookupTypeButton = () => {
    return (
      <IconButton>
        <PeopleOutlineIcon mr={2} />
      </IconButton>
    );
  };

  const AccountSearchBar = () => {
    return (
      <TextField
        id={"searchTarget"}
        label="Search For Accounts"
        type={"text"}
        onChange={handleSearchTargetChange}
        autoFocus={"autofocus"}
        name={"searchTarget"}
        defaultValue={searchQuery}
        fullWidth
        my={2}
        size={"small"}
        variant={"outlined"}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{ endAdornment: <CurrentLookupTypeButton /> }}
        helperText="Currently searching by account characteristics"
      ></TextField>
    );
  };

  const AccountRowStatusStyling = (account) => {
    account.name = account.lastName;
    if (account.firstName != null) {
      account.name = account.firstName + ", " + account.lastName;
    }
    account.accountStatus = (
      <StatusTag
        type={"accountStatus"}
        isLoading={props.accountsSearch.isLoading}
        typeId={account.accountStatusId}
        showTitle={false}
        condensed={true}
      />
    );
    account.customerType = (
      <StatusTag
        type={"customerType"}
        isLoading={props.accountsSearch.isLoading}
        typeId={account.customerTypeId}
        showTitle={false}
        condensed={true}
      />
    );
    return {}; // Returns an empty style as to not effect each row
  };

  // Table Stuff:
  const fieldTitles = ["Acct #", "Status", "Type", "Name"];

  const fieldTypes = ["label", "label", "label", "label"];

  const fieldNames = ["accountNum", "accountStatus", "customerType", "name"]; // Must be the exact name of the properties you wanna show

  const onAccountSelected = (item) => {
    window.open("/accountmanager/" + item.accountNum);
  };

  const Filters = () => {
    const classes = useStyles();

    return (
      <Grid container spacing={6}>
        <Grid item lg={8} md={8} sm={8}>
          {!props.isAccountPage && (
            <>
              <FormControl fullWidth my={2}>
                <InputLabel id="propertyToBillDropDownLabel">
                  Location{"  "}
                  {props.apl.isLoading && <CircularProgress size={15} m={0} />}
                </InputLabel>
                {property == "" && props.apl.isLoading ? (
                  <Select
                    labelId="propertyToBillDropDownLabel"
                    id="propertyToBillDropDown"
                    value={property}
                    className={classes.selectedPropertyLocationLabel}
                    onChange={handleSelectedPropertyChange}
                    fullWidth
                    disabled
                    my={2}
                  >
                  </Select>
                ) : (
                  <Select
                    labelId="propertyToBillDropDownLabel"
                    id="propertyToBillDropDown"
                    value={property}
                    className={classes.selectedPropertyLocationLabel}
                    onChange={handleSelectedPropertyChange}
                    fullWidth
                    my={2}
                  >
                    {props.apl.data.propertyPreviews.length > 0 ?
                      [
                        <MenuItem value={""}>All</MenuItem>,
                        props.apl.data.propertyPreviews.map((item, key) => {
                          return (
                            <MenuItem value={item.uuid} id={key}>
                              {item.name}
                            </MenuItem>
                          );
                        })
                      ]
                      : [<MenuItem value={-1}>No Properties Found</MenuItem>]
                    }
                  </Select>
                )}
              </FormControl>
            </>
          )}
        </Grid>
        <Grid item lg={6} md={6} sm={6}>
          <ButtonGroup
            color="primary"
            aria-label="outlined primary buwtton group"
          >
            <Button
              onClick={() => {
                onAccountStatusTypeSelected(null);
              }}
              className={
                accountStatusType == null ? classes.active : classes.inactive
              }
            >
              All
            </Button>
            <Button
              onClick={() => {
                onAccountStatusTypeSelected("Active");
              }}
              className={
                accountStatusType == "Active"
                  ? classes.active
                  : classes.inactive
              }
            >
              Active
            </Button>
            <Button
              onClick={() => {
                onAccountStatusTypeSelected("Inactive");
              }}
              className={
                accountStatusType == "Inactive"
                  ? classes.active
                  : classes.inactive
              }
            >
              Inactive
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item lg={6} md={6} sm={6}>
          <ButtonGroup
            color="primary"
            aria-label="outlined primary button group"
          >
            <Button
              onClick={() => {
                onCustomerTypeSelected(null);
              }}
              className={
                customerType == null ? classes.active : classes.inactive
              }
            >
              All
            </Button>
            <Button
              onClick={() => {
                onCustomerTypeSelected("PM");
              }}
              className={
                customerType == "PM" ? classes.active : classes.inactive
              }
            >
              PM
            </Button>
            <Button
              onClick={() => {
                onCustomerTypeSelected("Tenant");
              }}
              className={
                customerType == "Tenant" ? classes.active : classes.inactive
              }
            >
              Tenant
            </Button>
            <Button
              onClick={() => {
                onCustomerTypeSelected("Common");
              }}
              className={
                customerType == "Common" ? classes.active : classes.inactive
              }
            >
              Common
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    );
  };

  // Handles putting off initial loading until the user account is loaded in:
  if (!initialLoading) {
    if (props.userProfiles && props.userProfiles.details) {
      setInitialLoading(true);
      getAccountsWithQuery();
    }
  }

  return (
    <Card mb={6} elevation={3}>
      <CardContent>
        <Grid container xs={12} alignItems="center" spacing={2}>
          <Grid item lg={12} md={12} sm={12}>
            <AccountSearchBar />
          </Grid>
        </Grid>
        <EditableTable
          Filters={<Filters />}
          readOnly={false}
          canAddItems={false}
          canSave={false}
          isLoading={props.accountsSearch.isLoading}
          conditionalStylingMethod={AccountRowStatusStyling}
          // Functional methods of table
          handleRowClickParentMethod={onAccountSelected}
          // Properties of table
          tableTitle={"Related User Accounts"}
          objectArray={props.accountsSearch.data.accountPreviews}
          fieldTitles={fieldTitles}
          fieldTypes={fieldTypes}
          fieldNames={fieldNames}
          customChangePageRowsEvent={handleChangeRowsPerPage}
          customHandleChangePageEvent={handleChangePage}
          defaultItemCount={rowsPerPage}
          totalRowCount={
            props.accountsSearch.data.previewsSummary == null
              ? 0
              : props.accountsSearch.data.previewsSummary.totalRows
          }
        />
      </CardContent>
    </Card>
  );
}
// Component Properties
UserRelatedAccountPreviewsTable.propTypes = {
  // Store objects:
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  billing: PropTypes.object.isRequired,
  accountsSearch: PropTypes.object.isRequired,
  searchAccountsByFieldAndValue: PropTypes.func.isRequired,
  getAllPropertyPreviews: PropTypes.func.isRequired,
  updateFilters: PropTypes.func.isRequired,
  searchForAccountsByFilters: PropTypes.func.isRequired,
  userProfiles: PropTypes.object.isRequired,
};

// Component State
function UserRelatedAccountPreviewsTableState(state) {
  return {
    user: state.user,
    apl: state.apl,
    billing: state.billing,
    accountsSearch: state.accountsSearch,
    userProfiles: state.userProfiles,
  };
}
export default connect(UserRelatedAccountPreviewsTableState, {
  searchAccountsByFieldAndValue,
  getAllPropertyPreviews,
  updateFilters,
  searchForAccountsByFilters,
})(UserRelatedAccountPreviewsTable);
