import {
  Button as MuiButton,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  FormGroup,
  Checkbox,
  Select as MuiSelect,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components/macro";
import { setSnackbar } from "../../snackbar/api/snackbar";
import { postByUnitUuid, put } from "../api/actions";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const TextField = styled(MuiTextField)(spacing);
const Select = styled(MuiSelect)(spacing);

const MeterForm = (props) => {
  const useStyles = makeStyles((theme) => ({
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    loadingIcon: {
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
    },
  }));

  const [fields, setFields] = React.useState({...props.meters.data.meter})

  const onFieldChange = (event) => {
    const { name, value } = event.target;
    setFields((prevFields) => ({ ...prevFields, [name]: value }));
  };

  const onFieldToggle = (name) => {
    setFields((prevFields) => ({ ...prevFields, [name]: prevFields[name] === 1? 0: 1 }));
  };


  const dispatch = useDispatch();


  // Handles vendor drop down
  // const [physical, setPhysical] = React.useState(false);
  // const [evMeter, setEVMeter] = React.useState(false);

  useEffect(() => {
    // setPhysical(Boolean(props.meters.data.meter?.physical));
    // setEVMeter(Boolean(props.meters.data.meter?.EVMeter));
    setFields({...props.meters.data.meter})
  }, [props.meters.data.meter]);



  // const toggleEVMeter = () => {
  //   setEVMeter(!evMeter);
  //   props.meters.data.meter.EVMeter = !evMeter;
  // };
  // const toggleBackwardMeter = () => {
  //   setBackwardMeter(!backwardMeter);
  //   props.meters.data.meter.backwardMeter = !backwardMeter;
  // };
  //
  // const toggleNoMeter = () => {
  //   setNoMeter(!noMeter);
  //   props.meters.data.meter.noMeter = !noMeter;
  // };
  // const toggleBrokenMeter = () => {
  //   setBrokenMeter(!brokenMeter);
  //   props.meters.data.meter.brokenMeter = !brokenMeter;
  // };
  // const toggleHold = () => {
  //   setHold(!hold);
  //   props.meters.data.meter.hold = !hold;
  // };



  // Handles vendor drop down
  const [state, setState] = React.useState("");
  const classes = useStyles();

  const save = () => {
    const m = props.meters.data.meter;
    const newMeter = {
      id: m.id,
      uuid: m.uuid,
      meterNum: fields.meterNum,
      ERTNum: fields.ERTNum,
      description: fields.description,
      readSequence: fields.readSequence,
      readDecimals: fields.readDecimals,
      meterCode: fields.meterCode,
      multiplier: parseInt(fields.multiplier),
      physical: fields.physical,
      backwardMeter:fields.backwardMeter,
      noMeter: fields.noMeter,
      brokenMeter: fields.brokenMeter,
      hold:fields.hold
    };
    props.onUpdate();
    if (!newMeter.id) {
      // TODO: get unit uuid from somewhere
      props.postByUnitUuid(props.units.data.unit.uuid, newMeter);
    } else {
      props.put(newMeter);
    }
    dispatch(setSnackbar(true, "success", "Meter updated."));
  };

  if (props.meters.meterIsLoading) {
    return (
      <Card mb={6}>
        <CardContent>
          <Typography variant="h3" gutterBottom>
            Loading...
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardContent>
        {/* Meter Details */}
        <Grid container spacing={2}>
          <Grid container item spacing={2}>
            <Grid item sm={6} xs={12}>
              <TextField
                label="Meter Number"
                type="text"
                onChange={onFieldChange}
                name="meterNum"
                value={fields.meterNum}
                //required
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                label="ERT Number"
                type="text"
                onChange={onFieldChange}
                name="ERTNum"
                value={fields.ERTNum}
                //required
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="selectedAccountStatusDropDownLabel">
                  Meter Code
                </InputLabel>
                <Select
                    labelId="selectedAccountStatusDropDownLabel"
                    id="selectedAccountStatusDropDown"
                    value={fields.meterCode}
                    onChange={onFieldChange}
                    name="meterCode"
                >{[
                  <MenuItem id="AccountStatusOption1" value={"1"}>
                    Primary
                  </MenuItem>,
                  <MenuItem id="AccountStatusOption3" value={"0"}>
                    Secondary
                  </MenuItem>
                ]}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={6} xs={12}>
                <TextField
                  label="Description"
                  type="text"
                  onChange={onFieldChange}
                  value={fields.description}
                  name="description"
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                  label="Multiplier"
                  type="number"
                  onChange={onFieldChange}
                  name="multiplier"
                  value={fields.multiplier}
                  //required
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                  label="Read Sequence"
                  type="text"
                  onChange={onFieldChange}
                  value={fields.readSequence}
                  name="readSequence"
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={2}>
            <Grid item md={4} xs={6}>
              <FormGroup row>
                <FormControlLabel
                    control={
                      <Checkbox
                          checked={fields.readDecimals === 1}
                          onChange={()=>onFieldToggle('readDecimals')}
                          name="readDecimals"
                      />
                    }
                    label="Read Decimals"
                />
              </FormGroup>
            </Grid>
            <Grid item md={4} xs={6}>
              <FormGroup row>
                <FormControlLabel
                    control={
                      <Checkbox
                          checked={fields.physical === 1}
                          onChange={()=>onFieldToggle('physical')}
                          name="physical"
                      />
                    }
                    label="Physical"
                />
              </FormGroup>
            </Grid>
            <Grid item md={4} xs={6}>
              <FormGroup row>
                <FormControlLabel
                    control={
                      <Checkbox
                          checked={fields.brokenMeter === 1}
                          onChange={()=>onFieldToggle('brokenMeter')}
                          name="brokenMeter"
                      />
                    }
                    label="Broken Meter"
                />
              </FormGroup>
            </Grid>
            <Grid item md={4} xs={6}>
              <FormGroup row>
                <FormControlLabel
                    control={
                      <Checkbox
                          checked={fields.noMeter === 1}
                          onChange={()=>onFieldToggle('noMeter')}
                          name="noMeter"
                      />
                    }
                    label="No Meter"
                />
              </FormGroup>
            </Grid>
            <Grid item md={4} xs={6}>
              <FormGroup row>
                <FormControlLabel
                    control={
                      <Checkbox
                          checked={fields.backwardMeter === 1}
                          onChange={()=>onFieldToggle('backwardMeter')}
                          name="backwardMeter"
                      />
                    }
                    label="Backward Meter"
                />
              </FormGroup>
            </Grid>
            <Grid item md={4} xs={6}>
              <FormGroup row>
                <FormControlLabel
                    control={
                      <Checkbox
                          checked={fields.hold === 1}
                          onChange={()=>onFieldToggle('hold')}
                          name="hold"
                      />
                    }
                    label="Hold / Vacation"
                />
              </FormGroup>
            </Grid>
          </Grid>


          {/*<FormGroup row>*/}
          {/*  <FormControlLabel*/}
          {/*    control={*/}
          {/*      <Checkbox*/}
          {/*        checked={evMeter}*/}
          {/*        onChange={toggleEVMeter}*/}
          {/*        name="EVMeter"*/}
          {/*      />*/}
          {/*    }*/}
          {/*    label="EV Meter"*/}
          {/*  />*/}
          {/*</FormGroup> */}
          {/*<FormGroup row>*/}
          {/*  <FormControlLabel*/}
          {/*    control={*/}
          {/*      <Checkbox*/}
          {/*        checked={evMeter}*/}
          {/*        onChange={toggleEVMeter}*/}
          {/*        name="EVMeter"*/}
          {/*      />*/}
          {/*    }*/}
          {/*    label="EV Meter"*/}
          {/*  />*/}
          {/*</FormGroup>*/}



          </Grid >
          <Grid container item spacing={2} justify={"flex-end"}>
            <Grid item>
              <MuiButton variant="contained" onClick={save} color="secondary">
                Save
              </MuiButton>
            </Grid>
          </Grid>
      </CardContent>
    </Card>
  );
};

// Component Properties
MeterForm.propTypes = {
  meters: PropTypes.object.isRequired,
  units: PropTypes.object.isRequired,
};

function MeterFormState(state) {
  return {
    meters: state.meters,
    units: state.units,
  };
}
export default connect(MeterFormState, { put, postByUnitUuid })(MeterForm);
