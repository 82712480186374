import {
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  CircularProgress as MuiCircularProgress,
  Divider as MuiDivider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper as MuiPaper,
  Select,
  TextField as MuiTextField,
  IconButton
} from "@material-ui/core";
//Material UI Components and Functions
import { makeStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
// Redux Components
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";

import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import {
  EditableTable,
  newConditionalOnSelectButton,
} from "../../../components/EditableTable";
import { getAllPropertyPreviews } from "../../apl/api/actions";
import { addSelectedPmBillAccountsSelectedToCheck } from "../../payments/api/actions";
import { searchForPmBillPacketsByFilters } from "../api/actions";

const Button = styled(MuiButton)(spacing);
const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)(spacing);
const DashboardToolBarButton = styled(Button)`
  padding: 4px;
  min-width: 0;
  svg {
    width: 2em;
    height: 2em;
  }
`;

const CircularProgress = styled(MuiCircularProgress)(spacing);

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  searchBarGrid: {
    //paddingBottom: "10px",
    fontSize: "90%",
    height: "5%",
  },
  searchDropDownButton: {
    height: "90%",
  },
  percentDif: {
    textAlign: "right",
    color: "green",
  },
  errorColorIcon: {
    color: "red",
  },
  successColorIcon: {
    color: "green",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  loadingIcon: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  active: {
    fontWeight: "bold",
    color: "#f9a825",
    borderColor: "#f9a825",
  },
  inactive: {
    fontWeight: "bold",
  },
}));

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Spacer = styled.div`
  flex: 1 1 100%;
`;

// Editable Table Object Expects: {objectArray, fieldTitles(Array), fieldTypes(Array), fieldNames(Array), handleRowClickParentMethod}
// Method can take line items if they exist initially or starts as an empty array of nothing passed
function PmBillPacketTable(props) {
  const classes = useStyles();
  const history = useHistory();

  // Table Data management
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [resetCache, setResetCachedData] = React.useState(true);

  const [property, setProperty] = React.useState(null);
  const [propertyName, setPropertyName] = React.useState(null);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [focus, setFocus] = React.useState(false);

  const handleChangeRowsPerPage = (rowsPerPage) => {
    setPage(0);
    setResetCachedData(false);
    setRowsPerPage(rowsPerPage);
  };

  const handleSelectedPropertyChange = (event) => {
    setPage(0);
    setResetCachedData(true);
    if (event.target.value == "" || event.target.value == null) {
      setPropertyName(null);
    }
    else {
      var target = props.apl.data.propertyPreviews.find(x => x.uuid === event.target.value);
      setPropertyName(target.name);
    }
    setProperty(event.target.value);
  };

  const handleChangePage = (page) => {
    setResetCachedData(false);
    setPage(page);
  };


  const setSearchFocus = () => {
    setFocus(true);
  }
  const unsetSearchFocus = () => {
    setFocus(false);
  }

  const getAccountsWithQuery = () => {
    const pmUuid = props.pmUuid;
    const query = { page, rowsPerPage, pmUuid, searchQuery, property, resetCache };
    props.searchForPmBillPacketsByFilters(query);
  };

  // When the textbox changes
  const handleSearchTargetChange = (event) => {
    setPage(0);
    setResetCachedData(true);
    setSearchQuery(event.target.value);
  }

  useEffect(() => {
    if (
      (page + 1) * rowsPerPage > props.bills.pmBillPackets.items.length ||
      resetCache
    ) {
      getAccountsWithQuery();
    }
  }, [page, rowsPerPage, property, searchQuery]);

  const PmBillRowStatusStyling = (billPacket) => {
    return {}; // Returns an empty style as to not effect each row
  };

  const addAccountsSelectedToCheck = (items) => {
    props.addSelectedPmBillAccountsSelectedToCheck(items);
  };
  const CurrentLookupTypeButton = () => {
    return (
      <IconButton>
        <AssignmentIndIcon mr={2} />
      </IconButton>
    );
  }

  const Filters = () => {
    const classes = useStyles();

    return (
      <Grid container spacing={6}>
        <Grid item lg={8} md={8} sm={8}>
          {!props.isAccountPage && (
            <>
              <FormControl fullWidth my={2}>
                <InputLabel id="propertyToBillDropDownLabel">
                  Location{"  "}
                  {props.apl.isLoading && <CircularProgress size={15} m={0} />}
                </InputLabel>
                {property == "" && props.apl.isLoading ? (
                  <Select
                    labelId="propertyToBillDropDownLabel"
                    id="propertyToBillDropDown"
                    value={property}
                    className={classes.selectedPropertyLocationLabel}
                    onChange={handleSelectedPropertyChange}
                    fullWidth
                    disabled
                    my={2}
                  >
                  </Select>
                ) : (
                  <Select
                    labelId="propertyToBillDropDownLabel"
                    id="propertyToBillDropDown"
                    value={property}
                    className={classes.selectedPropertyLocationLabel}
                    onChange={handleSelectedPropertyChange}
                    fullWidth
                    my={2}
                  >
                    {props.apl.data.propertyPreviews.length > 0 ?
                      [
                        <MenuItem value={""}>All</MenuItem>,
                        props.apl.data.propertyPreviews.map((item, key) => {
                          return (
                            <MenuItem value={item.uuid} id={key}>
                              {item.name}
                            </MenuItem>
                          );
                        })
                      ]
                      : [<MenuItem value={-1}>No Properties Found</MenuItem>]
                    }
                  </Select>
                )}
              </FormControl>
            </>
          )}
        </Grid>
      </Grid>
    );
  };

  const PmInvoiceNumberSearchBar = () => {
    return (
      <>
        {focus ?
          <TextField
            id={"searchTarget"}
            label="Search By Invoice Characteristics"
            type={"number"}
            onChange={handleSearchTargetChange}
            autoFocus={"autofocus"}
            name={"searchTarget"}
            defaultValue={searchQuery}
            onBlur={unsetSearchFocus}
            fullWidth
            my={2}
            autoComplete={"off"}
            size={"small"}
            variant={"outlined"}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{ endAdornment: <CurrentLookupTypeButton /> }}
          >
          </TextField>
          :
          <TextField
            id={"searchTarget"}
            label="Search By Invoice Characteristics"
            type={"number"}
            onChange={handleSearchTargetChange}
            name={"searchTarget"}
            defaultValue={searchQuery}
            onClick={setSearchFocus}
            fullWidth
            autoComplete={"off"}
            my={2}
            size={"small"}
            variant={"outlined"}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{ endAdornment: <CurrentLookupTypeButton /> }}
          >
          </TextField>
        }

      </>
    );
  }


  // Table Stuff:
  const fieldTitles = [
    "ID",
    "Type",
    "Total",
    "Bill Packet Date",
    "Due Date",
    "Bill Count",
    "Next Read Rate",
    "Bill",
    "Packet"
    
  ];
  const fieldTypes = [
    "label",
    "label",
    "moneyLabel",
    "dateLabel",
    "dateLabel",
    "label",
    "dateLabel",
    "buttonLink",
    "buttonLink"

  ];
  const fieldNames = [
    "id",
    "billType",
    "billTotal",
    "billDate",
    "dueDate",
    "childBillCount",
    "nextReadDate",
    "url",
    "pmpacket"
    
  ];

  const bulkCheckFieldTitles = [
    "Invoice #",
    "PM",
    "Total",
    "Bill Date",
    "Due Date",
    "Bill Count",
  ];
  const bulkCheckFieldTypes = [
    "label",
    "label",
    "moneyLabel",
    "dateLabel",
    "dateLabel",
    "label",
  ];
  const bulkCheckFieldNames = [
    "invoiceNumber",
    "pmName",
    "billTotal",
    "billDate",
    "dueDate",
    "childBillCount",
  ];
  const onPmBillSelected = (item) => {
    //window.open("/accountmanager/" + item.accountNum);
  };
  const ConditionalOnSelectButtons = [
    newConditionalOnSelectButton(
      "Add Unsettled Accounts To Check",
      () => {
        return true;
      },
      addAccountsSelectedToCheck
    ),
  ];
  return (
    <Card mb={6} elevation={3}>
      <CardContent>
        <Grid container xs={12} alignItems="center" spacing={2}>
          <Grid item lg={12} md={12} sm={12}>
            <PmInvoiceNumberSearchBar />
          </Grid>
        </Grid>
        {props.isBulkCheckEntry ? (
          <EditableTable
            Filters={<Filters />}
            readOnly={false}
            canAddItems={false}
            canSave={false}
            canDelete={false}
            isLoading={props.bills.pmBillPacketIsLoading}
            conditionalStylingMethod={PmBillRowStatusStyling}
            // Functional methods of table
            handleRowClickParentMethod={onPmBillSelected}
            // Properties of table
            tableTitle={"Property Manager Bills"}
            objectArray={props.bills.pmBillPackets}
            fieldTitles={bulkCheckFieldTitles}
            fieldTypes={bulkCheckFieldTypes}
            fieldNames={bulkCheckFieldNames}
            customChangePageRowsEvent={handleChangeRowsPerPage}
            customHandleChangePageEvent={handleChangePage}
            defaultItemCount={rowsPerPage}
            conditionalOnSelectButtons={ConditionalOnSelectButtons}
            totalRowCount={
              props.bills.pmBillPacketsSummary == null
                ? 0
                : props.bills.pmBillPacketsSummary.totalRows
            }
          />
        ) : (
          <EditableTable
            readOnly={true}
            canAddItems={false}
            canSave={false}
            isLoading={props.bills.pmBillPacketIsLoading}
            conditionalStylingMethod={PmBillRowStatusStyling}
            // Functional methods of table
            handleRowClickParentMethod={onPmBillSelected}
            currentActiveFilters={[propertyName]}
            // Properties of table
            tableTitle={"Property Manager Bills"}
            objectArray={props.bills.pmBillPackets}
            fieldTitles={fieldTitles}
            fieldTypes={fieldTypes}
            fieldNames={fieldNames}
            customChangePageRowsEvent={handleChangeRowsPerPage}
            customHandleChangePageEvent={handleChangePage}
            defaultItemCount={rowsPerPage}
            totalRowCount={
              props.bills.pmBillPacketsSummary == null
                ? 0
                : props.bills.pmBillPacketsSummary.totalRows
            }
          />
        )}
      </CardContent>
    </Card>
  );
}
// Component Properties
PmBillPacketTable.propTypes = {
  // Store objects:
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  bills: PropTypes.object.isRequired,
  getUserBills: PropTypes.func.isRequired,
  searchForPmBillPacketsByFilters: PropTypes.func.isRequired,
  addSelectedPmBillAccountsSelectedToCheck: PropTypes.func.isRequired,
};

// Component State
function PmBillPacketTableState(state) {
  return {
    user: state.user,
    apl: state.apl,
    bills: state.bills,
  };
}
export default connect(PmBillPacketTableState, {
  getAllPropertyPreviews,
  searchForPmBillPacketsByFilters,
  addSelectedPmBillAccountsSelectedToCheck,
})(PmBillPacketTable);
