import {
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  CircularProgress as MuiCircularProgress,
  Divider as MuiDivider,
  Paper as MuiPaper,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
//Material UI Components and Functions
import { makeStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
import moment from "moment";
// Redux Components
import PropTypes from "prop-types";
import React from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { EditableTable } from "../../../components/EditableTable";
import { getAccountBalance, getAccountLedger } from "../../account/api/actions";
import { setSnackbar } from "../../snackbar/api/snackbar";
import {
  createNewAccountPayment,
  getUserPayments,
  pushUpdateToCurrentlySelectedAccountPaymentsList,
  setPayments,
} from "../api/actions";
import PaymentForm from "./PaymentForm";

const Button = styled(MuiButton)(spacing);

const DashboardToolBarButton = styled(Button)`
  padding: 4px;
  min-width: 0;
  svg {
    width: 2em;
    height: 2em;
  }
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CircularProgress = styled(MuiCircularProgress)(spacing);

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  percentDif: {
    textAlign: "right",
    color: "green",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  loadingIcon: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Spacer = styled.div`
  flex: 1 1 100%;
`;

//Editable Table Object Expects: {objectArray, fieldTitles(Array), fieldTypes(Array), fieldNames(Array), handleRowClickParentMethod}
// Method can take line items if they exist initially or starts as an empty array of nothing passed
function AccountRecentPaymentsTable(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const navTo = (page) => history.push(page);

  const [paymentFormOpen, setPaymentFormOpen] = React.useState(false);

  const PaymentRowStatusStyling = (payment) => {
    if (payment.status.includes("Success")) {
      return { backgroundColor: "#ccffc7" };
    }
    if (payment.status.includes("Pending")) {
      return { backgroundColor: "#feffc7" };
    }
    if (
      payment.status.includes("Cancelled") ||
      payment.status.includes("Rejected") ||
      payment.status.includes("Declined")
    ) {
      return { backgroundColor: "#ffc7c8" };
    }
    if (payment.status.includes("Unknown")) {
      //return { backgroundColor: '#2a2b2e', color: '#ffffff'};
    }
    return {};
  };

  const handlePaymentFormClickOpen = () => {
    setPaymentFormOpen(true);
  };

  const handlePaymentFormClose = () => {
    setPaymentFormOpen(false);
  };

  const updateLocalItemsValues = (items) => {
    // Replaces existing store object with new object with modified sub data
    props.setPayments(items);
  };

  // To be used to save over the api in the database
  const saveAllLineItems = (payments) => {
    if (payments.toUpdate.length > 0) {
      dispatch(setSnackbar(true, "warning", "Updating Account Payments"));
    }
    payments.toUpdate.map((targetUuid) => {
      let targetItemIndex = payments.items.findIndex(
        (x) => x.uuid === targetUuid
      );
      if (targetItemIndex != -1) {
        props
          .pushUpdateToCurrentlySelectedAccountPaymentsList(
            payments.items[targetItemIndex]
          )
          .then(() => {
            dispatch(
              setSnackbar(true, "success", "Payment updated successfully")
            );
            const today = moment().format("MM/DD/YYYY");
            let typeID = -1;
            for (
              var x = 0;
              x < props.apl.genericTypes.paymentMethodType.data.length;
              x++
            ) {
              if (
                props.apl.genericTypes.paymentMethodType.data[x].description ==
                "Account Credit"
              ) {
                typeID = props.apl.genericTypes.paymentMethodType.data[x].id;
              }
            }
            var newReversedPayment = {
              paymentType: typeID,
              paymentNote: "PAYMENT REVERSAL",
              paymentAmount:
                payments.items[targetItemIndex].paymentAmount * -1,

              routingNumber: "",
              accountNumber: "",

              nameOnCard: "",
              cardNumber: "",
              cardType: "",
              cardExpMonth: "",
              cardExpYear: "",
              cardCvv: "",
              paymentDate: today,
              transactionFee: 0,
              transactionTotal: 0,
            };
            dispatch(setSnackbar(true, "warning", "Updating Account Ledger"));
            props
              .createNewAccountPayment(
                props.account.data.account.uuid,
                newReversedPayment
              )
              .then(() => {
                props.getAccountBalance(props.account.data.account.id);
                props.getUserPayments(props.account.data.account.uuid);
                props.getAccountLedger(props.account.data.account.accountNum);
                dispatch(
                  setSnackbar(
                    true,
                    "success",
                    "Account ledger updated successfully"
                  )
                );
              });
          });
      }
    });
  };

  const fieldTitles = [
    "Payment ID",
    "Status",
    "Payment Date",
    "Fee Amount",
    "Payment Amount",
    "Reversed",
  ];
  const fieldTypes = [
    "label",
    "label",
    "dateLabel",
    "moneyLabel",
    "moneyLabel",
    "switch",
  ];
  const fieldNames = [
    "paymentMethodId",
    "status",
    "paymentDate",
    "feeAmount",
    "paymentAmount",
    "reversed",
  ];

  const onPaymentSelected = (item) => {
    //
  };

  const createNewPayment = () => {
    handlePaymentFormClickOpen();
  };
  return (
    <div>
      <EditableTable
        readOnly={true}
        canAddItems={true}
        canSave={true}
        saveCurrentTableData={saveAllLineItems}
        updateItemsLocalState={updateLocalItemsValues}
        isLoading={props.payments.isLoading}
        conditionalStylingMethod={PaymentRowStatusStyling}
        // Functional methods of table
        newItemCreationMethod={handlePaymentFormClickOpen}
        handleRowClickParentMethod={onPaymentSelected}
        // Properties of table
        tableTitle={"Recent Payments"}
        objectArray={props.payments.data}
        objectError={props.payments.error}
        fieldTitles={fieldTitles}
        fieldTypes={fieldTypes}
        fieldNames={fieldNames}
      />
      <Dialog
        open={paymentFormOpen}
        TransitionComponent={Transition}
        fullWidth={"lg"}
        maxWidth={"lg"}
        keepMounted
        onClose={handlePaymentFormClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        {props.account.data.account ? (
          <DialogTitle id="alert-dialog-slide-title">
            New Payment for Account #{props.account.data.account.accountNum}{" "}
          </DialogTitle>
        ) : (
          <DialogTitle id="alert-dialog-slide-title">
            New Payment for Account: LOADING.....{" "}
          </DialogTitle>
        )}
        <DialogContent>
          <PaymentForm
            onUpdate={handlePaymentFormClose}
            onCancel={handlePaymentFormClose}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
// Component Properties
AccountRecentPaymentsTable.propTypes = {
  // Store objects:
  payments: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  setPayments: PropTypes.func.isRequired,
  pushUpdateToCurrentlySelectedAccountPaymentsList: PropTypes.func.isRequired,
  getAccountBalance: PropTypes.func.isRequired,
  createNewAccountPayment: PropTypes.func.isRequired,
  getUserPayments: PropTypes.func.isRequired,
  getAccountLedger: PropTypes.func.isRequired,
};

// Component State
function AccountRecentPaymentsTableState(state) {
  return {
    account: state.account,
    apl: state.apl,
    payments: state.payments,
  };
}
export default connect(AccountRecentPaymentsTableState, {
  setPayments,
  pushUpdateToCurrentlySelectedAccountPaymentsList,
  getAccountBalance,
  createNewAccountPayment,
  getUserPayments,
  getAccountLedger,
})(AccountRecentPaymentsTable);
