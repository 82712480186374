import {
  GET_DEVELOPER,
  GET_DEVELOPERS,
  GET_DEVELOPERS_RESPONSE,
  GET_DEVELOPER_RESPONSE,
  RESET,
} from "./actions";

const initialState = {
  isLoading: false,
  error: null,
  data: {
    developersList: {
      items: [],
    },
    developer: null,
    searchQuery: null,
    range: { start: 0, end: 25 },
  },
};

// State
export default (state = initialState, action) => {
  var data = state.data;
  switch (action.type) {
    case RESET:
      return Object.assign({}, initialState);
    case GET_DEVELOPERS:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case GET_DEVELOPERS_RESPONSE:
      data.developersList.items = action.developers;
      return {
        ...state,
        data: data,
        error: action.error,
        isLoading: false,
      };
    case GET_DEVELOPER:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case GET_DEVELOPER_RESPONSE:
      data.developer = action.developer;
      return {
        ...state,
        data: data,
        error: action.error,
        isLoading: false,
      };
    default:
      return {
        ...state,
      };
  }
};
