import {
  Button as MuiButton,
  Card,
  CardContent,
  CardHeader as MuiCardHeader,
  CircularProgress as MuiCircularProgress,
  Divider as MuiDivider,
  Grid,
  LinearProgress as MuiLinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import { EditableTable } from "../../../components/EditableTable";
//import { login, logout } from '../../modules/user/api/actions';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import TableContainer from "@material-ui/core/TableContainer";
import EditIcon from "@material-ui/icons/Edit";
import Skeleton from "@material-ui/lab/Skeleton";
import { spacing } from "@material-ui/system";
// Redux Components
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { dateFormatter, formatMoney } from "../../../setup/helpers";
import { getPaymentLineItems } from "../api/actions";


const LinearProgress = MuiLinearProgress;
const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);

const CardHeader = styled(MuiCardHeader)(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  percentDif: {
    textAlign: "right",
    color: "green",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  loadingIcon: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

const SelectedPaymentInformationCard = (props) => {
  const history = useHistory();
  const classes = useStyles();


  const fieldTitles = [
    "Date Posted",
    "Amount Posted",
    "GL Code",
    "Bill",
    "Item Description"
  ];
  const fieldTypes = [
    "dateLabel",
    "moneyLabel",
    "label",
    "label",
    "label"
  ];
  const fieldNames = [
    "created",
    "amtPosted",
    "glCode",
    "accountBillId",
    "accountBillLineItemDescription",
  ];

  useEffect(() => {
    if (props.isOpen) {
      props.getPaymentLineItems(props.payments.selectedPayment.id);
    }
  }, [props.isOpen]);

  const PaymentRecieptLineItemRowStyling = (paymentLineItem) => {
    if (!paymentLineItem.accountBillLineItemDescription || paymentLineItem.accountBillLineItemDescription == null) {
      if (!paymentLineItem.glCodeAccountDescription || paymentLineItem.glCodeAccountDescription == null || paymentLineItem.glCodeAccountDescription == "") {
        paymentLineItem.accountBillLineItemDescription = "Unknown";
      }
      else {
        paymentLineItem.accountBillLineItemDescription = paymentLineItem.glCodeAccountDescription; // uses the line item as a backup if present and contains a gl code
      }
    }
    if (!paymentLineItem.accountBillId || paymentLineItem.accountBillId == null) {
      paymentLineItem.accountBillId = "Unknown";
    }
    if (!paymentLineItem.glCode || paymentLineItem.glCode == null) {
      if (!paymentLineItem.accountBillLineItemGlCode || paymentLineItem.accountBillLineItemGlCode == null || paymentLineItem.accountBillLineItemGlCode == "") {
        paymentLineItem.glCode = "No GL Code Listed";
      }
      else {
        paymentLineItem.glCode = paymentLineItem.accountBillLineItemGlCode; // uses the line item as a backup if present and contains a gl code
      }
    }
    return {};
  };

  let authorizeCustomerProfileId = "N/A";
  let authorizePaymentProfileId = "N/A";
  if (props.payments.selectedPayment != null) {
    if (props.payments.selectedPayment.authorizeProfileId != null) {
      let splitProfile = props.payments.selectedPayment.authorizeProfileId.split('/');
      authorizeCustomerProfileId = splitProfile[0];
      authorizePaymentProfileId = splitProfile[1];
    }
  }

  return (
    <Card mb={6}>
      <CardHeader
        xs={12}
        sm={12}
        md={12}
        lg={12}
        title="Payment Info"
      />
      <CardContent xs={6} sm={6} md={6} lg={6}>
        <Divider className="mb-2 mt-2" my={2} />
        {props.payments.selectedPaymentDataIsLoading ? (
          <>

          </>
        ) : (
          props.payments.selectedPayment != null ?
            <Grid container spacing={2} lg={12}>
              <Grid item xs={12} lg={6}>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography variant="body1">Payment Date</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {dateFormatter(props.payments.selectedPayment.paymentDate) || "N/A"}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography variant="body1">Payment Amount</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {formatMoney(props.payments.selectedPayment.paymentAmount) || "N/A"}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography variant="body1">Payment Fees</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {formatMoney(props.payments.selectedPayment.feeAmount) || "N/A"}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography variant="body1">Payment Type</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {props.payments.selectedPayment.paymentMethodTypeDesc || props.payments.selectedPayment.paymentObjectTypeDesc || "N/A"}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      {
                        /*<TableRow>
                          <TableCell component="th" scope="row">
                            <Typography variant="body1">Auth Number</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2">
                              {(props.payments.selectedPayment.authNum != "") ? props.payments.selectedPayment.authNum : "N/A"}
                            </Typography>
                          </TableCell>
                        </TableRow>*/
                      }
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography variant="body1">Check Number</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {(props.payments.selectedPayment.legacyCheckNum != "" && props.payments.selectedPayment.legacyCheckNum != null) ? props.payments.selectedPayment.legacyCheckNum : (props.payments.selectedPayment.paymentMethodTypeDesc == "Check" || props.payments.selectedPayment.paymentObjectTypeDesc == "Check" || props.payments.selectedPayment.paymentMethodTypeDesc == "WB Lockbox" || props.payments.selectedPayment.paymentObjectTypeDesc == "WB Lockbox" | props.payments.selectedPayment.paymentMethodTypeDesc == "HNB eLockbox" || props.payments.selectedPayment.paymentObjectTypeDesc == "HNB eLockbox") ? "N/A" : "Not Applicable"}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography variant="body1">Notes</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {props.payments.selectedPayment.legacyNotes || "N/A"}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography variant="body1">Submitted By</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {props.payments.selectedPayment.CreatorName || "N/A"}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography variant="body1">Status</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {props.payments.selectedPayment.status || "N/A"}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12} lg={6}>
                <>{/*
                  {(props.payments.selectedPayment.ccUuid == null && props.payments.selectedPayment.achUuid == null && props.payments.selectedPayment.forteTransactionId == null) &&
                    <TableContainer component={Paper}>
                      <Table className={classes.table} aria-label="simple table">
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              <Typography variant="body2">
                                No Payment Remote Resource Information Found!
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  }
                */}
                </>
                <>
                  {(props.payments.selectedPayment.ccUuid != null) &&
                    <TableContainer component={Paper}>
                      <Table className={classes.table} aria-label="simple table">
                        <TableBody>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              <Typography variant="body1">Card Type</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body2">
                                {props.payments.selectedPayment.ccType || "N/A"}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              <Typography variant="body1">Name on Card</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body2">
                                {props.payments.selectedPayment.ccName || "N/A"}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              <Typography variant="body1">Card Number</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body2">
                                {props.payments.selectedPayment.ccNum || "N/A"}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              <Typography variant="body1">Expiration </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body2">
                                {props.payments.selectedPayment.ccExpMonth || "N/A"}/{props.payments.selectedPayment.ccExpYear || "N/A"}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  }
                </>
                <>
                  {(props.payments.selectedPayment.achUuid != null) ?
                    <TableContainer component={Paper}>
                      <Table className={classes.table} aria-label="simple table">
                        <TableBody>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              <Typography variant="body1">Bank Name</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body2">
                                {props.payments.selectedPayment.achBankName || "N/A"}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              <Typography variant="body1">Bank Account Holder Name</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body2">
                                {props.payments.selectedPayment.achOwnerName || "N/A"}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              <Typography variant="body1">Bank Account Number</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body2">
                                {props.payments.selectedPayment.bankAcctNum || "N/A"}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              <Typography variant="body1">Bank Routing Number</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body2">
                                {props.payments.selectedPayment.bankRoutingNum || "N/A"}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          {(props.payments.selectedPayment.authorizeProfileId != null) &&
                            <>
                              <TableRow>
                                <TableCell component="th" scope="row">
                                  <Typography variant="body1">Customer Profile Id (AuthorizeNet)</Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="body2">
                                    {authorizeCustomerProfileId || "N/A"}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell component="th" scope="row">
                                  <Typography variant="body1">Payment Profile Id (AuthorizeNet)</Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="body2">
                                    {authorizePaymentProfileId || "N/A"}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            </>
                          }
                          <TableRow>
                            <TableCell component="th" scope="row">
                              <Typography variant="body1">Transaction Id (AuthorizeNet)</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body2">
                                {props.payments.selectedPayment.forteTransactionId || "N/A"}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                    :
                    <>
                      <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                          <TableBody>
                            {(props.payments.selectedPayment.forteTransactionId != null || props.payments.selectedPayment.authNum != null) ?
                              <TableRow>
                                <TableCell component="th" scope="row">
                                  {(props.payments.selectedPayment.forteTransactionId != null || (props.payments.selectedPayment.authNum != null && props.payments.selectedPayment.authNum.length == 11)) ?
                                    <Typography variant="body1">Transaction Id (AuthorizeNet)</Typography>
                                    :
                                    <Typography variant="body1">Transaction Id (Chase)</Typography>
                                  }
                                </TableCell>
                                <TableCell>
                                  <Typography variant="body2">
                                    {props.payments.selectedPayment.forteTransactionId || props.payments.selectedPayment.authNum || "N/A"}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                              :
                              <TableRow>
                                <TableCell>
                                  <Typography variant="body2">
                                    No Payment Remote Resource Information Found!
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  }
                </>
              </Grid>
              <Grid item xs={12} lg={12}>
                <EditableTable
                  readOnly={true}
                  canAddItems={false}
                  canSave={false}
                  isLoading={props.payments.selectedPaymentLineItemsAreLoading}
                  // Properties of table
                  tableTitle={"Payment Line Items"}
                  canExport={true}
                  objectArray={props.payments.selectedPaymentLineItems}
                  conditionalStylingMethod={PaymentRecieptLineItemRowStyling}
                  fieldTitles={fieldTitles}
                  fieldTypes={fieldTypes}
                  fieldNames={fieldNames}
                  objectError={props.payments.selectedPaymentLineItemsError}
                />
              </Grid>
            </Grid>
            :
            <Grid item xs={12} lg={12}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Typography variant="body2">
                          Space reserved for remote payment resource results
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
        )}
      </CardContent>
    </Card>
  );
};

// Component Properties
SelectedPaymentInformationCard.propTypes = {
  // Store objects:
  payments: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  setPayments: PropTypes.func.isRequired,
  getPaymentLineItems: PropTypes.func.isRequired,
};

// Component State
function SelectedPaymentInformationCardState(state) {
  return {
    account: state.account,
    apl: state.apl,
    payments: state.payments,
  };
}
export default connect(
  SelectedPaymentInformationCardState,
  { getPaymentLineItems }
)(SelectedPaymentInformationCard);
