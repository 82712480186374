// App Imports
import moment from "moment";
import {
  BILL_ACCOUNT_BILLS_RESET,
  BILL_DELETE_ACCOUNT_BILLS_REQUEST,
  BILL_DELETE_ACCOUNT_BILLS_RESPONSE,
  BILL_DELETE_ACCOUNT_BILLS_FAILURE,
  BILL_GET_ACCOUNT_BILLS_FAILURE,
  BILL_GET_ACCOUNT_BILLS_REQUEST,
  BILL_GET_ACCOUNT_BILLS_RESPONSE,
  BILL_GET_ALL_ACCOUNT_BILLS_FAILURE,
  BILL_GET_ALL_ACCOUNT_BILLS_REQUEST,
  BILL_GET_ALL_ACCOUNT_BILLS_RESPONSE,
  BILL_GET_PM_BILLS_REQUEST,
  BILL_GET_PM_BILLS_RESPONSE,
  BILL_CREAT_NEW_ACCOUNT_BILL_REQUEST,
  BILL_UPDATE_NEW_ACCOUNT_BILL_IN_PROGRESS,
  SET_BILL_LIST,
  SET_ERROR,
  SET_PM_BILL_LIST,
  TIMES_LATE_REQUEST,
  TIMES_LATE_RESPONSE,
  TIMES_LATE_FAILURE
} from "./actions";

const today = moment().format("MM/DD/YYYY");
const dueDate = moment().add(14, "d").format("MM/DD/YYYY");
// Initial State
const billsInitialState = {
  isLoading: true,
  error: null,
  accountBillsTotalRows: null,
  data: {
    items: [],
    toDelete: [],
    toUpdate: [],
  },
  accountBillsSummary: null,
  accountBillingTimesLateIsLoading: true,
  accountBillingTimesLate: 0,
  newBill: {
    vendorBillUUID: -1,
    vendorID: null,
    fiscalMonth: 0,
    dueDate: dueDate,
    tariffRateTotal: 0,
    kwhUsedTotal: 0,
    fiscalYear: 0,
    billDate: today,
    marketRateTotal: 0,
    kwhTotal: 0,
    meterReadDate: "",
    serviceStartDate: "",
    serviceEndDate: "",
    billTotal: 0,
    costPerKWH: 0,
    // Each table consists of 3 sub arrays:
    newBillLineItems: {
      items: [],
      toDelete: [], // Array of uuids
      toUpdate: [], // Array of uuids
    },
  },
  pmBillPacketIsLoading: true,
  pmBillPackets: {
    items: [],
    toDelete: [],
    toUpdate: [],
  },
  pmBillPacketsSummary: null,
};

// State
export default (state = billsInitialState, action) => {
  switch (action.type) {
    case SET_BILL_LIST:
      return {
        ...state,
        data: action.data,
      };

    case SET_PM_BILL_LIST:
      var newStateData = state.pmBillPackets.items;
      if (action.resetCache) {
        let pmBills = {
          items: action.data.items,
          toDelete: [],
          toUpdate: [],
        };
        newStateData = pmBills;
      } else {
        let pmBills = {
          items: newStateData.concat(action.data.items),
          toDelete: [],
          toUpdate: [],
        };
        newStateData = pmBills;
      }
      if (action.isBaseGroup) {
        return {
          ...state,
          pmBillPacketsSummary: action.data.summary,
        };
      } else {
        return {
          ...state,
          pmBillPackets: newStateData,
          pmBillPacketsSummary: action.data.summary,
        };
      }
    case BILL_GET_ALL_ACCOUNT_BILLS_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case BILL_GET_ALL_ACCOUNT_BILLS_RESPONSE:
      if (action.isReset) {
        state.data.items = action.data.items;
      } else {
        var newArray = state.data.items.concat(action.data.items); 
        state.data.items = newArray;
      }
      return {
        ...state,
        data: state.data,
        error: action.error,
        isLoading: false,
        accountBillsSummary: action.data.summary,
        accountBillsTotalRows: action.data.summary.totalRows,
      };
    case BILL_GET_ALL_ACCOUNT_BILLS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case BILL_GET_PM_BILLS_REQUEST:
      return {
        ...state,
        pmBillPacketIsLoading: action.isLoading,
        error: null,
      };

    case BILL_GET_PM_BILLS_RESPONSE:
      return {
        ...state,
        pmBillPacketIsLoading: false,
        error: action.error,
      };

    case BILL_DELETE_ACCOUNT_BILLS_REQUEST:
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
      };
    case TIMES_LATE_REQUEST:
      return {
        ...state,
        accountBillingTimesLateIsLoading: action.isLoading,
        error: null,
      };

    case TIMES_LATE_RESPONSE:
      return {
        ...state,
        accountBillingTimesLateIsLoading: false,
        accountBillingTimesLate: action.response.timesLate,
        error: action.error,
      };
    case TIMES_LATE_FAILURE:
      return {
        ...state,
        accountBillingTimesLateIsLoading: false,
        error: action.error,
      };
    case BILL_DELETE_ACCOUNT_BILLS_RESPONSE:
      let rowCount = state.accountBillsTotalRows;
      if (action.error == null) {
        rowCount = state.accountBillsTotalRows - 1;
      }
      return {
        ...state,
        accountBillsTotalRows: rowCount,
        isLoading: action.isLoading,
        error: action.error,
      };

    case BILL_GET_ACCOUNT_BILLS_REQUEST:
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
      };

    case BILL_CREAT_NEW_ACCOUNT_BILL_REQUEST:
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
      }

    case BILL_GET_ACCOUNT_BILLS_RESPONSE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        data: action.data,
      };

    case BILL_GET_ACCOUNT_BILLS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

      case BILL_DELETE_ACCOUNT_BILLS_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: action.error,
        };
    case BILL_UPDATE_NEW_ACCOUNT_BILL_IN_PROGRESS:
      // Merges the old and new property objects together
      const newBillUpdated = Object.assign(
        {},
        state.newBill,
        action.newBillData
      );
      return {
        ...state,
        newBill: newBillUpdated,
      };
    case BILL_ACCOUNT_BILLS_RESET:
      return Object.assign({}, billsInitialState);

    case SET_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
};
