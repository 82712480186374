import React from 'react';
import {
  CircularProgress as MuiCircularProgress, Divider as MuiDivider, FormControl as MuiFormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField as MuiTextField,
} from "@material-ui/core";
import {txtBoxDateFormatter} from "../../../../setup/helpers";
import {connect, useDispatch} from "react-redux";
import {updateBillInProgressBillCalculation} from "../../../../modules/billing/api/actions";
import styled from "styled-components/macro";
import {spacing} from "@material-ui/system";
import {makeStyles} from "@material-ui/core/styles";


const Divider = styled(MuiDivider)(spacing);
const FormControl = styled(MuiFormControl)(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);
const TextField = styled(MuiTextField)(spacing);


const VendorSelect = (props) => {
  const useStyles = makeStyles(() => ({
    selectedVendorLabel: {
      fontSize: 16,
      backgroundColor: "#fffffff",
      borderColor: "#fffffff",
    },
  }));
  const classes = useStyles();

  const dispatch = useDispatch();
  const {vendorInformation, billInformation} = props.billing.data.billInProgress;

  const onSelectedVendorChange = (event) => {
    const selectedVendorID = event.target.value;
    dispatch(updateBillInProgressBillCalculation({
      vendorInformation: {
        ...vendorInformation,
        vendorID: selectedVendorID,
      }
    }));
  };

  const onFiscalYearChange = (event) => {
    const selectedYear = Number(event.target.value);
    const selectedMonth = vendorInformation.fiscalMonth;
    const lastDayOfMonth = new Date(selectedYear, selectedMonth, 0);
    const formattedLastDay = lastDayOfMonth.toISOString().split('T')[0];
    dispatch(updateBillInProgressBillCalculation({
      vendorInformation: {
        ...vendorInformation,
        fiscalYear: Number(event.target.value),
      },
      billInformation: {
        ...billInformation,
        nextReadDate: formattedLastDay,
      }
    }));
  };

  const onFiscalMonthFieldChange = (event) => {
    const selectedMonth = event.target.value;
    const lastDayOfMonth = new Date(new Date().getFullYear(), selectedMonth, 0);
    const formattedLastDay = lastDayOfMonth.toISOString().split('T')[0];

    dispatch(updateBillInProgressBillCalculation({
      vendorInformation: {
        ...vendorInformation,
        fiscalMonth: selectedMonth,
      },
      billInformation: {
        ...billInformation,
        nextReadDate: formattedLastDay,
      }
    }));
  };

  const onBillDateFieldChange = (event) => {
    dispatch(updateBillInProgressBillCalculation({
      vendorInformation: {
        ...vendorInformation,
        billDate: event.target.value,
      },
      billInformation: {
        ...billInformation,
        billDate: event.target.value,
      }
    }));
  };

  const onDueDateChange = (event) => {
    dispatch(updateBillInProgressBillCalculation({
      billInformation: {
        ...billInformation,
        dueDate: event.target.value,
      }
    }));
  };
  const onNextReadDateChange = (event) => {
    dispatch(updateBillInProgressBillCalculation({
      billInformation: {
        ...billInformation,
        nextReadDate: event.target.value,
      }
    }));
  };

  const onDateChange = (event) => {
    dispatch(updateBillInProgressBillCalculation({
      vendorInformation: {
        ...vendorInformation,
        [event.target.name]: event.target.value,
      }
    }));
  };

  return (
    <>
      <Grid item lg={4} md={4} sm={12}>
        <FormControl fullWidth my={2}>
          <InputLabel id="vendorIDDropDownLabel" shrink>
            Vendor{"  "}
            {props.apl.genericTypes.vendor.isLoading && (
              <CircularProgress size={15} m={0}/>
            )}
          </InputLabel>
            <Select
              labelId="vendorIDDropDownLabel"
              id="vendorIDDropDown"
              value={vendorInformation.vendorID}
              disabled={vendorInformation.vendorID === -1 && props.apl.genericTypes.vendor.isLoading}
              onChange={onSelectedVendorChange}
              fullWidth
              my={2}
              className={classes.selectedVendorLabel}
            >
              {props.apl.genericTypes.vendor.data.length > 0 ? [
                props.apl.genericTypes.vendor.data.map((item, key) => {
                  return (
                    <MenuItem value={item.id} id={key}>
                      {item.name}
                    </MenuItem>
                  );
                })
              ] : [
                <MenuItem value={-1}>No Vendors Found</MenuItem>
              ]}
            </Select>
        </FormControl>
      </Grid>
      <Grid item lg={12} md={12} sm={12}>
        <Divider className="mb-3 mt-3"/>
      </Grid>
      <Grid item lg={4} md={4} sm={12}>
        <FormControl fullWidth my={2}>
          <InputLabel id="fiscalMonthDropDownLabel">
            Fiscal Month (MM)
          </InputLabel>
          <Select
            labelId="fiscalMonthDropDownLabel"
            id="fiscalMonth"
            name="fiscalMonth"
            value={vendorInformation.fiscalMonth}
            autoComplete={"off"}
            onChange={onFiscalMonthFieldChange}
            fullWidth
            my={2}
          >
            {[
              <MenuItem value={1}>January</MenuItem>,
              <MenuItem value={2}>February</MenuItem>,
              <MenuItem value={3}>March</MenuItem>,
              <MenuItem value={4}>April</MenuItem>,
              <MenuItem value={5}>May</MenuItem>,
              <MenuItem value={6}>June</MenuItem>,
              <MenuItem value={7}>July</MenuItem>,
              <MenuItem value={8}>August</MenuItem>,
              <MenuItem value={9}>September</MenuItem>,
              <MenuItem value={10}>October</MenuItem>,
              <MenuItem value={11}>November</MenuItem>,
              <MenuItem value={12}>December</MenuItem>
            ]}
          </Select>
        </FormControl>
      </Grid>
      <Grid item lg={4} md={4} sm={12}>
        <TextField
          id="fiscalYear"
          label="Fiscal Year (YYYY)"
          type="number"
          autoComplete={"off"}
          fullWidth
          my={2}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          value={vendorInformation.fiscalYear !== 0 && vendorInformation.fiscalYear}
          onChange={onFiscalYearChange}
          name="fiscalYear"
        />
      </Grid>
      <Grid item lg={4} md={4} sm={12}>
        <TextField
          id="billDate"
          label="Bill Date"
          type="date"
          fullWidth
          my={2}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          value={txtBoxDateFormatter(vendorInformation.billDate)}
          onChange={onBillDateFieldChange}
          name="billDate"
        />
      </Grid>
      <Grid item lg={4} md={4} sm={12}>
        <TextField
          id="dueDate"
          label="Due Date"
          type="date"
          fullWidth
          my={2}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          value={txtBoxDateFormatter(billInformation.dueDate)}
          onChange={onDueDateChange}
          name="billDate"
        />
      </Grid>
      <Grid item lg={4} md={4} sm={12}>
        <TextField
          id="nextReadDate"
          label="Next Read Date"
          type="date"
          fullWidth
          my={2}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          value={txtBoxDateFormatter(billInformation.nextReadDate)}
          onChange={onNextReadDateChange}
          name="nextReadDate"
        />
      </Grid>
      {props.propertyUtilities?.data.propertyUtility?.billingType === 'BILL' &&
        <Grid item lg={4} md={4} sm={12}>
          <TextField
            id="meterReadDate"
            label="Meter Read Date"
            type="date"
            fullWidth
            my={2}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            defaultValue={txtBoxDateFormatter(vendorInformation.meterReadDate)}
            onChange={onDateChange}
            name="meterReadDate"
          />
        </Grid>}
      <Grid item lg={4} md={4} sm={12}>
        <TextField
          id="periodStartDate"
          label="Period Start Date"
          type="date"
          fullWidth
          my={2}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          value={txtBoxDateFormatter(vendorInformation.periodStartDate)}
          onChange={onDateChange}
          name="periodStartDate"
        />
      </Grid>
      <Grid item lg={4} md={4} sm={12}>
        <TextField
          id="periodEndDate"
          label="Period End Date"
          type="date"
          fullWidth
          my={2}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          value={txtBoxDateFormatter(vendorInformation.periodEndDate)}
          onChange={onDateChange}
          name="periodEndDate"
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12}>
        <Divider className="mb-3 mt-3"/>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => ({
  apl: state.apl,
  billing: state.billing,
  propertyUtilities: state.propertyUtilities
})

export default connect(mapStateToProps)(VendorSelect);