import {
  Card,
  CardContent,
  Grid,
  Select as MuiSelect,
  TextField as MuiTextField,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { spacing } from "@material-ui/system";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import styled from "styled-components/macro";
import {useSelector} from "react-redux";

const Select = styled(MuiSelect)(spacing);
const TextField = styled(MuiTextField)(spacing);

export default (props) => {
  const owner = props.owner;
  const ownerList = useSelector(state => state.owners.data.ownersList);
  const handleClose = props.handleClose;
  const submitOwnerEdit = props.submitOwnerEdit;

  const [editOwnerObj, setEditOwnerObj] = useState({});
  const [editOwnerAddrObj, setEditOwnerAddrObj] = useState({});
  const [isGlCodeExists, setIsGlCodeExists] = useState(false);

  const onOwnerFieldChange = (e) => {
    const newValue = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setEditOwnerObj(
      Object.assign({}, editOwnerObj, { [e.target.name]: newValue })
    );
    if (e.target.name === 'glCode' && newValue !== owner.glCode) {
      setIsGlCodeExists(ownerList.items.some(owner => owner.glCode === newValue.trim()))
    }
  };

  const onAddrFieldChange = (e) => {
    setEditOwnerAddrObj(
      Object.assign({}, editOwnerAddrObj, { [e.target.name]: e.target.value })
    );
  };

  const action = async () => {
    let editObj = Object.assign(
      {},
      { addressInfo: editOwnerAddrObj },
      { ownerInfo: editOwnerObj }
    );
    if (!isGlCodeExists) {
    await submitOwnerEdit(owner, editObj);
    }
  };

  return (
    <div>
      {isEmpty(owner) ? (
        <div>Loading</div>
      ) : (
        <Card>
          <CardContent>
            {/* PropertyManager Details */}
            <Grid container spacing={2}>
              <h3>Owner Info:</h3>
              <Grid container item spacing={2}>
                <Grid item sm={6} xs={12}>
                  <TextField
                    label="Owner Name"
                    type="text"
                    onChange={onOwnerFieldChange}
                    name="name"
                    defaultValue={owner.name}
                    //required
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    label="Contact Name"
                    type="text"
                    onChange={onOwnerFieldChange}
                    name="contactName"
                    defaultValue={owner.contactName}
                    //required
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    label="Address 1"
                    type="text"
                    onChange={onAddrFieldChange}
                    name="address1"
                    defaultValue={owner.address1}
                    //required
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    label="Address 2"
                    type="text"
                    onChange={onAddrFieldChange}
                    name="address2"
                    defaultValue={owner.address2}
                    //required
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    label="City"
                    type="text"
                    onChange={onAddrFieldChange}
                    name="city"
                    defaultValue={owner.city}
                    //required
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item sm={3} xs={6}>
                  <TextField
                      label="State"
                      type="text"
                      onChange={onAddrFieldChange}
                      name="state"
                      defaultValue={owner.state}
                      //required
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                  />
                </Grid>
                <Grid item sm={3} xs={6}>
                  <TextField
                      label="Zip"
                      type="text"
                      onChange={onAddrFieldChange}
                      name="zip"
                      defaultValue={owner.zip}
                      //required
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                      label="Phone"
                      type="text"
                      onChange={onAddrFieldChange}
                      defaultValue={owner.phone}
                      name="phone"
                      //required
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                      label="Email"
                      type="text"
                      onChange={onAddrFieldChange}
                      defaultValue={owner.email}
                      name="email"
                      //required
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                      label="Accounting ID"
                      error={isGlCodeExists}
                      type="text"
                      onChange={onOwnerFieldChange}
                      defaultValue={owner.glCode}
                      name="glCode"
                      helperText={isGlCodeExists ? 'This Accounting ID is already in use.' : ''}
                    //required
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                  />
                </Grid>
                <Grid item sm={3} xs={6}>
                  <FormControlLabel
                      control={
                        <Checkbox
                            defaultChecked={owner.active == 1}
                            onChange={onOwnerFieldChange}
                            name="active"
                            value={owner.active == 1}
                        />
                      }
                      label="Active"
                  />
                </Grid>
              </Grid>

              {/*<Grid item sm={3} xs={6}>*/}
              {/*  <TextField*/}
              {/*    label="Customer Service Amount"*/}
              {/*    type="number"*/}
              {/*    onChange={onOwnerFieldChange}*/}
              {/*    name="customerServiceAmount"*/}
              {/*    defaultValue={owner.customerServiceAmount}*/}
              {/*    //required*/}
              {/*    fullWidth*/}
              {/*    variant="outlined"*/}
              {/*    InputLabelProps={{*/}
              {/*      shrink: true,*/}
              {/*    }}*/}
              {/*  />*/}
              {/*</Grid>*/}
              {/*<Grid item sm={3} xs={6}>*/}
              {/*  <TextField*/}
              {/*    label="Distribution Fee Amount"*/}
              {/*    type="number"*/}
              {/*    onChange={onOwnerFieldChange}*/}
              {/*    name="distFeeAmt"*/}
              {/*    defaultValue={owner.distFeeAmt}*/}
              {/*    //required*/}
              {/*    fullWidth*/}
              {/*    variant="outlined"*/}
              {/*    InputLabelProps={{*/}
              {/*      shrink: true,*/}
              {/*    }}*/}
              {/*  />*/}
              {/*</Grid>*/}

              {/*<Grid item sm={3} xs={6}>*/}
              {/*  <TextField*/}
              {/*    label="Profit Share %"*/}
              {/*    type="number"*/}
              {/*    onChange={onOwnerFieldChange}*/}
              {/*    name="profitSharePercent"*/}
              {/*    defaultValue={owner.profitSharePercent}*/}
              {/*    //required*/}
              {/*    fullWidth*/}
              {/*    variant="outlined"*/}
              {/*    InputLabelProps={{*/}
              {/*      shrink: true,*/}
              {/*    }}*/}
              {/*  />*/}
              {/*</Grid>*/}



              <h3>Mailing Address Info:</h3>

              <Grid container item spacing={2}>
                <Grid item xs={12} />
                <Grid item sm={6} xs={12}>
                  <TextField
                    label="Mailing Name"
                    type="text"
                    onChange={onOwnerFieldChange}
                    name="mailingName"
                    defaultValue={owner.mailingName}
                    //required
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    label="Mailing Contact Name"
                    type="text"
                    onChange={onOwnerFieldChange}
                    name="mailingContactName"
                    defaultValue={owner.mailingContactName}
                    //required
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                      label="Mailing Address 1"
                      type="text"
                      onChange={onOwnerFieldChange}
                      name="mailingAddress1"
                      defaultValue={owner.mailingAddress1}
                      //required
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                      label="Mailing Address 2"
                      type="text"
                      onChange={onOwnerFieldChange}
                      name="mailingAddress2"
                      defaultValue={owner.mailingAddress2}
                      //required
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                      label="Mailing City"
                      type="text"
                      onChange={onOwnerFieldChange}
                      name="mailingCity"
                      defaultValue={owner.mailingCity}
                      //required
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                  />
                </Grid>
                <Grid item sm={3} xs={6}>
                  <TextField
                      label="Mailing State"
                      type="text"
                      onChange={onOwnerFieldChange}
                      name="mailingState"
                      defaultValue={owner.mailingState}
                      //required
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                  />
                </Grid>
                <Grid item sm={3} xs={6}>
                  <TextField
                      label="Mailing Zip"
                      type="text"
                      onChange={onOwnerFieldChange}
                      defaultValue={owner.mailingZip}
                      name="mailingZip"
                      //required
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                      label="Mailing Email"
                      type="text"
                      onChange={onOwnerFieldChange}
                      name="mailingEmail"
                      defaultValue={owner.mailingEmail}
                      //required
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <TextField
                    label="Notes"
                    type="text"
                    onChange={onOwnerFieldChange}
                    defaultValue={owner.notes}
                    name="notes"
                    multiline
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container item spacing={2} justify={"flex-end"}>
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      action();
                      if (!isGlCodeExists) handleClose();
                    }}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
    </div>
  );
};
