import {
  Button as MuiButton,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components/macro";
import { getByUuid as getPropertyByUuid } from "../../property/api/actions";
import { txtBoxDateFormatter } from "../../../setup/helpers";
import {
  updatePropertyGLCodeLocalState,
  getByPropertyId as getPropertyGLCode,
  put as updatePropertyGLCode
} from "../api/actions";
import { UnsubscribeOutlined } from "@material-ui/icons";
import { setSnackbar } from "../../snackbar/api/snackbar";
import { post, put } from "../api/actions";

const TextField = styled(MuiTextField)(spacing);
const Select = styled(MuiSelect)(spacing);
const Button = styled(MuiButton)(spacing);

const PropertyGLCodeForm = (props) => {
  const useStyles = makeStyles((theme) => ({
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    loadingIcon: {
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
    },
    selectedDropDownLabel: {
      backgroundColor: "#fffffff",
      borderColor: "#fffffff",
    },
  }));

  const onFieldChange = (event) => {
      setAccountId(event.target.value)
    
  };
  // const onFieldExit = () => {
  //   console.log(props.propertyGLCode.data.propertyGLCode)
  //   props.updatePropertyGLCodeLocalState(
  //     props.propertyGLCode.data.propertyGLCode
  //   );
  // };



  useEffect(() => {}, []);

  const dispatch = useDispatch();

  const classes = useStyles();

  const [accountId, setAccountId] = useState();

  const save = async () => {

      let update = {
        glCode: accountId,
        propertyId:  props.propertyGLCode.data.propertyGLCode.propertyId,  
        uuid:  props.propertyGLCode.data.propertyGLCode.uuid
      }

     await props.updatePropertyGLCode(update)
     await props.getPropertyGLCode(update.propertyId)
     props.onUpdate();
  };

  if (
    props.owners.isLoading ||
    props.vendors.isLoading ||
    props.propertyGLCode.propertyGLCodeIsLoading ||
    !props.propertyGLCode.data.propertyGLCode
  ) {
    return (
      <Card mb={6}>
        <CardContent>
          <Typography variant="h3" gutterBottom>
            Loading...
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardContent>
        <Grid container spacing={4}>
          <Grid item sm={4} xs={6}>
            <TextField
              variant="outlined"
              className={classes.margin}
              defaultValue={
                props.propertyGLCode.data.propertyGLCode.glCode
              }
              onChange={onFieldChange}
              name="vendorAcctNum"
              fullWidth
              id="input-with-icon-textfield"
              label="Accounting ID"
            />
          </Grid>
          <Grid container item spacing={2} justify={"flex-end"}>
            <Grid item>
              <MuiButton variant="contained" onClick={save} color="secondary">
                Save
              </MuiButton>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

// Component Properties
PropertyGLCodeForm.propTypes = {
  propertyGLCode: PropTypes.object.isRequired,
  owners: PropTypes.object.isRequired,
  vendors: PropTypes.object.isRequired,
  getPropertyByUuid: PropTypes.func.isRequired,
  updatePropertyGLCode: PropTypes.func.isRequired,
  updatePropertyGLCodeLocalState: PropTypes.func.isRequired,
  getPropertyGLCode: PropTypes.func.isRequired,
  property: PropTypes.object.isRequired
};

function PropertyGLCodeFormState(state) {
  return {
    propertyGLCode: state.propertyGLCode,
    owners: state.owners,
    vendors: state.vendors,
    user: state.user,
    properties: state.properties,
    propertyGLCode: state.propertyGLCode
  };
}

export default connect(PropertyGLCodeFormState, {
  getPropertyGLCode,
  getPropertyByUuid,
  updatePropertyGLCode,
  updatePropertyGLCodeLocalState,
})(PropertyGLCodeForm);
