import {
  Card as MuiCard,
  CardContent,
  Slide,
  TextField as MuiTextField,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
// Redux Components
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import { EditableTable } from "../../../components/EditableTable";
import StatusTag from "../../apl/components/StatusTag";
import { getRecentlyVisitedAccounts } from "../api/actions";

const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function RecentAccountsTable(props) {
  useEffect(() => {
    let user = JSON.parse(window.localStorage.getItem("user"));
    if (user && user.visitedAccounts && user.visitedAccounts.length > 0) {
      props.getRecentlyVisitedAccounts(user.visitedAccounts);
    }
  }, []);

  // Table Stuff:
  // Table Stuff:
  const fieldTitles = [
    "Acct #",
    "Type",
    "Name",
    "Address",
    "Balance",
    "Property",
  ];

  const fieldTypes = [
    "label",
    "label",
    "label",
    ["labelSmall", "labelSmall"],
    "moneyLabel",
    "label",
    "label",
  ];

  const fieldNames = [
    "accountNum",
    "customerType",
    "name",
    ["serviceAddress1", "serviceAddress2"],
    "balance",
    "propertyName",
  ];

  const AccountRowStatusStyling = (account) => {
    account.name = account.lastName;
    if (account.firstName != null) {
      account.name = account.firstName + ", " + account.lastName;
    }
    account.accountStatus = (
      <StatusTag
        type={"accountStatus"}
        isLoading={props.accountsSearch.isLoading}
        typeId={account.accountStatusId}
        showTitle={false}
        condensed={true}
      />
    );
    account.customerType = (
      <StatusTag
        type={"customerType"}
        isLoading={props.accountsSearch.isLoading}
        typeId={account.customerTypeId}
        showTitle={false}
        condensed={true}
      />
    );
    return {}; // Returns an empty style as to not effect each row
  };

  // Should open to customer page
  const onSelected = (item) => {
    window.open("/accountmanager/" + item.accountNum);
  };

  const sortedByVisited = [...props.accountsSearch.accountHistoryPreviews.items].sort((a, b) => {
    const indexA = props.user?.details?.visitedAccounts.indexOf(a.accountNum.toString());
    const indexB = props.user?.details?.visitedAccounts.indexOf(b.accountNum.toString());
    return indexA - indexB;
  });

  return (
    <Card mb={6} elevation={3}>
      <CardContent>
        <EditableTable
          readOnly={true}
          canAddItems={false}
          canSave={false}
          isLoading={props.accountsSearch.isLoading}
          conditionalStylingMethod={AccountRowStatusStyling}
          handleRowClickParentMethod={onSelected}
          tableTitle={"Recent Accounts"}
          objectArray={{ ...props.accountsSearch.accountHistoryPreviews, items: sortedByVisited }}
          fieldTitles={fieldTitles}
          fieldTypes={fieldTypes}
          fieldNames={fieldNames}
        />
      </CardContent>
    </Card>
  );
}

RecentAccountsTable.propTypes = {
  user: PropTypes.object.isRequired,
  accountsSearch: PropTypes.object.isRequired,
  getRecentlyVisitedAccounts: PropTypes.func.isRequired,
};

function RecentAccountsTableState(state) {
  return {
    user: state.user,
    accountsSearch: state.accountsSearch,
  };
}

export default connect(RecentAccountsTableState, {
  getRecentlyVisitedAccounts,
})(RecentAccountsTable);
