import {
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
// components
import PaymentsTable from "./PaymentsTable";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

export default ({ paymentsInfo, paymentsInfoLoading }) => {
  const classes = useStyles();

  let [organizeType, setOrganizeType] = useState("List"); // 'List', 'GLCode', 'PropertyCode'

  return (
    <div className={classes.root}>
      <Card>
        <CardContent>
          <Grid container item direction="row" xs={12}>
            <Grid container item lg={6} md={6} xs={12}>
              <Typography variant="h6" mb={4}>
                Today's Payments
              </Typography>
            </Grid>
            <Grid container item lg={6} md={6} xs={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="organize"
                  name="organize"
                  value={organizeType}
                  onChange={(e) => setOrganizeType(e.target.value)}
                >
                  <FormControlLabel
                    value="List"
                    control={<Radio />}
                    label="All"
                  />
                  <FormControlLabel
                    value="GLCode"
                    control={<Radio />}
                    label="GLCode"
                  />
                  <FormControlLabel
                    value="PropertyCode"
                    control={<Radio />}
                    label="PropertyCode"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <div className={classes.root}>
            {paymentsInfoLoading == true ? (
              <div style={{ position: "relative" }}>
                {" "}
                <CircularProgress
                  className={classes.circularIcon}
                  size={20}
                  color="inherit"
                />
              </div>
            ) : (
              <PaymentsTable
                paymentsInfo={paymentsInfo}
                organizeType={organizeType}
              />
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};
