import {
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  TextField as MuiTextField,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { PrintRounded } from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import axios from "axios";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";

const Select = styled(MuiSelect)(spacing);
const TextField = styled(MuiTextField)(spacing);

const getPropertyNameToUuid = (properties, uuid) => {
  let ind = properties.findIndex((elem) => elem.uuid == uuid);
  return properties[ind].name;
};

const getPropertyIdToUuid = (properties, uuid) => {
  let ind = properties.findIndex((elem) => elem.uuid == uuid);
  return properties[ind].id;
};

export default (props) => {
  const [useServiceAddress, setUseServiceAddress] = React.useState(true);
  const editData = props.editData;
  const onFieldChange = props.onFieldChange;
  const moveIn = props.moveIn;
  const action = props.action;
  const handleClose = props.handleClose;
  const properties = props.properties.data.propertiesList.items;
  const onFieldCheckboxChange = props.onFieldCheckboxChange;

  let readTypeCheckedInitialState = "";

  if (moveIn.readAmtType == "EST") readTypeCheckedInitialState = "EST";
  if (moveIn.readAmtType == "ACT") readTypeCheckedInitialState = "ACT";

  const [units, setUnits] = useState([]);
  const [areUnitsLoading, setAreUnitsLoading] = useState(false);

  const [readTypeChecked, setReadTypeChecked] = useState(
    readTypeCheckedInitialState
  );

  const handleUseServiceAddressChange = (event) => {
    onFieldCheckboxChange(event);
    if (useServiceAddress) {
      setUseServiceAddress(false);
    }
    else {
      setUseServiceAddress(true);
    }
  };

  const handleReadTypeChange = (type) => {
    if (readTypeChecked == type) setReadTypeChecked("");
    else setReadTypeChecked(type);
  };

  const handlePropertyChange = (uuid) => {
    if (properties && properties.length) {
      const propertyId = getPropertyIdToUuid(properties, uuid);
      setAreUnitsLoading(true);

      axios
        .get(`/unit/property/${propertyId}`)
        .then((res) => {
          setUnits(res.data);
          setAreUnitsLoading(false);
        })
        .catch(console.log);
    }
  };

  const fetchInitialUnits = () => {
    if (!isEmpty(moveIn) && moveIn.propertyUuid)
      handlePropertyChange(moveIn.propertyUuid);
  };

  const isUsingServiceAddress = () => {
    if (moveIn.serviceAddress == moveIn.address1) {
      return true;
    }
    else {
      return false;
    }
  };

  useEffect(() => {
    if (!isUsingServiceAddress()) {
      setUseServiceAddress(false);
    }
    fetchInitialUnits();
  }, []);

  //console.log("move in: ", moveIn);
  //console.log("useEffect move in edit form");
  //console.log(moveIn);
  return (
    <div>
      {isEmpty(moveIn) ? (
        <div>Loading</div>
      ) : (
        <Card>
          <CardContent>
            {/* PropertyManager Details */}
            <Grid container spacing={2}>
              <h3>Contact Info:</h3>
              <Grid container item spacing={2}>
                <Grid item sm={6} xs={12}>
                  <TextField
                    label="First Name"
                    type="text"
                    onChange={onFieldChange}
                    name="firstName"
                    defaultValue={moveIn.firstName}
                    //required
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    label="Last Name"
                    type="text"
                    onChange={onFieldChange}
                    name="lastName"
                    defaultValue={moveIn.lastName}
                    //required
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                {/*<Grid item sm={3} xs={6}>
                  <TextField
                    label="Last 4 SSN"
                    type="text"
                    onChange={onFieldChange}
                    name="last4SSN"
                    defaultValue={moveIn.last4SSN}
                    //required
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  </Grid>*/}
                <Grid item sm={3} xs={6}>
                  <TextField
                    label="SSN"
                    type="password"
                    onChange={onFieldChange}
                    name="ssn"
                    defaultValue={moveIn.ssn}
                    //required
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    label="Email"
                    type="text"
                    onChange={onFieldChange}
                    name="email"
                    defaultValue={moveIn.email}
                    //required
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  label="Phone 1"
                  type="text"
                  onChange={onFieldChange}
                  name="phone1"
                  defaultValue={moveIn.phone1}
                  //required
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  label="Phone 2"
                  type="text"
                  onChange={onFieldChange}
                  name="phone2"
                  defaultValue={moveIn.phone2}
                  //required
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item sm={12} xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={moveIn.eBills ? true : false}
                      onChange={onFieldCheckboxChange}
                      name="eBills"
                      value="E-Bills"
                    />
                  }
                  label="E-Bills"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={moveIn.printedBills ? true : false}
                      onChange={onFieldCheckboxChange}
                      name="printedBills"
                      value="Print Bills"
                    />
                  }
                  label="Print Bills"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={moveIn.userAccountExists ? true : false}
                      onChange={onFieldCheckboxChange}
                      name="userAccountExists"
                      value="User Exists"
                    />
                  }
                  label="User Exists"
                />
              </Grid>

              <h3>Unit Info:</h3>
              <Grid container item spacing={2}>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="property">Property</InputLabel>
                    <Select
                      labelId="property"
                      id="propertyUuid"
                      onChange={onFieldChange}
                      defaultValue={moveIn.propertyUuid}
                      name="propertyUuid"
                    >
                      {properties.length > 0 ?
                        [
                          <MenuItem value={""}>All</MenuItem>,
                          properties.map((item, key) => {
                            return (
                              <MenuItem value={item.uuid} id={key}>{item.name}</MenuItem>
                            )
                          })
                        ]
                        :
                        [
                          <MenuItem value={-1}>No Properties Found</MenuItem>
                        ]
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="unit">Unit</InputLabel>

                    {units && units.length ? (
                      <Select
                        labelId="unit"
                        id="unitUuid"
                        onChange={onFieldChange}
                        defaultValue={moveIn.unitUuid}
                        name="unitUuid"
                      >
                        {units &&
                          units.length &&
                          units.map((item, index) => {
                            let address2 = !item.address2 ? "" : item.address2;
                            return (
                              <MenuItem value={item.uuid} key={index}>
                                {item.address1 + address2}
                              </MenuItem>
                            );
                          })}
                        ]
                      </Select>
                    ) : (
                      <div></div>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <br />
              <h3>Address Info:</h3>
              {useServiceAddress ?
                <Grid container xs={12} spacing={1}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={useServiceAddress}
                          onChange={handleUseServiceAddressChange}
                          name="serviceAddressSame"
                        />
                      }
                      label="Use service address as contact and billing info"
                    />
                  </Grid>
                </Grid>
                :
                <>
                  <Grid container xs={12} spacing={1}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={useServiceAddress}
                            onChange={handleUseServiceAddressChange}
                            name="serviceAddressSame"
                          />
                        }
                        label="Use service address as contact and billing info"
                      />
                    </Grid>
                  </Grid>
                  <Grid container item spacing={2}>
                    <h5>Mailing Address:</h5>
                    <Grid item xs={12} />
                    <Grid item sm={6} xs={12}>
                      <TextField
                        label="Address 1"
                        type="text"
                        onChange={onFieldChange}
                        name="address1"
                        defaultValue={moveIn.address1}
                        //required
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <TextField
                        label="address 2"
                        type="text"
                        onChange={onFieldChange}
                        name="address2"
                        defaultValue={moveIn.address2}
                        //required
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item spacing={2}>
                    <Grid item sm={6} xs={12}>
                      <TextField
                        label="City"
                        type="text"
                        onChange={onFieldChange}
                        name="city"
                        defaultValue={moveIn.city}
                        //required
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item sm={2} xs={6}>
                      <TextField
                        label="State"
                        type="text"
                        onChange={onFieldChange}
                        name="state"
                        defaultValue={moveIn.state}
                        //required
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item sm={4} xs={6}>
                      <TextField
                        label="Zip"
                        type="text"
                        onChange={onFieldChange}
                        name="zip"
                        defaultValue={moveIn.zip}
                        //required
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  </Grid>

                  <h5>Billing Address:</h5>
                  <Grid container item spacing={2}>
                    <Grid item sm={6} xs={12}>
                      <TextField
                        label="Billing Address 1"
                        type="text"
                        onChange={onFieldChange}
                        name="billingAddress1"
                        defaultValue={moveIn.billingAddress1}
                        //required
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <TextField
                        label="Billing Address 2"
                        type="text"
                        onChange={onFieldChange}
                        name="billingAddress2"
                        defaultValue={moveIn.billingAddress2}
                        //required
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item spacing={2}>
                    <Grid item sm={6} xs={12}>
                      <TextField
                        label="Billing City"
                        type="text"
                        onChange={onFieldChange}
                        name="billingCity"
                        defaultValue={moveIn.billingCity}
                        //required
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item sm={2} xs={6}>
                      <TextField
                        label="Billing State"
                        type="text"
                        onChange={onFieldChange}
                        name="billingState"
                        defaultValue={moveIn.billingState}
                        //required
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item sm={4} xs={6}>
                      <TextField
                        label="Billing Zip"
                        type="text"
                        onChange={onFieldChange}
                        name="billingZip"
                        defaultValue={moveIn.billingZip}
                        //required
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                </>
              }
              <h3>Move Info:</h3>
              <Grid container item spacing={2}>
                <Grid item sm={6} xs={12}>
                  <TextField
                    label="Move In Date"
                    type="date"
                    onChange={onFieldChange}
                    name="moveInDate"
                    defaultValue={moment.utc(moveIn.moveInDate).format(
                      "YYYY-MM-DD"
                    )}
                    //required
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item sm={3} xs={6}>
                  <TextField
                    label="Read Amt"
                    type="text"
                    onChange={onFieldChange}
                    name="meterRead"
                    defaultValue={moveIn.meterRead}
                    //required
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  {moveIn.meterRead != 0 ?
                    <>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={readTypeChecked == "ACT" ? true : false}
                            onChange={(e) => {
                              handleReadTypeChange("ACT");
                              onFieldChange(e);
                            }}
                            name="readAmtType"
                            value="ACT"
                          />
                        }
                        label="ACT Read"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={readTypeChecked == "EST" ? true : false}
                            onChange={(e) => {
                              handleReadTypeChange("EST");
                              onFieldChange(e);
                            }}
                            name="readAmtType"
                            value="EST"
                          />
                        }
                        label="EST Read"
                      />
                    </>
                    :
                    <>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={readTypeChecked == "ACT" ? true : false}
                            onChange={(e) => {
                              handleReadTypeChange("ACT");
                              onFieldChange(e);
                            }}
                            disabled={true}
                            name="readAmtType"
                            value="ACT"
                          />
                        }
                        label="ACT Read"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={readTypeChecked == "EST" ? true : false}
                            disabled={true}
                            onChange={(e) => {
                              handleReadTypeChange("EST");
                              onFieldChange(e);
                            }}
                            name="readAmtType"
                            value="EST"
                          />
                        }
                        label="EST Read"
                      />
                    </>
                  }
                </Grid>
              </Grid>

              <Grid container item spacing={2} justify={"flex-end"}>
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      action();
                      handleClose();
                    }}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
    </div>
  );
};
