import React, { useEffect } from "react";
import styled, { withTheme } from "styled-components/macro";
import { spacing } from "@material-ui/system";
import {
  CircularProgress as MuiCircularProgress,
  Paper,
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card,
  CardContent,
  Chip,
  Divider as MuiDivider,
  Grid,
  LinearProgress as MuiLinearProgress,
  Link,
  Table,
  Checkbox,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Switch,
} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Skeleton from "@material-ui/lab/Skeleton";
import EditIcon from "@material-ui/icons/Edit";
import style from "./styling/style";
import PropertyForm from "./Form";
import { dateFormatter, formatPhoneNumber } from "../../../setup/helpers";
import ContactMailIcon from "@material-ui/icons/ContactMail";

const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function DetailCard(props) {
  const useStyles = style;
  const classes = useStyles();

  const [display, setDisplay] = React.useState(false);

  const toggleDisplay = () => {
    const shouldDisplay = !display;
    setDisplay(shouldDisplay);
  };

  //console.log(props)
  //if (!props.propertyManagers.data.propertyManager ) return <div></div>

  return (
    <Card mb={6}>
      <CardContent xs={12}>
        <Grid container xs={12}>
          <Grid item xs={9} sm={9} md={9} lg={9}>
            <Typography variant="h6" gutterBottom>
              Property Manager
            </Typography>
          </Grid>
          <Grid
            item
            xs={3}
            sm={3}
            md={3}
            lg={3}
            className={classes.rightSideHeaderSubMenuToolBar}
          >
            {props.edit &&
              <Button onClick={toggleDisplay}>
                <EditIcon
                  fontSize="medium"
                  className={classes.SubMenuToolBarIcon}
                />
              </Button>}
            <Dialog
              open={display}
              TransitionComponent={Transition}
              fullWidth={"lg"}
              maxWidth={"lg"}
              keepMounted
              onClose={toggleDisplay}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              {props.propertyManagers.data.propertyManager ? (
                <DialogTitle id="alert-dialog-slide-title">
                  Property Manager{" "}
                  {props.propertyManagers.data.propertyManager.name}{" "}
                </DialogTitle>
              ) : (
                <DialogTitle id="alert-dialog-slide-title">
                  Loading Manager Info.....{" "}
                </DialogTitle>
              )}
              <DialogContent>
                <PropertyForm
                  onUpdate={toggleDisplay}
                  onCancel={toggleDisplay}
                />
              </DialogContent>
            </Dialog>
          </Grid>
        </Grid>
        <Divider className="mb-2 mt-2" my={1} />
        {!props.propertyManagers.data.propertyManager ? (
          <>
            <Skeleton
              variant="rect"
              height="40px"
              style={{
                backgroundColor: `#bdbfbe`,
                padding: 6,
                margin: 6,
                marginBottom: 3,
              }}
            />
            <Skeleton
              variant="rect"
              height="40px"
              style={{
                backgroundColor: `#bdbfbe`,
                padding: 6,
                margin: 6,
                marginBottom: 3,
              }}
            />
            <Skeleton
              variant="rect"
              height="40px"
              style={{
                backgroundColor: `#bdbfbe`,
                padding: 6,
                margin: 6,
                marginBottom: 3,
              }}
            />
            <Skeleton
              variant="rect"
              height="40px"
              style={{
                backgroundColor: `#bdbfbe`,
                padding: 6,
                margin: 6,
                marginBottom: 3,
              }}
            />
          </>
        ) : (
          <Grid
            container
            spacing={2}
            lg={12}
            className={classes.PropertyInfoGrid}
          >
            <Grid item xs={12} lg={12} spacing={2}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1"> Name </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {props.propertyManagers.data.propertyManager.name ||
                            "N/A"}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1"> Phone # </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {formatPhoneNumber(props.propertyManagers.data.propertyManager.phone) ||
                            "N/A"}{" "}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1"> Contact Name </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {props.propertyManagers.data.propertyManager.contactName ||
                            "N/A"}{" "}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1">Email Address </Typography>
                      </TableCell>
                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems:'center', justifyContent: 'space-between'}}>
                        <Typography variant="body2">
                          {props.propertyManagers.data.propertyManager
                            .email}
                        </Typography>
                        {props.propertyManagers.data.propertyManager
                            .email &&
                          <Button onClick={() => { window.open(`mailto:${props.propertyManagers.data.propertyManager.email}?subject=CCOS%20PM%20Account%20Notice%20&body=`) }}>
                            <ContactMailIcon
                              fontSize="small"
                              className={classes.SubMenuToolBarIcon}
                            />
                          </Button>}
                      </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1"> Billing Email Address </Typography>
                      </TableCell>
                      <TableCell>
                          <Box sx={{ display: 'flex', alignItems:'center', justifyContent: 'space-between'}}>
                        <Typography variant="body2">
                          {props.propertyManagers.data.propertyManager
                            .billingEmail}
                        </Typography>
                          <Button onClick={() => { window.open(`mailto:${props.propertyManagers.data.propertyManager.billingEmail}?subject=CCOS%20PM%20Account%20Notice%20&body=`) }}>
                            <ContactMailIcon
                              fontSize="small"
                              className={classes.SubMenuToolBarIcon}
                            />
                          </Button>
                          </Box>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1"> Delinquencies Email Address </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {props.propertyManagers.data.propertyManager
                            .delinquencyEmail || "N/A"}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1"> Address </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {props.propertyManagers.data.propertyManager
                            .address1 || "N/A"}{" "}
                          <br />
                          {props.propertyManagers.data.propertyManager
                            .address2 || "N/A"}{" "}
                          <br />
                          {props.propertyManagers.data.propertyManager.city ||
                            "N/A"}{" "}
                          {props.propertyManagers.data.propertyManager.state ||
                            "N/A"}
                          {", "}
                          {props.propertyManagers.data.propertyManager.zip ||
                            "N/A"}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1"> Status </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {props.propertyManagers.data.propertyManager
                            .pmStatus || "N/A"}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1"> Stop Setup Fee </Typography>
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          checked={
                            props.propertyManagers.data.propertyManager.stopSetupFee == 1
                              ? true
                              : false
                          }
                          disabled={true}
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1"> Stop Late Fee </Typography>
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          checked={
                            props.propertyManagers.data.propertyManager.stopLateFee == 1
                              ? true
                              : false
                          }
                          disabled={true}
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1"> Notes </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {props.propertyManagers.data.propertyManager
                            .notes || "N/A"}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1"> Updated </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {new Date(
                            props.propertyManagers.data.propertyManager.updated
                          ).toLocaleDateString() || "N/A"}
                        </Typography>
                      </TableCell>
                    </TableRow>

                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
}

DetailCard.propTypes = {
  apl: PropTypes.object.isRequired,
  propertyManagers: PropTypes.object.isRequired,
};

function DetailCardState(state) {
  return {
    apl: state.apl,
    propertyManagers: state.propertyManagers,
  };
}

export default connect(DetailCardState, {})(DetailCard);
