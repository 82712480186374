import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import moment from "moment";
import React, { useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

export default ({
  acumaticaDate,
  setAcumaticaDate,
  utilityValue,
  setUtilityValue,
  acumaticaConnected,
  dispatch,
  setSnackbar,
  batchesRan,
  setBatchesRan,
}) => {
  const classes = useStyles();

  const [batchLoading, setBatchLoading] = useState(false);

  const runBatch = (batchType) => {
    setBatchLoading(true);
    let token = localStorage.getItem("token");
    let headers = {
      "x-access-token": token,
    };

    axios
      .post(
        "/run-acumatica-batch",
        { date: acumaticaDate, batchType, utility: utilityValue },
        { headers }
      )
      .then((res) => {
        dispatch(setSnackbar(true, "success", "Successfully posted batch."));
        setBatchLoading(false);
        let newBatchRan = [
          `${acumaticaDate} - Utility: ${
            utilityValue == 1 ? "CCOS" : "CCOS"
          } Type: ${batchType} @${moment().format("HH:MM:SS")}`,
        ];
        setBatchesRan(batchesRan.concat(newBatchRan));
      })
      .catch((err) => {
        dispatch(setSnackbar(true, "error", "Failed to post batch."));
        setBatchLoading(false);
      });
  };
  return (
    <div className={classes.root}>
      <Card>
        <CardContent>
          <Grid container item direction="row" xs={12}>
            <Grid container item xs={6}>
              <Typography variant="h6" mb={4}>
                Acumatica Actions
              </Typography>
              <TextField
                id="date"
                label="Batch Date"
                type="date"
                defaultValue={acumaticaDate}
                onChange={(e) => setAcumaticaDate(e.target.value)}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid container item xs={6}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Utility</FormLabel>
                <RadioGroup
                  aria-label="utility"
                  name="utility"
                  value={utilityValue}
                  onChange={(e) => setUtilityValue(e.target.value)}
                >
                  <FormControlLabel value="1" control={<Radio />} label="CCOS" />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="CCOS"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>

          <div className={classes.root}>
            <Button
              disabled={acumaticaDate == "" || batchLoading == true}
              onClick={() => runBatch("Bills")}
              size="small"
              variant="contained"
              color="primary"
            >
              Bills
            </Button>
            <Button
              disabled={acumaticaDate == "" || batchLoading == true}
              onClick={() => runBatch("Payments")}
              size="small"
              variant="contained"
              color="secondary"
            >
              Receipts
            </Button>
            <Button
              disabled={acumaticaDate == "" || batchLoading == true}
              onClick={() => runBatch("Credits")}
              size="small"
              variant="contained"
              color="warning"
            >
              Credits
            </Button>
            <Button
              disabled={acumaticaDate == "" || batchLoading == true}
              onClick={() => runBatch("Accrual")}
              size="small"
              variant="contained"
              color="info"
            >
              Accrual
            </Button>
            <div>
              {batchesRan.length > 0 ? (
                <p>
                  Please allow 10 minutes for batches to be posted to Acumatica.
                </p>
              ) : null}
              {batchesRan.map((batchText, index) => {
                return (
                  <p key={index}>
                    {index + 1}. {batchText}
                  </p>
                );
              })}
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};
