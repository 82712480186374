import { Button as MuiButton, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
// Redux Components
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import { handleBillingProcessSubmission } from "../../modules/billing/api/actions";

const Button = styled(MuiButton)(spacing);

const DashboardToolBarButton = styled(Button)`
  padding: 4px;
  min-width: 0;
  svg {
    width: 2em;
    height: 2em;
  }
`;

function ToolBar(props) {
  const useStyles = makeStyles((theme) => {
    return {
      dropdown: {
        width: "14rem",
      },
      modalcontainer: {
        width: "60%",
        height: "60%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "15px",
        padding: "3rem",
      },
      link: {
        textDecoration: "none",
        color: theme.palette.secondary.main,
        "&:hover": {
          fontWeight: "bold",
          textDecoration: "underline",
        },
      },

      rightSideHeader: {
        display: "flex",
        alignItems: "flex-end",
        flexDirection: "row",
        justifyContent: "flex-end",
      },
      searchLabel: {
        fontWeight: "bolder",
        fontSize: "110%",
        backgroundColor: "#f5f5f5",
      },

      leftSideHeader: {
        display: "flex",
        justifyContent: "left",
        alignItems: "left",
      },
      margin: {
        margin: theme.spacing(1),
      },
      selectButtonMargin: {
        margin: theme.spacing(1),
        width: "25%",
        fontWeight: "bolder",
        fontSize: "120%",
      },
      withoutLabel: {
        marginTop: theme.spacing(3),
      },
      textField: {
        width: "25ch",
      },
      searchSelectionText: {
        fontWeight: "bolder",
        fontSize: "220%",
      },
    };
  });
  const classes = useStyles();

  const handleSaveClick = () => {
    props.handleBillingProcessSubmission(props.billing.data.billInProgress);
  };

  const handleDeleteClick = () => {
    if (props.deleteDraft) {
      props.deleteDraft();
    }
  };

  const handleTrialBillItemsRegeneration = () => {
    if (props.regenerateTrialBillItems) {
      props.regenerateTrialBillItems();
    }
  };

  const handleRefresh = () => {
    if (props.refreshPage) {
      props.refreshPage();
    }
  };

  return (
    <Grid item lg={12} className={classes.rightSideHeader}>
      {/*<Button
        mr={2}
        variant="contained"
        color="secondary"
        onClick={handleSaveClick}
      >
        Save Progress
      </Button>*/}
      {props.billing.data.billInProgress.currentStep > 2 && props.tableType !== 'BillingInformation' && (
        <Button
          variant="contained"
          onClick={handleTrialBillItemsRegeneration}
          mr={2}
        >
          Recalculate Trial Billing Items
        </Button>
      )}
      <Button variant="contained" color="primary" onClick={handleDeleteClick}>
        Delete Draft
      </Button>
    </Grid>
  );
}

// Component Properties
ToolBar.propTypes = {
  // Store objects:
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  billing: PropTypes.object.isRequired,
  // Store object functions:
  handleBillingProcessSubmission: PropTypes.func.isRequired,
};
// Component State
function ToolBarState(state) {
  return {
    user: state.user,
    apl: state.apl,
    billing: state.billing,
  };
}
export default connect(ToolBarState, { handleBillingProcessSubmission })(
  ToolBar
);
