import {
  Box,
  Button as MuiButton,
  Card,
  CardContent,
  Divider as MuiDivider,
  Grid,
  LinearProgress as MuiLinearProgress,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import { spacing } from "@material-ui/system";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Clipboard, DollarSign, Lock, ShoppingCart } from "react-feather";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { get as getDevelopers } from "../../modules/developer/api/actions";
import styled from "styled-components/macro";
import StandardHeader from "../../components/global/StandardHeader";
import { LeftSidebar as NavFrame } from "layout-blueprints";
import { getAllPropertyPreviews } from "../../modules/apl/api/actions";
import StatusTag from "../../modules/apl/components/StatusTag";
import { getUsersByAccount, setSelectedUserProfile } from "../../modules/userProfiles/api/actions";
import { searchForDelinquencyGroupsByFilters } from "../../modules/delinquencies/api/actions";
import { get as getOwners } from "../../modules/owner/api/actions";
import {
  getByUuid as getPropertyManagerByUuid,
  getPmAccountSummary,
  getPmActiveBalanceSummary,
} from "../../modules/propertyManager/api/actions";
import { getBillingPdfs } from "../../modules/billing/api/actions";
import PropertyManagerDetailCard from "../../modules/propertyManager/components/DetailCard";
import { getAllPMStatusTypes as getPropertyManagerStatuses } from "../../modules/apl/api/actions";
import { get as getUnitTypes } from "../../modules/unitType/api/actions";
import UserDetailSummaryCard from "../../modules/userProfiles/components/UserDetailSummaryCard";
import { formatMoney } from "../../setup/helpers";
import PropertyManagerDashboardTabedTables from "./PropertyManagerTabedTables";
import ToolBar from "./ToolBar";

const LinearProgress = MuiLinearProgress;
const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);

const IndividualPropertyManagerPage = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const useStyles = makeStyles((theme) => ({
    title: {
      flexGrow: 1,
    },
    percentDif: {
      textAlign: "right",
      color: "green",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    loadingIcon: {
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
    },
    StatsIcon: {
      width: "32px",
      height: "32px",
      color: theme.palette.secondary.main,
      position: "absolute",
      right: "16px",
      top: "32px",
    },
    rightSideHeader: {
      display: "flex",
      alignItems: "flex-end",
      flexDirection: "row",
      justifyContent: "flex-end",
    },
    rightSideHeaderSubMenuToolBar: {
      display: "flex",
      alignItems: "flex-end",
      flexDirection: "row",
      justifyContent: "flex-end",
      margin: "0px",
      padding: "0px",
    },
    SubMenuToolBarIcon: {
      width: "25px",
      height: "25px",
      paddingBotom: "5px",
    },
    orange: {
      //color: theme.palette.getContrastText(deepOrange[500]),
    },
    UserBoxText: {
      textAlign: "center",
    },
    UserProfileDetailsButton: {
      padding: "5px",
    },
    AvatarText: {
      marginTop: "20%",
      height: "128px",
      width: "128px",
      textAlign: "center",
      alignSelf: "center",
    },
    AvatarIcon: {
      display: "inline-block",
      height: "128px",
      width: "128px",
      backgroundColor: "gray",
      borderColor: theme.palette.primary.main,
      borderStyle: "solid",
    },
    ChartWrapper: {
      height: "200px",
      maxHeight: "200px",
      minHeight: "200px",
    },
    RowHeight: {
      display: "inline-block",
      height: "50%",
      maxHeight: "50%",
      minHeight: "50%",
    },
  }));

  const classes = useStyles();
  const refreshPage = () => {
    props.getAllPropertyPreviews();
    props.getPropertyManagerByUuid(props.match.params.uuid);
    props.getPmAccountSummary(props.match.params.uuid);
    props.getPmActiveBalanceSummary(props.match.params.uuid);
    props.getPropertyManagerStatuses();
    props.getDevelopers();
    props.searchForDelinquencyGroupsByFilters({
      page: 0,
      rowsPerPage: 0,
      pmUuid: props.match.params.uuid,
      isBaseGroup: true,
      resetCache: true,
      isLoading: false,
    });
  };

  useEffect(() => {
    refreshPage();
  }, []);

  const CurrentBalance = () => {
    // Loading bar should represent last payment val/ current balance
    return (
      <Box position="relative">
        <Card mb={6} pt={2}>
          <CardContent>
            {props.propertyManagers.propertyManagerBalanceSummaryIsLoading ? (
              <Skeleton
                variant="rect"
                height="60px"
                style={{ backgroundColor: `#bdbfbe`, padding: 6, margin: 6 }}
              />
            ) : (
              <>
                <Typography variant="h4" gutterBottom>
                  {props.propertyManagers.propertyManagerBalanceSummary !=
                    null ? (
                    <Box fontWeight="fontWeightRegular">
                      {formatMoney(
                        props.propertyManagers.propertyManagerBalanceSummary
                          .combinedBalance
                      )}
                    </Box>
                  ) : (
                    <Box fontWeight="fontWeightRegular">N/A</Box>
                  )}
                </Typography>
                <Typography variant="body2" gutterBottom mt={3} mb={0}>
                  Current Balance
                </Typography>
                <DollarSign className={classes.StatsIcon} />
                <LinearProgress
                  variant="determinate"
                  value={50}
                  color="secondary"
                  mt={4}
                />
              </>
            )}
          </CardContent>
        </Card>
      </Box>
    );
  };

  const VacantUnits = () => {
    // Loading bar should represent last payment val/ current balance
    return (
      <Box position="relative">
        <Card mb={6} pt={2}>
          <CardContent>
            {props.propertyManagers.propertyManagerAccountSummaryIsLoading ? (
              <Skeleton
                variant="rect"
                height="60px"
                style={{ backgroundColor: `#bdbfbe`, padding: 6, margin: 6 }}
              />
            ) : (
              <>
                <Typography variant="h4" gutterBottom>
                  {props.propertyManagers.propertyManagerAccountSummary !=
                    null ? (
                    <Box fontWeight="fontWeightRegular">
                      {
                        props.propertyManagers.propertyManagerAccountSummary
                          .activeAccounts
                      }
                    </Box>
                  ) : (
                    <Box fontWeight="fontWeightRegular">N/A</Box>
                  )}
                </Typography>
                <Typography variant="body2" gutterBottom mt={3} mb={0}>
                  {props.propertyManagers.propertyManagerAccountSummary !=
                    null ? (
                    <Box fontWeight="fontWeightRegular">
                      Units (
                      {!props.propertyManagers
                        .propertyManagerBalanceSummaryIsLoading &&
                        props.propertyManagers.propertyManagerBalanceSummary !=
                        null &&
                        ` ${props.propertyManagers.propertyManagerBalanceSummary
                          .managementCoAccounts -
                        props.propertyManagers.propertyManagerBalanceSummary
                          .unitCommonAreaAccounts
                        } Vacant `}
                      {props.delinquencies.initialDelinquencyGroupsSummary !=
                        null &&
                        `, ${props.delinquencies.initialDelinquencyGroupsSummary.unresolvedDelinquincies} Delinq. `}
                      {!props.propertyManagers
                        .propertyManagerBalanceSummaryIsLoading &&
                        props.propertyManagers.propertyManagerBalanceSummary !=
                        null &&
                        `, ${props.propertyManagers.propertyManagerBalanceSummary.unitCommonAreaAccounts} Common `}
                      )
                    </Box>
                  ) : (
                    <Box fontWeight="fontWeightRegular">Units</Box>
                  )}
                </Typography>
                <Clipboard className={classes.StatsIcon} />
                {props.propertyManagers.propertyManagerAccountSummary != null &&
                  props.propertyManagers.propertyManagerBalanceSummary != null ? (
                  <LinearProgress
                    variant="determinate"
                    value={
                      ((props.propertyManagers.propertyManagerAccountSummary
                        .activeAccounts -
                        props.propertyManagers.propertyManagerBalanceSummary
                          .activeAccounts) /
                        props.propertyManagers.propertyManagerAccountSummary
                          .activeAccounts) *
                      100
                    }
                    color="secondary"
                    mt={4}
                  />
                ) : (
                  <LinearProgress
                    variant="determinate"
                    value={50}
                    color="secondary"
                    mt={4}
                  />
                )}
              </>
            )}
          </CardContent>
        </Card>
      </Box>
    );
  };

  const AmmountPastDue = () => {
    // Loading bar should represent last payment val/ current balance
    return (
      <Box position="relative">
        <Card mb={6} pt={2}>
          <CardContent>
            {props.propertyManagers.propertyManagerBalanceSummaryIsLoading ? (
              <Skeleton
                variant="rect"
                height="60px"
                style={{ backgroundColor: `#bdbfbe`, padding: 6, margin: 6 }}
              />
            ) : (
              <>
                <Typography variant="h4" gutterBottom>
                  {props.propertyManagers.propertyManagerBalanceSummary !=
                    null ? (
                    <Box fontWeight="fontWeightRegular">
                      {formatMoney(
                        props.propertyManagers.propertyManagerBalanceSummary
                          .combinedPastDue
                      )}
                    </Box>
                  ) : (
                    <Box fontWeight="fontWeightRegular">N/A</Box>
                  )}
                </Typography>
                <Typography variant="body2" gutterBottom mt={3} mb={0}>
                  Amount Past Due
                </Typography>
                <ShoppingCart className={classes.StatsIcon} />
                {props.propertyManagers.propertyManagerBalanceSummary !=
                  null ? (
                  <LinearProgress
                    variant="determinate"
                    value={
                      (props.propertyManagers.propertyManagerBalanceSummary
                        .combinedPastDue /
                        props.propertyManagers.propertyManagerBalanceSummary
                          .combinedBalance) *
                      100
                    }
                    color="secondary"
                    mt={4}
                  />
                ) : (
                  <LinearProgress
                    variant="determinate"
                    value={50}
                    color="secondary"
                    mt={4}
                  />
                )}
              </>
            )}
          </CardContent>
        </Card>
      </Box>
    );
  };

  const UtilityDisconnects = () => {
    // Loading bar should represent last payment val/ current balance
    return (
      <Box position="relative">
        <Card mb={6} pt={2}>
          <CardContent>
            {props.propertyManagers.propertyManagerAccountSummaryIsLoading ? (
              <Skeleton
                variant="rect"
                height="60px"
                style={{ backgroundColor: `#bdbfbe`, padding: 6, margin: 6 }}
              />
            ) : (
              <>
                <Lock className={classes.StatsIcon} />
                {props.propertyManagers.propertyManagerAccountSummary !=
                  null ? (
                  <LinearProgress
                    variant="determinate"
                    value={
                      (props.propertyManagers.propertyManagerAccountSummary
                        .totalDisconnected /
                        props.propertyManagers.propertyManagerAccountSummary
                          .activeAccounts) *
                      100
                    }
                    color="secondary"
                    mt={4}
                  />
                ) : (
                  <LinearProgress
                    variant="determinate"
                    value={50}
                    color="secondary"
                    mt={4}
                  />
                )}
              </>
            )}
          </CardContent>
        </Card>
      </Box>
    );
  };

  var pageTitle = "Ubil+ Loading Property Manager...";
  var tabTitle = "Ubil+ Loading Property Manager...";
  // Sets the tab name based on the loaded account being worked on
  if (props.propertyManagers.data.propertyManager != null) {
    if (props.propertyManagers.data.propertyManager.name) {
      tabTitle = "PM: " + props.propertyManagers.data.propertyManager.name;
      pageTitle =
        "Property Manager: " + props.propertyManagers.data.propertyManager.name;
    } else {
      pageTitle = "Property Manager";
      tabTitle = "Property Manager";
    }
  }
  document.title = tabTitle;

  // Safety to make sure status ids are there before passing to tags (Should be a store function in the future, with accounts.data having default values for each of these)
  var statusId = -1;
  //var customerStatus = -1;
  if (props.propertyManagers.data.propertyManager != null) {
    statusId = props.propertyManagers.data.propertyManager.statusId;
  }
  var currentHeaderTags = 
  props.propertyManagers.data.propertyManager?.developerId != null ?
  [
    <StatusTag
      type={"propertyManagerStatus"}
      isLoading={props.propertyManagers.isLoading}
      typeId={statusId}
    />,
    <StatusTag
      type={"Default"}
      label={"Developer"}
      isLoading={props.propertyManagers.isLoading}
     
    />
    
  ]
  :
  [ <StatusTag
    type={"propertyManagerStatus"}
    isLoading={props.propertyManagers.isLoading}
    typeId={statusId}
  />];
    
    

  return (
    <NavFrame page={"IndividualPropertyManagerPage"}>
      <StandardHeader
        appPath="Properties / Property Manger Administration"
        title={pageTitle}
        headerTags={currentHeaderTags}
        rightSideToolBar={<ToolBar handleRefreshClick={refreshPage} />}
      />
      <Divider className="mb-3 mt-3" />
      <Grid container spacing={2} xs={12} sm={12} md={12} lg={12}>
        <Grid item xs={4}>
          <Grid container spacing={2} xs={12}>
            <Grid item xs={12}>
              <UserDetailSummaryCard
                isLoading={props.propertyManagers.isLoading}
                isPm={true}
                UserProfile={
                  props.propertyManagers.data.propertyManager != null
                    ? props.propertyManagers.data.propertyManager.user
                    : null
                }
              />
            </Grid>
            <Grid item xs={12}>
              <PropertyManagerDetailCard edit={true} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={2} xs={12}>
            <Grid item xs={3} my={2}>
              <CurrentBalance />
            </Grid>
            <Grid item xs={3} my={2}>
              <AmmountPastDue />
            </Grid>
            <Grid item xs={3} my={2}>
              <VacantUnits />
            </Grid>
            <Grid item xs={12}>
              <PropertyManagerDashboardTabedTables
                pmUuid={props.match.params.uuid}
                userProfile={props.propertyManagers.data.propertyManager != null
                  ? props.propertyManagers.data.propertyManager.user
                  : null}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </NavFrame>
  );
};

IndividualPropertyManagerPage.propTypes = {
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  units: PropTypes.object.isRequired,
  delinquencies: PropTypes.object.isRequired,
  propertyManagers: PropTypes.object.isRequired,
  billing: PropTypes.object.isRequired,
  getUnitTypes: PropTypes.func.isRequired,
  getPropertyManagerStatuses: PropTypes.func.isRequired,
  getPropertyManagerByUuid: PropTypes.func.isRequired,
  getAllPropertyPreviews: PropTypes.func.isRequired,
  getPmAccountSummary: PropTypes.func.isRequired,
  getPmActiveBalanceSummary: PropTypes.func.isRequired,
  searchForDelinquencyGroupsByFilters: PropTypes.func.isRequired,
  getBillingPdfs: PropTypes.func.isRequired
};

// Component State
function IndividualPropertyManagerPageState(state) {
  return {
    user: state.user,
    apl: state.apl,
    units: state.units,
    owners: state.owners,
    unitTypes: state.unitTypes,
    propertyManagers: state.propertyManagers,
    delinquencies: state.delinquencies,
    billing: state.billing,
    getDevelopers: PropTypes.func.isRequired,
  };
}

export default connect(IndividualPropertyManagerPageState, {
  getOwners,
  getUnitTypes,
  getPropertyManagerStatuses,
  getPropertyManagerByUuid,
  getAllPropertyPreviews,
  getPmAccountSummary,
  getPmActiveBalanceSummary,
  searchForDelinquencyGroupsByFilters,
  getBillingPdfs,
  getDevelopers,
})(IndividualPropertyManagerPage);
