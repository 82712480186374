import {
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Divider as MuiDivider,
  Grid,
  Paper as MuiPaper,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
// Redux Components
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import {
  EditableTable,
  newDropDownField,
  newOnFieldChangeMethod,
} from "../../../components/EditableTable";
import { updateNewAccountBillInProgress } from "../api/actions";

const Button = styled(MuiButton)(spacing);

const DashboardToolBarButton = styled(Button)`
  padding: 4px;
  min-width: 0;
  svg {
    width: 2em;
    height: 2em;
  }
`;

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Spacer = styled.div`
  flex: 1 1 100%;
`;

//Editable Table Object Expects: {objectArray, fieldTitles(Array), fieldTypes(Array), fieldNames(Array), handleRowClickParentMethod}
// Method can take line items if they exist initially or starts as an empty array of nothing passed
function AdditionalBillLineItemsManager(props) {
  const fieldTitles = ["Description", "Cost Type", "Rate", "Qty.", "Total"];
  const fieldTypes = ["text", "dropDown", "number", "number", "moneyLabel"];
  const fieldNames = ["notes", "itemDesc", "rate", "qty", "totalCharge"]; // Must be the exact name of the properties you wanna show

  // This is how a dropdown is handled: In my instance im importing that data so i need to check if its there yet before assigning it to the dropdown field:
  // Expects: newDropDownField(targetFieldName, values, displayValueName(What property do you want shown in the listbox), selectValueName(what property will selecting an item assign))
  //(targetFieldName, values, displayValueName, selectValueName

  const setItemDesc = (item) => {
    let billDesc = props.apl.genericTypes.billingAccountGLCode.data.filter(glcode => {
      return glcode.uuid === item.itemDesc;
    })
    item.notes = billDesc[0].billDescription
    return item;
  };


  const descArray = props.apl.genericTypes.billingAccountGLCode.data?.filter(el => el.glCode !== '7014-05' && el.glCode !== '7014-17' )
  const resourceFields = [
    newDropDownField(
      "itemDesc",
      descArray,
      "billDescription",
      "uuid",
      setItemDesc
      
    ),
  ];

  const createLineItemData = (id, uuid, itemDesc, acctDesc, itemTotal) => {
    return { id, uuid, itemDesc, acctDesc, itemTotal };
  };

  const createBlankLineItem = () => {
    return createLineItemData(-1, -1, "", "", 0);
  }
  const addBlankItemToArray = () => {
    props.bills.newBill.newBillLineItems.items.push(createBlankLineItem());
    props.updateNewAccountBillInProgress(props.bills.newBill);
  };

  const updateLocalItemsValues = (items) => {
    props.bills.newBill.newBillLineItems = items;
    props.updateNewAccountBillInProgress(props.bills.newBill);
  };

  const calculateLineValues = (item) => {
    console.log("setItem", item)
    item.totalCharge = item.rate * item.qty;
    return item;
  };

  const onChangeFields = [
    newOnFieldChangeMethod("itemDesc", setItemDesc),
    newOnFieldChangeMethod("rate", calculateLineValues),
    newOnFieldChangeMethod("qty", calculateLineValues),
  ];
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} lg={12} md={12}>
        <EditableTable
          canAddItems={true}
          canSave={false}
          // Functional methods of table
          newItemCreationMethod={addBlankItemToArray}
          updateItemsLocalState={updateLocalItemsValues}
          dropDownFields={resourceFields}
          // Properties of table
          tableTitle={"Other Bill Line Items:"}
          objectArray={props.bills.newBill.newBillLineItems}
          fieldTitles={fieldTitles}
          fieldTypes={fieldTypes}
          customOnChangeFields={onChangeFields}
          fieldNames={fieldNames}
          defaultItemCount={5}
        />
      </Grid>
    </Grid>
  );
}

// Component Properties
AdditionalBillLineItemsManager.propTypes = {
  // Store objects:
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  bills: PropTypes.object.isRequired,
  // Store object functions:
  updateNewAccountBillInProgress: PropTypes.func.isRequired,
};

// Component State
function AdditionalBillLineItemsManagerState(state) {
  return {
    user: state.user,
    apl: state.apl,
    bills: state.bills,
  };
}
export default connect(AdditionalBillLineItemsManagerState, {
  updateNewAccountBillInProgress,
})(AdditionalBillLineItemsManager);
