import {
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  TextField as MuiTextField,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { spacing } from "@material-ui/system";
import axios from "axios";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";

const Select = styled(MuiSelect)(spacing);
const TextField = styled(MuiTextField)(spacing);

const getPropertyNameToUuid = (properties, uuid) => {
  let ind = properties.findIndex((elem) => elem.uuid == uuid);
  return properties[ind].name;
};

const getPropertyIdToUuid = (properties, uuid) => {
  let ind = properties.findIndex((elem) => elem.uuid == uuid);
  return properties[ind].id;
};

export default (props) => {
  const editData = props.editData;
  const onFieldChange = props.onFieldChange;
  const moveOut = props.moveOut;
  const action = props.action;
  const handleClose = props.handleClose;
  const properties = props.properties.data.propertiesList.items;

  let readTypeCheckedInitialState = "";

  if (moveOut.readAmtType == "EST") readTypeCheckedInitialState = "EST";
  if (moveOut.readAmtType == "ACT") readTypeCheckedInitialState = "ACT";

  const [units, setUnits] = useState([]);
  const [areUnitsLoading, setAreUnitsLoading] = useState(false);

  const [readTypeChecked, setReadTypeChecked] = useState(
    readTypeCheckedInitialState
  );

  const handleReadTypeChange = (type) => {
    if (readTypeChecked == type) setReadTypeChecked("");
    else setReadTypeChecked(type);
  };

  const handlePropertyChange = (uuid) => {
    if (properties && properties.length) {
      const propertyId = getPropertyIdToUuid(properties, uuid);
      setAreUnitsLoading(true);

      axios
        .get(`/unit/property/${propertyId}`)
        .then((res) => {
          setUnits(res.data);
          setAreUnitsLoading(false);
        })
        .catch(console.log);
    }
  };

  const fetchInitialUnits = () => {
    if (!isEmpty(moveOut) && moveOut.propertyUuid)
      handlePropertyChange(moveOut.propertyUuid);
  };

  useEffect(() => {
    fetchInitialUnits();
  }, []);

  return (
    <div>
      {isEmpty(moveOut) ? (
        <div>Loading</div>
      ) : (
        <Card>
          <CardContent>
            {/* PropertyManager Details */}
            <Grid container spacing={3}>

              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12}  >
                    <h3 align="left" >Contact Info:</h3>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      label="Email"
                      type="text"
                      onChange={onFieldChange}
                      name="email"
                      defaultValue={moveOut.email}
                      //required
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <TextField
                      label="Phone"
                      type="text"
                      onChange={onFieldChange}
                      name="phone"
                      defaultValue={moveOut.phone}
                      //required
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}  >
                <Grid container spacing={1}>
                  <Grid item xs={12}  >
                    <h3 align="left" >Forwarding Info:</h3>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      label="Fwd Address 1"
                      type="text"
                      onChange={onFieldChange}
                      name="fwdAddress1"
                      defaultValue={moveOut.fwdAddress1}
                      //required
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      label="Fwd Address 2"
                      type="text"
                      onChange={onFieldChange}
                      name="fwdAddress2"
                      defaultValue={moveOut.fwdAddress2}
                      //required
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      label="Fwd City"
                      type="text"
                      onChange={onFieldChange}
                      name="fwdCity"
                      defaultValue={moveOut.fwdCity}
                      //required
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item sm={2} xs={6}>
                    <TextField
                      label="Fwd State"
                      type="text"
                      onChange={onFieldChange}
                      name="fwdState"
                      defaultValue={moveOut.fwdState}
                      //required
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item sm={4} xs={6}>
                    <TextField
                      label="Fwd Zip"
                      type="text"
                      onChange={onFieldChange}
                      name="fwdZip"
                      defaultValue={moveOut.fwdZip}
                      //required
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}  >
                <Grid container spacing={1}>
                  <Grid item xs={12}  >
                    <h3 align="left" >Unit Info:</h3>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel id="property">Property</InputLabel>
                      <Select
                        labelId="property"
                        id="propertyUuid"
                        onChange={onFieldChange}
                        defaultValue={moveOut.propertyUuid}
                        name="propertyUuid"
                      >
                        {properties &&
                          properties.length &&
                          properties.map((item, index) => (
                            <MenuItem value={item.uuid} key={index}>
                              {item.name}
                            </MenuItem>
                          ))}
                        ]
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel id="unit">Unit</InputLabel>

                      {units && units.length ? (
                        <Select
                          labelId="unit"
                          id="unitUuid"
                          onChange={onFieldChange}
                          defaultValue={moveOut.unitUuid}
                          name="unitUuid"
                        >
                          {units &&
                            units.length &&
                            units.map((item, index) => (
                              <MenuItem value={item.uuid} key={index}>
                                {item.address1 + " " + item.address2}
                              </MenuItem>
                            ))}
                          ]
                        </Select>
                      ) : (
                        <div></div>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}  >
                <Grid container spacing={1}>
                  <Grid item xs={12}  >
                    <h3 align="left" >Move Info:</h3>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      label="Move Out Date"
                      type="date"
                      onChange={onFieldChange}
                      name="moveOutDate"
                      defaultValue={moment.utc(moveOut.moveOutDate).format(
                        "YYYY-MM-DD"
                      )}
                      //required
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      label="Read Amt"
                      type="number"
                      onChange={onFieldChange}
                      name="meterRead"
                      defaultValue={moveOut.meterRead}
                      //required
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    {moveOut.meterRead != 0 ?
                      <>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={readTypeChecked == "ACT" ? true : false}
                              onChange={(e) => {
                                handleReadTypeChange("ACT");
                                onFieldChange(e);
                              }}
                              name="readAmtType"
                              value="ACT"
                            />
                          }
                          label="ACT Read"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={readTypeChecked == "EST" ? true : false}
                              onChange={(e) => {
                                handleReadTypeChange("EST");
                                onFieldChange(e);
                              }}
                              name="readAmtType"
                              value="EST"
                            />
                          }
                          label="EST Read"
                        />
                      </>
                      :
                      <>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={readTypeChecked == "ACT" ? true : false}
                              onChange={(e) => {
                                handleReadTypeChange("ACT");
                                onFieldChange(e);
                              }}
                              disabled={true}
                              name="readAmtType"
                              value="ACT"
                            />
                          }
                          label="ACT Read"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={readTypeChecked == "EST" ? true : false}
                              disabled={true}
                              onChange={(e) => {
                                handleReadTypeChange("EST");
                                onFieldChange(e);
                              }}
                              name="readAmtType"
                              value="EST"
                            />
                          }
                          label="EST Read"
                        />
                      </>
                    }
                  </Grid>
                </Grid>
              </Grid>

              <Grid container item spacing={2} justify={"flex-end"}>
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      action();
                      handleClose();
                    }}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
    </div>
  );
};
