// App Imports
import axios from "axios";
import { getRefreshToken } from "modules/user/api/actions";

// Actions Types
export const GET_ALL_BILLS_IN_PROGRESS_REQUEST =
  "BILLING/GET_ALL_BILLS_IN_PROGRESS_REQUEST";
export const GET_ALL_BILLS_IN_PROGRESS_RESPONSE =
  "BILLING/GET_ALL_BILLS_IN_PROGRESS_RESPONSE";
export const GET_ALL_BILLS_IN_PROGRESS_FAILURE =
  "BILLING/GET_ALL_BILLS_IN_PROGRESS_FAILURE";

export const GET_BILLING_ANALYTICS_REQUEST =
  "BILLING/GET_BILLING_ANALYTICS_REQUEST";
export const GET_BILLING_ANALYTICS_RESPONSE =
  "BILLING/GET_BILLING_ANALYTICS_RESPONSE";
export const GET_BILLING_ANALYTICS_FAILURE =
  "BILLING/GET_BILLING_ANALYTICS_FAILURE";

export const GET_BILLING_BATCH_REQUEST = "BILLING/GET_BILLING_BATCH_REQUEST";
export const GET_BILLING_BATCH_RESPONSE = "BILLING/GET_BILLING_BATCH_RESPONSE";
export const GET_BILLING_BATCH_FAILURE = "BILLING/GET_BILLING_BATCH_FAILURE";

export const GET_BILLING_PDF_REQUEST = "BILLING/GET_BILLING_PDF_REQUEST";
export const GET_BILLING_PDF_RESPONSE = "BILLING/GET_BILLING_PDF_RESPONSE";
export const GET_BILLING_PDF_FAILURE = "BILLING/GET_BILLING_PDF_FAILURE";

export const GET_BILLING_INFO_SUMMARY_REQUEST = "BILLING/GET_BILLING_INFO_SUMMARY_REQUEST";
export const GET_BILLING_INFO_SUMMARY_RESPONSE = "BILLING/GET_BILLING_INFO_SUMMARY_RESPONSE";
export const GET_BILLING_INFO_SUMMARY_FAILURE = "BILLING/GET_BILLING_INFO_SUMMARYx_FAILURE";

export const BILLING_OBJECT_RESET = "BILLING/BILLING_OBJECT_RESET";

export const HANDLE_NEW_BILLING_PROCESS_REQUEST =
  "BILLING/HANDLE_NEW_BILLING_PROCESS_REQUEST";
export const HANDLE_NEW_BILLING_PROCESS_RESPONSE =
  "BILLING/HANDLE_NEW_BILLING_PROCESS_RESPONSE";
export const HANDLE_NEW_BILLING_PROCESS_FAILURE =
  "BILLING/GET_ALL_BILLS_IN_PROGRESS_FAILURE";

export const UPDATE_BILL_DASHBOARD_APPLICATION_INSTANCE =
  "BILLING/UPDATE_BILLS_IN_PROGRESS_APPLICATION_INSTANCE"; // updates the current bill dashboard data

export const BILLWIZ_REPLACE_BILL_IN_PROGRESS_APPLICATION_INSTANCE =
  "BILLING/BILLWIZ_REPLACE_BILL_IN_PROGRESS_APPLICATION_INSTANCE"; // Get new billwiz instance from exising draft

export const BILLWIZ_GET_BILL_EXPANDED_INFO_REQUEST =
  "BILLING/BILLWIZ_GET_BILL_EXPANDED_INFO_REQUEST";
export const BILLWIZ_GET_BILL_EXPANDED_INFO_RESPONSE =
  "BILLING/BILLWIZ_GET_BILL_EXPANDED_INFO_RESPONSE";

export const BILLWIZ_DELETE_BILL_REQUEST =
  "BILLING/BILLWIZ_DELETE_BILL_REQUEST";
export const BILLWIZ_DELETE_BILL_RESPONSE =
  "BILLING/BILLWIZ_DELETE_BILL_RESPONSE";

export const BILLWIZ_GET_BILL_PROPERTY_INFO_REQUEST =
  "BILLING/BILLWIZ_GET_BILL_PROPERTY_INFO_REQUEST";
export const BILLWIZ_GET_BILL_PROPERTY_INFO_RESPONSE =
  "BILLING/BILLWIZ_GET_BILL_PROPERTY_INFO_RESPONSE";

export const BILLWIZ_GET_UNSETTLED_PROPERTY_METER_READINGS_REQUEST =
  "BILLING/GET_UNSETTLED_PROPERTY_METER_READINGS_REQUEST";
export const BILLWIZ_GET_UNSETTLED_PROPERTY_METER_READINGS_RESPONSE =
  "BILLING/BILLWIZ_GET_UNSETTLED_PROPERTY_METER_READINGS_RESPONSE";
export const BILLWIZ_UPDATE_SELECTED_PROPERTY_APPLICATION_INSTANCE =
  "BILLING/BILLWIZ_UPDATE_SELECTED_PROPERTY_APPLICATION_INSTANCE"; // updates the options available on that selected property
export const BILLWIZ_UPDATE_BILL_IN_PROGRESS_APPLICATION_INSTANCE =
  "BILLING/BILLWIZ_UPDATE_BILL_IN_PROGRESS_APPLICATION_INSTANCE"; // updates the options available on the bill in the works
export const BILLWIZ_UPDATE_APPLICATION_INSTANCE =
  "BILLING/BILLWIZ_UPDATE_APPLICATION_INSTANCE"; // updates the options available on the bill in the works
export const BILLWIZ_UPDATE_BILL_IN_PROGRESS_BILL_CALCULATION =
  'BILLING/BILLWIZ_UPDATE_BILL_IN_PROGRESS_BILL_CALCUlATION';

require("../../../setup/config/apiConfig");

export function updateBillingWizardSelectedProperty(
  newSelectedPropertyData,
  errorItems = []
) {
  return {
    type: BILLWIZ_UPDATE_SELECTED_PROPERTY_APPLICATION_INSTANCE,
    newSelectedPropertyData,
    errorItems,
  };
}

export function updateBillingWizardBillInProgress(
  newBillData,
  errorItems = []
) {
  return {
    type: BILLWIZ_UPDATE_BILL_IN_PROGRESS_APPLICATION_INSTANCE,
    newBillData,
    errorItems,
  };
}

export const updateBillInProgressBillCalculation = (updatedData) => ({
  type: BILLWIZ_UPDATE_BILL_IN_PROGRESS_BILL_CALCULATION,
  payload: updatedData,
});

export function refreshBillingWizardPageContent() {
  return {type: BILLWIZ_UPDATE_APPLICATION_INSTANCE};
}

export function updateBillingDashboard(newBillDashboardInfo) {
  return {
    type: UPDATE_BILL_DASHBOARD_APPLICATION_INSTANCE,
    newBillDashboardInfo,
  };
}

export function updateBillingDashboardAndDB(newBillDashboardInfo) {
  const {items, toUpdate} = newBillDashboardInfo;
  const itemsToUpdate = items.filter(item => toUpdate.includes(item.uuid));
  return dispatch => {
    dispatch(updateBillingDashboard(newBillDashboardInfo));

    return Promise.all(itemsToUpdate.map(item => {
      return axios.put(`/billingbatchset-dashboard-info/${item.uuid}`, item)
      .then(response => {
      })
      .catch(error => {
        console.log(error);
      });
    }));
  };
}

export function resetBillingObject() {
  return {type: BILLING_OBJECT_RESET};
}

// get bill in progress by bill id:
//  /billing-wizard-process/:accountBillWorkTableUuid

// Actions
export function handleBillingProcessSubmission(
  billInProgress,
  isLoading = true
) {
  return (dispatch) => {
    dispatch({
      type: HANDLE_NEW_BILLING_PROCESS_REQUEST,
      isLoading: isLoading,
    });
    return axios
      .post("/handle-billing-wizard-submission", billInProgress)
      .then((response) => {
        let error = "";
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }

        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem("user"));
        if (currentUser) {
          dispatch(updateBillingWizardBillInProgress(response.data));
          dispatch({
            type: HANDLE_NEW_BILLING_PROCESS_RESPONSE,
            isLoading: false,
            error: error,
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: HANDLE_NEW_BILLING_PROCESS_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: HANDLE_NEW_BILLING_PROCESS_FAILURE,
          isLoading: false,
          error: error,
        });
      });
  };
}
export function getBillingPdfs(isLoading = true){
  return (dispatch) => {
    dispatch({
      type: GET_BILLING_PDF_REQUEST,
      isLoading: isLoading
    });
    const filters = ["Printed Bills", "Owner Statements", "Rubs Packet"].join(',');

    return axios
      .get(`/pdf-filter?filter=${filters}`)
      .then((response) => {
        let error = "";
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }
       
        const currentUser = JSON.parse(window.localStorage.getItem("user"));
        if (currentUser) {
          if(response.data && response.data.length > 0){
            for (const item of response.data) {
              const date = new Date(item.created)
              const trimmedDate = date.toDateString()
              item.created = trimmedDate
            }
          }
          
          dispatch({
            type: GET_BILLING_PDF_RESPONSE,
            isLoading: false,
            error: error,
            pdfs: response.data
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: HANDLE_NEW_BILLING_PROCESS_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          });
        } 
      })
      .catch((err) => {
        dispatch({
          type: GET_BILLING_PDF_FAILURE,
          isLoading: false,
          error: err,
        });
      })
  }
}
export function handleBillingProcessRunning(billInProgress, isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: HANDLE_NEW_BILLING_PROCESS_REQUEST,
      isLoading: isLoading,
    });
    return axios
      .post("/handle-billing-wizard-submission", billInProgress)
      .then((response) => {
        let error = "";
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }
        // Update/Check if user object is the obj state

        const currentUser = JSON.parse(window.localStorage.getItem("user"));
        const refreshToken = window.localStorage.getItem("refreshToken");

        if (currentUser) {
           getRefreshToken(refreshToken, false).then((refreshToken) => {
             // If successfule update, call process account billing with the uuid
          if (response.data.accountBillWorkTableUUID) {
            response.data.successfulTrialBillExecutionAttempt = true;
            dispatch(updateBillingWizardBillInProgress(response.data));
            dispatch(
              axios.post(
                `/process-account-billing/${response.data.accountBillWorkTableUUID}`
              )
            );
            dispatch(getAllBillingDashboardInfo());
          }
          dispatch({
            type: HANDLE_NEW_BILLING_PROCESS_RESPONSE,
            isLoading: false,
            error: error,
          });
          // On a successful submission call billing object reset
          dispatch({
            type: BILLING_OBJECT_RESET,
          });
           }
           )
         
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: HANDLE_NEW_BILLING_PROCESS_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: HANDLE_NEW_BILLING_PROCESS_FAILURE,
          isLoading: false,
          error: error,
        });
      });
  };
}

export function getExtendedBillRidersView(
  item,
  billInProgress,
  isLoading = true
) {
  return (dispatch) => {
    dispatch({
      type: BILLWIZ_GET_BILL_EXPANDED_INFO_REQUEST,
      isLoading: isLoading,
    });
    return axios
      .get(
        "/worktableitem-riders?workTableItemUuid=" +
        item.uuid +
        "&billDate=" +
        billInProgress.billInformation.billDate +
        "&propertyUuid=" +
        billInProgress.billInformation.propertyUUID +
        "&isCommonAccount=0"
      )
      .then((response) => {
        let error = "";
        if (response.data.errors && response.data.errors.length > 0) {
        }
        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem("user"));
        if (currentUser) {
          dispatch({
            type: BILLWIZ_GET_BILL_EXPANDED_INFO_RESPONSE,
            isLoading: false,
            selectedBill: item,
            response: response.data,
            error: error,
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: BILLWIZ_GET_BILL_EXPANDED_INFO_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: BILLWIZ_GET_BILL_EXPANDED_INFO_RESPONSE,
          isLoading: false,
          error: error,
        });
      });
  };
}

// get bill in progress by bill id:
//
export function getBillInProgressDraft(workTableUUID, isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: HANDLE_NEW_BILLING_PROCESS_REQUEST,
      isLoading: isLoading,
    });
    return axios
      .get(`/billing-wizard-process/${workTableUUID}`)
      .then((response) => {
        let error = "";
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }
        dispatch(
          getSelectedBillWizPropertyInfoByPropUUID(
            response.data.billInformation.propertyUUID
          )
        );
        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem("user"));
        if (currentUser) {
          if (response.data?.trialBillingItems?.items.length > 0) {
            for (const item of response.data?.trialBillingItems?.items) {
            
            let prevReadDate = new Date(item.prevReadDate);
            let currReadDate = new Date(item.currReadDate);

             
            item.prevReadDate = prevReadDate.toISOString().split('T')[0];
            item.currReadDate = currReadDate.toISOString().split('T')[0];
            

          }
          if (response.data?.vendorInformation?.billDate) {
            let billDate = new Date(response.data?.vendorInformation?.billDate);
            if (!isNaN(billDate.getTime())) {
              response.data.vendorInformation.billDate = billDate.toISOString().split('T')[0];
            }
          }
        }
        if (response.data?.vendorInformation?.meterReadDate) {
          let meterReadDate = new Date(response.data?.vendorInformation?.meterReadDate);
          if (!isNaN(meterReadDate.getTime())) {
            response.data.vendorInformation.meterReadDate = meterReadDate.toISOString().split('T')[0];
          }
        }
        dispatch(updateBillingWizardBillInProgress(response.data));
        dispatch({
          type: HANDLE_NEW_BILLING_PROCESS_RESPONSE,
          isLoading: false,
          error: error,
        });
      } else {
        // if no user is found then clear object because its an unauthorized access
        dispatch({
          type: HANDLE_NEW_BILLING_PROCESS_RESPONSE,
          isLoading: false,
          error: "ERROR! NO USER LOGGED IN!",
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: HANDLE_NEW_BILLING_PROCESS_FAILURE,
        isLoading: false,
        error: error,
      });
    });
  };
}

export function getAllBillingDashboardInfo(isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: GET_ALL_BILLS_IN_PROGRESS_REQUEST,
      isLoading: isLoading,
    });
    return axios
      .get("/billingbatchset-dashboard-info")
      .then((response) => {
        let error = "";
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }

        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem("user"));
        if (currentUser) {
          dispatch(updateBillingDashboard(response.data));
          dispatch({
            type: GET_ALL_BILLS_IN_PROGRESS_RESPONSE,
            isLoading: false,
            error: error,
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: GET_ALL_BILLS_IN_PROGRESS_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_BILLS_IN_PROGRESS_FAILURE,
          isLoading: false,
          error: error,
        });
      });
  };
}

export function getAllCompanyBillingAnalytics(isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: GET_BILLING_ANALYTICS_REQUEST,
      isLoading: isLoading,
    });
    return axios
    .get("/billing-dashboard")
    .then((response) => {
      let error = "";
      if (response.data.errors && response.data.errors.length > 0) {
        error = response.data.errors[0].message;
      }

      // Update/Check if user object is the obj state
      const currentUser = JSON.parse(window.localStorage.getItem("user"));
      if (currentUser) {
        dispatch(updateBillingDashboard(response.data));
        dispatch({
          type: GET_BILLING_ANALYTICS_RESPONSE,
          isLoading: false,
          error: error,
        });
      } else {
        // if no user is found then clear object because its an unauthorized access
        dispatch({
          type: GET_BILLING_ANALYTICS_RESPONSE,
          isLoading: false,
          error: "ERROR! NO USER LOGGED IN!",
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: GET_BILLING_ANALYTICS_FAILURE,
        isLoading: false,
        error: error,
      });
    });
  };
}

export function getSelectedBillWizPropertyInfoByPropUUID(
  uuid,
  billingType,
  isLoading = true
) {
  return (dispatch) => {
    dispatch({
      type: BILLWIZ_GET_BILL_PROPERTY_INFO_REQUEST,
      isLoading: isLoading,
    });
    return axios
    .get(`/property/${uuid}`)
    .then((response) => {
      let error = "";
      if (response.data.errors && response.data.errors.length > 0) {
        error = response.data.errors[0].message;
      }

      // Update/Check if user object is the obj state
      const currentUser = JSON.parse(window.localStorage.getItem("user"));
      if (currentUser) {
        dispatch(updateBillingWizardSelectedProperty(response.data));
        if (response.data.id) {
          dispatch(
            getAllUnsettledPropertyMeterReadingsByPropertyUUID(
              response.data.id
            )
          );
        }
        //
        dispatch({
          type: BILLWIZ_GET_BILL_PROPERTY_INFO_RESPONSE,
          isLoading: false,
          error: error,
        });
      } else {
        // if no user is found then clear object because its an unauthorized access
        dispatch({
          type: BILLWIZ_GET_BILL_PROPERTY_INFO_RESPONSE,
          isLoading: false,
          error: "ERROR! NO USER LOGGED IN!",
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: BILLWIZ_GET_BILL_PROPERTY_INFO_RESPONSE,
        isLoading: false,
        error: error,
      });
    });
  };
}

export function getBillingBatchById(batchId, isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: GET_BILLING_BATCH_REQUEST,
      isLoading: isLoading,
    });
    return axios
      .get(`/billingbatches-by-batchsetid?billingBatchSetId=${batchId}`)
      .then((response) => {
        let error = "";
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }

        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem("user"));
        if (currentUser) {
          dispatch({
            type: GET_BILLING_BATCH_RESPONSE,
            response: response.data,
            error: error,
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: GET_BILLING_BATCH_RESPONSE,
            error: "ERROR! NO USER LOGGED IN!",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_BILLING_BATCH_FAILURE,
          isLoading: false,
          error: err,
        });
      });
  };
}

//let MeterReadings = await apiRequest(`/latest-meterreadings-type-count-by-property?propertyId=${propertyId}`, "GET");
// Gets all of the meter readings for a property
export function getAllUnsettledPropertyMeterReadingsByPropertyUUID(propertyId) {
  return (dispatch) => {
    dispatch({
      type: BILLWIZ_GET_UNSETTLED_PROPERTY_METER_READINGS_REQUEST,
      isLoading: true,
    });
    return axios
      .get(
        `/latest-meterreadings-type-count-by-property?propertyId=${propertyId}`
      )
      .then((response) => {
        let error = "";
        if (response.data.error) {
          error = response.data.errors[0].message;
        }
        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem("user"));
        if (currentUser) {
          const meterReadings = { meterReadings: response.data };
          // Setting the selected properties meter reading data to the response
          dispatch(
            updateBillingWizardSelectedProperty(
              meterReadings,
              response.data.errors
            )
          );
          // Sets the result message of the response to the state object
          dispatch({
            type: BILLWIZ_GET_UNSETTLED_PROPERTY_METER_READINGS_RESPONSE,
            isLoading: false,
            error: error,
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: BILLWIZ_GET_UNSETTLED_PROPERTY_METER_READINGS_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
            data: null,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: BILLWIZ_GET_UNSETTLED_PROPERTY_METER_READINGS_RESPONSE,
          error: error,
        });
      });
  };
}

export function deleteBillDraftByUUID(uuid, isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: BILLWIZ_GET_BILL_PROPERTY_INFO_REQUEST,
    });
    return axios
    .delete(`/cleanup-billing-draft?workTableUuid=${uuid}`)
    .then((response) => {
      let error = "";
      if (response.data.errors && response.data.errors.length > 0) {
        error = response.data.errors[0].message;
      }
      dispatch({
        type: BILLWIZ_DELETE_BILL_RESPONSE,
        isLoading: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: BILLWIZ_DELETE_BILL_RESPONSE,
        isLoading: false,
      });
    });
  };
}

export function getBillingInfoSummary(propertyUuid) {
  return dispatch => {
    dispatch({
      type: GET_BILLING_INFO_SUMMARY_REQUEST,
      isLoading: true
    });
    return axios.get(`/vendorbill-summary/${propertyUuid}`)
    .then(response => {
      dispatch({
        type: GET_BILLING_INFO_SUMMARY_RESPONSE,
        isLoading: false,
        responseData: response.data,
        error: ""
      });
    })
    .catch(error => {
      dispatch({
        type: GET_BILLING_INFO_SUMMARY_FAILURE,
        isLoading: false,
        error: error
      });
    });
  };
}



