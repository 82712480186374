//React Components and Hooks
import Grid from "@material-ui/core/Grid";
//Material UI Components and Functions
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import BreadCrumb from "../../components/BreadCrumb";
//Custom Components
import { LeftSidebar as NavFrame } from "layout-blueprints";
import ReportResults from "./ReportResults";
import Tools from "./Tools";
//Context

const useStyles = makeStyles((theme) => ({}));

const IndividualReportPage = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const [currentDate, setCurrentDate] = useState(new Date());
  const [error, setError] = useState("");
  const [showError, setShowError] = useState(false);
  const { reportName } = useParams();

  const asyncEffect = async () => { };
  useEffect(() => {
    asyncEffect();
  }, []);

  return (
    <NavFrame page={reportName}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="h4" color="textPrimary">
            {reportName}
          </Typography>
        </Grid>
        <Grid item>
          <BreadCrumb
            crumbs={[{ link: "/reports", label: "Report" }]}
            current={reportName}
          />
        </Grid>
        <Grid item>
          <Tools />
        </Grid>
        <Grid item>
          <ReportResults />
        </Grid>
      </Grid>
    </NavFrame>
  );
};
export default IndividualReportPage;
