import {
  Button as MuiButton,
  Card,
  CardContent,
  CircularProgress as MuiCircularProgress,
  Divider as MuiDivider,
  FormControl,
  Grid,
  InputLabel,
  Checkbox,
  FormGroup,
  FormControlLabel,
  LinearProgress as MuiLinearProgress,
  MenuItem,
  Select as MuiSelect,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import PropTypes from "prop-types";
import React, { useCallback, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components/macro";
import { setSnackbar } from "../../snackbar/api/snackbar";
import { post, put } from "../api/actions";
import { getById as getPropertyManager } from "modules/propertyManager/api/actions";

const LinearProgress = MuiLinearProgress;
const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Select = styled(MuiSelect)(spacing);

const UnitForm = (props) => {
  // Handles vendor drop down
  const dispatch = useDispatch();

  const onFieldChange = (event) => {
    props.units.data.unit[event.target.name] = event.target.value;
  };


  const toggleActiveCommon = () => {
    setCommonAccount(!commonAccount);
    props.units.data.unit.commonAccount = !commonAccount;
  };


  // Handles vendor drop down
  const [state, setState] = React.useState("");
  const [commonAccount, setCommonAccount] = React.useState(props.units.data.unit.commonAccount == 1 ? true : false);
  const [unitTypes, setUnitTypes] = React.useState(props.apl.genericTypes.unitType.data);

  const save = async () => {
    const u = props.units.data.unit;
    const newUnit = {
      id: u.id,
      uuid: u.uuid,
      unitTypeId: u.unitTypeId,
      propertyId: props.properties.data.property.id,
      propertyManagerId: u.propertyManagerId,
      developerId: u.developerId,
      ownerId: u.ownerId,
      commonAccount: u.commonAccount,
      address1: u.address1,
      address2: u.address2,
      buildingNumber: u.buildingNumber,
      bedrooms: u.bedrooms,
      baths: u.baths,
      city: u.city,
      state: u.state,
      zip: u.zip,
      heatingType: u.heatingType,
      sqFt: u.sqFt,
    };

    if (!newUnit.id) {
      props.post(newUnit);
    } else {
      await props.put(newUnit);
      await props.getPropertyManager(newUnit.propertyManagerId);

    }
    dispatch(setSnackbar(true, "success", "Unit updated"));
    props.onUpdate();
  };

  

  if (props.propertyManagers.isLoading || props.units.unitIsLoading) {
    return (
      <Card mb={6}>
        <CardContent>
          <Typography variant="h3" gutterBottom>
            Loading...
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardContent>
        {/* Unit Details */}
        <Grid container spacing={2}>
          <Grid container item spacing={2}>
            <Grid item sm={6} xs={12}>
              <TextField
                label="Address 1"
                type="text"
                onChange={onFieldChange}
                name="address1"
                defaultValue={props.units.data.unit.address1}
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                label="Address 2"
                type="text"
                onChange={onFieldChange}
                defaultValue={props.units.data.unit.address2}
                name="address2"
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>

          <Grid container item spacing={2}>
            {
              /*
                <Grid item md={6} xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="selectedAccountStatusDropDownLabel">
                    Property Manager
                  </InputLabel>
                  <Select
                    labelId="selectedAccountStatusDropDownLabel"
                    id="selectedAccountStatusDropDown"
                    defaultValue={props.units.data.unit.propertyManagerId}
                    onChange={onFieldChange}
                    name="propertyManagerId"
                  >
                    {props.propertyManagers.data.propertyManagersList.items.map(
                      (item, index) => (
                        <MenuItem id="AccountStatusOption1" value={item.id}>
                          {item.name}
                        </MenuItem>
                      )
                    )}
                  ]
</Select>
                </FormControl>
              </Grid>
            */
            }
            {/*<Grid item md={6} xs={12}>*/}
            {/*  <FormControl variant="outlined" fullWidth>*/}
            {/*    <InputLabel id="selectedAccountStatusDropDownLabel">*/}
            {/*      Owners*/}
            {/*    </InputLabel>*/}
            {/*    <Select*/}
            {/*      labelId="selectedAccountStatusDropDownLabel"*/}
            {/*      id="selectedAccountStatusDropDown"*/}
            {/*      defaultValue={props.units.data.unit.ownerId}*/}
            {/*      onChange={onFieldChange}*/}
            {/*      name="ownerId"*/}
            {/*    >*/}
            {/*      {props.owners.data.ownersList.items.map((item, index) => (*/}
            {/*        <MenuItem id="AccountStatusOption1" value={item.id}>*/}
            {/*          {`${item.name} - ${item.glCode}`}*/}
            {/*        </MenuItem>*/}
            {/*      ))}*/}
            {/*    </Select>*/}
            {/*  </FormControl>*/}
            {/*</Grid>*/}
            {/*<Grid item md={6} xs={12}>*/}
            {/*  <FormControl variant="outlined" fullWidth>*/}
            {/*    <InputLabel id="selectedUnitTypeDropDownLabel">*/}
            {/*      Unit Type*/}
            {/*    </InputLabel>*/}
            {/*    <Select*/}
            {/*      labelId="selectedUnitTypeDropDownLabel"*/}
            {/*      id="selectedUnitTypeDropDown"*/}
            {/*      defaultValue={props.units.data.unit.unitTypeId}*/}
            {/*      onChange={onFieldChange}*/}
            {/*      name="unitTypeId"*/}
            {/*    >*/}
            {/*      {props.apl.genericTypes.unitType.data.map((item, index) => (*/}
            {/*        <MenuItem key={item.id} value={item.id}>*/}
            {/*          {item.description}*/}
            {/*        </MenuItem>*/}
            {/*      ))}*/}
            {/*    </Select>*/}
            {/*  </FormControl>*/}
            {/*</Grid>*/}
          </Grid>
          <Grid container item spacing={2}>
            <Grid item sm={6} xs={12}>
              <TextField
                label="City"
                type="text"
                onChange={onFieldChange}
                defaultValue={props.units.data.unit.city}
                name="city"
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item sm={3} xs={6}>
              <TextField
                label="State"
                type="text"
                onChange={onFieldChange}
                defaultValue={props.units.data.unit.state}
                name="state"
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item sm={3} xs={6}>
              <TextField
                label="Zip"
                type="text"
                onChange={onFieldChange}
                defaultValue={props.units.data.unit.zip}
                name="zip"
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>

          <Grid container item spacing={2}>
            <Grid item md={6} xs={12}>
              <FormControl variant='outlined' fullWidth>
                <InputLabel id="selectedAccountStatusDropDownLabel">
                  Unit Type
                </InputLabel>
                <Select
                  labelId="selectedAccountStatusDropDownLabel"
                  id="selectedAccountStatusDropDown"
                  defaultValue={props.units.data.unit.unitTypeId}
                  onChange={onFieldChange}
                  name="unitTypeId"
                >
                  {props.unitTypes.data.unitTypesList.items.map(
                    (item, index) => (
                      <MenuItem id="AccountStatusOption1" value={item.id}>
                        {item.description}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </Grid>
            {/* <Grid item md={3} xs={6}>
              <FormControlLabel
                control={<Checkbox name="checkedA" />}
                label="Common Account"
                defaultValue={props.units.data.unit.commonAccount}
                name="commonAccount"
                onChange={onFieldChange}
                labelPlacement="top"
              />
            </Grid> */}
            <Grid item sm={3} xs={6}>
              <TextField
                label="SQ FT"
                type="text"
                onChange={onFieldChange}
                defaultValue={props.units.data.unit.sqFt}
                name="sqFt"
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item sm={3} xs={6}>
              <TextField
                label="Building Number"
                type="text"
                onChange={onFieldChange}
                defaultValue={props.units.data.unit.buildingNumber}
                name="buildingNumber"
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            {/*<Grid item sm={6} xs={6}>*/}
            {/*  <TextField*/}
            {/*    label="Bedrooms"*/}
            {/*    type="text"*/}
            {/*    onChange={onFieldChange}*/}
            {/*    defaultValue={props.units.data.unit.bedrooms}*/}
            {/*    name="bedrooms"*/}
            {/*    fullWidth*/}
            {/*    variant="outlined"*/}
            {/*    InputLabelProps={{*/}
            {/*      shrink: true,*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</Grid>*/}
            {/*<Grid item sm={6} xs={6}>*/}
            {/*  <TextField*/}
            {/*    label="Baths"*/}
            {/*    type="text"*/}
            {/*    onChange={onFieldChange}*/}
            {/*    defaultValue={props.units.data.unit.baths}*/}
            {/*    name="baths"*/}
            {/*    fullWidth*/}
            {/*    variant="outlined"*/}
            {/*    InputLabelProps={{*/}
            {/*      shrink: true,*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</Grid>*/}
            <Grid item md={4} xs={6}>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={props.units.data.unit.commonAccount == 1 ? true : false}
                      onChange={toggleActiveCommon}
                      name="commonAccount"
                    />
                  }
                  label="Common Account"
                />
              </FormGroup>
              {/*<FormGroup row>*/}
              {/*  <FormControlLabel*/}
              {/*    control={*/}
              {/*      <Checkbox*/}
              {/*        checked={props.units.data.unit.leaseLight == 1 ? true : false}*/}
              {/*        onChange={toggleActive}*/}
              {/*        name="leaseLight"*/}
              {/*      />*/}
              {/*    }*/}
              {/*    label="Lease Light"*/}
              {/*  />*/}
              {/*</FormGroup>*/}
            </Grid>
            {/* <Grid item md={6} xs={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="selectedAccountStatusDropDownLabel">
                  Heating Type
                </InputLabel>
                <Select
                  labelId="selectedAccountStatusDropDownLabel"
                  id="selectedAccountStatusDropDown"
                  value={UnitState}
                  onChange={handleSelectedAccountStatusChange}
                  name="accountStatusId"
                >
                  <MenuItem id="AccountStatusOption1" value={80}>
                    Active
                  </MenuItem>
                  <MenuItem id="AccountStatusOption3" value={83}>
                    Inactive
                  </MenuItem>
                ]
</Select>
              </FormControl>
            </Grid> */}
          </Grid>

          {/* <Grid container item>
            <Grid item xs={12}>
              <TextField
                label="Current/Last Occupant"
                type="text"
                onChange={onFieldChange}
                defaultValue={props.units.data.unit.address2}
                name="name"
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid> */}
          <Grid container item spacing={2} justify={"flex-end"}>
            <Grid item>
              <MuiButton variant="contained" onClick={save} color="secondary">
                Save
              </MuiButton>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

// Component Properties
UnitForm.propTypes = {
  units: PropTypes.object.isRequired,
  propertyManagers: PropTypes.object.isRequired,
  owners: PropTypes.object.isRequired,
  unitTypes: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  put: PropTypes.object.isRequired,
  post: PropTypes.object.isRequired,
  properties: PropTypes.object.isRequired,
  getPropertyManager: PropTypes.func.isRequired,
};

function UnitFormState(state) {
  return {
    units: state.units,
    propertyManagers: state.propertyManagers,
    apl: state.apl,
    owners: state.owners,
    unitTypes: state.unitTypes,
    properties: state.properties,
  };
}
export default connect(UnitFormState, { put, post, getPropertyManager })(UnitForm);
