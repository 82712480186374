// Imports
import axios from "axios";
import { setSnackbar } from "../../snackbar/api/snackbar";
require("../../../setup/config/apiConfig");

export const RESET = "RESET";
export const NEW_EMAIL_REQUEST = "NEW_EMAIL_REQUEST";
export const NEW_EMAIL_RESPONSE = "NEW_EMAIL_RESPONSE";
export const NEW_EMAIL_FAILURE = "NEW_EMAIL_FAILURE";
export const SET_TEMPLATE = "SET_TEMPLATE";
export const UPDATE_EMAIL_STATE = "UPDATE_EMAIL_STATE";

export function setEmailTemplate(type) {
  return { type: SET_TEMPLATE, templateType: type };
}

export function updateEmailState(email) {
  return { type: UPDATE_EMAIL_STATE, currentEmail: email };
}

export function newEmailNotification(body, isLoading = true) {
  return (dispatch) => {
    dispatch(setSnackbar(true, "warning", "Generating email for submission..."));
    dispatch({
      type: NEW_EMAIL_REQUEST,
      isLoading: isLoading,
    });
    return axios.post(`/notification/send-account-notification`, body).then((response) => {
      dispatch({
        type: NEW_EMAIL_RESPONSE,
        response: response.data,
      });
      dispatch(
        setSnackbar(true, "success", "Email Sent!")
      );
    }).catch((error) => {
      dispatch(setSnackbar("error", "Unable to send email."));
      dispatch({
        type: NEW_EMAIL_FAILURE,
        isLoading: false,
        error: error,
      });
    });
  };
}

