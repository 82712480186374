import { Divider as MuiDivider } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { spacing } from "@material-ui/system";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import StandardHeader from "../../components/global/StandardHeader";
import { LeftSidebar as NavFrame } from "layout-blueprints";
import { getByUuid as getMeterByUuid } from "../../modules/meter/api/actions";
import MeterDetailCard from "../../modules/meter/components/DetailCard";
import MeterReadForm from "../../modules/meter/components/meterReadForm";
import MeterHistory from "modules/meter/components/MeterHistory";


const Divider = styled(MuiDivider)(spacing);
const IndividualMeterPage = (props) => {
  const meterUuid = props.match.params.uuid;
  useEffect(() => {
    props.getMeterByUuid(meterUuid);
    document.title = "Ubil+ Meter";
  }, []);

  return (
    <NavFrame page={"IndividualMeterPage"}>
      <StandardHeader title="Meter" />
      <Divider className="mb-3 mt-3" />
      <Grid container spacing={1} xs={12} sm={12} md={12} lg={12}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container item>
            <Grid item xs={12}>
              <MeterDetailCard />
              <br></br>
              <MeterReadForm />
            </Grid>
            <Grid item xs={12}>
              <MeterHistory/>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </NavFrame>
  );
};

IndividualMeterPage.propTypes = {
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  meter: PropTypes.object.isRequired,
  getMeterByUuid: PropTypes.func.isRequired,
};

// Component State
function IndividualMeterPageState(state) {
  return {
    user: state.user,
    apl: state.apl,
    meter: state.meter,
  };
}

export default connect(IndividualMeterPageState, { getMeterByUuid })(
  IndividualMeterPage
);
