import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { get as getProperties } from "../../modules/property/api/actions";

const Filter = (props) => {
  const properties = props.properties.data.propertiesList.items;
  const reports = props.settings.reports.items;
  const paymentPlanStatus = props.apl.genericTypes.paymentPlanStatus.data;
  const customerStatus = props.apl.genericTypes.accountStatus.data;

  const params = props.params;
  const dispatch = useDispatch();

  useEffect(() => {
    if (properties.length == 0) {
    }
    dispatch(getProperties());
  }, [dispatch]);
  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "50ch" },
      }}
      noValidate
      autoComplete="off"
    >
      <div>
        {reports.length > 0 ? (
          <TextField
            id="report"
            size="small"
            name="report"
            select
            label="Report"
            value={props.report}
            onChange={props.handleChange}
            helperText="Please select Report"
          >
            {reports.map((option) => (
              <MenuItem key={option.uuid} value={option}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        ) : (
          <CircularProgress />
        )}
      </div>
      <div>
        {props.filter.property >= 1 && (
          <TextField
            id="property"
            select
            size="small"
            name="property"
            label="Property"
            onChange={props.handleChange}
            value={props.params.property}
            helperText="Active Properties"
          >
            <MenuItem key={1} value={"All"}>
              {"All"}
            </MenuItem>
            {properties.map((option) => (
              <MenuItem key={option.uuid} value={option.name}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        )}
        {props.filter.startDate >= 1 && (
          <TextField
            id="startDate"
            size="small"
            type="date"
            name="startDate"
            value={props.params.startDate}
            onChange={props.handleChange}
            helperText="Start Date"
          ></TextField>
        )}
        {props.filter.endDate >= 1 && (
          <TextField
            id="endDate"
            size="small"
            type="date"
            name="endDate"
            value={props.params.endDate}
            onChange={props.handleChange}
            helperText="End Date"
          ></TextField>
        )}

        {(props.filter.paymentPlan >= 1 && paymentPlanStatus.length) > 0 ? (
          <TextField
            id="paymentPlan"
            name="paymentPlan"
            size="small"
            select
            label="Payment Plan"
            value={props.paymentPlanStatus}
            onChange={props.handleChange}
            helperText="Please select Payment Plan"
          >
            {paymentPlanStatus.map((option) => (
              <MenuItem key={option.uuid} value={option.description}>
                {option.description}
              </MenuItem>
            ))}
          </TextField>
        ) : (
          <div />
        )}

        {(props.filter.customerStatus >= 1 && customerStatus.length) > 0 ? (
          <TextField
            id="customerStatus"
            name="customerStatus"
            select
            size="small"
            label="Customer Status"
            value={props.customerStatus}
            onChange={props.handleChange}
            helperText="Please select Customer Status"
          >
            {customerStatus.map((option) => (
              <MenuItem key={option.uuid} value={option.description}>
                {option.description}
              </MenuItem>
            ))}
          </TextField>
        ) : (
          <div />
        )}

        
      </div>
    </Box>
  );
};
// Component Properties
Filter.propTypes = {
  setting: PropTypes.object.isRequired,
  getMessageSettings: PropTypes.func.isRequired,
  getSettings: PropTypes.func.isRequired,
};

// Component State
function FilterState(state) {
  return {
    properties: state.properties,
    settings: state.setting,
    apl: state.apl,
  };
}
export default connect(FilterState, { getProperties })(Filter);
