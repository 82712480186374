// Imports
import axios from "axios";
import moment from "moment";

import { setSnackbar } from "../../snackbar/api/snackbar";

require("../../../setup/config/apiConfig");

// Actions Types
export const APL_UPDATE_APPLICATION_INSTANCE =
  "APL_UPDATE_APPLICATION_INSTANCE";
export const APL_GET_METRICS_REQUEST = "APL_GET_METRICS_REQUEST";
export const APL_GET_METRICS_RESPONSE = "APL_GET_METRICS_RESPONSE";
export const APL_GET_METRICS_RESET = "APL_GET_METRICS_RESET";

// Generic Req, Resp, Failure, tasks
export const APL_GET_GEN_TYPES_REQUEST = "APL_GET_GEN_TYPES_REQUEST";
export const APL_GEN_TYPES_UPDATE_LOCAL_STATE =
  "APL_GET_GEN_TYPES_UPDATE_LOCAL_STATE";
export const APL_GET_GEN_TYPES_RESPONSE = "APL_GET_GEN_TYPES_RESPONSE";
export const APL_GET_GEN_TYPES_FAILURE = "APL_GET_GEN_TYPES_FAILURE";

// Types/Statuses of items throughout the application's custom sub types
export const APL_GET_PROPERTY_PREVIEWS_REQUEST =
  "APL_GET_PROPERTY_PREVIEWS_REQUEST";
export const APL_GET_PROPERTY_PREVIEWS_RESPONSE =
  "APL_GET_PROPERTY_PREVIEWS_RESPONSE";

export const APL_TYPE_REQUEST = "APL_TYPE_REQUEST";
export const APL_TYPE_RESPONSE = "APL_TYPE_RESPONSE";
export const APL_TYPE_Failure = "APL_TYPE_Failure";

export function updateStateObject(data) {
  return { type: APL_UPDATE_APPLICATION_INSTANCE, data };
}

export function updateAppInstanceWithNewGenericData(data, itemName) {
  return { type: APL_GEN_TYPES_UPDATE_LOCAL_STATE, data, itemName };
}
/*Options: 
    "Today"
    "Yesterday"
    "Last 7 days"
    "Last 30 days"
    "This Month"
    "Last Month"
    "This Year"
 "Forever"
*/
// Returns the query body for the analytical data
function handleTimePeriodTranslation(timePeriod) {
  console.log("timePeriod");
  console.log(timePeriod);
  let range = { start: null, end: moment().format() }; // I have nulls set to get everything
  if (timePeriod == "Forever") {
    return range;
  } else if (timePeriod == "Today") {
    range.start = moment().startOf("day").format();
  } else if (timePeriod == "Yesterday") {
    range.start = moment().startOf("day").subtract(1, "day").format();
    range.end = moment().startOf("day").format();
  } else if (timePeriod == "Last 7 days") {
    range.start = moment().startOf("day").subtract(7, "day").format();
  } else if (timePeriod == "Last 30 days") {
    range.start = moment().startOf("day").subtract(30, "day").format();
  } else if (timePeriod == "This Month") {
    range.start = moment().startOf("month").format();
    //range.start = moment().startOf("day").subtract(30, "day").format();
  } else if (timePeriod == "Last Month") {
    range.start = moment().startOf("month").subtract(1, "month").format();
    range.end = moment().startOf("month").format();
  } else if (timePeriod == "This Year") {
    range.start = moment().startOf("year").format();
  }
  return range;
}
/*
var FormattedStartDate;
if (startDate != null)
  FormattedStartDate = moment.utc().subtract(1, timePeriod).format("YYYY-MM-DD HH:mm:ss");
else
  FormattedStartDate = moment.utc().subtract(6, 'months').format("YYYY-MM-DD HH:mm:ss");
*/

//let data = await apiRequest(`/analytics?startDate=${date ? moment.utc(date).format("YYYY-MM-DD HH:mm:ss") : moment.utc().subtract(1, 'month').format("YYYY-MM-DD HH:mm:ss")}`, "GET");
export function getAnalyticalData(timePeriod, isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: APL_GET_METRICS_REQUEST,
      isLoading: isLoading,
      newTimePeriod: timePeriod,
    });
    let params = { params: handleTimePeriodTranslation(timePeriod) };
    //console.log("params");
    //console.log(params);

    return axios
      .get(`/analytics`, params)
      .then((response) => {
        let error = "";
        if (response.data.error) {
          error = response.data.errors[0].message;
        }
        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem("user"));
        if (currentUser) {
          // Setting the states analytic data to the response
          //dispatch(updateStateObject(response.data));
          // Sets the result message of the response to the state object
          dispatch({
            type: APL_GET_METRICS_RESPONSE,
            isLoading: false,
            analyticSummary: response.data,
            error: error,
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: APL_GET_METRICS_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
            data: null,
          });
        }
      })
      .catch((error) => {
        dispatch(setSnackbar("error", "Could not retrieve data"));
        dispatch({
          type: APL_GET_METRICS_RESPONSE,
          error: error,
        });
      });
  };
}

export function getAllGenericDBStatusOrTypeData(
  tableName,
  requestStatus,
  responseStatus,
  failureStatus
) {
  return (dispatch) => {
    dispatch({
      type: APL_GET_GEN_TYPES_REQUEST,
      subItemType: requestStatus,
      itemName: tableName,
      subItemIsLoading: true,
    });
    return axios
      .get(`/` + tableName)
      .then((response) => {
        let error = null;
        if (response.data.error) {
          error = response.data.errors[0].message;
        }
        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem("user"));
        if (currentUser) {
          // Setting the states analytic data to the response
          dispatch(
            updateAppInstanceWithNewGenericData(response.data, tableName)
          );
          // Sets the result message of the response to the state object
          dispatch({
            type: APL_GET_GEN_TYPES_RESPONSE,
            subItemType: responseStatus,
            itemName: tableName,
            subItemIsLoading: false,
            subItemError: error,
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: APL_GET_GEN_TYPES_RESPONSE,
            subItemType: responseStatus,
            itemName: tableName,
            subItemIsLoading: false,
            subItemError: "ERROR! NO USER LOGGED IN!",
            data: null,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: APL_GET_GEN_TYPES_FAILURE,
          subItemType: failureStatus,
          itemName: tableName,
          subItemIsLoading: false,
          subItemError: error,
        });
      });
  };
}

export function getAllPropertyPreviews() {
  return (dispatch) => {
    dispatch({
      type: APL_GET_PROPERTY_PREVIEWS_REQUEST,
      isLoading: true,
    });
    return axios
      .get(`/property`)
      .then((response) => {
        let error = "";
        if (response.data.error) {
          error = response.data.errors[0].message;
        }
        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem("user"));
        if (currentUser) {
          let aplProperties = { propertyPreviews: response.data };
          // Setting the states analytic data to the response
          dispatch(updateStateObject(aplProperties));
          // Sets the result message of the response to the state object
          dispatch({
            type: APL_GET_PROPERTY_PREVIEWS_RESPONSE,
            isLoading: false,
            error: error,
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: APL_GET_PROPERTY_PREVIEWS_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
            data: null,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: APL_GET_PROPERTY_PREVIEWS_RESPONSE,
          error: error,
        });
      });
  };
}

// Account Standardized types
export function getAllAccountStatusCodes() {
  return (dispatch) => {
    dispatch(
      getAllGenericDBStatusOrTypeData(
        "accountStatus",
        APL_TYPE_REQUEST,
        APL_TYPE_RESPONSE,
        APL_TYPE_Failure
      )
    );
  };
}
export function getAllCustomerStatuses() {
  return (dispatch) => {
    dispatch(
      getAllGenericDBStatusOrTypeData(
        "customerStatus",
        APL_TYPE_REQUEST,
        APL_TYPE_RESPONSE,
        APL_TYPE_Failure
      )
    );
  };
}
export function getAllCustomerTypes() {
  return (dispatch) => {
    dispatch(
      getAllGenericDBStatusOrTypeData(
        "customerType",
        APL_TYPE_REQUEST,
        APL_TYPE_RESPONSE,
        APL_TYPE_Failure
      )
    );
  };
}
export function getAllPropertyStatuses() {
  return (dispatch) => {
    dispatch(
      getAllGenericDBStatusOrTypeData(
        "propertyStatus",
        APL_TYPE_REQUEST,
        APL_TYPE_RESPONSE,
        APL_TYPE_Failure
      )
    );
  };
}
export function getAllLetterTypes() {
  return (dispatch) => {
    dispatch(
      getAllGenericDBStatusOrTypeData(
        "letterType",
        APL_TYPE_REQUEST,
        APL_TYPE_RESPONSE,
        APL_TYPE_Failure
      )
    );
  };
}
export function getAllNoteTopicTypes() {
  return (dispatch) => {
    dispatch(
      getAllGenericDBStatusOrTypeData(
        "noteTopic",
        APL_TYPE_REQUEST,
        APL_TYPE_RESPONSE,
        APL_TYPE_Failure
      )
    );
  };
}

export function getAllMeterReadTypes() {
  return (dispatch) => {
    dispatch(
      getAllGenericDBStatusOrTypeData(
        "meterReadingType",
        APL_TYPE_REQUEST,
        APL_TYPE_RESPONSE,
        APL_TYPE_Failure
      )
    );
  };
}
export function getAllAccountBillableStatusTypes() {
  return (dispatch) => {
    dispatch(
      getAllGenericDBStatusOrTypeData(
        "accountbillablestatus",
        APL_TYPE_REQUEST,
        APL_TYPE_RESPONSE,
        APL_TYPE_Failure
      )
    );
  };
}
// Payment/Billing Types
export function getAllGlCodes() {
  return (dispatch) => {
    dispatch(
      getAllGenericDBStatusOrTypeData(
        "billingAccountGLCode",
        APL_TYPE_REQUEST,
        APL_TYPE_RESPONSE,
        APL_TYPE_Failure
      )
    );
  };
}
export function getAllPaymentMethodTypes() {
  return (dispatch) => {
    dispatch(
      getAllGenericDBStatusOrTypeData(
        "paymentMethodType",
        APL_TYPE_REQUEST,
        APL_TYPE_RESPONSE,
        APL_TYPE_Failure
      )
    );
  };
}
export function getAllPaymentPlanStatuses() {
  return (dispatch) => {
    dispatch(
      getAllGenericDBStatusOrTypeData(
        "paymentPlanStatus",
        APL_TYPE_REQUEST,
        APL_TYPE_RESPONSE,
        APL_TYPE_Failure
      )
    );
  };
}
export function getAllAutoPayStatuses() {
  return (dispatch) => {
    dispatch(
      getAllGenericDBStatusOrTypeData(
        "autoPayAttemptStatus",
        APL_TYPE_REQUEST,
        APL_TYPE_RESPONSE,
        APL_TYPE_Failure
      )
    );
  };
}
export function getAllAutoPayTypes() {
  return (dispatch) => {
    dispatch(
      getAllGenericDBStatusOrTypeData(
        "autoPayType",
        APL_TYPE_REQUEST,
        APL_TYPE_RESPONSE,
        APL_TYPE_Failure
      )
    );
  };
}
export function getAllCreditCardTypes() {
  return (dispatch) => {
    dispatch(
      getAllGenericDBStatusOrTypeData(
        "creditCardType",
        APL_TYPE_REQUEST,
        APL_TYPE_RESPONSE,
        APL_TYPE_Failure
      )
    );
  };
}
export function getAllACHTypes() {
  return (dispatch) => {
    dispatch(
      getAllGenericDBStatusOrTypeData(
        "achType",
        APL_TYPE_REQUEST,
        APL_TYPE_RESPONSE,
        APL_TYPE_Failure
      )
    );
  };
}

// Property Db Types
export function getAllUnitTypes() {
  return (dispatch) => {
    dispatch(
      getAllGenericDBStatusOrTypeData(
        "unitType",
        APL_TYPE_REQUEST,
        APL_TYPE_RESPONSE,
        APL_TYPE_Failure
      )
    );
  };
}
export function getAllVendors() {
  return (dispatch) => {
    dispatch(
      getAllGenericDBStatusOrTypeData(
        "vendor",
        APL_TYPE_REQUEST,
        APL_TYPE_RESPONSE,
        APL_TYPE_Failure
      )
    );
  };
}

export function getAllPMStatusTypes() {
  return (dispatch) => {
    dispatch(
      getAllGenericDBStatusOrTypeData(
        "propertyManagerStatus",
        APL_TYPE_REQUEST,
        APL_TYPE_RESPONSE,
        APL_TYPE_Failure
      )
    );
  };
}

export function getWorkOrderStatusTypes() {
  return (dispatch) => {
    dispatch(
      getAllGenericDBStatusOrTypeData(
        "workOrderStatus",
        APL_GET_GEN_TYPES_REQUEST,
        APL_GET_GEN_TYPES_RESPONSE,
        APL_GET_GEN_TYPES_FAILURE
      )
    );
  };
}