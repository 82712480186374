import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import logo from "../../../assets/images/logo-dark-shortened.png";

import { formatMoney, dateFormatter } from "../../../setup/helpers";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import { spacing } from "@material-ui/system";
// Redux Components
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import _ from "lodash"
const AccountLedgerPreview = (props) => {
  const today = moment().format("MMMM Do, YYYY");
  if (props.account.isLoading || !props.account.data.account || props.account.ledgerIsLoading || !props.account.ledger.entries) {
    return (
      <>
        <Skeleton
          variant="rect"
          height="600px"
          style={{
            backgroundColor: `#bdbfbe`,
            padding: 6,
            margin: 6,
            marginBottom: 3,
          }}
        />
      </>
    );
  }
  const account = props.account.data.account;
  const unit = props.account.data.unit;
  const property = props.account.data.property;
  var paymentAmount = 0;
  var billAmount = 0;
  var entries = props.account.ledger.entries;
  entries = _.uniqBy(entries, 'transactionId');


  var itemNumber = 0;
  entries.sort((a, b) => (a.transactionDate > b.transactionDate) ? 1 : -1)
  return (
    <div className="pdfView">
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      {/* Favicon */}
      <link rel="icon" href="./images/favicon.png" type="image/x-icon" />
      <div className="invoice-box">
        <table>
          <tbody>
            <tr className="top">
              <td colSpan={12}>
                <table>
                  <tbody>
                    <tr>
                      <td className="title">
                        <img
                          src="https://local-apl-bills.s3.us-east-2.amazonaws.com/ccos-log.png" alt="logo" width="130"
                          // src={logo}
                          // alt="CCOS"
                          // style={{ width: "100%", maxWidth: 300 }}
                        />
                      </td>
                      <td className="pdfDate">
                        Created: {today}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr className="information">
              <td colSpan={12}>
                <table>
                  <tbody>
                    <tr className="pdfHeader">
                      <td>
                        {`Account: ${account.accountNum}`}
                        <br />
                        {(account.firstName != null && account.lastName != null) ? account.firstName + ", " + account.lastName : account.lastName}
                        <br />
                        {(unit.address1 != null) ? unit.address1 + ", " + unit.address2 : unit.address1}
                        <br />
                        {`${unit.city}, ${unit.state} ${unit.zip}`}
                      </td>
                      <td>
                        Columbus & Central Ohio Systems, LLC
                        <br />
                        Account Activity History
                        <br />
                        Hours: Mon-Fri 8:30am-4:30pm
                        <br />
                        Phone: (614) 540-5408
                        <br />
                        {/*Emergencies: (877) 349-5441*/}
                        {/*<br />*/}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <td colSpan={12}>
              <tr className="Message">
                <td>About:</td>
              </tr>
              <tr className="details">
                <td>Listed are the account actions that took place on this account between {dateFormatter(account.created)} and {dateFormatter(moment())}</td>
              </tr>
              <tr className="details">
                <td>Current Account Balance:  {formatMoney(props.account.data.ongoingAccountBalance.balance)}</td>
              </tr>
            </td>
            <tr className="ledgerHeading">
              <td>Action #</td>
              <td className="left">Type</td>
              <td>Date</td>
              <td className="center">Amount</td>
              <td className="center">Balance</td>
              <td>Description</td>
            </tr>
            {entries.map((item, index) => {
              if (item.entryType == "Payment") {
                paymentAmount = paymentAmount + item.amount;
              }
              else if (item.entryType == "Bill") {
                billAmount = billAmount + item.amount;
              }
              if ((props.ledgerType == "Detailed" && item.entryType == "Bill") || (props.ledgerType == "Simple" && item.entryType == "BillDetail")) {
                return <></>
              }
              itemNumber++;
              return (
                <tr className="ledgerItem">
                  <td># {itemNumber}</td>
                  <td className="left">{item.entryType}</td>
                  <td>
                    {moment(item.transactionDate).format("MM/DD/YYYY")}
                  </td>
                  <td className="center">{formatMoney(item.amount)}</td>
                  <td className="center">{formatMoney(item.balance)}</td>
                  <td style={{ maxWidth: 300 }}>{item.description}</td>
                </tr>
              );
            })}
            <tr className="item last"></tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr className="total">
              <td />
              <td>
                <strong>{`Total Payments: ${formatMoney(paymentAmount)}`}</strong>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td>
                <strong>{`Total Billed: ${formatMoney(billAmount)}`}</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div >
    </div >
  );
};

// Component Properties
AccountLedgerPreview.propTypes = {
  // Store objects:
  user: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  ledgerType: PropTypes.string.isRequired,
};

// Component State
function AccountLedgerPreviewState(state) {
  return {
    user: state.user,
    apl: state.apl,
    account: state.account,
  };
}
export default connect(AccountLedgerPreviewState, {})(AccountLedgerPreview);
