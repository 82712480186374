import {
  Button as MuiButton,
  CircularProgress,
  FormControl as MuiFormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Loop as LoopIcon } from "@material-ui/icons";
import { spacing } from "@material-ui/system";
// Redux Components
import PropTypes from "prop-types";
import React from "react";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components/macro";
//import { updateSelectedAccount, updateCurrentAccountInDB } from '../../modules/account/api/actions';
import { setSnackbar } from "../../modules/snackbar/api/snackbar";

const Button = styled(MuiButton)(spacing);
const FormControl = styled(MuiFormControl)(spacing);

const DashboardToolBarButton = styled(Button)`
  padding: 4px;
  min-width: 0;
  svg {
    width: 2em;
    height: 2em;
  }
`;

function ToolBar(props) {
  const useStyles = makeStyles((theme) => {
    return {
      dropdown: {
        width: "14rem",
      },
      modalcontainer: {
        width: "60%",
        height: "60%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "15px",
        //padding:"3rem"
      },
      link: {
        textDecoration: "none",
        color: theme.palette.secondary.main,
        "&:hover": {
          fontWeight: "bold",
          textDecoration: "underline",
        },
      },

      rightSideHeader: {
        display: "flex",
        alignItems: "flex-end",
        flexDirection: "row",
        justifyContent: "flex-end",
      },
      searchLabel: {
        fontWeight: "bolder",
        fontSize: "110%",
        backgroundColor: "#f5f5f5",
      },

      leftSideHeader: {
        display: "flex",
        justifyContent: "left",
        alignItems: "left",
      },
      margin: {
        margin: theme.spacing(1),
      },
      selectButtonMargin: {
        margin: theme.spacing(1),
        width: "25%",
        fontWeight: "bolder",
        fontSize: "120%",
      },
      withoutLabel: {
        marginTop: theme.spacing(3),
      },
      textField: {
        width: "25ch",
      },
      searchSelectionText: {
        fontWeight: "bolder",
        fontSize: "220%",
      },
    };
  });
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleSaveClick = () => {
    dispatch(
      setSnackbar(true, "warning", "Saving Propert Manager Detail Changes")
    );
    dispatch(setSnackbar(true, "success", "Saving Successful"));
  };

  const handleDeleteClick = () => { };

  const handleSelectedPmAccountChange = (event) => { };

  return (
    <Grid item lg={12} className={classes.rightSideHeader} mb={1}>
      <FormControl fullWidth mr={6}>
        <InputLabel id="propertyToBillDropDownLabel">
          Other PM Accounts{"  "}
          {props.apl.isLoading && <CircularProgress size={15} m={0} />}
        </InputLabel>
        <Select
          labelId="propertyToBillDropDownLabel"
          id="propertyToBillDropDown"
          value={1}
          className={classes.selectedPropertyLocationLabel}
          onChange={handleSelectedPmAccountChange}
          fullWidth
          my={2}
        >
          <MenuItem value={1}>No Other PM Accounts Found</MenuItem>
          ]
        </Select>
      </FormControl>

      <DashboardToolBarButton
        size="small"
        mr={2}
        mb={1}
        onClick={props.handleRefreshClick}
      >
        <LoopIcon />
      </DashboardToolBarButton>
      <Button
        mb={1}
        variant="contained"
        color="primary"
        onClick={handleDeleteClick}
      >
        Transfer Accounts
      </Button>
    </Grid>
  );
}

// Component Properties
ToolBar.propTypes = {
  // Store objects:
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  userProfile: PropTypes.object.isRequired,
  // Store object functions:
  //updateCurrentAccountInDB: PropTypes.func.isRequired,
  //updateSelectedAccount: PropTypes.func.isRequired
};
// Component State
function ToolBarState(state) {
  return {
    user: state.user,
    apl: state.apl,
    account: state.account,
    userProfile: state.userProfile,
  };
}
export default connect(ToolBarState, {})(ToolBar);
