import { Button as MuiButton, Menu, MenuItem } from "@material-ui/core";
import { Loop as LoopIcon } from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import {
  getAllPropertyPreviews,
  getAnalyticalData,
} from "../../modules/apl/api/actions";

const Button = styled(MuiButton)(spacing);

const DashboardToolBarButton = styled(Button)`
  padding: 4px;
  min-width: 0;
  svg {
    width: 2em;
    height: 2em;
  }
`;

function AnalyticsRange_DD(props) {
  const [DataRangeType, setDataRangeType] = React.useState(
    props.apl.analyticRange
  );
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleDataRangeClickOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDataRangeClose = (newDataRange) => {
    setAnchorEl(null);
    if (newDataRange) {
      setDataRangeType(newDataRange);
      props.getAnalyticalData(newDataRange);
    }
  };

  return (
    <>
      <DashboardToolBarButton
        size="small"
        mr={2}
        onClick={() => props.getAnalyticalData(DataRangeType)}
      >
        <LoopIcon />
      </DashboardToolBarButton>
      <Button
        variant="contained"
        color="secondary"
        aria-owns={anchorEl ? "simple-menu" : undefined}
        aria-haspopup="true"
        onClick={handleDataRangeClickOpen}
      >
        Data From: {DataRangeType}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleDataRangeClose}
      >
        <MenuItem onClick={() => handleDataRangeClose("Today")}>Today</MenuItem>
        <MenuItem onClick={() => handleDataRangeClose("Yesterday")}>
          Yesterday
        </MenuItem>
        <MenuItem onClick={() => handleDataRangeClose("Last 7 days")}>
          Last 7 days
        </MenuItem>
        <MenuItem onClick={() => handleDataRangeClose("Last 30 days")}>
          Last 30 days
        </MenuItem>
        <MenuItem onClick={() => handleDataRangeClose("This Month")}>
          This Month
        </MenuItem>
        <MenuItem onClick={() => handleDataRangeClose("Last Month")}>
          Last Month
        </MenuItem>
        <MenuItem onClick={() => handleDataRangeClose("This Year")}>
          This Year
        </MenuItem>
        <MenuItem onClick={() => handleDataRangeClose("Forever")}>
          Forever
        </MenuItem>
      </Menu>
    </>
  );
}

// Component Properties
AnalyticsRange_DD.propTypes = {
  // Store objects:
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  getAnalyticalData: PropTypes.func.isRequired,
  getAllPropertyPreviews: PropTypes.func.isRequired,
};

// Component State
function AnalyticsRange_DD_State(state) {
  return {
    user: state.user,
    apl: state.apl,
  };
}
export default connect(AnalyticsRange_DD_State, {
  getAnalyticalData,
  getAllPropertyPreviews,
})(AnalyticsRange_DD);
