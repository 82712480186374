//React Components and Hooks
import {
  Card as MuiCard,
  CardContent,
  CircularProgress as MuiCircularProgress,
  Divider as MuiDivider,
  Grid,
  LinearProgress,
  Typography as MuiTypography,
} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import { spacing } from "@material-ui/system";
//Material UI Components and Functions
// Redux Components
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import SimpleStatCard from "../../components/cards/SimpleStatCard"; // The stat cards displaying simple analytic numbers
import StandardHeader from "../../components/global/StandardHeader";
// Custom application component imports:
import { LeftSidebar as NavFrame } from "layout-blueprints"; // The top navigation bar and side navigation panel
import { getBillingBatchById } from "../../modules/billing/api/actions";
import BatchBillsTable from "../../modules/billing/components/BatchBillsTable";
import { logout } from "../../modules/user/api/actions";
import AnalyticsRange_DD from "./AnalyticsRange_DD"; // Dropdown to set the scope of the analytics data
import _ from 'lodash'
const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);
const Card = styled(MuiCard)(spacing);


const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  percentDif: {
    textAlign: "right",
    color: "green",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  loadingIcon: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  titleText: {
    marginBottom: "5px",
  },
}));

const Batches = (props) => {
  const classes = useStyles();
  const [stats, setStats] = useState({
    printed: 0,
    emailed: 0,
    failed: 0,
    total: 0,
    complete: 0,
  });

  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor:
        theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: "#f9a825",
    },
  }))(LinearProgress);

  const logout = () => {
    props.logout();
  };

  const getCompanyBillingDataAndRefreshPage = (isLoading = true) => {
    //setIsLoaded(false);
    props.getBillingBatchById(props.match.params.id, isLoading);
  };

  const handleClose = (event, reason) => { };
  // On initial page load:
  // my apologies to anyone in the future who has to see this
  useEffect(() => {
    getCompanyBillingDataAndRefreshPage();
    const interval = setInterval(() => {
      var completeCount = "N/A";
      if (isError || props.billing.data.selectedBatch.items.length > 0) {
        const bills = props.billing.data.selectedBatch.items;
        emailCount = 0;
        printCount = 0;
        completeCount = 0;
        bills.map((bill) => {
          if (bill.email != null) {
            completeCount = completeCount + parseInt(bill.email);
          }
        });
      }
      if (completeCount < props.billing.data.selectedBatch.items.length) {
        getCompanyBillingDataAndRefreshPage(false);
      }
    }, 5000);
    return () => clearInterval(interval);
  }, []);
  var isLoaded =
    props.billing.billingBatchDataIsLoading ||
    props.billing.data.selectedBatch.items.length > 0;
  const isError = props.billing.error != null && props.billing.error != "";

  if (props.billing.data.selectedBatch?.batchSetStatus?.jobs?.length > 0)
    var StatusMessage = `Processing Generated Billing Procedure: ${JSON.stringify(props.billing.data.selectedBatch.batchSetStatus.jobs[0].status)}`;
  else
    StatusMessage = `Processing Generated Billing Procedure`
  if (props.billing.data.selectedBatch.items.length > 0) {
    StatusMessage = "Running Generated Billing Work Items";
  }

  var emailCount = "N/A";
  var printCount = "N/A";
  var completeCount = "N/A";
  var failedCount = "N/A";
  if (isError || props.billing.data.selectedBatch.items.length > 0) {
    const bills = props.billing.data.selectedBatch.items;
    emailCount = 0;
    printCount = 0;
    completeCount = 0;
    failedCount = 0;
    bills.map((bill) => {
      if (bill.email != null) {
        emailCount = emailCount + parseInt(bill.email);
      }
      if (bill.posted != null) {
        completeCount = completeCount + parseInt(bill.posted);
      }
      if (bill.printed != null && bill.print != "NO") {
        printCount = printCount + parseInt(bill.printed);
      }
      if (bill.accountBillId == 0){
        failedCount++;
      }
    });
  }

  document.title = "Batch Billing";
  // On Data Loaded:
  return (
    <NavFrame page={"Batches"}>
      <StandardHeader
        title="Property Billing Batch"
        rightSideToolBar={
          <AnalyticsRange_DD
            handleRefreshClick={getCompanyBillingDataAndRefreshPage}
          />
        }
      />
      <Divider className="mb-3 mt-3" />
      {(props.billing.error != "" && props.billing.error != null) &&
        <Snackbar
          open={true}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <MuiAlert elevation={6} severity="error" onClose={handleClose}>
            <Typography>Error - {props.billing.error}</Typography>
          </MuiAlert>
        </Snackbar>
      }
      <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Card mb={3} elevation={3}>
              <CardContent>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography
                      variant="h4"
                      color="textPrimary"
                      className={classes.titleText}
                    >
                      {StatusMessage}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        {props.billing.data.selectedBatch.items.length > 0 && (
          <>
            <Grid item xs={12} sm={12} md={6} lg={3}>
              <SimpleStatCard
                title="Total Bills"
                amount={
                  props.billing.data.selectedBatch.items.length.toString() ||
                  "N/A"
                }
                isLoaded={isLoaded}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3}>
              <SimpleStatCard
                title="Printed"
                amount={printCount.toString()}
                isLoaded={isLoaded}
              />
              {/* <LinearProgress variant="determinate" value={(printCount/props.billing.data.selectedBatch.items.length*100)} /> */}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3}>
              <SimpleStatCard
                title="Emailed"
                amount={emailCount.toString()}
                isLoaded={isLoaded}
              />
              {/* <LinearProgress variant="determinate" value={(emailCount/props.billing.data.selectedBatch.items.length*100)} /> */}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3}>
              <SimpleStatCard
                title="Failed"
                amount={failedCount.toString()}
                isLoaded={isLoaded}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <BatchBillsTable />
            </Grid>
          </>
        )}
      </Grid>
    </NavFrame>
  );
};

// Component Properties
Batches.propTypes = {
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  billing: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  getBillingBatchById: PropTypes.func.isRequired,
};

// Component State
function BatchesPageState(state) {
  return {
    user: state.user,
    apl: state.apl,
    billing: state.billing,
  };
}
export default connect(BatchesPageState, { logout, getBillingBatchById })(
  Batches
);
