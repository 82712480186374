import { Button as MuiButton, Menu, MenuItem } from "@material-ui/core";
import { Loop as LoopIcon } from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import React from "react";
import styled from "styled-components/macro";
// Helper methods to format current date:
import { getCurrentDay_MonthDay } from "../../setup/helpers";

const Button = styled(MuiButton)(spacing);

const DashboardToolBarButton = styled(Button)`
  padding: 4px;
  min-width: 0;
  svg {
    width: 2em;
    height: 2em;
  }
`;

function AnalyticsRange_DD({ handleRefreshClick }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <DashboardToolBarButton size="small" mr={2} onClick={handleRefreshClick}>
        <LoopIcon />
      </DashboardToolBarButton>
      <Button
        variant="contained"
        color="secondary"
        aria-owns={anchorEl ? "simple-menu" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        Today: {getCurrentDay_MonthDay()}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>Today</MenuItem>
        <MenuItem onClick={handleClose}>Yesterday</MenuItem>
        <MenuItem onClick={handleClose}>Last 7 days</MenuItem>
        <MenuItem onClick={handleClose}>Last 30 days</MenuItem>
        <MenuItem onClick={handleClose}>This month</MenuItem>
        <MenuItem onClick={handleClose}>Last month</MenuItem>
      </Menu>
    </>
  );
}

export default AnalyticsRange_DD;
