// App Imports
import {
  NOTE_ADD_NEW_NOTE,
  NOTE_ADD_NEW_NOTE_REPLY,
  NOTE_GET_ACCOUNT_NOTES_REQUEST,
  NOTE_GET_ACCOUNT_NOTES_RESPONSE,
  NOTE_GET_OPEN_NOTES_REQUEST,
  NOTE_GET_OPEN_NOTES_RESPONSE,
  NOTE_NEW_NOTE_REPLY_REQUEST,
  NOTE_NEW_NOTE_REPLY_RESPONSE,
  NOTE_NEW_NOTE_REQUEST,
  NOTE_NEW_NOTE_RESPONSE,
  NOTE_UPDATE_NOTE_REPLY_REQUEST,
  NOTE_UPDATE_NOTE_REPLY_RESPONSE,
  NOTE_UPDATE_NOTE_REQUEST,
  NOTE_UPDATE_NOTE_RESPONSE,
  SET_NOTE_LIST,
} from "./actions";

const defaultDateValue = "01-01-1900";
// Initial State
const notesInitialState = {
  isLoading: true,
  error: null,
  data: [],
  searchQuery: null,
  range: { start: 0, end: 25 },
  dueNotes: {
    items: [],
    toDelete: [],
    toUpdate: [],
  },
  dueNotesSummary: null,
  defaultDateValue: defaultDateValue,
  newNote: {
    note: "",
    dueDate: "",
    noteTopicId: 34, // Defaults to general note
    accountId: -1,
  },
  newNoteReply: {
    reply: "",
    noteId: -1,
  },
};

// State
export default (state = notesInitialState, action) => {
  switch (action.type) {
    case SET_NOTE_LIST:
      return {
        ...state,
        data: action.data,
      };
    case NOTE_GET_OPEN_NOTES_REQUEST:
      if (action.isReset) {
        state.dueNotes.items = [];
      }
      return {
        ...state,
        error: null,
        dueNotes: state.dueNotes,
        isLoading: action.isLoading,
      };
    case NOTE_GET_OPEN_NOTES_RESPONSE:
      //state.dueNotes.items = action.accountDisconnects.items;
      if (action.isReset) {
        state.dueNotes.items = action.dueNotes.items;
      } else {
        action.dueNotes.items.map((item) => {
          state.dueNotes.items.push(item);
        });
      }
      return {
        ...state,
        dueNotes: state.dueNotes,
        dueNotesSummary: action.dueNotes.summary,
        error: action.error,
        isLoading: false,
      };
    case NOTE_GET_ACCOUNT_NOTES_REQUEST:
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
      };
    case NOTE_GET_ACCOUNT_NOTES_RESPONSE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case NOTE_NEW_NOTE_REPLY_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case NOTE_NEW_NOTE_REPLY_RESPONSE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case NOTE_NEW_NOTE_REQUEST:
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
      };
    case NOTE_NEW_NOTE_RESPONSE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case NOTE_ADD_NEW_NOTE:
      if (action.newNote) {
        state.data.unshift(action.newNote);
      }
      return {
        ...state,
        data: state.data,
      };
    case NOTE_ADD_NEW_NOTE_REPLY:
      if (action.newNoteReply) {
        const currentNoteID = state.data.findIndex(
          (note) => note.id === action.newNoteReply.noteId
        );
        if (currentNoteID != -1) {
          state.data[currentNoteID].replys.push(action.newNoteReply);
        }
      }
      return {
        ...state,
        isLoading: action.isLoading,
        data: state.data,
      };
    case NOTE_UPDATE_NOTE_REQUEST:
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
      };
    case NOTE_UPDATE_NOTE_REPLY_RESPONSE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case NOTE_UPDATE_NOTE_REPLY_REQUEST:
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
      };
    case NOTE_UPDATE_NOTE_RESPONSE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
