import {
  Card as MuiCard,
  CircularProgress as MuiCircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  TextField as MuiTextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Search as SearchIcon } from "@material-ui/icons";
import { spacing } from "@material-ui/system";
// Redux Components
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components/macro";
import { EditableTable, newOnFieldChangeMethod, newDropDownField} from "../../../components/EditableTable";
import { deleteAccountPaymentPlan, getAccountPaymentPlans, updateAccountPaymentPlan, createAccountPaymentPlan, updateAccountPaymentPlansItemsState } from "../../payments/api/actions";
import { getAllPaymentPlanStatuses } from "../../apl/api/actions"
import moment from "moment";

import { setSnackbar } from "../../snackbar/api/snackbar";

const CircularProgress = styled(MuiCircularProgress)(spacing);
const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function Table(props) {

  const createBlankLineItem = (id, startingDate, planAmount, numberOfPayments) => {
    return { id, startingDate: "", planAmount, numberOfPayments };
  }
  const useStyles = makeStyles({
    active: {
      fontWeight: "bold",
      color: "#f9a825",
      borderColor: "#f9a825",
    },
    inactive: {
      fontWeight: "bold",
    },
  });

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchQuery, setSearchQuery] = React.useState(""); 
  const [property, setProperty] = React.useState("");
  const [resetCachedData, setResetCachedData] = React.useState(true);

  const dispatch = useDispatch();

  const [billFormOpen, setBillFormOpen] = React.useState(false);

  const createNewPaymentPlan = () => {
  };

  const CurrentLookupTypeButton = () => {
    return (
      <IconButton>
        <SearchIcon mr={2} />
      </IconButton>
    );
  };



  // Table Stuff:
  const fieldTitles = [
    "Plan Start",
    "Past Due",
    "# Of Payments",
    "Each Payment",
    "Status"
  ];

  const fieldTypes = [
    "dateField",
    "number",
    "number",
    "moneyLabel",
    "dropDown",
  ];

  const fieldNames = [
    "startingDate",
    "planAmount",
    "numberOfPayments",
    "paymentValue",
    "paymentPlanStatus"
  ];


  const paymentPlanChange = (item) => {
    let status = props.apl.genericTypes.paymentPlanStatus.data.filter(plan => {
      return item.paymentPlanStatus == plan.description
    })
    item.paymentPlanStatusId = status[0].id;
    return item;
  }

  const resourceFields = [
    newDropDownField(
      "paymentPlanStatus",
      props.apl.genericTypes.paymentPlanStatus.data || [],
      "description",
      "description",
      paymentPlanChange      
    ),
  ];

  const deleteSelectedAccountPaymentPlans = (paymentPlans) => {
    paymentPlans.map((paymentPlan, index) => {
      props.deleteAccountPaymentPlan(paymentPlan);
    });
  };

  const addBlankItemToArray = () => {
    props.payments.paymentPlans.items.push(createBlankLineItem(-1));
    props.updateAccountPaymentPlansItemsState(props.payments.paymentPlans);
  }


  const getSelectedAccountPaymentPlans = (accountUuid) => {
    props.getAccountPaymentPlans(accountUuid);
  };

  useEffect(() => {
    if (!props.account.isLoading && props.account.data.account.id) {
      props.getAllPaymentPlanStatuses()
      getSelectedAccountPaymentPlans(props.account.data.account.uuid);
    }
  }, [props.account.isLoading]);

  const urlFields = [];

  const conditionalStyling = (item) => {
    item.paymentValue = item.planAmount / (item.numberOfPayments != 0 ? item.numberOfPayments : 1);
    return {};
  };

  const calculateLineValues = (item) => {
    item.paymentValue = item.planAmount / (item.numberOfPayments != 0 ? item.numberOfPayments : 1);
    return item;
  }

  const startingDateChange = (item) => {
    item.startingDate = item.startingDate
    return item;
  }

  // To be used to save over the api in the database
  const saveAllLineItems = async (paymentPlans) => {
    if (paymentPlans.toUpdate.length > 0) {
      dispatch(setSnackbar(true, "warning", "Updating Account Payment Plans"));
    }
    paymentPlans.items.map((paymentPlan) => {

      if(paymentPlans.toUpdate.length > 0) {
        let targetItemIndex = paymentPlans.items.findIndex(
          (x) => x.uuid === paymentPlan.uuid
        );
        if (targetItemIndex != -1) {
          let status = props.apl.genericTypes.paymentPlanStatus.data.filter(plan => {
            return paymentPlans.items[targetItemIndex].paymentPlanStatusId == plan.id
          })

          let payload = {
            numberOfPayments: paymentPlans.items[targetItemIndex].numberOfPayments,
            planAmount: paymentPlans.items[targetItemIndex].planAmount,
            startingDate: paymentPlans.items[targetItemIndex].startingDate,
            paymentPlanStatusId: status[0].id 
          }

          props.updateAccountPaymentPlan(payload,paymentPlans.items[targetItemIndex].uuid)
            .then((response) => {
              if(response?.uuid) {
                dispatch(setSnackbar(true, "success", "Payment Plan updated successfully"));
                props.getAccountPaymentPlans(props.account.data.account.uuid)
              }
              else {
                dispatch(setSnackbar(true, "error", "Payment Plan not updated"));
              }
            });

        }
      }
      else {
        if(paymentPlan.id == -1) {

          let status = props.apl.genericTypes.paymentPlanStatus.data.filter(plan => {
            return paymentPlan.paymentPlanStatus == plan.description
          })

          let payload = {
            numberOfPayments: paymentPlan.numberOfPayments,
            planAmount: paymentPlan.planAmount,
            startingDate: paymentPlan.startingDate,
            paymentPlanStatusId: status[0].id 
          }
          props.createAccountPaymentPlan(payload, props.account.data.account.uuid).then((response) => {
            if(response?.uuid) {
              dispatch(setSnackbar(true, "success", "Payment Plan created successfully"));
              props.getAccountPaymentPlans(props.account.data.account.uuid)
            }
            else {
              dispatch(setSnackbar(true, "error", "Payment Plan not created"));
            }
          });

        }
      }
    });

    
    paymentPlans.toDelete.map((targetUuid) => {
      props.deleteAccountPaymentPlan(targetUuid);
    });


  };
  const onChangeFields = [
    newOnFieldChangeMethod("planAmount", calculateLineValues), 
    newOnFieldChangeMethod("numberOfPayments", calculateLineValues),
    newOnFieldChangeMethod("startingDate", startingDateChange),
    newOnFieldChangeMethod("paymentPlanStatus", paymentPlanChange),


  ];

  return (
    <>
      <EditableTable
        readOnly={false}
        canAddItems={true}
        canSave={true}
        canDelete={true}
        manualItemDeletionMethod={deleteSelectedAccountPaymentPlans}
        conditionalStylingMethod={conditionalStyling}
        isLoading={props.payments.accountPaymentPlansIsLoading}
        tableTitle={"Current Payment Plans"}
        objectArray={props.payments.paymentPlans}
        saveCurrentTableData={saveAllLineItems}
        dropDownFields={resourceFields}
        fieldTitles={fieldTitles}
        fieldTypes={fieldTypes}
        fieldNames={fieldNames}
        customOnChangeFields={onChangeFields}
        canExport={true}
        objectError={props.payments.accountPaymentPlansItemsError}
        customUrlFields={urlFields}
        newItemCreationMethod={addBlankItemToArray}
        updateItemsLocalState={props.updateAccountPaymentPlansItemsState}
      />
    </>
  );
}
Table.propTypes = {
  // Store objects:
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  bills: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  payments: PropTypes.object.isRequired,
  getAllPaymentPlanStatuses: PropTypes.func.isRequired,
  getAccountPaymentPlans: PropTypes.func.isRequired,
  deleteAccountPaymentPlan: PropTypes.func.isRequired,
  updateAccountPaymentPlan: PropTypes.func.isRequired,
  createAccountPaymentPlan: PropTypes.func.isRequired,
  updateAccountPaymentPlansItemsState: PropTypes.func.isRequired,

};

// Component State
function State(state) {
  return {
    user: state.user,
    apl: state.apl,
    bills: state.bills,
    account: state.account,
    payments: state.payments,
  };
}
export default connect(State, {getAllPaymentPlanStatuses, getAccountPaymentPlans, deleteAccountPaymentPlan, updateAccountPaymentPlan, createAccountPaymentPlan, updateAccountPaymentPlansItemsState })(Table);
