//React Components and Hooks
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
//Material UI Functions
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { Link } from "react-router-dom";
//Custom Components
//Context

//Styles
const useStyles = makeStyles((theme) => {
  return {
    link: {
      textDecoration: "none",
      color: theme.palette.secondary.main,
      "&:hover": {
        fontWeight: "bold",
        textDecoration: "underline",
      },
    },
  };
});

const UbilBreadCrumb = (props) => {
  const classes = useStyles();
  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Link className={classes.link} to="/dashboard">
        UBIL +
      </Link>
      {props.crumbs
        ? props.crumbs.map((crumb) => (
            <Link className={classes.link} to={crumb.link}>
              {crumb.label}
            </Link>
          ))
        : ""}
      <Typography>{props.current}</Typography>
    </Breadcrumbs>
  );
};

export default UbilBreadCrumb;
