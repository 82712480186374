import LinearProgress from "@mui/material/LinearProgress";
import { createTheme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import {
  DataGridPro,
  GridOverlay,
  GridToolbar,
  LicenseInfo,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid-pro";
import axios from "axios";
import _ from "lodash";
import * as React from "react";
import { Button, Grid } from "@material-ui/core";
import { setSnackbar } from "../../modules/snackbar/api/snackbar";
import Filter from "./Filter";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import moment from 'moment'
LicenseInfo.setLicenseKey(
  process.env.REACT_APP_MUX
);

const Reports = (props) => {
  const [data, setData] = React.useState({ rows: [], columns: [] });
  const [report, setReport] = React.useState("");
  const [fileName, setFileName] = React.useState("");
  const [validate, setValidate] = React.useState(false);
  const [params, setParams] = React.useState({
    property: "",
    startDate: "",
    endDate: "",
    yearsBack: "",
    paymentPlan: "",
    customerStatus: "",
  });
  const [filter, setFilter] = React.useState({
    property: 0,
    startDate: 0,
    endDate: 0,
    yearsBack: 0,
    paymentPlan: 0,
    customerStatus: 0,
  });

  const [paging, setPaging] = React.useState({
    usePaging: false,
    pageNum: 0,
    recordsPerPage: 100,
  });

  const [loading, setLoading] = React.useState(false);
  const defaultTheme = createTheme();
  const dispatch = useDispatch();
  const useStyles = makeStyles(
    (theme) =>
      createStyles({
        root: {
          flexDirection: "column",
          "& .ant-empty-img-1": {
            fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
          },
          "& .ant-empty-img-2": {
            fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
          },
          "& .ant-empty-img-3": {
            fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
          },
          "& .ant-empty-img-4": {
            fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
          },
          "& .ant-empty-img-5": {
            fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
            fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
          },
        },
        label: {
          marginTop: theme.spacing(1),
        },
        title: {
          flexGrow: 1,
        },
        percentDif: {
          textAlign: "right",
          color: "green",
        },
        backdrop: {
          zIndex: theme.zIndex.drawer + 1,
          color: "#fff",
        },
        loadingIcon: {
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        },
        padding: {
          paddingTop: 10,
        },
      }),
    { defaultTheme }
  );

  let callArray = [];

  React.useEffect(() => {
    (async () => {})();
  }, [report, params]);

  const handleClick = async (isEmail) => {
    setLoading(true);
    let formated = [];
    //Check on if requried fields have data before api call
    //Loop through the filters
    //On >= 1, push to params value index-1 of params array;
    let requiredIndex = 0;
    for (const param in params) {
      if (filter[param] > requiredIndex) requiredIndex = filter[param];
      if (filter[param] > 0 && params[param] != "") {
        //push to call array
        callArray[filter[param] - 1] = params[param];
      }
    }

    //Paging requireds two more params
    if (paging.usePaging) {
      callArray.push(null);
      callArray.push(null);
      requiredIndex++;
      requiredIndex++;
    }

    let caseChange = true;
    if(report == "RouteMap")
      caseChange = false;
    try {
      if (report != "" && callArray.length == requiredIndex) {
        if(!isEmail) {
          const response = await axios.put(`/report/call`, {
            function: report,
            params: callArray,
          });
          if (response.status == 200 && response.data[0].length > 0) {
            formated = formatData(response.data[0], caseChange);
            setData(formated);


            //Set file name for export

            if(report == `RouteMap`) {
              //get propertyID
              let propertyId = response.data[0][0]?.[ `WorkSet.WorkSetID`] || '0';
              setFileName(`routeE${propertyId}.exp`)
            }
            else{
              setFileName(`${report}-${moment().format('YYYY-MM-DD')}.exp`)
            }

            setLoading(false);
          } else {
            setData({ rows: [], columns: [] });
            dispatch(setSnackbar(true, "warning", "No records found"));
            setLoading(false);
          }
        }
        else {
          //EMAIL
          const userEmail = props.user.details.email;
          let emailResponse = await axios.put(`/report/call`, {
            function: report,
            params: callArray,
            email: userEmail
          });
          if (emailResponse.status == 200) {
            dispatch(setSnackbar(true, "success", `Request for email report sent to: ${userEmail}`));
            setParams({
              property: "",
              startDate: "",
              endDate: "",
              yearsBack: "",
              customerStatus: "",
              paymentPlan: "",
            });
            setLoading(false);
            setData({ rows: [], columns: [] });

          } else {
            setData({ rows: [], columns: [] });
            dispatch(setSnackbar(true, "warning", "Unable to send request for email. Please try again"));
            setLoading(false);
          }
        }
      } 
      else {
        setData({ rows: [], columns: [] });
        setLoading(false);
      }
    } catch (e) {
      setData({ rows: [], columns: [] });
      setLoading(false);
      dispatch(setSnackbar(true, "error", "Error retrieving data."));
    }
  };

  const handleChange = (event) => {
    const reportInfo = event.target.value;
    if (event.target.name == "report") {
      //Reset state vars and call array
      setReport(reportInfo.call);
      setParams({
        property: "",
        startDate: "",
        endDate: "",
        yearsBack: "",
        customerStatus: "",
        paymentPlan: "",
      });
      setFilter({
        property: reportInfo.property,
        startDate: reportInfo.startDate,
        endDate: reportInfo.endDate,
        yearsBack: reportInfo.yearsBack,
        customerStatus: reportInfo.customerStatus,
        paymentPlan: reportInfo.paymentPlan,
      });

      setPaging({
        usePaging: reportInfo.paging,
        pageNum: 1,
        recordsPerPage: 100,
      });

      callArray = [];
    } else {
      setParams({ ...params, [event.target.name]: event.target.value });
    }
    //document.title = `CCOS Report: ${_.capitalize(event.target.value)} - ${moment().format("MM/DD/YYYY")}`
  };

  const formatData = (datas, caseChange) => {
    const keys = Object.keys(datas[0]);
    let output = keys.map((key) => {
      return {
        field: key,
        editable: false,
        width: 200,
        headerName: (caseChange) ? _.startCase(key) : key
      };
    });

    datas.forEach(function (element, index) {
      element.id = index + 1;
    });
    return { rows: datas, columns: output };
  };

  function CustomLoadingOverlay() {
    return (
      <GridOverlay>
        <div style={{ position: "absolute", top: 0, width: "100%" }}>
          <LinearProgress />
        </div>
      </GridOverlay>
    );
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport
          printOptions={{
           disableToolbarButton: true 
          }}
          csvOptions={{
            fileName: fileName,
            utf8WithBom: true,
          }}
        />
      </GridToolbarContainer>
    );
  }

  function CustomNoRowsOverlay() {
    const classes = useStyles();

    return (
      <GridOverlay className={classes.root}>
        <svg
          width="120"
          height="100"
          viewBox="0 0 184 152"
          aria-hidden
          focusable="false"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(24 31.67)">
              <ellipse
                className="ant-empty-img-5"
                cx="67.797"
                cy="106.89"
                rx="67.797"
                ry="12.668"
              />
              <path
                className="ant-empty-img-1"
                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
              />
              <path
                className="ant-empty-img-2"
                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
              />
              <path
                className="ant-empty-img-3"
                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
              />
            </g>
            <path
              className="ant-empty-img-3"
              d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
            />
            <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
              <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
              <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
            </g>
          </g>
        </svg>
        <div className={classes.label}>No Rows</div>
      </GridOverlay>
    );
  }

  return (
<div>

      <Grid container spacing={2}>
        <Grid item xs={8}>
        <Filter handleChange={handleChange} filter={filter} params={params} />
        </Grid>
        <Grid item xs={4}>
        <Button
        variant="contained"
        color="secondary"
        onClick={() => {
          handleClick(true);
        }}
      >
        Email Report
      </Button>
        </Grid>
        <Grid item xs={4}>
        <Button
        variant="contained"
        color="primary"
        onClick={() => {
          handleClick(false);
        }}
      >
        Get Data
      </Button>{" "}
        </Grid>
        <Grid item xs={12}>
        <div style={{ height: 850, width: "100%" }}>

        <DataGridPro
        {...data}
        pagination
        loading={data.rows.length === 0}
        rowHeight={38}
        disableSelectionOnClick
        loading={loading}
        onCellClick={(rowData) => console.log(rowData)}
        components={{
          Toolbar: CustomToolbar,
          LoadingOverlay: CustomLoadingOverlay,
          NoRowsOverlay: CustomNoRowsOverlay,
        }}
      />
      </div>
        </Grid>
      </Grid>




     
     
    </div>
  );
}

Reports.propTypes = {
  user: PropTypes.object.isRequired,
};

function ReportsState(state) {
  return {
  user: state.user,
  };
}

export default connect(ReportsState, {})(Reports)