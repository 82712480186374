import {
  Avatar,
  Box,
  Button as MuiButton,
  Card,
  CardContent,
  Divider as MuiDivider,
  Grid,
  LinearProgress as MuiLinearProgress,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import ChatIcon from "@material-ui/icons/Chat";
import MailIcon from "@material-ui/icons/Mail";
import PermScanWifiIcon from "@material-ui/icons/PermScanWifi";
import SettingsIcon from "@material-ui/icons/Settings";
import Skeleton from "@material-ui/lab/Skeleton";
import { spacing } from "@material-ui/system";
// Redux Components
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components/macro";
import { updateUserPasswordEmailNotifier, updateUserEmail , sendTemporaryPassword} from "../../../modules/userProfiles/api/actions";
import { dateFormatter, formatPhoneNumber } from "../../../setup/helpers";
// snackbar
import { setSnackbar } from "../../snackbar/api/snackbar";
import axios from "axios"

const LinearProgress = MuiLinearProgress;
const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledNavMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);


const AccountUserDetailsCard = (props) => {
  if(props.UserProfile) {
    props.userProfiles.selectedUserProfile = props.UserProfile 
  }
  const useStyles = makeStyles((theme) => ({
    title: {
      flexGrow: 1,
    },
    percentDif: {
      textAlign: "right",
      color: "green",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    loadingIcon: {
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
    },
    StatsIcon: {
      width: "32px",
      height: "32px",
      color: theme.palette.secondary.main,
      position: "absolute",
      right: "16px",
      top: "32px",
    },
    rightSideHeader: {
      display: "flex",
      alignItems: "flex-end",
      flexDirection: "row",
      justifyContent: "flex-end",
    },
    rightSideHeaderSubMenuToolBar: {
      display: "flex",
      alignItems: "flex-end",
      flexDirection: "row",
      justifyContent: "flex-end",
      margin: "0px",
      padding: "0px",
    },
    SubMenuToolBarIcon: {
      width: "25px",
      height: "25px",
      margin: "2px",
      paddingBotom: "5px",
    },
    orange: {
      //color: theme.palette.getContrastText(deepOrange[500]),
    },
    UserBoxText: {
      textAlign: "center",
    },
    UserProfileDetailsButton: {
      padding: "5px",
    },
    AvatarText: {
      marginTop: "20%",
      height: "128px",
      width: "128px",
      textAlign: "center",
      alignSelf: "center",
    },
    AvatarIcon: {
      display: "inline-block",
      height: "128px",
      width: "128px",
      backgroundColor: "gray",
      borderColor: theme.palette.primary.main,
      borderStyle: "solid",
    },
    ChartWrapper: {
      height: "200px",
      maxHeight: "200px",
      minHeight: "200px",
    },
    AccountInfoGrid: {
      height: "400px",
      maxHeight: "400px",
      minHeight: "400px",
    },
  }));

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [emailDialogOpen, setEmailDialogOpen] = React.useState(false);
  const [tempPasswordDialogOpen, setTempPasswordDialogOpen] = React.useState(false);
  const [tempPassword, setTempPassword] = React.useState("");
  const [newUserEmail, setNewUserEmail] = React.useState(null);

  const dispatch = useDispatch();

  const handleUserProfileEditingDropDownClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const setUserEmail = (event) =>
 {
    setNewUserEmail(event.currentTarget.value);
 }
  const handleSendPasswordResetUpdate = () => {
    if (props.userProfiles.selectedUserProfile && props.userProfiles.selectedUserProfile != null) {
      dispatch(setSnackbar(true, "warning", "Sending Password Reset Email"));
      props.updateUserPasswordEmailNotifier(props.userProfiles.selectedUserProfile.email).then(() => {
        dispatch(setSnackbar(true, "success", "Password Reset Email Sent"));
      });
    }
  };
  const handleUpdateAccountEmail = () => {
    if (props.userProfiles.selectedUserProfile && props.userProfiles.selectedUserProfile!= null) {
      dispatch(setSnackbar(true, "warning", "Updating Account Email"));
    

        let updateEmailPayload = {

          email:newUserEmail,
          uuid: props.userProfiles.selectedUserProfile.uuid
        
      }

      props.updateUserEmail(updateEmailPayload).then(() => {
        setEmailDialogOpen(false);
        if(!props.userProfiles.error){
          props.userProfiles.selectedUserProfile.email = updateEmailPayload.email;
        }
      })
    }
    else{
      dispatch(setSnackbar(true, "error", "No user profile"))
    }

  }
  const generateRandomPassword = () => {

    let array = new Uint32Array(6);

    window.crypto.getRandomValues(array);
    let tempPass = "CCOS_"+Array.from(array, dec => ('0' + dec.toString(16)).substr(-2)).join('');

    return tempPass.substring(0, 9);
}   
  const handleDialogOpen = () => {
    setEmailDialogOpen(true);
  }
  const handleDialogClose = () => {
    setEmailDialogOpen(false);
  }

  const handleSendTemporaryPass = () => {
    if (props.userProfiles.selectedUserProfile) {
      dispatch(setSnackbar(true, "warning", "Updating Account Email"));
    

        let sendTempPassPayload = {

          password:tempPassword,
          uuid: props.userProfiles.selectedUserProfile.uuid
        
      }
      props.sendTemporaryPassword(sendTempPassPayload).then(setTempPasswordDialogOpen(false));
  }

    else{
      dispatch(setSnackbar(true, "error", "No user profile"))
    }
}


  const handleTempPasswordDialogOpen = () => {
    let tempPassword = generateRandomPassword();

    setTempPassword(tempPassword);
    setTempPasswordDialogOpen(true);


  }
  const handleTempPasswordDialogClose = () => {
    setTempPasswordDialogOpen(false);
  }

  const handleSendAccountInvitation = () => {
    //Must check if it is a PM
    dispatch(setSnackbar(true, "warning", "Sending Account Invtiation Email"));
    if(props.isPm == true){
      axios.post("accountcreation/invitation/pm", {
        pm: {
          uuid: props.propertyManagers.data.propertyManager.uuid,
          name: props.propertyManagers.data.propertyManager.name,
          email: props.propertyManagers.data.propertyManager.email
        }
      }).then(response => {
        if (response.status == 200) {
          dispatch(setSnackbar(true, "success", "Invitation sent."));
        }
        else
          throw Error;
      }).catch(error => {
        dispatch(setSnackbar(true, "error", "Invitation unable to send."));
  
      })
    }else {
      
      axios.post("accountcreation/invitation", {
        accountUuid: props.account.data.account.uuid,
        accountNum: props.account.data.account.accountNum,
        name: `${props.account.data.account.firstName} ${props.account.data.account.lastName}`,
        email: props.account.data.account.email,
      }).then(response => {
        if (response.status == 200) {
          dispatch(setSnackbar(true, "success", "Invitation sent."));
        }
        else
          throw Error;
      }).catch(error => {
        dispatch(setSnackbar(true, "error", "Invitation unable to send."));
  
      })
    }


  };
  const handleUserProfileEditingDropDownClose = () => {
    setAnchorEl(null);
  };

  return (
    <Card mb={6}>
      <CardContent xs={12} sm={12} md={12} lg={12}>
      <Dialog open={emailDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Update Email</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the new email address
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="userEmail"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            onChange={setUserEmail}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleUpdateAccountEmail}>Submit</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={tempPasswordDialogOpen} onClose={handleTempPasswordDialogClose}>
        <DialogTitle>Send Temporary Password</DialogTitle>
        <DialogContent>
          <DialogContentText style={{color: 'black'}}>Temporary Password: {tempPassword}</DialogContentText>
          <br />
          <DialogContentText style={{color: 'black'}}>Do you want to set this as the temporary password for this account?</DialogContentText>
          <DialogContentText style={{color: 'red'}}>*** This cannot be undone ***</DialogContentText>
        
        </DialogContent>
        <DialogActions>
          <Button onClick={handleTempPasswordDialogClose}>Cancel</Button>
          <Button onClick={handleSendTemporaryPass}>Submit</Button>
        </DialogActions>
      </Dialog>
        <Grid container xs={12} sm={12} md={12} lg={12}>
          <Grid item xs={9} sm={9} md={9} lg={9}>
            <Typography variant="h6" gutterBottom>
              User Profile Details
            </Typography>
          </Grid>
          <Grid
            item
            xs={3}
            sm={3}
            md={3}
            lg={3}
            className={classes.rightSideHeaderSubMenuToolBar}
          >
            <Button
              onClick={handleUserProfileEditingDropDownClick}
              aria-controls="userProfiles-act-settings"
              aria-haspopup="true"
            >
              <SettingsIcon
                fontSize="medium"
                className={classes.SubMenuToolBarIcon}
              />
            </Button>

            <ClickAwayListener
              onClickAway={handleUserProfileEditingDropDownClose}
            >
              <StyledMenu
                id="userProfiles-act-settings"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleUserProfileEditingDropDownClose}
              >
                {/*List Item*/}
                {props.userProfiles.selectedUserProfile || props.UserProfile ? (<>
                  <StyledNavMenuItem>
                    <ListItemIcon>
                      <ChatIcon fontSize="small" />
                    </ListItemIcon>
                    <Link
                      href={`mailto:${props.userProfiles.selectedUserProfile?.email}`}
                      onClick={() => {
                        navigator.clipboard.writeText(props.userProfiles.selectedUserProfile?.email);
                        dispatch(
                          setSnackbar(
                            true,
                            "success",
                            "Copied email to clipboard."
                          )
                        );
                      }}
                    >
                      <ListItemText primary="Send Email"></ListItemText>
                    </Link>
                  </StyledNavMenuItem>
                  <StyledNavMenuItem onClick={handleDialogOpen}>
                    <ListItemIcon>
                      <MailIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Update Account Email" />
                  </StyledNavMenuItem>
                  <StyledNavMenuItem onClick={handleSendPasswordResetUpdate}>
                    <ListItemIcon>
                      <PermScanWifiIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Reset Password" />
                  </StyledNavMenuItem> 
                  <StyledNavMenuItem onClick={handleTempPasswordDialogOpen}>
                    <ListItemIcon>
                      <PermScanWifiIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Send Temporary Password" />
                  </StyledNavMenuItem> 
                  
                  </>
                ) : (
                  <StyledNavMenuItem onClick={handleSendAccountInvitation}>
                    <ListItemIcon>
                      <PermScanWifiIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Resend Account Invitation" />
                  </StyledNavMenuItem>
                )}
              </StyledMenu>
            </ClickAwayListener>
          </Grid>
        </Grid>
        <Divider className="mb-2 mt-2" my={1} />
        {props.userProfiles.isLoading || props.isLoading ? (
          <Skeleton
            variant="rect"
            height="380px"
            style={{ backgroundColor: `#bdbfbe`, padding: 6, margin: 6 }}
          />
        ) : props.userProfiles.selectedUserProfile && props.userProfiles.selectedUserProfile != null ? (
          <Grid
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            direction="column"
            alignItems="center"
            justify="center"
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              direction="row"
            >
              <Avatar
                className={classes.AvatarIcon}
                direction="column"
                alignItems="center"
                justify="center"
              >
                <Typography variant="h2" className={classes.AvatarText}>
                  {props.userProfiles.selectedUserProfile
                    ? (props.userProfiles.selectedUserProfile.firstName || props.userProfiles.selectedUserProfile.lastName
                      ? `${props.userProfiles.selectedUserProfile.firstName?.[0] || ''}${props.userProfiles.selectedUserProfile.lastName?.[0] || ''}`
                      : "Name not found")
                    : ""}
                </Typography>
              </Avatar>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography
                variant="h6"
                component="div"
                gutterBottom
                className={classes.UserBoxText}
              >
                <Box fontWeight="fontWeightMedium">
                  {props.userProfiles.selectedUserProfile
                    ? `${props.userProfiles.selectedUserProfile.firstName} ${props.userProfiles.selectedUserProfile.lastName}`
                    : "CCOS User"}
                </Box>
                {props.userProfiles.selectedUserProfile.isCustomer == 1 && (
                  <Box fontWeight="fontWeightRegular">Customer</Box>
                )}
                {props.userProfiles.selectedUserProfile.isSuperUser == 1 && (
                  <Box fontWeight="fontWeightRegular">Admin</Box>
                )}
                {props.userProfiles.selectedUserProfile.isInternalUser == 1 && (
                  <Box fontWeight="fontWeightRegular">CCOS Employee</Box>
                )}
              </Typography>
            </Grid>
            <Grid container spacing={2} lg={12}>
              <Grid item xs={12} lg={12}>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography variant="body1">User Name</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {props.userProfiles.selectedUserProfile ? props.userProfiles.selectedUserProfile.email : "N/A"}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography variant="body1">Phone #</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {props.userProfiles.selectedUserProfile ? formatPhoneNumber(props.userProfiles.selectedUserProfile.phone) : "N/A"}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            direction="column"
            alignItems="center"
            justify="center"
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              direction="row"
            >
              <Avatar
                className={classes.AvatarIcon}
                direction="column"
                alignItems="center"
                justify="center"
              >
                <Typography variant="h2" className={classes.AvatarText}>
                  ?
                </Typography>
              </Avatar>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography
                variant="h6"
                component="div"
                gutterBottom
                className={classes.UserBoxText}
              >
                <Box fontWeight="fontWeightMedium">User Profile Not Found</Box>
              </Typography>
              <Typography
                variant="body1"
                component="div"
                gutterBottom
                className={classes.UserBoxText}
              >
                <Box fontWeight="fontWeightRegular">
                  (No associated user profile)
                </Box>
              </Typography>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

// Component Properties
AccountUserDetailsCard.propTypes = {
  // Store objects:
  account: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  userProfiles: PropTypes.object.isRequired,
  updateUserPasswordEmailNotifier: PropTypes.func.isRequired,
  updateUserEmail: PropTypes.func.isRequired,
  sendTemporaryPassword: PropTypes.func.isRequired,
};

// Component State
function AccountUserDetailsCardState(state) {
  return {
    account: state.account,
    apl: state.apl,
    userProfiles: state.userProfiles,
    propertyManagers: state.propertyManagers
  };
}
export default connect(AccountUserDetailsCardState, {
  updateUserPasswordEmailNotifier,
  updateUserEmail,
  sendTemporaryPassword
})(AccountUserDetailsCard);
