//React Components and Hooks
//Material UI Components
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import moment from "moment";
import React from "react";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { setSnackbar } from "modules/snackbar/api/snackbar";
import { Dialog, DialogTitle, DialogActions, DialogContentText, DialogContent, Tooltip } from "@material-ui/core";

const UploadsTable = (props) => {
  const canDelete = props.canDelete;
  const dispatch = useDispatch();

  const [rows, setRows] = useState(props.uploads.sort((a,b) => {
    return new Date(b.created) - new Date(a.created);
  }) || [])
  const [openMarkAsBilledDialog, setOpenMarkAsBilledDialog] = useState(false);
  const [selectedUpload, setSelectedUpload] = useState(null);

  const handleCloseMarkAsBilledDialog = () => {
    setOpenMarkAsBilledDialog(false);
  }
  const handleOpenMarkAsBilledDialog = (uuid) => {
    setSelectedUpload(uuid);
    setOpenMarkAsBilledDialog(true);
  }
  

  const handleDelete = async (uuid) => {
    dispatch(setSnackbar(true, "warning", "Proceeding to delete upload"));
      let response = await props.handleUploadDelete(uuid);
      console.log(response)
      if(response?.status == 200) {
        let newlist = rows.filter(row => row.uuid != uuid);
        setRows(newlist);
      }
       
  }
  const handleMarkAsBilled = async (uuid) => {
   
    dispatch(setSnackbar(true, "warning", "Proceeding to mark as billed")); 
    let response = await props.markMeterReadingUploadAsBilled(selectedUpload);

    
  }

  return (
    <>
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell>Date Uploaded</TableCell>
            <TableCell>Filename</TableCell>
            <TableCell>Meter Reading Count</TableCell>
            <TableCell>File</TableCell>
            <TableCell>Mark as Billed</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => {
            return (
              <TableRow key={index}>
                <TableCell>
                  {moment(row.created).format("YYYY-MM-DD HH:MM:SS")}
                </TableCell>
                <TableCell>{row.fileName}</TableCell>
                <TableCell align="center">{row.readingCount}</TableCell>
                <TableCell>
                  <a target="_blank" href={`${row.url}`}>
                    <AttachFileIcon></AttachFileIcon>
                  </a>
                </TableCell>
               <Tooltip title="Mark as Billed" placement="right"> 
                <TableCell>
                  <Button onClick={() => {handleOpenMarkAsBilledDialog(row.uuid)}}>
                   <RequestQuoteIcon></RequestQuoteIcon>
                  </Button>
                </TableCell>
                </Tooltip>
                {canDelete &&
                  <TableCell>
                    <Button onClick={() => {handleDelete(row.uuid)}}>
                      <DeleteOutlinedIcon></DeleteOutlinedIcon>
                    </Button>
                  </TableCell>
                }
                
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
    <Dialog
    open={openMarkAsBilledDialog}
    onClose={handleCloseMarkAsBilledDialog}
    keepMounted
    aria-labelledby="alert-dialog-slide-title"
    aria-describedby="alert-dialog-slide-description">
      <DialogTitle>Mark as Billed</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to mark this upload as billed?
          <br/>
          <br/>
          <text style={{color: 'red'}}>*** This action cannot be undone. ***</text>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseMarkAsBilledDialog}>Cancel</Button>
        <Button onClick={handleMarkAsBilled}>Mark as Billed</Button>
      </DialogActions>
    </Dialog>
    </>
  );
};

export default UploadsTable;
