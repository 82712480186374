import { EditableTable } from "../../../components/EditableTable";
import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { findAllUserChildAccounts, deletePmUserLink, createPmUserLink } from '../../userProfiles/api/actions';
import { setSnackbar } from "../../snackbar/api/snackbar";
import { TextField , IconButton, Dialog, DialogTitle, DialogContent,DialogActions, Slide, Button} from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import { get as getAllPropManagers } from "../../propertyManager/api/actions";

import Autocomplete from '@mui/material/Autocomplete';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});





function PmUserLinkTable (props) {


  const [currentPmUuid, setCurrentPmUuid] = React.useState('');
  const dispatch = useDispatch();
  const [newSelectedUserLoaded, setNewSelectedUserLoaded] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [display, setDisplay] = React.useState(false);
  const [pms , setPms] = React.useState([]);
  const [pmAutoComplete, setPmAutoComplete] = React.useState([]);
  const [selectedPm, setSelectedPm] = React.useState({});
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);



  useEffect(() => {
    setCurrentPmUuid(props.pmUuid)
    if (props.userProfile?.id && props.userProfiles?.selectedUserProfilePmAccounts?.length < 1 && !newSelectedUserLoaded){
      setNewSelectedUserLoaded(true);
     
     
      
      props.findAllUserChildAccounts(props.userProfile.uuid, true )
    }else {
      setNewSelectedUserLoaded(false);

    }
  },[props.userProfiles.selectedUserProfilePmAccounts])

  const show = async () => {
    await props.getAllPropManagers()
      console.log("pms:", props.propertyManagers.data.propertyManagersList.items)
     await setPms(props.propertyManagers.data.propertyManagersList.items)

      const pmsOptions = {
        options: props.propertyManagers.data.propertyManagersList.items ,
        getOptionLabel: (option => `${option.name}`)
      }

      await setPmAutoComplete(pmsOptions);
      await setDisplay(true);

  };

  const handleChangePmState = (e , value) => {
    hide();
    setOpenConfirmDialog(true);
    setSelectedPm(value);

  }
  const hideConfirmDialog = () => {
    setOpenConfirmDialog(false);
  }
  const hide = () => {
    setDisplay(false);
  };

  const handleCrateLink = () =>{
    dispatch(setSnackbar(true, "warning", "Creating User PM Link"));
    hideConfirmDialog();

    props.createPmUserLink(props.userProfile.uuid, selectedPm.uuid).then(() =>{
      if (props.userProfiles.error != null || props.userProfiles.error == '') {

        dispatch(setSnackbar(true, "error", "Error Creating User Account Link!"));
        
      }
      else {

        dispatch(setSnackbar(true, "success", "Use Account Link Created"));
        props.findAllUserChildAccounts(props.userProfile.uuid, true );
       
      }
    })
  }


  const SearchBar = () => {
    return ((pms && pms.length > 0) ?
      <Autocomplete
       name="pmUuid"
       options={props.propertyManagers.data.propertyManagersList.items || []}
       getOptionLabel={ (option) => option.name}
       onChange={handleChangePmState}
       size="small"
       renderInput={(params) => <TextField  {...params} label="Property Manager" />}
     />: null)
  }
  
  const Accounts_fieldTitles = [
    "Contact Name",
    "Property Name",
  ];
  const Accounts_fieldTypes = [
    "label",
    "label",
  ];
  const Accounts_fieldNames = [
    "contactName",
    "propertyName",
  ];

  const deleteSelectedPmAccountLinks = (pmAccountsToDelete) => {
   
    dispatch(setSnackbar(true, "warning", "Deleting User PM Links"));
    try {
      pmAccountsToDelete.map(pm =>{
        let isCurrentAccount = pm === currentPmUuid ? true : false;
        props.deletePmUserLink( props.userProfiles.selectedUserProfile.uuid , pm).then(() => {
          if (props.userProfiles.error != null && props.userProfiles.error != '') {

            dispatch(setSnackbar(true, "error", "Error Deleting User Account Link!"));
            
          }
          else {

            dispatch(setSnackbar(true, "success", "Use Account Link Deleted"));
            

           
          }
          if(isCurrentAccount){

            window.location.reload();

          }
        })
      })
    }
    catch(e) {
      dispatch(setSnackbar(true, "error", "Error Deleting User PM Link!"));

    }
     
  }

  const NewDialog = () => {
    return (
      <Dialog
        open={display}
        TransitionComponent={Transition}
        fullWidth={"lg"}
        maxWidth={"lg"}
        keepMounted
        onClose={hide}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">New User Property Manager</DialogTitle>
        <DialogContent>
          <SearchBar/>
        </DialogContent>
      </Dialog>
    );
  };

  const ConfirmDialog = () => {
    return (
      <Dialog
        open={openConfirmDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={hideConfirmDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description">

        <DialogTitle id="alert-dialog-slide-title">Add New Pm User Link</DialogTitle>
        <DialogContent>Are you sure you want the add PM: {selectedPm.name} {selectedPm.address1} {selectedPm.address2 || ""}  to user account?</DialogContent>
        <DialogActions>
            <Button color="secondary" onClick={hideConfirmDialog}>Cancel</Button>
            <Button color="primary" onClick={handleCrateLink}>Submit</Button>

        </DialogActions>
      </Dialog>

    )
  }

  return (
   
    <>
    <NewDialog/>
    <ConfirmDialog/>
    <EditableTable
              readOnly={false}
              canAddItems={props.userProfile?.uuid != undefined}
              canSave={false}
              newItemCreationMethod={show}
              canDelete={true}
              verifyDelete={true}
              manualItemDeletionMethod={deleteSelectedPmAccountLinks}
              isLoading={props.userProfiles.isLoading}
              tableTitle={"User Associated PM Accounts"}
              objectArray={{ items: props.userProfiles.selectedUserProfilePmAccounts, toUpdate: [], toDelete: [] }}
              fieldTitles={Accounts_fieldTitles}
              fieldTypes={Accounts_fieldTypes}
              fieldNames={Accounts_fieldNames}
            />
  </>
 
  )
 
}
PmUserLinkTable.propTypes = {
  // Store objects:
  user: PropTypes.object.isRequired,
  userProfiles: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  propertyManagers: PropTypes.object.isRequired,
  findAllUserChildAccounts: PropTypes.func.isRequired,
  deletePmUserLink: PropTypes.func.isRequired,
  getAllPropManagers: PropTypes.func.isRequired,
  createPmUserLink: PropTypes.func.isRequired,
}

// Component State
function PmUserLinkTableState(state) {
  return {
    user: state.user,
    userProfiles: state.userProfiles,
    apl: state.apl,
    propertyManagers: state.propertyManagers
  }
}
export default connect(PmUserLinkTableState, {findAllUserChildAccounts, deletePmUserLink, getAllPropManagers, createPmUserLink })(PmUserLinkTable)
