import React, {useEffect} from 'react';
import {Card as MuiCard, Grid} from "@material-ui/core";
import styled from "styled-components/macro";
import {spacing} from "@material-ui/system";
import {EditableTable} from "../../../components/EditableTable";
import {useDispatch, useSelector} from "react-redux";
import {getBillingInfoSummary} from "../api/actions";

const Card = styled(MuiCard)(spacing);

const BillingInformationSummaryTable = () => {

  const dispatch = useDispatch();
  const propertyUuid = useSelector(state => state.properties.data?.property.uuid);
  const billingInfoSummaryData = useSelector(state => state.billing.data?.billingInfoSummary);
  const isLoading = useSelector(state => state.billing.billingInfoSummaryLoading);

  useEffect(() => {
    dispatch(getBillingInfoSummary(propertyUuid))
  }, [propertyUuid]);


  const fieldTitles = [
    "Water Usage Total, CF",
    "Bill Total",
    "Vendor Bill Percentage",
    "Bill Date",
    "Created"

  ];
  const fieldTypes = [
    "label",
    "moneyLabel",
    "label",
    "dateLabelNoTime",
    "dateLabelNoTime",

  ];
  const fieldNames = [
    "usageTotal",
    "billTotal",
    "vendorBillPercentage",
    "billDate",
    "created"
  ];
  return (
    <Card mb={6} elevation={3}>
      <Grid container xs={12} alignItems="center" spacing={2}>
        <Grid item lg={12} md={12} sm={12}>
        </Grid>
      </Grid>
      <EditableTable
        readOnly
        canAddItems={false}
        canSave={false}
        canDelete={false}
        tableTitle={"Vendor Bill Summary"}
        fieldTitles={fieldTitles}
        fieldTypes={fieldTypes}
        fieldNames={fieldNames}
        objectArray={billingInfoSummaryData}
        isLoading={isLoading}
      />

    </Card>
  );
};

export default BillingInformationSummaryTable;