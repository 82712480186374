import {
  Button,
  Card as MuiCard,
  CardContent,
  CircularProgress as MuiCircularProgress,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  TextField as MuiTextField,
} from "@material-ui/core";
//Material UI Components and Functions
import { makeStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
// Redux Components
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import {
  createNewNote,
  setNotes,
  updateNote,
  updateNoteReply,
} from "../api/actions";

const CenteredContent = styled.div`
  text-align: center;
`;

const CircularProgress = styled(MuiCircularProgress)(spacing);
const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const FormControl = styled(MuiFormControl)(spacing);
const TextField = styled(MuiTextField)(spacing);

// Custom Styles for property selection drop down

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  loadingIcon: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  selectedTopicLabel: {
    backgroundColor: "#fffffff",
    borderColor: "#fffffff",
  },
}));

const UpdateNoteReplyForm = (props) => {
  const updateNoteReply = () => {
    if (props.noteReplyIndex == -1) {
      props.updateNote(props.notes.data[props.noteIndex]);
    } else {
      props.updateNoteReply(
        props.notes.data[props.noteIndex].replys[props.noteReplyIndex]
      );
    }
    props.onUpdate();
  };
  const onFieldChange = (event) => {
    if (props.noteReplyIndex == -1) {
      props.notes.data[props.noteIndex][event.target.name] = event.target.value;
    } else {
      props.notes.data[props.noteIndex].replys[props.noteReplyIndex][
        event.target.name
      ] = event.target.value;
    }
  };

  return (
    <Card mb={6} elevation={3}>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item lg={12} md={12} sm={12}>
            {props.noteReplyIndex == -1 ? (
              <TextField
                fullWidth
                type="text"
                id="note"
                label="Note"
                defaultValue={props.notes.data[props.noteIndex].note}
                onChange={onFieldChange}
                multiline={true}
                rows={10}
                rowsMax={10}
                variant="outlined"
                name="note"
              />
            ) : (
              <TextField
                fullWidth
                type="text"
                id="reply"
                label="Reply"
                defaultValue={
                  props.notes.data[props.noteIndex].replys[props.noteReplyIndex]
                    .reply
                }
                onChange={onFieldChange}
                multiline={true}
                rows={10}
                rowsMax={10}
                variant="outlined"
                name="reply"
              />
            )}
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12}>
            <Button
              mr={1}
              variant="contained"
              color="secondary"
              fullWidth
              onClick={updateNoteReply}
              my={2}
            >
              Update
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

// Component Properties
UpdateNoteReplyForm.propTypes = {
  // Store objects:
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  notes: PropTypes.object.isRequired,

  createNewNote: PropTypes.func.isRequired,
  setNotes: PropTypes.func.isRequired,
  updateNote: PropTypes.func.isRequired,
  updateNoteReply: PropTypes.func.isRequired,
};

// Component State
function UpdateNoteReplyFormState(state) {
  return {
    user: state.user,
    apl: state.apl,
    account: state.account,
    notes: state.notes,
  };
}
export default connect(UpdateNoteReplyFormState, {
  createNewNote,
  setNotes,
  updateNote,
  updateNoteReply,
})(UpdateNoteReplyForm);
