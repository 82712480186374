//React Components and Hooks
import React, {useState, useRef, useEffect} from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  useHistory,
  useLocation,
  withRouter
} from "react-router-dom";
import styled from "styled-components/macro";
import {NavLink} from "react-router-dom";
import moment from 'moment';
import Helmet from "react-helmet";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import {
  Avatar,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Button,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  Link,
  TextField as MuiTextField,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress as MuiCircularProgress, FormGroup, Checkbox,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {TimePicker, DatePicker, DateTimePicker} from "@material-ui/pickers";
import {spacing} from "@material-ui/system";
import NativeSelect from '@material-ui/core/NativeSelect';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import PropertyUtilityForm from "../../../../modules/propertyUtility/components/Form";
// Redux Components
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {logout} from '../../../../modules/user/api/actions';
import {getAllPropertyPreviews} from '../../../../modules/apl/api/actions';
import {
  updateBillingWizardSelectedProperty,
  updateBillingWizardBillInProgress,
  getAllUnsettledPropertyMeterReadingsByPropertyUUID,
  handleBillingProcessSubmission,
  getSelectedBillWizPropertyInfoByPropUUID
} from '../../../../modules/billing/api/actions';
import {
  getByUuid as getPropertyByUuid,
  getPropRiderRatesByPropByUuid,
  getAllCommonChargeRelatedDataByPropertyId
} from "../../../../modules/property/api/actions";
import {getByPropertyId as getPropertyUtilityByPropertyId} from "../../../../modules/propertyUtility/api/actions";
import EditIcon from "@material-ui/icons/Edit";
//Material UI Components and Functions
import {makeStyles, withStyles} from '@material-ui/core/styles';

import {
  dateFormatter,
  txtBoxDateFormatter,
  formatMoney,
  CalculateSelectedPropertyTotalCommonsCharge,
  buildRiders
} from "../../../../setup/helpers";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const CenteredContent = styled.div`
  text-align: center;
`;
const CircularProgress = styled(MuiCircularProgress)(spacing);
const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const FormControl = styled(MuiFormControl)(spacing);
const TextField = styled(MuiTextField)(spacing);


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
// Custom Styles for property selection drop down


const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    selectedPropertyLocationLabel: {
        fontSize: 30,
        backgroundColor: "#fffffff",
        borderColor: "#fffffff",
    },
    loadingIcon: {
        position: 'absolute', left: '50%', top: '50%',
        transform: 'translate(-50%, -50%)'
    },
    dropDownLoadingIcon: {
        position: 'absolute', left: '50%', top: '50%',
        transform: 'translate(-50%, -50%)'
    }
}));


const BillingInformationForm = (props) => {
    const [property, setProperty] = React.useState(props.billing.data.billInProgress.billInformation.propertyUUID);
    const [billInformationFormObject, setBillInformationFormObject] = React.useState(props.billing.data.billInProgress.billInformation);
    const [currentTestKWH, setCurrentTestKWH] = React.useState(props.properties.currentRiderRateTestKwhCharge);
    const [riderRateTestProduct, setRiderRateTestProduct] = React.useState([]);
    const [riderRateTestProductSummary, setRiderRateTestProductSummary] = React.useState({ commonAreaTotal: 0, utilRidersTotal: 0 });
    const [initalCommonAreaLoading, setInitalCommonAreaLoading] = React.useState(false);

    const [display, setDisplay] = React.useState(false);

    const toggleDisplay = () => {
        const shouldDisplay = !display;
        setDisplay(shouldDisplay);
    };


    const history = useHistory();
    // Updates the local state object of "bill in progress" upon each modification
    const onFieldChange = (event) => {
        if (event.target.type == "number") {
            props.billing.data.billInProgress.billInformation[event.target.name] = Number(event.target.value);
        }
        else {
            props.billing.data.billInProgress.billInformation[event.target.name] = event.target.value;
        }
    }

    // const HandleTextBoxDisselect = (event) => {
    //     props.handleBillingProcessSubmission(props.billing.data.billInProgress, false);
    // }

    const EvaluateFormObject = () => {
        var newErrors = [];
        // Amount warnings
        //newErrors.push("This is my sample error message");
        props.billing.data.billInProgress.billInformationErrors = newErrors;
        // on error checking update the store and tell 
        if (newErrors.length > 0) {
            props.updateBillingWizardBillInProgress(props.billing.data.billInProgress);
            return false;
        }
        props.updateBillingWizardBillInProgress(props.billing.data.billInProgress);
        return true;
    }


    const CalculateSelectedPropertyTotalChargeSettings = () => {

        // Common area total for kwh and usd
        let total = 0;

        if (!props.properties.propertyResidualAmountsToAddIsLoading && !props.properties.propertyAdditionalCommonAreaExpensesIsLoading && !props.properties.isLoading) {
            total = CalculateSelectedPropertyTotalCommonsCharge(props.properties.propertyAdditionalCommonAreaExpenses, props.properties.propertyResidualAmountsToAdd, props.properties.data.property.totalUnits);
        }

        const today = moment().format("MM/DD/YYYY");
        // Rider rates summary totals (subject to change)
        if (props.propertyUtilities.data.propertyUtility) {
            var testRiderResult = buildRiders(
                1,
                props.propertyUtilities.data.propertyUtility,
                props.properties.data.propertyRiderRates.items,
                0,
                today,
                props.properties.data.property.totalUnits,
                1
            );
            testRiderResult.bill.commonAreaTotal = total || 0;
            setRiderRateTestProductSummary(testRiderResult.bill);
            setRiderRateTestProduct(testRiderResult.riders);
        }
    }


    const classes = useStyles();
    // Should pass the index of the selected property as found in the apl "properties" array, and it passes that object to be merged
    const handleSelectedPropertyChange = (event) => {
        setProperty(event.target.value);
        var selectedProperty = props.apl.data.propertyPreviews.find(obj => {
            return obj.uuid === event.target.value
        })
        // NOTE: Copout this is what onfield change does basically so its sloppy to have twice. did it because value on event is array index not uuid 
        // Calls field change method to update the value in the redux store
        props.billing.data.billInProgress.billInformation.propertyUUID = event.target.value;
        // props.billing.data.billInProgress.currentStep = 1;
        // Replaces existing store object with new object with modified sub data
        props.updateBillingWizardBillInProgress(props.billing.data.billInProgress);


        // Has to merge the selected property into the state with the selected property details and loads all necessary property assets for the form
        props.updateBillingWizardSelectedProperty(selectedProperty);
        props.getSelectedBillWizPropertyInfoByPropUUID(selectedProperty.id);
        props.getAllUnsettledPropertyMeterReadingsByPropertyUUID(selectedProperty.id);
        props.getPropRiderRatesByPropByUuid(selectedProperty.uuid);
        props.getAllCommonChargeRelatedDataByPropertyId(selectedProperty.id);
        props.getPropertyByUuid(selectedProperty.uuid);
        props.getPropertyUtilityByPropertyId(selectedProperty.id);
    };

    useEffect(() => {
        if (!props.properties.riderRatesAreLoading && !props.properties.propertyAdditionalCommonAreaExpensesIsLoading && !props.properties.propertyResidualAmountsToAddIsLoading && !props.properties.data.property.isLoading) {
            CalculateSelectedPropertyTotalChargeSettings();
        }
        if (props.billing.data.billInProgress.billInformation.propertyUUID != "" && !props.properties.data.property.totalUnits && !props.properties.data.property.id && !props.properties.data.property.isLoading && props.properties.propertyRiderRates.items.length == 0) {
            var selectedProperty = props.apl.data.propertyPreviews.find(obj => {
                return obj.uuid === props.billing.data.billInProgress.billInformation.propertyUUID
            })
            if (!initalCommonAreaLoading) {
                props.getPropertyUtilityByPropertyId(selectedProperty.id);
                props.getPropRiderRatesByPropByUuid(selectedProperty.uuid);
                props.getAllCommonChargeRelatedDataByPropertyId(selectedProperty.id);
                props.getPropertyByUuid(selectedProperty.uuid);
                setInitalCommonAreaLoading(true);
            }
        }
    }, [props.properties.riderRatesAreLoading, props.properties.propertyAdditionalCommonAreaExpensesIsLoading, props.properties.propertyResidualAmountsToAddIsLoading, props.properties.data.property.isLoading]);

  return (
    <Card mb={6} elevation={3}>
      <CardContent>
        {/* Main Grid */}
        <Grid container spacing={4}>
          {/*             Col 1            */}
          {props.billing.data.billInProgress.trialBillingItems.items.length == 0 && !props.billing.data.billInProgress.generateTrialBilling ?
            <Grid item lg={4} md={12} sm={12}>
              <FormControl fullWidth my={2}>
                <InputLabel id="propertyToBillDropDownLabel">Location{"  "}
                  {props.apl.isLoading &&
                    <CircularProgress size={15} m={0}/>
                  }
                </InputLabel>
                {property == "" && props.apl.isLoading ?
                  <Select
                    labelId="propertyToBillDropDownLabel"
                    id="propertyToBillDropDown"
                    className={classes.selectedPropertyLocationLabel}
                    onChange={handleSelectedPropertyChange}
                    fullWidth
                    disabled
                    my={2}>
                  </Select>
                  :
                  <Select
                    labelId="propertyToBillDropDownLabel"
                    id="propertyToBillDropDown"
                    value={props.billing.data.billInProgress.billInformation.propertyUUID}
                    className={classes.selectedPropertyLocationLabel}
                    onChange={handleSelectedPropertyChange}
                    fullWidth
                    my={2}>
                    {props.apl.data.propertyPreviews.length > 0 ?
                      [
                        props.apl.data.propertyPreviews.map((item, key) => {
                          return (
                            <MenuItem value={item.uuid} id={key}>{item.name}</MenuItem>
                          )
                        })
                      ]
                      :
                      [
                        <MenuItem value={-1}>No Properties Found</MenuItem>
                      ]
                    }
                  </Select>
                }
              </FormControl>
            </Grid>
            :
            <Grid item lg={4} md={12} sm={12}>
              <FormControl fullWidth my={2}>
                <InputLabel id="propertyToBillDropDownLabel">Location{"  "}
                </InputLabel>
                <Select
                  labelId="propertyToBillDropDownLabel"
                  id="propertyToBillDropDown"
                  value={props.billing.data.billInProgress.billInformation.propertyUUID}
                  disabled
                  className={classes.selectedPropertyLocationLabel}
                  onChange={handleSelectedPropertyChange}
                  fullWidth
                  my={2}>
                  {props.apl.data.propertyPreviews.length > 0 ?
                    [
                      props.apl.data.propertyPreviews.map((item, key) => {
                        return (
                          <MenuItem value={item.uuid} id={key}>{item.name}</MenuItem>
                        )
                      })
                    ]
                    :
                    [
                      <MenuItem value={-1}>No Properties Found</MenuItem>
                    ]
                  }
                </Select>
              </FormControl>
            </Grid>
          }
          {/*             Col 2            */}
          <Grid item lg={4} md={12} sm={12}>
            <TextField
              my={2}
              fullWidth
              type="date"
              id="billDate"
              label="Billing Date"
              defaultValue={txtBoxDateFormatter(props.billing.data.billInProgress.billInformation.billDate)}
              onChange={onFieldChange}
              // onBlur={HandleTextBoxDisselect}
              //onBlur={() =>{ props.handleBillingProcessSubmission(props.billing.data.billInProgress, false); } }
              variant="outlined"
              name="billDate"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item lg={4} md={12} sm={12}>
            <TextField
              id="dueDate"
              label="Due Date"
              type="date"
              fullWidth
              my={2}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              defaultValue={props.billing.data.billInProgress.billInformation.dueDate != props.billing.defaultDateValue &&
                txtBoxDateFormatter(props.billing.data.billInProgress.billInformation.dueDate)
              }
              onChange={onFieldChange}
              // onBlur={HandleTextBoxDisselect}
              //onBlur={() =>{ props.handleBillingProcessSubmission(props.billing.data.billInProgress, false); } }
              name="dueDate"
            />
          </Grid>
          <Grid item lg={4} md={12} sm={12}>
            <TextField
              id="runDate"
              label="Run Date"
              disabled={!props.hold}
              type="date"
              fullWidth
              my={2}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              defaultValue={txtBoxDateFormatter(props.billing.data.billInProgress.billInformation.runDate)}
              onChange={onFieldChange}
              name="runDate"
            />
          </Grid>
          {/*  <Grid item lg={4} md={12} sm={12}>*/}
          {/*  <TextField*/}
          {/*    id="nextReadDate"*/}
          {/*    label="Next Read Date"*/}
          {/*    type="date"*/}
          {/*    my={2}*/}
          {/*    fullWidth*/}
          {/*    variant="outlined"*/}
          {/*    InputLabelProps={{*/}
          {/*      shrink: true,*/}
          {/*    }}*/}
          {/*    defaultValue={txtBoxDateFormatter(props.billing.data.billInProgress.billInformation.nextReadDate)}*/}
          {/*    onChange={onFieldChange}*/}
          {/*    // onBlur={HandleTextBoxDisselect}*/}
          {/*    //onBlur={() =>{ props.handleBillingProcessSubmission(props.billing.data.billInProgress, false); } }*/}
          {/*    name="nextReadDate"*/}
          {/*  />*/}
          {/*</Grid>*/}
          {/*             Col 3           */}
          <Grid item lg={8} md={12} sm={12}>
            <TextField
              label="Bill Message"
              id="customBillMessage"
              multiline={true}
              rows={10}
              autoComplete={"off"}
              rowsMax={10}
              variant="outlined"
              my={2}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              defaultValue={billInformationFormObject.billMessage}
              onChange={onFieldChange}
              // onBlur={HandleTextBoxDisselect}
              //onBlur={() =>{ props.handleBillingProcessSubmission(props.billing.data.billInProgress, false); } }
              name="billMessage"
            />
          </Grid>
        </Grid>
        {(riderRateTestProductSummary != null && props.properties.data.property.id && props.propertyUtilities.data.propertyUtility) &&
          <Grid container spacing={4}>
            <Grid item lg={12} md={12} sm={12} className="mt-2 pt-2">
              <Typography variant="h5" className="mt-2 pt-2">
                Current Property Utility Object ~ {" "}
                <Typography variant="caption" className={classes.typography}>
                  Last Updated: {new Date(
                  props.propertyUtilities.data.propertyUtility.updated
                ).toLocaleDateString() + "  " || " N/A "}
                  <Button onClick={toggleDisplay}>
                    <EditIcon
                      fontSize="medium"
                      className={classes.SubMenuToolBarIcon}
                    />
                  </Button>
                  <Dialog
                    open={display}
                    TransitionComponent={Transition}
                    fullWidth={"lg"}
                    maxWidth={"lg"}
                    keepMounted
                    onClose={toggleDisplay}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                  >
                    {props.propertyUtilities.data.propertyUtility ? (
                      <DialogTitle id="alert-dialog-slide-title">
                        Utilities {props.propertyUtilities.data.propertyUtility.name}{" "}
                      </DialogTitle>
                    ) : (
                      <DialogTitle id="alert-dialog-slide-title">
                        Loading Utilities.....{" "}
                      </DialogTitle>
                    )}
                    <DialogContent>
                      {props.propertyUtilities.data.propertyUtility && (
                        <PropertyUtilityForm
                          onUpdate={toggleDisplay}
                          onCancel={toggleDisplay}
                          serviceOutage={
                            props.propertyUtilities.data.propertyUtility
                              .serviceOutage || 0
                          }
                          active={
                            props.propertyUtilities.data.propertyUtility.active || 0
                          }
                        />
                      )}
                    </DialogContent>
                  </Dialog>
                </Typography>
              </Typography>
              <Divider className="mt-2"/>
            </Grid>
            <Grid item lg={3} md={3} sm={12}>
              <Typography variant="body1" className={classes.typography}>
                Billing Type :
              </Typography>
              <Typography variant="caption" className={classes.typography} >
                {props.propertyUtilities.data.propertyUtility.billingType}
              </Typography>
            </Grid>
            <Grid item lg={3} md={3} sm={12}>
              <Typography variant="body1" className={classes.typography}>
                Service Fee :
              </Typography>
              <Typography variant="caption" className={classes.typography}>
                {formatMoney(props.propertyUtilities.data.propertyUtility.serviceFee || 0)}
              </Typography>
            </Grid>
            <Grid item lg={3} md={3} sm={12}>
              <Typography variant="body1" className={classes.typography}>
                Processing Fee :
              </Typography>
              <Typography variant="caption" className={classes.typography}>
                {formatMoney(props.propertyUtilities.data.propertyUtility.processingFee || 0)}
              </Typography>
            </Grid>
            <Grid item lg={3} md={3} sm={12}>
              <Typography variant="body1" className={classes.typography}>
                Estimated Usage :
              </Typography>
              <Typography variant="caption" className={classes.typography}>
                {formatMoney(props.propertyUtilities.data.propertyUtility.estimatedUsage || 0)}
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12}>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={props.propertyUtilities.data.propertyUtility.ccosPaysBill}
                      name="ccosPaysBill"
                      disabled
                    />
                  }
                  label="CCOS Pays Bills"
                />
              </FormGroup>
            </Grid>
            {/*<Grid item lg={12} md={12} sm={12} className="mt-2 pt-2">*/}
            {/*  <Typography variant="h5" className="mt-2 pt-2">*/}
            {/*    Saved Property Commons Area Charges*/}
            {/*  </Typography>*/}
            {/*  <Divider className="mt-2"/>*/}
            {/*</Grid>*/}
            {/*<Grid item lg={6} md={12} sm={12}>*/}
            {/*  <Typography variant="body1" className={classes.typography}>*/}
            {/*    Current Common Charge/Account: $ {(riderRateTestProductSummary.commonAreaTotal || 0).toFixed(4)}*/}
            {/*  </Typography>*/}
            {/*</Grid>*/}
            {/*<Grid item lg={6} md={12} sm={12}>*/}
            {/*  <Typography variant="body1" className={classes.typography}>*/}
            {/*    Current Rider Rate Totals (@ 1 CF): $ {(riderRateTestProductSummary.utilRidersTotal || 0).toFixed(4)}*/}
            {/*  </Typography>*/}
            {/*</Grid>*/}
            <Grid item lg={12} md={12} sm={12}>
              <Divider className="mb-2 mt-2" my={1}/>
            </Grid>
          </Grid>
        }
      </CardContent>
    </Card>
  );
}
// Component Properties
BillingInformationForm.propTypes = {
    // Store objects:
    user: PropTypes.object.isRequired,
    apl: PropTypes.object.isRequired,
    billing: PropTypes.object.isRequired,
    properties: PropTypes.object.isRequired,
    propertyUtilities: PropTypes.object.isRequired,
    // Store object functions:
    getAllPropertyPreviews: PropTypes.func.isRequired,
    updateBillingWizardSelectedProperty: PropTypes.func.isRequired,
    updateBillingWizardBillInProgress: PropTypes.func.isRequired,
    getAllUnsettledPropertyMeterReadingsByPropertyUUID: PropTypes.func.isRequired,
    handleBillingProcessSubmission: PropTypes.func.isRequired,
    getSelectedBillWizPropertyInfoByPropUUID: PropTypes.func.isRequired,

    getPropRiderRatesByPropByUuid: PropTypes.func.isRequired,
    getAllCommonChargeRelatedDataByPropertyId: PropTypes.func.isRequired,
    getPropertyByUuid: PropTypes.func.isRequired,
    getPropertyUtilityByPropertyId: PropTypes.func.isRequired,
}

// Component State
function BillingInformationFormState(state) {
    return {
        user: state.user,
        apl: state.apl,
        billing: state.billing,
        properties: state.properties,
        propertyUtilities: state.propertyUtilities
    }
}

export default connect(BillingInformationFormState, {
  getPropRiderRatesByPropByUuid,
  getAllCommonChargeRelatedDataByPropertyId,
  getPropertyByUuid,
  getPropertyUtilityByPropertyId,
  logout,
  updateBillingWizardSelectedProperty,
  updateBillingWizardBillInProgress,
  getAllPropertyPreviews,
  handleBillingProcessSubmission,
  getSelectedBillWizPropertyInfoByPropUUID,
  getAllUnsettledPropertyMeterReadingsByPropertyUUID
})(withRouter(BillingInformationForm))