//React Components and Hooks
import {
  CircularProgress as MuiCircularProgress,
  Divider as MuiDivider,
  Grid,
  Typography as MuiTypography,
} from "@material-ui/core";
//Material UI Components and Functions
import { makeStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
// Redux Components
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import StandardHeader from "../../components/global/StandardHeader";
// Custom application component imports:
import { LeftSidebar as NavFrame } from "layout-blueprints"; // The top navigation bar and side navigation panel
import {
  getAllPMStatusTypes,
  getAllPropertyPreviews,
} from "../../modules/apl/api/actions";
import { get as getAllPropManagers } from "../../modules/propertyManager/api/actions";
import PropertyManagerSearchTable from "../../modules/propertyManager/components/PropertyManagerSearchTable";
import { logout } from "../../modules/user/api/actions";
import AdvanceFilterToolbar from "./AdvanceFilterToolbar"; // Dropdown to set the scope of the analytics data

const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  percentDif: {
    textAlign: "right",
    color: "green",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  loadingIcon: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

const PropertyManagerSearchPage = (props) => {
  const classes = useStyles();

  const logout = () => {
    props.logout();
  };

  const RefreshPage = () => {
    props.getAllPropertyPreviews();
    props.getAllPMStatusTypes();
  };

  // On initial page load:
  useEffect(() => {
    RefreshPage();
    document.title = "Ubil+ Property Manager Search";
  }, []);

  // On Data Loaded:
  return (
    <NavFrame page={"PropertyManagerSearch"}>
      <StandardHeader
        title="Property Manager Search"
        rightSideToolBar={
          <AdvanceFilterToolbar
            props={props}
            handleRefreshClick={RefreshPage}
          />
        }
      />
      <Divider className="mb-3 mt-3" />
      <Grid container spacing={1} xs={12} sm={12} md={12} lg={12}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <PropertyManagerSearchTable />
        </Grid>
      </Grid>
    </NavFrame>
  );
};

/* 
<Grid item xs={12} sm={12} md={12} lg={5} spacing={3}>
    <AccountsByPropertyTable/>
</Grid>
*/
// Component Properties
PropertyManagerSearchPage.propTypes = {
  user: PropTypes.object.isRequired,
  propertyManagers: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  getAllPropertyPreviews: PropTypes.func.isRequired,
  getAllPropManagers: PropTypes.func.isRequired,
  getAllPMStatusTypes: PropTypes.func.isRequired,
};

// Component State
function PropertyManagerSearchPageState(state) {
  return {
    user: state.user,
    apl: state.apl,
    billing: state.billing,
    accountsSearch: state.accountsSearch,
    propertyManagers: state.propertyManagers,
  };
}
export default connect(PropertyManagerSearchPageState, {
  logout,
  getAllPropertyPreviews,
  getAllPropManagers,
  getAllPMStatusTypes,
})(PropertyManagerSearchPage);
