//React Components and Hooks
import {
  CircularProgress as MuiCircularProgress,
  Divider as MuiDivider,
  Grid,
  Paper,
  Button,
  Typography as MuiTypography,
} from "@material-ui/core";
//Material UI Components and Functions
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem as MuiTimelineItem,
  TimelineSeparator,
} from "@material-ui/lab";
import Skeleton from "@material-ui/lab/Skeleton";
import { spacing } from "@material-ui/system";
// Redux Components
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import StandardHeader from "../../components/global/StandardHeader";
import moment from "moment";
// Custom application component imports:
import { LeftSidebar as NavFrame } from "layout-blueprints"; // The top navigation bar and side navigation panel
import {
  getAllGlCodes,
  getAllPropertyPreviews,
  getAllVendors,
  getAnalyticalData,
} from "../../modules/apl/api/actions";
import {
  deleteBillDraftByUUID, getAllBillingDashboardInfo,
  getAllUnsettledPropertyMeterReadingsByPropertyUUID,
  getBillInProgressDraft,
  getSelectedBillWizPropertyInfoByPropUUID,
  handleBillingProcessSubmission,
  updateBillingWizardBillInProgress,
  updateBillingWizardSelectedProperty,
} from "../../modules/billing/api/actions";
import {getByPropertyId as getPropertyUtilityByPropertyId} from "../../modules/propertyUtility/api/actions";
import { setSnackbar } from "../../modules/snackbar/api/snackbar";
import CustomBillWizardFormProcessingSnackbar from "../../modules/snackbar/components/billingWizardFormTransitionSnackbar";
import CustomErrorSnackbar from "../../modules/snackbar/components/customErrorSnackbar";
import { logout } from "../../modules/user/api/actions";
import BillingInformationForm from "./Components/Forms/BillingInformation";
import FinalizeForm from "./Components/Forms/Finalize";
import PropertyMeterDataForm from "./Components/Forms/MeterReadCheck";
import TrialBillingForm from "./Components/Forms/TrialBilling";
import TrialBillingSummaryForm from "./Components/Forms/TrialBillingSummary";
import VendorInformationForm from "./Components/Forms/VendorInformation";
import ToolBar from "./ToolBar";

function BillingWizardPhase(Title, Content) {
  return {
    title: Title,
    content: Content,
  };
}

const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);

const TimelineItem = withStyles({
  missingOppositeContent: {
    "&:before": {
      display: "none",
    },
  },
})(MuiTimelineItem);

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  percentDif: {
    textAlign: "right",
    color: "green",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  loadingIcon: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  billingWizardContent: {
    maxHeight: "60%",
  },
  paper: {
    padding: "1%",
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  formLoadingSkeleton: {
    height: "300px",
    backgroundColor: `#bdbfbe`,
    padding: 6,
    margin: 6,
    marginBottom: 3,
  },
  snackbarRoot: {
    maxWidth: 600,
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  documentToolBar: {
    padding: 60,
  },
}));

const BillingWizard = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isDeleting, setIsDeleting] = React.useState(false);
  const navTo = (page) => history.push(page);
  const logout = () => {
    props.logout();
    props.history.push("/");
  };

  const RefreshPage = async () => {
    props.getAllPropertyPreviews();
    props.getAllGlCodes();
    props.getAllVendors();
    if (props.match.params.wtUuid != "new") {
      props.getBillInProgressDraft(props.match.params.wtUuid);
    }
  };

  const updateCurrentBillingObjAndRunTrialBillsGeneration = () => {
    props.billing.data.billInProgress.currentStep = 3; // Sets the state to the next phase before saving
    props.billing.data.billInProgress.generateTrialBilling = true;
    props.updateBillingWizardBillInProgress(props.billing.data.billInProgress);
    props.handleBillingProcessSubmission(props.billing.data.billInProgress);
  };
  const updateCurrentBillingObjAndContinue = () => {
    props.billing.data.billInProgress.currentStep = props.billing.data.billInProgress.currentStep + 1;
    props.billing.data.billInProgress.generateTrialBilling = true;
    props.updateBillingWizardBillInProgress(props.billing.data.billInProgress);
    props.handleBillingProcessSubmission(props.billing.data.billInProgress);
  };

  const handleNext = () => {
    props.billing.data.billInProgress.currentStep = props.billing.data.billInProgress.currentStep + 1;
    props.handleBillingProcessSubmission(props.billing.data.billInProgress, false)
  }
  const handleSave = () => {
    props.handleBillingProcessSubmission(props.billing.data.billInProgress, true);
  }

  const DeleteBillingDraftAndCloseTab = () => {
    console.log("DeleteBillingDraftAndCloseTab");
    if (props.match.params.wtUuid != "new") {
      console.log("Saved Bill Deleting Draft");
      props.deleteBillDraftByUUID(props.match.params.wtUuid);
    } else {
      console.log("New Bill, Closing Window");
      setIsDeleting(true);
    }
  };

  // On initial page load methods:
  useEffect(() => {
   props.getAllBillingDashboardInfo();
   RefreshPage();
  }, []);

  /*
  if (props.billing.data.billInProgress.errors.length > 0){
    dispatch(setSnackbar(true, "error", "Test Message"));
  }*/
  var pageTitle = "Ubil+ Billing Draft";
  // Sets the tab name based on the loaded account being worked on
  if (
    props.match.params.wtUuid == "new" &&
    !props.billing.data.selectedProperty.id
  ) {
    pageTitle = "Ubil+ New Billing Draft";
  } else if (props.billing.data.selectedProperty.id) {
    pageTitle = "New Billing: " + props.billing.data.selectedProperty.name;

  }
  document.title = pageTitle;
  if (props.billing.billIsBeingDeleted || isDeleting) {
    navTo("/billingdashboard/4000");
  }
  if (props.billing.data.billInProgress.successfulTrialBillExecutionAttempt) {
    navTo("/billingdashboard/4000");
  }
  if (
    props.match.params.wtUuid == "new" &&
    props.billing.data.billInProgress.accountBillWorkTableUUID != -1
  ) {
    navTo(
      "/billingwizard/" +
      props.billing.data.billInProgress.accountBillWorkTableUUID
    );
  }
  // Clause to goto the process page if bill has already been submitted
  if (!props.billing.billInProgressIsLoading &&
    props.billing.data.billInProgress.existingBillingBatchSet != null &&
    props.billing.data.billInProgress.existingBillingBatchSet.fulfillmentValue &&
    props.billing.data.billInProgress.existingBillingBatchSet.fulfillmentValue.id) {
    navTo("/batches/" + props.billing.data.billInProgress.existingBillingBatchSet.fulfillmentValue.id);
  }
  const phase = props.billing.data.billInProgress.currentStep;

  const billingType = props.propertyUtilities.data.propertyUtility?.billingType;

  const propertyUuid = props.billing.data.selectedProperty.uuid
  const findHold = props.billing.data.billingWizardDashboard.incompleteWorkTables.items?.find(item => item.propertyUuid === propertyUuid)
  const hold = findHold?.hold

  let isProcessingCurrently =
    props.billing.isLoading ||
    props.billing.billInProgressIsLoading ||
    props.billing.selectedPropertyMetersAreLoading ||
    props.billing.selectedPropertyIsLoading;
  var message = "Billing Process Assets Loaded Successfully";
  var severity = "success";

  if (props.billing.isLoading) {
    message = "Getting progress on current billing process....";
  }
  if (props.billing.selectedPropertyIsLoading) {
    message = "Getting Bill's selected property...";
    severity = "warning";
  }
  if (props.billing.selectedPropertyMetersAreLoading && billingType === 'BILL') {
    message = "Getting Selected property's meter data...";
    severity = "warning";
  }
  if (props.billing.billInProgressIsLoading) {
    message = "Processing Bill In Progress...";
    severity = "warning";
  }
  if (
    !isProcessingCurrently &&
    props.billing.error != "" &&
    props.billing.error != null
  ) {
    severity = "error";
    message = "Error! Unable to retrieve property billing/ property meter data";
  } else if (isProcessingCurrently) {
    severity = "warning";
  }

  if (props.billing.billIsBeingSubmittedbillIsBeingSubmitted) {
    isProcessingCurrently = true;
    message = "Saving, Validating, and Submitting Bill In Progress...";
    severity = "warning";
  }

  if (
    props.match.params.wtUuid == "new" &&
    !props.billing.data.selectedProperty.id
  ) {
    props.billing.isLoading = false;
    props.billing.billInProgressIsLoading = false;
    props.billing.selectedPropertyMetersAreLoading = false;
    props.billing.selectedPropertyIsLoading = false;
    message = null;
    severity = null;
  }
  var currentPath = "Billing  /  Billing Wizard"
  if (!props.billing.billInProgressIsLoading) {
    currentPath = currentPath + `  -   Created: ${props.billing.data.billInProgress.createdDate || moment().format("MM/DD/YYYY")}`
  }
  // On Data Loaded:
  return (
    <NavFrame page={"BillingWizard"}>
      <StandardHeader
        title={pageTitle}
        rightSideToolBar={
          <ToolBar
            refreshPage={RefreshPage}
            deleteDraft={DeleteBillingDraftAndCloseTab}
            regenerateTrialBillItems={
              updateCurrentBillingObjAndRunTrialBillsGeneration
            }
          />
        }
        appPath={currentPath}
      />
      <Divider className="mb-3 mt-3" />
      <CustomBillWizardFormProcessingSnackbar
        message={message}
        severity={severity}
        isProcessingForm={isProcessingCurrently}
      />
      <CustomErrorSnackbar
        errors={props.billing.data.billInProgress.errors}
        refreshPage={RefreshPage}
        isLoading={props.billing.isLoading}
      />
      <Paper elevation={1} className={classes.paper}>
        {/*<RightAlignedTimeline Phases={applicationPhases} CurrentPhase={props.billing.data.billInProgress.currentStep}/>*/}
        {props.billing.billInProgressIsLoading &&
          props.billing.data.billInProgress.accountBillWorkTableUUID == -1 ? (
          <>
            <Grid container className={classes.formGrid} spacing={2}>
              <Grid item xs={12}>
                <Skeleton
                  variant="rect"
                  className={classes.formLoadingSkeleton}
                />
              </Grid>
              <Grid item xs={12}>
                <Skeleton
                  variant="rect"
                  className={classes.formLoadingSkeleton}
                />
              </Grid>
              <Grid item xs={12}>
                <Skeleton
                  variant="rect"
                  className={classes.formLoadingSkeleton}
                />
              </Grid>
            </Grid>
          </>
        ) : (
          <Timeline>
            <TimelineItem id={"tlItem"} color="secondary">
              <TimelineSeparator id={"tlSeperator"}>
                <TimelineDot color={phase >= 0 ? "secondary" : "primary"}>
                  #1
                </TimelineDot>
                <TimelineConnector
                  className={phase >= 0 && classes.secondaryTail}
                />
              </TimelineSeparator>

              <TimelineContent id={"tlContent"}>
                <Typography
                  variant="h6"
                  className={classes.openPhaseTitle}
                  color={phase >= 0 ? "secondary" : "primary"}
                >
                  Billing Information
                </Typography>
                {/* The phase card content go here */}
                {phase >= 0 && (
                  <Grid container className={classes.formGrid}>
                    <Grid item xs={12}>
                      <BillingInformationForm billingType={billingType} hold={hold}/>
                    </Grid>
                  { phase == 0 && <Button variant="contained" color="primary" onClick={handleNext}>Save and Continue</Button>}
                  </Grid>
                )}
              </TimelineContent>
            </TimelineItem>

            <TimelineItem id={"tlItem"} color="secondary">
              <TimelineSeparator id={"tlSeperator"}>
                <TimelineDot color={phase >= 1 ? "secondary" : "primary"}>
                  #2
                </TimelineDot>
                <TimelineConnector className={classes.secondaryTail} />
              </TimelineSeparator>
              <TimelineContent id={"tlContent"}>
                <Typography
                  variant="h6"
                  className={classes.openPhaseTitle}
                  color={phase >= 1 ? "secondary" : "primary"}
                >
                  Vendor Information
                </Typography>
                {/* The phase card content go here */}
                {phase >= 1 && (
                  <Grid container className={classes.formGrid}>
                    <Grid item xs={12}>
                      <VendorInformationForm billingType={billingType} hold={hold}/>
                    </Grid>
                    { phase == 1 && <Button variant="contained" color="primary" onClick={handleNext}>Save and Continue</Button>}
                    { phase > 1 && billingType === 'BILL' && <Button variant="contained" color="primary" onClick={handleSave}>Save</Button>}

                    {phase > 1 && (
                      <>
                        {billingType !== 'BILL' && (
                          <Button
                            // style={{ marginLeft: "10px" }}
                            variant="contained"
                            color="secondary"
                            onClick={updateCurrentBillingObjAndContinue}
                          >
                            Save and Continue
                          </Button>
                        )}
                      </>
                    )}




                  </Grid>
                )}
              </TimelineContent>
            </TimelineItem>
            {billingType === 'BILL' && (
            <TimelineItem id={"tlItem"} color="secondary">
              <TimelineSeparator id={"tlSeperator"}>
                <TimelineDot color={phase >= 2 ? "secondary" : "primary"}>
                  #3
                </TimelineDot>
                {phase >= 2 ? (
                  <TimelineConnector className={classes.secondaryTail} />
                ) : (
                  <TimelineConnector />
                )}
              </TimelineSeparator>
              <TimelineContent id={"tlContent"}>
                <Typography
                  variant="h6"
                  className={classes.openPhaseTitle}
                  color={phase >= 2 ? "secondary" : "primary"}
                >
                  Meter Read Check
                </Typography>
                {/* The phase card content go here */}
                {phase >= 2 && (
                  <Grid container className={classes.formGrid}>
                    <Grid item xs={12}>
                      <PropertyMeterDataForm />
                    </Grid>

                  </Grid>
                )}
              </TimelineContent>
            </TimelineItem>
            )
          }
            <TimelineItem id={"tlItem"} color="secondary">
              <TimelineSeparator id={"tlSeperator"}>
                <TimelineDot color={phase >= 3 ? "secondary" : "primary"}>
                  #{billingType === 'BILL' ? "4" : "3"}
                </TimelineDot>
                {phase >= 3 ? (
                  <TimelineConnector className={classes.secondaryTail} />
                ) : (
                  <TimelineConnector />
                )}
              </TimelineSeparator>
              <TimelineContent id={"tlContent"}>
                <Typography
                  variant="h6"
                  className={classes.openPhaseTitle}
                  color={phase >= 3 ? "secondary" : "primary"}
                >
                  Trial Billing
                </Typography>
                {/* The phase card content go here */}
                {phase >= 3 && (
                  <Grid container className={classes.formGrid}>
                    <Grid item xs={12}>
                      <TrialBillingForm billingType={billingType}/>
                    </Grid>
                  </Grid>
                )}
              </TimelineContent>
            </TimelineItem>

            <TimelineItem id={"tlItem"} color="secondary">
              <TimelineSeparator id={"tlSeperator"}>
                <TimelineDot color={phase >= 3 ? "secondary" : "primary"}>
                  #{billingType === 'BILL' ? "5" : "4"}
                </TimelineDot>
                {phase >= 3 ? (
                  <TimelineConnector className={classes.secondaryTail} />
                ) : (
                  <TimelineConnector />
                )}
              </TimelineSeparator>
              <TimelineContent id={"tlContent"}>
                <Typography
                  variant="h6"
                  className={classes.openPhaseTitle}
                  color={phase >= 4 ? "secondary" : "primary"}
                >
                  Trial Billing Summary
                </Typography>
                {/* The phase card content go here */}
                {phase >= 3 && (
                  <Grid container className={classes.formGrid}>
                    <Grid item xs={12}>
                      <TrialBillingSummaryForm billingType={billingType}/>
                    </Grid>
                  </Grid>
                )}
              </TimelineContent>
            </TimelineItem>

            <TimelineItem id={"tlItem"} color="secondary">
              <TimelineSeparator id={"tlSeperator"}>
                <TimelineDot color={phase >= 3 ? "secondary" : "primary"}>
                  #{billingType === 'BILL' ? "6" : "5"}
                </TimelineDot>
                {phase >= 4 && (
                  <TimelineConnector className={classes.secondaryTail} />
                )}
              </TimelineSeparator>
              <TimelineContent id={"tlContent"}>
                <Typography
                  variant="h6"
                  className={classes.openPhaseTitle}
                  color={phase >= 4 ? "secondary" : "primary"}
                >
                  Finalize
                </Typography>
                {/* The phase card content go here */}
                {phase >= 3 && (
                  <Grid container className={classes.formGrid}>
                    <Grid item xs={12}>
                      <FinalizeForm />
                    </Grid>
                  </Grid>
                )}
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        )}
      </Paper>
      {/*
        <Snackbar
            className={classes.documentToolBar}
            open={true}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <ToolBar refreshPage={RefreshPage} deleteDraft={DeleteBillingDraftAndCloseTab} regenerateTrialBillItems={updateCurrentBillingObjAndRunTrialBillsGeneration}/>
        </Snackbar>
        */}
    </NavFrame>
  );
};

// Component Properties
BillingWizard.propTypes = {
  // Store objects:
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  billing: PropTypes.object.isRequired,
  propertyUtilities: PropTypes.object.isRequired,
  // Store object functions:
  logout: PropTypes.func.isRequired,
  getAllPropertyPreviews: PropTypes.func.isRequired,
  updateBillingWizardSelectedProperty: PropTypes.func.isRequired,
  updateBillingWizardBillInProgress: PropTypes.func.isRequired,
  getAllUnsettledPropertyMeterReadingsByPropertyUUID: PropTypes.func.isRequired,
  getAllGlCodes: PropTypes.func.isRequired,
  getBillInProgressDraft: PropTypes.func.isRequired,
  getSelectedBillWizPropertyInfoByPropUUID: PropTypes.func.isRequired,
  getAllVendors: PropTypes.func.isRequired,
  setSnackbar: PropTypes.func.isRequired,
  handleBillingProcessSubmission: PropTypes.func.isRequired,
  deleteBillDraftByUUID: PropTypes.func.isRequired,
  getPropertyUtilityByPropertyId: PropTypes.func.isRequired,
};
// Component State
function BillingWizardPageState(state) {
  return {
    user: state.user,
    apl: state.apl,
    billing: state.billing,
    propertyUtilities: state.propertyUtilities
  };
}
export default connect(BillingWizardPageState, {
  handleBillingProcessSubmission,
  getPropertyUtilityByPropertyId
,  logout,
  getAnalyticalData,
  getAllGlCodes,
  getBillInProgressDraft,
  getAllPropertyPreviews,
  setSnackbar,
  getAllUnsettledPropertyMeterReadingsByPropertyUUID,
  getSelectedBillWizPropertyInfoByPropUUID,
  updateBillingWizardSelectedProperty,
  updateBillingWizardBillInProgress,
  getAllVendors,
  deleteBillDraftByUUID,
  getAllBillingDashboardInfo
})(BillingWizard);
