import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import MuiAlert from "@material-ui/lab/Alert";
import Skeleton from "@material-ui/lab/Skeleton";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { setSnackbar } from "../../../modules/snackbar/api/snackbar";
import { passwordReset } from "../../settings/api/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "25ch",
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Profile = (props) => {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    password: "",
    verifyPassword: "",
    oldPassword: "",
    showPassword: false,
    formError: false,
  });

  const [alert, setAlert] = useState({ open: false, message: "", type: "" });
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const handleClose = () => {
    setAlert({ open: false });
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onUpdate = (event) => {
    if (
      values.password == "" ||
      values.verifyPassword == "" ||
      values.oldPassword == ""
    ) {
      setValues({
        formError: true,
        formErrorMessage: "Passwords can not be blank.",
      });
    } else if (values.password != values.verifyPassword) {
      setValues({
        formError: true,
        formErrorMessage: "Passwords do not match.",
      });
    } else {
      const body = {
        uuid: props.user.details.uuid || "",
        oldPassword: values.oldPassword,
        password: values.password,
      };
      props
        .passwordReset(body)
        .then((resp) => {
          dispatch(
            setSnackbar(true, "success", "Password successfully changed.")
          );
        })
        .catch((error) => {
          dispatch(
            setSnackbar(true, "error", "Password error. Please try again")
          );
        });

      setValues({ formError: false });
      console.log(props);
    }
  };

  useEffect(() => {
    if (props.setting.passwordResetStatus == true) {
      setAlert({
        open: true,
        message: "Password reset successful",
        type: "success",
      });
    }
    if (props.setting.error) {
      setAlert({
        open: true,
        message: props.setting.error.toString(),
        type: "error",
      });
    }
  }, [props.setting]);

  return (
    <div className={classes.root}>
      <div>
        {props.user.details.isSuperUser || props.user.details.administration ?
        <Typography variant="h5">Password Reset</Typography>
        : (<h3>You do dont have Profile privileges</h3>)}
        {!props.setting.isLoading ? (
          <div>
            <FormControl
              fullWidth
              className={classes.margin}
              variant="outlined"
            >
              <InputLabel htmlFor="password">Old Password</InputLabel>
              <OutlinedInput
                InputProps={{ inputProps: { tabIndex: -1 } }}
                id="outlined-adornment-password"
                type={values.showPassword ? "text" : "password"}
                value={values.oldPassword || ""}
                onChange={handleChange("oldPassword")}
                error={values.formError}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={105}
              />
            </FormControl>
            <FormControl
              fullWidth
              className={classes.margin}
              variant="outlined"
            >
              <InputLabel htmlFor="password">New Password</InputLabel>
              <OutlinedInput
                InputProps={{ inputProps: { tabIndex: 2 } }}
                id="outlined-adornment-password"
                type={values.showPassword ? "text" : "password"}
                value={values.password || ""}
                onChange={handleChange("password")}
                error={values.formError}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={105}
              />
            </FormControl>
            <FormControl
              fullWidth
              className={classes.margin}
              variant="outlined"
            >
              <InputLabel htmlFor="verifyPassword">Verify Password</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={values.showPassword ? "text" : "password"}
                value={values.verifyPassword || ""}
                error={values.formError}
                onChange={handleChange("verifyPassword")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={115}
              />
            </FormControl>
            {values.formError && (
              <FormHelperText error={values.formError}>
                {values.formErrorMessage}
              </FormHelperText>
            )}
            <Snackbar
              open={alert.open}
              autoHideDuration={6000}
              onClose={handleClose}
            >
              <Alert onClose={handleClose} severity={alert.type}>
                {alert.message}
              </Alert>
            </Snackbar>
            <Button onClick={onUpdate}>Update Password</Button>
          </div>
        ) : (
          <Skeleton
            variant="rect"
            height="60px"
            style={{ backgroundColor: `#bdbfbe`, padding: 6, margin: 6 }}
          />
        )}
        
      </div>
    </div>
  );
};

function ProfileState(state) {
  return {
    setting: state.setting,
    user: state.user,
  };
}

export default connect(ProfileState, { passwordReset })(Profile);
