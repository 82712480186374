//React Components and Hooks
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
//Material UI components
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
//Material UI Functions
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
//Contexts

//Styling
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "& label.Mui-focused": {
      color: theme.palette.secondary.main,
    },
    "& .MuiTextField-underline:after": {
      borderBottomColor: theme.palette.secondary.main,
    },
    "& .MuiTextFieldInput-root": {
      "&:hover fieldset": {
        borderColor: theme.palette.secondary.main,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.secondary.main,
      },
    },
  },
  dropDown: {
    minWidth: 150,
  },
  filter: {},
  filterButton: {},
}));

const TableTools = () => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Grid
        container
        direction="row"
        alignItems="center"
        justify="space-between"
        spacing={3}
      >
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={2}
          justify="center"
          alignItems="center"
        >
          <Grid item>
            <FormControl
              variant="outlined"
              size="small"
              className={classes.dropDown}
            >
              <InputLabel>Property Name</InputLabel>
              <Select autoWidth={true} label="Property Name">
                <option value={10}>All</option>
                ]
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={2} justify="center">
          <Button variant="contained" color="secondary">
            Get Reports
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default TableTools;
