// Imports
import axios from "axios";
import { setSnackbar } from "../../snackbar/api/snackbar";

// App Imports
require("../../../setup/config/apiConfig");

// Actions Types
export const SET_NOTE_LIST = "SET_NOTE_LIST";
export const UPDATE_NOTES_LIST = "UPDATE_NOTES_LIST";

export const NOTE_GET_ACCOUNT_NOTES_REQUEST = "NOTE_GET_ACCOUNT_NOTES_REQUEST";
export const NOTE_GET_ACCOUNT_NOTES_RESPONSE =
  "NOTE_GET_ACCOUNT_NOTES_RESPONSE";

export const NOTE_GET_OPEN_NOTES_REQUEST = "NOTE_GET_OPEN_NOTES_REQUEST";
export const NOTE_GET_OPEN_NOTES_RESPONSE = "NOTE_GET_OPEN_NOTES_RESPONSE";

export const NOTE_NEW_NOTE_REPLY_REQUEST = "NOTE_NEW_NOTE_REPLY_REQUEST";
export const NOTE_NEW_NOTE_REPLY_RESPONSE = "NOTE_NEW_NOTE_REPLY_RESPONSE";

// Delete Note item
export const NOTE_DELETE_ITEM_REQUEST = "NOTE_DELETE_ITEM_REQUEST";
export const NOTE_DELETE_ITEM_RESPONSE = "NOTE_DELETE_ITEM_RESPONSE";

// Update Notes/Replys
export const NOTE_UPDATE_NOTE_REQUEST = "NOTE_UPDATE_NOTE_REQUEST";
export const NOTE_UPDATE_NOTE_RESPONSE = "NOTE_UPDATE_NOTE_RESPONSE";

export const NOTE_UPDATE_NOTE_REPLY_REQUEST = "NOTE_UPDATE_NOTE_REPLY_REQUEST";
export const NOTE_UPDATE_NOTE_REPLY_RESPONSE =
  "NOTE_UPDATE_NOTE_REPLY_RESPONSE";

// Create Notes/Replys
export const NOTE_NEW_NOTE_REQUEST = "NOTE_NEW_NOTE_REQUEST";
export const NOTE_NEW_NOTE_RESPONSE = "NOTE_NEW_NOTE_RESPONSE";
export const NOTE_ADD_NEW_NOTE = "NOTE_ADD_NEW_NOTE";
export const NOTE_ADD_NEW_NOTE_REPLY = "NOTE_ADD_NEW_NOTE_REPLY";

// Actions
export function setNotes(data) {
  return { type: SET_NOTE_LIST, data };
}
export function addNewNote(newNote) {
  return { type: NOTE_ADD_NEW_NOTE, newNote: newNote };
}

export function addNewNoteReply(newNoteReply) {
  return { type: NOTE_ADD_NEW_NOTE_REPLY, newNoteReply: newNoteReply };
}

// For dashboard. Gets paginated notes due and sets them to notes array.
export function getDueNotes({
  page,
  rowsPerPage,
  searchQuery,
  isLoading = true,
}) {
  var target = "?";
  if (page || page == 0) {
    target = target + "page=" + page + "&";
  }
  if (rowsPerPage && rowsPerPage != null) {
    target = target + "pageLimit=" + rowsPerPage + "&";
  }
  if (searchQuery && searchQuery != "") {
    target = target + "searchQuery=" + encodeURIComponent(searchQuery) + "&";
  }
  return (dispatch) => {
    dispatch({
      type: NOTE_GET_OPEN_NOTES_REQUEST,
      isLoading,
    });
    return axios
      .get("/note/due-notes/" + target)
      .then((response) => {
        let error = "";
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }
        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem("user"));
        if (currentUser) {
          dispatch({
            type: NOTE_GET_OPEN_NOTES_RESPONSE,
            dueNotes: response.data,
            error: error,
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: NOTE_GET_OPEN_NOTES_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          });
        }
      })
      .catch((error) => {
        //console.log(error);
        dispatch({
          type: NOTE_GET_OPEN_NOTES_RESPONSE,
          error: error,
        });
      });
  };
}

export function getAccountNotesByAccountUUID(accountUUID, isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: NOTE_GET_ACCOUNT_NOTES_REQUEST,
      isLoading,
    });
    return axios
      .get("/note/accountnotes/" + accountUUID)
      .then((response) => {
        let error = "";
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }
        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem("user"));
        if (currentUser) {
          dispatch(setNotes(response.data));
          dispatch({
            type: NOTE_GET_ACCOUNT_NOTES_RESPONSE,
            error: error,
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: NOTE_GET_ACCOUNT_NOTES_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          });
        }
      })
      .catch((error) => {
        //console.log(error);
        dispatch({
          type: NOTE_GET_ACCOUNT_NOTES_RESPONSE,
          error: error,
        });
      });
  };
}

export function createNewNote(newNote, User, isLoading = true) {
  return (dispatch) => {
    dispatch(setSnackbar(true, "warning", "Creating new account note"));
    dispatch({
      type: NOTE_NEW_NOTE_REQUEST,
      isLoading,
    });
    return axios
      .post("/note", newNote)
      .then((response) => {
        let error = "";
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }
        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem("user"));
        if (currentUser) {
          response.data.creator = User;
          response.data.creatorName = User.firstName + ", " + User.lastName;
          dispatch(addNewNote(response.data));
          if (response.status == 200) {
            dispatch(setSnackbar(true, "success", "Account Note Added"));
          }
          else {
            dispatch(setSnackbar(true, "error", "Error Posting Account Note"));
          }
          dispatch({
            type: NOTE_NEW_NOTE_RESPONSE,
            error: error,
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: NOTE_NEW_NOTE_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          });
        }
      })
      .catch((error) => {
        //console.log(error);
        dispatch({
          type: NOTE_NEW_NOTE_RESPONSE,
          error: error,
        });
      });
  };
}

export function createNewNoteReply(newReply, User, isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: NOTE_NEW_NOTE_REPLY_REQUEST,
      isLoading,
    });
    return axios
      .post("/notereply", newReply)
      .then((response) => {
        let error = "";
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }
        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem("user"));
        if (currentUser) {
          response.data.creator = User;
          response.data.creatorName = User.firstName + ", " + User.lastName;
          dispatch(addNewNoteReply(response.data));
          dispatch({
            type: NOTE_NEW_NOTE_REPLY_RESPONSE,
            error: error,
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: NOTE_NEW_NOTE_REPLY_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          });
        }
      })
      .catch((error) => {
        //console.log(error);
        dispatch({
          type: NOTE_NEW_NOTE_REPLY_RESPONSE,
          error: error,
        });
      });
  };
}

export function updateNote(note, isLoading = false) {
  return (dispatch) => {
    dispatch({
      type: NOTE_UPDATE_NOTE_REQUEST,
      isLoading,
    });
    return axios
      .put("/note/" + note.uuid, note)
      .then((response) => {
        let error = "";
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }
        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem("user"));
        if (currentUser) {
          dispatch({
            type: NOTE_UPDATE_NOTE_RESPONSE,
            error: error,
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: NOTE_UPDATE_NOTE_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          });
        }
      })
      .catch((error) => {
        //console.log(error);
        dispatch({
          type: NOTE_UPDATE_NOTE_RESPONSE,
          error: error,
        });
      });
  };
}

export function updateNoteReply(reply, isLoading = false) {
  return (dispatch) => {
    dispatch({
      type: NOTE_UPDATE_NOTE_REPLY_REQUEST,
      isLoading,
    });
    return axios
      .put("/notereply/" + reply.uuid, reply)
      .then((response) => {
        let error = "";
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }
        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem("user"));
        if (currentUser) {
          dispatch({
            type: NOTE_UPDATE_NOTE_REPLY_RESPONSE,
            error: error,
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: NOTE_UPDATE_NOTE_REPLY_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          });
        }
      })
      .catch((error) => {
        //console.log(error);
        dispatch({
          type: NOTE_UPDATE_NOTE_REPLY_RESPONSE,
          error: error,
        });
      });
  };
}

export function deleteNoteOrReply(item, isLoading = false) {
  var targetURL = "/notereply/";
  if (item.note) {
    targetURL = "/note/";
  }
  return (dispatch) => {
    dispatch({
      type: NOTE_DELETE_ITEM_REQUEST,
      isLoading,
    });
    return axios
      .delete(targetURL + item.uuid)
      .then((response) => {
        let error = "";
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }
        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem("user"));
        if (currentUser) {
          dispatch({
            type: NOTE_DELETE_ITEM_RESPONSE,
            error: error,
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: NOTE_DELETE_ITEM_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          });
        }
      })
      .catch((error) => {
        //console.log(error);
        dispatch({
          type: NOTE_DELETE_ITEM_RESPONSE,
          error: error,
        });
      });
  };
}
