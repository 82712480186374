//React Components and Hooks
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
//Material UI Components
import Typography from "@material-ui/core/Typography";
import { PageTitle } from "layout-components";
import React from "react";
import { Link } from "react-router-dom";
//Material UI Icons
//Custom Components
//Context
const StandardHeader = (
  props /*{props, props.title., props.rightSideToolBar, props.appPath}*/
) => {
  const useStyles = makeStyles((theme) => {
    return {
      dropdown: {
        width: "14rem",
      },
      modalcontainer: {
        width: "60%",
        height: "60%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "15px",
        padding: "3rem",
      },
      link: {
        textDecoration: "none",
        color: theme.palette.secondary.main,
        "&:hover": {
          fontWeight: "bold",
          textDecoration: "underline",
        },
      },
      pageEmergencyHeaderFontColor: {
        color: "#ff0000",
        fontWeight: "bold",
      },
      rightSideHeader: {
        display: "flex",
        alignItems: "flex-end",
        flexDirection: "row",
        justifyContent: "flex-end",
      },

      leftSideHeader: {
        display: "flex",
        justifyContent: "left",
        alignItems: "left",
      },
      titleText: {
        marginBottom: "5px",
      },
      pathText: {
        marginTop: "5px",
      },
      root: {
        display: "flex",
        justifyContent: "left",
        flexWrap: "wrap",
        "& > *": {
          margin: theme.spacing(0.5),
        },
      },
    };
  });
  const classes = useStyles();
  var headerStyleClass = {};
  if (props.pageEmergency) {
    headerStyleClass = classes.pageEmergencyHeaderFontColor;
  }

  return (
    <Grid container direction="row">
      <Grid item lg={7} md={12} sm={12}>
        <Typography
          variant="h3"
          color="textPrimary"
          className={(classes.titleText, headerStyleClass)}
        >
          {props.title || "UNKNOWN_TITLE"}
        </Typography>
        <div className={classes.root}>
          {props.headerTags && props.headerTags.length > 0 && props.headerTags}
        </div>
        <Breadcrumbs aria-label="breadcrumb" className={classes.pathText}>
          <Link className={classes.link} to="/dashboard">
            UBIL +
          </Link>
          <Typography>
            {props.appPath || props.title || "UNKNOWN_LOCATION"}
          </Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item className={classes.rightSideHeader} lg={5} md={12} sm={12}>
        {props.rightSideToolBar}
      </Grid>
    </Grid>
  );
};

export default StandardHeader;
