import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";

import Helmet from "react-helmet";

import {
  Checkbox,
  Grid,
  IconButton,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  FormControlLabel,
  Switch,
  Menu,
  MenuItem,
  TextField,
  Button as MuiButton,
  FormControl,
  InputLabel,
  Select,
  Input,
} from "@material-ui/core";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  Loop as LoopIcon,
} from "@material-ui/icons";

import { spacing } from "@material-ui/system";

import {
  EditableTable,
  newDropDownField,
} from "../../../components/EditableTable";

// Redux Components
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logout } from "../../user/api/actions";
import { getAllPropertyPreviews } from "../../apl/api/actions";
import {
  updateBillingWizardSelectedProperty,
  updateBillingWizardBillInProgress,
  getAllUnsettledPropertyMeterReadingsByPropertyUUID,
  handleBillingProcessSubmission,
} from "../../billing/api/actions";

const Button = styled(MuiButton)(spacing);

const DashboardToolBarButton = styled(Button)`
  padding: 4px;
  min-width: 0;
  svg {
    width: 2em;
    height: 2em;
  }
`;

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Spacer = styled.div`
  flex: 1 1 100%;
`;

//Editable Table Object Expects: {objectArray, fieldTitles(Array), fieldTypes(Array), fieldNames(Array), handleRowClickParentMethod}
// Method can take line items if they exist initially or starts as an empty array of nothing passed
function AdditionalBillLineItemsManager(props) {
  const fieldTitles = [
    "Description",
    "Cost Type",
    "Amount (USD)",
    "Flat Rate",
    "Use SQFT",
  ];
  const fieldTypes = ["text", "dropDown", "number", "checkBox", "checkBox"];
  const fieldNames = ["itemDesc", "acctDesc", "itemAmt", "flatRate", "useSqft"]; // Must be the exact name of the properties you wanna show

  // This is how a dropdown is handled: In my instance im importing that data so i need to check if its there yet before assigning it to the dropdown field:
  // Expects: newDropDownField(targetFieldName, values, displayValueName(What property do you want shown in the listbox), selectValueName(what property will selecting an item assign))
  //(targetFieldName, values, displayValueName, selectValueName
  const resourceFields = [
    newDropDownField(
      "acctDesc",
      props.apl.genericTypes.billingAccountGLCode.data || [],
      "accountDescription",
      "uuid"
    ),
  ];

  const createLineItemData = (
    id,
    uuid,
    itemDesc,
    acctDesc,
    itemAmt,
    flatRate,
    useSqft
  ) => {
    return { id, uuid, itemDesc, acctDesc, itemAmt, flatRate, useSqft };
  };

  const createBlankLineItem = () => {
    return createLineItemData(-1, -1, "", "", 0, false, false);
  };

  const addBlankItemToArray = () => {
    props.billing.data.billInProgress.vendorInformation.vendorBillPassthroughItems.items.push(
      createBlankLineItem()
    );
    props.updateBillingWizardBillInProgress(props.billing.data.billInProgress);
  };

  const updateLocalItemsValues = (items) => {
    props.billing.data.billInProgress.vendorInformation.vendorBillPassthroughItems =
      items;
    // Replaces existing store object with new object with modified sub data
    props.updateBillingWizardBillInProgress(props.billing.data.billInProgress);
  };

  // To be used to save over the api in the database
  const saveAllLineItems = (items) => {
    props.billing.data.billInProgress.vendorInformation.vendorBillPassthroughItems =
      items;
    props.handleBillingProcessSubmission(
      props.billing.data.billInProgress,
      false
    );
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} lg={12} md={12}>
        <EditableTable
          // Functional methods of table
          newItemCreationMethod={addBlankItemToArray}
          saveCurrentTableData={saveAllLineItems}
          updateItemsLocalState={updateLocalItemsValues}
          dropDownFields={resourceFields}
          // Properties of table
          tableTitle={"Additional Vendor Bill Line Items:"}
          objectArray={
            props.billing.data.billInProgress.vendorInformation
              .vendorBillPassthroughItems
          }
          fieldTitles={fieldTitles}
          saveOnTextboxLeave={true}
          fieldTypes={fieldTypes}
          canSave={false}
          fieldNames={fieldNames}
          defaultItemCount={5}
          handleRowClickParentMethod={() => {}}
        />
      </Grid>
    </Grid>
  );
}

// Component Properties
AdditionalBillLineItemsManager.propTypes = {
  // Store objects:
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  billing: PropTypes.object.isRequired,
  // Store object functions:
  getAllPropertyPreviews: PropTypes.func.isRequired,
  updateBillingWizardSelectedProperty: PropTypes.func.isRequired,
  updateBillingWizardBillInProgress: PropTypes.func.isRequired,
  getAllUnsettledPropertyMeterReadingsByPropertyUUID: PropTypes.func.isRequired,
  handleBillingProcessSubmission: PropTypes.func.isRequired,
};

// Component State
function AdditionalBillLineItemsManagerState(state) {
  return {
    user: state.user,
    apl: state.apl,
    billing: state.billing,
  };
}
export default connect(AdditionalBillLineItemsManagerState, {
  logout,
  updateBillingWizardSelectedProperty,
  updateBillingWizardBillInProgress,
  getAllPropertyPreviews,
  handleBillingProcessSubmission,
  getAllUnsettledPropertyMeterReadingsByPropertyUUID,
})(AdditionalBillLineItemsManager);
