import { Box, Divider as MuiDivider } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { spacing } from "@material-ui/system";
import axios from "axios";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import styled from "styled-components/macro";
import StandardHeader from "../../components/global/StandardHeader";
import { LeftSidebar as NavFrame } from "layout-blueprints";
import ARTable from "../../modules/accounting/components/AccountReceivablesTable";
import { logout } from "../../modules/user/api/actions";
import {
  getAllPropertyPreviews,
  getAnalyticalData,
} from "../../modules/apl/api/actions";
import SimpleStatCard from "../../components/cards/SimpleStatCard";
import AgingTable from "../../modules/accounting/components/AgingTable";
// snackbar
import { setSnackbar } from "../../modules/snackbar/api/snackbar";
// components
import AcumaticaActions from "./components/AcumaticaActions";
import AcumaticaStatus from "./components/AcumaticaStatus";
import CashIncome from "./components/CashIncome";
import DeletedBills from "./components/DeletedBills";
import PaymentsByType from "./components/PaymentsByType";
import { formatMoney } from "../../setup/helpers";

const Divider = styled(MuiDivider)(spacing);

const AccountingPage = (props) => {
  const dispatch = useDispatch();

  const [value, setValue] = React.useState(0); // used for tabs

  // Acumatica Actions and Status State
  let [acumaticaDate, setAcumaticaDate] = useState("");
  let [acumaticaConnected, setAcumaticaConnected] = useState(false);
  let [acumaticaStatusLoading, setAcumaticaStatusLoading] = useState(true);
  let [batchesRan, setBatchesRan] = useState([]);
  let [incomeStatementsRan, setIncomeStatementsRan] = useState([]);

  // report data
  let [paymentsInfo, setPaymentsInfo] = useState([]);
  let [paymentsInfoLoading, setPaymentsInfoLoading] = useState(true);

  const [utilityValue, setUtilityValue] = useState("1"); // 1 = CCOS, 2 = CCOS
  async function getPaymentsByDate() {
    try {
      setPaymentsInfoLoading(true);
      let headers = {
        "x-access-token": localStorage.getItem("token"),
      };

      let data = await axios.put(
        "/report/call",
        {
          function: "GetPaymentsByDate",
          params: [moment().format("YYYY-MM-DD")],
        },
        { headers }
      );

      setPaymentsInfoLoading(false);
      setPaymentsInfo(data.data[0]);
    } catch (err) {
      setPaymentsInfoLoading(false);
      dispatch(setSnackbar(true, "error", "Failed to get payments."));
    }
  }
  useEffect(() => {
    if(props.user.details.accounting || props.user.details.isSuperUser){
    async function testAcumaticaStatus() {
      try {
        setAcumaticaStatusLoading(true);
        let headers = {
          "x-access-token": localStorage.getItem("token"),
        };

        let res = await axios.get("/run-acumatica-status", { headers });

        if (!res) throw new Error("Fail.");

        setAcumaticaConnected(true);

        setAcumaticaStatusLoading(false);
      } catch (err) {
        setAcumaticaStatusLoading(false);
        setAcumaticaConnected(false);
        dispatch(setSnackbar(true, "error", "Failed to fetch status."));
      }
    }

   

    testAcumaticaStatus();
    getPaymentsByDate();
    document.title = "Ubil+ Accounting Dashboard";
    props.getAllPropertyPreviews();
    props.getAnalyticalData(props.apl.analyticRange);
    }
  }, [props.user]);

  // change tabs
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div {...other}>{value === index && <Box p={3}>{children}</Box>}</div>
    );
  }

  return (
    <NavFrame page={"Accounting"}>
      <StandardHeader title="Accounting" />
      <Divider className="mb-3 mt-3" />
      {props.user.details.accounting || props.user.details.administration || props.user.details.isSuperUser ? (
      <Grid container spacing={1} xs={12} sm={12} md={12} lg={12}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
        
          <AppBar position="static">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
            >
              <Tab label="Home" />
              <Tab label="Aging" />
              <Tab label="Receipts" />
              <Tab label="Deleted Bills" />
            </Tabs>
          </AppBar>

          {/* Home Tab */}
          <TabPanel value={value} index={0}>
            <Grid container spacing={6} xs={12} lg={12}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <SimpleStatCard
                  title="Cash Received"
                  amount={
                    props.apl.analyticSummary?.paymentsSummary
                      ? formatMoney(
                        props.apl.analyticSummary.paymentsSummary.summary
                          .amountPaid
                      ) || 0
                      : "N/A"
                  }
                  chip={props.apl.analyticRange}
                  isLoaded={
                    !props.apl.isLoading && !props.apl.analyticSummaryIsLoading
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <SimpleStatCard
                  title="Total Billed"
                  amount={
                    props.apl.analyticSummary?.billingSummary
                      ? formatMoney(
                        props.apl.analyticSummary?.billingSummary.summary
                          .totalBilled
                      ) || 0
                      : "N/A"
                  }
                  chip={props.apl.analyticRange}
                  isLoaded={
                    !props.apl.isLoading && !props.apl.analyticSummaryIsLoading
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <AcumaticaActions
                  acumaticaDate={acumaticaDate}
                  setAcumaticaDate={setAcumaticaDate}
                  utilityValue={utilityValue}
                  setUtilityValue={setUtilityValue}
                  acumaticaConnected={acumaticaConnected}
                  setSnackbar={setSnackbar}
                  dispatch={dispatch}
                  batchesRan={batchesRan}
                  setBatchesRan={setBatchesRan}
                />
                <AcumaticaStatus
                  acumaticaConnected={acumaticaConnected}
                  acumaticaStatusLoading={acumaticaStatusLoading}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={8}>
                <CashIncome
                  setSnackbar={setSnackbar}
                  dispatch={dispatch}
                  incomeStatementsRan={incomeStatementsRan}
                  setIncomeStatementsRan={setIncomeStatementsRan}
                />
                <PaymentsByType
                  paymentsInfo={paymentsInfo}
                  paymentsInfoLoading={paymentsInfoLoading}
                />
              </Grid>
            </Grid>
          </TabPanel>

          {/* Aging Tab  */}
          <TabPanel value={value} index={1}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <AgingTable />
            </Grid>
          </TabPanel>

           {/* Receipts Tab  */}
          <TabPanel value={value} index={2}>
            <ARTable />
          </TabPanel>

           {/* Deleted Bills Tab  */}
          <TabPanel value={value} index={3}>
            <Grid container spacing={2} xs={12} sm={12} md={12} lg={12}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <DeletedBills />
              </Grid>
            </Grid>
          </TabPanel>
       

        </Grid>
      </Grid >
        ) :
        (<h3>You do not have accounting privilages</h3> )}
    </NavFrame >
  );
};

// Component Properties
AccountingPage.propTypes = {
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  getAnalyticalData: PropTypes.func.isRequired,
  getAllPropertyPreviews: PropTypes.func.isRequired,
};

// Component State
function AccountingPageState(state) {
  return {
    user: state.user,
    apl: state.apl,
  };
}
export default connect(AccountingPageState, {
  logout,
  getAnalyticalData,
  getAllPropertyPreviews,
})(AccountingPage);
