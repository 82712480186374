import React from "react";

export default ({ color, text, helper }) => {
  return (
    <div className="moves-legendbox">
      <span
        className="moves-legendbox-bullet"
        style={{ backgroundColor: color }}
      ></span>
      &nbsp;&nbsp;[{text}] = {helper}
    </div>
  );
};
