import {
  GET_PROPERTY_GLCODES,
  GET_PROPERTY_GLCODES_RESPONSE,
  GET_PROPERTY_GLCODE,
  GET_PROPERTY_GLCODE_RESPONSE,
  POST_PROPERTY_GLCODE,
  POST_PROPERTY_GLCODE_FAIL,
  POST_PROPERTY_GLCODE_RESPONSE,
  PUT_PROPERTY_GLCODE,
  PUT_PROPERTY_GLCODE_FAIL,
  PUT_PROPERTY_GLCODE_RESPONSE,
  RESET,
  UPDATE_PROPERTY_GLCODE_LOCAL_STATE,
} from "./actions";

const initialState = {
  isLoading: false,
  propertyGLCodeIsLoading: false,
  postPropertyGLCodeError: null,
  putPropertyGLCodeError: null,
  error: null,
  data: {
    propertyGLCodesList: {
      items: [],
    },
    propertyGLCode: null,
    searchQuery: null,
    range: { start: 0, end: 25 },
  },
};

// State
export default (state = initialState, action) => {
  var data = state.data;
  switch (action.type) {
    case RESET:
      return Object.assign({}, initialState);
    case UPDATE_PROPERTY_GLCODE_LOCAL_STATE:
      let newStateData = state.data;
      newStateData.propertyGLCode = action.newPropertyGLCode || null;
      return {
        ...state,
        data: newStateData,
      };
    case GET_PROPERTY_GLCODE:
      return {
        ...state,
        error: null,
        propertyGLCodeIsLoading: action.isLoading,
      };
    case GET_PROPERTY_GLCODE_RESPONSE:
      data.propertyGLCode = action.propertyGLCode;
      return {
        ...state,
        data: data,
        error: action.error,
        propertyGLCodeIsLoading: false,
      };
    case GET_PROPERTY_GLCODE:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case GET_PROPERTY_GLCODE_RESPONSE:
      data.propertyGLCodesList.items = action.propertyGLCodes;
      return {
        ...state,
        data: data,
        error: action.error,
        isLoading: false,
      };
    case POST_PROPERTY_GLCODE:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case POST_PROPERTY_GLCODE_RESPONSE:
      return {
        ...state,
        data: data,
        error: action.error,
        isLoading: false,
      };
    case POST_PROPERTY_GLCODE_FAIL:
      return {
        ...state,
        data: data,
        error: action.error,
        postPropertyGLCodeError: false,
      };
    case PUT_PROPERTY_GLCODE:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case PUT_PROPERTY_GLCODE_RESPONSE:
      return {
        ...state,
        data: { propertyGLCode: action.propertyGLCode },
        error: action.error,
        isLoading: false,
      };
    case PUT_PROPERTY_GLCODE_FAIL:
      return {
        ...state,
        data: data,
        error: action.error,
        putPropertyGLCodeError: false,
      };
    default:
      return {
        ...state,
      };
  }
};
