import axios from "axios";
import { setSnackbar } from "../../../modules/snackbar/api/snackbar";
import { GET } from "../../genericAPI/api/actions";

export const GET_MESSAGE_SETTING_REQUEST =
  "SETTINGS/GET_MESSAGE_SETTING_REQUEST";
export const GET_MESSAGE_SETTING_RESPONSE =
  "SETTINGS/GET_MESSAGE_SETTING_RESPONSE";
export const GET_MESSAGE_SETTING_FAILURE =
  "SETTINGS/GET_MESSAGE_SETTING_FAILURE";

export const GET_SETTING_REQUEST = "SETTINGS/GET_SETTING_REQUEST";
export const GET_SETTING_RESPONSE = "SETTINGS/GET_SETTING_RESPONSE";
export const GET_SETTING_FAILURE = "SETTINGS/GET_SETTING_FAILURE";

export const GET_REPORTS_REQUEST = "SETTINGS/GET_REPORTS_REQUEST";
export const GET_REPORTS_RESPONSE = "SETTINGS/GET_REPORTS_RESPONSE";
export const GET_REPORTS_FAILURE = "SETTINGS/GET_REPORTS_FAILURE";

export const UPDATE_MESSAGE_SETTING_REQUEST =
  "SETTINGS/UPDATE_MESSAGE_SETTING_REQUEST";
export const UPDATE_MESSAGE_SETTING_RESPONSE =
  "SETTINGS/UPDATE_MESSAGE_SETTING_RESPONSE";
export const UPDATE_MESSAGE_SETTING_FAILURE =
  "SETTINGS/UPDATE_MESSAGE_SETTING_FAILURE";

export const UPDATE_SETTING_REQUEST = "SETTINGS/UPDATE_SETTING_REQUEST";
export const UPDATE_SETTING_RESPONSE = "SETTINGS/UPDATE_SETTING_RESPONSE";
export const UPDATE_SETTING_FAILURE = "SETTINGS/UPDATE_SETTING_FAILURE";

export const GET_ACTIVE_USERS_REQUEST = "SETTINGS/GET_ACTIVE_USERS_REQUEST";
export const GET_ACTIVE_USERS_RESPONSE = "SETTINGS/GET_ACTIVE_USERS_RESPONSE";
export const GET_ACTIVE_USERS_FAILURE = "SETTINGS/GET_ACTIVE_USERS_FAILURE";

export const UPDATE_ACTIVE_USERS_REQUEST =
  "SETTINGS/UPDATE_ACTIVE_USERS_REQUEST";
export const UPDATE_ACTIVE_USERS_RESPONSE =
  "SETTINGS/UPDATE_ACTIVE_USERS_RESPONSE";
export const UPDATE_ACTIVE_USERS_FAILURE =
  "SETTINGS/UPDATE_ACTIVE_USERS_FAILURE";

export const REGISTER_ACTIVE_USERS_REQUEST =
  "SETTINGS/REGISTER_ACTIVE_USERS_REQUEST";
export const REGISTER_ACTIVE_USERS_RESPONSE =
  "SETTINGS/REGISTER_ACTIVE_USERS_RESPONSE";
export const REGISTER_ACTIVE_USERS_FAILURE =
  "SETTINGS/REGISTER_ACTIVE_USERS_FAILURE";

export const PASSWORD_RESET_REQUEST = "SETTINGS/PASSWORD_RESET_REQUEST";
export const PASSWORD_RESET_RESPONSE = "SETTINGS/PASSWORD_RESET_RESPONSE";
export const PASSWORD_RESET_FAILURE = "SETTINGS/PASSWORD_RESET_FAILURE";

export const GET_PDF_MESSAGE_SETTINGS_REQUEST = "SETTINGS/GET_PDF_MESSAGE_SETTING_REQUEST";
export const GET_PDF_MESSAGE_SETTINGS_RESPONSE = "SETTINGS/GET_PDF_MESSAGE_SETTING_RESPONSE";
export const GET_PDF_MESSAGE_SETTINGS_FAILURE = "SETTINGS/GET_PDF_MESSAGE_SETTING_FAILURE";

export const UPDATE_PDF_MESSAGE_SETTINGS_REQUEST = "SETTINGS/UPDATE_PDF_MESSAGE_SETTING_REQUEST";
export const UPDATE_PDF_MESSAGE_SETTINGS_RESPONSE = "SETTINGS/UPDATE_PDF_MESSAGE_SETTING_RESPONSE";
export const UPDATE_PDF_MESSAGE_SETTINGS_FAILURE = "SETTINGS/UPDATE_PDF_MESSAGE_SETTING_FAILURE";

export function getMessageSettings(isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: GET_MESSAGE_SETTING_REQUEST,
      isLoading: isLoading,
    });
    return axios
      .get(`/message`)
      .then((response) => {
        let error = "";
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }

        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem("user"));
        if (currentUser) {
          dispatch({
            type: GET_MESSAGE_SETTING_RESPONSE,
            isLoading: false,
            response: response.data,
            error: error,
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: GET_MESSAGE_SETTING_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_MESSAGE_SETTING_FAILURE,
          isLoading: false,
          error: error,
        });
      });
  };
}

export function getSettings(isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: GET_SETTING_REQUEST,
      isLoading: isLoading,
    });
    return axios
      .get(`/setting`)
      .then((response) => {
        let error = "";
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }

        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem("user"));
        if (currentUser) {
          dispatch({
            type: GET_SETTING_RESPONSE,
            isLoading: false,
            response: response.data,
            error: error,
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: GET_SETTING_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_SETTING_FAILURE,
          isLoading: false,
          error: error,
        });
      });
  };
}

export function updateMessageSetting(uuid, body, isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_MESSAGE_SETTING_REQUEST,
      isLoading: isLoading,
    });
    return axios
      .put(`/message/${uuid}`, body)
      .then((response) => {
        let error = "";

        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }

        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem("user"));
        if (currentUser) {
          dispatch({
            type: UPDATE_MESSAGE_SETTING_RESPONSE,
            isLoading: false,
            response: response.data,
            error: error,
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: UPDATE_MESSAGE_SETTING_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_MESSAGE_SETTING_FAILURE,
          isLoading: false,
          error: error,
        });
      });
  };
}

export function updateSetting(uuid, body, isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SETTING_REQUEST,
      isLoading: isLoading,
    });
    return axios
      .put(`/setting/${uuid}`, body)
      .then((response) => {
        let error = "";

        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }

        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem("user"));
        if (currentUser) {
          dispatch({
            type: UPDATE_SETTING_RESPONSE,
            isLoading: false,
            response: response.data,
            error: error,
          });

          if (response.status == 200) {
            dispatch(
              setSnackbar(true, "success", "Successfully updated setting")
            );
          } else {
            dispatch(
              setSnackbar(
                true,
                "error",
                "Setting not updated. Please try again."
              )
            );
          }
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: UPDATE_SETTING_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_SETTING_FAILURE,
          isLoading: false,
          error: error,
        });
        dispatch(
          setSnackbar(true, "error", "Setting not updated. Please try again.")
        );
      });
  };
}

export function passwordReset(body, isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: PASSWORD_RESET_REQUEST,
      isLoading: isLoading,
    });
    return axios
      .post(`/profile-password-reset`, body)
      .then((response) => {
        let error = "";

        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }

        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem("user"));
        if (currentUser) {
          console.log(response.data);
          dispatch({
            type: PASSWORD_RESET_RESPONSE,
            isLoading: false,
            response: response.data,
            error: error,
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: PASSWORD_RESET_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: PASSWORD_RESET_FAILURE,
          isLoading: false,
          error: error,
        });
      });
  };
}

export function getActiveUsers(isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: GET_ACTIVE_USERS_REQUEST,
      isLoading: isLoading,
    });
    return axios
      .get(`/active-internal-users`)
      .then((response) => {
        let error = "";
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }

        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem("user"));
        if (currentUser) {
          dispatch({
            type: GET_ACTIVE_USERS_RESPONSE,
            isLoading: false,
            response: response.data,
            error: error,
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: GET_ACTIVE_USERS_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_MESSAGE_SETTING_FAILURE,
          isLoading: false,
          error: error,
        });
      });
  };
}

export function updateActiveUser(uuid, body, isLoading = true) {
  body.uuid = uuid;
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACTIVE_USERS_REQUEST,
      isLoading: isLoading,
    });
    return axios
      .put(`/update-internal-user`, body)
      .then((response) => {
        let error = "";

        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }

        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem("user"));
        if (currentUser) {
          dispatch({
            type: UPDATE_ACTIVE_USERS_RESPONSE,
            isLoading: false,
            response: response.data,
            error: error,
          });
          dispatch(setSnackbar(true, "success", "Successfully updated user."));

        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: UPDATE_ACTIVE_USERS_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_MESSAGE_SETTING_FAILURE,
          isLoading: false,
          error: error,
        });
        dispatch(setSnackbar(true, "error", "Failed to update user."));

      });
  };
}

export function registerActiveUser(body, isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: REGISTER_ACTIVE_USERS_REQUEST,
      isLoading: isLoading,
    });
    return axios
      .post(`/register-internal-user`, body)
      .then((response) => {
        dispatch({
          propertyManager: response.data,
          type: REGISTER_ACTIVE_USERS_RESPONSE,
        });
      })
      .catch((error) => {
        //console.log(error);
        dispatch({
          type: REGISTER_ACTIVE_USERS_FAILURE,
          error: error,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: REGISTER_ACTIVE_USERS_FAILURE,
          isLoading: false,
          error: error,
        });
      });
  };
}

export function getReports() {
  return (dispatch) => {
    dispatch(GET("report", "", GET_REPORTS_REQUEST, GET_REPORTS_RESPONSE));
  };
}

export function getPDFMessageSettings(isLoading = true){
  return (dispatch) => {
    dispatch({
      type: GET_PDF_MESSAGE_SETTINGS_REQUEST,
      isLoading,
    })
    return axios
    .get(`/pdf-message`)
    .then((response) => {
      let error = "";
      if (response.data.errors && response.data.errors.length > 0) {
        error = response.data.errors[0].message;
      }

      const currentUser = JSON.parse(window.localStorage.getItem("user"));
      if (currentUser) {
        dispatch({
          type: GET_PDF_MESSAGE_SETTINGS_RESPONSE,
          isLoading: false,
          response: response.data,
          error: error,
        });
      } else {
        // if no user is found then clear object because its an unauthorized access
        dispatch({
          type: GET_PDF_MESSAGE_SETTINGS_RESPONSE,
          isLoading: false,
          error: "ERROR! NO USER LOGGED IN!",
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: GET_PDF_MESSAGE_SETTINGS_FAILURE,
        isLoading: false,
        error: error,
      });
      throw error;
    });
  }
}

export function updatePDFMessageSetting(uuid, body, isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_PDF_MESSAGE_SETTINGS_REQUEST,
      isLoading: isLoading,
    });
    return axios
    .put(`/pdf-message/${uuid}`, body)
    .then((response) => {
      let error = "";

      if (response.data.errors && response.data.errors.length > 0) {
        error = response.data.errors[0].message;
      }

      const currentUser = JSON.parse(window.localStorage.getItem("user"));
      if (currentUser) {
        dispatch({
          type: UPDATE_PDF_MESSAGE_SETTINGS_RESPONSE,
          isLoading: false,
          response: response.data,
          error: error,
        });
      } else {
        dispatch({
          type: UPDATE_PDF_MESSAGE_SETTINGS_RESPONSE,
          isLoading: false,
          error: "ERROR! NO USER LOGGED IN!",
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: UPDATE_PDF_MESSAGE_SETTINGS_FAILURE,
        isLoading: false,
        error: error,
      });
      throw error;
    });
  };
}

