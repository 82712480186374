import {
  Button as MuiButton,
  Card,
  CardContent,
  CircularProgress as MuiCircularProgress,
  Divider as MuiDivider,
  Grid,
  LinearProgress as MuiLinearProgress,
  Typography
} from "@material-ui/core";

import { formatMoney } from "../../../setup/helpers";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import { spacing } from "@material-ui/system";
// Redux Components
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { EditableTable } from "../../../components/EditableTable";
import AccountLedgerPreview from "./AccountLedgerPreview";

const styles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  AccountInfoGrid: {
    height: "100%",
    overflowY: "auto",
  },
}));

const LinearProgress = MuiLinearProgress;
const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);

const AccountLedgerPreviewPopup = (props) => {
  const history = useHistory();
  const useStyles = styles;
  const classes = useStyles();
  if (props.account.ledgerIsLoading) {
    return (
      <>
        <Skeleton
          variant="rect"
          height="400px"
          style={{
            backgroundColor: `#bdbfbe`,
            padding: 6,
            margin: 6,
            marginBottom: 3,
          }}
        />
      </>
    )
  };
  return (
    <>
      <Grid
        container
        spacing={1}
        lg={12}
        className={classes.AccountInfoGrid}
      >
        <Grid item xs={12} lg={12} >
          <AccountLedgerPreview ledgerType={props.ledgerType} />
        </Grid>
        <Grid item xs={12} lg={12} >
          <Divider className="mb-2 mt-2" my={1} />
        </Grid>
      </Grid>
    </>
  );
};

// Component Properties
AccountLedgerPreviewPopup.propTypes = {
  // Store objects:
  account: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  ledgerType: PropTypes.string.isRequired,
};

// Component State
function AccountLedgerPreviewPopupState(state) {
  return {
    account: state.account,
    apl: state.apl
  };
}
export default connect(
  AccountLedgerPreviewPopupState,
  {}
)(AccountLedgerPreviewPopup);
