import {
  Card as MuiCard,
  CardContent,
  Grid,
  IconButton,
  Slide,
  TextField as MuiTextField,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { spacing } from "@material-ui/system";
// Redux Components
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import { EditableTable } from "../../../components/EditableTable";
import { getDueNotes } from "../api/actions";

const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function DueNotesTable(props) {
  const [searchQuery, setSearchQuery] = React.useState("");
  const [resetCache, setResetCachedData] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // When the textbox changes
  const handleSearchTargetChange = (event) => {
    setPage(0);
    setResetCachedData(true);
    setSearchQuery(event.target.value);
  };

  const CurrentLookupTypeButton = () => {
    return (
      <IconButton>
        <SearchIcon mr={2} />
      </IconButton>
    );
  };

  const handleChangeRowsPerPage = (rowsPerPage) => {
    setPage(0);
    setResetCachedData(false);
    setRowsPerPage(rowsPerPage);
  };

  const handleChangePage = (page) => {
    setResetCachedData(false);
    setPage(page);
  };

  const getDueNotesWithQuery = () => {
    const query = { page, rowsPerPage, searchQuery, resetCache };
    props.getDueNotes(query);
  };

  useEffect(() => {
    if (
      (page + 1) * rowsPerPage > props.notes.dueNotes.items.length ||
      resetCache
    ) {
      getDueNotesWithQuery();
    }
  }, [page, rowsPerPage, searchQuery]);

  const SearchBar = () => {
    return (
      <TextField
        id={"searchTarget"}
        label="Search"
        type={"text"}
        onChange={handleSearchTargetChange}
        autoFocus={"autofocus"}
        name={"searchTarget"}
        value={searchQuery}
        fullWidth
        my={2}
        size={"small"}
        variant={"outlined"}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{ endAdornment: <CurrentLookupTypeButton /> }}
        helperText="Search by any visible line item characteristics"
      ></TextField>
    );
  };

  // Table Stuff:
  const fieldTitles = ["Customer", "Note", "Due Date"];
  const fieldTypes = [
    ["label", "label", "labelSmall"],
    "labelSmall",
    "dateLabel",
  ];
  const fieldNames = [
    ["firstName", "lastName", "accountNum"],
    "note",
    "dueDate",
  ];

  // Should open to customer page
  const onSelected = (item) => {
    window.open("/accountmanager/" + item.accountNum);
  };
  return (
    <Card mb={6} elevation={3}>
      <CardContent>
        <Grid container xs={12} alignItems="center">
          <Grid item lg={12} md={12} sm={12}>
            <SearchBar />
          </Grid>
        </Grid>
        <EditableTable
          readOnly={true}
          canAddItems={false}
          canSave={false}
          isLoading={props.notes.isLoading}
          handleRowClickParentMethod={onSelected}
          tableTitle={"Open Notes"}
          objectArray={props.notes.dueNotes}
          fieldTitles={fieldTitles}
          fieldTypes={fieldTypes}
          fieldNames={fieldNames}
          customHandleChangePageEvent={handleChangePage}
          defaultItemCount={rowsPerPage}
          totalRowCount={
            props.notes.dueNotesSummary == null
              ? 0
              : props.notes.dueNotesSummary.totalRows
          }
        />
      </CardContent>
    </Card>
  );
}

DueNotesTable.propTypes = {
  user: PropTypes.object.isRequired,
  notes: PropTypes.object.isRequired,
  getDueNotes: PropTypes.func.isRequired,
};

function DueNotesTableState(state) {
  return {
    user: state.user,
    notes: state.notes,
  };
}

export default connect(DueNotesTableState, { getDueNotes })(DueNotesTable);
