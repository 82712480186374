import React, {useState} from 'react';
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import {useSelector} from "react-redux";
import {FormControl, InputLabel} from "@material-ui/core";

const PDFFormDialog = (props) => {
  const [open, setOpen] = useState(props.show);
  const [state, setState] = useState({
    message: props.item.message || "",
    active: props.item.active || 0,
    name: "Message",
    template: {
      id: props.item.pdfMessageTypeId || "",
      value: props.item.description || "",
    },
  });

  const pdfTemplates = useSelector((state) => state.setting.pdfSettings.types) || [];
  const handleClose = () => {
    setOpen(false);
    props.onChange(false);
    const payload = {
      message: state.message || "",
      active: state.active,
      pdfMessageTypeId: state.template.id
    }
    props.updateItem(props.item.uuid, payload);
  };

  const handleCancel = () => {
    setOpen(false);
    props.onChange(false);
  };

  const handleChange = (evt) => {
    const { name, value, checked } = evt.target;

    if (name === "template") {
      const selectedTemplate = pdfTemplates.find(template => template?.id == value);

      setState((prevState) => ({
        ...prevState,
        template: {
          id: selectedTemplate?.id,
          value: selectedTemplate?.description,
        },
      }));
    } else if (name === 'active') {
      setState((prevState) => ({
        ...prevState,
        active: checked ? 1 : 0,
      }));
    } else {
      setState({
        ...state,
        [name]: value,
      });
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{state.name}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {props.dialogText}
          </DialogContentText>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "stretch",
              gap:'20px'
          }}>
          <FormControlLabel
            labelPlacement="top"
            style={{marginLeft:'0px'}}
            control={
              <Switch
                checked={state.active === 1}
                size="small"
                onChange={handleChange}
                name="active"
                color="primary"
              />
            }
            label="Status"
          />
            <FormControl fullWidth>
              <InputLabel htmlFor="template">Template</InputLabel>
              <Select
                native
                value={state.template.id}
                onChange={handleChange}
                inputProps={{
                  name: "template",
                  id: "template",
                }}
              >
                {pdfTemplates.map(template => (
                  <option key={template.id} value={template.id}>
                    {template.description}
                  </option>
                ))}
              </Select>
            </FormControl>
          </div>

          <TextField
            autoFocus
            margin="dense"
            id="message"
            name="message"
            value={state.message}
            label="Message"
            fullWidth
            onChange={handleChange}
            multiline
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="secondary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PDFFormDialog;
