import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import {
  Grid,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Paper as MuiPaper,
  MenuItem,
  TextField as MuiTextField,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  Dialog,
  DialogContent,
  DialogTitle,
  select,
  Slide,
  IconButton,
  ButtonGroup,
  Button
} from "@material-ui/core";
// snackbar
import _ from "lodash"
import { setSnackbar } from "../../../modules/snackbar/api/snackbar";
import { useDispatch } from "react-redux";
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PauseIcon from '@material-ui/icons/Pause';
import moment from 'moment';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from "@material-ui/icons/Search";
import { spacing } from "@material-ui/system";
import { formatMoney } from "../../../setup/helpers";
import { EditableTable, newFieldLinkUrl, newConditionalOnSelectButton } from "../../../components/EditableTable";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import StatusTag from "../../apl/components/StatusTag";
// Redux Components
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  createNewMoveIn,
  deleteMove,
  getMoveOutsInDateRange,
  editMove,
  fetchMoves,
  processMoves,
  setTabState,
  updateBatchMoves,
  startBulkMovesLoading
} from "../api/actions";
import EditModal from './movesPageComponents/EditModal';
import ConfirmDeleteModal from './movesPageComponents/ConfirmDeleteModal';

const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const useStyles = makeStyles({
  chipTrue: {
    backgroundColor: 'green',
    marginRight: '2px',
    padding: '1px'
  },

  chipFalse: {
    backgroundColor: 'red',
    marginRight: '2px',
    padding: '1px'
  },

  chipIgnore: {
    marginRight: '2px'
  },

  actionIcons: {
    position: 'relative',
    top: '5px',
    fontSize: '18px',
    marginRight: '5px',
    cursor: 'pointer'
  },

  processedRow: {
    backgroundColor: 'rgba(211, 211, 211, 0.74)'
  },
  nonProcessedRow: {
    backgroundColor: 'none'
  },

  bgRed: {
    backgroundColor: '#F08080'
  },
  bgYellow: {
    backgroundColor: '#FFFACD'
  },
  bgBlue: {
    backgroundColor: '#ADD8E6'
  },
  bgNone: {
    backgroundColor: 'none'
  },
  active: {
    fontWeight: "bold",
    color: "#f9a825",
    borderColor: "#f9a825",
  },
  inactive: {
    fontWeight: "bold",
  },
})

function MoveOutsTable(props) {

  const dispatch = useDispatch();

  const classes = useStyles();

  //  confirmation for delete
  const [openConfirmDelete, setOpenConfirmDelete] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);

  const [searchQuery, setSearchQuery] = React.useState("");
  const [monthSearchRange, setMonthSearchRange] = React.useState(3);
  const [targetProperty, setTargetProperty] = React.useState("");
  const [targetPropertyName, setTargetPropertyName] = React.useState(null);
  const [focus, setFocus] = React.useState(false);

  // Flags stuff to accomodate editing formsconfirmation for delete
  const [confirmDeleteName, setConfirmDeleteName] = useState('');
  const [targetDeleteUuid, setTargetDeleteUuid] = useState(null);
  const [editObj, setEditObj] = useState({});


  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [resetCache, setResetCachedData] = useState(true);

  const [currentDateFilters, setCurrentDateFilters] = useState('Current/Past');
  const [currentStateFilters, setCurrentStateFilters] = useState('Unprocessed');

  const handleSearchTargetChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const CurrentLookupTypeButton = () => {
    return (
      <IconButton>
        <SearchIcon mr={2} />
      </IconButton>
    );
  };


  const handleOpenEdit = (uuid) => {
    var targetIndex = props.moves.data.loadedMoveOuts.items.map(function (e) { return e.uuid; }).indexOf(uuid);
    if (targetIndex != -1) {
      setEditObj(props.moves.data.loadedMoveOuts.items[targetIndex]);
      setOpenEdit(true);
    }
  };

  const handleChangePage = (page) => {
    console.log(page)
    setResetCachedData(false);
    setPage(page);
  };



  const handleChangeRowsPerPage = (rowsPerPage) => {
    setPage(0);
    setResetCachedData(false);
    setRowsPerPage(rowsPerPage);
  };
  

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleCloseConfirmDelete = () => {
    setOpenConfirmDelete(false);
  };

  const onSetMoveCreateBill = async (originalObj) => {
    try {
      dispatch(setSnackbar(true, "warning", "Updating move."));
      await props.editMove(originalObj, { finalBill: 1 }, 'moveout', false);
      dispatch(setSnackbar(true, "success", "Successfully updated move."));
    } catch (err) {
      //console.log("Failed to edit move.");
      //console.log(err);
      dispatch(setSnackbar(true, "error", "Failed to edit move."));
    }
  };

  const onRemoveMoveCreateBill = async (originalObj) => {
    try {
      dispatch(setSnackbar(true, "warning", "Updating move."));
      await props.editMove(originalObj, { finalBill: 0 }, 'moveout', false);
      dispatch(setSnackbar(true, "success", "Successfully updated move."));
    } catch (err) {
      //console.log("Failed to edit move.");
      //console.log(err);
      dispatch(setSnackbar(true, "error", "Failed to edit move."));
    }
  };

  const onSetMoveToIssue = async (originalObj) => {
    try {
      dispatch(setSnackbar(true, "warning", "Updating move."));
      await props.editMove(originalObj, { issues: 1 }, 'moveout', false);
      dispatch(setSnackbar(true, "success", "Successfully updated move."));
    } catch (err) {
      //console.log("Failed to edit move.");
      //console.log(err);
      dispatch(setSnackbar(true, "error", "Failed to edit move."));
    }
  };

  const onRemoveMoveIssue = async (originalObj) => {
    try {
      dispatch(setSnackbar(true, "warning", "Updating move."));
      await props.editMove(originalObj, { issues: 0 }, 'moveout', false);
      dispatch(setSnackbar(true, "success", "Successfully updated move."));
    } catch (err) {
      //console.log("Failed to edit move.");
      //console.log(err);
      dispatch(setSnackbar(true, "error", "Failed to edit move."));
    }
  };

  const onSetMoveToReady = async (originalObj) => {
    try {
      dispatch(setSnackbar(true, "warning", "Updating move."));
      await props.editMove(originalObj, { readyForProcess: 1 }, 'moveout', false);
      dispatch(setSnackbar(true, "success", "Successfully updated move."));
    } catch (err) {
      //console.log("Failed to edit move.");
      //console.log(err);
      dispatch(setSnackbar(true, "error", "Failed to edit move."));
    }
  };

  const onRemoveMoveReady = async (originalObj) => {
    try {
      dispatch(setSnackbar(true, "warning", "Updating move."));
      await props.editMove(originalObj, { readyForProcess: 0 }, 'moveout', false);
      dispatch(setSnackbar(true, "success", "Successfully updated move."));
    } catch (err) {
      //console.log("Failed to edit move.");
      //console.log(err);
      dispatch(setSnackbar(true, "error", "Failed to edit move."));
    }
  };

  // useEffect(() => {

  //   if (props.moves.data.loadedMoveOuts.items) {
  //     if (((page + 1) * rowsPerPage > props.moves.data.loadedMoveOuts.items.length) || resetCache) {
  //       props.getMoveOutsInDateRange(1, page, rowsPerPage);
  //     }
  //   }
  // }, [page, rowsPerPage]);



  const handleOpenConfirmDelete = (uuid) => {
    var targetIndex = props.moves.data.loadedMoveOuts.items.map(function (e) { return e.uuid; }).indexOf(uuid);
    if (targetIndex != -1) {
      setConfirmDeleteName(props.moves.data.loadedMoveOuts.items[targetIndex].accountHolder);
      setTargetDeleteUuid(uuid);
      setOpenConfirmDelete(true);
    }
  };
  const handleMoveOutSelected = (item) => {
  };

  const onUpdate = () => {
    hide();
  };

  const [display, setDisplay] = React.useState(false);

  const show = () => {
    props.properties.data.property = {}; // TODO: clear property on popup show
    setDisplay(true);
  };

  const hide = () => {
    setDisplay(false);
  };

  useEffect(() => {
    console.log("useEffect");
    if (props.moves.movesToLoad == null || props.moves.movesLoaded == null) {
      props.getMoveOutsInDateRange(monthSearchRange, page, 1000);
    }
    else {
      if (props.moves.error) {
        dispatch(setSnackbar(true, "error", `Error Updating Moves: ${props.moves.error}`));
        props.startBulkMovesLoading(null);
      }
      else if (props.moves.movesToLoad == -1 && props.moves.movesLoaded == -1) {
        dispatch(setSnackbar(true, "success", "Successfully updated moves."));
        props.startBulkMovesLoading(null);
      }
      else {
        dispatch(setSnackbar(true, "loading", "Updating moves: ", props.moves.movesLoaded, props.moves.movesToLoad));
      }
    }
  }, [props.user, props.moves.movesLoaded, props.moves.movesToLoad]);


  const confirmDeleteAction = async (
    confirmDeleteMoveUuid,
    confirmDeleteType
  ) => {
    if (targetDeleteUuid && targetDeleteUuid != null) {
      try {
        dispatch(setSnackbar(true, "warning", "Updating move."));
        await props.deleteMove(targetDeleteUuid, 'moveout');
        dispatch(setSnackbar(true, "success", "Successfully deleted move."));
        await props.getMoveOutsInDateRange(monthSearchRange);
      } catch (err) {
        dispatch(setSnackbar(true, "error", "Failed to delete move."));
      }
    }
  };

  const onEditSavedAction = async (originalObj, modifiedObj, type) => {
    try {
      dispatch(setSnackbar(true, "warning", "Updating move"));
      await props.editMove(originalObj, modifiedObj, type, false);
      dispatch(setSnackbar(true, "success", "Successfully updated moves."));
    } catch (err) {
      //console.log("Failed to edit move.");
      //console.log(err);
      dispatch(setSnackbar(true, "error", "Failed to edit move."));
    }
  };

  const processMovesClick = (uuids) => {
    handleProcessMoves(uuids, "moveout");
  };

  /*const incrementLoadedCount = () => {
    var upodatedMovesCount = movesUpdated + 1;
    setMovesUpdated(upodatedMovesCount);
    dispatch(setSnackbar(true, "loading", "Updating moves.", upodatedMovesCount, movesToUpdate));
  }*/

  const updateBulkMoves = (newItem, moves) => {
    try {
      props.startBulkMovesLoading(moves.length);
      //dispatch(setSnackbar(true, "loading", "Updating moves.", 0, moves.length));
      for (var x = 0; x < moves.length; x++) {
        props.editMove(moves[x], newItem, 'moveout', false).then(() => {
        });
      }
      //dispatch(setSnackbar(true, "loading", "Updating move."));
      //dispatch(setSnackbar(true, "success", "Successfully updated move."));
    } catch (err) {
      console.log("Failed to edit moves.");
      console.log(err);
      dispatch(setSnackbar(true, "error", "Failed to edit moves."));
    }
  }

  const handleSetMovesToReady = (moves) => {
    updateBulkMoves({ readyForProcess: 1 }, moves);
  }

  const handleUnsetMovesToReady = (moves) => {
    updateBulkMoves({ readyForProcess: 0 }, moves);
  }

  const handleSetMovesToIssue = (moves) => {
    updateBulkMoves({ issues: 1 }, moves);
  }

  const handleUnsetMovesToIssue = (moves) => {
    updateBulkMoves({ issues: 0 }, moves);
  }

  const handleSetCreateBill = async (moves) => {
    updateBulkMoves({ finalBill: 1 }, moves);
  }

  const handleUnsetCreateBill = async (moves) => {
    updateBulkMoves({ finalBill: 0 }, moves);
  }


  const handleProcessMoves = async (moves) => {
    ////console.log("moves");
    ////console.log(moves);
    // keep these to preserve in case of err
    // const originalUuids = Object.assign([], uuids);
    // const originalType = type;
    try {
      let areMovesNotReadyButSelected = false;
      moves.forEach((move) => {
        var isIssue = (move.issues == 1 || move.accountStatus == "Inactive");
        var isHold = (move.prevReadBilled != 1 && move.billingType == "BILL");
        if (!move.readyForProcess || isIssue) {
          /*if (isIssue) {
            //console.log("isIssue");
            //console.log(move);
          }
          if (isHold) {
            //console.log("isHold");
            //console.log(move);
          }
          if (!move.readyForProcess) {
            //console.log("!move.readyForProcess");
            //console.log(move);
          }*/
          dispatch(
            setSnackbar(
              true,
              "error",
              "Please unselect moves that are NOT ready to process."
            ),
            true
          );
          // preserveMoves(originalUuids, originalType);
          areMovesNotReadyButSelected = true;
        }
      });

      if (areMovesNotReadyButSelected) return 1;
      var movesToSend = [];
      moves.map((moveout) => {
        delete moveout.movesToolbar;
        delete moveout.statusTags;
        delete moveout.accountNumDisplay;
        delete moveout.currentCreateBill;
        movesToSend.push(moveout);
      });
      if (movesToSend.length > 0) {
        dispatch(setSnackbar(true, "warning", `Processing ${movesToSend.length} Moves`))
        movesToSend = _.uniqBy(movesToSend, 'uuid')
        let counter = 0;
        for(let move of movesToSend) {
          counter++;
          delete move.movesToolbar;
        delete move.statusTags;
        delete move.accountNumDisplay;
        delete move.currentCreateBill;
          try {
            let response = await props.processMoves([move], "moveout");
            if(response.status == 200) {
              dispatch(setSnackbar(true, "success", `Successfully Processed ${counter}/${movesToSend.length} moves`))
            }
            else {
              dispatch(setSnackbar(true, "error", `Error processing ${counter}/${movesToSend.length} moves`))
            }
          }catch(e){
            dispatch(setSnackbar(true, "error", `Error processing move`))
          }
        }
        await props.getMoveOutsInDateRange(monthSearchRange);
    }
    } catch (err) {
      //console.log("Failed to process moves:");
      //console.log(err);
      dispatch(setSnackbar(true, "error", "Failed to Process Moves: " + err));
    }
  };


  /*
    ROW STYING FOR MOVE OUTS TABLE 
  */
  const MovesRowStatusStyling = (move) => {
    let styleToReturn = {}

    let invalidTenant = (move.accountStatus == "Inactive");
    let isIssue = (move.issues == 1);
    let isHold = ((move.prevReadBilled != 1 && move.meterRead == 0 && move.prevReadFinal != 1 && move.billingType == "BILL"));

    var daysBetween = 1;
    if (move.prevReadFinal != 1) {
      daysBetween = moment(move.prevReadDate).startOf('day').diff(
        moment(move.moveOutDate).startOf('day'),
        "days"
      ) * -1;
    }
    // Horidly inefficiant but theres no better way to access this in the context i can think of
    move.movesToolbar =
      <Grid container style={{ maxWidth: '200px' }} spacing={1}>
        <Grid item >
       { move.readyForProcess != 1 &&
          <EditIcon onClick={() => { handleOpenEdit(move.uuid) }} className={classes.actionIcons} /> }
        </Grid>
        <Grid item>
        { (move.readyForProcess != 1 && move.processed != 1) &&
          <DeleteIcon onClick={() => { handleOpenConfirmDelete(move.uuid) }} className={classes.actionIcons} /> }
        </Grid> 
        {move.processed != 1 &&
          <>
            <Grid item>
              {move.issues == 1 ?
                <CheckCircleIcon onClick={() => { onRemoveMoveIssue(move); }} className={classes.actionIcons} />
                :
                <WarningIcon onClick={() => { onSetMoveToIssue(move); }} className={classes.actionIcons} />
              }
            </Grid>
            {(!isIssue && !isHold && move.finalBill ) ? (
              <Grid item>
                {move.readyForProcess == 1 ?
                  <PauseIcon onClick={() => { onRemoveMoveReady(move); }} className={classes.actionIcons} />
                  :
                  <PlayArrowIcon onClick={() => { onSetMoveToReady(move); }} className={classes.actionIcons} />
                }
              </Grid> ) : null
            }
          </>
        }
      </Grid>
    move.statusTags = <>
      <Grid container style={{ maxWidth: '200px' }} spacing={1}>
        <>
          {move.processed == 1 ?
            <Grid item xs={7}><StatusTag type={"Success"} label={"Move Processed"} condensed={true} /> </Grid>
            :
            <>
              {move.issues == 1 && <Grid item xs={7}><StatusTag type={"Alert"} label={"Issue: Manually Assigned"} condensed={true} /> </Grid>}
              {(invalidTenant) && <Grid item xs={7}><StatusTag type={"Alert"} label={"Issue: Invalid Unit Occupant"} condensed={true} /> </Grid>}
              {move.disconnected == 1 && <Grid item xs={7}><StatusTag type={"Alert"} label={"Issue: Account Disconnected"} condensed={true} /> </Grid>}
              {isHold && <Grid item xs={7}><StatusTag type={"Alert"} label={"Hold: Open Meter Read"} condensed={true} /> </Grid>}
              {((!isIssue && !isHold && !invalidTenant) && move.readyForProcess == 1) &&
                <Grid item xs={7}>
                  <StatusTag type={"Success"} label={"Ready To Process!"} condensed={true} />
                </Grid>
              }
            </>
          }
        </>
      </Grid>
    </>

    move.currentCreateBill = <>
      <Grid container style={{ maxWidth: '200px' }} spacing={1}>
        {move.processed != 1 &&
          <>
            {(move.workTableUuid != null && move.readyForProcess) ?
              <Grid item xs={7}>
                <StatusTag type={"Success"} label={"Bill Ready To Send"} condensed={true} />
              </Grid>
              :
              <>
               <>
                    {move.finalBill ?
                    (
                      <Grid item onClick={() => { onRemoveMoveCreateBill(move); }} xs={7}>
                        {(daysBetween > 0 && moment(move.moveOutDate).startOf('day') != moment(move.prevReadDate).startOf('day')) ?
                        <StatusTag type={"Success"} label={"Create Bill"} condensed={true} />

                        :  
                        <>
                        <StatusTag type={"Alert"} label={"Bill Creation On"} condensed={true} />
                        <StatusTag type={"Alert"} label={"Days Since Last < 1"} condensed={true} />
                        </>}
                      </Grid> 
                    )
                    :
                    (
                      <Grid item onClick={() => { onSetMoveCreateBill(move); }} xs={7}>
                         {(daysBetween > 0 && moment(move.moveOutDate).startOf('day') != moment(move.prevReadDate).startOf('day')) ?
                        <>
                        <StatusTag type={"Warning"} label={"Bill Creation Not Set"} condensed={true} />
                        </> :
                           <>
                           <StatusTag type={"Alert"} label={"Bill Creation Off"} condensed={true} />
                           <StatusTag type={"Alert"} label={"Days Since Last < 1"} condensed={true} />
                           </>}
                      </Grid>
                    )
                    }
                  </>
                {/* {(daysBetween > 0 && moment(move.moveOutDate).startOf('day') != moment(move.prevReadDate).startOf('day')) ?
                  <>
                    {move.finalBill ?
                      <Grid item onClick={() => { onRemoveMoveCreateBill(move); }} xs={7}>
                        <StatusTag type={"Success"} label={"Create Bill"} condensed={true} />
                      </Grid>
                      :
                      <Grid item onClick={() => { onSetMoveCreateBill(move); }} xs={7}>
                        <StatusTag type={"Warning"} label={"Bill Creation Not Set"} condensed={true} />
                      </Grid>
                    }
                  </>
                  :
                  <Grid item xs={7}>
                    <StatusTag type={"Alert"} label={"No Bill Creation"} condensed={true} />
                    <StatusTag type={"Alert"} label={"Days Since Last < 1"} condensed={true} />
                  </Grid>
                } */}
              </>
            }
          </>
        }
      </Grid>
    </>

    move.timeCreated = moment(move.created).format('hh:mm:ss A');

    if (move.accountNum == null && move.billingType == "BILL")  {
      move.accountNumDisplay = <StatusTag type={"Alert"} label={"Account Not Created / Found"} condensed={true} />;
    }
    else {
      move.lastBillDateLabel = `Last Bill Date: ${move.lastBillDate ? moment(move.lastBillDate).utc().format('YYYY-MM-DD') : ''}`;
      let accountStatus = `${move.accountStatus} ${move.customerType == "Management Co" ? "PM" : move.customerType}`;
      if( move.accountStatus == "Inactive"){ 
        move.accountNumDisplay = 
        <>
          <Grid container style={{ maxWidth: '200px' }} spacing={1}>

              <Grid item xs={7}><StatusTag type={"Alert"} label={`${accountStatus} #${move.accountNum}`} condensed={true} /> </Grid>
            
          </Grid>
        </>
      }else if(move.accountStatus == "Transfer"){
        move.accountNumDisplay = 
        <>
          <Grid container style={{ maxWidth: '200px' }} spacing={1}>

            <Grid item xs={7}><StatusTag type={"Warning"} label={`${accountStatus} #${move.accountNum}`} condensed={true} /> </Grid>
            
          </Grid>
      </>
        
      }else{
        move.accountNumDisplay = 
        <>
          <Grid container style={{ maxWidth: '200px' }} spacing={1}>

            <Grid item xs={7}><StatusTag type={"Success"} label={`${accountStatus} #${move.accountNum}`} condensed={true} /> </Grid>
            
          </Grid>
      </>
      }
        
    }
    if (move.creatorIsInternalUser) {
      move.sourceLabel = `Source: Employee`;
    }
    else if (move.creatorIsCustomer) {
      move.sourceLabel = `Source: New Customer`;
    }
    else if (move.creatorIsPm) {
      move.sourceLabel = `Source: PM`;
    }
    else {
      move.sourceLabel = `Source: Client`;
    }

    //move.usageCalculated = move.prevRead - move.secondRead;
    if (move.prevReadFinal) {
      if (move.prevReadTypeDesc == "EST" && move.meterRead == 0) {
        ////console.log("Meter Read = 0");
        move.readLabel = `${move.prevRead || "N/A"} (Gen. ${move.prevReadTypeDesc || "Unknown"})`;
        move.usage = move.prevRead - move.secondRead;
        move.prevReadDateLabel = move.prevReadDate;
      }
      else {
        //console.log("Else: EST move.prevRead - move.secondRead");
        if (move.meterRead == move.prevRead) {
          move.readLabel = `${move.prevRead || "N/A"} (Prov. ${move.prevReadTypeDesc || "Unknown"})`;
          move.usage = move.prevRead - move.secondRead;
          move.prevReadDateLabel = move.prevReadDate;
        }
        else {
          move.readLabel = `${move.prevRead || "N/A"} (Prov. ${move.prevReadTypeDesc || "Unknown"})`;
          move.usage = move.prevRead - move.secondRead;
          move.prevReadDateLabel = move.prevReadDate;
        }
      }
    }
    else {
      if (move.meterRead != 0) {
        //console.log("Meter Read != 0");
        if (move.meterRead == move.prevRead) {
          move.readLabel = `${move.meterRead || "N/A"} (Prov. ${move.prevReadTypeDesc || "Unknown"})`;
          move.usage = move.prevRead - move.secondRead;
          move.prevReadDateLabel = move.prevReadDate;
        }
        else {
          move.readLabel = `${move.meterRead || "N/A"} (Prov. ${move.prevReadTypeDesc || "Unknown"})`;
          move.usage = move.meterRead - move.prevRead;
          move.prevReadDateLabel = move.prevReadDate;
        }
      }
      else {
        move.readLabel = "Not Set";
        move.usage = "N/A";
        move.prevReadDateLabel = move.prevReadDate;
      }
    }
    if (move.prevReadFinal && move.secondMeter) {
      if (move.secondMeter.prevReadTypeDesc == "EST" && move.secondMeter.meterRead == 0) {
        ////console.log("Meter Read = 0");
        move.secondMeterReadLabel = `${move.secondMeter.prevRead || "N/A"} (Gen. ${move.secondMeter.prevReadTypeDesc || "Unknown"})`;
        move.secondMeter.secondMeterUsage = move.secondMeter.prevRead - move.secondMeter.secondRead;
        move.secondPrevReadLabel = move.secondMeter.prevReadDate;
      }
      else {
        //console.log("Else: EST move.prevRead - move.secondRead");
        if (move.secondMeter.meterRead == move.secondMeter.prevRead) {
          move.secondMeterReadLabel = `${move.secondMeter.prevRead || "N/A"} (Prov. ${move.secondMeter.prevReadTypeDesc || "Unknown"})`;
          move.secondMeter.secondMeterUsage = move.secondMeter.prevRead - move.secondMeter.secondRead;
          move.secondPrevReadLabel = move.secondMeter.prevReadDate;
        }
        else {
          move.secondMeterReadLabel = `${move.secondMeter.prevRead || "N/A"} (Prov. ${move.secondMeter.prevReadTypeDesc || "Unknown"})`;
          move.secondMeter.secondMeterUsage = move.secondMeter.prevRead - move.secondMeter.secondRead;
          move.secondMeter.secondPrevReadLabel = move.secondMeter.prevReadDate;
        }
      }
    }
    else if (move.secondMeter) {
      if (move.secondMeter?.meterRead != 0) {
        //console.log("Meter Read != 0");
        if (move.secondMeter.meterRead == move.secondMeter.prevRead) {
          move.secondMeterReadLabel = `${move.secondMeter.meterRead || "N/A"} (Prov. ${move.prevReadTypeDesc || "Unknown"})`;
          move.secondMeterUsage = move.secondMeter.prevRead - move.secondMeter.secondRead;
          move.secondPrevReadLabel = move.secondMeter.prevReadDate;
        }
        else {
          move.secondMeterReadLabel = `${move.secondMeter.meterRead || "N/A"} (Prov. ${move.prevReadTypeDesc || "Unknown"})`;
          move.secondMeterUsage = move.secondMeter.meterRead - move.secondMeter.prevRead;
          move.secondPrevReadLabel = move.secondMeter.prevReadDate;
        }
      }
      else {
        move.secondMeterReadLabel = "Not Set";
        move.secondMeterUsage = "N/A";
        move.secondPrevReadLabel = move.secondMeter.prevReadDate;
      }
    }
    if (daysBetween < 1 || !move.finalBill) {
      move.usage = "N/A";
      if (move.secondMeter){

        move.secondMeter.secondMeterUsage = "N/A";

      }
      
    }

    move.usageCalculated = move.usage;
    if(move.secondMeter) {
      move.secondMeterUsageCalculated = move.secondMeterUsage;
    }
    
    if( move.secondMeter && move.secondMeter.workTableUuid){
      if(move.secondMeterUsageCalculated != "N/A"){
        if(move.secondMeter.abwtiTotalCharge == null || move.secondMeter.abwtiRate == null){
          move.secondMeterUsageCalculatedLabel = `Second Meter Usage: ${move.secondMeterUsageCalculated}`;
          move.secondMeterTotalChargeLabel = `Total Charge: Not Found`;
          move.secondMeterRateLabel = `Rate: Not Found`;
        } else {
          move.secondMeterUsageCalculatedLabel = `Second Meter Usage: ${move.secondMeterUsageCalculated}`;
          move.secondMeterTotalChargeLabel = `Total Charge: ${formatMoney(move.secondMeter.abwtiTotalCharge)}`;
          move.secondMeterRateLabel = `Rate: ${parseFloat(move.secondMeter.abwtiRate.toFixed(4))}`;
          // var periodDays = moment(move.secondMeter.secondReadDate.startOf('day')).diff(
          //   move.secondMeter.moveOutDate.startOf('day'),
          //   "days"
          // ) * -1;
          // periodDays = (periodDays == 0) ? 1 : periodDays;

          var start = moment(move.secondMeter.secondReadDate, "YYYY-MM-DD");
          var end = moment(move.secondMeter.moveOutDate, "YYYY-MM-DD");
          
          //Difference in number of days
          let periodDays = Math.round(moment.duration(start.diff(end)).asDays()) * -1;

          move.secondMeterDailyUsage = move.secondMeterUsageCalculated / periodDays;
          move.secondMeterDailyUsageLabel = `Second Meter Daily Usage: ${parseFloat(move.secondMeterDailyUsage.toFixed(4))}`;
        }
      }
    }
    else if(move.secondMeter){
      move.secondMeterUsageCalculatedLabel = `Second Meter Usage: ${move.secondMeterUsageCalculated}`;
      move.secondMeterTotalChargeLabel = null;
      move.secondMeterRateLabel = null;
    }
    
    if (move.workTableUuid != null) {
      if (move.usageCalculated != "N/A") {
        if (move.abwtiTotalCharge == null || move.abwtiRate == null) {
          move.usageCalculatedLabel = `Usage: ${move.usageCalculated}`;
          move.totalChargeLabel = `Total Charge: Not Found`;
          move.rateLabel = `Rate: Not Found`;
        }
        else {
          move.usageCalculatedLabel = `Usage: ${move.usageCalculated || move.abwtiUsage}`;
          move.totalChargeLabel = `Total Charge: ${formatMoney(move.abwtiTotalCharge)}`;
          move.rateLabel = `Rate: ${parseFloat(move.abwtiRate.toFixed(4))}`;
          // var periodDays = moment(move.secondReadDate).startOf('day').diff(
          //   moment(move.moveOutDate).startOf('day'),
          //   "days"
          // ) * -1;
          // console.log(periodDays)
          // periodDays = (periodDays == 0) ? 1 : periodDays;

          var start = moment(move.secondReadDate, "YYYY-MM-DD");
          var end = moment(move.moveOutDate, "YYYY-MM-DD");
          
          //Difference in number of days
          let periodDays = Math.round(moment.duration(start.diff(end)).asDays()) * -1;

          move.billingPeriodDaysLbl = `Day Count: ${periodDays} @ ${parseFloat((move.usage || move.abwtiUsage )/ periodDays).toFixed(4)} CF/Day`;
        }
      }
    }
    else {
      move.usageCalculatedLabel = move.usageCalculated;
      move.totalChargeLabel = null;
      move.rateLabel = null;
    }

    if (move.propertyIsRemote) {
      move.isRemoteFlagLabel = <StatusTag type={"Default"} label={"Remote Property"} condensed={true} />
    }

    if(move.billingType == "BILL")
      move.meterNumLabel = `Meter #${move.meterNum || "N/A"}`;
    else 
      move.meterNumLabel = move.billingType;

    if(move.secondMeter?.meterNum) {
    move.secondMeterNumLabel = `Meter # 2 #${move.secondMeter.meterNum || "N/A"}`;

      if (move.secondMeter.meterRead == move.secondMeter.prevRead || move.readLabel.includes(move.secondMeter.prevRead)) {
        move.secondPrevReadLabel = `Prev Read (${move.secondMeter.secondRead || ""}):`;
      }
      else {
        move.secondPrevReadLabel = `Prev Read (${move.secondMeter.prevRead || ""}):`;
      }

    }


    if (move.meterRead == move.prevRead || move.readLabel.includes(move.prevRead)) {
      move.prevReadLabel = `Prev Read (${move.secondRead || ""}):`;
    }
    else {
      move.prevReadLabel = `Prev Read (${move.prevRead || "" }):`;
    }


    if (move.processed == 1) {
      styleToReturn = { backgroundColor: 'rgba(211, 211, 211, 0.74)' }
    }
    else if (move.usageCalculated < 0 || move.usageCalculated > 1500) {
      styleToReturn = { backgroundColor: '#F08080' }
    }
    return styleToReturn;
  };


  const setSearchFocus = () => {
    setFocus(true);
  }
  const unsetSearchFocus = () => {
    setFocus(false);
  }
  const handleSelectedPropertyChange = (event) => {
    // recieves the prop uuid
    if (event.target.value == "All") {
      setTargetPropertyName(null);
      setTargetProperty("");
    }
    else {
      var targetIndex = props.properties.data.propertiesList.items.map(function (e) { return e.uuid; }).indexOf(event.target.value);
      if (targetIndex != -1) {
        setTargetPropertyName(props.properties.data.propertiesList.items[targetIndex].name);
        setTargetProperty(event.target.value);
      }
    }
  };


  const Filters = () => {
    const classes = useStyles();

    return (
      <Grid container spacing={6}>
        <Grid item lg={8} md={8} sm={8}>
          <>
            <FormControl fullWidth my={2}>
              <InputLabel id="propertyToBillDropDownLabel">Location{"  "}
                {props.apl.isLoading &&
                  <CircularProgress size={15} m={0} />
                }
              </InputLabel>
              {targetProperty == "" && props.apl.isLoading ?
                <Select
                  labelId="propertyToBillDropDownLabel"
                  id="propertyToBillDropDown"
                  value={targetProperty}
                  className={classes.selectedPropertyLocationLabel}
                  onChange={handleSelectedPropertyChange}
                  fullWidth
                  disabled
                  my={2}>
                </Select>
                :
                <Select
                  labelId="propertyToBillDropDownLabel"
                  id="propertyToBillDropDown"
                  value={targetProperty}
                  className={classes.selectedPropertyLocationLabel}
                  onChange={handleSelectedPropertyChange}
                  fullWidth
                  my={2}>
                  {props.properties.data.propertiesList.items.length > 0 ?
                    [
                      <MenuItem value={"All"}>All</MenuItem>,
                      props.properties.data.propertiesList.items.map((item, key) => {
                        return (
                          <MenuItem value={item.uuid} id={key}>{item.name}</MenuItem>
                        )
                      })
                    ]
                    :
                    [
                      <MenuItem value={-1}>No Properties Found</MenuItem>
                    ]
                  }
                </Select>
              }
            </FormControl>
          </>
        </Grid>
        <Grid item lg={6} md={6} sm={6}>
          <ButtonGroup
            color="primary"
          >
            <Button
              onClick={() => { setCurrentDateFilters("All") }}
              className={
                currentDateFilters == "All"
                  ? classes.active
                  : classes.inactive
              }
            >
              All
            </Button>
            <Button
              onClick={() => { setCurrentDateFilters("Current/Past") }}
              className={
                currentDateFilters == "Current/Past"
                  ? classes.active
                  : classes.inactive
              }
            >
              Current/Past
            </Button>
            <Button
              onClick={() => { setCurrentDateFilters("Future") }}
              className={
                currentDateFilters == "Future"
                  ? classes.active
                  : classes.inactive
              }
            >
              Future
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item lg={6} md={6} sm={6}>
          <ButtonGroup
            color="primary"
          >
            <Button
              onClick={() => { setCurrentStateFilters("All") }}
              className={
                currentStateFilters == "All"
                  ? classes.active
                  : classes.inactive
              }
            >
              All
            </Button>
            <Button
              onClick={() => { setCurrentStateFilters("Processed") }}
              className={
                currentStateFilters == "Processed"
                  ? classes.active
                  : classes.inactive
              }
            >
              Processed
            </Button>
            <Button
              onClick={() => { setCurrentStateFilters("Unprocessed") }}
              className={
                currentStateFilters == "Unprocessed"
                  ? classes.active
                  : classes.inactive
              }
            >
              Unprocessed/No Issues
            </Button>
            <Button
              onClick={() => { setCurrentStateFilters("Hold") }}
              className={
                currentStateFilters == "Hold"
                  ? classes.active
                  : classes.inactive
              }
            >
              Hold
            </Button>
            <Button
              onClick={() => { setCurrentStateFilters("Issues") }}
              className={
                currentStateFilters == "Issues"
                  ? classes.active
                  : classes.inactive
              }
            >
              Issue
            </Button>
            <Button
              onClick={() => { setCurrentStateFilters("Ready") }}
              className={
                currentStateFilters == "Ready"
                  ? classes.active
                  : classes.inactive
              }
            >
              Ready
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    );
  };



  const SearchBar = () => {
    return (
      <Grid container xs={12} spacing={4}>
        <Grid item xs={12}>
          {
            focus ?
              <TextField
                id={"searchTarget"}
                label="Search Moves On File"
                type={"text"}
                placeholder={"Search by any move object field"}
                onChange={handleSearchTargetChange}
                autoFocus={true}
                onBlur={unsetSearchFocus}
                inputRef={text => text && text.focus()}
                name={"searchTarget"}
                defaultValue={searchQuery}
                fullWidth
                size={"small"}
                variant={"outlined"}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{ endAdornment: <CurrentLookupTypeButton /> }}
              ></TextField>
              :
              <TextField
                id={"searchTarget"}
                label="Search Moves On File"
                type={"text"}
                placeholder={"Search by any move object field"}
                onChange={handleSearchTargetChange}
                onClick={setSearchFocus}
                name={"searchTarget"}
                defaultValue={searchQuery}
                fullWidth
                size={"small"}
                variant={"outlined"}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{ endAdornment: <CurrentLookupTypeButton /> }}
              ></TextField>
          }
        </Grid>
      </Grid>
    );
  };

  // Table Stuff:
  const fieldTitles = [
    "Account #",
    "Account",
    "Move Out Date",
    "Meter Read",
    "Usage/Bill Totals",
    "Date Created",
    "Move Statuses",
  ];
  const fieldTypes = [
    ["label", "label", "label"],
    ["labelSmall", "labelSmall", "labelSmall", "labelSmall"],
    ["dateLabelNoTime", "label"],
    ["labelSmall", "labelSmall", "labelSmall", "dateLabelNoTime", "labelSmall", "labelSmall", "labelSmall", "dateLabelNoTime", "labelSmall"],
    ["labelSmall", "labelSmall", "labelSmall", "labelSmall", "labelSmall", "labelSmall", "labelSmall", "labelSmall"],
    ["dateLabel", "label"],
    "label",
  ];
  const fieldNames = [
    ["sourceLabel", "accountNumDisplay", "movesToolbar"],
    ["accountHolder", "propertyName", "serviceAddress", "isRemoteFlagLabel"],
    ["moveOutDate", "currentCreateBill"],
    ["meterNumLabel", "readLabel", "prevReadLabel", "prevReadDateLabel" ,"secondMeterNumLabel", "secondMeterReadLabel", "secondPrevReadLabel", "secondPrevReadDateLabel", "lastBillDateLabel"],
    ["usageCalculatedLabel", "rateLabel", "billingPeriodDaysLbl", "totalChargeLabel", "secondMeterUsageCalculatedLabel", "secondMeterRateLabel", "secondMeterBillingPeriodDaysLbl", "secondMeterTotalChargeLabel"],
    ["created", "timeCreated"],
    "statusTags"
  ];
  const getAccountURL = (item) => {
    return "/accountmanager/" + item.accountNum;
  }
  const urlFields = [newFieldLinkUrl("accountNum", getAccountURL), newFieldLinkUrl("accountNumDisplay", getAccountURL), newFieldLinkUrl("accountHolder", getAccountURL)];



  const deleteSelectedMoves = (moves) => {
    moves.map((moveUuid, index) => {
      props.deleteMove(moveUuid, 'moveout');
    });

    props.getMoveOutsInDateRange(monthSearchRange);
  };


  const IsAbleToDeleteMove = (move) => {
    if (move.processed != 1 && move.readyForProcess != 1) {
      return true;
    }
    return false;
  };

  const ConditionalOnSelectButtons = [
    newConditionalOnSelectButton(
      "Set Create Bill",
      () => { return true; },
      handleSetCreateBill
    ),
    newConditionalOnSelectButton(
      "Unset Create Bill",
      () => { return true; },
      handleUnsetCreateBill
    ),
    newConditionalOnSelectButton(
      "Set To Issue",
      () => { return true; },
      handleSetMovesToIssue
    ),
    newConditionalOnSelectButton(
      "Unset Issue",
      () => { return true; },
      handleUnsetMovesToIssue
    ),
    newConditionalOnSelectButton(
      "Set Ready",
      () => { return true; },
      handleSetMovesToReady
    ),
    newConditionalOnSelectButton(
      "Unset Ready",
      () => { return true; },
      handleUnsetMovesToReady
    ),
    newConditionalOnSelectButton(
      "Process Selected Moves",
      () => { return true; },
      handleProcessMoves
    ),

  ];
  const toShowAll = JSON.parse(JSON.stringify(props.moves.data.loadedMoveOuts));//{ items: , toUpdate: [], toDelete: [] };
  const today = moment();
  let daysBetween = null;
  var isIssue = false;
  var isHold = false;
  var invalidTenant = false;
  // Loops through each item:
  for (var x = 0; x < toShowAll.items.length;) {
    // Filtering by property
    if (targetProperty != "" && targetProperty != null) {
      if (toShowAll.items[x].propertyUuid != targetProperty) {
        toShowAll.items.splice(x, 1);
        continue;
      }
    }
    // Filter Toggles
    if (currentStateFilters != "All") {
      invalidTenant = toShowAll.items[x].accountStatus == "Inactive";
      isIssue = (toShowAll.items[x].issues == 1 || toShowAll.items[x].disconnected == 1);
      isHold = ((toShowAll.items[x].prevReadBilled != 1 && toShowAll.items[x].meterRead == 0 && toShowAll.items[x].prevReadFinal != 1));
      if (
        (currentStateFilters == "Processed" && toShowAll.items[x].processed != 1) ||
        (currentStateFilters == "Unprocessed" && (toShowAll.items[x].processed == 1 || isIssue || invalidTenant)) ||
        (currentStateFilters == "Issues" && (!(isIssue || invalidTenant) || toShowAll.items[x].processed == 1)) ||
        (currentStateFilters == "Hold" && (!isHold || toShowAll.items[x].processed == 1)) ||
        (currentStateFilters == "Ready" && (toShowAll.items[x].readyForProcess != 1 || isIssue || isHold || invalidTenant))
      ) {
        toShowAll.items.splice(x, 1);
        continue;
      }
    }
    // Filter Toggles
    if (currentDateFilters != "All") {
      daysBetween = moment(toShowAll.items[x].moveOutDate).diff(
        today,
        "days"
      );
      //&& toShowAll.items[x].processed != 1 && toShowAll.items[x].onHold != 1 && toShowAll.items[x].issues != 1 && toShowAll.items[x].readyForProcess != 1
      if (
        ((currentDateFilters == "Current/Past" && !(daysBetween <= 0)) ||
          (currentDateFilters == "Past" && !(toShowAll.items[x].moveOutDate < today)) ||
          (currentDateFilters == "Future" && !(moment(toShowAll.items[x].moveOutDate) > today)))
      ) {
        toShowAll.items.splice(x, 1);
        continue;
      }
    }
    if (searchQuery != "") {
      // Search target properties
      if (
        !(toShowAll.items[x].accountHolder != null && toShowAll.items[x].accountHolder.toUpperCase().includes(searchQuery.toUpperCase())) &&
        !(toShowAll.items[x].accountNum != null && toShowAll.items[x].accountNum.toString().includes(searchQuery.toUpperCase())) &&
        !(toShowAll.items[x].propertyName != null && toShowAll.items[x].propertyName.toUpperCase().includes(searchQuery.toUpperCase())) &&
        !(toShowAll.items[x].serviceAddress != null && toShowAll.items[x].serviceAddress.toUpperCase().includes(searchQuery.toUpperCase())) &&
        !(toShowAll.items[x].moveOutDate != null && moment(toShowAll.items[x].moveOutDate).format("MM/DD/YYYY").includes(searchQuery.toUpperCase())) &&
        !(toShowAll.items[x].created != null && moment(toShowAll.items[x].created).format("MM/DD/YYYY").includes(searchQuery.toUpperCase())) &&
        !(toShowAll.items[x].readLabel != null && toShowAll.items[x].readLabel.toUpperCase().includes(searchQuery.toUpperCase())) &&
        !(toShowAll.items[x].usageCalculated != null && toShowAll.items[x].usageCalculated.toString().includes(searchQuery)) &&
        !(toShowAll.items[x].firstName != null && toShowAll.items[x].firstName.toString().includes(searchQuery.toUpperCase())) &&
        !(toShowAll.items[x].lastName != null && toShowAll.items[x].lastName.toString().includes(searchQuery.toUpperCase())) &&
        !(toShowAll.items[x].sourceLabel != null && toShowAll.items[x].sourceLabel.toUpperCase().includes(searchQuery.toUpperCase()))
      ) {
        toShowAll.items.splice(x, 1);
        continue;
      }
    }
    x++;
  }


  return (
    <Card mb={6} elevation={3}>
      <CardContent>
        <Grid container xs={12} spacing={4}>
          <Grid item xs={12}>
            <SearchBar />
          </Grid>
          <Grid item xs={12}>
            <EditableTable
              canSearch={false}
              canDelete={true}
              Filters={<Filters />}
              currentActiveFilters={[currentDateFilters, currentStateFilters, targetPropertyName]}
              customUrlFields={urlFields}
              readOnly={false}
              canAddItems={false}
              canSave={false}
              conditionalStylingMethod={MovesRowStatusStyling}
              manualItemDeletionMethod={deleteSelectedMoves}
              verifyDelete={true}
              isLoading={props.moves.isLoading}
              newItemCreationMethod={show}
              conditionalOnSelectButtons={ConditionalOnSelectButtons}
              customOnDeleteValidationMethod={IsAbleToDeleteMove}
              //handleRowClickParentMethod={onSelected}
              tableTitle={"Move Outs"}
              objectArray={toShowAll}
              canExport={true}
              fieldTitles={fieldTitles}
              fieldTypes={fieldTypes}
              fieldNames={fieldNames}
              // customChangePageRowsEvent={handleChangeRowsPerPage}
              // customHandleChangePageEvent={handleChangePage}
              // defaultItemCount={rowsPerPage}
              // totalRowCount={props.moves.data.loadedMoveOuts.count == null ? 0 : props.moves.data.loadedMoveOuts.count}

            />
          </Grid>
        </Grid>
      </CardContent>

      <ConfirmDeleteModal
        open={openConfirmDelete}
        handleClose={handleCloseConfirmDelete}
        onCancel={handleCloseConfirmDelete}
        type={"moveout"}
        action={confirmDeleteAction}
        name={confirmDeleteName}
      />
      <EditModal
        open={openEdit}
        handleClose={handleCloseEdit}
        action={onEditSavedAction}
        editType={"moveout"}
        editObj={editObj}
        properties={props.properties}
      />
    </Card>
  );
}

MoveOutsTable.propTypes = {
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  properties: PropTypes.object.isRequired,
  moves: PropTypes.object.isRequired,

  editMove: PropTypes.func.isRequired,
  fetchMoves: PropTypes.func.isRequired,
  getMoveOutsInDateRange: PropTypes.func.isRequired,
  deleteMove: PropTypes.func.isRequired,
  updateBatchMoves: PropTypes.func.isRequired,
  processMoves: PropTypes.func.isRequired,
  startBulkMovesLoading: PropTypes.func.isRequired,
};

function MoveOutsTableState(state) {
  return {
    user: state.user,
    apl: state.apl,
    moves: state.moves,
    search: state.search,
    properties: state.properties
  };
}

export default connect(MoveOutsTableState, {
  createNewMoveIn,
  deleteMove,
  getMoveOutsInDateRange,
  editMove,
  fetchMoves,
  processMoves,
  setTabState,
  updateBatchMoves,
  startBulkMovesLoading
})(MoveOutsTable);
