// Imports
import axios from "axios";
require("../../../setup/config/apiConfig");

export const RESET = "RESET";
export const GET_DEVELOPERS = "GET_DEVELOPERS";
export const GET_DEVELOPERS_RESPONSE = "GET_DEVELOPERS_RESPONSE";
export const GET_DEVELOPERS_RESPONSE_FAIL = "GET_DEVELOPERS_RESPONSE_FAIL";
export const GET_DEVELOPER = "GET_DEVELOPER";
export const GET_DEVELOPER_RESPONSE = "GET_DEVELOPER_RESPONSE";
export const GET_DEVELOPER_RESPONSE_FAIL = "GET_DEVELOPER_RESPONSE_FAIL";

export function get(isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: GET_DEVELOPERS,
      isLoading: isLoading,
    });
    return axios
      .get(`/developer`)
      .then((response) => {
        dispatch({
          type: GET_DEVELOPERS_RESPONSE,
          developers: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_DEVELOPERS_RESPONSE_FAIL,
          isLoading: false,
          error: error,
        });
      });
  };
}

export function getByUuid(uuid, isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: GET_DEVELOPER,
      isLoading: isLoading,
    });
    return axios
      .get(`/developer/${uuid}`)
      .then((response) => {
        dispatch({
          type: GET_DEVELOPER_RESPONSE,
          developer: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_DEVELOPER_RESPONSE_FAIL,
          isLoading: false,
          error: error,
        });
      });
  };
}

export function getById(id, isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: GET_DEVELOPER,
      isLoading: isLoading,
    });
    return axios
      .get(`/developer/${id}`)
      .then((response) => {
        dispatch({
          type: GET_DEVELOPER_RESPONSE,
          developer: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_DEVELOPER_RESPONSE_FAIL,
          isLoading: false,
          error: error,
        });
      });
  };
}
