import { Button as MuiButton, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Loop as LoopIcon } from "@material-ui/icons";
import { spacing } from "@material-ui/system";
// Redux Components
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import {
  searchAccountsByFieldAndValue,
  updateFilters,
} from "../../modules/accountsSearch/api/actions";
import { getAllPropertyPreviews } from "../../modules/apl/api/actions";
import { logout } from "../../modules/user/api/actions";

const Button = styled(MuiButton)(spacing);

const DashboardToolBarButton = styled(Button)`
  padding: 4px;
  min-width: 0;
  svg {
    width: 2em;
    height: 2em;
  }
`;

function AdvanceFilterToolbar({ props, handleRefreshClick }) {
  const useStyles = makeStyles((theme) => {
    return {
      dropdown: {
        width: "14rem",
      },
      modalcontainer: {
        width: "60%",
        height: "60%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "15px",
        padding: "3rem",
      },
      link: {
        textDecoration: "none",
        color: theme.palette.secondary.main,
        "&:hover": {
          fontWeight: "bold",
          textDecoration: "underline",
        },
      },

      rightSideHeader: {
        display: "flex",
        alignItems: "flex-end",
        flexDirection: "row",
        justifyContent: "flex-end",
      },
      searchLabel: {
        fontWeight: "bolder",
        //fontSize: "110%",
        backgroundColor: "#f5f5f5",
      },

      leftSideHeader: {
        display: "flex",
        justifyContent: "left",
        alignItems: "left",
      },
      root: {
        display: "flex",
        flexWrap: "wrap",
      },
      margin: {
        margin: theme.spacing(1),
      },
      selectButtonMargin: {
        margin: theme.spacing(1),
        width: "25%",
        fontWeight: "bolder",
        fontSize: "90%",
      },
      withoutLabel: {
        marginTop: theme.spacing(3),
      },
      textField: {
        width: "25ch",
      },
      searchSelectionText: {
        fontWeight: "bolder",
        fontSize: "90%",
      },
    };
  });
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedSearchFieldTitle, setSelectedSearchFieldTitle] =
    React.useState("Name");
  const [selectedSearchField, setSelectedSearchField] = React.useState("name");
  const handleTypeOpenClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // When the textbox changes
  const handleSearchTargetChange = (event) => {
    props.accounts.data.filters[0].target = event.target.value;
    props
      .searchAccountsByFieldAndValue(
        props.accounts.data.filters,
        props.accounts.data.range
      )
      .then(() => {});
    //props.updateFilters(props.accounts.data.filters);
  };

  const handleSearchTypeSelected = (name, value) => {
    var result = props.accounts.data.filters.find((obj) => {
      return obj.field === value;
    })[0];
    if (!result) {
      props.accounts.data.filters.push({ field: value, target: "" });
    }
    //props.updateFilters(props.accounts.data.filters);
    setSelectedSearchFieldTitle(name);
    setSelectedSearchField(value);
    // Field name is in value item
    handleClose();
  };

  return (
    <Grid item lg={12} className={classes.rightSideHeader} spacing={2} mb={1}>
      <DashboardToolBarButton
        size="small"
        mr={2}
        mb={1}
        onClick={props.handleRefreshClick}
      >
        <LoopIcon />
      </DashboardToolBarButton>
    </Grid>
  );
}

/*
 */

// Component Properties
AdvanceFilterToolbar.propTypes = {
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  billing: PropTypes.object.isRequired,
  accounts: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  searchAccountsByFieldAndValue: PropTypes.func.isRequired,
  getAllPropertyPreviews: PropTypes.func.isRequired,
  updateFilters: PropTypes.func.isRequired,
};

// Component State
function AdvanceFilterToolbarState(state) {
  return {
    user: state.user,
    apl: state.apl,
    billing: state.billing,
    accounts: state.accounts,
  };
}
export default connect(AdvanceFilterToolbarState, {
  logout,
  searchAccountsByFieldAndValue,
  getAllPropertyPreviews,
  updateFilters,
})(AdvanceFilterToolbar);
