import {
  Button as MuiButton,
  ButtonGroup,
  Card as MuiCard,
  CardContent,
  CircularProgress as MuiCircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  TextField as MuiTextField,
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from "@material-ui/core/styles";
import { Loop as LoopIcon } from "@material-ui/icons";
import { spacing } from "@material-ui/system";
// Redux Components
import PropTypes from "prop-types";
import React from "react";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components/macro";
import { setSnackbar } from "../../modules/snackbar/api/snackbar";
import { transferUnitsToNewPm } from "../../modules/unit/api/actions";
import StatusTag from "modules/apl/components/StatusTag";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Button = styled(MuiButton)(spacing);

const DashboardToolBarButton = styled(Button)`
  min-width: 0;
  svg {
    width: 2em;
    height: 2em;
  }
`;

function ToolBar(props) {
  const useStyles = makeStyles((theme) => {
    return {
      dropdown: {
        width: "14rem",
      },
      modalcontainer: {
        width: "60%",
        height: "60%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "15px",
        //padding:"3rem"
      },
      link: {
        textDecoration: "none",
        color: theme.palette.secondary.main,
        "&:hover": {
          fontWeight: "bold",
          textDecoration: "underline",
        },
      },

      rightSideHeader: {
        display: "flex",
        alignItems: "flex-end",
        flexDirection: "row",
        justifyContent: "flex-end",
      },
      searchLabel: {
        fontWeight: "bolder",
        fontSize: "110%",
        backgroundColor: "#f5f5f5",
      },

      leftSideHeader: {
        display: "flex",
        justifyContent: "left",
        alignItems: "left",
      },
      margin: {
        margin: theme.spacing(1),
      },
      selectButtonMargin: {
        margin: theme.spacing(1),
        width: "25%",
        fontWeight: "bolder",
        fontSize: "120%",
      },
      withoutLabel: {
        marginTop: theme.spacing(3),
      },
      textField: {
        width: "25ch",
      },
      searchSelectionText: {
        fontWeight: "bolder",
        fontSize: "220%",
      },
      selectedTopicLabel: {
        backgroundColor: "#fffffff",
        borderColor: "#fffffff",
      },
    };
  });

  const [targetPm, setTargetPm] = React.useState(null);
  const [targetOwner, setTargetOwner] = React.useState(null);
  const [modal, setModal]= React.useState(null);
  const [display, setDisplay] = React.useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();

  const show = (target) => {
    setDisplay(true);
    setModal(target)
  };

  const hide = () => {
    setDisplay(false);
    setModal(null);
  };
  const onSetTargetPm = (event) => {
    setTargetPm(event.target.value);
  };

  const onSetTargetOwner = (event) => {
    setTargetOwner(event.target.value);
  };

  const transferUnitToNewPM = () => {
    dispatch(setSnackbar(true, "warning", "Transfering unit to new PM"));
    props.transferUnitsToNewPm([props.units.data.unit.uuid], targetPm).then(() => {
      if (!props.units.error || props.units.error == null || props.units.error == "") {
        dispatch(setSnackbar(true, "success", "Unit Transfer Successful"));
        setTargetPm(null);
        hide();
        if (props.refreshPage) {
          props.refreshPage();
        }
      }
      else {
        dispatch(setSnackbar(true, "error", "Error transfering unit, please try again later"));
      }
    });
  };

  return (
    <Grid item lg={12}>
      <Grid container className={classes.rightSideHeader} spacing={2}>
        {/*<Grid item>*/}
        {/*  <Button*/}
        {/*    variant="contained"*/}
        {/*    color="secondary"*/}
        {/*    onClick={()=>show('owner')}*/}
        {/*    my={2}*/}
        {/*  >*/}
        {/*    Transfer To Other Owner*/}
        {/*  </Button>*/}
        {/*</Grid>*/}
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            onClick={()=>show('propertyManager')}
            my={2}
          >
            Transfer To Other Property Manager
          </Button>
        </Grid>
      </Grid>
      <Dialog
        open={display}
        TransitionComponent={Transition}
        fullWidth={"lg"}
        maxWidth={"lg"}
        keepMounted
        onClose={hide}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Transfer Units To New {modal === 'owner' ? 'Owner' : 'Property Manager'}</DialogTitle>
        <DialogContent>
          <Grid container xs={12} spacing={2} className="pb-10">
            <Grid item md={12} xs={12}>
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel id="selectedPMDropDownLabel"
                  className={classes.selectedTopicLabel}>
                  {modal === 'owner' ? 'Owner' : 'Property Manager'}
                </InputLabel>
                {modal==='propertyManager' ?
                    <Select
                    labelId="selectedPMDropDownLabel"
                    id="selectedPMDropDown"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={targetPm}
                    onChange={onSetTargetPm}
                    name="propertyManagerUuid"
                    ty
                    fullWidth
                    my={2}
                >
                  {props.propertyManagers.data.propertyManagersList.items.map(
                      (item, index) => (
                          <MenuItem id={"pmOption" + index} value={item.uuid}>
                            {item.name} {item.developerId && <StatusTag
                              className="ml-3"
                              type={"Default"}
                              label={"Developer"}
                          />
                          }
                          </MenuItem>
                      )
                  )}
                </Select>
                :
                    <Select
                        labelId="selectedOwnerDropDownLabel"
                        id="selectedOwnerDropDown"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={targetOwner}
                        onChange={onSetTargetOwner}
                        name="ownerUuid"
                        ty
                        fullWidth
                        my={2}
                    >
                      {props.owners.data.ownersList.items.map(
                          (item, index) => (
                              <MenuItem id={"ownerOption" + index} value={item.uuid}>
                                {item.name}
                              </MenuItem>
                          )
                      )}
                    </Select>
                }
              </FormControl>
            </Grid>

            {targetPm != null &&
              <>
                {modal==='propertyManager' &&
                    <Grid item md={12} xs={12}>
                      <Alert severity="error">Warning!!! Transfering units will deactivate old property manager default
                        accounts as well as make the new property manager account the current occupant if a Tenant is
                        not
                        currently occupying the unit. This cannot be undone.
                      </Alert>
                    </Grid>}
                <Grid item md={4} xs={6}>
                  <Button variant="contained" onClick={transferUnitToNewPM} color={"primary"} fullWidth>
                    Transfer Unit
                  </Button>
                </Grid>
              </>
            }
          </Grid>
        </DialogContent>
      </Dialog>
    </Grid >
  );
}

// Component Properties
ToolBar.propTypes = {
  // Store objects:
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  propertyManagers: PropTypes.object.isRequired,
  units: PropTypes.object.isRequired,
  // Store object functions:
  transferUnitsToNewPm: PropTypes.func.isRequired,
  refreshPage: PropTypes.func.isRequired,
};
// Component State
function ToolBarState(state) {
  return {
    user: state.user,
    apl: state.apl,
    units: state.units,
    owners: state.owners,
    unitTypes: state.unitTypes,
    propertyManagers: state.propertyManagers
  };
}
export default connect(ToolBarState, {
  transferUnitsToNewPm
})(ToolBar);
