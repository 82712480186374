// Imports
import axios from "axios";
require("../../../setup/config/apiConfig");

export const RESET = "RESET";

export const GET_PROPERTY_GLCODES = "GET_PROPERTY_GLCODES";
export const GET_PROPERTY_GLCODES_RESPONSE =
  "GET_PROPERTY_GLCODES_RESPONSE";
export const GET_PROPERTY_GLCODES_RESPONSE_FAIL =
  "GET_PROPERTY_GLCODES_RESPONSE_FAIL";

export const GET_PROPERTY_GLCODE = "GET_PROPERTY_GLCODE";
export const GET_PROPERTY_GLCODE_RESPONSE = "GET_PROPERTY_GLCODE_RESPONSE";
export const GET_PROPERTY_GLCODE_FAIL = "GET_PROPERTY_GLCODE_FAIL";

export const POST_PROPERTY_GLCODE = "POST_PROPERTY_GLCODE";
export const POST_PROPERTY_GLCODE_RESPONSE = "POST_PROPERTY_GLCODE_RESPONSE";
export const POST_PROPERTY_GLCODE_FAIL = "POST_PROPERTY_GLCODE_FAIL";

export const PUT_PROPERTY_GLCODE = "PUT_PROPERTY_GLCODE";
export const PUT_PROPERTY_GLCODE_RESPONSE = "PUT_PROPERTY_GLCODE_RESPONSE";
export const PUT_PROPERTY_GLCODE_FAIL = "PUT_PROPERTY_GLCODE_FAIL";
export const UPDATE_PROPERTY_GLCODE_LOCAL_STATE = "UPDATE_PROPERTY_GLCODE_LOCAL_STATE";


export function updatePropertyGLCodeLocalState(newPropertyGlCode) {
  return { type: UPDATE_PROPERTY_GLCODE_LOCAL_STATE, newPropertyGlCode }
}



export function get(isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: GET_PROPERTY_GLCODES,
      isLoading: isLoading,
    });
    return axios
      .get(`/propertyGLCode`)
      .then((response) => {
        dispatch({
          type: GET_PROPERTY_GLCODES_RESPONSE,
          propertyGLCodes: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_PROPERTY_GLCODES_RESPONSE_FAIL,
          isLoading: false,
          error: error,
        });
      });
  };
}

export function getByPropertyId(id, isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: GET_PROPERTY_GLCODE,
      isLoading: isLoading,
    });
    return axios
      .get(`/propertyglcode/property/${id}`)
      .then((response) => {
        dispatch({
          type: GET_PROPERTY_GLCODE_RESPONSE,
          propertyGLCode: response.data[0], // TODO: fix
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_PROPERTY_GLCODE_FAIL,
          isLoading: false,
          error: error,
        });
      });
  };
}

export function post(u, isLoading = false) {
  return (dispatch) => {
    dispatch({
      type: POST_PROPERTY_GLCODE,
      isLoading,
    });
    return axios
      .post("/propertyglcode/", u)
      .then((response) => {
        dispatch({
          propertyGLCode: response.data,
          type: POST_PROPERTY_GLCODE_RESPONSE,
        });
      })
      .catch((error) => {
        //console.log(error);
        dispatch({
          type: POST_PROPERTY_GLCODE_FAIL,
          error: error,
        });
      });
  };
}

export function put(u, isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: PUT_PROPERTY_GLCODE,
      isLoading,
    });
    return axios
      .put("/propertyglcode/" + u.uuid, u)
      .then((response) => {
        dispatch({
          propertyGLCode: response.data,
          type: PUT_PROPERTY_GLCODE_RESPONSE,
        });
      })
      .catch((error) => {
        //console.log(error);
        dispatch({
          type: PUT_PROPERTY_GLCODE_FAIL,
          error: error,
        });
      });
  };
}
