import {
  Button as MuiButton,
  Card,
  CardContent,
  CircularProgress as MuiCircularProgress,
  Divider as MuiDivider,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress as MuiLinearProgress,
  MenuItem,
  Select as MuiSelect,
  TextField as MuiTextField,
  Typography,
  Switch,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
// Redux Components
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components/macro";
import { txtBoxDateFormatter } from "../../../setup/helpers";
import { setSnackbar } from "../../snackbar/api/snackbar";
import {
  moveOutCustomer
} from "../api/actions";

const LinearProgress = MuiLinearProgress;
const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Select = styled(MuiSelect)(spacing);

const AccountNewMoveOutForm = (props) => {


  const [createFinalBill, setCreateFinalBill] = React.useState(false);
  const [moveInPropertyManager, setMoveOutPropertyManager] = React.useState(true);

  const dispatch = useDispatch();
  const useStyles = makeStyles((theme) => ({
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    loadingIcon: {
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
    },
  }));

  useEffect(() => {
  }, [props.account.isLoading])

  // Handles vendor drop down
  const classes = useStyles();
  // Updates the local state object of "bill in progress" upon each modification
  const onFieldChange = (event) => {
    // Gets the current vendor info data and updates it with the event's name and value:
    if (event.target.type == "number") {
      props.moves.newMoveOut.meterReads[event.target.name] = Number(event.target.value);
    }
    else {
      props.moves.newMoveOut[event.target.name] = event.target.value;
    }


    if (event.target.name == "createFinalBill") {
      if (createFinalBill) {
        props.moves.newMoveOut[event.target.name] = 0;
        setCreateFinalBill(false);
      }
      else {
        props.moves.newMoveOut[event.target.name] = 1;
        setCreateFinalBill(true);
      }
    }
    if (event.target.name == "moveInPropertyManager") {
      if (moveInPropertyManager) {
        props.moves.newMoveOut[event.target.name] = 0;
        setMoveOutPropertyManager(false);
      }
      else {
        props.moves.newMoveOut[event.target.name] = 1;
        setMoveOutPropertyManager(true);
      }
    }
  };

  const createMoveOut = () => {
    dispatch(setSnackbar(true, "warning", "Moving Out Current Tenant"));
    props.moveOutCustomer(props.moves.newMoveOut, props.account.data).then(() => {
      if (props.moves.accountData.error == null) {
        if (props.refreshPage) {
          props.refreshPage();
        }
        if (props.onClose) {
          props.onClose();
        }
        dispatch(setSnackbar(true, "success", "Move Successful"));
      }
      else {
        dispatch(setSnackbar(true, "error", "Error Creating account movement."));
      }
    });
  }

  if (props.account.isLoading) {
    return (
      <Card mb={6}>
        <Typography variant="h6" gutterBottom>
          Account Move Out Form
        </Typography>
        <CardContent>
          <Typography variant="h3" gutterBottom>
            Account Loading...
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Account New Move Out
        </Typography>
        <Divider className="mb-3 mt-3" />

        <Grid container spacing={4}>
          <Grid item lg={4} md={6} sm={6}>
            <div className="pt-1 pb-3">
              <h6 className="title font-weight-normal mb-0">Transfer Unit Ownership:   {props.account.data.account.serviceAddress1}{props.account.data.account.serviceAddress2 &&
                ", " + props.account.data.account.serviceAddress2
              } </h6>
            </div>
          </Grid>
          <Grid item lg={4} md={6} sm={6}>
            <div className="pt-1 pb-1">
              <h6 className="title font-weight-normal mb-0">Account Number: {props.account.data.account.accountNum}</h6>
            </div>
          </Grid>
          <Grid item lg={4} md={6} sm={6}>
            <div className="pt-1 pb-1">
              <h6 className="title font-weight-normal mb-0">Account Holder: {props.account.data.account.firstName} {props.account.data.account.lastName &&
                ", " + props.account.data.account.lastName
              }</h6>
            </div>
          </Grid>
        </Grid>
        <Divider className="mb-3 mt-3" />
        <Grid container spacing={4}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            lg={4} md={6} sm={6}
          >
            <Grid item xs={3}>
              <Switch
                checked={createFinalBill}
                onChange={onFieldChange}
                name="createFinalBill"
              />
            </Grid>
            <Grid item xs={9}>
              <Typography variant="body2">Create Final Bill</Typography>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            lg={4} md={6} sm={6}
          >
            <Grid item xs={3}>
              <Switch
                checked={moveInPropertyManager}
                onChange={onFieldChange}
                name="moveInPropertyManager"
              />
            </Grid>
            <Grid item xs={9}>
              <Typography variant="body2">Move In Property Manager</Typography>
            </Grid>
          </Grid>
          <Grid item lg={4} md={6} sm={6}>
            <TextField
              label="Move Out Date"
              type="date"
              onChange={onFieldChange}
              name="moveOutDate"
              defaultValue={txtBoxDateFormatter(props.moves.newMoveOut.moveOutDate)}
              fullWidth
              my={2}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          {(props.account.data.unit && props.account.data.unit.meters
           && props.propertyUtilities?.data?.propertyUtility?.billingType  == 'BILL') &&
            <>
              {props.account.data.unit.meters.map((meter, index) => {
                return (
                  <Grid item lg={4} md={6} sm={6}>
                    <Typography className="mb-3" variant="body2">{"Meter: #" + meter.meterNum}</Typography>
                    <TextField
                      label={"Read: "}
                      type="number"
                      onChange={onFieldChange}
                      name={meter.id}
                      fullWidth
                      my={2}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                )
              })
              }
            </>
          }
          {props.moves.newMoveOut.createFinalBill ?
            <Grid item lg={4} md={6} sm={6}>
              <TextField
                label="Bill Due Date"
                type="date"
                onChange={onFieldChange}
                name="billDueDate"
                defaultValue={txtBoxDateFormatter(props.moves.newMoveOut.billDueDate)}
                fullWidth
                className="mt-3"
                my={2}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            :
            <></>
          }
          <Grid item lg={12} md={12} sm={12}>
            <Divider className="mb-3 mt-3" />
          </Grid>
        
           
          <Grid item lg={4} md={4} sm={6}>
            <TextField
              label="Forward Address"
              type="text"
              onChange={onFieldChange}
              name="fwdAddress1"
              defaultValue={props.moves.newMoveOut.fwdAddress1}
              fullWidth
              my={2}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={6}>
            <TextField
              label="Forward Address (Apt)"
              type="text"
              onChange={onFieldChange}
              name="fwdAddress2"
              defaultValue={props.moves.newMoveOut.fwdAddress2}
              fullWidth
              my={2}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={6}>
            <TextField
              label="Forward City"
              type="text"
              onChange={onFieldChange}
              name="fwdCity"
              defaultValue={props.moves.newMoveOut.fwdCity}
              fullWidth
              my={2}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={6}>
            <TextField
              label="Forward State"
              type="text"
              onChange={onFieldChange}
              name="fwdState"
              defaultValue={props.moves.newMoveOut.fwdState}
              fullWidth
              my={2}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={6}>
            <TextField
              label="Forward Zip"
              type="text"
              onChange={onFieldChange}
              name="fwdZip"
              defaultValue={props.moves.newMoveOut.fwdZip}
              fullWidth
              my={2}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
        <Button
          className="mb-3 mt-3 mr-3"
          variant="contained"
          color="secondary"
          onClick={createMoveOut}
        >
          Create Move Out
        </Button>
      </CardContent>
    </Card>
  );
};

// Component Properties
AccountNewMoveOutForm.propTypes = {
  // Store objects:
  account: PropTypes.object.isRequired,
  moves: PropTypes.object.isRequired,
  //userProfile: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  moveOutCustomer: PropTypes.func.isRequired,
  propertyUtilities: PropTypes.object.isRequired,
};

// Component State
function AccountNewMoveOutFormState(state) {
  return {
    account: state.account,
    apl: state.apl,
    moves: state.moves,
    propertyUtilities: state.propertyUtilities,
  };
}
export default connect(AccountNewMoveOutFormState, { moveOutCustomer, })(AccountNewMoveOutForm);
