import {
  Button,
  Card as MuiCard,
  CardContent,
  CircularProgress as MuiCircularProgress,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  MenuItem,
  TextField as MuiTextField,
} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
//Material UI Components and Functions
import { makeStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
// Redux Components
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import { createNewNote, setNotes } from "../api/actions";

const CenteredContent = styled.div`
  text-align: center;
`;

const CircularProgress = styled(MuiCircularProgress)(spacing);
const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const FormControl = styled(MuiFormControl)(spacing);
const TextField = styled(MuiTextField)(spacing);

// Custom Styles for property selection drop down

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  loadingIcon: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  selectedTopicLabel: {
    backgroundColor: "#fffffff",
    borderColor: "#fffffff",
  },
}));

const NewNoteForm = (props) => {
  // Handles vendor drop down
  const [topic, setTopic] = React.useState(84); // Default topic is a general note
  const [newNoteFormObject, setNewNoteFormObject] = React.useState(
    props.notes.newNote
  );

  const makeNewNote = () => {
    if (props.account.data.account) {
      props.notes.newNote.accountId = props.account.data.account.id;
    }
    var newNote = Object.assign({}, props.notes.newNote);
    if (props.notes.newNote.dueDate == null) {
      delete newNote.dueDate;
    }
    props.createNewNote(newNote, props.user.details);
    props.notes.newNote.accountId = -1;
    props.notes.newNote.accountId = 84;
    props.notes.newNote.note = "";
    props.onUpdate(true);
  };

  const handleSelectedTopicChange = (event) => {
    props.notes.newNote.noteTopicId = event.target.value;
    setTopic(event.target.value);
  };
  const classes = useStyles();
  // Updates the local state object of "bill in progress" upon each modification
  const onFieldChange = (event) => {
    // Gets the current vendor info data and updates it with the event's name and value:
    if (event.target.type == "number") {
      props.notes.newNote[event.target.name] = Number(event.target.value);
    } else {
      props.notes.newNote[event.target.name] = event.target.value;
    }
  };
  return (
    <Card mb={6} elevation={3}>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item lg={12} md={12} sm={12}>
            <FormControl fullWidth my={2}>
              <InputLabel
                className={classes.selectedTopicLabel}
                id="selectedNoteTopicDropDownLabel"
              >
                Note Topic{"  "}
                {props.apl.isLoading && <CircularProgress size={15} m={0} />}
              </InputLabel>
              {props.apl.genericTypes.noteTopic.isLoading ? (
                <Select
                  labelId="selectedNoteTopicDropDownLabel"
                  disabled
                  id="noteTopicId"
                  value={topic}
                  onChange={handleSelectedTopicChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="noteTopicId"
                  fullWidth
                  my={2}
                >
                </Select>
              ) : (
                <Select
                  labelId="selectedNoteTopicDropDownLabel"
                  id="noteTopicId"
                  name="noteTopicId"
                  value={topic}
                  onChange={handleSelectedTopicChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  my={2}
                >
                  {props.apl.genericTypes.noteTopic.data &&
                    props.apl.genericTypes.noteTopic.data.length > 0 ? [
                    props.apl.genericTypes.noteTopic.data.map((item, key) => {
                      return (
                        <MenuItem id={"noteType" + key} value={item.id}>
                          {item.description}
                        </MenuItem>
                      );
                    })
                  ] : [
                    <MenuItem id={"noteType0"} value={-1}>
                      No Topic Types Found
                    </MenuItem>
                  ]
                  }
                </Select>
              )}
            </FormControl>
          </Grid>
          <Grid item lg={12} md={12} sm={12}>
            <TextField
              fullWidth
              type="text"
              id="note"
              label="Note"
              defaultValue={newNoteFormObject.note}
              onChange={onFieldChange}
              multiline={true}
              rows={10}
              rowsMax={10}
              variant="outlined"
              name="note"
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12}>
            <TextField
              my={2}
              fullWidth
              type="date"
              id="dueDate"
              label="Due Date"
              defaultValue={newNoteFormObject.dueDate}
              onChange={onFieldChange}
              variant="outlined"
              name="dueDate"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12}>
            <Button
              mr={1}
              variant="contained"
              color="secondary"
              fullWidth
              onClick={makeNewNote}
              my={2}
            >
              Create New Account Note
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

// Component Properties
NewNoteForm.propTypes = {
  // Store objects:
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  notes: PropTypes.object.isRequired,

  createNewNote: PropTypes.func.isRequired,
  setNotes: PropTypes.func.isRequired,
};

// Component State
function NewNoteFormState(state) {
  return {
    user: state.user,
    apl: state.apl,
    account: state.account,
    notes: state.notes,
  };
}
export default connect(NewNoteFormState, { createNewNote, setNotes })(
  NewNoteForm
);
