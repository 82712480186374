// material ui
import { Button, Grid, TextField } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import axios from "axios";
import moment from "moment";
import React, { useState } from "react";

const useStyles = makeStyles({
  rowHeader: {
    fontWeight: "bold",
    textAlign: "left",
  },

  tableCell: {
    textAlign: "right",
  },
});

export default (props) => {
  const classes = useStyles();

  const [beginDate, setBeginDate] = useState(
    moment().subtract(1, "month").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [pageNum, setPageNum] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [bills, setBills] = useState([]);

  const fieldNames = bills && bills.length > 0 ? Object.keys(bills[0]) : [];

  const fetchDeletedBills = async function () {
    try {
      let headers = {
        "x-access-token": localStorage.getItem("token"),
      };

      let data = await axios.put("/report/call", {
        function: "GetDeletedBills",
        params: [beginDate, endDate, pageNum, rowsPerPage],
      });
      setBills(data.data[0]);
    } catch (err) {}
  };

  const handleChangePage = (event) => {
    setPageNum(parseInt(event.target.value));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
  };

  return (
    <div>
      <Grid container item direction="row" xs={12}>
        <Grid container item xs={2}>
          <TextField
            id="date"
            label="Start Date"
            type="date"
            defaultValue={beginDate}
            onChange={(e) => setBeginDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <Grid container item xs={2}>
          <TextField
            id="date"
            label="End Date"
            type="date"
            defaultValue={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <Grid container item xs={4}>
          <Grid container item direction="row" xs={12}>
            <Grid container item xs={4}>
              <TextField
                label="Rows Per Page"
                type="number"
                onChange={handleChangeRowsPerPage}
                name="rowsPerPage"
                defaultValue={rowsPerPage}
                //required
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid container item xs={4}>
              <TextField
                label="Page Num"
                type="number"
                onChange={handleChangePage}
                name="pageNum"
                defaultValue={pageNum}
                //required
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container item xs={2}>
          <Button
            onClick={() => fetchDeletedBills()}
            size="small"
            variant="contained"
            color="secondary"
          >
            Fetch Bills
          </Button>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              {fieldNames.map((name, index) => {
                return (
                  <TableCell className={classes.rowHeader} key={index}>
                    {name}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {bills.map((bill, index) => {
              return (
                <TableRow key={index}>
                  {fieldNames.map((name, ind) => {
                    const data =
                      name == "total"
                        ? `$${bill[name].toFixed(2)}`
                        : bill[name];
                    return (
                      <TableCell className={classes.tableCell} key={ind}>
                        {data}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
