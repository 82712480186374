import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import logo from "../../../assets/images/logo-dark.png";
import { formatMoney } from "../../../setup/helpers";
const BillPreview = (props) => {
  const today = moment().format("MMMM Do, YYYY");
  const due =
    moment(props.billing.data.billInProgress.billInformation.dueDate).format(
      "mm/dd/yyyy"
    ) || "";
  const selectedBill = props.billing.data.selectedBill.bill || props.bill;
  const property = props.billing.data.selectedProperty;
  const trialBillItemsCount = props.billing.data.billInProgress.trialBillingItems.items.length || 0;
  const passItems = props.billing.data.billInProgress.vendorInformation.vendorBillPassthroughItems.items;

  // if loading done we count up the common charge totals
  let runningCommonExpenses = [];
  let activeResidualAmounts = [];
  let totalCommonCharge = 0.0;
  let kwhTotal = 0;
  props.properties.propertyAdditionalCommonAreaExpenses.items.map(
    (CommonAreaExpense) => {
      if (CommonAreaExpense.active) {
        runningCommonExpenses.push(CommonAreaExpense);
        totalCommonCharge = totalCommonCharge + CommonAreaExpense.expenseValue;
      }
    }
  );
  let TodayNumber = new Date();
  let currentMonth = TodayNumber.getMonth();
  let currentYear = TodayNumber.getFullYear();
  props.properties.propertyResidualAmountsToAdd.items.map((RollOverItem) => {
    let remainingUSD = RollOverItem.cost || 0;
    let remainingKWH = RollOverItem.kwh || 0;
    if (RollOverItem.transactions) {
      RollOverItem.transactions.map((transaction) => {
        remainingUSD = remainingUSD - transaction.costUsed;
        remainingKWH = remainingKWH - transaction.kwhUsed;
        if (
          transaction.fiscalMonth == currentMonth &&
          transaction.fiscalYear == currentYear
        ) {
          let newTransaction = {
            rollOverId: RollOverItem.id,
            description: RollOverItem.description,
            createdDate: RollOverItem.created,
            transaction: transaction,
          };
          activeResidualAmounts.push(newTransaction);
          if (transaction.costUsed != null) {
            totalCommonCharge = totalCommonCharge + (transaction.costUsed / props.properties.data.property.totalUnits);
          }
          if (transaction.kwhUsed != null) {
            kwhTotal = kwhTotal + transaction.kwhUsed / props.properties.data.property.totalUnits;
          }
        }
      });
      activeResidualAmounts.map((activeResidual) => {
        if (RollOverItem.id == activeResidual.rollOverId)
          activeResidual.remainingUSD = remainingUSD;
        activeResidual.remainingKWH = remainingKWH;
      });
    }
  });
  kwhTotal = kwhTotal.toFixed(4);
  let totalAmt = totalCommonCharge;
  const message = props.billing.data.billInProgress.billInformation.billMessage || "";

  return (
    <div className="pdfView">
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      {/* Favicon */}
      <link rel="icon" href="./images/favicon.png" type="image/x-icon" />
      <h1>CCOS Bill Preview</h1>
      <h3>THIS BILL HAS NOT BEEN CREATED</h3>
      <div className="invoice-box">
        <table>
          <tbody>
            <tr className="top">
              <td colSpan={12}>
                <table>
                  <tbody>
                    <tr>
                      <td className="title">
                        <img
                          src={logo}
                          alt="CCOS"
                          style={{ width: "100%", maxWidth: 300 }}
                        />
                      </td>
                      <td className="pdfDate">
                        Bill Id #: TBD
                        <br />
                        Created: {today}
                        <br />
                        Due: {due}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr className="information">
              <td colSpan={12}>
                <table>
                  <tbody>
                    <tr className="pdfHeader">
                      <td>
                        {`Account: ${selectedBill.accountNum}`}
                        <br />
                        {selectedBill.customerName}
                        <br />
                        {selectedBill.serviceAddress}
                        <br />
                        {`${property.city}, ${property.state} ${property.zip}`}
                      </td>
                      <td>
                        Columbus& Central Ohio Systems, LLC
                        <br />
                        Electric Bill
                        <br />
                        Hours: Mon-Fri 8:30am-4:30pm
                        <br />
                        Phone: (888) 850-0098
                        <br />
                        Emergencies: (877) 349-5441
                        <br />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <td colSpan={12}>
              <tr className="Message">
                <td>Message</td>
              </tr>
            </td>
            <tr className="details">
              <td>{message}</td>
            </tr>
            <tr className="heading">
              <td>Bill Detail</td>
              <td>Meter #</td>
              <td>Service Period</td>
              <td>Readings</td>
              <td>Usage</td>
              <td>Price</td>
            </tr>
            <tr className="item">
              <td>Electric Transmission, Distribution, & Generation</td>
              <td>{selectedBill.meterNum}</td>
              <td>{`${moment(selectedBill.prevReadDate).format(
                "MM/DD/YYYY"
              )} - ${moment(selectedBill.currReadDate).format(
                "MM/DD/YYYY"
              )}`}</td>
              <td>{`${selectedBill.prevReadAmt} - ${selectedBill.currReadAmt}`}</td>
              <td>{selectedBill.usage}</td>
              <td>{`${formatMoney(selectedBill.totalCharge)}`}</td>
            </tr>
            {totalCommonCharge != 0 &&
              <tr className="item">
                <td>Common Area Charge</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>{`${formatMoney(totalCommonCharge)}`}</td>
              </tr>
            }
            {passItems.map((item) => {
              let total = 0;
              if (item.flatRate == 1) {
                total = item.itemAmt;
              } else {
                total = Math.round(item.itemAmt / trialBillItemsCount, 2);
              }

              totalAmt += total;

              return (
                <tr className="item">
                  <td>{item.itemDesc}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>{`${formatMoney(total)}`}</td>
                </tr>
              );
            })}
            <tr className="item last"></tr>
            <tr className="total">
              <td />
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>
                <strong>{`Total: ${formatMoney(
                  totalAmt + selectedBill.totalCharge
                )}`}</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
// Component State
function BillPreviewState(state) {
  return {
    user: state.user,
    apl: state.apl,
    billing: state.billing,
    properties: state.properties,
  };
}
export default connect(BillPreviewState, {})(BillPreview);
