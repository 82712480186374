import {
  Button as MuiButton,
  Card,
  CardContent,
  CircularProgress as MuiCircularProgress,
  Divider as MuiDivider,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress as MuiLinearProgress,
  MenuItem,
  Select as MuiSelect,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
// Redux Components
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components/macro";
import { txtBoxDateFormatter } from "../../../setup/helpers";
import { setSnackbar } from "../../snackbar/api/snackbar";
import {
  existingMoveOutUpdate,
} from "../api/actions";

const LinearProgress = MuiLinearProgress;
const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Select = styled(MuiSelect)(spacing);

const AccountMoveOutItemForm = (props) => {
  const dispatch = useDispatch();
  const useStyles = makeStyles((theme) => ({
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    loadingIcon: {
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
    },
  }));

  useEffect(() => {
    if (props.account.data.move && props.account.data.move.moveOut.id) {
      props.moves.accountData.moveOut = props.account.data.move.moveOut;
    }
  }, [props.account.isLoading])

  // Handles vendor drop down
  const classes = useStyles();
  if (props.account.isLoading && props.moves.accountData.moveOut == null) {
    return (
      <Card mb={6}>
        <CardContent>
          <Typography variant="h3" gutterBottom>
            Account Move Out Loading...
          </Typography>
        </CardContent>
      </Card>
    );
  }
  if (props.moves.accountData.moveOut == null) {
    return (
      <Card mb={6}>
        <CardContent>
          <Typography variant="h3" gutterBottom>
            Account move out not found
          </Typography>
        </CardContent>
      </Card>
    );
  }

  // Updates the local state object of "bill in progress" upon each modification
  const onFieldChange = (event) => {
    // Gets the current vendor info data and updates it with the event's name and value:
    if (event.target.type == "number") {
      props.moves.accountData.moveOut[event.target.name] = Number(
        event.target.value
      );
    } else {
      props.moves.accountData.moveOut[event.target.name] = event.target.value;
    }
    if (event.target.name == "moveOutDate" && props.account.data.move && props.account.data.move.moveOut.id) {
      props.account.data.move.moveOut = props.moves.accountData.moveOut;
    }
  };

  const submitMoveOutUpdate = () => {
    console.log(props.moves.accountData.moveOut);
    if (props.moves.accountData.moveOut.id) {
      dispatch(
        setSnackbar(true, "warning", "Updating account move out object")
      );
      props.existingMoveOutUpdate(props.moves.accountData.moveOut).then(() => {
        if (props.moves.accountData.error == null) {
          dispatch(setSnackbar(true, "success", "Move Out item updated successfully"));
        }
        else {
          dispatch(setSnackbar(true, "error", "Error updating move item."));
        }
      });
    }
  };
  return (
    <Card className="mb-6">
      <Typography variant="h6" gutterBottom>
        Edit Move Out Data
      </Typography>
      <Divider className="mb-3 mt-3" />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item lg={4} md={4} sm={6}>
            <TextField
              label="Move Out Date"
              type="date"
              onChange={onFieldChange}
              name="moveOutDate"
              defaultValue={txtBoxDateFormatter(props.moves.accountData.moveOut.moveOutDate)}
              fullWidth
              my={2}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={6}>
            <TextField
              label="Service Zip Code"
              type="text"
              onChange={onFieldChange}
              name="serviceZipCode"
              defaultValue={props.moves.accountData.moveOut.serviceZipCode}
              fullWidth
              my={2}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={6}>
            <TextField
              label="Email"
              type="text"
              onChange={onFieldChange}
              name="email"
              defaultValue={props.moves.accountData.moveOut.email}
              fullWidth
              my={2}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={6}>
            <TextField
              label="Phone"
              type="text"
              onChange={onFieldChange}
              name="phone"
              defaultValue={props.moves.accountData.moveOut.phone}
              fullWidth
              my={2}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={6}>
            <TextField
              label="Forward Address"
              type="text"
              onChange={onFieldChange}
              name="fwdAddress1"
              defaultValue={props.moves.accountData.moveOut.fwdAddress1}
              fullWidth
              my={2}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={6}>
            <TextField
              label="Forward Address (Apt)"
              type="text"
              onChange={onFieldChange}
              name="fwdAddress2"
              defaultValue={props.moves.accountData.moveOut.fwdAddress2}
              fullWidth
              my={2}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={6}>
            <TextField
              label="Forward City"
              type="text"
              onChange={onFieldChange}
              name="fwdCity"
              defaultValue={props.moves.accountData.moveOut.fwdCity}
              fullWidth
              my={2}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={6}>
            <TextField
              label="Forward State"
              type="text"
              onChange={onFieldChange}
              name="fwdState"
              defaultValue={props.moves.accountData.moveOut.fwdState}
              fullWidth
              my={2}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={6}>
            <TextField
              label="Forward Zip"
              type="text"
              onChange={onFieldChange}
              name="fwdZip"
              defaultValue={props.moves.accountData.moveOut.fwdZip}
              fullWidth
              my={2}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
        <Button
          className="mb-3 mt-3 mr-3"
          variant="contained"
          color="secondary"
          onClick={submitMoveOutUpdate}
        >
          Update Move Out
        </Button>
      </CardContent>
    </Card>
  );
};

// Component Properties
AccountMoveOutItemForm.propTypes = {
  // Store objects:
  account: PropTypes.object.isRequired,
  moves: PropTypes.object.isRequired,
  //userProfile: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  existingMoveOutUpdate: PropTypes.func.isRequired,
};

// Component State
function AccountMoveOutItemFormState(state) {
  return {
    account: state.account,
    apl: state.apl,
    moves: state.moves
  };
}
export default connect(AccountMoveOutItemFormState, {
  existingMoveOutUpdate
})(AccountMoveOutItemForm);
