//React Components and Hooks
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
//Images
import logo from "../../assets/images/logo-dark.png";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" to="/">
        Columbus& Central Ohio Systems LLC
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage:
      "url(https://borror.com/wp-content/uploads/2017/08/downtown-columbus-ohio.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(24, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const HomePage = () => {
  const classes = useStyles();
  const history = useHistory();
  const [devMode, setDevMode] = useState(localStorage.getItem("mode") || true);
  const toggle = () => {
    setDevMode(!devMode);
    localStorage.setItem("mode", devMode);
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <img src={logo} alt="apl" />
          <Typography component="h3" variant="h3">
            Ubil+
          </Typography>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => history.push("/login")}
          >
            Sign In
          </Button>

          {/* <Button
              type="submit"
              variant="contained"
              color="secondary"
              className={classes.submit}
            >
              Register
            </Button> */}
          <Link to="/" variant="body1">
            {"Version: Beta 1.0"}
          </Link>
          <Box mt={5}>
            <Copyright />
          </Box>
          <Grid
            component="label"
            container
            alignItems="center"
            spacing={1}
          ></Grid>
        </div>
      </Grid>
    </Grid>
  );
};

export default HomePage;
