import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";

import moment from 'moment';

import Helmet from "react-helmet";
import CheckCircle from "@material-ui/icons";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import CachedIcon from '@mui/icons-material/Cached';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { red, green, blue } from "@material-ui/core/colors";
import { makeStyles, withStyles } from '@material-ui/core/styles';

import {
  Checkbox, Grid, IconButton, Link, Breadcrumbs as MuiBreadcrumbs, Divider as MuiDivider, Paper as MuiPaper, Table, TableBody, TableContainer, TableCell,
  TableHead, TablePagination, Chip, TableRow, TableSortLabel, Toolbar, Tooltip, Typography, FormControlLabel, Switch, Menu, MenuItem, TextField as MuiTextField, Button as MuiButton,
  FormControl, InputLabel, Select, Input,
} from "@material-ui/core";




import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import CurrencyTextField from '@unicef/material-ui-currency-textfield'

import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  Loop as LoopIcon,
  Check
} from "@material-ui/icons";
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import EditIcon from '@material-ui/icons/Edit';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import DescriptionIcon from '@material-ui/icons/Description';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import EvStationIcon from '@mui/icons-material/EvStation';
import PrintIcon from '@mui/icons-material/Print';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';

import { spacing } from "@material-ui/system";
import { dateFormatter, dateFormatterNoTime, dateTime, formatMoney, txtBoxDateFormatter, formatPhoneNumber } from "../../setup/helpers";

import {
  BrowserRouter as Router,
  Route,
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";

// Types i initially plan to support: 
// text, number, checkBox, date

const Button = styled(MuiButton)(spacing);
const TextField = styled(MuiTextField)(spacing);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export function EditableTableRow({
  currentObject, fieldTitles, fieldTypes, fieldNames,
  handleRowClick, handleRowSelected,
  isItemSelected, handleStandardItemChange,
  dropDownFields,
  globalIndex,
  readOnly,
  conditionalStyle,
  onChangeFields,
  urlFields,
  handlePreviewClick,
  handleGenerateClick,
  popupModelFields,
  showNumber,
  markItemAsEdited,
  hideRowFieldTitles = false,
  isDenseTable = false,
  itemSaveOnTextboxLeave = false
}) {


  const useStyles = makeStyles((theme) => ({
    tableCell: {
      padding: "14px"
    },
    rowNumberTableCell: {
      paddingRight: "14px"
    },
  }));

  const history = useHistory();
  const navTo = (page) => history.push(page);
  const [customOnChangeFields, setOnChangeFields] = React.useState(onChangeFields);
  const [customUrlFields, setUrlFields] = React.useState(urlFields);
  const [rowObject, setRowObject] = React.useState(Object.assign({}, currentObject));
  const classes = useStyles();

  const [cellPoppedUp, setPoppedUpCell] = React.useState(null);

  const passedGlobalIndex = globalIndex || 0;
  const HandleRowSelected = () => {
    handleRowSelected(currentObject);
  }

  const HandlePreviewClicked = (event) => {
    if (event) event.stopPropagation();
    handlePreviewClick(currentObject);
  }

  const HandleGenerateClicked = () => {
    handleGenerateClick(currentObject);
  }


  const HandleRowClick = () => {
    handleRowClick(currentObject);
  }

  const showFieldPopupClick = (fieldName) => {
    setPoppedUpCell(fieldName);
  };

  const hideFieldPopupClick = (fieldName) => {
    setPoppedUpCell(null);
  };

  // For a checkbox that contains a true or false, just set the value to the opposite of itself
  const HandleRowPropertyToggled = (event) => {
    currentObject[event.target.name] = !currentObject[event.target.name];
    if (handleStandardItemChange) {
      handleStandardItemChange(currentObject);
    }
  }

  const onFieldChange = (event) => {
    // Gets the current vendor info data and updates it with the event's name and value:
    if (event.target.type == "number") {
      if (event.target.value.length == 0) {
        currentObject[event.target.name] = Number(0);
      }
      else {
        currentObject[event.target.name] = Number(event.target.value);
      }
    }
    else {
      currentObject[event.target.name] = event.target.value;
    }
    if (markItemAsEdited) {
      markItemAsEdited(currentObject);
    }
  }

  const HandleTextBoxDisselect = (event) => {
    if (event.target.value != rowObject[event.target.name]) {
      onFieldChange(event);
      if (customOnChangeFields && customOnChangeFields.length > 0) {
        let onChangeFieldCustomMethod = customOnChangeFields.find(obj => {
          return obj.targetFieldName === event.target.name
        });
        if (onChangeFieldCustomMethod) {
          var newRowObject = onChangeFieldCustomMethod.method(currentObject);
          currentObject = newRowObject;
        }
        if (handleStandardItemChange) {
          handleStandardItemChange(currentObject);
        }
      }
      else if (itemSaveOnTextboxLeave) {
        if (handleStandardItemChange) {
          handleStandardItemChange(currentObject);
        }
      }
      setRowObject(Object.assign({}, currentObject));
    }
  }

  const HandleLabelPageUrlSelection = (fieldName) => {
    if (customUrlFields && customUrlFields.length > 0) {
      let customUrlField = customUrlFields.find(obj => {
        return obj.targetFieldName === fieldName
      });
      if (customUrlField) {
        var customURL = customUrlField.method(currentObject);
        if (customURL != null && !customURL.includes("notfound")) {
          window.open(customURL);
        }
      }
    }
  };


  /////////////////////////////////////////////////////////
  // All of the editable field types:
  const EditableNumberField = ({ fieldName, fieldTitle }) => {
    return (
      <TextField
        onChange={onFieldChange}
        label={fieldTitle}
        type="number"
        fullWidth
        onBlur={HandleTextBoxDisselect}
        name={fieldName}
        defaultValue={currentObject[fieldName]}
        InputLabelProps={{
          shrink: true,
        }}
      />
    )
  };
  const EditableMoneyField = ({ fieldName, fieldTitle }) => {
    return (
      <CurrencyTextField
        onChange={onFieldChange}
        label={fieldTitle}
        name={fieldName}
        onBlur={HandleTextBoxDisselect}
        variant="standard"
        defaultValue={currentObject[fieldName]}
        currencySymbol="$"
        //minimumValue="0"
        outputFormat="number"
        decimalCharacter="."
        digitGroupSeparator=","
      />
    )
  };
  const EditableDateField = ({ fieldName, fieldTitle }) => {
    return (
      <TextField
        onChange={onFieldChange}
        onClick={e=>e?.stopPropagation()}
        label={fieldTitle}
        type="date"
        fullWidth
        name={fieldName}
        onBlur={HandleTextBoxDisselect}
        defaultValue={txtBoxDateFormatter(currentObject[fieldName])}
        InputLabelProps={{
          shrink: true,
        }}
      />
    )
  };
  const EditableDateFieldPopupSmall = ({ fieldName, fieldTitle }) => {
    return (
      <>
        <Typography button component={'span'} variant="caption" name={fieldName} onClick={() => {
          showFieldPopupClick(fieldName)
        }}>
          {dateFormatter(currentObject[fieldName])}
        </Typography>
        <Dialog
          open={cellPoppedUp == fieldName ? true : false}
          TransitionComponent={Transition}
          fullWidth={"sm"}
          maxWidth={"sm"}
          keepMounted
          onClose={hideFieldPopupClick}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{fieldTitle}</DialogTitle>
          <DialogContent>
            <TextField
              onBlur={(event) => {
                hideFieldPopupClick();
                HandleTextBoxDisselect(event);
              }}
              label={fieldTitle}
              type="date"
              fullWidth
              name={fieldName}
              my={3}
              defaultValue={txtBoxDateFormatter(currentObject[fieldName])}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </DialogContent>
        </Dialog>

      </>
    )
  };
  const EditableNumberFourDecimalLimitField = ({ fieldName, fieldTitle }) => {
    return (
      <TextField
        onChange={onFieldChange}
        label={fieldTitle}
        type="number"
        fullWidth
        name={fieldName}
        onBlur={HandleTextBoxDisselect}
        defaultValue={currentObject[fieldName].toFixed(4)}
        InputLabelProps={{
          shrink: true,
        }}
      />
    )
  };
  const EditableTextField = ({ fieldName, fieldTitle }) => {
    return (
      <TextField
        onChange={onFieldChange}
        name={fieldName}
        label={fieldTitle}
        type="text"
        fullWidth
        onBlur={HandleTextBoxDisselect}
        defaultValue={currentObject[fieldName]}
        InputLabelProps={{
          shrink: true,
        }}
      />
    )
  };
  const TableLblField = ({ fieldName, fieldTitle }) => {
    return (
      <Typography component={'span'} name={fieldName} onClick={() => HandleLabelPageUrlSelection(fieldName)}>
        {currentObject[fieldName]}
      </Typography>
    )
  };
  const ButtonLinkField = ({ fieldName, fieldTitle }) => {
    return (
      <div>
        {(currentObject[fieldName] && currentObject[fieldName].indexOf("notfound") == -1) &&
          <Button target="_blank" href={currentObject[fieldName]} color="primary">
            <InsertDriveFileIcon />
          </Button>
        }
      </div>
    )
  };


  const PreviewButtonField = () => {
    return (
      <div>
        <Tooltip title="Preview">
          <Button size="small" onClick={() => HandlePreviewClicked()} color="primary">
            <DescriptionIcon />
          </Button>
        </Tooltip>
      </div>
    )
  };

  const BillPdfButtonField = () => {

    if(currentObject?.type?.toUpperCase() == "LATEFEE")
    return (
      <Tooltip title="Late fee generated">
      <Button size="small" color="primary">
        <MoneyOffIcon />
      </Button>
    </Tooltip>
    )

    if(currentObject?.url?.toLowerCase()?.includes("notfound"))
    return (
      <Tooltip title="Generate PDF">
      <Button size="small" onClick={() => HandleGenerateClicked()} color="danger">
         <p>Print Error</p>
        <CachedIcon style={{color: "#ff0000"}} />
      </Button>
    </Tooltip>
    )
    else
      return (
        <div>
          <Tooltip title="Preview">
            <Button size="small" onClick={() => HandlePreviewClicked()} color="primary">
              <DescriptionIcon />
            </Button>
          </Tooltip>
        </div>
      )
  };

  const ReprintPdfButtonField = () => {

      return (
        <Tooltip title="Generate PDF">
          <Button size="small" onClick={() => HandleGenerateClicked()} color="danger">
            <p>Reprint</p>
            <PictureAsPdfIcon />
          </Button>
        </Tooltip>
      )
  };


  const EditButtonField = () => {
    return (
      <div>
        <Tooltip title="Edit">
          <Button size="small" onClick={(e) => HandlePreviewClicked(e)} color="primary">
            <EditIcon />
          </Button>
        </Tooltip>
      </div>
    )
  };

  const WorkOrderStatusField = ({ fieldName, fieldTitle }) => {

    if (currentObject[fieldName] == "Closed") {
      return (<span className="m-1 badge badge-primary">{currentObject[fieldName]}</span>)
    }
    else if (currentObject[fieldName] == "Open") {
      return (<span className="m-1 badge badge-success">{currentObject[fieldName]}</span>)
    }
    else if (currentObject[fieldName] == "New") {
      return (<span className="m-1 badge badge-info">{currentObject[fieldName]}</span>)
    }
    else {
      return (<span className="m-1 badge badge-secondary">{currentObject[fieldName]}</span>)
    }
  };

  const TableLblDateFieldDueDate = ({ fieldName, fieldTitle }) => {
    let date = new Date(currentObject[fieldName]);
    let now = Date.now();
    var offset = new Date().getTimezoneOffset();
    let str = moment(date).add(offset, "minutes").format("L");
    if (now > date && (currentObject['description'] != "Closed")) {
      return (<span className="m-1 badge badge-danger">{`SLA Breached: ${dateFormatter(currentObject[fieldName])}`}</span>)
    }
    else {
      return (
        <Typography component={'span'} name={fieldName} onClick={() => HandleLabelPageUrlSelection(fieldName)}>
          <span className="m-1 badge badge-black">{`${dateFormatter(currentObject[fieldName])}`}</span>
        </Typography>
      )
    }

  };
  const TablePhoneLblFieldSmall = ({ fieldName, fieldTitle }) => {
    return (
      <Typography component={'span'} variant="caption" name={fieldName} onClick={() => HandleLabelPageUrlSelection(fieldName)}>
        {formatPhoneNumber(currentObject[fieldName])}
      </Typography>
    )
  };
  const TablePhoneLblField = ({ fieldName, fieldTitle }) => {
    return (
      <Typography component={'span'} name={fieldName} onClick={() => HandleLabelPageUrlSelection(fieldName)}>
        {formatPhoneNumber(currentObject[fieldName])}
      </Typography>
    )
  };

  const TableDateLblFieldSmall = ({ fieldName, fieldTitle }) => {
    return (
      <Typography component={'span'} variant="caption" name={fieldName} onClick={() => HandleLabelPageUrlSelection(fieldName)}>
        {dateFormatter(currentObject[fieldName])}
      </Typography>
    )
  };
  const TableLblFieldSmall = ({ fieldName, fieldTitle }) => {
    return (

      <Link variant="body2" component={'span'} name={fieldName} onClick={() => HandleLabelPageUrlSelection(fieldName)}>
        {currentObject[fieldName]}
      </Link>
    )
  };
  const TableLblFieldBold = ({ fieldName, fieldTitle }) => {
    return (
      <Typography component={'span'} style={{ fontWeight: "bold" }} name={fieldName} onClick={() => HandleLabelPageUrlSelection(fieldName)}>
        {currentObject[fieldName]}
      </Typography>
    )
  };
  const TableLblFieldFourDecimalRounded = ({ fieldName, fieldTitle }) => {
    let value = "";
    let amount = currentObject[fieldName];
    try {
      let decimalCount = 4;
      const negativeSign = amount < 0 ? "-" : "";

      let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      let j = (i.length > 3) ? i.length % 3 : 0;

      value = negativeSign + (j ? i.substr(0, j) + ',' : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + ',') + (decimalCount ? '.' + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
      console.log(e)
    }
    return (
      <Typography component={'span'} name={fieldName} onClick={() => HandleLabelPageUrlSelection(fieldName)}>
        {value}
      </Typography>
    )
  };
  const TableLblMoneyFourDecimal = ({ fieldName, fieldTitle }) => {
    return (
      <Typography component={'span'} name={fieldName} onClick={() => HandleLabelPageUrlSelection(fieldName)}>
        {formatMoney(currentObject[fieldName], 4)}
      </Typography>
    )
  };
  const TableLblDateField = ({ fieldName, fieldTitle }) => {
    return (
      <Typography component={'span'} name={fieldName} onClick={() => HandleLabelPageUrlSelection(fieldName)}>
        {dateFormatter(currentObject[fieldName])}
      </Typography>
    )
  };
  const TableLblDateNoTimeField = ({ fieldName, fieldTitle }) => {
    return (
      <Typography component={'span'} name={fieldName} onClick={() => HandleLabelPageUrlSelection(fieldName)}>
        {dateFormatterNoTime(currentObject[fieldName])}
      </Typography>
    )
  };
  const TableLblDateTimeField = ({ fieldName, fieldTitle }) => {
    return (
      <Typography component={'span'} name={fieldName} onClick={() => HandleLabelPageUrlSelection(fieldName)}>
        {dateTime(currentObject[fieldName])}
      </Typography>
    )
  };
  const TableLblMoneyField = ({ fieldName, fieldTitle }) => {
    return (
      <Typography component={'span'} name={fieldName} onClick={() => HandleLabelPageUrlSelection(fieldName)}>
        {currentObject[fieldName] != "N/A" ?
          formatMoney(currentObject[fieldName])
          :
          currentObject[fieldName]
        }
      </Typography>
    )
  };

  const ButtonField = ({ fieldName, fieldTitle }) => {
    return (
      <Typography component={'span'} name={fieldName} onClick={() => HandleLabelPageUrlSelection(fieldName)}>
        {formatMoney(currentObject[fieldName])}
      </Typography>
    )
  };

  const CheckBoxField = ({ fieldName, fieldTitle }) => {
    return (
      <Checkbox
        name={fieldName}
        checked={Boolean(currentObject[fieldName])}
        onClick={(event) => HandleRowPropertyToggled(event)}
        inputProps={{ "aria-labelledby": fieldTitle }}
      />
    )
  };

  const SwitchField = ({ fieldName, fieldTitle }) => {
    return (
      <Switch
        name={fieldName}
        checked={Boolean(currentObject[fieldName])}
        onClick={(event) => {
         event.stopPropagation();
          HandleRowPropertyToggled(event);
        }}
        inputProps={{ "aria-labelledby": fieldTitle }}
      />
    )
  };
  const ChipField = ({ fieldName, fieldTitle }) => {
    return (
      <Chip
        name={fieldName}
        label={currentObject[fieldName]}
        color="primary"
        inputProps={{ "aria-labelledby": fieldTitle }}
      />
    )
  };
  const Icon = ({ fieldName, fieldTitle, icon }) => {
    return (
      <div>
        {currentObject[fieldName] == 1 &&
          <CheckCircleRoundedIcon
            style={{ fill: "green" }}
          />}
        {currentObject[fieldName] == 0 &&
          <ErrorOutlineIcon
            style={{ fill: "red" }}
          />}
        {currentObject[fieldName] == undefined &&
          <div></div>
        }
      </div>
    )
  };
  const DropDownField = ({ fieldName, fieldTitle, options, optionDisplayProperty, optionOnSelectValue, onChangeMethod }) => {
    return (
      <FormControl fullWidth my={2} spacing={0}>
        <InputLabel id={"select" + fieldName + passedGlobalIndex + "DropdownField_Label"}>{fieldTitle}</InputLabel>
        <Select
          labelId={"select" + fieldName + passedGlobalIndex + "DropdownField_Label"}
          id={"select" + fieldName + passedGlobalIndex + "DropdownField"}
          value={currentObject[fieldName]}
          onChange={(event) => {
            onFieldChange(event);
            if (handleStandardItemChange) {
              handleStandardItemChange(currentObject);
            }
            if(onChangeMethod)
              onChangeMethod(currentObject)
          }}
          name={fieldName}
          fullWidth
        >
          {options.length > 0 ?
            [
              options.map((option, key) => {
                return (
                  <MenuItem value={option[optionOnSelectValue]}>{option[optionDisplayProperty]}</MenuItem>
                )
              })
            ]
            :
            [
              <MenuItem value={-1}>No {fieldTitle} options Found</MenuItem>
            ]
          }
        </Select>
      </FormControl>
    )
  };
  /////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////
  // Field Selection method:
  const getDesiredField = (field, fieldTitle, fieldType, ddFieldItem, url, model, HandleRowSelected) => {
    switch (fieldType) {
      case "text":
        return (<EditableTextField fieldName={field} fieldTitle={fieldTitle} />);
      case "number":
        return (<EditableNumberField fieldName={field} fieldTitle={fieldTitle} />);
      case "money":
        return (<EditableMoneyField fieldName={field} fieldTitle={fieldTitle} />);
      case "numberFourDecimal":
        return (<EditableNumberFourDecimalLimitField fieldName={field} fieldTitle={fieldTitle} />);
      case "checkBox":
        return (<CheckBoxField fieldName={field} fieldTitle={fieldTitle} />);
      case "switch":
        return (<SwitchField fieldName={field} fieldTitle={fieldTitle} />);
      case "dateLabel":
        return (<TableLblDateField fieldName={field} fieldTitle={fieldTitle} />);
      case "dateLabelNoTime":
        return (<TableLblDateNoTimeField fieldName={field} fieldTitle={fieldTitle} />);
      case "dateField":
        return (<EditableDateField fieldName={field} fieldTitle={fieldTitle} />);

      case "dateTimeLabel":
        return (<TableLblDateTimeField fieldName={field} fieldTitle={fieldTitle} />);
      case "label":
        return (<TableLblField fieldName={field} fieldTitle={fieldTitle} />);
      case "phoneLabel":
        return (<TablePhoneLblField fieldName={field} fieldTitle={fieldTitle} />);
      case "phoneLabelSmall":
        return (<TablePhoneLblFieldSmall fieldName={field} fieldTitle={fieldTitle} />);
      case "labelFourDecimalRounded":
        return (<TableLblFieldFourDecimalRounded fieldName={field} fieldTitle={fieldTitle} />);
      case "labelSmallPageLink":
        return (<TableLblField fieldName={field} fieldTitle={fieldTitle} />);
      case "labelSmallPopupModel":
        return (<TableLblField fieldName={field} fieldTitle={fieldTitle} />);
      case "labelMoneyFourDecimal":
        return (<TableLblMoneyFourDecimal fieldName={field} fieldTitle={fieldTitle} />);
      case "labelSmall":
        return (<TableLblFieldSmall fieldName={field} fieldTitle={fieldTitle} />);
      case "dateLabelSmall":
        return (<TableDateLblFieldSmall fieldName={field} fieldTitle={fieldTitle} />);
      case "dateLabelSmallPopup":
        return (<EditableDateFieldPopupSmall fieldName={field} fieldTitle={fieldTitle} />);
      case "labelBold":
        return (<TableLblFieldBold fieldName={field} fieldTitle={fieldTitle} />);
      case "moneyLabel":
        return (<TableLblMoneyField fieldName={field} fieldTitle={fieldTitle} />);
      case "dropDown":
        return (<DropDownField fieldName={field} fieldTitle={fieldTitle} options={ddFieldItem.values} optionDisplayProperty={ddFieldItem.displayValueName} optionOnSelectValue={ddFieldItem.selectValueName} onChangeMethod={ddFieldItem.onChangeMethod} />);
      case "chip":
        return (<TableLblMoneyField fieldName={field} fieldTitle={fieldTitle} />);
      case "icon":
        return (<Icon fieldName={field} fieldTitle={fieldTitle} />);
      case "buttonLink":
        return (<ButtonLinkField fieldName={field} fieldTitle={fieldTitle} />);
      case "previewBtn":
        return (<PreviewButtonField fieldName={field} fieldTitle={fieldTitle} />);
      case "billPdfBtn":
        return (<BillPdfButtonField fieldName={field} fieldTitle={fieldTitle} />);
      case "reprintPdfBtn":
        return (<ReprintPdfButtonField fieldName={field} fieldTitle={fieldTitle} />);
      case "editBtn":
        return (<EditButtonField fieldName={field} fieldTitle={fieldTitle} />);
      case "workOrderStatus":
        return (<WorkOrderStatusField fieldName={field} fieldTitle={fieldTitle} />);
      case "dateLabelDueDate":
        return (<TableLblDateFieldDueDate fieldName={field} fieldTitle={fieldTitle} />);

      default:
        return (<></>)
    }
  }

  // The row object being returned:
  return (
    <TableRow hover role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={currentObject.id} selected={isItemSelected} style={conditionalStyle}>
      {!readOnly && <TableCell padding="checkbox">
        <Checkbox
          onClick={(event) => HandleRowSelected(event)}
          checked={isItemSelected}
          inputProps={{ "aria-labelledby": currentObject.id }}
        />
      </TableCell>}
      {showNumber &&
        <TableCell style={{ paddingLeft: "0px" }}>
          #{passedGlobalIndex + 1}
        </TableCell>}
      {
        // Maps each of the objects properties to a respective field.
        // Takes that index and uses it to find what title type and field name is needed for that component
        fieldNames.map((field, index) => {
          //Accessing object value: myObject[key];
          if (!Array.isArray(field)) {
            var fieldTitle = fieldTitles[index];
            const fieldType = fieldTypes[index];
            var ddFieldItem = null;
            if (fieldType == "dropDown") { // If its a dropdown we need to map the propper options array to the field cell:
              ddFieldItem = dropDownFields.find(obj => {
                return obj.targetFieldName === field
              });
            }
            if (hideRowFieldTitles) {
              fieldTitle = null;
            }
            return (<TableCell align="left" key={fieldType + "Cell" + index} onClick={() => handleRowClick(currentObject)} style={conditionalStyle}>
              <Grid container direction="column" justify="left" alignItems="stretch" spacing={0}>
                <Grid item>
                  {getDesiredField(field, fieldTitle, fieldType, ddFieldItem)}
                </Grid>
              </Grid>
            </TableCell>);
          }
          else {
            var ddFieldItem = null;
            var cellComponents = [];
            field.map((childField, childIndex) => {
              var title = fieldTitles[index];
              if (Array.isArray(fieldTitles[index][childIndex])) {
                title = fieldTitles[index][childIndex];
              }
              if (hideRowFieldTitles) {
                title = null;
              }
              cellComponents.push(getDesiredField(childField, title, fieldTypes[index][childIndex], ddFieldItem))
            })
            return (<TableCell className={classes.tableCell} align="left" key={fieldTypes[index] + "Cell" + index} onClick={() => handleRowClick(currentObject)} style={conditionalStyle}>
              <Grid container display="flex" direction="column" justify="left" spacing={0}>
                {cellComponents.map((component, key) => {
                  return (
                    <Grid item display="flex" >
                      {component}
                    </Grid>
                  )
                })}
              </Grid>
            </TableCell>);
          }
        })
      }
    </TableRow>
  );
}