import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { setSnackbar } from "../api/snackbar";
const useStyles = makeStyles((theme) => ({
  root: {},
}));

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} value={props.value} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" style={{ color: "white", fontSize: "14px" }}>{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const CustomizedSnackbars = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const snackbarOpen = useSelector((state) => state.snackbar.snackbarOpen);
  const snackbarType = useSelector((state) => state.snackbar.snackbarType);
  const completedItems = useSelector((state) => state.snackbar.completedItems);
  const totalItems = useSelector((state) => state.snackbar.totalItems);
  const snackbarMessage = useSelector(
    (state) => state.snackbar.snackbarMessage
  );
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setSnackbar(false, snackbarType, snackbarMessage));
  };
  var progress = 0;
  if (snackbarType == "loading") {
    progress = (completedItems / totalItems) * 100
  }
  return (
    <div className={classes.root}>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={20000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        {snackbarType == "loading" ?
          <Alert
            elevation={6}
            variant="filled"
            onClose={handleClose}
            severity={"warning"}
            style={{ color: "white", fontSize: "14px" }}
          >
            {`${snackbarMessage}  ${completedItems}/${totalItems}`}
            <LinearProgressWithLabel
              value={progress}
            />
          </Alert>
          :
          <Alert
            elevation={6}
            variant="filled"
            onClose={handleClose}
            severity={snackbarType}
            style={{ color: "white", fontSize: "14px" }}
          >
            {snackbarType == "warning" && <CircularProgress size={20} />}
            {snackbarMessage}
          </Alert>
        }
      </Snackbar>
    </div>
  );
};

export default CustomizedSnackbars;
