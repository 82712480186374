import {
  Button as MuiButton,
  Card,
  CardContent,
  CircularProgress as MuiCircularProgress,
  DialogActions,
  Divider as MuiDivider,
  Grid,
  LinearProgress as MuiLinearProgress,
} from "@material-ui/core";
import { useState } from "react";
import _ from "lodash"
//import { login, logout } from '../../modules/user/api/actions';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Slide from "@material-ui/core/Slide";
import { withStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
import { setSeconds } from "date-fns";
// Redux Components
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { EditableTable , newConditionalOnSelectButton} from "../../../../components/EditableTable";
import StatusTag from "../../../apl/components/StatusTag";
import {
  createNewMeterReading,
  resetNewMeterRead,
  deleteMeterReading,
  updateMeterReading,
  lookupMeterHistory
} from "../../../meter/api/actions";
import MeterReadForm from "../../../meter/components/meterReadForm";
import { setSnackbar } from "../../../snackbar/api/snackbar";
import {
  hydrateAccount,
  refreshLookedUpAccountMeters,
} from "../../api/actions";
import styles from "../styling/accountManager";

const LinearProgress = MuiLinearProgress;
const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledNavMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const SelectedAccountPropertyInformationCard = (props) => {
  const useStyles = styles;
  const classes = useStyles();
  const history = useHistory();
  const [maxReadId, setMaxReadId] = useState(0);

  const dispatch = useDispatch();
  const [NewReadFormFormOpen, setNewReadFormOpen] = React.useState(false);
  const [BilledCofirmOpen, setBilledCofirmOpen] = React.useState(false);
  const [FirstReadCofirmOpen, setFirstReadCofirmOpen] = React.useState(false);
  const [SelectedMeterReading, setSelectedMeterReading] = React.useState({});

  const [toShowAll, setToShowAll] = React.useState({ items: JSON.parse(JSON.stringify(props.account.meterReads)), toUpdate: [], toDelete: [] });

  const handleNewReadFormClickOpen = () => {
    setNewReadFormOpen(true);
  };

  const handleNewReadFormClose = () => {
    setNewReadFormOpen(false);
  };

  const handleSaveClick = () => {
    dispatch(
      setSnackbar(true, "warning", "Submitting New Account Meter Reading...")
    );
    // Handle the account object updating
    //const data = props.account.data;
    props.createNewMeterReading(props.meters.newMeterRead).then(() => {
      dispatch(
        setSnackbar(
          true,
          "success",
          "Account Meter Reading Creation Successful"
        )
      );
      dispatch(hydrateAccount(props.account.data.account.uuid, false));
      dispatch(resetNewMeterRead());
    }); // Sends account data to db

    setNewReadFormOpen(false);
  };

  useEffect(() => {

    var newToShowAll = { items: JSON.parse(JSON.stringify(props.account.meterReads)), toUpdate: [], toDelete: [] };
    if (props.selectedMeter && props.selectedMeter != null) {
      for (var y = 0; y < newToShowAll.items.length;) {
        if (props.selectedMeter != newToShowAll.items[y].meterId) {
          newToShowAll.items.splice(y, 1);
        }
        else {
          y++;
        }
      }
    } 
    for (var x = 0; x < newToShowAll.items.length; x++) {
      if (x + 1 < newToShowAll.items.length) {
        newToShowAll.items[x].usage = newToShowAll.items[x].readAmount - newToShowAll.items[x + 1].readAmount;
      
      } else {
        newToShowAll.items[x].usage = 0;
      }
    }
    setToShowAll(newToShowAll);

    let max = _.maxBy(newToShowAll.items, item => { return item.id; })
    setMaxReadId(max);
  }, [props.isOpen]);

  const deleteSelectedMeterReads = (meterReads) => {
    let error = false;
    meterReads.map((meterReadUuid, index) => {
      props.deleteMeterReading(meterReadUuid, false).then(() => {
          dispatch(setSnackbar(true, "success", "Meter Reads Deleted"));
          
      });
      let newArray = props.account.meterReads.filter(item => {
          return !meterReads.includes(item.uuid)
      });
      
      console.log(newArray);

      //props.callbackModal()
    });
  }

  const onMeterReadSelected = (item) => { };
  const fieldTitles = [
    "#",
    "Date",
    "Read Type",
    "Read Amount",
    "Usage",
    "Billed",
    "First Read"
  ];
  const fieldTypes = ["label", "dateLabelNoTime", "label", "label", "label", "label", "label"];
  const fieldNames = [
    "id",
    "readDate",
    "meterReadType",
    "readAmount",
    "usage",
    "isBilled",
    "firstRead"
  ];

  const MeterReadRowStatusStyling = (meterRead) => {
    if (meterRead.billed) {
      meterRead.isBilled = "YES";
    } else {
      meterRead.isBilled = "NO";
    }

    if (meterRead.firstRead == 1) {
      meterRead.firstRead = "YES";
    }if(meterRead.firstRead == 0) {
      meterRead.firstRead = "NO";
    }

    meterRead.meterReadType = (
      <StatusTag
        type={"meterReadingType"}
        isLoading={props.apl.genericTypes.meterReadingType.isLoading}
        typeId={meterRead.meterReadTypeId}
        showTitle={false}
        condensed={true}
      />
    );
    return {}; // Returns an empty style as to not effect each row
  };

  const IsAbleToDeleteMeterRead = (meterRead) => {
    if (meterRead.billed) {
      if(meterRead.id != maxReadId.id)
        return false;
      else
        return true;
    }
    return true;
  }
  const createNewMeterRead = () => {
    //props.resetSelectedAccountObject();
    //navTo("/billingwizard");
    handleNewReadFormClickOpen();
  };

 const setOpenReadAsBilled = async () => {
      
      if (SelectedMeterReading) {
        dispatch(setSnackbar(true, "warning", "Meter Read(s) Updating"));

        
          let readingUpdate = {}
          readingUpdate.uuid = SelectedMeterReading.uuid;
          readingUpdate.billed = true;
          
         await props.updateMeterReading(readingUpdate, true)
         await props.lookupMeterHistory(props.selectedMeter, true)
       if (!props.meters.error || props.meters.error == '') {
        dispatch(setSnackbar(true, "success", "Meter Read(s) Updated"));
        setSelectedMeterReading(SelectedMeterReading.billed = true)
       }else {

        dispatch(setSnackbar(true, "error", "Failed to update meter reading(s)"));

       }
        setBilledCofirmOpen(!BilledCofirmOpen);


    }
 }
 const handleSetBilledCofirmOpen = (readings) =>{
   setSelectedMeterReading(readings[0])
   setBilledCofirmOpen(!BilledCofirmOpen);
 } 
 const handleSetFirstReadCofirmOpen = (readings) =>{
  setSelectedMeterReading(readings[0])
  setFirstReadCofirmOpen(!FirstReadCofirmOpen);
} 

      const setReadAsFirstRead = async () => {
      
        if (SelectedMeterReading) {
          dispatch(setSnackbar(true, "warning", "Meter Read(s) Updating"));
  
          
            let readingUpdate = {}
            readingUpdate.uuid = SelectedMeterReading.uuid;
            readingUpdate.firstRead = true;
            
         await props.updateMeterReading(readingUpdate, true)
         await props.lookupMeterHistory(props.selectedMeter, true)

         if (!props.meters.error || props.meters.error == '') {
          dispatch(setSnackbar(true, "success", "Meter Read(s) Updated"));
          setSelectedMeterReading(SelectedMeterReading.firstRead = true)

         }else {
  
          dispatch(setSnackbar(true, "error", "Failed to update meter reading(s)"));
  
         }
         setFirstReadCofirmOpen(!FirstReadCofirmOpen);

      }
      
    
 };

  const ConditionalOnSelectButtons = [
    newConditionalOnSelectButton(
      "Set as Billed",
      () => {
        return (true);
      },
      handleSetBilledCofirmOpen
    ),
    newConditionalOnSelectButton(
      "Set as First Read",
      () => {
        return (true);
      },
      handleSetFirstReadCofirmOpen
    )
  ];

  return (
   
    <Card mb={6}>
      <CardContent xs={12} sm={12} md={12} lg={12}>
        <EditableTable
          readOnly={false}
          canAddItems={true}
          canSave={false}
          verifyDelete={true}
          newItemCreationMethod={createNewMeterRead}
          manualItemDeletionMethod={deleteSelectedMeterReads}
          isLoading={props.account.meterLookupIsLoading}
          // Functional methods of table
          handleRowClickParentMethod={onMeterReadSelected}
          conditionalStylingMethod={MeterReadRowStatusStyling}
          // Properties of table
          tableTitle={"Meter History"}
          objectArray={toShowAll}
          objectError={props.account.meterLookupError}
          fieldTitles={fieldTitles}
          fieldTypes={fieldTypes}
          defaultItemCount={5}
          fieldNames={fieldNames}
          customOnDeleteValidationMethod={IsAbleToDeleteMeterRead}
          conditionalOnSelectButtons={ConditionalOnSelectButtons}
        />
        <Dialog
          open={NewReadFormFormOpen}
          TransitionComponent={Transition}
          fullWidth={"lg"}
          maxWidth={"lg"}
          keepMounted
          onClose={handleNewReadFormClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>New Meter Reading</DialogTitle>
          <DialogContent>
            <MeterReadForm handleSaveClick={handleSaveClick} />
            <Grid item lg={6} md={12} sm={12}>
              {/* <Button
                mr={2}
                mb={2}
                variant="contained"
                color="secondary"
                onClick={handleSaveClick}
              >
                Submit Meter Reading
              </Button> */}
            </Grid>
          </DialogContent>
        </Dialog>
        <Dialog
          open={BilledCofirmOpen}
          TransitionComponent={Transition}
          fullWidth={"lg"}
          maxWidth={"lg"}
          keepMounted
          onClose={handleSetBilledCofirmOpen}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>Set As Billed</DialogTitle>
          <DialogContent>
              Are you sure you want to set read # {SelectedMeterReading?.id || "N/A"} as billed?
          </DialogContent>
          <DialogActions>
              <Button onClick={setOpenReadAsBilled}>Yes</Button>
              <Button onClick={handleSetBilledCofirmOpen}>Cancel</Button>
            </DialogActions>
        </Dialog>
        <Dialog
          open={FirstReadCofirmOpen}
          TransitionComponent={Transition}
          fullWidth={"lg"}
          maxWidth={"lg"}
          keepMounted
          onClose={handleSetFirstReadCofirmOpen}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>Set As First Read</DialogTitle>
          <DialogContent>
              Are you sure you want to set read # {SelectedMeterReading?.id || "N/A"} as first read?
          </DialogContent>
          <DialogActions>
              <Button onClick={setReadAsFirstRead}>Yes</Button>
              <Button onClick={handleSetFirstReadCofirmOpen}>Cancel</Button>
            </DialogActions>
        </Dialog>
      </CardContent>
    </Card>
  );
};

// Component Properties
SelectedAccountPropertyInformationCard.propTypes = {
  // Store objects:
  account: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  meters: PropTypes.object.isRequired,
  deleteMeterReading: PropTypes.func.isRequired,
  createNewMeterReading: PropTypes.func.isRequired,
  refreshLookedUpAccountMeters: PropTypes.func.isRequired,
  resetNewMeterRead: PropTypes.func.isRequired,
  updateMeterReading: PropTypes.func.isRequired,
  lookupMeterHistory: PropTypes.func.isRequired,
};

// Component State
function SelectedAccountPropertyInformationCardState(state) {
  return {
    account: state.account,
    apl: state.apl,
    meters: state.meters,
   };
} 

export default connect(SelectedAccountPropertyInformationCardState, {
  createNewMeterReading,
  resetNewMeterRead,
  deleteMeterReading,
  refreshLookedUpAccountMeters,
  updateMeterReading,
  lookupMeterHistory
})(SelectedAccountPropertyInformationCard);
