import {
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  TextField as MuiTextField,
} from "@material-ui/core";
//Material UI Components and Functions
import { makeStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
// Redux Components
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import {
  handleBillingProcessSubmission,
  updateBillingWizardBillInProgress,
} from "../../../../modules/billing/api/actions";
/*import {
  Breadcrumbs as MuiBreadcrumbs, Button as MuiButton, Card as MuiCard, CardContent, Divider as MuiDivider, FormControl as MuiFormControl, Grid,
  Link, TextField as MuiTextField, Typography, InputLabel, Select, Button, MenuItem, CircularProgress
} from "@material-ui/core";*/
// Custom Componenets
import TrialBillingLineItemsManager from "../../../../modules/billing/components/TrialBillingItemsChart";

const CenteredContent = styled.div`
  text-align: center;
`;

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const FormControl = styled(MuiFormControl)(spacing);
const TextField = styled(MuiTextField)(spacing);

// Custom Styles for property selection drop down

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  selectedPropertyLocationLabel: {
    fontSize: 50,
    backgroundColor: "#fffffff",
    borderColor: "#fffffff",
  },
  loadingIcon: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

const TrialBillingForm = (props) => {
  const classes = useStyles();
  return (
    <Card mb={6} elevation={3}>
      <CardContent>
        {/* Main Content (Just imports the properties meter data manager) */}
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12}>
            <TrialBillingLineItemsManager billingType={props.billingType} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

// Component Properties
TrialBillingForm.propTypes = {
  // Store objects:
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  billing: PropTypes.object.isRequired,
  // Store object functions:
  updateBillingWizardBillInProgress: PropTypes.func.isRequired,

  // Store object functions:
  handleBillingProcessSubmission: PropTypes.func.isRequired,
};

// Component State
function TrialBillingFormState(state) {
  return {
    user: state.user,
    apl: state.apl,
    billing: state.billing,
  };
}
export default connect(TrialBillingFormState, {
  updateBillingWizardBillInProgress,
  handleBillingProcessSubmission,
})(TrialBillingForm);
