import React, { useEffect }from "react";
import styled, { withTheme } from "styled-components/macro";
import { NavLink } from "react-router-dom";

import Helmet from "react-helmet";

//import "../../vendor/roundedBarCharts";
import { Bar } from "react-chartjs-2";

import { red, green, blue } from "@material-ui/core/colors";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { spacing } from "@material-ui/system";

export default makeStyles((theme) => ({
    title: {
    flexGrow: 1,
    },
    percentDif: {
        textAlign: 'right',
        color: 'green'
    },
    backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    },
    loadingIcon: {
    position: 'absolute', left: '50%', top: '50%',
    transform: 'translate(-50%, -50%)'
    },
    StatsIcon: {
    width: "32px",
    height: "32px",
    color: theme.palette.secondary.main,
    position: "absolute",
    right: "16px",
    top: "32px",
    },
    rightSideHeader:{
    display:"flex",
    alignItems: 'flex-end',
    flexDirection: 'row', 
    justifyContent: 'flex-end'
    },
    rightSideHeaderSubMenuToolBar:{
    display:"flex",
    alignItems: 'flex-end',
    flexDirection: 'row', 
    justifyContent: 'flex-end',
    margin:"0px",
    padding:"0px",
    },
    SubMenuToolBarIcon:{
    width: "25px",
    height: "25px",
    paddingBotom: "5px"
    },
    orange: {
    //color: theme.palette.getContrastText(deepOrange[500]),
    },
    UserBoxText: {
    textAlign: "center",
    },
    UserProfileDetailsButton: {
    padding: "5px",
    },
    AvatarText:{
    marginTop: "20%",
    height: "128px",
    width: "128px",
    textAlign: "center",
    alignSelf: 'center'
    },
    AvatarIcon:{
        display: "inline-block",
        height: "128px",
        width: "128px",
        backgroundColor: "gray",
        borderColor: theme.palette.primary.main,
        borderStyle: "solid",
    },
    ChartWrapper:{
    height: "200px",
    maxHeight: "200px",
    minHeight: "200px",
    },
    AccountInfoGrid:{
    height: "350px",
    maxHeight: "350px",
    minHeight: "350px",
    overflowY: "auto", 
    }
}));