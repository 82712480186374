//React Components and Hooks
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
//Material UI Components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import HomeWork from "@material-ui/icons/HomeWork";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import LocalShippingOutlinedIcon from "@material-ui/icons/LocalShippingOutlined";
import PermMediaOutlinedIcon from "@material-ui/icons/PermMediaOutlined";
import PowerOffOutlinedIcon from "@material-ui/icons/PowerOffOutlined";
import ReceiptIcon from "@material-ui/icons/Receipt";
import SettingsIcon from "@material-ui/icons/Settings";
// Context
// Redux Components
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { logout } from "../../modules/user/api/actions";

function ListItemObject(Title, IconName, Location, isDisabled = false) {
  return {
    title: Title,
    icon: IconName,
    path: Location,
    isDisabled: isDisabled,
  };
}

function ParentListItemObject(
  Title,
  IconName,
  clickMethod,
  isOpen,
  Children = []
) {
  return {
    title: Title,
    icon: IconName,
    clickMethod: clickMethod,
    isOpen: isOpen,
    children: Children,
  };
}

function ChildListItemObject(Title, Location, isDisabled = false) {
  return {
    title: Title,
    path: Location,
    isDisabled: isDisabled,
  };
}

// Nav list styles:
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  hide: {
    display: "none",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    ...theme.mixins.toolbar,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const SideNavigationPanel = (props) => {
  // Generating and managing function for nav list items:
  const NavListItem = ({ navItem, key }) => {
    // Boolean checking if that list item is the current path
    var isSelected = navItem.path == props.location.pathname;
    return (
      <>
        {navItem.isDisabled ? ( // if the nav list item is disabled:
          <ListItem id={key}>
            <ListItemIcon>{navItem.icon}</ListItemIcon>
            <ListItemText primary={navItem.title} secondary={"Coming Soon!"} />
          </ListItem>
        ) : (
          <ListItem
            button
            onClick={(e) => props.history.push(`${navItem.path}`)}
            id={key}
            selected={isSelected}
          >
            <ListItemIcon>{navItem.icon}</ListItemIcon>
            <ListItemText primary={navItem.title} />
          </ListItem>
        )}
      </>
    );
  };

  const ChildNavListItem = ({ navItem, key }) => {
    // Boolean checking if that list item is the current path
    var isSelected = navItem.path == props.location.pathname;
    return (
      <>
        {navItem.isDisabled ? ( // if the nav list item is disabled:
          <ListItem id={key} className={classes.nested}>
            <ListItemText primary={navItem.title} secondary={"Coming Soon!"} />
          </ListItem>
        ) : (
          <ListItem
            button
            onClick={(e) => props.history.push(`${navItem.path}`)}
            id={navItem.path + "NavListChildItem" + key}
            selected={isSelected}
            className={classes.nested}
          >
            <ListItemText primary={navItem.title} />
          </ListItem>
        )}
      </>
    );
  };

  const ParentNavListItems = ({ navItem, key }) => {
    // Boolean checking if that list item is the current path
    var isSelected = navItem.path == props.location.pathname;
    return (
      <>
        <ListItem button onClick={navItem.clickMethod}>
          <ListItemIcon>{navItem.icon}</ListItemIcon>
          <ListItemText primary={navItem.title} />
          {navItem.isOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={navItem.isOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {navItem.children.map((item, index) => (
              <ChildNavListItem button navItem={item} key={index} />
            ))}
          </List>
        </Collapse>
      </>
    );
  };

  const theme = useTheme();
  const [openAccount, setAccountOpen] = React.useState(false);
  const [openBills, setBillsOpen] = React.useState(false);
  const [openProperties, setOpenProperties] = React.useState(false);
  const classes = useStyles();

  const handleBillsDataClick = () => {
    setBillsOpen(!openBills);
  };
  const handleAccountsDataClick = () => {
    setAccountOpen(!openAccount);
  };

  const handlePropertiesDataClick = () => {
    setOpenProperties(!openProperties);
  };

  // Where all the possible navigation locations and thier icons and titles are stored
  const navItems = [
    ListItemObject("Dashboard", <HomeOutlinedIcon />, "/dashboard"),
    ListItemObject("Accounts", <ContactMailIcon />, "/accounts"),
    // ListItemObject("Work Orders", <BuildOutlinedIcon/>, "/workorders"),
    ListItemObject("Moves", <LocalShippingOutlinedIcon />, "/moves"),
    //ListItemObject("Property", <HomeWork/>, "/properties"),
    ListItemObject("Accounting", <LocalAtmIcon />, "/accounting"),
    ListItemObject("Reports", <FolderOpenIcon />, "/reports"),
    ListItemObject("Settings", <SettingsIcon />, "/settings"),
  ];

  const parentNavItems = [
    ParentListItemObject(
      "Bills",
      <ReceiptIcon />,
      handleBillsDataClick,
      openBills,
      [
        ChildListItemObject("Billing Dashboard", "/billingdashboard"),
        ChildListItemObject("Bill Property", "/billingwizard/new"),
        // ChildListItemObject("PMs", "/"),
        // ChildListItemObject("Customers", "/"),
        // ChildListItemObject("Batches", "/batches"),
      ]
    ),

    ParentListItemObject(
      "Properties",
      <HomeWork />,
      handlePropertiesDataClick,
      openProperties,
      [
        ChildListItemObject("Locations", "/properties"),
        ChildListItemObject("Managers", "/property-manager-search"),
      ]
    ),

    ParentListItemObject(
      "Management",
      <PermMediaOutlinedIcon />,
      handleAccountsDataClick,
      openAccount,
      [
        ChildListItemObject("Meter Upload", "/meter-readings-upload"),
        ChildListItemObject("Bulk Check Entry", "/bulkcheckentry"),
        ChildListItemObject("Delete Bill", "/deletebill", true),
        ChildListItemObject("Developers", "/developers"),
        ChildListItemObject("Rider Rates", "/riderrates", true),
        ChildListItemObject("Export Route Map", "/exportroutemap", true),
        ChildListItemObject("Owners", "/owners"),
        //ChildListItemObject("Properties", "/properties"),
        //ChildListItemObject("Property Managers", "/propertymanagers"),
      ]
    ),
  ];

  return (
    <div className={classes.root}>
      <List>
        {parentNavItems.map((item, key) => {
          //console.log("NavListParentItem" + key);
          return (
            <ParentNavListItems
              button
              navItem={item}
              id={item.path + "NavListParentItem" + key}
            />
          );
        })}
        {navItems.map((item, key) => {
          //console.log("NavListItem" + key);
          return (
            <NavListItem
              button
              navItem={item}
              id={item.path + "NavListItem" + key}
            />
          );
        })}
      </List>
    </div>
  );
};
// Component Properties
SideNavigationPanel.propTypes = {
  user: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
};

// Component State
function SideNavigationPanelState(state) {
  return {
    user: state.user,
  };
}
export default connect(SideNavigationPanelState, { logout })(
  withRouter(SideNavigationPanel)
);
