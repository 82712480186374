import React, { Fragment, useEffect, useState } from 'react';

import { PageTitle } from '../../layout-components';
import { Grid, Divider, TextField, MenuItem } from '@material-ui/core';
import { Prompt } from 'react-router'
import { spacing } from "@material-ui/system";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { ExampleWrapperSimple } from '../../layout-components';
import { LeftSidebar } from 'layout-blueprints';
import UnitForm from 'modules/batchUnit/components/UnitForm';
import DataTable from 'pages/Manage/DataTable';

const BatchUnit = (props) => {
  const [data, setData] = useState([]);
  const [propertyManager, setPropertyManager] = useState({})
  const [pmCustomerType, setPmCustomerType] = useState({});
  const [activeAccountStatus, setActiveAccountStatus] = useState({});
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false)
  const onGenerateDataTable = (dataTable, selectedPM, pmCustomerType, activeAccountStatus) => {
    setData(dataTable);
    setPropertyManager(selectedPM)
    setPmCustomerType(pmCustomerType)
    setActiveAccountStatus(activeAccountStatus)

  }

  useEffect(() => {
    if (shouldBlockNavigation) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = undefined
    }
  }, [props.user.details]);


  var pageTitle = "Ubil+ Loading Batch Unit Generator...";
  // Sets the tab name based on the loaded account being worked on
  if (props.properties.data.property.id) {
    pageTitle = "Batch Units: " + props.properties.data.property.name;
  }
  document.title = pageTitle;

  return (
    <Fragment>
      {/* <Prompt
            when={shouldBlockNavigation}
            message='You have unsaved changes, are you sure you want to leave?'
          /> */}
      <LeftSidebar>

        <PageTitle
          titleHeading="Batch Add Units"
          titleDescription="Create units and meters all at the same time"
        />
        <Grid container spacing={4}>
          <Grid item xs={12} lg={12}>
            <ExampleWrapperSimple sectionHeading="Set up Unit">
              <UnitForm onGenerateDataTable={onGenerateDataTable} />
            </ExampleWrapperSimple>
          </Grid>
          <Grid item xs={12} lg={12}>
            <ExampleWrapperSimple sectionHeading="Edit">
              <DataTable data={data} props={props} selectedPM={propertyManager} pmCustomerType={pmCustomerType} activeAccountStatus={activeAccountStatus} />
            </ExampleWrapperSimple>
          </Grid>
        </Grid>
      </LeftSidebar>
    </Fragment>
  );
}


BatchUnit.propTypes = {
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  units: PropTypes.object.isRequired,
  propertyManagers: PropTypes.object.isRequired,
  properties: PropTypes.object.isRequired,
};

// Component State
function BatchUnitState(state) {
  return {
    user: state.user,
    apl: state.apl,
    units: state.units,
    propertyManagers: state.propertyManagers,
    properties: state.properties
  };
}

export default connect(BatchUnitState, {})(BatchUnit);

