import { GET_VENDORS, GET_VENDORS_RESPONSE, RESET } from "./actions";

const initialState = {
  isLoading: false,
  error: null,
  data: {
    vendorsList: {
      items: [],
    },
    vendor: null,
    searchQuery: null,
    range: { start: 0, end: 25 },
  },
};

// State
export default (state = initialState, action) => {
  var data = state.data;
  switch (action.type) {
    case RESET:
      return Object.assign({}, initialState);
    case GET_VENDORS:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case GET_VENDORS_RESPONSE:
      data.vendorsList.items = action.vendors;
      return {
        ...state,
        data: data,
        error: action.error,
        isLoading: false,
      };
    default:
      return {
        ...state,
      };
  }
};
