//React Components and Hooks
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
//Material UI Components and Functions
import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import { DataGridPro } from "@mui/x-data-grid-pro";
import MuiAlert from "@material-ui/lab/Alert";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  fetchPreviousMeterReadingUploads,
  handleError,
  handleMeterReadingsUploadSubmission,
  receivedReadingsSubmission,
  deleteMeterReadingUpload,
  markMeterReadingUploadAsBilled
} from "../../modules/readings-uploads/api/actions";
import { getMetersByMeterNumberBatch } from "modules/meter/api/actions";
import csvToArray from "./helpers/csvToArray";
import _ from "lodash"
import "./index.css";
import UploadsTable from "./UploadsTable";
import { styled } from '@mui/material/styles';
import moment from "moment";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`action-tabpanel-${index}`}
      aria-labelledby={`action-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pt={3} pl={-1}>
          {children}
        </Box>
      )}
    </Typography>
  );
}

TabPanel.propTypes = {
  user: PropTypes.object.isRequired,
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `action-tab-${index}`,
    "aria-controls": `action-tabpanel-${index}`,
  };
}





function csvJSON(csv){

  var lines=csv.split("\t");

  var result = [];

  var headers=lines[0].split(",");

  for(var i=1;i<lines.length;i++){

	  var obj = {};
	  var currentline=lines[i].split(",");

	  for(var j=0;j<headers.length;j++){
		  obj[headers[j]] = currentline[j];
	  }

	  result.push(obj);

  }
  
  //return result; //JavaScript object
  return result //JSON
}

const useStyles = makeStyles({});

const Input = styled('input')({
  display: 'none',
});

const MeterReadingsUpload = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const [value, setValue] = useState(0);
  const [file, setFile] = useState({});
  const [csvData, setCsvData] = useState({ rows: [], columns: [] });
  const [recentFile, setRecentFile] = useState("");
  const [successState, setSuccessState] = useState(false);
  const [errorState, setErrorState] = useState(false);
  const [meters, setMeters] = useState([]);

  const getUploads = () => {
    try {
      props.fetchPreviousMeterReadingUploads();
    } catch (err) {
      setErrorState(true);
    }
  };
  

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSuccess = () => {
    setSuccessState(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccessState(false);
    setErrorState(false);
  };

  const fileInputChange = async  (e) => {
    let fileTypeRegEx = /(?:\.([^.]+))?$/;
    let fileType = fileTypeRegEx.exec(e.target.files[0].name)[1];

    if (fileType !== "exp") {
      props.handleError({ message: "Please upload a exp file." });
      setErrorState(true);
    } else {
      setFile(e.target.files[0])
      var file = e.target.files[0];
        var reader = new FileReader();
        reader.onload = async function(e) {
          // The file's text will be printed here
          console.log(e.target.result)
          let result = await expToJSON(e.target.result)
           
         
          console.log("result: ",result)
          // if(result.length > 1000){
          //   props.handleError({ message: "exp file must have less than 1000 records." });
          //   setErrorState(true);
          //   return;
          // }
          let formatted = []
          formatted = formatData(result, true);
          console.log(formatted)
  
          setCsvData(formatted);
        };

        reader.readAsText(file);
    };
  };

  const submitReadingsUpload = async (e) => {
    e.preventDefault();

    let reader = new FileReader();

    
      try {
        let data = csvData;
        // props.receivedReadingsSubmission(data); // if we implement preview feature
        let result = await props.handleMeterReadingsUploadSubmission(
          data.rows,
          data.columns,
          file.name
        );
        if (result?.error == true)
          handleError(result.data.message)
        else {
          handleSuccess(`Successfully Uploaded ${result.length} meter reads.`);
          setCsvData({ rows: [], columns: [] });
          props.fetchPreviousMeterReadingUploads();
          setRecentFile(file.name);
        }

        
      } catch (err) {
        setErrorState(true);
      }
    

    reader.readAsText(file);
  };

  useEffect(() => {
    getUploads();
  }, []);

  const formatData = (datas, caseChange) => {
    const keys = Object.keys(datas[0]);
    let output = keys.map((key) => {
      return {
        field: key,
        editable: false,
        width: 200,
        headerName: (caseChange) ? _.startCase(key) : key
      };
    });

    datas.forEach(function (element, index) {
      element.id = index + 1;
    });
    return { rows: datas, columns: output };
  };
  async function expToJSON(exp) {
    console.log("exp:",{exp})
    let iComma = exp.slice(0, 100).indexOf(',');
    let fileData = exp.slice(iComma + 1);
    let lines = []
    // split by white space 
    lines.push(...fileData.split(/\r?\n/))
    // lines = lines.flatMap(e => e.split(/\s+/));
    console.log({lines})
    let result = [];
    // get all meter numbers  
    const meterNums = lines.map(e => {
      e = e.split(/\s+/).filter(e => e !== "");
      return e[1];
    })
    await props.getMetersByMeterNumberBatch(meterNums)
      console.log("im done")
      console.log("props", props)
      // setMeters(props.meter.data.metersList.items) 
    
     
      
    let headers = ['accountNum', 'meterNum', 'readType', 'address1', 'address2','acct holder', 'prev read', 'curr read' ];
    for (let i = 0; i < lines.length; i++) {
      let obj = {};
      let currentline = lines[i];
      if (currentline === "") {
        continue;
      }
      //remove split by white space and remove empty strings
      currentline = currentline.split(/\s+/).filter(e => e !== "");
      let meterNum = currentline[1];
      let accountNum = currentline[2].slice(0, 7);
      const meter = props.meters.data?.metersList?.items.find(e => e.meterNum === meterNum)
      // get last 3 characters 
      let readType = currentline[5].slice(-3);
      let readAmt = 0;
      let readDate; 
      let readIndex;
      // let currentRead;
      for(let i = 6; i < currentline.length; i++){
        const item = currentline[i]
        const firstFour = item.slice(0,4)
        if(!firstFour){
          console.log("no first four", item)
        }
        if(firstFour.includes("0000")){
         
          readIndex = currentline.indexOf(item);
          
          // read first 10 digits
          var prevRead = item.slice(0, 10)
          // read next 10 digits
          let currentRead = item.slice(10, 20)
          if(currentRead.includes(":")){
            // remove non digit characters
            currentRead = currentRead.replace(/\D/g, '');
          }
          
          readAmt = currentRead != '' ? currentRead : 0;
          // if readDecimal is false then round down to nearest whole number
          if(meter?.readDecimals){
            readAmt = Number((readAmt / 100).toFixed(2))
          }
      }
    }
  
      readDate = moment(currentline[readIndex + 1]);
      
  
     
      obj['accountNum'] = accountNum;
      obj['meterNumber'] = meterNum;
      obj['readType'] = readType;
      obj['readAmt'] = readAmt;
      obj['readDate'] = readDate;
  
      result.push(obj);
    }
    return result;
  }

  let errorMessage =
    props.meterReadingsUpload.error && props.meterReadingsUpload.error.message
      ? props.meterReadingsUpload.error.message
      : null;

  return (
    <Grid className={classes.root} container item xs={12}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={successState}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          Successfully submitted meter upload.
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={errorState}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>

      <Grid container item>
        <Grid md={12} container item>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="primary"
            variant="fullWidth"
            aria-label="action tabs example"
          >
            <Tab label="Upload Meter Readings" {...a11yProps(0)} />
            <Tab label="Previous Meter Reading Uploads" {...a11yProps(1)} />
          </Tabs>
        </Grid>
      </Grid>

      <Grid container item>
        <Grid item md={12}>
          <TabPanel value={value} index={0} dir={theme.direction}>
            <form method="post" action="#" id="#">
              <div className="meter-readings-upload-form-container">
                <div className="meter-readings-upload-form">

                <input
                className={classes.input}
                style={{ display: 'none' }}
                onChange={fileInputChange}
                id="raised-button-file"
                multiple=""
                type="file"
              />
              <label htmlFor="raised-button-file">
                <Button color="primary" variant="contained" component="span">
                  Upload
                </Button>
              </label> 
                </div>
                {csvData.rows.length > 0 &&
                <Button
                  classes={classes.root}
                  disabled={
                    props.meterReadingsUpload.isLoading ||
                    recentFile == file.name
                  }
                  variant="contained"
                  color="secondary"
                  onClick={submitReadingsUpload}
                >
                  {props.meterReadingsUpload.isLoading  ? (
                    <CircularProgress color="inherit" />
                  ) : (
                    "Submit"
                  )}
                </Button> }
              </div>
            </form>

            <div style={{ height: 850, width: "100%" }}>            
            <DataGridPro
                {...csvData}
                rowHeight={38}
    
              />
            </div>
          </TabPanel>
        </Grid>

        <Grid>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <UploadsTable
              uploads={
                props.meterReadingsUpload.data.previousMeterReadingUploads
              }
              canDelete={(props.user.details.administration || props.user.details.isSuperUser) ? true : false}
              handleUploadDelete={props.deleteMeterReadingUpload}
              markMeterReadingUploadAsBilled={props.markMeterReadingUploadAsBilled}
            />
          </TabPanel>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    meterReadingsUpload: state.meterReadingsUpload,
    meters: state.meters,
    user: state.user
  };
};

export default connect(mapStateToProps, {
  handleMeterReadingsUploadSubmission,
  receivedReadingsSubmission,
  handleError,
  fetchPreviousMeterReadingUploads,
  deleteMeterReadingUpload,
  markMeterReadingUploadAsBilled,
  getMetersByMeterNumberBatch
})(MeterReadingsUpload);
