import React, { useEffect } from "react";
import styled, { withTheme } from "styled-components/macro";
import { NavLink } from "react-router-dom";

import Helmet from "react-helmet";

//import "../../vendor/roundedBarCharts";
import { Bar } from "react-chartjs-2";

import { red, green, blue } from "@material-ui/core/colors";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";

import {
  Paper,
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card,
  CardContent,
  Chip,
  Divider as MuiDivider,
  Grid,
  LinearProgress as MuiLinearProgress,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Switch,
} from "@material-ui/core";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import TableContainer from "@material-ui/core/TableContainer";

// Redux Components
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  // Generic Types status changing types
  APL_GET_GEN_TYPES_REQUEST,
  APL_GET_GEN_TYPES_RESPONSE,
  APL_GET_GEN_TYPES_FAILURE,
  getAllGenericDBStatusOrTypeData,
} from "../api/actions";

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  percentDif: {
    textAlign: "right",
    color: "green",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  loadingIcon: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  billingWizardContent: {
    maxHeight: "60%",
  },
  paper: {
    padding: "1%",
  },
}));

const MissingTypeIDValue = "Unknown";

// Generic Tag Item
// NOTE:  Expects (In props){type (the name in the store as a string), isLoading, typeId, customStyles(optional), condensed(optional, uses small styling), showTitle(optional, toggles title)}
const StatusTag = (props) => {
  const classes = useStyles();
  // Default Values for status settings
  var isLoading = false;
  var showTitle = true;
  var condensed = false;
  if (typeof props.isLoading === "boolean") {
    isLoading = props.isLoading;
  }
  if (typeof props.showTitle === "boolean") {
    showTitle = props.showTitle;
  }
  if (typeof props.condensed === "boolean") {
    condensed = props.condensed;
  }

  // Default error tag needs this props
  if (props.type == "Alert" && props.label) {
    return (
      <Chip
        label={props.label}
        color="primary"
        style={{
          backgroundColor: "red",
          color: "white",
          fontSize: "100%",
          fontWeight: "bolder",
        }}
        size={condensed ? "small" : "medium"}
      />)
  }
  else if (props.type == "Warning" && props.label) {
    return (
      <Chip
        label={props.label}
        color="primary"
        style={{
          backgroundColor: "orange",
          color: "white",
          fontSize: "100%",
          fontWeight: "bolder",
        }}
        size={condensed ? "small" : "medium"}
      />)
  }
  else if (props.type == "Success" && props.label) {
    return (
      <Chip
        label={props.label}
        color="primary"
        style={{
          backgroundColor: "green",
          color: "white",
          fontSize: "100%",
          fontWeight: "bolder",
        }}
        size={condensed ? "small" : "medium"}
      />)
  }
  else if (props.type == "Default" && props.label) {
    return (
      <Chip
        label={props.label}
        color="primary"
        style={{
          backgroundColor: "#000000",
          color: "white",
          fontSize: "100%",
          fontWeight: "bolder",
        }}
        size={condensed ? "small" : "medium"}
      />)
  }

  // If the requested code doesnt Exist, return an error tag to alert the dev they screwed up (remove on production, here to help troubleshoot)
  if (!props.apl.genericTypes[props.type]) {
    // Checks type
    return (
      <Chip
        label={"ERROR! Type Not Found: " + props.type}
        color="primary"
        style={{
          backgroundColor: "red",
          color: "white",
          fontSize: "100%",
          fontWeight: "bolder",
        }}
        size={condensed ? "small" : "medium"}
      />
    );
  }



  // Checks if either module is still loading a piece before continuing
  if (
    props.apl.genericTypes[props.type].isLoading ||
    isLoading ||
    props.apl.genericTypes[props.type].status == null
  ) {
    // If it sees the isLoading of the requested type is null, that means it didnt even attempt to load in the values for a first time yet, dispatch it here:
    if (props.apl.genericTypes[props.type].status == null) {
    }
    return <></>; // Returns nothing (may make a loading version of the tag in a later revision)
  }
  if (!props.typeId) {
    // Checks typeId
    return (
      <Chip
        label={"ERROR! No typeId passed"}
        color="primary"
        style={{
          backgroundColor: "red",
          color: "white",
          fontSize: "100%",
          fontWeight: "bolder",
        }}
        size={condensed ? "small" : "medium"}
      />
    );
  }

  var chipStyles = { fontSize: "100%", fontWeight: "bolder", color: "white" };
  if (props.customStyles) {
    chipStyles = Object.assign({}, props.customStyles, chipStyles);
  }
  var lbl = "";
  if (showTitle) {
    lbl = lbl + props.apl.genericTypes[props.type].title;
  }

  for (var x = 0; x < props.apl.genericTypes[props.type].data.length; x++) {
    if (props.apl.genericTypes[props.type].data[x].id == props.typeId) {
      // if a typeID match is found, concat the target field to the type title field and return the chip
      if (
        props.apl.genericTypes[props.type].data[x].bgColorTag &&
        props.apl.genericTypes[props.type].data[x].bgColorTag != null
      ) {
        chipStyles.backgroundColor =
          "#" + props.apl.genericTypes[props.type].data[x].bgColorTag;
      }
      if (
        props.apl.genericTypes[props.type].data[x].txtColorTag &&
        props.apl.genericTypes[props.type].data[x].txtColorTag != null
      ) {
        chipStyles.color =
          "#" + props.apl.genericTypes[props.type].data[x].txtColorTag;
      }

      if(props.apl.genericTypes[props.type].data[x].description == "Active") {
        chipStyles.backgroundColor =
        "#00db28"
      }
      return (
        <Chip
          //icon={props.icon}
          label={
            lbl +
            props.apl.genericTypes[props.type].data[x][
            props.apl.genericTypes[props.type].valueToDisplay
            ]
          } // Yikes, was more ellegant in my head lol
          color={props.apl.genericTypes[props.type].data[x].description == "Active" ? "secondary": "primary"}
          style={chipStyles}
          size={condensed ? "small" : "medium"}
        />
      );
    }
  }
  // If the for loop ends and nothing is found return the unknown tag
  return (
    <Chip
      //icon={props.icon}
      label={lbl + MissingTypeIDValue}
      color={lbl == "Active" ? "success": "primary"}
      style={chipStyles}
      size={condensed ? "small" : "medium"}
    />
  );
};

// Component Properties
StatusTag.propTypes = {
  // Store objects:
  apl: PropTypes.object.isRequired,
  getAllGenericDBStatusOrTypeData: PropTypes.func.isRequired,
};

// Component State
function StatusTagState(state) {
  return {
    apl: state.apl,
  };
}
export default connect(StatusTagState, { getAllGenericDBStatusOrTypeData })(
  StatusTag
);
