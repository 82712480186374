import {
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  CircularProgress as MuiCircularProgress,
  Divider as MuiDivider,
  Grid,
  Paper as MuiPaper,
  Snackbar,
} from "@material-ui/core";
//Material UI Components and Functions
import { makeStyles } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import { spacing } from "@material-ui/system";
// Redux Components
import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { EditableTable } from "../../../components/EditableTable";
import { logout } from "../../user/api/actions";
import { updateActiveUser } from "../api/actions";
import ActiveUserForm from "./ActiveUsersForm";
import {setSnackbar } from "../../snackbar/api/snackbar"
const Button = styled(MuiButton)(spacing);

const DashboardToolBarButton = styled(Button)`
  padding: 4px;
  min-width: 0;
  svg {
    width: 2em;
    height: 2em;
  }
`;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CircularProgress = styled(MuiCircularProgress)(spacing);

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  percentDif: {
    textAlign: "right",
    color: "green",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  loadingIcon: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Spacer = styled.div`
  flex: 1 1 100%;
`;

//Editable Table Object Expects: {objectArray, fieldTitles(Array), fieldTypes(Array), fieldNames(Array), handleRowClickParentMethod}
// Method can take line items if they exist initially or starts as an empty array of nothing passed
function ActiveUserTable(props) {
  const classes = useStyles();
  const history = useHistory();
  const navTo = (page) => history.push(page);
  const fieldTitles = [
    "User",
    "First Name",
    "Last Name",
    "Email",
    "Phone",
    "Admin",
    "Active",
    "Updated",
  ];
  const fieldTypes = [
    "labelSmall",
    "labelBold",
    "label",
    "label",
    "label",
    "icon",
    "icon",
    "dateTimeLabel",
  ];
  const fieldNames = [
    "id",
    "firstName",
    "lastName",
    "email",
    "phone",
    "isSuperUser",
    "isActive",
    "updated",
  ]; // Must be the exact name of the properties you wanna show

  const [open, setOpen] = useState(false);
  const [item, setItem] = useState({});
  const [create, setNew] = useState(false);
  const [alert, setAlert] = useState({ open: false, message: "", type: "" });
  const dispatch = useDispatch();

  const onSettingSelected = (item) => {
    setNew(false);
    if (item) {
      setItem(item);
    }
    setOpen(!open);
  };

  const onNewSettingSelected = () => {
    setNew(true);
    setItem({});
    setOpen(!open);
  };

  const onSettingUpdate = (uuid, newItem) => {
    props.updateActiveUser(uuid, newItem).then(() => {
    }).catch(error => {
    });
  };

  const isAdmin = props.user.details.isSuperUser == 1 ? true : false;

  const handleClose = () => {
    setAlert({ open: false });
  };

  return (
    <div>
      {!!(props.user.details.isSuperUser || props.user.details.administration) ? 
      <Grid item xs={12}>
        <Snackbar
          open={alert.open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={alert.type}>
            {alert.message}
          </Alert>
        </Snackbar>
        <EditableTable
          // Functional methods of table
          // Properties of table
          tableTitle={"Active Internal Users"}
          canAddItems={isAdmin}
          canExport={true}
          dense={true}
          canSave={false}
          readOnly={false}
          //canSearch={true}
          newItemCreationMethod={onNewSettingSelected}
          objectArray={props.setting.activeUsers}
          fieldTitles={fieldTitles}
          fieldTypes={fieldTypes}
          handleRowClickParentMethod={onSettingSelected}
          fieldNames={fieldNames}
          showButtons={false}
        />
      </Grid> 
      : <h3>You do not not have User privileges</h3>  
    }
      {open && (
        <ActiveUserForm
          new={create}
          item={item}
          updateItem={onSettingUpdate}
          onChange={onSettingSelected}
          show={open}
        />
      )}
    </div>
    
  );
}
// Component Properties
ActiveUserTable.propTypes = {
  // Store objects:
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  billing: PropTypes.object.isRequired,
  // Store object functions:
  getAllBillingDashboardInfo: PropTypes.func.isRequired,
  getBillInProgressDraft: PropTypes.func.isRequired,
  updateBillingWizardBillInProgress: PropTypes.func.isRequired,
  updateBillingWizardSelectedProperty: PropTypes.func.isRequired,
  resetBillingObject: PropTypes.func.isRequired,
  getBillingBatchById: PropTypes.func.isRequired,
};

// Component State
function ActiveUserTableState(state) {
  return {
    usr: state.user,
    setting: state.setting,
  };
}

export default connect(ActiveUserTableState, { logout, updateActiveUser })(
  ActiveUserTable
);
