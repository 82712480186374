//React Components and Hooks
import { Divider as MuiDivider } from "@material-ui/core";
//Material UI Components and Functions
import { makeStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components/macro";
import StandardHeader from "../../components/global/StandardHeader";
//Custom Components
import { LeftSidebar as NavFrame } from "layout-blueprints";
//Context
import MeterReadingsUpload from "./MeterReadingsUpload";

const Divider = styled(MuiDivider)(spacing);
const useStyles = makeStyles((theme) => ({}));

const MeterReadingsUploadPage = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const [currentDate, setCurrentDate] = useState(new Date());
  const [error, setError] = useState("");
  const [showError, setShowError] = useState(false);
  const { reportName } = useParams();

  const asyncEffect = async () => { };

  useEffect(() => {
    asyncEffect();
  }, []);

  var pageTitle = "Meter Readings Uploads";
  document.title = "Ubil+ Meter Readings";
  return (
    <NavFrame page={"Meter Readings Upload"}>
      <StandardHeader
        title={pageTitle}
        appPath={"Management / Meter Readings Uploads"}
      />
      <Divider className="mb-3 mt-3" />
      <MeterReadingsUpload />
    </NavFrame>
  );
};

export default MeterReadingsUploadPage;
