// Imports
import axios from "axios";
require("../../../setup/config/apiConfig");

export const RESET = "RESET";
export const GET_VENDORS = "GET_VENDORS";
export const GET_VENDORS_RESPONSE = "GET_VENDORS_RESPONSE";
export const GET_VENDORS_RESPONSE_FAIL = "GET_VENDORS_RESPONSE_FAIL";
export const GET_VENDOR = "GET_VENDOR";
export const GET_VENDOR_RESPONSE = "GET_VENDOR_RESPONSE";
export const GET_VENDOR_RESPONSE_FAIL = "GET_VENDOR_RESPONSE_FAIL";

export function get(isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: GET_VENDORS,
      isLoading: isLoading,
    });
    return axios
      .get(`/vendor`)
      .then((response) => {
        dispatch({
          type: GET_VENDORS_RESPONSE,
          vendors: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_VENDORS_RESPONSE_FAIL,
          isLoading: false,
          error: error,
        });
      });
  };
}

export function getByUuid(uuid, isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: GET_VENDOR,
      isLoading: isLoading,
    });
    return axios
      .get(`/vendor/${uuid}`)
      .then((response) => {
        dispatch({
          type: GET_VENDOR_RESPONSE,
          vendor: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_VENDOR_RESPONSE_FAIL,
          isLoading: false,
          error: error,
        });
      });
  };
}

export function getById(id, isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: GET_VENDOR,
      isLoading: isLoading,
    });
    return axios
      .get(`/vendor/${id}`)
      .then((response) => {
        dispatch({
          type: GET_VENDOR_RESPONSE,
          vendor: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_VENDOR_RESPONSE_FAIL,
          isLoading: false,
          error: error,
        });
      });
  };
}
