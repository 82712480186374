import {
  Button as MuiButton,
  Card,
  CardContent,
  CircularProgress as MuiCircularProgress,
  CardHeader as MuiCardHeader,
  Divider as MuiDivider,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress as MuiLinearProgress,
  MenuItem,
  Select as MuiSelect,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";

// Redux Components
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components/macro";
import { txtBoxDateFormatter } from "../../../setup/helpers";
import { setSnackbar } from "../../snackbar/api/snackbar";
import {
  existingMoveInUpdate
} from "../api/actions";

const LinearProgress = MuiLinearProgress;
const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Select = styled(MuiSelect)(spacing);
const CardHeader = styled(MuiCardHeader)(spacing);

const AccountMoveInItemForm = (props) => {
  const dispatch = useDispatch();
  const useStyles = makeStyles((theme) => ({
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    loadingIcon: {
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
    },
  }));

  useEffect(() => {
    if (props.account.data.move && props.account.data.move.moveIn.id) {
      props.moves.accountData.moveIn = props.account.data.move.moveIn;
    }
  }, [props.account.isLoading])

  // Handles vendor drop down
  const classes = useStyles();
  if (props.account.isLoading && props.moves.accountData.moveIn == null) {
    return (
      <Card mb={6}>
        <CardContent>
          <Typography variant="h3" gutterBottom>
            Account Move In Loading...
          </Typography>
        </CardContent>
      </Card>
    );
  }
  if (props.moves.accountData.moveIn == null) {
    return (
      <Card mb={6}>
        <CardContent>
          <Typography variant="h3" gutterBottom>
            Account move in not found
          </Typography>
        </CardContent>
      </Card>
    );
  }

  // Updates the local state object of "bill in progress" upon each modification
  const onFieldChange = (event) => {
    // Gets the current vendor info data and updates it with the event's name and value:
    if (event.target.type == "number") {
      props.moves.accountData.moveIn[event.target.name] = Number(
        event.target.value
      );
    } else {
      props.moves.accountData.moveIn[event.target.name] = event.target.value;
    }
    if (event.target.name == "moveInDate" && props.account.data.move && props.account.data.move.moveIn.id) {
      props.account.data.move.moveIn = props.moves.accountData.moveIn;
    }
  };

  const submitMoveInUpdate = () => {
    console.log(props.moves.accountData.moveIn);
    if (props.moves.accountData.moveIn.id) {
      dispatch(
        setSnackbar(true, "warning", "Updating account move in object")
      );
      props.existingMoveInUpdate(props.moves.accountData.moveIn).then(() => {
        if (props.moves.accountData.error == null) {
          dispatch(setSnackbar(true, "success", "Move in item updated successfully"));
        }
        else {
          dispatch(setSnackbar(true, "error", "Error updating move item."));
        }
      });
    }
  }

  return (
    <Card className="mb-6">
      <CardHeader
        xs={12}
        sm={12}
        md={12}
        lg={12}
        title="Edit Account Move In Details"
        subheader="Editing of original customer move in item not allowed. To update the move in date, please use the override item on the account object."
      />
      <Divider className="mb-3" />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item lg={4} md={4} sm={6}>
            <TextField
              label="Move In Date"
              type="date"
              onChange={onFieldChange}
              name="moveInDate"
              defaultValue={txtBoxDateFormatter(props.moves.accountData.moveIn.moveInDate)}
              fullWidth
              my={2}
              disabled={true}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={6}>
            <TextField
              label="Initial Move In First Name"
              type="text"
              onChange={onFieldChange}
              name="firstName"
              defaultValue={props.moves.accountData.moveIn.firstName}
              fullWidth
              my={2}
              disabled={true}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={6}>
            <TextField
              label="Initial Move In Last Name"
              type="text"
              onChange={onFieldChange}
              name="lastName"
              defaultValue={props.moves.accountData.moveIn.lastName}
              fullWidth
              my={2}
              disabled={true}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={6}>
            <TextField
              label="Initial Move In Email"
              type="text"
              onChange={onFieldChange}
              name="lastName"
              defaultValue={props.moves.accountData.moveIn.email}
              fullWidth
              my={2}
              disabled={true}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
        <Button
          className="mb-3 mt-3 mr-3"
          variant="contained"
          disabled={true}
          color="secondary"
          onClick={submitMoveInUpdate}
        >
          Update Move In
        </Button>
      </CardContent>
    </Card>
  );
};

// Component Properties
AccountMoveInItemForm.propTypes = {
  // Store objects:
  account: PropTypes.object.isRequired,
  moves: PropTypes.object.isRequired,
  //userProfile: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  existingMoveInUpdate: PropTypes.func.isRequired,
};

// Component State
function AccountMoveInItemFormState(state) {
  return {
    account: state.account,
    apl: state.apl,
    moves: state.moves
  };
}
export default connect(AccountMoveInItemFormState, { existingMoveInUpdate })(AccountMoveInItemForm);
