import {
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  TextField as MuiTextField,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { spacing } from "@material-ui/system";
import React, { useState } from "react";
import styled from "styled-components/macro";

const Select = styled(MuiSelect)(spacing);
const TextField = styled(MuiTextField)(spacing);

export default (props) => {
  const handleClose = props.handleClose;
  const submitNewDeveloper = props.submitNewDeveloper;

  const [editDeveloperObj, setEditDeveloperObj] = useState({
    status: "Active",
  });

  const onDeveloperFieldChange = (e) => {
    setEditDeveloperObj(
      Object.assign({}, editDeveloperObj, { [e.target.name]: e.target.value })
    );
  };

  const action = async () => {
    await submitNewDeveloper(editDeveloperObj);
  };

  return (
    <div>
      <Card>
        <CardContent>
          {/* PropertyManager Details */}
          <Grid container spacing={2}>
            <h3>Developer Info:</h3>
            <Grid container item spacing={2}>
              <Grid item sm={6} xs={12}>
                <TextField
                  label="Developer Name"
                  type="text"
                  onChange={onDeveloperFieldChange}
                  name="name"
                  //required
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  label="Contact Name"
                  type="text"
                  onChange={onDeveloperFieldChange}
                  name="contactName"
                  //required
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  label="Address 1"
                  type="text"
                  onChange={onDeveloperFieldChange}
                  name="address1"
                  //required
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  label="Address 2"
                  type="text"
                  onChange={onDeveloperFieldChange}
                  name="address2"
                  //required
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  label="City"
                  type="text"
                  onChange={onDeveloperFieldChange}
                  name="city"
                  //required
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
            <Grid item sm={3} xs={6}>
              <TextField
                label="State"
                type="text"
                onChange={onDeveloperFieldChange}
                name="state"
                //required
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item sm={3} xs={6}>
              <TextField
                label="Zip"
                type="text"
                onChange={onDeveloperFieldChange}
                name="zip"
                //required
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item sm={3} xs={6}>
              <TextField
                label="Email"
                type="text"
                onChange={onDeveloperFieldChange}
                name="email"
                //required
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item sm={3} xs={6}>
              <TextField
                label="Phone"
                type="text"
                onChange={onDeveloperFieldChange}
                name="phone"
                //required
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="property">Status</InputLabel>
                <Select
                  labelId="status"
                  id="status"
                  onChange={onDeveloperFieldChange}
                  defaultValue="Active"
                  name="status"
                >
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="Inactive">Inactive</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid container item spacing={2} justify={"flex-end"}>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    action();
                    handleClose();
                  }}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};
