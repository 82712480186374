import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Autocomplete from '@mui/material/Autocomplete';

import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { setSnackbar } from "../../snackbar/api/snackbar";
import { getActiveUsers } from "../../settings/api/actions";
import { createWorkOrder, getWorkOrders, getWorkOrdersByUnit } from "../api/actions"
import Typography from "@material-ui/core/Typography";
import WorkOrderNotes from "./WorkOrderNotes"

function CreateWorkOrder(props) {
  const [users, setUsers] = useState(props.setting.activeUsers.items || [{ id: "", label: "" }]);
  const [properties, setProperties] = useState(props.properties.data.propertiesList.items || [{ id: "", label: "" }])
  const [status, setStatus] = useState(props.apl.genericTypes.workOrderStatus.data || [{ id: "", label: "" }]);
  const [user, setUser] = useState(props.user.details || {})
  const [units, setUnits] = useState([{ id: "", label: "" }])
  let optionsUnits = [];
  const optionsProperties = properties.map(property => {
    return { label: `${property.name}`, id: property.id }
  })

  const options = users.map(user => {
    return { label: `${user.firstName} ${user.lastName}`, id: user.id }
  })

  const workOrderStatusOptions = status.map(status => {
    return { label: `${status.description}`, id: status.id }
  })
  const newOption = workOrderStatusOptions.find(ws => {
    return ws.label == "New"
  })
  const [add, setAdd] = useState(props.show);
  const [item, setItem] = useState(props.item);
  const [state, setState] = useState({
    workOrderTitle: "",
    assignedTo: "",
    workOrderStatusId: newOption?.id || "",
    dueDate: "",
    unitUuid: "",
  });
  const [error, setError] = useState(false);



  const dispatch = useDispatch();



  const handleSave = async () => {
    let currentState = state;
    if (props.existingUnit) {
      currentState.unitUuid = props.existingUnit.unit.uuid;
    }
    const body = {
      assignedTo: String(currentState.assignedTo),
      dueDate: currentState.dueDate,
      unitUuid: currentState.unitUuid,
      workOrderStatusId: currentState.workOrderStatusId,
      workOrderTitle: currentState.workOrderTitle
    }
    const isEmpty = Object.values(body).some(x => x === null || x === '');

    if (!isEmpty) {
      props.createWorkOrder(body).then(() => {
        if (!props.existingUnit) {
          props.getWorkOrders()
        }
        else {
          props.getWorkOrdersByUnit(props.existingUnit.unit.id);
        }
      })
      setError(false);
      setAdd(false);
    }
    else {
      setError(true);
    }
  };

  const propertyChange = async (event, value) => {
    setState({ ...state, propertyId: value?.id || "" })
    try {
      if (value?.id) {

        let response = await axios.get(`/property/${value.id}/unit`);

        if (response.status = 200) {
          let units = response.data;
          optionsUnits = units.map(unit => {
            return { label: `${unit.address1} ${unit.address2}`, id: unit.uuid }
          })
          setUnits(optionsUnits)
        }
        else {
          setState({ ...state, unitUuid: "" })
          setUnits([{ id: "", label: "" }])

        }
      }
      else {
        setUnits([{ id: "", label: "" }])
      }
    }
    catch (e) {

    }
  }

  const handleCancel = () => {
    setAdd(false);
  };

  const handleClose = () => {
    setAdd(false);
  }

  const handleChange = (evt) => {
    let value = evt.target.value;
    if (evt.target.name == "isActive") value = evt.target.checked;

    setState({
      ...state,
      [evt.target.name]: value,
    });
  };


  useEffect(() => {
    if (props.setting.activeUsers.items.length <= 0) {
      props.getActiveUsers();
    }
  }, [])

  return (
    <div>
      <Dialog
        open={add}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="md"
        minHeight='80vh'
        max
        disableBackdropClick
      >
        <DialogTitle id="form-dialog-title">
          <>{!props.new ? "Update Work Order" : "New Work Order"}</>
          {props.existingUnit &&
            <Typography>{props.existingUnit.property.name}
              {props.existingUnit.unit.address1 ? " -  " + props.existingUnit.unit.address1 + ", " + props.existingUnit.unit.address2 : " "}
            </Typography>
          }
        </DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="workOrderTitle"
            name="workOrderTitle"
            value={state.workOrderTitle}
            label="Work Order Title"
            type=""
            fullWidth
            onChange={handleChange}
          />
          <div>
            <Autocomplete
              disablePortal
              isOptionEqualToValue={(option, value) => option.id === value.id}
              id="assignedTo"
              name="assignedTo"
              defaultValue={options.find(v => v.id == state.assignedTo)}
              onChange={(event, value) => setState({ ...state, assignedTo: value?.id || "" })}
              options={options}
              error={error}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Assigned To" error={error && state.assignedTo == ""} />}
            />
            <Autocomplete
              disablePortal
              required
              isOptionEqualToValue={(option, value) => option.id === value.id}
              id="workOrderStatusId"
              name="workOrderStatusId"
              error={error}
              defaultValue={workOrderStatusOptions.find(v => v.label == "New")}
              onChange={(event, value) => setState({ ...state, workOrderStatusId: value?.id || "" })}
              options={workOrderStatusOptions}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField error={error && state.workOrderStatusId == ""} {...params} label="Status" />}
            />
            {!props.existingUnit &&
              <>
                <Autocomplete
                  disablePortal
                  required
                  error={error}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  id="propertyId"
                  name="propertyId"
                  onChange={propertyChange}
                  options={optionsProperties}
                  sx={{ width: 300 }}
                  renderInput={(params) => <TextField error={error && state.propertyId == ""} {...params} label="Property" />}
                />
                <Autocomplete
                  disablePortal
                  requried
                  error={error}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  id="unitUuid"
                  name="unitUuid"
                  onChange={(event, value) => setState({ ...state, unitUuid: value?.id || "" })}
                  options={units}
                  sx={{ width: 300 }}
                  renderInput={(params) => <TextField error={error && state.unitUuid == ""} {...params} label="Unit" />}
                />
              </>
            }
          </div>
          <TextField
            id="dueDate"
            label="Due Date"
            type="date"
            error={error}
            name="dueDate"
            sx={{ width: '25ch', m: 1 }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleChange}
          />
          <DialogActions>
            <Button onClick={handleCancel} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSave} color="secondary">
              Save
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
}
CreateWorkOrder.propTypes = {
  // Store objects:
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  existingUnit: PropTypes.object.isRequired,

  billing: PropTypes.object.isRequired,
  properties: PropTypes.object.isRequired,
  getActiveUsers: PropTypes.func.isRequired,
  createWorkOrder: PropTypes.func.isRequired,
  getWorkOrdersByUnit: PropTypes.func.isRequired
  // Store object functions:
};

// Component State
function CreateWorkOrderState(state) {
  return {
    setting: state.setting,
    apl: state.apl,
    user: state.user,
    properties: state.properties
  };
}

export default connect(CreateWorkOrderState, { getActiveUsers, createWorkOrder, getWorkOrders, getWorkOrdersByUnit })(
  CreateWorkOrder
);
