// App Imports
import {
  APL_GEN_TYPES_UPDATE_LOCAL_STATE,
  APL_GET_GEN_TYPES_FAILURE,
  // Generic Types status changing types
  APL_GET_GEN_TYPES_REQUEST,
  APL_GET_GEN_TYPES_RESPONSE,
  APL_GET_METRICS_REQUEST,
  APL_GET_METRICS_RESET,
  APL_GET_METRICS_RESPONSE,
  APL_GET_PROPERTY_PREVIEWS_REQUEST,
  APL_GET_PROPERTY_PREVIEWS_RESPONSE,
  APL_UPDATE_APPLICATION_INSTANCE,
} from "./actions";

// Initial State
const aplInitialState = {
  isLoading: false,
  error: null,
  //Overall analytics, apl object (WasdashBoardInfo)
  // Is loading flags for said global objects
  glCodesLoading: false,
  propertyPreviewsLoading: false,
  accountStatusTypesLoading: false,

  analyticRange: "This Month", // Defaults for
  analyticSummary: {}, // Defaults for
  analyticSummaryIsLoading: true,
  // Standardizing the repetitive process for code to get all a sub items types/statuses
  genericTypes: {
    // Account
    accountStatus: {
      isLoading: false,
      error: null,
      status: null,
      title: "Account Status: ",
      valueToDisplay: "description",
      data: [],
    },
    accountBillableStatus: {
      isLoading: false,
      error: null,
      status: null,
      title: "Account Billable Status: ",
      valueToDisplay: "description",
      data: [],
    },
    meterReadingType: {
      isLoading: false,
      error: null,
      status: null,
      title: "Read Type ",
      valueToDisplay: "description",
      data: [],
    },
    customerStatus: {
      isLoading: false,
      error: null,
      status: null,
      title: "Customer Status: ",
      valueToDisplay: "description",
      data: [],
    },
    propertyStatus: {
      isLoading: false,
      error: null,
      status: null,
      title: "Property Status: ",
      valueToDisplay: "description",
      data: [],
    },
    customerType: {
      isLoading: false,
      error: null,
      status: null,
      title: "Customer Type: ",
      valueToDisplay: "description",
      data: [],
    },
    propertyManagerStatus: {
      isLoading: false,
      error: null,
      status: null,
      title: "Property Manager Status: ",
      valueToDisplay: "description",
      data: [],
    },
    letterType: {
      isLoading: false,
      error: null,
      status: null,
      title: "Letter Type: ",
      valueToDisplay: "name",
      data: [],
    },
    noteTopic: {
      isLoading: false,
      error: null,
      status: null,
      title: "Note Topic: ",
      valueToDisplay: "description",
      data: [],
    },
    // Payment/Billing Types
    billingAccountGLCode: {
      isLoading: false,
      error: null,
      status: null,
      title: "GL Code: ",
      valueToDisplay: "glCode",
      data: [],
    },
    paymentMethodType: {
      isLoading: false,
      error: null,
      status: null,
      title: "Payment Method Type: ",
      valueToDisplay: "description",
      data: [],
    },
    paymentPlanStatus: {
      isLoading: false,
      error: null,
      status: null,
      title: "Payment Plan Status: ",
      valueToDisplay: "description",
      data: [],
    },
    autoPayAttemptStatus: {
      isLoading: false,
      error: null,
      status: null,
      title: "AutoPay Attempt Status: ",
      valueToDisplay: "description",
      data: [],
    },
    autoPayType: {
      isLoading: false,
      error: null,
      status: null,
      title: "AutoPay Type: ",
      valueToDisplay: "description",
      data: [],
    },
    vendor: {
      isLoading: false,
      error: null,
      status: null,
      title: "Vendor: ",
      valueToDisplay: "name",
      data: [],
    },
    creditCardType: {
      isLoading: false,
      error: null,
      status: null,
      title: "Credit Card Type: ",
      valueToDisplay: "description",
      data: [],
    },
    achType: {
      isLoading: false,
      error: null,
      status: null,
      title: "ACH Type: ",
      valueToDisplay: "description",
      data: [],
    },
    // Property Db Types
    unitType: {
      isLoading: false,
      error: null,
      status: null,
      title: "Unit Type: ",
      valueToDisplay: "description",
      data: [],
    },
    workOrderStatus: {
      isLoading: false,
      error: null,
      status: null,
      title: "Workorder Status Type: ",
      valueToDisplay: "description",
      data: [],
    },
  },
  data: {
    propertyPreviews: [],

    // keeping location to not break things i might have missed, new location of data is in generic types
    accountStatusTypes: [],
    glCodes: [],
  },
};

// State
export default (state = aplInitialState, action) => {
  switch (action.type) {
    case APL_GET_METRICS_RESET:
      return Object.assign({}, aplInitialState);

    case APL_UPDATE_APPLICATION_INSTANCE:
      //console.log(state.data);
      const updatedAPLObject = Object.assign({}, state.data, action.data);
      return {
        ...state,
        data: updatedAPLObject,
      };

    // Standardizing the repetitive process for code to get all a sub items types/statuses
    case APL_GEN_TYPES_UPDATE_LOCAL_STATE:
      state.genericTypes[action.itemName].status =
        APL_GEN_TYPES_UPDATE_LOCAL_STATE;
      state.genericTypes[action.itemName].data = action.data;
      return {
        ...state,
        genericTypes: state.genericTypes,
      };
    case APL_GET_GEN_TYPES_REQUEST:
      // If custom status type not passed use generic one
      state.genericTypes[action.itemName].status = APL_GET_GEN_TYPES_REQUEST;
      if (action.subItemType) {
        state.genericTypes[action.itemName].status = action.subItemType;
      }
      state.genericTypes[action.itemName].error = null;
      state.genericTypes[action.itemName].isLoading = action.subItemIsLoading;
      return {
        ...state,
        genericTypes: state.genericTypes,
      };
    case APL_GET_GEN_TYPES_RESPONSE:
      // If custom status type not passed use generic one
      state.genericTypes[action.itemName].status = APL_GET_GEN_TYPES_RESPONSE;
      if (action.subItemType) {
        state.genericTypes[action.itemName].status = action.subItemType;
      }
      state.genericTypes[action.itemName].error = action.subItemError;
      state.genericTypes[action.itemName].isLoading = action.subItemIsLoading;
      return {
        ...state,
        genericTypes: state.genericTypes,
      };
    case APL_GET_GEN_TYPES_FAILURE:
      // If custom failure type not passed use generic one
      state.genericTypes[action.itemName].status = APL_GET_GEN_TYPES_FAILURE;
      if (action.subItemType) {
        state.genericTypes[action.itemName].status = action.subItemType;
      }
      state.genericTypes[action.itemName].error = action.subItemError;
      state.genericTypes[action.itemName].isLoading = false;
      return {
        ...state,
        genericTypes: state.genericTypes,
      };

    case APL_GET_METRICS_REQUEST:
      return {
        ...state,
        error: null,
        analyticRange: action.newTimePeriod || state.analyticRange,
        analyticSummaryIsLoading: action.isLoading,
      };
    case APL_GET_METRICS_RESPONSE:
      return {
        ...state,
        error: action.error,
        analyticSummary: action.analyticSummary,
        analyticSummaryIsLoading: false,
      };
    case APL_GET_PROPERTY_PREVIEWS_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case APL_GET_PROPERTY_PREVIEWS_RESPONSE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    default:
      return state;
  }
};
