import {
  GET_UNIT,
  GET_UNIT_RESPONSE,
  GET_CURRENT_OCCUPANT_REQUEST,
  GET_CURRENT_OCCUPANT_RESPONSE,
  GET_CURRENT_OCCUPANT_FAIL,
  GET_ALL_UNIT_OCCUPANTS_REQUEST,
  GET_ALL_UNIT_OCCUPANTS_RESPONSE,
  GET_ALL_UNIT_OCCUPANTS_FAIL,
  UPDATE_CURRENT_OCCUPANT_RESPONSE,
  UPDATE_CURRENT_OCCUPANT_REQUEST,
  UPDATE_CURRENT_OCCUPANT_FAIL,
  POST_UNIT,
  POST_UNIT_FAIL,
  POST_UNIT_RESPONSE,
  PUT_UNIT,
  PUT_UNIT_FAIL,
  PUT_UNIT_RESPONSE,
  DELETE_UNIT_REQUEST,
  DELETE_UNIT_RESPONSE,
  DELETE_UNIT_FAIL,
  RESET,
  SEARCH_REQUEST,
  SEARCH_RESPONSE,
  UPDATE_SELECTED_UNIT_FOR_TRANSFER,
  TRANSFER_SELECTED_UNIT_REQUEST,
  TRANSFER_SELECTED_UNIT_RESPONSE,
  TRANSFER_SELECTED_UNIT_FAILURE,
  TRANSFER_SELECTED_UNIT_PM_REQUEST,
  TRANSFER_SELECTED_UNIT_PM_RESPONSE,
  TRANSFER_SELECTED_UNIT_PM_FAILURE,
  TRANSFER_SELECTED_UNIT_OWNER_REQUEST,
  TRANSFER_SELECTED_UNIT_OWNER_RESPONSE,
  TRANSFER_SELECTED_UNIT_OWNER_FAILURE
} from "./actions";

const initialState = {
  isLoading: false,
  unitIsLoading: false,
  postUnitError: null,
  putUnitError: null,
  error: null,
  data: {
    unitsList: {
      items: [],
    },
    unit: {},
    searchQuery: null,

    
  },

  unitTransferLoading: false,
  unitTransferError: null,
  selectedUnitForTransfer: null,
  currentOccupantError: null,
  allOccupants: {
      items: [],
      toDelete: [],
      toUpdate: []
     
    },
  currentOccupant: {},
  
};

// State
export default (state = initialState, action) => {
  var data = state.data;
  switch (action.type) {
    case RESET:
      return Object.assign({}, initialState);
    case UPDATE_SELECTED_UNIT_FOR_TRANSFER:
      return {
        ...state,
        selectedUnitForTransfer: action.unit,
      };
    case TRANSFER_SELECTED_UNIT_REQUEST:
      return {
        ...state,
        unitTransferError: null,
        unitTransferLoading: true,
      };
    case TRANSFER_SELECTED_UNIT_RESPONSE:
      return {
        ...state,
        unitTransferError: null,
        selectedUnitForTransfer: null,
        isLoading: false,
      };
    case TRANSFER_SELECTED_UNIT_FAILURE:
      return {
        ...state,
        error: action.error,
        unitTransferLoading: false,
      };

    case SEARCH_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };

    case SEARCH_RESPONSE:
      var data = state.data;
      data.unitsList = {
        items: action.units,
      };
      return {
        ...state,
        data: data,
        error: action.error,
        isLoading: false,
      };
    case TRANSFER_SELECTED_UNIT_PM_REQUEST:
      return {
        ...state,
        error: null,
        unitIsLoading: true,
      };
    case TRANSFER_SELECTED_UNIT_PM_RESPONSE:
      return {
        ...state,
        error: action.error,
        unitIsLoading: false,
      };
    case TRANSFER_SELECTED_UNIT_PM_FAILURE:
      return {
        ...state,
        error: "Error Transfering Unit to new PM",
        unitIsLoading: false,
      };
    case TRANSFER_SELECTED_UNIT_OWNER_REQUEST:
      return {
        ...state,
        error: null,
        unitIsLoading: true,
      };
    case TRANSFER_SELECTED_UNIT_OWNER_RESPONSE:
      return {
        ...state,
        error: action.error,
        unitIsLoading: false,
      };
    case TRANSFER_SELECTED_UNIT_OWNER_FAILURE:
      return {
        ...state,
        error: "Error Transferring Unit to new Owner",
        unitIsLoading: false,
      };

    case GET_UNIT:
      return {
        ...state,
        error: null,
        unitIsLoading: action.isLoading,
      };
    case GET_UNIT_RESPONSE:
      data.unit = action.unit;
      return {
        ...state,
        data: data,
        error: action.error,
        unitIsLoading: false,
      };
    case POST_UNIT:
      return {
        ...state,
        error: null,
        unitIsLoading: action.isLoading,
      };
    case POST_UNIT_RESPONSE:
      return {
        ...state,
        data: data,
        error: action.error,
        unitIsLoading: false,
      };
    case POST_UNIT_FAIL:
      return {
        ...state,
        data: data,
        postUnitError: action.error,
        unitIsLoading: false,
      };
    case PUT_UNIT:
      return {
        ...state,
        error: null,
        unitIsLoading: action.isLoading,
      };
    case PUT_UNIT_RESPONSE:
      return {
        ...state,
        data: data,
        error: action.error,
        unitIsLoading: false,
      };
    case PUT_UNIT_FAIL:
      return {
        ...state,
        data: data,
        putUnitError: action.error,
        unitIsLoading: false,
      };
    case GET_CURRENT_OCCUPANT_REQUEST:
      return{
        ...state,
        isLoading: action.isLoading,
      }
    case GET_CURRENT_OCCUPANT_RESPONSE:
      return {
        ...state,
        isLoading: action.isLoading,
        currentOccupant: action.currentOccupant
      }
    case GET_CURRENT_OCCUPANT_FAIL:
      return {
        ...state,
        isLoading: action.isLoading,
        currentOccupantError: action.error
      }
    case GET_ALL_UNIT_OCCUPANTS_REQUEST:
      return {
        ...state,
        isLoading: action.isLoading
      }
    case GET_ALL_UNIT_OCCUPANTS_RESPONSE:
      state.allOccupants.items = [...action.data];

      return {
        ...state,
        isLoading: action.isLoading,
        allOccupants: state.allOccupants
       
      }
    case GET_ALL_UNIT_OCCUPANTS_FAIL:
      return {
        ...state,
        isLoading: action.isLoading,
        error: action.error
      }
    case UPDATE_CURRENT_OCCUPANT_REQUEST:
      return{
        ...state,
        isLoading: action.isLoading,
        error: action.error
      }
    case UPDATE_CURRENT_OCCUPANT_RESPONSE:
      return{
        ...state,
        isLoading: action.isLoading,
        error: action.error
      }
    case UPDATE_CURRENT_OCCUPANT_FAIL:
      return{
        ...state,
        isLoading: action.isLoading,
        error: action.error
      }
    case DELETE_UNIT_REQUEST:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case DELETE_UNIT_RESPONSE:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case DELETE_UNIT_FAIL:
      return {
        ...state,
        isLoading: action.isLoading,
        error: action.error,
      };

    default:
      return {
        ...state,
      };
  }
};
