//React Components and Hooks
import {
  CircularProgress as MuiCircularProgress,
  Divider as MuiDivider,
  Grid,
  Typography as MuiTypography,
} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
//Material UI Components and Functions
import { makeStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Skeleton from "@material-ui/lab/Skeleton";
import { spacing } from "@material-ui/system";
import moment from "moment";
// Redux Components
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import SimpleStatCard from "../../components/cards/SimpleStatCard"; // The stat cards displaying simple analytic numbers
import StandardHeader from "../../components/global/StandardHeader";
// Custom application component imports:
import { LeftSidebar as NavFrame } from "layout-blueprints"; // The top navigation bar and side navigation panel
import RecentAccountsTable from "../../modules/accountsSearch/components/RecentAccountsViewedTable";
import {
  getAllPropertyPreviews,
  getAnalyticalData,
} from "../../modules/apl/api/actions";
import DueNotesTable from "../../modules/notes/components/DueNotesTable";
import PropertiesOverviewMap from "../../modules/property/components/PropertiesOverviewMap";
import { logout } from "../../modules/user/api/actions";
// Helper methods:
import { formatMoney } from "../../setup/helpers";
import AnalyticsRange_DD from "./AnalyticsRange_DD"; // Dropdown to set the scope of the analytics data
import { LeftSidebar } from "layout-blueprints";
import { PageTitle } from "layout-components";
const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  percentDif: {
    textAlign: "right",
    color: "green",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  loadingIcon: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  padding: {
    paddingTop: 10,
  },
}));

// Since everything is retrieved in one query, this number will represent how many
// loading boxes to insert if apl overview data is still loading
const DashboardAnalyticItemsCount = 7;

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return <div {...other}>{value === index && <Box>{children}</Box>}</div>;
}

const DashboardPage = (props) => {
  const classes = useStyles();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [properties, setProperties] = useState([]);
  const [dateTime, setCurrentDateTime] = useState("");

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const logout = () => {
    props.logout();
    props.history.history.push("/");
  };

  const getCurrentDateTime = () => {
    const display = moment().format("HH:mm:ss · MMM Do YY");
    setCurrentDateTime(display);
  };
  // On initial page load:
  useEffect(() => {
    document.title = "Ubil+ Overview Dashboard";
    const interval = setInterval(() => {
      getCurrentDateTime();
    }, 1000);
    props.getAllPropertyPreviews();
    props.getAnalyticalData(props.apl.analyticRange);
  }, [props.user]);

  // The item we want to wait on to show the analytical data
  return (
    <LeftSidebar>
      <PageTitle
        titleHeading="Ubil +"
        titleDescription="System Overview"
      />

      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={4} lg={2}>
          <SimpleStatCard
            title="Active Accounts"
            amount={
              props.apl.analyticSummary?.accountsSummary
                ? props.apl.analyticSummary?.accountsSummary.summary
                  .activeAccounts || 0
                : "N/A"
            }
            chip={"Currently"}
            isLoaded={
              !props.apl.isLoading && !props.apl.analyticSummaryIsLoading
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={2}>
          <SimpleStatCard
            title="Properties Billed"
            amount={
              props.apl.analyticSummary?.billingSummary
                ? props.apl.analyticSummary?.billingSummary.summary
                  .propertiesBilled || 0
                : "N/A"
            }
            chip={props.apl.analyticRange}
            isLoaded={
              !props.apl.isLoading && !props.apl.analyticSummaryIsLoading
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={2}>
          <SimpleStatCard
            title="Bills Sent"
            amount={
              props.apl.analyticSummary?.billingSummary
                ? props.apl.analyticSummary?.billingSummary.summary.bills ||
                0
                : "N/A"
            }
            chip={props.apl.analyticRange}
            isLoaded={
              !props.apl.isLoading && !props.apl.analyticSummaryIsLoading
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={2}>
          <SimpleStatCard
            title="New Customers"
            amount={
              props.apl.analyticSummary?.accountsSummary
                ? props.apl.analyticSummary?.accountsSummary.summary
                  .newAccounts || 0
                : "N/A"
            }
            chip={props.apl.analyticRange}
            isLoaded={
              !props.apl.isLoading && !props.apl.analyticSummaryIsLoading
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={2}>
          <SimpleStatCard
            title="Move Outs"
            amount={
              props.apl.analyticSummary?.accountsSummary
                ? props.apl.analyticSummary?.accountsSummary.summary
                  .moveOuts || 0
                : "N/A"
            }
            chip={props.apl.analyticRange}
            isLoaded={
              !props.apl.isLoading && !props.apl.analyticSummaryIsLoading
            }
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <AppBar position="static">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
            >
              <Tab label="Notes/Account History" />
              <Tab disabled label="Property Overview Map" />
              <Tab label="Calendar" />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <Grid container spacing={6} xs={12} className={classes.padding}>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <DueNotesTable />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <RecentAccountsTable />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <PropertiesOverviewMap
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDmVWMr5vZTfXCQ8NNWSBPHg2mSW2ws3EY&v=3.exp&libraries=geometry,drawing,places"
              loadingElement={
                <Skeleton
                  variant="rect"
                  height="500px"
                  style={{ backgroundColor: `#bdbfbe`, padding: 6, margin: 6 }}
                />
              }
              containerElement={<div style={{ height: `500px` }} />}
              mapElement={<div style={{ height: `100%` }} />}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Grid container spacing={6} xs={12} className={classes.padding}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <iFrame
                  src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23F6BF26&amp;ctz=America%2FNew_York&amp;src=dWJpbHBsdXNAZ21haWwuY29t&amp;color=%23039BE5&amp;title=Ubil%2B&amp;showTz=0&amp;showNav=1&amp;showTitle=1&amp;showPrint=0&amp;showDate=1"
                  style={{ height: `500px`, width: `100%` }}
                ></iFrame>
              </Grid>
            </Grid>
          </TabPanel>
        </Grid>
      </Grid>
    </LeftSidebar>
  );

  //   // On Data Loaded:
  //   return (
  //     <NavFrame page={"Dashboard"}>
  //       <StandardHeader title="System Overview" rightSideToolBar={<AnalyticsRange_DD />}/>
  //         <Divider className="mb-2 mt-2" my={3}/>
  //         <Grid container spacing={6}>
  //           <Grid item xs={12} lg={12}>
  //             <Grid container spacing={6}>
  //               <Grid item xs={12} sm={12} md={6} lg={3}>
  //                 <SimpleStatCard
  //                   title="Cash Received"
  //                   amount={formatMoney(props.apl.data.amountPaid)||"Unknown"}
  //                   chip={props.apl.analyticRange}
  //                 />
  //               </Grid>
  //               <Grid item xs={12} sm={12} md={6} lg={3}>
  //                 <SimpleStatCard
  //                   title="Properties Billed"
  //                   amount={props.apl.data.propertiesBilled||"Unknown"}
  //                   chip={props.apl.analyticRange}
  //                 />
  //               </Grid>
  //               <Grid item xs={12} sm={12} md={6} lg={6}>
  //                 <SimpleStatCard
  //                   title="Balance Past Due"
  //                   amount={formatMoney(props.apl.data.totalPastDueBalance)||"Unknown"}
  //                   chip={props.apl.analyticRange}
  //                 />
  //               </Grid>
  //               <Grid item xs={12} sm={12} md={6} lg={3}>
  //                 <SimpleStatCard
  //                   title="Bills Sent"
  //                   amount={props.apl.data.totalBillSent||"Unknown"}
  //                   chip={props.apl.analyticRange}
  //                 />
  //               </Grid>
  //               <Grid item xs={12} sm={12} md={6} lg={3}>
  //                 <SimpleStatCard
  //                   title="New Customers"
  //                   amount={props.apl.data.totalNewCustomers||"Unknown"}
  //                   chip={props.apl.analyticRange}
  //                 />
  //               </Grid>
  //               <Grid item xs={12} sm={12} md={6} lg={3}>
  //                 <SimpleStatCard
  //                   title="Open Disconnects"
  //                   amount={props.apl.data.totalDisconnects||"Unknown"}
  //                   chip={props.apl.analyticRange}
  //                 />
  //               </Grid>
  //               <Grid item xs={12} sm={12} md={6} lg={3}>
  //                 <SimpleStatCard
  //                   title="Move Outs"
  //                   amount={props.apl.data.totalMoveOuts||"Unknown"}
  //                   chip={props.apl.analyticRange}
  //                 />
  //               </Grid>
  //             </Grid>
  //           </Grid>
  //         </Grid>
  //       </NavFrame>
  //   );
};

// Component Properties
DashboardPage.propTypes = {
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  getAnalyticalData: PropTypes.func.isRequired,
  getAllPropertyPreviews: PropTypes.func.isRequired,
};

// Component State
function DashboardPageState(state) {
  return {
    user: state.user,
    apl: state.apl,
  };
}
export default connect(DashboardPageState, {
  logout,
  getAnalyticalData,
  getAllPropertyPreviews,
})(DashboardPage);
// this is the analytics route:    /analytics?startDate=2020-04-27
// this is the moment call: moment.utc().subtract(1, 'month').format("YYYY-MM-DD HH:mm:ss")
