import {
  Card as MuiCard,
  CircularProgress as MuiCircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  TextField as MuiTextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Search as SearchIcon } from "@material-ui/icons";
import { spacing } from "@material-ui/system";
// Redux Components
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components/macro";
import { EditableTable } from "../../../components/EditableTable";
import { deleteBill, get as getAccountBills, getUserBills } from "../api/actions";
import BillForm from "./BillForm";
import StatusTag from "modules/apl/components/StatusTag";
import { regenerateBillPdf } from "../../bills/api/actions"
import { setSnackbar } from "../../snackbar/api/snackbar";
const CircularProgress = styled(MuiCircularProgress)(spacing);
const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function Table(props) {
  const useStyles = makeStyles({
    active: {
      fontWeight: "bold",
      color: "#f9a825",
      borderColor: "#f9a825",
    },
    inactive: {
      fontWeight: "bold",
    },
  });

  const canDelete = (props.user.details.administration || props.user.details.isSuperUser) ? true : false ;
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchQuery, setSearchQuery] = React.useState(""); // should be all thats needed to reduce the disconnects shown to only that accounts
  const [property, setProperty] = React.useState("");
  const [resetCachedData, setResetCachedData] = React.useState(true);

  const dispatch = useDispatch();

  const [billFormOpen, setBillFormOpen] = React.useState(false);

  const handleBillFormClickOpen = () => {
    setBillFormOpen(true);
  };

  const handleBillFormClose = () => {
    setBillFormOpen(false);
  };

  const handleBillFormUpdate = () => {
    setBillFormOpen(false);
    getAccountBills();
  };

  const onBillSelected = (item) => {
    window.open(item.url);
  };

  const onBillGenerated = async (item) => {
    try {
      dispatch(setSnackbar(true, "warning", "Generating printed bill."));
      let response = await props.regenerateBillPdf(item.uuid);
      
      if(response?.pdf) {
        dispatch(setSnackbar(true, "success", "Bill Regenerated"));
        let url = response.pdf.url
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
      }
      else {
        dispatch(setSnackbar(true, "error", "Bill failed to generate. Retry."));
      }
      getAccountBills()
    } catch (e) {
      dispatch(setSnackbar(true, "error", "Bill failed to generate. Retry."));
    }

  };

  const createNewBill = () => {
    //props.resetSelectedAccountObject();
    //navTo("/billingwizard");
    handleBillFormClickOpen();
  };

  const CurrentLookupTypeButton = () => {
    return (
      <IconButton>
        <SearchIcon mr={2} />
      </IconButton>
    );
  };


  // Table Stuff:
  // Account Holder 	Address 	Meter Info 	Letter Sent Dates 	Disconnect Date 	Last Paid 	Disconnect Amt 	Account Balance

  const fieldTitles = [
    "ID",
    "Type/Bill Date",
    "Preview",
    "Due Date",
    "Usage",
    "New Charges",
    "Total"
  ];
  const fieldTypes = [
    ["label", "label"],
    ["label", "dateLabelNoTime"],
    "billPdfBtn",
    "dateLabelNoTime",
    "label",
    "moneyLabel",
    "moneyLabel",
  ];
  const fieldNames = [
    ["id", "amtPaidFlag"],
    ["type", "billDate"],
    "",
    "dueDate",
    "usage",
    "newCharges",
    "total"
  ];

  const handleChangeRowsPerPage = (rowsPerPage) => {
    setResetCachedData(true);
    setRowsPerPage(rowsPerPage);
  };
  const deleteSelectedAccountBills = (bills) => {
    let error = false;
    bills.map((billUuid, index) => {
      props.deleteBill(billUuid).then(resp => {
          if(props.bills.error) {
            error = true;
          }
      });
    });


      props.getAccountBills(
        page,
        rowsPerPage,
        props.account.initialAccount.uuid,
        true,
        resetCachedData
      );


  };

  const handleChangePage = (page) => {
    setResetCachedData(false);
    setPage(page);
  };

  const handleSearchQueryInput = (event) => {
    setPage(0);
    setResetCachedData(true);
    setSearchQuery(event.target.value);
  };

  const getAccountBills = () => {
    if (props.accountUuid && props.accountUuid != null) {
      props.getAccountBills(
        page,
        rowsPerPage,
        props.accountUuid,
        true,
        resetCachedData
      );
    }
    else {
      if (!props.account.isInitialAccountLoading && props.account.initialAccount != null) {
        props.getAccountBills(
          page,
          rowsPerPage,
          props.account.initialAccount.uuid,
          true,
          resetCachedData
        );
      }
    }
  };

  useEffect(() => {
    if (
      (page + 1) * rowsPerPage > props.bills.data.items.length ||
      resetCachedData
    ) {
      getAccountBills();
    }
  }, [page, rowsPerPage, props.account.isInitialAccountLoading]);

  const accountUrl = (item) => {
    return `/accountmanager/${item.accountNum}/${item.accountUuid}`;
  };

  const urlFields = [];

  const conditionalStyling = (item) => {

    if (item.amtPaid == item.newCharges) {
      item.amtPaidFlag = <StatusTag type={"Success"} label={`Paid`} condensed={true} />
    }

    if (item.amtPaid > 0 && item.amtPaid != item.newCharges) {
      item.amtPaidFlag = <StatusTag type={"Warning"} label={`Partial`} condensed={true} />
    }

    return {};
  };

  const IsAbleToDeleteBill = (bill) => {
    if (bill.amtPaid > 0) {
      return false;
    }
    return true;

  }

  return (
    <>
      <EditableTable
        readOnly={!canDelete}
        canAddItems={canDelete}
        canSave={false}
        conditionalStylingMethod={conditionalStyling}
        manualItemDeletionMethod={deleteSelectedAccountBills}
        isLoading={props.bills.isLoading}
        tableTitle={"Bill History"}
        objectArray={props.bills.data}
        fieldTitles={fieldTitles}
        fieldTypes={fieldTypes}
        fieldNames={fieldNames}
        canExport={true}
        objectError={props.bills.error}
        verifyDelete={true}
        customUrlFields={urlFields}
        newItemCreationMethod={createNewBill}
        //handleRowClickParentMethod={onBillSelected}
        customChangePageRowsEvent={handleChangeRowsPerPage}
        handlePreviewClickParentMethod={onBillSelected}
        handleGenerateClickParentMethod={onBillGenerated}
        customHandleChangePageEvent={handleChangePage}
        totalRowCount={props.bills.accountBillsTotalRows}
        customOnDeleteValidationMethod={IsAbleToDeleteBill}
      />
      <Dialog
        open={billFormOpen}
        TransitionComponent={Transition}
        fullWidth={"lg"}
        maxWidth={"lg"}
        keepMounted
        onClose={handleBillFormClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        className={classes.newbillDialog}
      >
        {props.account.data.account ? (
          <DialogTitle id="alert-dialog-slide-title">
            New Bill for Account #{props.account.data.account.accountNum}{" "}
          </DialogTitle>
        ) : (
          <DialogTitle id="alert-dialog-slide-title">
            New Bill for Account: LOADING.....{" "}
          </DialogTitle>
        )}
        <DialogContent>
          <BillForm
            onUpdate={handleBillFormUpdate}
            onCancel={handleBillFormClose}
            accountNum={
              props.account.data.account
                ? props.account.data.account.accountNum
                : -1
            }
            getUserBills={getAccountBills}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
// Component accountDisconnects
Table.propTypes = {
  // Store objects:
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  bills: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  getAccountBills: PropTypes.func.isRequired,
  deleteBill: PropTypes.func.isRequired,
  regenerateBillPdf: PropTypes.func.isRequired
};

// Component State
function State(state) {
  return {
    user: state.user,
    apl: state.apl,
    bills: state.bills,
    account: state.account,
  };
}
export default connect(State, { getAccountBills, deleteBill, regenerateBillPdf })(Table);
