import axios from "axios";
import { setSnackbar } from "../../../modules/snackbar/api/snackbar";
import { GET, DEL } from "../../genericAPI/api/actions";


export const GET_WORK_ORDERS_REQUEST = "WORKORDER/GET_WORK_ORDERS_REQUEST";
export const GET_WORK_ORDERS_RESPONSE = "WORKORDER/GET_WORK_ORDERS_RESPONSE";
export const GET_WORK_ORDERS_FAILURE = "WORKORDER/GET_WORK_ORDERS_FAILURE";

export const GET_UNIT_WORK_ORDERS_REQUEST = "GET_UNIT_WORK_ORDERS_REQUEST";
export const GET_UNIT_WORK_ORDERS_RESPONSE = "GET_UNIT_WORK_ORDERS_RESPONSE";
export const GET_UNIT_WORK_ORDERS_FAILURE = "GET_UNIT_WORK_ORDERS_FAILURE";


export const UPDATE_WORK_ORDERS_REQUEST =
  "WORKORDER/UPDATE_WORK_ORDERS_REQUEST";
export const UPDATE_WORK_ORDERS_RESPONSE =
  "WORKORDER/UPDATE_WORK_ORDERS_RESPONSE";
export const UPDATE_WORK_ORDERS_FAILURE =
  "WORKORDER/UPDATE_WORK_ORDERS_FAILURE";

export const CREATE_WORK_ORDER_REQUEST =
  "WORKORDER/CREATE_WORK_ORDER_REQUEST";
export const CREATE_WORK_ORDER_RESPONSE =
  "WORKORDER/CREATE_WORK_ORDER_RESPONSE";
export const CREATE_WORK_ORDER_FAILURE =
  "WORKORDER/CREATE_WORK_ORDER_FAILURE";


export const DELETE_WORK_ORDER_REQUEST = "WORKORDER/DELETE_WORK_ORDER_REQUEST";
export const DELETE_WORK_ORDER_RESPONSE = "WORKORDER/DELETE_WORK_ORDER_RESPONSE";
export const DELETE_WORK_ORDER_FAILURE = "WORKORDER/DELETE_WORK_ORDER_FAILURE";

export const SET_UNIT_WORK_ORDERS = "WORKORDER/SET_UNIT_WORK_ORDERS";


export function setUnitWorkOrders(newUnitWorkOrders) {
  return { type: SET_UNIT_WORK_ORDERS, newUnitWorkOrders };
}

export function getWorkOrdersByUnit(unitId) {
  return GET("workorder/by-unit", unitId, GET_UNIT_WORK_ORDERS_REQUEST, GET_UNIT_WORK_ORDERS_RESPONSE, GET_UNIT_WORK_ORDERS_FAILURE);
}
export function deleteWorkOrder(uuid) {
  return DEL("workorder", uuid, DELETE_WORK_ORDER_REQUEST, DELETE_WORK_ORDER_RESPONSE, DELETE_WORK_ORDER_FAILURE);
}

export function getWorkOrders(isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: GET_WORK_ORDERS_REQUEST,
      isLoading: isLoading,
    });
    return axios
      .get(`/workorders`)
      .then((response) => {
        let error = "";
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }

        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem("user"));
        if (currentUser) {
          response.data.sort(function (a, b) {
            return b.id - a.id;
          });

          dispatch({
            type: GET_WORK_ORDERS_RESPONSE,
            isLoading: false,
            response: response.data,
            error: error,
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: GET_WORK_ORDERS_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          });
        }
      })
      .catch((error) => {
        dispatch(setSnackbar(true, "error", `Failed to retrive work orders: ${error.toString()}.`));
        dispatch({
          type: GET_WORK_ORDERS_FAILURE,
          isLoading: false,
          error: error,
        });
      });
  };
}

export function updateWorkOrder(uuid, body, isLoading = true) {
  body.uuid = uuid;
  return (dispatch) => {
    dispatch({
      type: UPDATE_WORK_ORDERS_REQUEST,
      isLoading: isLoading,
    });
    return axios
      .put(`/workorder/${uuid}`, body)
      .then((response) => {
        let error = "";

        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }

        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem("user"));
        if (currentUser) {
          dispatch({
            type: UPDATE_WORK_ORDERS_RESPONSE,
            isLoading: false,
            response: response.data,
            error: error,
          });
          dispatch(setSnackbar(true, "success", `Successfully updated work order: ${response.data.workOrderTitle}.`));

        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: UPDATE_WORK_ORDERS_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_WORK_ORDERS_FAILURE,
          isLoading: false,
          error: error,
        });
        dispatch(setSnackbar(true, "error", `Failed to update work order.`));

      });
  };
}

export function createWorkOrder(body, isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: CREATE_WORK_ORDER_REQUEST,
      isLoading: isLoading,
    });
    return axios
      .post(`/workorder`, body)
      .then((response) => {

        if (response.status == 200) {
          dispatch(setSnackbar(true, "success", `Successfully created work order: ${body.workOrderTitle}.`));
          dispatch({
            response: response.data,
            type: CREATE_WORK_ORDER_RESPONSE,
          });
        }

      })
      .catch((error) => {
        console.log("error");
        console.log(error);
        dispatch(setSnackbar(true, "error", `Failed to create work order.`));
        dispatch({
          type: CREATE_WORK_ORDER_FAILURE,
          isLoading: false,
          error: error,
        });
      });
  };
}
