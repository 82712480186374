import { Box, Card, CardContent, Chip, Typography } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import React from "react";

const SimpleStatCard = ({
  title,
  amount,
  chip,
  percentageText,
  percentagecolor,
  isLoaded,
}) => {
  return (
    <Card>
      <CardContent>
        <Typography variant="h6" mb={4}>
          {title}
        </Typography>
        <Typography variant="h3" mb={2}>
          {isLoaded ? (
            <Box fontWeight="fontWeightRegular">{amount}</Box>
          ) : (
            <Skeleton
              variant="rect"
              height="60px"
              style={{ backgroundColor: `#bdbfbe`, padding: 6, margin: 6 }}
            />
          )}
        </Typography>
        {percentagecolor && percentageText ? (
          <Typography
            variant="subtitle2"
            mb={6}
            color="textSecondary"
            percentagecolor={percentagecolor}
          >
            <span>{percentageText}</span> Since last week
          </Typography>
        ) : (
          <br />
        )}
        {chip && <Chip label={chip} />}
      </CardContent>
    </Card>
  );
};

export default SimpleStatCard;
