import React, { useEffect } from "react";
import styled, { withTheme } from "styled-components/macro";
import { spacing } from "@material-ui/system";
import {
  CircularProgress as MuiCircularProgress,
  Paper,
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card,
  CardContent,
  Chip,
  Divider as MuiDivider,
  Grid,
  LinearProgress as MuiLinearProgress,
  Link,
  Table,
  TableBody,
  TableCell,
  Checkbox,
  TableHead,
  TableRow,
  Typography,
  Switch,
} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Skeleton from "@material-ui/lab/Skeleton";
import EditIcon from "@material-ui/icons/Edit";
import style from "./styling/style";
import PropertyForm from "./Form";
import { getByUuid as getPropertyByUuid} from '../../../modules/property/api/actions'

const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function DetailCard(props) {
  const useStyles = style;
  const classes = useStyles();

  const [display, setDisplay] = React.useState(false);

  const canEdit = (props.user.details.billing || props.user.details.administration || props.user.details.isSuperUser) ? true : false;

  const toggleDisplay = () => {
    const shouldDisplay = !display;
    setDisplay(shouldDisplay);
  };

  useEffect(()=>{
    let propertyUuid = props.match?.params.uuid;
   props.getPropertyByUuid(propertyUuid);
  }, [])

  return (
    <Card mb={6}>
      <CardContent xs={12}>
        <Grid container xs={12}>
          <Grid item xs={9} sm={9} md={9} lg={9}>
            <Typography variant="h6" gutterBottom>
              Property Info
            </Typography>
          </Grid>
          <Grid
            item
            xs={3}
            sm={3}
            md={3}
            lg={3}
            className={classes.rightSideHeaderSubMenuToolBar}
          >
            <Button onClick={toggleDisplay}>
              <EditIcon 
                hidden={!canEdit}
                fontSize="medium"
                className={classes.SubMenuToolBarIcon}
              />
            </Button>
            <Dialog
              open={display}
              TransitionComponent={Transition}
              fullWidth={"lg"}
              maxWidth={"lg"}
              keepMounted
              onClose={toggleDisplay}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              {props.properties.data.property ? (
                <DialogTitle id="alert-dialog-slide-title">
                  Property: {props.properties.data.property.name}{" "}
                </DialogTitle>
              ) : (
                <DialogTitle id="alert-dialog-slide-title">
                  Loading Property.....{" "}
                </DialogTitle>
              )}
              <DialogContent>
                <PropertyForm
                  onUpdate={toggleDisplay}
                  onCancel={toggleDisplay}
                />
              </DialogContent>
            </Dialog>
          </Grid>
        </Grid>
        <Divider className="mb-2 mt-2" my={1} />
        {props.properties.propertyIsLoading ? (
          <>
            <Skeleton
              variant="rect"
              height="40px"
              style={{
                backgroundColor: `#bdbfbe`,
                padding: 6,
                margin: 6,
                marginBottom: 3,
              }}
            />
            <Skeleton
              variant="rect"
              height="40px"
              style={{
                backgroundColor: `#bdbfbe`,
                padding: 6,
                margin: 6,
                marginBottom: 3,
              }}
            />
            <Skeleton
              variant="rect"
              height="40px"
              style={{
                backgroundColor: `#bdbfbe`,
                padding: 6,
                margin: 6,
                marginBottom: 3,
              }}
            />
            <Skeleton
              variant="rect"
              height="40px"
              style={{
                backgroundColor: `#bdbfbe`,
                padding: 6,
                margin: 6,
                marginBottom: 3,
              }}
            /> <Skeleton
              variant="rect"
              height="40px"
              style={{
                backgroundColor: `#bdbfbe`,
                padding: 6,
                margin: 6,
                marginBottom: 3,
              }}
            />
          </>
        ) : (
          <Grid
            container
            spacing={2}
            lg={12}
            className={classes.PropertyInfoGrid}
          >
            <Grid item xs={12} lg={12} spacing={2}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1"> Name </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {props.properties.data.property.name || "N/A"}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1"> Address </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {props.properties.data.property.address || "Address not provided "}
                          <br />
                          {`${props.properties.data.property.city || ''}, ${props.properties.data.property.state || ''} ${props.properties.data.property.zip || ''}`}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {/*<TableRow>*/}
                    {/*  <TableCell component="th" scope="row">*/}
                    {/*    <Typography variant="body1"> Owner </Typography>*/}
                    {/*  </TableCell>*/}
                    {/*  <TableCell>*/}
                    {/*    <Typography variant="body2">*/}
                    {/*      {props.properties.data.property.owner*/}
                    {/*        ? props.properties.data.property.owner.name*/}
                    {/*        : "N/A" || "N/A"}*/}
                    {/*    </Typography>*/}
                    {/*  </TableCell>*/}
                    {/*</TableRow>*/}
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1"> Developer </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {props.properties.data.property.developer
                            ? props.properties.data.property.developer.name
                            : "N/A" || "N/A"}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1"> Property Manager </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {props.properties.data.property.propertyManager
                            ? props.properties.data.property.propertyManager.name
                            : "N/A" || "N/A"}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    {/* <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1"> Owner </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {props.properties.data.property.owner
                            ? props.properties.data.property.owner.name
                            : "N/A" || "N/A"}{" "}
                        </Typography>
                      </TableCell>
                    </TableRow> */}

                    {/*<TableRow>*/}
                    {/*  <TableCell component="th" scope="row">*/}
                    {/*    <Typography variant="body1"> Remote </Typography>*/}
                    {/*  </TableCell>*/}
                    {/*  <TableCell>*/}
                    {/*    <Checkbox*/}
                    {/*      checked={*/}
                    {/*        props.properties.data.property.isRemote == 1*/}
                    {/*          ? true*/}
                    {/*          : false*/}
                    {/*      }*/}
                    {/*      disabled={true}*/}
                    {/*      inputProps={{ "aria-label": "primary checkbox" }}*/}
                    {/*    />*/}
                    {/*  </TableCell>*/}
                    {/*</TableRow>*/}
                    {/*<TableRow>*/}
                    {/*  <TableCell component="th" scope="row">*/}
                    {/*    <Typography variant="body1"> Status </Typography>*/}
                    {/*  </TableCell>*/}
                    {/*  <TableCell>*/}
                    {/*    <Typography variant="body2">*/}
                    {/*      {props.properties.data.property.status*/}
                    {/*        ? props.properties.data.property.status.description*/}
                    {/*        : "N/A" || "N/A"}*/}
                    {/*    </Typography>*/}
                    {/*  </TableCell>*/}
                    {/*</TableRow>*/}

                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1"> Updated </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {new Date(
                            props.properties.data.property.updated
                          ).toLocaleDateString() || "N/A"}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
}

DetailCard.propTypes = {
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  properties: PropTypes.object.isRequired,
  units: PropTypes.object.isRequired,
  propertyGLCode: PropTypes.object.isRequired,
  getPropertyByUuid: PropTypes.func.isRequired,
};

function DetailCardState(state) {
  return {
    user: state.user,
    apl: state.apl,
    properties: state.properties,
    units: state.units,
    propertyGLCode: state.propertyGLCode
  };
}

export default connect(DetailCardState, {getPropertyByUuid})(DetailCard);
