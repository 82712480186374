import {
  Card as MuiCard,
  CardContent,
  CircularProgress as MuiCircularProgress,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core";
//Material UI Components and Functions
import { makeStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
// Redux Components
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import { formatMoney } from "../../../setup/helpers";
import { updateCurrentAccountInDB } from "../../account/api/actions";
import { getAllCommonChargeRelatedDataByPropertyId } from "../../property/api/actions";

const CenteredContent = styled.div`
  text-align: center;
`;

const CircularProgress = styled(MuiCircularProgress)(spacing);
const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const FormControl = styled(MuiFormControl)(spacing);
const TextField = styled(MuiTextField)(spacing);
const SpacedCurrencyTextField = styled(CurrencyTextField)(spacing);

// Custom Styles for property selection drop down

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  loadingIcon: {
    position: "relative",
    left: "50%",
    top: "50%",
  },
  typography: {
    flexGrow: 1,
    textAlign: "center",
  },
}));

const PropertyCommonAreaCalculatorForm = (props) => {
  // Handles vendor drop down

  const [propertyId, setPropertyId] = React.useState(props.propertyId);
  const classes = useStyles();

  useEffect(() => {
    props.getAllCommonChargeRelatedDataByPropertyId(propertyId);
    // Should load in if initial loading hasnt teken place yet
  }, [propertyId]);

  if (
    props.properties.propertyResidualAmountsToAddIsLoading ||
    props.properties.propertyAdditionalCommonAreaExpensesIsLoading
  ) {
    return (
      <Card mb={6} elevation={3}>
        <CardContent>
          <Grid container spacing={4}>
            <Grid item lg={12} md={12} sm={12}>
              <Divider className="mb-2 mt-2" my={1} />
            </Grid>
            <Grid item lg={12} md={12} sm={12}>
              <Typography variant="h5">
                Loading Property Common Charges
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12}>
              <CircularProgress disableShrink className={classes.loadingIcon} />
            </Grid>
            <Grid item lg={12} md={12} sm={12}>
              <Divider className="mb-2 mt-2" my={1} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }

  // if loading done we count up the common charge totals
  let runningCommonExpenses = [];
  let activeResidualAmounts = [];
  let total = 0;
  let kwhTotal = 0;
  props.properties.propertyAdditionalCommonAreaExpenses.items.map(
    (CommonAreaExpense) => {
      if (CommonAreaExpense.active) {
        runningCommonExpenses.push(CommonAreaExpense);
        total = total + CommonAreaExpense.expenseValue;
      }
    }
  );
  let today = new Date();
  let currentMonth = today.getMonth() + 1;
  let currentYear = today.getFullYear();
  /*

  console.log("totalUnits for division");
  console.log(props.properties.data.property.totalUnits);
  console.log("extra perunit expense total");
  console.log(total);

  console.log("props.properties.propertyResidualAmountsToAdd.items");
  console.log(props.properties.propertyResidualAmountsToAdd.items);

  */
  props.properties.propertyResidualAmountsToAdd.items.map((RollOverItem) => {
    let remainingUSD = RollOverItem.cost || 0;
    let remainingKWH = RollOverItem.kwh || 0;
    if (RollOverItem.transactions) {
      RollOverItem.transactions.map((transaction) => {
        remainingUSD = remainingUSD - transaction.costUsed;
        remainingKWH = remainingKWH - transaction.kwhUsed;
        if (
          transaction.fiscalMonth == currentMonth &&
          transaction.fiscalYear == currentYear
        ) {
          let newTransaction = {
            rollOverId: RollOverItem.id,
            description: RollOverItem.description,
            createdDate: RollOverItem.created,
            transaction: transaction,
          };
          activeResidualAmounts.push(newTransaction);
          /*
          console.log("transaction.costUsed");
          console.log(transaction.costUsed);
          */
          if (transaction.costUsed != null) {
            total = total + (transaction.costUsed / props.properties.data.property.totalUnits);
          }
          if (transaction.kwhUsed != null) {
            kwhTotal = kwhTotal + (transaction.kwhUsed / props.properties.data.property.totalUnits);
          }
        }
      });
      activeResidualAmounts.map((activeResidual) => {
        if (RollOverItem.id == activeResidual.rollOverId)
          activeResidual.remainingUSD = remainingUSD;
        activeResidual.remainingKWH = remainingKWH;
      });
    }
  });

  //console.log("overall common expense total");
  //console.log(total);
  kwhTotal = kwhTotal.toFixed(4);
  return (
    <Card mb={6} elevation={3}>
      <CardContent>
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12}>
            <Divider className="mb-2 mt-2" my={1} />
          </Grid>
          <Grid item lg={12} md={12} sm={12}>
            <Typography variant="h6">
              Total Property Commons Charge Value (Per Billing Cycle):{" "}
              {formatMoney(
                total * (props.properties.data.property.totalUnits || 1)
              )}
            </Typography>
          </Grid>
          <Grid item lg={6} md={12} sm={12}>
            <Typography variant="h6">
              Per Unit Total: {formatMoney(total)}
            </Typography>
          </Grid>
          <Grid item lg={6} md={12} sm={12}>
            <Typography variant="h6">
              Per Unit kWh Roll Over Total: {kwhTotal}
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12}>
            <Divider className="mb-2 mt-2" my={1} />
          </Grid>
          {runningCommonExpenses.length > 0 && (
            <>
              <Grid item lg={12} md={12} sm={12}>
                <Typography variant="h5">Active Common Expenses:</Typography>
              </Grid>
              <Grid item lg={8} md={8} sm={8}>
                <Typography variant="p">Name</Typography>
              </Grid>
              <Grid item lg={4} md={4} sm={4}>
                <Typography variant="p">Per Unit Cost</Typography>
              </Grid>
            </>
          )}
          {runningCommonExpenses.map((CommonAreaExpense, index) => {
            return (
              <>
                <Grid item lg={8} md={8} sm={8}>
                  <Typography variant="h6">
                    # {index + 1} {CommonAreaExpense.expenseName}
                  </Typography>
                </Grid>
                <Grid item lg={4} md={4} sm={4}>
                  <Typography variant="h6">
                    {formatMoney(CommonAreaExpense.expenseValue)}
                  </Typography>
                </Grid>
              </>
            );
          })}
          {activeResidualAmounts.length > 0 && (
            <>
              <Grid item lg={12} md={12} sm={12}>
                <Divider className="mb-2 mt-2" my={1} />
              </Grid>
              <Grid item lg={12} md={12} sm={12}>
                <Typography variant="h6">Active Rollover Charges:</Typography>
              </Grid>
              <Grid item lg={3} md={3} sm={3}>
                <Typography variant="p">Description</Typography>
              </Grid>
              <Grid item lg={3} md={3} sm={3}>
                <Typography variant="p">Transaction Value</Typography>
              </Grid>
              <Grid item lg={3} md={3} sm={3}>
                <Typography variant="p">Per Unit Cost</Typography>
              </Grid>
              <Grid item lg={3} md={3} sm={3}>
                <Typography variant="p">Remaining Cost</Typography>
              </Grid>
            </>
          )}
          {activeResidualAmounts.map((residualCharge, index) => {
            return (
              <>
                <Grid item lg={3} md={3} sm={3}>
                  <Typography variant="h6">
                    # {index + 1} {residualCharge.description}
                  </Typography>
                </Grid>
                <Grid item lg={3} md={3} sm={3}>
                  {residualCharge.transaction.costUsed != null &&
                    residualCharge.transaction.costUsed != 0 && (
                      <Typography variant="h6">
                        {formatMoney(residualCharge.transaction.costUsed)}
                      </Typography>
                    )}
                  {residualCharge.transaction.kwhUsed != null &&
                    residualCharge.transaction.kwhUsed != 0 && (
                      <Typography variant="h6">
                        kWh {residualCharge.transaction.kwhUsed}
                      </Typography>
                    )}
                </Grid>
                <Grid item lg={3} md={3} sm={3}>
                  {residualCharge.transaction.costUsed != null &&
                    residualCharge.transaction.costUsed != 0 && (
                      <Typography variant="h6">
                        {formatMoney(
                          residualCharge.transaction.costUsed /
                          (props.properties.data.property.totalUnits || 1)
                        )}
                      </Typography>
                    )}
                  {residualCharge.transaction.kwhUsed != null &&
                    residualCharge.transaction.kwhUsed != 0 && (
                      <Typography variant="h6">
                        kWh {residualCharge.transaction.kwhUsed.toFixed(4)}
                      </Typography>
                    )}
                </Grid>
                <Grid item lg={3} md={3} sm={3}>
                  {residualCharge.transaction.costUsed != null &&
                    residualCharge.transaction.costUsed != 0 && (
                      <Typography variant="h6">
                        {formatMoney(residualCharge.remainingUSD)}
                      </Typography>
                    )}
                  {residualCharge.transaction.kwhUsed != null &&
                    residualCharge.transaction.kwhUsed != 0 && (
                      <Typography variant="h6">
                        kWh {residualCharge.remainingKWH}
                      </Typography>
                    )}
                </Grid>
              </>
            );
          })}
        </Grid>
      </CardContent>
    </Card>
  );
};

// Component Properties
PropertyCommonAreaCalculatorForm.propTypes = {
  // Store objects:
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  billing: PropTypes.object.isRequired,
  properties: PropTypes.object.isRequired,
  getAllCommonChargeRelatedDataByPropertyId: PropTypes.func.isRequired,
  // Store object functions:
  updateCurrentAccountInDB: PropTypes.func.isRequired,
};

// Component State
function PropertyCommonAreaCalculatorFormState(state) {
  return {
    user: state.user,
    apl: state.apl,
    billing: state.billing,
    properties: state.properties,
  };
}
export default connect(PropertyCommonAreaCalculatorFormState, {
  getAllCommonChargeRelatedDataByPropertyId,
  updateCurrentAccountInDB,
})(PropertyCommonAreaCalculatorForm);

//export default VendorInformationForm;
