// Imports
import axios from "axios";
require("../../../setup/config/apiConfig");

export const RESET = "RESET";
export const GET_PROPERTY_MANAGER_STATUSES = "GET_PROPERTY_MANAGER_STATUSES";
export const GET_PROPERTY_MANAGER_STATUSES_RESPONSE =
  "GET_PROPERTY_MANAGER_STATUSES_RESPONSE";
export const GET_PROPERTY_MANAGER_STATUSES_FAIL =
  "GET_PROPERTY_MANAGER_STATUSES_FAIL";

export function get(isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: GET_PROPERTY_MANAGER_STATUSES,
      isLoading: isLoading,
    });
    return axios
      .get(`/propertymanagerstatus`)
      .then((response) => {
        dispatch({
          type: GET_PROPERTY_MANAGER_STATUSES_RESPONSE,
          propertyManagerStatuses: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_PROPERTY_MANAGER_STATUSES_FAIL,
          isLoading: false,
          error: error,
        });
      });
  };
}
