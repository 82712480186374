import React, { useRef, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import Helmet from "react-helmet";


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import Skeleton from '@material-ui/lab/Skeleton';
import EditIcon from '@material-ui/icons/Edit';
import {
  Checkbox, Grid, IconButton, Link, Breadcrumbs as MuiBreadcrumbs, Divider as MuiDivider, Paper as MuiPaper, Table, TableBody, TableContainer, TableCell,
  TableHead, TablePagination, TableRow, TableSortLabel, Toolbar, Tooltip, Typography, FormControlLabel, Switch, Menu, MenuItem, TextField as MuiTextField, Button as MuiButton,
  FormControl, InputLabel, Select, Input
} from "@material-ui/core";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  Loop as LoopIcon,
  PowerOffSharp,
  Search as SearchIcon,
  Save as SaveIcon,
} from "@material-ui/icons";

import { spacing } from "@material-ui/system";

import { EditableTable, newDropDownField, newFieldLinkUrl, newFieldPopupModel, newOnFieldChangeMethod } from "../../../components/EditableTable";

// Redux Components
import PropTypes from 'prop-types';
import { connect, useDispatch } from "react-redux";
import { logout } from '../../user/api/actions';
import { getAllPropertyPreviews } from '../../apl/api/actions';
import { updateBillingWizardSelectedProperty, updateBillingWizardBillInProgress, getAllUnsettledPropertyMeterReadingsByPropertyUUID, handleBillingProcessSubmission, getExtendedBillRidersView } from '../../billing/api/actions';
import SelectedBillingInformationCard from './BillExpandedViewPopup';

import { setSnackbar } from "../../snackbar/api/snackbar";
import { buildRiders } from '../../../setup/helpers';

import moment from "moment";
import PropertyStats from "./PropertyStats";

const Button = styled(MuiButton)(spacing);
const today = moment().format("MM/DD/YYYY");
const DashboardToolBarButton = styled(Button)`
padding: 4px;
min-width: 0;
svg {
    width: 2em;
    height: 2em;
}
`;
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Spacer = styled.div`
  flex: 1 1 100%;
`;

//Editable Table Object Expects: {objectArray, fieldTitles(Array), fieldTypes(Array), fieldNames(Array), handleRowClickParentMethod}
// Method can take line items if they exist initially or starts as an empty array of nothing passed
const TrialBillingLineItemsManager = (props) => {

  const dispatch = useDispatch();
  const [riderRates, setRiderRates] = React.useState(props.properties.propertyRiderRates);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [oldQuery, setOldQuery] = React.useState("");
  const [billingFormOpen, setBillingFormOpen] = React.useState(false);
  const [focus, setFocus] = React.useState(false);
  const [toShowCurrent, setToShowCurrent] = React.useState({ items: [], toUpdate: [], toDelete: [] });
  const [currRead, setCurrRead] = React.useState(null);
  const [prevRead, setPrevRead] = React.useState(null);

  useEffect(() => {
    setToShowCurrent(props.billing.data.billInProgress.trialBillingItems);
  }, [props.billing.data.billInProgress.trialBillingItems]);

  const handleSearchQueryInput = (event) => {
    setSearchQuery(String(event.target.value));
  };

  const handleBillInfoFormClickOpen = (item) => {
    props.getExtendedBillRidersView(item, props.billing.data.billInProgress);
    setBillingFormOpen(true);
  };

  const handleBillInfoFormClose = () => {
    setBillingFormOpen(false);
  }

  const fieldTitles = ["Customer",
    // "",
    "Previous Read",
    "Type",
    "Current Read",
    "Type",
    "Usage (CF)",
    "Rate (USD/CF)",
    "Cost",
    "Total Charge",
    "Line Profit"];
  const fieldTypes = [
    ["labelSmall", "labelSmall", "labelSmall", "labelSmall", "labelSmall"],
    // "previewBtn",
    ["number", "dateLabelSmallPopup"],
    "dropDown",
    ["number", "dateLabelSmallPopup"],
    "dropDown",
    ["labelBold"],
    "labelFourDecimalRounded",
    "moneyLabel",
    "moneyLabel",
    "moneyLabel"];
  const fieldNames = [
    ["accountNum", "customerName", "serviceAddress", "meterNum", "meterCode"],
    // "",
    ["prevReadAmt", "prevReadDate"],
    "prevReadType",
    ["currReadAmt", "currReadDate"],
    "currReadType",
    ["usage"],
    "rate",
    "cost",
    "totalCharge",
    "profit"]; // Must be the exact name of the properties you wanna show

  const nonMeterFieldTitles = [
    "Customer",
    // "",
    "Start Date",
    "End Date",
    "Usage (CF)",
    "Rate (USD/CF)",
    "Cost",
    "Total Charge",
    "Line Profit"];
  const nonMeterFieldTypes = [
    ["labelSmall", "labelSmall", "labelSmall", "labelSmall", "labelSmall"],
    // "previewBtn",
    ["number", "dateLabelSmallPopup"],
    ["number", "dateLabelSmallPopup"],
    ["labelBold"],
    "labelFourDecimalRounded",
    "moneyLabel",
    "moneyLabel",
    "moneyLabel"];
  const nonMeterFieldNames = [
    ["accountNum", "customerName", "serviceAddress", "meterNum", "meterCode"],
    // "",
    ["prevReadDate"],
    ["currReadDate"],
    ["usage"],
    "rate",
    "cost",
    "totalCharge",
    "profit"];

  const createTrialLineItemData = (id, customer, rate, previousRead, currentRead, usage, totalCharge, profit) => {
    return { id, customer, rate, previousRead, currentRead, usage, totalCharge, profit };
  }

  const calculateLineValuesDateChange = (item) => {
    var oldPrevDate = null;
    var oldCurrDate = null;
    var itemIndex = null;
    for (var x = 0; x < props.billing.data.billInProgress.trialBillingItems.items.length; x++) {
      if (props.billing.data.billInProgress.trialBillingItems.items[x].id == item.id) {
        itemIndex = x;
        oldPrevDate = props.billing.data.billInProgress.trialBillingItems.items[itemIndex].prevReadDate;
        oldCurrDate = props.billing.data.billInProgress.trialBillingItems.items[itemIndex].currReadDate;
        x = props.billing.data.billInProgress.trialBillingItems.items.length;
      }
    }
    if ((oldCurrDate != item.currReadDate && item.currReadType == 'EST') || (oldPrevDate != item.prevReadDate && item.prevReadType == 'EST')) {
      var usageAvgDaily = Number(item.usage / moment(oldCurrDate).diff(moment(oldPrevDate), 'days'));
      item.usage = Math.floor(usageAvgDaily * moment(item.currReadDate).diff(moment(item.prevReadDate), 'days'));
      item.currReadAmt = Math.floor(item.prevReadAmt + item.usage);
      console.log("current Read Amt:", item.currReadAmt)
    }
    props.billing.data.billInProgress.trialBillingItems.items[itemIndex] = item;
    return item;
  }

  const calculateAllLineValuesDateChange = async () => {
    var oldPrevDate = null;
    var oldCurrDate = null;
    let updates = []
    if (prevRead != null || currRead != null)
      for (var y = 0; y < props.billing.data.billInProgress.trialBillingItems.items.length; y++) {
        let item = props.billing.data.billInProgress.trialBillingItems.items[y];
        oldPrevDate = props.billing.data.billInProgress.trialBillingItems.items[y].prevReadDate;
        oldCurrDate = props.billing.data.billInProgress.trialBillingItems.items[y].currReadDate;
        if (prevRead != null && prevRead != "") {
          item.prevReadDate = prevRead;
        }
        if (currRead != null && currRead != "") {
          item.currReadDate = currRead;
        }
        if (item.currReadType == 'EST') {
          var usageAvgDaily = Number(item.usage / moment(oldCurrDate).diff(moment(oldPrevDate), 'days'));
          item.usage = Math.floor(usageAvgDaily * moment(item.currReadDate).diff(moment(item.prevReadDate), 'days'));
          item.currReadAmt = Math.floor(item.prevReadAmt + item.usage);
        }
        props.billing.data.billInProgress.trialBillingItems.items[y] = item;
        if (item.uuid) {
          // props.billing.data.billInProgress.trialBillingItems.toUpdate.push(item.uuid);
          updates.push(item.uuid);
        }
      }

      for(let [index, update] of updates.entries()) {
        props.billing.data.billInProgress.trialBillingItems.toUpdate = [update];
      }


    setCurrRead(null);
    setPrevRead(null);
  }

  const calculateLineValues = (item) => {
    var vendorBillOperator = 0;
    if (props.billing.data.billInProgress.vendorInformation.billTotal != 0 && props.billing.data.billInProgress.vendorInformation.usageTotal != 0) {
      vendorBillOperator = props.billing.data.billInProgress.vendorInformation.billTotal / props.billing.data.billInProgress.vendorInformation.usageTotal;
    }
    item.totalCharge = item.usage * item.rate;
    item.cost = item.totalCharge
    for (var x = 0; x < props.billing.data.billInProgress.trialBillingItems.items.length; x++) {
      if (props.billing.data.billInProgress.trialBillingItems.items[x].id == item.id) {
        props.billing.data.billInProgress.trialBillingItems.items[x] = item;
        x = props.billing.data.billInProgress.trialBillingItems.items.length;
      }
    }
    // dispatch(setSnackbar(true, "warning", "Updating Current Template Item"));
    // props.handleBillingProcessSubmission(props.billing.data.billInProgress, false).then(() => {
    //   if (props.billing.data.billInProgress.possibleError && props.billing.data.billInProgress.possibleError.length > 0) {
    //     dispatch(setSnackbar(true, "error", "Error Updating Work Table Item"));
    //   }
    //   else {
    //     dispatch(setSnackbar(true, "success", "Item Updated and totals recalulated"));
    //   }
    // });

    return item;
  }

  // Returns the item in question
  const onReadAmountsChanged = (item) => {
    item.usage = item.currReadAmt - item.prevReadAmt;
    if(item.multiplier > 1.00){
      item.usage = ((item.currReadAmt - item.prevReadAmt) * item.multiplier);
    }
    return calculateLineValues(item);
  }

  const getAccountURL = (item) => {
    return "/accountmanager/" + item.accountNum;
  }
  const getPropertyURL = (item) => {
    return "/accountmanager/" + item.accountNum;
  }
  const createBlankLineItem = () => {
    return createTrialLineItemData(-1);
  }

  const CurrentLookupTypeButton = () => {
    return (
      <IconButton>
        <SearchIcon mr={2} />
      </IconButton>
    );
  };

  const onCurrReadChange = (event) => {
    setCurrRead(event.target.value);
  }
  const onPrevReadChange = (event) => {
    setPrevRead(event.target.value);
  }

  const onUpdateAllReadDatesClick = () => {
    calculateAllLineValuesDateChange();
  }

  const setSearchFocus = () => {
    setFocus(true);
  }
  const unsetSearchFocus = () => {
    setFocus(false);
  }
  const SearchBar = () => {
    return (
      <Grid container xs={12} spacing={4}>
        <PropertyStats 
        totalUnits={props.properties?.data?.property?.stats?.total || ''}
        activeAccounts={props.properties?.data?.property?.stats?.ActiveAccounts || ''}
        inActiveAccounts={props.properties?.data?.property?.stats?.InActiveAccounts || ''}
        commonAccounts={props.properties?.data?.property?.stats?.CommonAccounts || ''}
        tenantAccounts={props.properties?.data?.property?.stats?.TenantAccounts || ''}
        pmAccounts={props.properties?.data?.property?.stats?.PMAccounts || ''}
        commonLineItem={props.properties?.data?.property?.stats?.CommonLineItem || ''}
        billableToPm={props.properties?.data?.property?.stats?.BillableToPM || ''}
        />
        <Grid item xs={12}>
          {
            focus ?
              <TextField
                id={"searchTarget"}
                label="Search Trial Billing Items"
                type={"text"}
                placeholder={"Search by any trial billing item field"}
                onChange={handleSearchQueryInput}
                autoFocus={"true"}
                onBlur={unsetSearchFocus}
                inputRef={text => text && text.focus()}
                name={"searchTarget"}
                defaultValue={searchQuery}
                fullWidth
                size={"small"}
                variant={"outlined"}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{ endAdornment: <CurrentLookupTypeButton /> }}
                helperText="Currently searching by trial billing item characteristics. Use Comma to seperate search values"
              ></TextField>
              :
              <TextField
                id={"searchTarget"}
                label="Search Trial Billing Items"
                type={"text"}
                placeholder={"Search by any trial billing item field"}
                onChange={handleSearchQueryInput}
                onClick={setSearchFocus}
                name={"searchTarget"}
                defaultValue={searchQuery}
                fullWidth
                size={"small"}
                variant={"outlined"}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{ endAdornment: <CurrentLookupTypeButton /> }}
                helperText="Currently searching by trial billing item characteristics. Use Comma to seperate search values"
              ></TextField>
          }
        </Grid>
      </Grid>
    );
  };


  const MultiAccountDateChangeToolbar = () => {
    return (
      <Grid item>
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <TextField
              id={"searchTarget"}
              label={props.billingType === 'BILL'? "Previous Read Date" : "Start Date"}
              type={"date"}
              name={"searchTarget"}
              value={prevRead}
              fullWidth
              size={"small"}
              onChange={onPrevReadChange}
              variant={"outlined"}
              InputLabelProps={{
                shrink: true,
              }}
            ></TextField>
          </Grid>
          <Grid item xs={5}>
            <TextField
              id={"searchTarget"}
              label={props.billingType === 'BILL'? "Current Read Date" : "End Date"}
              type={"date"}
              name={"searchTarget"}
              value={currRead}
              fullWidth
              size={"small"}
              onChange={onCurrReadChange}
              variant={"outlined"}
              InputLabelProps={{
                shrink: true,
              }}
            ></TextField>
          </Grid>
          <Grid item xs={2}>
            {prevRead != null || currRead != null ?
              <Button variant="contained" color="secondary" onClick={onUpdateAllReadDatesClick}>
                Update
              </Button>
              :
              <Button variant="contained" color="secondary" disabled={true}>
                Update
              </Button>
            }
          </Grid>
        </Grid >
      </Grid >
    );
  };

  const TrialBillingItemsStatusStyling = (workTableItem) => {
    // Line title things
    var vendorBillOperator = 0;
    if (props.billing.data.billInProgress.vendorInformation.billTotal != 0 && props.billing.data.billInProgress.vendorInformation.usageTotal != 0) {
      vendorBillOperator = props.billing.data.billInProgress.vendorInformation.billTotal / props.billing.data.billInProgress.vendorInformation.usageTotal;
    }
    workTableItem.cost = workTableItem.totalCharge;
    workTableItem.profit = workTableItem.totalCharge - workTableItem.cost;


    // Inline formatting removing null from customer name if it exists:
    if (workTableItem.customerName.includes("null")) // Should make the default just an empty string
    {
      workTableItem.customerName = workTableItem.customerName.replace('null', '');
    }
    if (workTableItem.commonAccount == 1) // Should make the default just an empty string
    {
      workTableItem.accountType = "Common Account";
    }
    else {
      workTableItem.accountType = "Tenant Account";
    }
    if (workTableItem.finalRead == 1) // Should make the default just an empty string
    {
      workTableItem.readType = "Final Read";
    }
    else {
      workTableItem.readType = "Standard Read";
    }
    if ((workTableItem.prevReadAmt <= 0) || (workTableItem.currReadAmt <= 0) ||
      (workTableItem.usage <= 0) || (workTableItem.totalCharge <= 1.00) ||
      (workTableItem.totalCharge >= 350.00)) {
      return { backgroundColor: '#ffaaaa' };
    }
    return {};
  };

  const addBlankItemToArray = () => {
    props.billing.data.billInProgress.trialBillingItems.items.push(createBlankLineItem());
    props.updateBillingWizardBillInProgress(props.billing.data.billInProgress);
  }

  const mergeArraysById = (arr1, arr2) => arr1.map(obj => arr2.find(o => o.id === obj.id) || obj);
  const mergeArraysByValue = (arr1, arr2) => arr1.map(obj => arr2.find(o => o === obj) || obj);
  // To be used to save over the api in the database
  const saveAllLineItems = (items) => {
    props.billing.data.billInProgress.trialBillingItems.items = items.items;
    props.billing.data.billInProgress.trialBillingItems.toUpdate = items.toUpdate;
    props.billing.data.billInProgress.trialBillingItems.toDelete = items.toDelete;
    // Replaces existing store object with new object with modified sub data
    dispatch(setSnackbar(true, "warning", "Updating Current Template Items"));
    props.handleBillingProcessSubmission(props.billing.data.billInProgress, false).then(() => {
         if (props.billing.data.billInProgress.possibleError && props.billing.data.billInProgress.possibleError.length > 0) {
        dispatch(setSnackbar(true, "error", "Error Updating Work Table Items"));
      }
      else {
        dispatch(setSnackbar(true, "success", "Items Updated and totals recalulated"));
        props.billing.data.billInProgress.trialBillingItems.toUpdate = [];
        props.billing.data.billInProgress.trialBillingItems.toDelete = [];
        props.updateBillingWizardBillInProgress(props.billing.data.billInProgress);
      }
      
    
    });
  }

  const updateLocalItemsValues = (items) => {
    props.billing.data.billInProgress.trialBillingItems.items = items.items;
    props.billing.data.billInProgress.trialBillingItems.toUpdate = items.toUpdate;
    props.billing.data.billInProgress.trialBillingItems.toDelete = items.toDelete;
    // Replaces existing store object with new object with modified sub data

    
  }


  const urlFields = [newFieldLinkUrl("accountNum", getAccountURL), newFieldLinkUrl("serviceAddress", getPropertyURL), newFieldLinkUrl("customerName", getPropertyURL)];
  const onChangeFields = [newOnFieldChangeMethod("prevReadAmt", onReadAmountsChanged), newOnFieldChangeMethod("currReadAmt", onReadAmountsChanged), newOnFieldChangeMethod("currReadDate", calculateLineValuesDateChange), newOnFieldChangeMethod("prevReadDate", calculateLineValuesDateChange)];
  const resourceFields = [newDropDownField("prevReadType", props.billing.readingTypes, "type", "type"), newDropDownField("currReadType", props.billing.readingTypes, "type", "type")];

  // Filtering process locally: 
  if (oldQuery != searchQuery) {
    var toShowAll = JSON.parse(JSON.stringify(props.billing.data.billInProgress.trialBillingItems));
    var toSearch = searchQuery.split(",");
    console.log("Re-filter");
    // Loops through each item:
    for (var x = 0; x < toShowAll.items.length;) {
      // Filtering by Search Query
      var itemString = toShowAll.items[x].customerName.toUpperCase() +
        toShowAll.items[x].accountNum + " " +
        toShowAll.items[x].serviceAddress.toUpperCase() + " " +
        toShowAll.items[x].prevReadType.toUpperCase() + " " +
        toShowAll.items[x].currReadType.toUpperCase() + " " +
        toShowAll.items[x].prevReadAmt + " " +
        toShowAll.items[x].currReadAmt + " " +
        toShowAll.items[x].totalCharge + " " +
        toShowAll.items[x].rate + " " +
        toShowAll.items[x].profit + " " +
        toShowAll.items[x].meterNum + " " +
        toShowAll.items[x].usage;
      var wasCut = false;
      for (var y = 0; y < toSearch.length; y++) {
        if (toSearch[y] != "" && toSearch[y] != null) {
          if (!itemString.toUpperCase().includes(toSearch[y].toUpperCase())) {
            y = toSearch.length;
            wasCut = true;
          }
        }
      }
      if (wasCut) {
        toShowAll.items.splice(x, 1);
      }
      else {
        x++;
      }
    }
    setOldQuery(searchQuery);
    setToShowCurrent(toShowAll);
  }

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <SearchBar />
        </Grid>
        <Grid item xs={12}>
          < MultiAccountDateChangeToolbar />
        </Grid>
        <Grid item xs={12}>
          <EditableTable
            // Functional methods of table
            canSave={true}
            newItemCreationMethod={addBlankItemToArray}
            saveCurrentTableData={saveAllLineItems}
            updateItemsLocalState={updateLocalItemsValues}
            conditionalStylingMethod={TrialBillingItemsStatusStyling}
            isLoading={props.billing.billInProgressIsLoading}
            canAddItems={false}
            dropDownFields={resourceFields}
            saveOnTextboxLeave={false}
            isVirtualized={true}
            defaultItemCount={10}
            // isPagedLocally={false}
            // Properties of table
            tableTitle={`Trial Billing Items ${props.billing.data.billInProgress.trialBillingItems.items.length || ''}`}
            objectArray={toShowCurrent}//{props.billing.data.billInProgress.trialBillingItems} 
            fieldTitles={props.billingType === 'BILL' ? fieldTitles: nonMeterFieldTitles}
            fieldTypes={props.billingType === 'BILL' ? fieldTypes: nonMeterFieldTypes}
            fieldNames={props.billingType === 'BILL' ? fieldNames: nonMeterFieldNames}
            customOnChangeFields={onChangeFields}
            canExport={true}
            showItemNumber={true}
            customUrlFields={urlFields}
            handlePreviewClickParentMethod={handleBillInfoFormClickOpen}
          //ConditionalOnSelectToolbar={MultiAccountDateChangeToolbar}
          />
        </Grid>
        <Dialog
          open={billingFormOpen}
          TransitionComponent={Transition}
          fullWidth={"lg"}
          maxWidth={"lg"}
          keepMounted
          onClose={handleBillInfoFormClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <SelectedBillingInformationCard onUpdate={handleBillInfoFormClose} onCancel={handleBillInfoFormClose} />
          </DialogContent>
        </Dialog>
      </Grid>
    </>
  );
}

// Component Properties
TrialBillingLineItemsManager.propTypes = {
  // Store objects:
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  billing: PropTypes.object.isRequired,
  properties: PropTypes.object.isRequired,
  propertyUtilities: PropTypes.object.isRequired,
  // Store object functions:
  getAllPropertyPreviews: PropTypes.func.isRequired,
  updateBillingWizardSelectedProperty: PropTypes.func.isRequired,
  updateBillingWizardBillInProgress: PropTypes.func.isRequired,
  getAllUnsettledPropertyMeterReadingsByPropertyUUID: PropTypes.func.isRequired,
  handleBillingProcessSubmission: PropTypes.func.isRequired,
  getExtendedBillRidersView: PropTypes.func.isRequired
};

// Component State
function TrialBillingLineItemsManagerState(state) {
  return {
    user: state.user,
    apl: state.apl,
    billing: state.billing,
    properties: state.properties,
    propertyUtilities: state.propertyUtilities
  }
}
export default connect(TrialBillingLineItemsManagerState, { logout, handleBillingProcessSubmission, updateBillingWizardSelectedProperty, updateBillingWizardBillInProgress, getAllPropertyPreviews, getAllUnsettledPropertyMeterReadingsByPropertyUUID, getExtendedBillRidersView })(TrialBillingLineItemsManager)

