//React Components and Hooks
import {
  CircularProgress as MuiCircularProgress,
  Divider as MuiDivider,
  Grid,
  Typography as MuiTypography,
} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Snackbar from "@material-ui/core/Snackbar";
//Material UI Components and Functions
import { makeStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import MuiAlert from "@material-ui/lab/Alert";
import { spacing } from "@material-ui/system";
import { LeftSidebar } from "layout-blueprints";
// Redux Components
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import StandardHeader from "../../components/global/StandardHeader";
// Custom application component imports:
import { LeftSidebar as NavFrame } from "layout-blueprints"; // The top navigation bar and side navigation panel
import {
  getWorkOrders
} from "../../modules/workOrders/api/actions";
import ActiveUsers from "../../modules/settings/components/ActiveUsers";
// import CurrentBillDraftsManagerTable from '../../modules/billing/components/CurrentBillDraftsManagerTable';
// import CurrentBillBatchesManagerTable from '../../modules/billing/components/CurrentBillBatchesManagerTable';
// Helper methods:
import MessageSettingTable from "../../modules/settings/components/MessageSettingTable";
import Profile from "../../modules/settings/components/Profile";
import SettingsList from "../../modules/settings/components/Settings";
import { logout } from "../../modules/user/api/actions";
import qs from "querystring"
import WorkOrdersTable from "modules/workOrders/components/WorkOrdersTable";
import { getActiveUsers } from "modules/settings/api/actions";
import { getWorkOrderStatusTypes } from "modules/apl/api/actions";
import { get } from "modules/property/api/actions"
const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  percentDif: {
    textAlign: "right",
    color: "green",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  loadingIcon: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

function WorkOrders(props) {
  const classes = useStyles();

  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(
    "Error retrieving data"
  );

  useEffect(() => {
    props.getWorkOrders()
    props.getWorkOrderStatusTypes()
    props.getActiveUsers()
    props.get() //Properties
  }, []);

  return (
    <LeftSidebar page="Work Orders">
      <StandardHeader title="Work Orders" />

      <Divider className="mb-3 mt-3" />

      <WorkOrdersTable />
    </LeftSidebar>
  );
}

// Component Properties
WorkOrders.propTypes = {
  workOrders: PropTypes.object.isRequired,
  getWorkOrders: PropTypes.func.isRequired,
  getActiveUsers: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired
};

// Component State
function WorkOrdersPageState(state) {
  return {
    user: state.user,
    workOrders: state.workOrders,
    setting: state.setting,

  };
}
export default connect(WorkOrdersPageState, {
  logout,
  getWorkOrders,
  getActiveUsers,
  getWorkOrderStatusTypes,
  get
})(WorkOrders);
