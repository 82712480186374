import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { setSnackbar } from "../../../modules/snackbar/api/snackbar";
import { registerActiveUser, getActiveUsers } from "../../settings/api/actions";

function ActiveUserForm(props) {
  const [open, setOpen] = useState(props.show);
  const [item, setItem] = useState(props.item);
  const [state, setState] = useState({
    firstName: props.item.firstName || "",
    isActive: props.item.isActive || props.new  ,
    lastName: props.item.lastName || "",
    email: props.item.email || "",
    accounting: props.item.accounting || 0,
    disconnects: props.item.disconnects || 0,
    administration: props.item.administration || 0,
    billing: props.item.billing || 0

  });
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = async () => {
    //If New, call register user,
    if (state.email == "" || state.firstName == "" || state.lastName == "") {
      dispatch(setSnackbar(true, "error", "Form incomplete."));
    }

    if (props.new) {
      let body = {
        firstName: state.firstName,
        lastName: state.lastName,
        email: state.email,
        password: "password",
      };
      const response = await axios.post(`/register-internal-user`, body)
          if (response)
            dispatch(
              setSnackbar(
                true,
                "success",
                `${state.email} has received an email with instructions to reset their password.`
              )
            );
          else {
            dispatch(
              setSnackbar(true, "error", "Error on invite or email in use")
            );
          }
    }

    setOpen(false);
    props.onChange(false);
    await props.updateItem(item.uuid, state);
    await props.getActiveUsers();

  };

  const handleCancel = () => {
    setOpen(false);
    props.onChange(false);
  };

  const handleChange = (evt) => {
    let value = evt.target.value;
    if (evt.target.name == "isActive") value = evt.target.checked;
    if (evt.target.name == "accounting" || evt.target.name == "administration" ||evt.target.name == "disconnects" ||evt.target.name == "billing") {
      value = evt.target.checked;
    }

    setState({
      ...state,
      [evt.target.name]: value,
    });
  };

  const registerNewUser = () => {};

  const updateSelectedUser = () => {};

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open form dialog
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
      >
        <DialogTitle id="form-dialog-title">
          {!props.new ? "Update User" : "Invite new user"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <FormControlLabel
            control={
              <Switch
                checked={state.isActive}
                onChange={handleChange}
                name="isActive"
                color="primary"
              />
            }
            label="Active"
          />
          <TextField
            autoFocus
            margin="dense"
            id="firstName"
            name="firstName"
            value={state.firstName}
            label="First Name"
            type=""
            fullWidth
            onChange={handleChange}
            required
          />
          <TextField
            autoFocus
            margin="dense"
            id="lastName"
            name="lastName"
            value={state.lastName}
            label="Last Name"
            type=""
            fullWidth
            onChange={handleChange}
            required
          />
          <TextField
            autoFocus
            margin="dense"
            id="email"
            name="email"
            value={state.email}
            label="Email"
            type="email"
            fullWidth
            onChange={handleChange}
            disabled={!props.new}
            required
          />
          { !props.new &&
             <Box sx={{ display: 'flex' }}>
              <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                <FormLabel component="legend">Assign responsibility</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox checked={state.accounting} onChange={handleChange} name="accounting" />
                    }
                    label="Accounting"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={state.disconnects} onChange={handleChange} name="disconnects" />
                    }
                    label="Disconnects"
                  />
                    <FormControlLabel
                    control={
                      <Checkbox checked={state.billing} onChange={handleChange} name="billing" />
                    }
                    label="Billing"/>
                  <FormControlLabel
                    control={
                      <Checkbox checked={state.administration} onChange={handleChange} name="administration" />
                    }
                    label="Admin"
          />
        </FormGroup>
      </FormControl>
      </Box> }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="secondary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
ActiveUserForm.propTypes = {
  // Store objects:
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  billing: PropTypes.object.isRequired,
  // Store object functions:
  getAllBillingDashboardInfo: PropTypes.func.isRequired,
  getBillInProgressDraft: PropTypes.func.isRequired,
  getActivieUSers: PropTypes.func.isRequired,
  updateBillingWizardBillInProgress: PropTypes.func.isRequired,
  updateBillingWizardSelectedProperty: PropTypes.func.isRequired,
  resetBillingObject: PropTypes.func.isRequired,
  getBillingBatchById: PropTypes.func.isRequired,
};

// Component State
function ActiveUserFormState(state) {
  return {
    setting: state.setting,
  };
}

export default connect(ActiveUserFormState, { registerActiveUser, getActiveUsers })(
  ActiveUserForm
);
