import React, {useEffect} from "react";
import styled from "styled-components/macro";
import {
  Grid,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Paper as MuiPaper,
  MenuItem,
  TextField as MuiTextField,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  CircularProgress as MuiCircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  IconButton,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { spacing } from "@material-ui/system";
import { EditableTable } from "../../../components/EditableTable";

import StatusTag from "../../apl/components/StatusTag";
// Redux Components
import PropTypes from "prop-types";
import {connect, useDispatch} from "react-redux";
import { get as getProperties, updateFilters } from "../api/actions";
import {get as getPropertyStatuses } from "modules/propertyStatus/api/actions"
import Form from "./Form";

const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Table(props) {
  const [searchQuery, setSearchQuery] = React.useState("");
  const [focus, setFocus] = React.useState(false);

  const dispatch = useDispatch();


  const handleSearchTargetChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const CurrentLookupTypeButton = () => {
    return (
      <IconButton>
        <SearchIcon mr={2} />
      </IconButton>
    );
  };

  const PropertyRowStatusStyling = (property) => {
    // Horidly inefficiant but theres no better way to access this in the context i can think of
    property.propertyStatus = (
      <>
        <StatusTag
          type={"propertyStatus"}
          isLoading={props.apl.genericTypes.propertyStatus.isLoading}
          typeId={property.statusId}
          showTitle={false}
          condensed={true}
        />
      </>
    );
    return {};
  };

  const canAdd = (props.user.details.administration || props.user.details.isSuperUser) ? true : false;

  const setSearchFocus = () => {
    setFocus(true);
  }
  const unsetSearchFocus = () => {
    setFocus(false);
  }

  useEffect(()=>{
    dispatch(getPropertyStatuses())
  },[])

  const SearchBar = () => {
    return (
      <Grid container xs={12} spacing={4}>
        <Grid item xs={12}>
          {
            focus ?
              <TextField
                id={"searchTarget"}
                label="Search Properties On File"
                type={"text"}
                placeholder={"Search by any property object field"}
                onChange={handleSearchTargetChange}
                autoFocus={"true"}
                onBlur={unsetSearchFocus}
                inputRef={text => text && text.focus()}
                name={"searchTarget"}
                defaultValue={searchQuery}
                fullWidth
                size={"small"}
                variant={"outlined"}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{ endAdornment: <CurrentLookupTypeButton /> }}
              ></TextField>
              :
              <TextField
                id={"searchTarget"}
                label="Search Properties On File"
                type={"text"}
                placeholder={"Search by any property object field"}
                onChange={handleSearchTargetChange}
                onClick={setSearchFocus}
                name={"searchTarget"}
                defaultValue={searchQuery}
                fullWidth
                size={"small"}
                variant={"outlined"}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{ endAdornment: <CurrentLookupTypeButton /> }}
              ></TextField>
          }
        </Grid>
      </Grid>
    );
  };

  // Table Stuff:
  const fieldTitles = [
    "Name",
    "Status",
    "GL Code",
    "Developer",
    "Property Manager",
    "Units",
  ];
  const fieldTypes = ["label", "label", "label", "label", "label", "label"];
  const fieldNames = [
    "name",
    "propertyStatus",
    "glCode",
    "developerName",
    "propertyManagerName",
    "totalUnits",
  ];

  const onSelected = (item) => {
    window.open(
      "/property/" + item.uuid + "/" + item.id + "/" + item.propertyManagerId
    );
  };

  const onUpdate = () => {
    hide();
  };

  const [display, setDisplay] = React.useState(false);

  const show = () => {
    props.properties.data.property = {}; // TODO: clear property on popup show
    setDisplay(true);
  };

  const hide = () => {
    setDisplay(false);
  };

  const NewDialog = () => {
    return (
      <Dialog
        open={display}
        TransitionComponent={Transition}
        fullWidth={"lg"}
        maxWidth={"lg"}
        keepMounted
        onClose={hide}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">New Property</DialogTitle>
        <DialogContent>
          <Form onUpdate={onUpdate} onCancel={hide} />
        </DialogContent>
      </Dialog>
    );
  };

  const toShowAll = { items: JSON.parse(JSON.stringify(props.properties.data.propertiesList.items)), toUpdate: [], toDelete: [] };
  // Loops through each item:
  for (var x = 0; x < toShowAll.items.length;) {
    // Filtering by Search Query
    if (searchQuery != "") {
      if (!(toShowAll.items[x].name != null && toShowAll.items[x].name.toUpperCase().includes(searchQuery.toUpperCase())) &&
        !(toShowAll.items[x].city != null && toShowAll.items[x].city.toUpperCase().includes(searchQuery.toUpperCase())) &&
        !(toShowAll.items[x].state != null && toShowAll.items[x].state.toUpperCase().includes(searchQuery.toUpperCase())) &&
        !(toShowAll.items[x].zip != null && toShowAll.items[x].zip.toUpperCase().includes(searchQuery.toUpperCase())) &&
        !(toShowAll.items[x].statusDescription != null && toShowAll.items[x].statusDescription.toUpperCase().includes(searchQuery.toUpperCase())) &&
        !(toShowAll.items[x].totalUnits != null && toShowAll.items[x].totalUnits.toString().includes(searchQuery)) &&
        !(toShowAll.items[x].developerName != null && toShowAll.items[x].developerName.toUpperCase().includes(searchQuery.toUpperCase())) &&
        !(toShowAll.items[x].glCode != null && toShowAll.items[x].glCode.toUpperCase().includes(searchQuery.toUpperCase())) &&
        !(toShowAll.items[x].ownerName != null && toShowAll.items[x].ownerName.toUpperCase().includes(searchQuery.toUpperCase()))
      ) {
        toShowAll.items.splice(x, 1);
        continue;
      }
    }
    x++;
  }


  return (
    <Card mb={6} elevation={3}>
      <CardContent>
        <NewDialog />
        <SearchBar />
        <EditableTable
          canSearch={false}
          readOnly={!canAdd}
          canSave={canAdd}
          canAddItems={canAdd}
          conditionalStylingMethod={PropertyRowStatusStyling}
          isLoading={props.properties.isLoading}
          newItemCreationMethod={show}
          handleRowClickParentMethod={onSelected}
          tableTitle={"Properties On File"}
          objectArray={toShowAll}
          canExport={true}
          fieldTitles={fieldTitles}
          fieldTypes={fieldTypes}
          fieldNames={fieldNames}
        />
      </CardContent>
    </Card>
  );
}

Table.propTypes = {
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  properties: PropTypes.object.isRequired,
  getProperties: PropTypes.func.isRequired,
};

function State(state) {
  return {
    user: state.user,
    apl: state.apl,
    search: state.search,
    properties: state.properties,
  };
}

export default connect(State, { getProperties })(Table);
