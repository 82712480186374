import { Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  percentDif: {
    textAlign: "right",
    color: "green",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  loadingIcon: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  billingWizardContent: {
    maxHeight: "60%",
  },
  paper: {
    padding: "1%",
  },
}));

const MissingTypeIDValue = "Unknown";

// Generic Tag Item
// NOTE:  Expects (In props){type (the name in the store as a string), isLoading, typeId, customStyles(optional), condensed(optional, uses small styling), showTitle(optional, toggles title)}
const StatusTag = (props) => {
  const classes = useStyles();
  // Default Values for status settings
  var isLoading = false;
  var showTitle = true;
  var condensed = false;
  if (typeof props.isLoading === "boolean") {
    isLoading = props.isLoading;
  }
  if (typeof props.showTitle === "boolean") {
    showTitle = props.showTitle;
  }
  if (typeof props.condensed === "boolean") {
    condensed = props.condensed;
  }

  var chipStyles = { fontSize: "100%", fontWeight: "bolder", color: "white" };
  if (props.customStyles) {
    chipStyles = Object.assign({}, props.customStyles, chipStyles);
  }
  return (
    <Chip
      label={props.title}
      color={props.color || "primary"}
      style={chipStyles}
      size={condensed ? "small" : "medium"}
    />
  );
};
export default StatusTag;
