// App Imports
import {
  GET_USER_FROM_ACCOUNT_REQUEST,
  GET_USER_FROM_ACCOUNT_RESPONSE,
  REGISTER_NEW_USER_REQUEST,
  REGISTER_NEW_USER_RESPONSE,
  UPDATE_SELECTED_USER_PROFILE,
  SET_SELECTED_USER_PROFILE,
  PAYMENT_METHOD_GET_USER_PAYMENT_METHODS_REQUEST,
  PAYMENT_METHOD_GET_USER_PAYMENT_METHODS_RESPONSE,
  UPDATE_USER_EMAIL_RESPONSE,
  UPDATE_USER_EMAIL_FAILED,
  UPDATE_USER_PASSWORD_RESPONSE,
  UPDATE_USER_PASSWORD_FAILED,
  GET_ALL_USER_ACCOUNTS_REQUEST,
  GET_ALL_USER_ACCOUNTS_RESPONSE,
  GET_ALL_USER_ACCOUNTS_FAILED,
  DELETE_USER_ACCOUNT_REQUEST,
  DELETE_USER_ACCOUNT_RESPONSE,
  DELETE_USER_ACCOUNT_FAILED,
  CREATE_USER_ACCOUNT_REQUEST,
  CREATE_USER_ACCOUNT_RESPONSE,
  CREATE_USER_ACCOUNT_FAILED,
  GET_ALL_USER_CHILD_ACCOUNTS_REQUEST,
  GET_ALL_USER_CHILD_ACCOUNTS_RESPONSE,
  GET_ALL_USER_CHILD_ACCOUNTS_FAIL,
  DELETE_USER_PM_REQUEST,
  DELETE_USER_PM_RESPONSE,
  DELETE_USER_PM_FAILED,
  CREATE_USER_PM_REQUEST,
  CREATE_USER_PM_RESPONSE,
  CREATE_USER_PM_FAILED,
  GET_EXTERNAL_USERS_TABLE_DATA_REQUEST,
  GET_EXTERNAL_USERS_TABLE_DATA_RESPONSE,
  GET_EXTERNAL_USERS_TABLE_DATA_FAILED,
  UPDATE_EXTERNAL_USER_STATUS_RESPONSE,
  UPDATE_EXTERNAL_USER_STATUS_REQUEST,
  UPDATE_EXTERNAL_USER_STATUS_FAILED,
  UPDATE_EXTERNAL_USERS_LOCAL_STATE,
  DELETE_EXTERNAL_USER_REQUEST,
  DELETE_EXTERNAL_USER_RESPONSE,
  DELETE_EXTERNAL_USER_FAILED
} from "./actions";

// Initial State
export const userInitialState = {
  error: null,
  isLoading: false,
  isAuthenticated: false,
  details: null,
  selectedUserProfile: null,
  selectedUserProfilePaymentMethodsAreLoading: true,
  selectedUserProfilePaymentMethods: {
    cc: [],
    ach: []
  },
  selectedUserProfileAccounts: [],
  selectedUserProfilePmAccounts: [],
  externalUsers:  {
    users: {
      items: [],
      toUpdate: [],
      toDelete: []
    },
    totalCount: 0,
  }
};

// State
export default (state = userInitialState, action) => {
  switch (action.type) {
    case UPDATE_SELECTED_USER_PROFILE:
      return {
        ...state,
        details: action.newData,
      };
    case SET_SELECTED_USER_PROFILE:
      return {
        ...state,
        selectedUserProfile: action.profile,
      };

    case PAYMENT_METHOD_GET_USER_PAYMENT_METHODS_REQUEST:
      return {
        ...state,
        selectedUserProfilePaymentMethodsAreLoading: action.isLoading,
        error: null
      }

    case PAYMENT_METHOD_GET_USER_PAYMENT_METHODS_RESPONSE:
      return {
        ...state,
        selectedUserProfilePaymentMethodsAreLoading: false,
        error: action.error,
        selectedUserProfilePaymentMethods: action.data
      }


    case REGISTER_NEW_USER_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };

    case REGISTER_NEW_USER_RESPONSE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
      case UPDATE_USER_EMAIL_RESPONSE:
        return {
        ...state,
        error: action.error,
        isLoading: false
      }
      case UPDATE_USER_EMAIL_FAILED:
        return {
        ...state,
        error: action.error
      }
    case UPDATE_USER_PASSWORD_RESPONSE:
      return {
        ...state,
        error: action.error
      }
    case UPDATE_USER_PASSWORD_FAILED:
      return {
        ...state,
        error: action.error
      }

    case GET_USER_FROM_ACCOUNT_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };

    case GET_USER_FROM_ACCOUNT_RESPONSE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case GET_ALL_USER_ACCOUNTS_REQUEST:
      return {
        ...state,
        error: action.error,
        isLoading: action.isLoading
      }
    case GET_ALL_USER_ACCOUNTS_RESPONSE:
      return {
        ...state,
        error: action.error,
        isLoading: action.isLoading,
        selectedUserProfileAccounts: action.data
      }
    case GET_ALL_USER_ACCOUNTS_FAILED:
      return {
        ...state,
        error: action.error,
        isLoading: action.isLoading
      }
    case DELETE_USER_ACCOUNT_REQUEST:
      return {
        ...state,
        error: action.error,
        isLoading: action.isLoading
      }
    case DELETE_USER_ACCOUNT_RESPONSE:
      return {
        ...state,
        error: action.error,
        isLoading: action.isLoading
      }
    case DELETE_USER_ACCOUNT_FAILED:  
      return {
      ...state,
      error: action.error,
      isLoading: action.isLoading
    }
    case CREATE_USER_ACCOUNT_REQUEST:
      return {
        ...state,
        error: action.error,
        isLoading: action.isLoading
      }
    case CREATE_USER_ACCOUNT_RESPONSE:  
      return {
        ...state,
        error: action.error,
        isLoading: action.isLoading
      }
    case CREATE_USER_ACCOUNT_FAILED:  
      return {
      ...state,
      error: action.error,
      isLoading: action.isLoading
    }
    case GET_ALL_USER_CHILD_ACCOUNTS_REQUEST:
      return {
        ...state,
        isLoading: action.isLoading
      }
    case GET_ALL_USER_CHILD_ACCOUNTS_RESPONSE:
      return {
        ...state,
        selectedUserProfilePmAccounts: action.data,
        isLoading: action.isLoading,
      }
    case GET_ALL_USER_CHILD_ACCOUNTS_FAIL:  
      return {
        ...state,
        error: action.error,
        isLoading: action.isLoading
      }
    case CREATE_USER_PM_REQUEST:
      return {
        ...state,
        isLoading:action.isLoading
      }
    case CREATE_USER_PM_RESPONSE:
      return {
        ...state,
        isLoading:action.isLoading,
        error: action.error
      }
    case CREATE_USER_PM_FAILED:
      return {
        ...state,
        isLoading:action.isLoading,
        error: action.error
      }
    case DELETE_USER_PM_REQUEST:
      return {
        ...state,
        isLoading:action.isLoading
      }
    case DELETE_USER_PM_RESPONSE:
      return {
        ...state,
        isLoading:action.isLoading,
        error: action.error
      }
    case DELETE_USER_PM_FAILED:
      return {
        ...state,
        isLoading:action.isLoading,
        error: action.error
      }
    case GET_EXTERNAL_USERS_TABLE_DATA_REQUEST:
      return {
        ...state,
        error: action.error,
        isLoading: action.isLoading
      }
    case GET_EXTERNAL_USERS_TABLE_DATA_RESPONSE:
      return {
        ...state,
        error: action.error,
        isLoading: action.isLoading,
        externalUsers: {
          ...state.externalUsers,
          users: {
            ...state.externalUsers.users,
            items: action.resetCache
              ? (action.data && action.data.users)
              : state.externalUsers.users.items.concat(action.data.users),
          },
          totalCount: (action.data && action.data.totalCount),
        }
      };
    case GET_EXTERNAL_USERS_TABLE_DATA_FAILED:
      return {
        ...state,
        error: action.error,
        isLoading: action.isLoading
      }
    case UPDATE_EXTERNAL_USER_STATUS_REQUEST:
      return {
        ...state,
        error: action.error,
        isLoading: action.isLoading,
      };
    case UPDATE_EXTERNAL_USER_STATUS_RESPONSE:
      return {
        ...state,
        error: action.error,
        isLoading: action.isLoading,
      }
    case UPDATE_EXTERNAL_USER_STATUS_FAILED:
      return {
        ...state,
        error: action.error,
        isLoading: action.isLoading,
      }
    case UPDATE_EXTERNAL_USERS_LOCAL_STATE: {
      return {
        ...state,
        externalUsers: {
          ...state.externalUsers,
          users: {
            ...state.externalUsers.users,
            items: action.payload
          }
        },
      };
    }
    case DELETE_EXTERNAL_USER_REQUEST:
      return {
        ...state,
        error: action.error,
        isLoading: action.isLoading
      }
    case DELETE_EXTERNAL_USER_RESPONSE:
      return {
        ...state,
        error: action.error,
        isLoading: action.isLoading
      }
    case DELETE_EXTERNAL_USER_FAILED:
      return {
        ...state,
        error: action.error,
        isLoading: action.isLoading
      }

    default:
      return state;
  }
};
