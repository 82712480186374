import {
  Card as MuiCard,
  CardContent,
  Slide,
  TextField as MuiTextField,
  Dialog,
  DialogContent,
  DialogTitle,
  AppBar, Box, Tab, Tabs, Typography
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { makeStyles } from "@material-ui/core/styles";
// Redux Components
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { setSnackbar } from "../../snackbar/api/snackbar";

import { connect, useDispatch } from "react-redux";
import styled from "styled-components/macro";
import { EditableTable } from "../../../components/EditableTable";
import StatusTag from "../../apl/components/StatusTag";
import { getUserPaymentMethodPreviews, getAccountsByUser , deleteAccountUserLink} from "../api/actions";
import Form from "./Form"
const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;


  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}


const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  searchBarGrid: {
    //paddingBottom: "10px",
    fontSize: "90%",
    height: "5%"
  },
  searchDropDownButton: {
    height: "90%"
  },
  percentDif: {
    textAlign: 'right',
    color: 'green'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  loadingIcon: {
    position: 'absolute', left: '50%', top: '50%',
    transform: 'translate(-50%, -50%)'
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  active: {
    fontWeight: "bold",
    color: "#f9a825",
    borderColor: "#f9a825",
  },
  inactive: {
    fontWeight: "bold",
  },
}));


function UserPaymentMethodTypesTable(props) {

  const classes = useStyles();
  const dispatch = useDispatch();

  const [value, setValue] = React.useState(0);
  const [newSelectedUserLoaded, setNewSelectedUserLoaded] = React.useState(false);
  const [currentAccountUuid, setCurrentAccountUuid] = React.useState('');
  const [display, setDisplay] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

    const show = () => {
    setDisplay(true);
  };

  const hide = () => {
    setDisplay(false);
  };


  useEffect(() => {
    
    setCurrentAccountUuid(props.accountUuid);
    if (props.userProfiles.selectedUserProfile != null && props.userProfiles.selectedUserProfile.id && !newSelectedUserLoaded) {
      setNewSelectedUserLoaded(true);
      props.getUserPaymentMethodPreviews(props.userProfiles.selectedUserProfile.id);
      props.getAccountsByUser(props.userProfiles.selectedUserProfile.uuid, true);
    }
    else if (props.userProfiles.selectedUserProfile == null) {
      setNewSelectedUserLoaded(false);
    }
  }, [props.userProfiles.selectedUserProfile]);

  const deleteSelectedAccountLinks = (accountsToDelete) => {
   
    dispatch(setSnackbar(true, "warning", "Deleting User Acount Links"));
    try {
      accountsToDelete.map(account =>{
        let isCurrentAccount = account === currentAccountUuid ? true : false;
        props.deleteAccountUserLink( props.userProfiles.selectedUserProfile.uuid , account).then(() => {
          if (props.userProfiles.error != null && props.userProfiles.error != '') {

            dispatch(setSnackbar(true, "error", "Error Deleting User Account Link!"));
            
          }
          else {

            dispatch(setSnackbar(true, "success", "Use Account Link Deleted"));
           
          }
          if(isCurrentAccount){

            window.location.reload();

          }
        })
      })
    }
    catch(e) {
      dispatch(setSnackbar(true, "error", "Error Deleting User Account Link!"));

    }
     
  }

  // Table Stuff:
  // Table Stuff:
  const ACH_fieldTitles = [
    "Save Name",
    "Bank Name",
    "Account Holder",
    "Account Type",
    "Account/Routing #",
    "Created On",
  ];

  const ACH_fieldTypes = [
    "label",
    "label",
    "label",
    "label",
    ["label", "label"],
    "dateLabel"
  ];

  const ACH_fieldNames = [
    "saveName",
    "bankName",
    "ownerName",
    "achType",
    ["bankAcctNum", "bankRoutingNum"],
    "created"
  ];

  const CC_fieldTitles = [
    "Save Name",
    "Card Type",
    "Card Owner",
    "Card Number",
    "Expiration",
    "Created On",
  ];

  const CC_fieldTypes = [
    "label",
    "label",
    "label",
    "label",
    "label",
    "dateLabel"
  ];

  const CC_fieldNames = [
    "saveName",
    "ccType",
    "ccName",
    "ccNum",
    "expLabel",
    "created"
  ];

  const Accounts_fieldTitles = [
    "Account Number",
    "Last Name",
    "First Name"
  ];
  const Accounts_fieldTypes = [
    "label",
    "label",
    "label"
  ];
  const Accounts_fieldNames = [
    "accountNum",
    "lastName",
    "firstName"
  ];

  const PaymentMethodRowStatusStyling = (paymentMethod) => {
    if (paymentMethod.ccExpMonth && paymentMethod.ccExpYear) {
      paymentMethod.expLabel = paymentMethod.ccExpMonth + "/" + paymentMethod.ccExpYear;
    }

    if (paymentMethod.saveName == null && paymentMethod.saveName == "") {
      paymentMethod.saveName = "No Save Name Given";
    }
    return {}; // Returns an empty style as to not effect each row
  };

  const NewDialog = () => {
    return (
      <Dialog
        open={display}
        TransitionComponent={Transition}
        fullWidth={"lg"}
        maxWidth={"lg"}
        keepMounted
        onClose={hide}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">New User Account</DialogTitle>
        <DialogContent>
          <Form handleClose={hide} />
        </DialogContent>
      </Dialog>
    );
  };

  return (

    <Card mb={6} elevation={3}>
      <CardContent>
        <div className={classes.root}>
          <AppBar position="static">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="Saved User Payment Methods Tabs"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="Credit Cards" {...a11yProps(0)} />
              <Tab label="ACH" {...a11yProps(1)} />
              <Tab label="User Account" {...a11yProps(2)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <EditableTable
              readOnly={true}
              canAddItems={false}
              canSave={false}
              isLoading={props.userProfiles.selectedUserProfilePaymentMethodsAreLoading}
              conditionalStylingMethod={PaymentMethodRowStatusStyling}
              tableTitle={"Saved Credit Cards"}
              objectArray={{ items: props.userProfiles.selectedUserProfilePaymentMethods.cc, toUpdate: [], toDelete: [] }}
              fieldTitles={CC_fieldTitles}
              fieldTypes={CC_fieldTypes}
              fieldNames={CC_fieldNames}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <EditableTable
              readOnly={true}
              canAddItems={false}
              canSave={false}
              isLoading={props.userProfiles.selectedUserProfilePaymentMethodsAreLoading}
              conditionalStylingMethod={PaymentMethodRowStatusStyling}
              tableTitle={"Saved ACH Targets"}
              objectArray={{ items: props.userProfiles.selectedUserProfilePaymentMethods.ach, toUpdate: [], toDelete: [] }}
              fieldTitles={ACH_fieldTitles}
              fieldTypes={ACH_fieldTypes}
              fieldNames={ACH_fieldNames}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <NewDialog />
            <EditableTable
              readOnly={false}
              canAddItems={props.userProfiles.selectedUserProfileAccounts.length > 0 ? true : false}
              canSave={false}
              newItemCreationMethod={show}
              canDelete={props.userProfiles.selectedUserProfileAccounts.length >= 1 ? true : false}
              verifyDelete={true}
              manualItemDeletionMethod={deleteSelectedAccountLinks}
              isLoading={props.userProfiles.isLoading}
              tableTitle={"User Associated Accounts"}
              objectArray={{ items: props.userProfiles.selectedUserProfileAccounts, toUpdate: [], toDelete: [] }}
              fieldTitles={Accounts_fieldTitles}
              fieldTypes={Accounts_fieldTypes}
              fieldNames={Accounts_fieldNames}
            />
          </TabPanel>
        </div>
      </CardContent>
    </Card>
  );
}

UserPaymentMethodTypesTable.propTypes = {
  user: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  userProfiles: PropTypes.object.isRequired,
  accountsSearch: PropTypes.object.isRequired,
  getUserPaymentMethodPreviews: PropTypes.func.isRequired,
  getAccountsByUser:  PropTypes.func.isRequired,
  deleteAccountUserLink: PropTypes.func.isRequired,
};

function UserPaymentMethodTypesTableState(state) {
  return {
    user: state.user,
    userProfiles: state.userProfiles,
    accounts: state.accounts
  };
}

export default connect(UserPaymentMethodTypesTableState, {
  getUserPaymentMethodPreviews,
  getAccountsByUser,
  deleteAccountUserLink
})(UserPaymentMethodTypesTable);
