// Imports
import axios from "axios";
require("../../../setup/config/apiConfig");

export const RESET = "RESET";
export const GET_UNIT_TYPES = "GET_UNIT_TYPES";
export const GET_UNIT_TYPES_RESPONSE = "GET_UNIT_TYPES_RESPONSE";
export const GET_UNIT_TYPES_RESPONSE_FAIL = "GET_UNIT_TYPES_RESPONSE_FAIL";

export function get(isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: GET_UNIT_TYPES,
      isLoading: isLoading,
    });
    return axios
      .get(`/unitType`)
      .then((response) => {
        /*
        console.log("=.=.=.=.=.=.=.=.=.=.=.=.");
        console.log(response);
        console.log("=.=.=.=.=.=.=.=.=.=.=.=.");*/
        dispatch({
          type: GET_UNIT_TYPES_RESPONSE,
          unitTypes: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_UNIT_TYPES_RESPONSE_FAIL,
          isLoading: false,
          error: error,
        });
      });
  };
}
