import axios from "axios";
require("../../../setup/config/apiConfig");

export const GET_OWNER_STATEMENTS = "GET_OWNER_STATEMENTS";
export const GET_OWNER_STATEMENTS_RESPONSE = "GET_OWNER_STATEMENTS_RESPONSE";
export const GET_OWNER_STATEMENTS_RESPONSE_FAIL = "GET_OWNER_STATEMENTS_RESPONSE_FAIL";

export const PRINT_OWNER_STATEMENT_REQUEST = "PRINT_OWNER_STATEMENT_REQUEST";
export const PRINT_OWNER_STATEMENT_RESPONSE = "PRINT_OWNER_STATEMENT_RESPONSE";
export const PRINT_OWNER_STATEMENT_RESPONSE_FAIL = "PRINT_OWNER_STATEMENT_RESPONSE_FAIL";

export const UPDATE_OWNER_STATEMENTS_FOUND =
  "UPDATE_OWNER_STATEMENTS_FOUND";

export function getOwnerStatements(ownerId,
                                   page,
                                   limit,
                                   resetCache = true,
                                   loading = true,
) {
  return async (dispatch) => {
    dispatch({
      type: GET_OWNER_STATEMENTS,
      loading
    });
    return axios
    .get(`/owner-statement/${ownerId}`, {params: {page, limit}})
    .then(
      (response) => {
        let error = "";
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }
          dispatch(
            updateOwnerStatementsFound(
              response.data,
              resetCache
            )
          )
          dispatch({
            type: GET_OWNER_STATEMENTS_RESPONSE,
            loading: false,
            error: error
          })
      }
    )
    .catch((error) => {
      dispatch({
        type: GET_OWNER_STATEMENTS_RESPONSE_FAIL,
        loading: false,
        error: error
      });
    })
  }
}

export function updateOwnerStatementsFound(
  data,
  resetCache
) {
  console.log(resetCache, 'resetCache')
  return {
    type: UPDATE_OWNER_STATEMENTS_FOUND,
    data,
    resetCache,
  };
}

export function printOwnerStatement(ownerStatementId, loading = true, callback) {
  return async (dispatch) => {
    dispatch({
      type: PRINT_OWNER_STATEMENT_REQUEST,
      loading
    });
    try {
      const response = await axios.post(`/pdf/create/ownerStatement-by-id`, { statementId: ownerStatementId });
      let error = "";
      if (response.data.errors && response.data.errors.length > 0) {
        error = response.data.errors[0].message || 'Error Printing PDF';
      }
      dispatch({
        type: PRINT_OWNER_STATEMENT_RESPONSE,
        loading: false,
        error: error
      });
      if (callback) callback(error);
    } catch (error) {
      const errorMessage = error?.response?.data || 'Error Printing PDF';
      dispatch({
        type: PRINT_OWNER_STATEMENT_RESPONSE_FAIL,
        loading: false,
        error: errorMessage
      });
      if (callback) callback(errorMessage);
    }
  }
}
