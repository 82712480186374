
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { logout } from "../../modules/user/api/actions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Grid, IconButton, Button, Tooltip } from '@material-ui/core';

import svgImage7 from "../../assets/images/illustrations/500.svg";
import { NavLink as RouterLink } from 'react-router-dom';
import { LeftSidebar as NavFrame } from "layout-blueprints";
const PageDownForMaintenance = (props) => {
  return (
    <NavFrame page={"PropertyManagerSearch"}>
      <div className="app-wrapper bg-light">
        <div className="app-main">
          <div className="app-content p-0">
            <div className="app-inner-content-layout--main">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--content">
                  <div className="hero-wrapper bg-composed-wrapper min-vh-100">
                    <div className="flex-grow-1 w-100 d-flex align-items-center">
                      <Grid
                        item
                        lg={6}
                        md={9}
                        className="px-4 mx-auto text-center text-black">
                        <img
                          src={svgImage7}
                          className="w-50 mx-auto d-block my-5 img-fluid"
                          alt="..."
                        />

                        <h1 className="display-1 mb-3 px-4 font-weight-bold">
                          Page Currently Down For Maintenance
                        </h1>
                        <h3 className="font-size-xxl line-height-sm font-weight-light d-block px-3 mb-3 text-black-50">
                          The server has the currently selected page marked as down for maintenance.
                        </h3>
                        <p>
                          For more information, contact your system admin at DevAO
                        </p>
                        <Button
                          size="large"
                          color="primary"
                          variant="contained"
                          onClick={(e) => props.history.push(`/dashboard`)}
                          className="text-white mt-4">
                          <span className="btn-wrapper--icon">
                            <FontAwesomeIcon icon={['fas', 'arrow-left']} />
                          </span>
                          <span className="btn-wrapper--label">
                            Back to dashboard
                          </span>
                        </Button>
                      </Grid>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </NavFrame>
  );
}
PageDownForMaintenance.propTypes = {
  user: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
};

// Component State
function PageDownForMaintenanceState(state) {
  return {
    user: state.user,
  };
}
export default connect(PageDownForMaintenanceState, { logout })(
  withRouter(PageDownForMaintenance)
);
