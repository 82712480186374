import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
  deleteAccountUserLink,
  deleteExternalUser,
  getAccountsByUser,
  getUserTableData,
  setSelectedUserProfile,
  updateExternalUsers,
  updateUserStatus
} from "../api/actions";
import {Card, CardContent, IconButton, TextField, Grid, DialogTitle} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import {EditableTable} from "../../../components/EditableTable";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import UserDetailSummaryCard from "./UserDetailSummaryCard";
import Form from "./Form";
import {setSnackbar} from "../../snackbar/api/snackbar";

const UsersPreviewTable = () => {

  const {
    externalUsers,
    isLoading,
    selectedUserProfileAccounts
  } = useSelector(state => state.userProfiles);

  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dialogLoading, setDialogLoading] = useState(false);
  const [resetCache, setResetCachedData] = useState(true);
  const [focus, setFocus] = useState(false);
  const [userCardOpen, setUserCardOpen] = useState(false);
  const [display, setDisplay] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const dispatch = useDispatch();

  const getUsersData = async () => {
    try {
      const queryParams = {
        page,
        rowsPerPage,
        search,
        isLoading: true,
        resetCache
      }
      await dispatch(getUserTableData(queryParams));
    } catch (e) {
      dispatch(setSnackbar(true, "error", "Error Fetching Users!"));
    }
  }

  const show = () => {
    setDisplay(true);
  };

  const hide = () => {
    setDisplay(false);
  };

  useEffect(() => {
    getUsersData();
  }, [dispatch, search, page, rowsPerPage]);

  const handleChangeRowsPerPage = (rowsPerPage) => {
    setPage(0);
    setResetCachedData(false);
    setRowsPerPage(rowsPerPage);
  };

  const handleChangePage = (page) => {
    setResetCachedData(false);
    setPage(page);
  };

  const handleSearchTargetButtonClicked = () => {
    setPage(0);
    setResetCachedData(true);
    getUsersData();
  }
  const handleSearchTargetChange = (event) => {
    setSearch(event.target.value?.trim());
    setPage(0);
    setResetCachedData(true);
    getUsersData();
  }


  const CurrentLookupTypeButton = () => {
    return (
      <IconButton onClick={handleSearchTargetButtonClicked} style={{width: "50px", height: "50px"}}>
        <SearchIcon style={{width: "30px", height: "30px"}}/>
      </IconButton>
    );
  }

  const onEditUser = async (item) => {
    setDialogLoading(true);
    setUserCardOpen(true);
    setSelectedUser(item);
    await dispatch(getAccountsByUser(item.uuid));
    dispatch(setSelectedUserProfile(item))
    setDialogLoading(false);
  }

  const deleteSelectedAccountLinks = async (accountsToDelete) => {
    dispatch(setSnackbar(true, "warning", "Deleting User Account Links"));
    try {
      const promises = accountsToDelete.map(account => {
        dispatch(deleteAccountUserLink(selectedUser.uuid, account))
      })
      await Promise.all(promises);
      dispatch(setSnackbar(true, "success", "Successfully Deleted User Account Links"));

    } catch (e) {
      dispatch(setSnackbar(true, "error", "Error Deleting User Account Link!"));
    }
  }

  const deleteSelectedUsers = async (uuidsToDelete) => {
      const promises = uuidsToDelete.map(uuid => dispatch(deleteExternalUser(uuid)));
      await Promise.all(promises);
  };

  const saveItems = async (itemsObject) => {
    const updatedUsers = itemsObject.items
    .filter(item => itemsObject.toUpdate.includes(item.uuid))
    let promises = [];
    const invalid = updatedUsers.some(user => user.isPM == user.isCustomer)
    if (updatedUsers.length > 0) {
      if (invalid){
        dispatch(setSnackbar(true, "error", "A user cannot hold both 'Customer' and 'PM' statuses. Please review your selection and try again"));
      } else {
        promises = updatedUsers.map(updatedUser => {
          dispatch(updateUserStatus(updatedUser))
        })
        await Promise.all(promises)
      }
    }
  }

  const updateLocalItemsValues = (updatedUsers) => {
    dispatch(updateExternalUsers(updatedUsers.items))
  }

  const NewDialog = () => {
    return (
      <Dialog
        open={display}
        fullWidth={"lg"}
        maxWidth={"lg"}
        keepMounted
        onClose={hide}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">New User Account</DialogTitle>
        <DialogContent>
          <Form handleClose={hide}/>
        </DialogContent>
      </Dialog>
    );
  };

  const fieldTitles = ["First Name", "Last Name", "Email", "PM", "Customer", ""]
  const fieldTypes = ["label", "label", "label", "checkBox", "checkBox", "editBtn"]
  const fieldNames = ["firstName", "lastName", "email", "isPM", "isCustomer", "id"]

  const Accounts_fieldTitles = [
    "Account Number",
    "Last Name",
    "First Name"
  ];
  const Accounts_fieldTypes = [
    "label",
    "label",
    "label"
  ];
  const Accounts_fieldNames = [
    "accountNum",
    "lastName",
    "firstName"
  ];

  return (
    <Card mb={6} elevation={3}>
      <CardContent>
        <Grid container xs={12} alignItems="center" spacing={2}>
          <Grid item lg={12} md={12} sm={12}>
            <TextField
              id={"searchTarget"}
              label="Search For Users"
              type={"text"}
              autoFocus={focus}
              onChange={handleSearchTargetChange}
              name={"searchTarget"}
              defaultValue={search}
              onClick={() => setFocus(true)}
              fullWidth
              autoComplete={"off"}
              my={2}
              size={"small"}
              variant={"outlined"}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyPress={async (ev) => {
                if (ev.key === 'Enter') {
                  ev.preventDefault();
                  setPage(0);
                  setResetCachedData(true);
                  await getUsersData()
                }
              }}
              InputProps={{endAdornment: <CurrentLookupTypeButton/>}}
              helperText="Currently searching by user's email"
            >
            </TextField>
          </Grid>
        </Grid>
        <EditableTable
          readOnly={false}
          canAddItems={false}
          canSave={true}
          canDelete={true}
          isLoading={isLoading}
          denseTable
          verifyDelete
          handlePreviewClickParentMethod={onEditUser}
          tableTitle={"External Users"}
          objectArray={externalUsers.users}
          fieldTitles={fieldTitles}
          fieldTypes={fieldTypes}
          fieldNames={fieldNames}
          customChangePageRowsEvent={handleChangeRowsPerPage}
          customHandleChangePageEvent={handleChangePage}
          defaultItemCount={rowsPerPage}
          totalRowCount={externalUsers.totalCount || 0}
          saveCurrentTableData={saveItems}
          updateItemsLocalState={updateLocalItemsValues}
          manualItemDeletionMethod={deleteSelectedUsers}
        />
      </CardContent>
      <Dialog
        open={userCardOpen}
        fullWidth={"lg"}
        maxWidth={"lg"}
        keepMounted
        onClose={() => setUserCardOpen(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <UserDetailSummaryCard isLoading={dialogLoading}/>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card>
                <CardContent xs={12} sm={12} md={12} lg={12}>
                  <NewDialog/>
                  <EditableTable
                    readOnly={false}
                    canAddItems={!dialogLoading}
                    canSave={false}
                    newItemCreationMethod={show}
                    canDelete={!!selectedUserProfileAccounts.length}
                    verifyDelete
                    manualItemDeletionMethod={deleteSelectedAccountLinks}
                    isLoading={dialogLoading}
                    tableTitle={"User Associated Accounts"}
                    objectArray={{items: selectedUserProfileAccounts, toUpdate: [], toDelete: []}}
                    fieldTitles={Accounts_fieldTitles}
                    fieldTypes={Accounts_fieldTypes}
                    fieldNames={Accounts_fieldNames}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Card>
  );
};

export default UsersPreviewTable;