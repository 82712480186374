import React, { Fragment } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Grid, Card } from '@material-ui/core';
//PM count is pmaccounts - billable to pm - commonlinetime
//TotalCommon billable+commonlineitem
//Tenant Total
//Active Account Total


export default function PropertyStats(props) {
  return (
    <Fragment>
      <Grid container spacing={4}>
        <Grid item xs={12} lg={12}>
          <Card className="card-box mb-4">
            <Grid container spacing={4}>
              <Grid item xs={12} sm={3}>
                <div className="divider-v divider-v-lg" />
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <div className="text-center py-3">
                      <div>
                        {/* <FontAwesomeIcon
                          icon={['far', 'city']}
                          className="font-size-xxl text-success"
                        /> */}
                      </div>
                      <div className="mt-2 line-height-sm">
                        <b className="font-size-lg">{props.activeAccounts}</b>
                        <span className="text-black-50 d-block">Active Accounts</span>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={3}>
                <div className="divider-v divider-v-lg" />
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <div className="text-center py-3">
                      <div>
                        {/* <FontAwesomeIcon
                          icon={['far', 'house']}
                          className="font-size-xxl text-success"
                        /> */}
                      </div>
                      <div className="mt-2 line-height-sm">
                        <b className="font-size-lg">{props.tenantAccounts}</b>
                        <span className="text-black-50 d-block">Tenants</span>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <div className="text-center py-3">
                      <div>
                        {/* <FontAwesomeIcon
                          icon={['far', 'key']}
                          className="font-size-xxl text-danger"
                        /> */}
                      </div>
                      <div className="mt-2 line-height-sm">
                        <b className="font-size-lg">{props.pmAccounts - props.commonLineItem - props.billableToPm}</b>
                        <span className="text-black-50 d-block">PM</span>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <div className="text-center py-3">
                      <div>
                        {/* <FontAwesomeIcon
                          icon={['far', 'keyboard']}
                          className="font-size-xxl text-danger"
                        /> */}
                      </div>
                      <div className="mt-2 line-height-sm">
                        <b className="font-size-lg">{props.commonAccounts}</b>
                        <span className="text-black-50 d-block">Common</span>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Fragment>
  );
}
