import {
  Button as MuiButton,
  Card,
  CardContent,
  Checkbox,
  Divider as MuiDivider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import TableContainer from "@material-ui/core/TableContainer";
import EditIcon from "@material-ui/icons/Edit";
import Skeleton from "@material-ui/lab/Skeleton";
import { spacing } from "@material-ui/system";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import style from "../../property/components/styling/style";
import PropertyGLCodeForm from "./Form";

const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function GLCodeDetailCard(props) {
  const useStyles = style;
  const classes = useStyles();
  const [display, setDisplay] = React.useState(false);

  const toggleDisplay = () => {
    const shouldDisplay = !display;
    setDisplay(shouldDisplay);
  };

  function formatCurrency(s) {
    return "$" + s;
  }

  return (
    <Card mb={6}>
      <CardContent xs={12}>
        <Grid container xs={12}>
          <Grid item xs={9} sm={9} md={9} lg={9}>
            <Typography variant="h6" gutterBottom>
              Accounting ID
            </Typography>
          </Grid>
          <Grid
            item
            xs={3}
            sm={3}
            md={3}
            lg={3}
            className={classes.rightSideHeaderSubMenuToolBar}
          >
            {props.user.details.accounting == 1 ? ( <Button onClick={toggleDisplay}>
              <EditIcon
                fontSize="medium"
                className={classes.SubMenuToolBarIcon}
              />
            </Button>) : (
              <p></p>
            )}
           
            <Dialog
              open={display}
              TransitionComponent={Transition}
              fullWidth={"lg"}
              maxWidth={"lg"}
              keepMounted
              onClose={toggleDisplay}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              {props.propertyGLCode.data.propertyGLCode ? (
                <DialogTitle id="alert-dialog-slide-title">
                  Accounting ID 
                </DialogTitle>
              ) : (
                <DialogTitle id="alert-dialog-slide-title">
                  Loading Account ID.....{" "}
                </DialogTitle>
              )}
              <DialogContent>
                {props.propertyGLCode.data.propertyGLCode && (
                  <PropertyGLCodeForm
                    onUpdate={toggleDisplay}
                    onCancel={toggleDisplay}

                  />
                )}
              </DialogContent>
            </Dialog>
          </Grid>
        </Grid>
        <Divider className="mb-2 mt-2" my={1} />
        {!props.propertyGLCode.data.propertyGLCode ? (
          <>
            <Skeleton
              variant="rect"
              height="40px"
              style={{
                backgroundColor: `#bdbfbe`,
                padding: 6,
                margin: 6,
                marginBottom: 3,
              }}
            />
            </>
        ) : (
          <Grid
            container
            spacing={2}
            lg={12}
            className={classes.PropertyInfoGrid}
          >
            <Grid item xs={12} lg={12} spacing={2}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableBody>
                  < TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1">  Accounting ID </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {props.propertyGLCode.data.propertyGLCode
                            .glCode || ""}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
}

GLCodeDetailCard.propTypes = {
  apl: PropTypes.object.isRequired,
  propertyGLCode: PropTypes.object.isRequired
};

function GLCodeDetailCardState(state) {
  return {
    apl: state.apl,
    user: state.user,
    propertyGLCode: state.propertyGLCode
  };
}

export default connect(GLCodeDetailCardState, {})(GLCodeDetailCard);
