//React Components and Hooks
import {
  Grid,
  IconButton,
  ButtonGroup,
  Button
} from "@material-ui/core";
import {setOwners as setOwnersRedux} from "../api/actions";
import PropTypes from 'prop-types';
import { addAccountsSelectedToCheck } from '../../payments/api/actions';


//Material UI Components
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { Search as SearchIcon } from "@material-ui/icons";
//Custom Context
import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Breadcrumbs from "../../../components/BreadCrumb";
//Context
//Custom Components
import { EditableTable, newConditionalOnSelectButton } from "../../../components/EditableTable";
//Custom Components
import { LeftSidebar as NavFrame } from "layout-blueprints";
// snackbar
import { setSnackbar } from "../../snackbar/api/snackbar";
//modals
import EditOwner from "../../../pages/Owners/modals/EditOwner";
import NewOwner from "../../../pages/Owners/modals/NewOwner";
// utils
import filterOwners from "../../../pages/Owners/utils/filterOwners";
//Styles
const useStyles = makeStyles((theme) => ({
  root: {
    "& label.Mui-focused": {
      color: theme.palette.secondary.main,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: theme.palette.secondary.main,
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: theme.palette.secondary.main,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.secondary.main,
      },
    },
  },
  textcontainer: {
    backgroundColor: theme.palette.secondary.main,
    width: "98%",
    display: "flex",
    justifyContent: "flex-start",
    padding: "1rem",
  },
  headerText: {
    color: theme.palette.secondary.contrastText,
    fontWeight: "bold",
  },
  active: {
    fontWeight: "bold",
    color: "#f9a825",
    borderColor: "#f9a825",
  },
  inactive: {
    fontWeight: "bold",
  }
}));
const OwnersTable = (props) => {

  const classes = useStyles();
  const [searchQuery, setSearchQuery] = useState("");
  const [owners, setOwners] = useState([]);
  const [ownersToDisplay, setOwnersToDisplay] = useState({ items: [] });
  const [ownersLoading, setOwnersLoading] = useState(true);
  const [ownerStatusFilter, setOwnerStatusFilter] = useState("All");
  const [selectedOwner, setSelectedOwner] = useState({});
  const [ownersWithStatementData , setOwnersWithStatementData] = useState(props.owners?.data?.ownersStatementDataList || null);
  const [focus, setFocus] = useState(false)
  const [refreshDataSwitch, setRefreshDataSwitch] = useState(false); // switches between true and false to trigger useEffect refetching of data

  const [editFormOpen, setEditFormOpen] = useState(false);
  const [newOwnerFormOpen, setNewOwnerFormOpen] = useState(false);

  const dispatch = useDispatch();

  const changeOwnersDisplaying = (owners, term, status) => {
    let filteredOwners = filterOwners(owners, term, status);

    setOwnersToDisplay(filteredOwners);
  };

useEffect(() => {
  document.title = "Ubil+ Owners";
  setOwnersLoading(false);
  setOwners(props.owners?.data?.ownersStatementDataList || null);
  changeOwnersDisplaying(props.owners?.data?.ownersStatementDataList?.items || [], searchQuery, ownerStatusFilter);
}, [refreshDataSwitch]);

const rowStyling = (owner) => {
  if (owner.active) {
    owner.status = "Active";
  }
  else {
    owner.status = "Inactive";
  }
  // Adding until added to data set
  if (owner.glCode == null) {
    owner.glCode = "N/A"
  }
  return {}
};

const fieldTitles = ["Name", "Contact Name", "Status", "Address", "Account ID"];
const fieldTypes = [
  "labelSmall",
  "labelSmall",
  "labelSmall",
  ["labelSmall", "labelSmall"],
  "labelSmall",
];
const fieldNames = [
  "name",
  "contactName",
  "status",
  ["addrLine1", "addrLine2"],
  "glCode",
];

const fieldTitlesBulkChecks = ["Name", "Contact Name", "Status", "Address", "Account ID", "Statement Total"];
const fieldTypesBulkChecks = [
  "labelSmall",
  "labelSmall",
  "labelSmall",
  ["labelSmall", "labelSmall"],
  "labelSmall",
  "labelSmall"
];
const fieldNamesBulkChecks = [
  "name",
  "contactName",
  "status",
  ["addrLine1", "addrLine2"],
  "glCode",
  "statementTotal"
];

const handleSearchQueryInput = (event) => {
  setSearchQuery(String(event.target.value));
  changeOwnersDisplaying(owners.items, event.target.value, ownerStatusFilter);
};
const addOwnerSelectedToCheck = (items) => {
  for (var x = 0; x < items.length; x++) {
    items[x].amountCovered = 0;
    items[x].balance = 0
    items[x].isOwner = true;
    items[x].accountStatusId = items[x].active == 1 ? 1 : 4;
    items[x].customerTypeId = 5;
    if (items[x].statementTotal > 0) {
      items[x].amountCovered = items[x].statementTotal;
      items[x].balance = items[x].statementTotal;
     
    }
  }
  console.log(JSON.stringify(items, null, 2))
  props.addAccountsSelectedToCheck(JSON.parse(JSON.stringify(items)))
};

const ConditionalOnSelectButtons =  [

  newConditionalOnSelectButton("Add Owner", () => { return (true) }, addOwnerSelectedToCheck)

]
const setAutoFocus = () =>{
  setFocus(true);
}


const CurrentLookupTypeButton = () => {
  return (
    <IconButton>
      <SearchIcon mr={2} />
    </IconButton>
  );
};

const onSelected = (item) => {
  window.open("/propertyOwner/" + item.uuid + "/" + item.id);
};
const unsetFocus = () => {
  setFocus(false);
};
const SearchBar = () => {
  return (
    <TextField
      id={"searchTarget"}
      label="Search Owners"
      type={"text"}
      placeholder={"Search by name, contact name, address, and acct #..."}
      onChange={handleSearchQueryInput}
      autoFocus={focus}
      autoComplete={"off"}
      //ref={inputElement}
      inputRef={(text) => text}
      name={"searchTarget"}
      defaultValue={searchQuery}
      onClick={setAutoFocus}
      onBlur={unsetFocus}
      fullWidth
      size={"small"}
      variant={"outlined"}
      my={4}
      ml={4}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{ endAdornment: <CurrentLookupTypeButton /> }}
    ></TextField>
  );
};

const changeOwnerStatus = (val) => {
  setOwnerStatusFilter(val);
  changeOwnersDisplaying(owners, searchQuery, val);
};

const handleOwnerEditFormOpen = (item) => {
  setSelectedOwner(item);
  setEditFormOpen(true);
};

const handleCloseEdit = () => {
  setEditFormOpen(false);
};

const handleNewOwnerFormOpen = () => {
  setNewOwnerFormOpen(true);
};

const handleNewOwnerFormClose = () => {
  setNewOwnerFormOpen(false);
};

const submitOwnerEdit = async (originalOwnerObj, editOwnerObj) => {
  try {
    let headers = {
      "x-access-token": localStorage.getItem("token"),
    };

    let data = await axios.put(
      `/owner/${originalOwnerObj.uuid}/?consolidatedEdit=true`,
      editOwnerObj,
      { headers }
    );

    if (data.status != 200) throw new Error("Fail.");

    dispatch(setSnackbar(true, "success", "Successfully edited owner info."));
    setRefreshDataSwitch(!refreshDataSwitch);
  } catch (err) {
    dispatch(setSnackbar(true, "error", "Failed to edit owner."));
  }
};

const submitNewOwner = async (ownerObj) => {
  try {
    let headers = {
      "x-access-token": localStorage.getItem("token"),
    };

    let data = await axios.post(`/owner`, ownerObj, { headers });

    if (data.status != 200) throw new Error("Fail.");

    dispatch(
      setSnackbar(true, "success", "Successfully created new owner info.")
    );
    setRefreshDataSwitch(!refreshDataSwitch);
  } catch (err) {
    console.log("hello?!??!");
    dispatch(setSnackbar(true, "error", "Failed to create new owner."));
  }
};

const Filters = () => {
  return (
    <Grid
      container
      item
      direction="row"
      xs={12}
      spacing={1}
      alignItems="center"
    >
      <Grid item lg={6} md={6} sm={6}>
        <ButtonGroup
          color="primary"
        >
          <Button
            onClick={() => { changeOwnerStatus("All") }}
            className={
              ownerStatusFilter == "All"
                ? classes.active
                : classes.inactive
            }
          >
            All
          </Button>
          <Button
            onClick={() => { changeOwnerStatus("Active") }}
            className={
              ownerStatusFilter == "Active"
                ? classes.active
                : classes.inactive
            }
          >
            Active
          </Button>
          <Button
            onClick={() => { changeOwnerStatus("Inactive") }}
            className={
              ownerStatusFilter == "Inactive"
                ? classes.active
                : classes.inactive
            }
          >
            Inactive
          </Button>
        </ButtonGroup>
      </Grid>
      {
        /*<Grid item xs={6}>
          <ButtonGroupSelect
            label="filter"
            options={["All", "Active", "Inactive"]}
            defaultValue={ownerStatusFilter}
            onChange={changeOwnerStatus}
          />
        </Grid>*/
      }
    </Grid>
  );
};

const toShowAll = { items: JSON.parse(JSON.stringify(ownersToDisplay.items)), toUpdate: [], toDelete: [] };
// Loops through each item:
for (var x = 0; x < toShowAll.items.length;) {
  // Filtering by Search Query
  if (ownerStatusFilter != "All") {
    if ((ownerStatusFilter == "Active" && toShowAll.items[x].status != "Active") || (ownerStatusFilter == "Inactive" && toShowAll.items[x].status != "Inactive")) {
      toShowAll.items.splice(x, 1);
      continue;
    }
  }
  x++;
}



  return (
    <>
      {/* <EditOwner
        open={editFormOpen}
        handleClose={handleCloseEdit}
        owner={selectedOwner}
        submitOwnerEdit={submitOwnerEdit}
      />
      <NewOwner
        open={newOwnerFormOpen}
        handleClose={handleNewOwnerFormClose}
        submitNewOwner={submitNewOwner}
      /> */}
      <Grid container className={classes.root}>
        <Grid container item direction="column" xs={12}>
          <Grid container>
            <Grid
              item
              container
              direction="row"
              xs={12}
              justify="space-between"
            >
              <Grid item xs={12} sm={12} md={6}>
                <Typography variant="h4" gutterBottom>
                  Owners
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Breadcrumbs current={"Owners"} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={6} justify="space-between">
            <Grid item xs={2}></Grid>
            <Grid item xs={4}>
              <SearchBar />
            </Grid>
            {!props.isBulkCheckEntry && <Grid item xs={4}>
              <Button
                onClick={handleNewOwnerFormOpen}
                size="small"
                variant="contained"
                color="secondary"
              >
                {" "}
                New Owner
              </Button>
            </Grid>}
            <Grid item xs={2}></Grid>
          </Grid>
          <Grid container direction="column" spacing={1}>
            <Grid container item direction="row" justify="space-between">
              {props.isBulkCheckEntry ? <EditableTable
                // Functional methods of table
                Filters={<Filters />}
                canSave={false}
                isLoading={ownersLoading}
                canAddItems={false}
                currentActiveFilters={[ownerStatusFilter]}
                defaultItemCount={10}
                // Properties of table
                tableTitle={"Owners"}
                objectArray={ownersToDisplay}
                conditionalStylingMethod={rowStyling}
                fieldTitles={fieldTitlesBulkChecks}
                fieldTypes={fieldTypesBulkChecks}
                fieldNames={fieldNamesBulkChecks}
                canExport={true}
                showItemNumber={true}
                conditionalOnSelectButtons={ConditionalOnSelectButtons}
                handlePreviewClickParentMethod={handleOwnerEditFormOpen}
              /> :
              <EditableTable
                // Functional methods of table
                Filters={<Filters />}
                canSave={false}
                isLoading={ownersLoading}
                canAddItems={false}
                saveOnTextboxLeave={true}
                currentActiveFilters={[ownerStatusFilter]}
                defaultItemCount={10}
                canDelete={false}
                // Properties of table
                tableTitle={"Owners"}
                objectArray={ownersToDisplay}
                conditionalStylingMethod={rowStyling}
                fieldTitles={fieldTitles}
                fieldTypes={fieldTypes}
                fieldNames={fieldNames}
                canExport={true}
                showItemNumber={true}
                selectItemAction={onSelected}
                handlePreviewClickParentMethod={handleOwnerEditFormOpen}
              />
            }
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
OwnersTable.propTypes = {
  owners: PropTypes.object.isRequired

}
function OwnersTableState(state){
  return {
    owners: state.owners
  }
}
export default connect(OwnersTableState, {addAccountsSelectedToCheck})(OwnersTable)