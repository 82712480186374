import {
  CREATED_MOVE_IN,
  DELETE_MOVE_IN,
  DELETE_MOVE_OUT,
  EDITED_MOVE_IN,
  EDITED_MOVE_OUT,
  FETCHED_MOVES,
  HANDLE_ERROR,
  PROCESSED_MOVE_INS,
  PROCESSED_MOVE_OUTS,
  SET_LOADING,
  SET_TAB_STATE,
  UPDATED_MOVE_INS,
  UPDATED_MOVE_OUTS,
  UPDATE_MOVE_IN_REQUEST,
  UPDATE_MOVE_IN_RESPONSE,
  UPDATE_MOVE_OUT_REQUEST,
  UPDATE_MOVE_OUT_RESPONSE,
  UPDATE_MOVE_FAILED,
  GET_MOVE_OUTS_RESPONSE,
  GET_MOVE_INS_RESPONSE,
  GET_MOVES_REQUEST,
  START_BULK_MOVES_LOADING,
  GET_MOVES_FAILED
} from "./actions";
import moment from "moment";

const today = moment();
// Initial State
const MovesInitialState = {
  isLoading: false,
  movesLoaded: null,
  movesToLoad: null,
  error: null,
  data: {
    moveIns: [],
    moveOuts: [],
    loadedMoveIns: {
      items: [],
      count: 0,
      toDelete: [],
      toUpdate: [],
    },
    loadedMoveOuts: {
      items: [],
      toDelete: [],
      toUpdate: [],
    },
    tabState: "Current/Past",
  },
  accountData: {
    moveIn: null,
    moveOut: null,
    isLoading: false,
    error: null
  },
  newMoveIn: {
    firstName: "",
    lastName: "",
    unitUuid: -1,
    targetAccountUuid: -1,
    email: "",
    emailValidation: "",
    phone1: "",
    phone2: "",
    // Billing Address Info:
    billingAddress1: "",
    billingAddress2: "",
    billingCity: "",
    billingState: "",
    billingZip: "",
  },
  newMoveOut: {
    moveOutDate: today,
    meterReads: {},
    fwdAddress1: "",
    fwdAddress2: "",
    fwdCity: "",
    fwdState: "",
    fwdZip: "",
    email: "",
    phone: "",
    moveInPropertyManager: 1,
    createFinalBill: 0,
    billDueDate: today,
  }
};

// State
export default (state = MovesInitialState, action) => {
  switch (action.type) {

    case GET_MOVE_OUTS_RESPONSE:
      var newMoveOutsData = state.data;
      if (action.response && action.response.moveOuts) {
        // this is for garage meter reads
        // sort by move uuid
        action.response.moveOuts[0].sort( function (a, b) { 
          return a.uuid - b.uuid;
        });
        for (var i = 0; i < action.response.moveOuts[0].length; i++) {
          if (i > 0 && action.response.moveOuts[0][i].uuid === action.response.moveOuts[0][i - 1].uuid) {
            const secondMove = action.response.moveOuts[0][i];
            // get all moveouts with the same uuid
            const sameUuid = action.response.moveOuts[0].filter(move => move.uuid === secondMove.uuid);
            // filter out moveouts with same meter number
            const sameMeter = sameUuid.filter(move => move.meterNum === secondMove.meterNum);
            //remove sameMeter from action.response.moveOuts
            action.response.moveOuts[0] = action.response.moveOuts[0].filter(move => !sameMeter.includes(move));
            
            const secondMeter = {}
            //remove the second move
            action.response.moveOuts[0].splice(i, 1);
            //add the second move's meter info to the first move
            secondMeter.meterNum = secondMove.meterNum;
            secondMeter.meterRead = secondMove.meterRead;
            secondMeter.secondRead = secondMove.secondRead;
            secondMeter.prevRead = secondMove.prevRead;
            secondMeter.prevReadTypeDesc = secondMove.prevReadTypeDesc;
            secondMeter.prevReadDate = secondMove.prevReadDate;
            secondMeter.secondReadDate = secondMove.secondReadDate;
            secondMeter.abwtiRate = secondMove.abwtiRate;
            secondMeter.abwtiUsage = secondMove.abwtiUsage;
            secondMeter.abwtiTotalCharge = secondMove.abwtiTotalCharge;
            secondMeter.workTableUuid = secondMove.workTableUuid;
            action.response.moveOuts[0][i - 1].secondMeter = secondMeter;
            

          }
        }
        newMoveOutsData.loadedMoveOuts.items = action.response.moveOuts[0].sort(
          function (a, b) {
            if (a.propertyName != b.propertyName) {
              // Price is only important when cities are the same
              return b.propertyName < a.propertyName ? 1 : -1;
            }
            if (b.serviceAddress != a.serviceAddress) {
              return b.serviceAddress < a.serviceAddress ? 1 : -1;
            }
            return a.moveOutDate < b.moveOutDate ? 1 : -1;
          });
      }
      newMoveOutsData.loadedMoveOuts.count = action.response.moveOutsCount[0][0].c;
      return { ...state, data: newMoveOutsData, isLoading: false };
    case GET_MOVE_INS_RESPONSE:
      var newMoveInsData = state.data;
      if (action.response && action.response.moveIns) {
        newMoveInsData.loadedMoveIns.items = action.response.moveIns[0].sort(
          function (a, b) {
            if (a.propertyName != b.propertyName) {
              // Price is only important when cities are the same
              return b.propertyName < a.propertyName ? 1 : -1;
            }
            if (b.serviceAddress != a.serviceAddress) {
              return b.serviceAddress < a.serviceAddress ? 1 : -1;
            }
            return a.moveInDate < b.moveInDate ? 1 : -1;
          });
      }
      newMoveOutsData.loadedMoveIns.count = action.response.moveInsCount[0][0].c;

      return { ...state, data: newMoveInsData, isLoading: false };


    case START_BULK_MOVES_LOADING:
      return {
        ...state,
        movesLoaded: 0,
        movesToLoad: action.itemsCount
      };

    case GET_MOVES_FAILED:
      console.log("Error retrieving moves history");
      console.log(action.error);
      return { ...state, error: "Error retrieving moves history", isLoading: false };

    case GET_MOVES_REQUEST:
      return { ...state, error: null, isLoading: true };


    case UPDATE_MOVE_IN_REQUEST:
      var newAccountData = state.accountData;
      newAccountData.isLoading = true;
      newAccountData.error = null;
      return { ...state, accountData: newAccountData };

    case UPDATE_MOVE_IN_RESPONSE:
      var newAccountData = state.accountData;
      newAccountData.isLoading = false;
      if (!action.response.id) {
        newAccountData.error = "Error updating move in";
        console.log(newAccountData.error);
      }
      return { ...state, accountData: newAccountData };


    case UPDATE_MOVE_OUT_REQUEST:
      var newAccountData = state.accountData;
      newAccountData.isLoading = true;
      newAccountData.error = null;
      return { ...state, accountData: newAccountData };


    case UPDATE_MOVE_OUT_RESPONSE:
      var newAccountData = state.accountData;
      newAccountData.isLoading = false;
      if (!action.response.id) {
        newAccountData.error = "Error updating move out";
      }
      return { ...state, accountData: newAccountData };

    case UPDATE_MOVE_FAILED:
      var newAccountData = state.accountData;
      newAccountData.isLoading = false;
      newAccountData.error = "Error updating move out";
      return { ...state, accountData: newAccountData };

    case SET_TAB_STATE:
      let newTabState = Object.assign({}, state.data, {
        tabState: action.tabName,
      });
      return { ...state, data: newTabState, isLoading: false };

    case FETCHED_MOVES:
      let moveInsWithSetHold = action.moves.moveIns.map((moveIn) => {
        if (moveIn.prevReadBilled == 0) {
          moveIn.onHold = 1;
        }

        return moveIn;
      });
      let moveOutsWithSetHold = action.moves.moveOuts.map((moveOut) => {
        if (moveOut.prevReadBilled == 0) {
          moveOut.onHold = 1;
        }

        return moveOut;
      });
      const movesData = Object.assign({}, state.data, {
        moveIns: moveInsWithSetHold,
        moveOuts: moveOutsWithSetHold,
      });
      return { ...state, data: movesData, isLoading: false };

    case SET_LOADING:
      return Object.assign({}, state, { isLoading: action.isLoading });

    case HANDLE_ERROR:
      return Object.assign({}, state, {
        error: action.error,
        isLoading: false,
        movesToLoad: null,
        movesLoaded: null
      });

    case DELETE_MOVE_IN:
      let ind = state.data.moveIns.findIndex(
        (elem) => elem.uuid == action.uuid
      );
      let newMoveInsCopy = [
        ...state.data.moveIns.slice(0, ind),
        ...state.data.moveIns.slice(ind + 1),
      ];
      let moveInsData = Object.assign({}, state.data, {
        moveIns: newMoveInsCopy,
      });
      return { ...state, data: moveInsData, isLoading: false };

    case DELETE_MOVE_OUT:
      let moind = state.data.moveOuts.findIndex(
        (elem) => elem.uuid == action.uuid
      );
      let newMoveOutsCopy = [
        ...state.data.moveOuts.slice(0, moind),
        ...state.data.moveOuts.slice(moind + 1),
      ];
      let moveOutsData = Object.assign({}, state.data, {
        moveOuts: newMoveOutsCopy,
      });
      return { ...state, data: moveOutsData, isLoading: false };

    case UPDATED_MOVE_INS:
      const newMoveIns = [];
      state.data.moveIns.forEach((moveIn) => {
        let didMatch = false;
        action.uuids.forEach((uuid) => {
          if (uuid == moveIn.uuid) {
            let keyToUpdate = Object.keys(action.keyValPair)[0];
            let newMove = Object.assign({}, moveIn, {
              [keyToUpdate]: action.keyValPair[keyToUpdate],
            });
            newMoveIns.push(newMove);
            didMatch = true;
          }
        });
        if (!didMatch) newMoveIns.push(moveIn);
      });

      let updatedMoveInsData = Object.assign({}, state.data, {
        moveIns: newMoveIns,
      });
      return { ...state, data: updatedMoveInsData, isLoading: false };

    case UPDATED_MOVE_OUTS:
      const newMoveOuts = [];
      state.data.moveOuts.forEach((moveOut) => {
        action.uuids.forEach((uuid) => {
          if (uuid == moveOut.uuid) {
            let keyToUpdate = Object.keys(action.keyValPair)[0];
            let newMove = Object.assign({}, moveOut, {
              [keyToUpdate]: action.keyValPair[keyToUpdate],
            });
            newMoveOuts.push(newMove);
          } else newMoveOuts.push(moveOut);
        });
      });

      let updatedMoveOutsData = Object.assign({}, state.data, {
        moveOuts: newMoveOuts,
      });
      return { ...state, data: updatedMoveOutsData, isLoading: false };
    case EDITED_MOVE_IN:

      //console.log("action.newObj");
      //console.log(action.newObj);
      //console.log(.newObj);
      let editedDataMoveIns = state.data;
      if (Array.isArray(action.newObj)) {
        for (var x = 0; x < editedDataMoveIns.loadedMoveIns.items.length; x++) {
          for (var y = 0; y < action.newObj.length; y++) {
            if (editedDataMoveIns.loadedMoveIns.items[x].uuid == action.newObj[y].uuid) {
              editedDataMoveIns.loadedMoveIns.items[x] = action.newObj[y];
              y = action.newObj.length;
            }
          }
        }
      }
      else {
        for (var x = 0; x < editedDataMoveIns.loadedMoveIns.items.length; x++) {
          if (editedDataMoveIns.loadedMoveIns.items[x].uuid == action.newObj.uuid) {
            editedDataMoveIns.loadedMoveIns.items[x] = action.newObj;
            x = editedDataMoveIns.loadedMoveIns.items.length;
          }
        }
      }
      // Loading progression
      if (state.movesToLoad != null) {
        var currentLoaded = state.movesLoaded + 1;
        if (state.movesToLoad == currentLoaded) {
          return { ...state, data: editedDataMoveOuts, isLoading: false, movesToLoad: -1, movesLoaded: -1 };
        }
        return { ...state, data: editedDataMoveIns, isLoading: false, movesLoaded: currentLoaded };
      }
      return { ...state, data: editedDataMoveIns, isLoading: false };

    case EDITED_MOVE_OUT:
      //console.log("action.newObj");
      //console.log(action.newObj);
      let editedDataMoveOuts = state.data;
      if (Array.isArray(action.newObj)) {
        for (var x = 0; x < editedDataMoveOuts.loadedMoveOuts.items.length; x++) {
          for (var y = 0; y < action.newObj.length; y++) {
            if (editedDataMoveOuts.loadedMoveOuts.items[x].uuid == action.newObj[y].uuid) {
              editedDataMoveOuts.loadedMoveOuts.items[x] = action.newObj[y];
              y = action.newObj.length;
            }
          }
        }
      }
      else {
        for (var x = 0; x < editedDataMoveOuts.loadedMoveOuts.items.length; x++) {
          if (editedDataMoveOuts.loadedMoveOuts.items[x].uuid == action.newObj.uuid) {
            editedDataMoveOuts.loadedMoveOuts.items[x] = action.newObj;
            x = editedDataMoveOuts.loadedMoveOuts.items.length;
          }
        }
      }
      // Loading progression
      if (state.movesToLoad != null) {
        var currentLoaded = state.movesLoaded + 1;
        if (state.movesToLoad == currentLoaded) {
          return { ...state, data: editedDataMoveOuts, isLoading: false, movesToLoad: -1, movesLoaded: -1 };
        }
        return { ...state, data: editedDataMoveOuts, isLoading: false, movesLoaded: currentLoaded };
      }

      return { ...state, data: editedDataMoveOuts, isLoading: false };

    case PROCESSED_MOVE_INS:
      const newMoveInsWithProcessed = [];
      state.data.moveIns.forEach((moveIn) => {
        let didMatch = false;
        action.uuids.forEach((uuid) => {
          if (moveIn.uuid == uuid) {
            let newPMoveIn = Object.assign({}, moveIn, { processed: 1 });
            newMoveInsWithProcessed.push(newPMoveIn);
            didMatch = true;
          }
        });

        if (!didMatch) newMoveInsWithProcessed.push(moveIn);
      });

      let processedMoveIns = Object.assign({}, state.data, {
        moveIns: newMoveInsWithProcessed,
      });
      return { ...state, data: processedMoveIns, isLoading: false };

    case PROCESSED_MOVE_OUTS:
      const newMoveOutsWithProcessed = [];

      state.data.moveOuts.forEach((moveOut) => {
        let didMatch = false;
        action.uuids.forEach((uuid) => {
          if (moveOut.uuid == uuid) {
            let newPMoveOut = Object.assign({}, moveOut, { processed: 1 });
            newMoveOutsWithProcessed.push(newPMoveOut);
            didMatch = true;
          }
        });

        if (!didMatch) newMoveOutsWithProcessed.push(moveOut);
      });

      let processedMoveOuts = Object.assign({}, state.data, {
        moveIns: newMoveOutsWithProcessed,
      });
      return { ...state, data: processedMoveOuts, isLoading: false };

    case CREATED_MOVE_IN:
      let moveInsWithNew = state.data.moveIns.concat([action.moveIn]);
      let moveInsWithNewState = Object.assign({}, state.data, {
        moveIns: moveInsWithNew,
      });
      return { ...state, data: moveInsWithNewState, isLoading: false };

    default:
      return state;
  }
};
