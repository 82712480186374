// Imports
import axios from "axios";
import { GET, POST } from "../../genericAPI/api/actions";
import moment from "moment";
import { setSnackbar } from "modules/snackbar/api/snackbar";
require("../../../setup/config/apiConfig");

export const RESET = "RESET";
export const SEARCH_REQUEST = "SEARCH_REQUEST";
export const SEARCH_RESPONSE = "SEARCH_RESPONSE";
export const SEARCH_FAILURE = "SEARCH_FAILURE";

export const GET_UNITS = "GET_UNITS";
export const GET_UNITS_RESPONSE = "GET_UNITS_RESPONSE";
export const GET_UNITS_FAIL = "GET_UNITS_RESPONSE_FAIL";

export const GET_UNIT = "GET_UNIT";
export const GET_UNIT_RESPONSE = "GET_UNIT_RESPONSE";
export const GET_UNIT_FAIL = "GET_UNIT_FAIL";

export const GET_CURRENT_OCCUPANT_REQUEST = "GET_CURRENT_OCCUPANT_REQUEST";
export const GET_CURRENT_OCCUPANT_RESPONSE = "GET_CURRENT_OCCUPANT_RESPONSE";
export const GET_CURRENT_OCCUPANT_FAIL = "GET_CURRENT_OCCUPANT_FAIL";

export const GET_ALL_UNIT_OCCUPANTS_REQUEST = "GET_ALL_UNIT_OCCUPANTS_REQUEST";
export const GET_ALL_UNIT_OCCUPANTS_RESPONSE = "GET_ALL_UNIT_OCCUPANTS_RESPONSE";
export const GET_ALL_UNIT_OCCUPANTS_FAIL = "GET_ALL_UNIT_OCCUPANTS_FAIL";

export const UPDATE_CURRENT_OCCUPANT_REQUEST = "UPDATE_CURRENT_OCCUPANT_REQUEST";
export const UPDATE_CURRENT_OCCUPANT_RESPONSE = "UPDATE_CURRENT_OCCUPANT_RESPONSE";
export const UPDATE_CURRENT_OCCUPANT_FAIL = "UPDATE_CURRENT_OCCUPANT_FAIL";



export const POST_UNIT = "POST_UNIT";
export const POST_UNIT_RESPONSE = "POST_UNIT_RESPONSE";
export const POST_UNIT_FAIL = "POST_UNIT_FAIL";

export const PUT_UNIT = "PUT_UNIT";
export const PUT_UNIT_RESPONSE = "PUT_UNIT_RESPONSE";
export const PUT_UNIT_FAIL = "PUT_UNIT_FAIL";

export const DELETE_UNIT_REQUEST = "DELETE_UNIT_REQUEST"; 
export const DELETE_UNIT_RESPONSE = "DELETE_UNIT_RESPONSE";
export const DELETE_UNIT_FAIL = "DELETE_UNIT_FAIL";


export const UPDATE_SELECTED_UNIT_FOR_TRANSFER = "UPDATE_SELECTED_UNIT_FOR_TRANSFER";

export const TRANSFER_SELECTED_UNIT_REQUEST = "TRANSFER_SELECTED_UNIT_REQUEST";
export const TRANSFER_SELECTED_UNIT_RESPONSE = "TRANSFER_SELECTED_UNIT_RESPONSE";
export const TRANSFER_SELECTED_UNIT_FAILURE = "TRANSFER_SELECTED_UNIT_FAILURE";

export const TRANSFER_SELECTED_UNIT_PM_REQUEST = "TRANSFER_SELECTED_UNIT_PM_REQUEST";
export const TRANSFER_SELECTED_UNIT_PM_RESPONSE = "TRANSFER_SELECTED_UNIT_PM_RESPONSE";
export const TRANSFER_SELECTED_UNIT_PM_FAILURE = "TRANSFER_SELECTED_UNIT_PM_FAILURE";

export const TRANSFER_SELECTED_UNIT_OWNER_REQUEST = "TRANSFER_SELECTED_UNIT_OWNER_REQUEST";
export const TRANSFER_SELECTED_UNIT_OWNER_RESPONSE = "TRANSFER_SELECTED_UNIT_OWNER_RESPONSE";
export const TRANSFER_SELECTED_UNIT_OWNER_FAILURE = "TRANSFER_SELECTED_UNIT_OWNER_FAILURE";

export function updateSelectedUnitForTransfer(unit) {
  return { type: UPDATE_SELECTED_UNIT_FOR_TRANSFER, unit };
}

export function submitUnitSwap(unitUuid, AccountUuid) {
  var object = { accountUuid: AccountUuid, unitUuid: unitUuid };
  return POST("unit/submit-unit-swap", "", object, TRANSFER_SELECTED_UNIT_REQUEST, TRANSFER_SELECTED_UNIT_RESPONSE, TRANSFER_SELECTED_UNIT_FAILURE);
}

export function transferUnitsToNewPm(unitUuids, pmUuid, doneByDate, billingType) {
  var object = { units: unitUuids, propertyManagerUuid: pmUuid, doneByDate: doneByDate, billingType };
  return POST("property/unit/pm-transfer", "", object, TRANSFER_SELECTED_UNIT_PM_REQUEST, TRANSFER_SELECTED_UNIT_PM_RESPONSE, TRANSFER_SELECTED_UNIT_PM_FAILURE);
}

export function transferUnitsToOwner(units, ownerUuid, doneByDate) {
  const object = { units, ownerUuid: ownerUuid, doneByDate: doneByDate};
  return POST("property/unit/owner-transfer", "", object, TRANSFER_SELECTED_UNIT_OWNER_REQUEST, TRANSFER_SELECTED_UNIT_OWNER_RESPONSE, TRANSFER_SELECTED_UNIT_OWNER_FAILURE);
}

export function get(searchQuery, isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: SEARCH_REQUEST,
      isLoading: isLoading,
    });
    return axios
      .get("/unit", { params: null })
      .then((response) => {
        //if (validateResponse(response.data.filters, filters)) dispatch(updateSearchResults(response.data.result));
        dispatch({
          type: SEARCH_RESPONSE,
          units: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: SEARCH_FAILURE,
          isLoading: false,
          error: error,
        });
      });
  };
}

export function deleteUnit(uuid, isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: DELETE_UNIT_REQUEST,
      isLoading: isLoading,
    });
    return axios
      .delete(`/unit/${uuid}`, {})
      .then((response) => {
        dispatch({
          type: DELETE_UNIT_RESPONSE,
          unit: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: DELETE_UNIT_FAIL,
          isLoading: false,
          error: error,
        });
      });
  };
}



export function getByPropertyId(propertyId, searchQuery, isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: SEARCH_REQUEST,
      isLoading: isLoading,
    });
    let queryParams =
      searchQuery && searchQuery.length > 0
        ? {
          unitType: searchQuery,
          ownerName: searchQuery,
          address: searchQuery,
        }
        : {};
    return axios
      .get(`/property/${propertyId}/unit`, { params: queryParams })
      .then((response) => {
        //if (validateResponse(response.data.filters, filters)) dispatch(updateSearchResults(response.data.result));
        dispatch({
          type: SEARCH_RESPONSE,
          units: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: SEARCH_FAILURE,
          isLoading: false,
          error: error,
        });
      });
  };
}

export function getByUuid(uuid, isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: GET_UNIT,
      isLoading: isLoading,
    });
    return axios
      .get(`/unit/${uuid}`, {})
      .then((response) => {
        dispatch({
          type: GET_UNIT_RESPONSE,
          unit: response.data[0],
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_UNIT_FAIL,
          isLoading: false,
          error: error,
        });
      });
  };
}
export function getUnitCurrentOccupant(unitUuid, isLoading = false) {
  return (dispatch) => {
    dispatch({
      type: GET_CURRENT_OCCUPANT_REQUEST,
      isLoading,
    });
    return axios
      .get(`/unit-current-occupant/${unitUuid}` , {})
      .then((response) => {
        dispatch({
          currentOccupant: response.data,
          type: GET_CURRENT_OCCUPANT_RESPONSE,
        });
      })
      .catch((error) => {
        //console.log(error);
        dispatch({
          type: GET_CURRENT_OCCUPANT_FAIL,
          error: error,
        });
      });
  };
}

export function getUnitAllOccupants(unitUuid, isLoading = false) {
  return (dispatch) => {
    dispatch({
      type: GET_ALL_UNIT_OCCUPANTS_REQUEST,
      isLoading: isLoading,
    });
    return axios
      .get(`/unit-all-occupants/${unitUuid}` , {})
      .then((response) => {
        response.data.map((i) => {
          i.created = moment(i.created).format('YYYY-MM-DD');
        })
        response.data .sort((a, b) =>{
          return (a.isCurrentOccupant === b.isCurrentOccupant)? 0 : a.isCurrentOccupant? -1 : 1;
        })
        dispatch({
          data: response.data,
          type: GET_ALL_UNIT_OCCUPANTS_RESPONSE,
          isLoading: false
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: GET_ALL_UNIT_OCCUPANTS_FAIL,
          isLoading: false,
          error: error,
        });
      });
  };
}

export function updateCurrentOccupant( accountUuid, unitUuid, isLoading = false) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_CURRENT_OCCUPANT_REQUEST,
      isLoading: isLoading,
    });
    return axios
      .put(`/update-current-occupant/account/${accountUuid}/unit/${unitUuid}`)
      .then((response) => {
        dispatch(setSnackbar(true, "success", "Current Occupant Updated Successfully"),{
          data: response.data,
          type: UPDATE_CURRENT_OCCUPANT_RESPONSE,
          isLoading: isLoading
        });
      })
      .catch((error) => {
        //console.log(error);
        dispatch(setSnackbar(true, "error", `${error.response.data.message || 'Error Updating Current Occupant'}`),{
          type: UPDATE_CURRENT_OCCUPANT_FAIL,
          isLoading: isLoading,
          error: error,
        });
      });
  };
}

export function post(u, isLoading = false) {
  return (dispatch) => {
    dispatch({
      type: POST_UNIT,
      isLoading,
    });
    return axios
      .post("/unit/", u)
      .then((response) => {
        dispatch({
          unit: response.data,
          type: POST_UNIT_RESPONSE,
        });
      })
      .catch((error) => {
        //console.log(error);
        dispatch({
          type: POST_UNIT_FAIL,
          error: error,
        });
      });
  };
}

export function put(u, isLoading = false) {
  return (dispatch) => {
    dispatch({
      type: PUT_UNIT,
      isLoading,
    });
    return axios
      .put("/unit/" + u.uuid, u)
      .then((response) => {
        dispatch({
          unit: response.data,
          type: PUT_UNIT_RESPONSE,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: PUT_UNIT_FAIL,
          error: error,
        });
      });
  };
}
