import React, { Fragment, useRef, useState } from 'react';

import {
  ClickAwayListener,
  InputBase,
  Popper,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';
import AccountQuickSearchBox from 'modules/accountsSearch/components/AccountQuickSearchBox';

const HeaderSearch = () => {
  const searchRef = useRef(null);

  const dummySearches = [
    'Analytics',
    'Sales',
    'Buttons',
    'Cards',
    'Helpdesk',
    'Projects',
    'Statistics'
  ];

  const handleSearchChange = event => {
    setSearchValue(event.target.value);

    if (event.target.value) {
      if (!openSearchPopover) {
        setOpenSearchPopover(true);
      }
    } else {
      setOpenSearchPopover(false);
    }
  };

  const handleSearchPopverClose = () => {
    setOpenSearchPopover(false);
  };

  const [openSearchPopover, setOpenSearchPopover] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  return (
    <Fragment>       
        <AccountQuickSearchBox/>
    </Fragment>
  );
};

export default HeaderSearch;
