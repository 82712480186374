export default (type, payments) => {
  // organize payments by gl code, property code

  if (type == "List") return payments;

  if (type == "GLCode") {
    let glTable = {};

    payments.forEach((payment) => {
      if (!glTable[payment.LedgerCode])
        glTable[payment.LedgerCode] = { AmountPosted: 0 };
      glTable[payment.LedgerCode].AmountPosted += payment.AmountPosted;
    });

    let glCodes = Object.keys(glTable);
    let organizedPayments = glCodes.map((code) => {
      return { LedgerCode: code, AmountPosted: glTable[code].AmountPosted };
    });

    return organizedPayments;
  }

  if (type == "PropertyCode") {
    let propertyCodeTable = {};

    payments.forEach((payment) => {
      if (!propertyCodeTable[payment.PropertyCode])
        propertyCodeTable[payment.PropertyCode] = { AmountPosted: 0 };
      propertyCodeTable[payment.PropertyCode].AmountPosted +=
        payment.AmountPosted;
    });

    let propertyCodes = Object.keys(propertyCodeTable);
    let organizedPayments = propertyCodes.map((code) => {
      return {
        PropertyCode: code,
        AmountPosted: propertyCodeTable[code].AmountPosted,
      };
    });

    return organizedPayments;
  }
};
