import axios from "axios";
import { setSnackbar } from "../../snackbar/api/snackbar";


export const FETCH_METER_READING_UPLOADS = "FETCH_METER_READING_UPLOADS";
export const SET_LOADING = "SET_LOADING";
export const HANDLE_ERROR = "HANDLE_ERROR";
export const RECEIVED_READINGS_SUBMISSION = "RECEIVED_READINGS_SUBMISSION";

export function fetchedMeterReadingUploads(previousMeterReadingUploads) {
  return { type: FETCH_METER_READING_UPLOADS, previousMeterReadingUploads };
}

export function receivedReadingsSubmission(previewUploadedMeterReadings) {
  return { type: RECEIVED_READINGS_SUBMISSION, previewUploadedMeterReadings };
}

export function handleError(error) {
  return { type: HANDLE_ERROR, error };
}

// Actions
export function handleMeterReadingsUploadSubmission(
  fileData,
  firstRow,
  fileName
) {
  return (dispatch) => {
    dispatch({
      type: SET_LOADING,
      isLoading: true,
    });

    return axios
      .post("/handle-meter-readings-upload", {
        data: fileData,
        fileName,
        firstRow,
      })
      .then((response) => {
        if (response.data.error == true) {
        dispatch({
          type: HANDLE_ERROR,
          error: response.data,
        });
        return response.data; 
      }
        else {
          
          dispatch(receivedReadingsSubmission(response.data));
          return response.data;
        }
      })
      .catch((error) => {
        dispatch({
          type: HANDLE_ERROR,
          error: error,
        });
      });
  };
}

export function fetchPreviousMeterReadingUploads() {
  return (dispatch) => {
    return axios
      .get("/meter-reading-upload")
      .then((response) => {
        dispatch(fetchedMeterReadingUploads(response.data));
      })
      .catch((error) => {
        dispatch({
          type: HANDLE_ERROR,
          error: error,
        });

        throw new Error(error);
      });
  };
}

export function markMeterReadingUploadAsBilled(uuid){
  return (dispatch) => {
    return axios
      .put("/meter-reading-upload-billed/" + uuid)
      .then((response) => {
        dispatch(setSnackbar(true, "success", "Upload Marked as Billed"),{
          type: SET_LOADING,
          isLoading: false
        })
        return response;
      })
      .catch((err) => {
        dispatch(setSnackbar(true, "error", ` ${err.response?.data?.message}`));

        dispatch({
          type: HANDLE_ERROR,
          error: err
        })
        return err
      })
  }
}

export function deleteMeterReadingUpload(uuid){
  return (dispatch) => {
    return axios
      .delete("/meter-reading-upload/" + uuid)
      .then((response) => {
        dispatch(setSnackbar(true, "success", "Upload Deleted"),{
          type: SET_LOADING,
          isLoading: false
        })
        return response;
      })
      .catch((err) => {
        dispatch(setSnackbar(true, "error", ` ${err.response?.data?.message}`));

        dispatch({
          type: HANDLE_ERROR,
          error: err
        })
        return err
      })
  };
}
