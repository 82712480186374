import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField as MuiTextField,
  Card as MuiCard,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  ButtonGroup,
  Button,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  Snackbar,
  MenuItem,
  CircularProgress as MuiCircularProgress,
} from "@material-ui/core";
import axios from "axios";
import MuiAlert from "@material-ui/lab/Alert";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  Loop as LoopIcon,
  PowerOffSharp,
  Search as SearchIcon,
} from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import { EditableTable } from "../../../components/EditableTable";
import { setSnackbar } from "../../snackbar/api/snackbar";
import StatusTag from "../../apl/components/StatusTag";
import UpdateWorkOrderForm from "./UpdateWorkOrderForm";
import CreateWorkOrderForm from "./CreateWorkOrderForm";
import { updateWorkOrder, getWorkOrdersByUnit, deleteWorkOrder, setUnitWorkOrders } from "../api/actions";

import { getActiveUsers } from "modules/settings/api/actions";
import { getWorkOrderStatusTypes } from "modules/apl/api/actions";
import { get as getProperties } from "modules/property/api/actions";

import {
  newDropDownField,
  newFieldLinkUrl,
  newFieldPopupModel,
  newOnFieldChangeMethod,
} from "../../../components/EditableTable";
// Redux Components
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";

const CircularProgress = styled(MuiCircularProgress)(spacing);
const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function UnitWorkOrdersTable(props) {
  const dispatch = useDispatch();
  const [rows, setRows] = useState(props.workOrders.workOrders);
  const [item, setItem] = useState({});
  const [create, setNew] = useState(false);
  const [alert, setAlert] = useState({ open: false, message: "", type: "" });
  const [open, setOpen] = useState(false);
  const [add, setAdd] = useState(false);
  const [page, setPage] = React.useState(0);
  const [workOrdersHaveBeenRequested, setWorkOrdersHaveBeenRequested] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState(""); // should be all thats needed to reduce the disconnects shown to only that accounts
  const [searchFocus, setSearchFocus] = React.useState(false);

  const [workOrderFormDataRequested, setWorkOrderFormDataRequested] = React.useState(false);



  const CurrentLookupTypeButton = () => {
    return (
      <IconButton>
        <SearchIcon mr={2} />
      </IconButton>
    );
  };

  // Table Stuff:
  const fieldTitles = [
    "ID",
    "Title",
    "Assigned To",
    "Created By",
    "Status",
    "Due Date",
    "Created",
    "Updated",
  ];
  const fieldTypes = [
    "label",
    "label",
    "label",
    "label",
    "label",
    "dateLabel",
    "dateLabel",
    "dateLabel",
  ];
  const fieldNames = [
    "id",
    "workOrderTitle",
    "assignedUser",
    "creatorName",
    "woStatus",
    "dueDate",
    "created",
    "updated",
  ];
  // Should open a tab to a account disconnect details page
  const onSelected = (item) => {
    //window.open("/propertyManager/" + item.uuid + "/" + item.id);
  };

  // Used for form refreshing after completion
  const onUpdate = () => {
    hide();
  };
  const handleClose = () => {
    setAlert({ open: false });
  };

  const show = () => {
    setOpen(true);
  };

  const hide = () => {
    setOpen(false);
  };

  const deleteWorkOrder = (toDelete) => {
    dispatch(setSnackbar(true, "warning", "Deleting Work Orders"));
    for (var x = 0; x < toDelete.length; x++) {
      props.deleteWorkOrder(toDelete[x]).then(() => {
        if (props.workOrders.error == null) {
          dispatch(setSnackbar(true, "success", `Deleted work order.`));
          props.getWorkOrdersByUnit(props.account.data.unit.id);
        }
        else {
          dispatch(setSnackbar(true, "error", `Failed to delete work order.`));
        }
      });
    }
  }

  const onCreate = () => {
    setNew(true);
    setAdd(!add);
  };

  const onSettingSelected = (item) => {
    setNew(false);
    if (item) {
      setItem(item);
    }
    setOpen(!open);
  };
  const onSettingUpdate = (uuid, newItem) => {
    props.updateWorkOrder(uuid, newItem).then(() => {
    }).catch(error => {
    });
  };

  const updateLocalItemsValues = (items) => {
    // Replaces existing store object with new object with modified sub data
    props.setUnitWorkOrders(items);
  }

  const useStyles = makeStyles({
    active: {
      fontWeight: "bold",
      color: "#f9a825",
      borderColor: "#f9a825",
    },
    inactive: {
      fontWeight: "bold",
    },
  });

  const handleChangePage = (page) => {
    setPage(page);
  };

  const handleSearchQueryInput = (event) => {
    setSearchQuery(String(event.target.value));
  };

  const conditionalStyling = (item) => {
    item.pmStatus = <StatusTag type={"propertyManagerStatus"} isLoading={props.propertyManagers.isLoading} typeId={item.statusId} showTitle={false} condensed={true} />
    return {};
  };

  const handleSearchTargetChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const setFocus = () => {
    setSearchFocus(true);
  };
  const unsetFocus = () => {
    setSearchFocus(false);
  };

  const SearchBar = () => {
    return (
      <Grid container xs={12} spacing={4}>
        <Grid item xs={12}>
          {
            searchFocus ?
              <TextField
                id={"searchTarget"}
                label="Search Account Files"
                type={"text"}
                placeholder={"Search by any file item field"}
                onChange={handleSearchTargetChange}
                autoFocus={"true"}
                onBlur={unsetFocus}
                inputRef={text => text && text.focus()}
                name={"searchTarget"}
                defaultValue={searchQuery}
                fullWidth
                size={"small"}
                variant={"outlined"}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{ endAdornment: <CurrentLookupTypeButton /> }}
              ></TextField>
              :
              <TextField
                id={"searchTarget"}
                label="Search Account Files"
                type={"text"}
                placeholder={"Search by any file item field"}
                onChange={handleSearchTargetChange}
                onClick={setFocus}
                name={"searchTarget"}
                defaultValue={searchQuery}
                fullWidth
                size={"small"}
                variant={"outlined"}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{ endAdornment: <CurrentLookupTypeButton /> }}
              ></TextField>
          }
        </Grid>
      </Grid>
    );
  };

  useEffect(() => {
    if (!props.account.isLoading && props.account.data.unit && props.account.data.unit.id) {
      if (!workOrdersHaveBeenRequested) {
        props.getWorkOrdersByUnit(props.account.data.unit.id);
        setWorkOrdersHaveBeenRequested(true);
      }
      else {
        // If its a regular table opening get form info
        if (props.unitWorkOrderTablesIsOpen && !workOrderFormDataRequested) {
          setWorkOrderFormDataRequested(true);
          props.getWorkOrderStatusTypes();
          props.getActiveUsers();
          props.getProperties();
        }
      }
    }
  }, [props.account.isLoading, props.unitWorkOrderTablesIsOpen])

  const toShow = JSON.parse(JSON.stringify(props.workOrders.unitWorkOrders));
  for (var x = 0; x < toShow.items.length;) {
    // Filtering by Search Query
    if (searchQuery != "") {
      if (!(toShow.items[x].workOrderTitle != null && toShow.items[x].workOrderTitle.includes(searchQuery)) &&
        !(toShow.items[x].assignedTo != null && toShow.items[x].assignedTo.includes(searchQuery)) &&
        !(toShow.items[x].woStatus != null && toShow.items[x].woStatus.includes(searchQuery)) &&
        !(toShow.items[x].creatorName != null && toShow.items[x].creatorName.includes(searchQuery))
      ) {
        toShow.items.splice(x, 1);
        continue;
      }
    }
    x++;
  }
  return (
    <Card mb={6} elevation={3}>
      <CardContent>
        <Grid container spacing={2} style={{ "justify-content": "end", "margin-top": "1em" }}>
          <Grid item xs={12}>
            <SearchBar />
          </Grid>
          <Grid item xs={12}>
            <EditableTable
              // Functional methods of table
              // Properties of table
              tableTitle={"Unit Work Orders"}
              canAddItems={true}
              canDelete={true}
              manualItemDeletionMethod={deleteWorkOrder}
              canExport={true}
              dense={true}
              canSave={false}
              readOnly={false}
              newItemCreationMethod={onCreate}
              updateItemsLocalState={updateLocalItemsValues}
              isLoading={props.workOrders.isLoading}
              objectArray={toShow}
              fieldTitles={fieldTitles}
              fieldTypes={fieldTypes}
              fieldNames={fieldNames}
              showButtons={false}
            />
          </Grid>
        </Grid>
        <Snackbar
          open={alert.open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={alert.type}>
            {alert.message}
          </Alert>
        </Snackbar>
        {open && (
          <UpdateWorkOrderForm
            new={create}
            item={item}
            updateItem={onSettingUpdate}
            onChange={onSettingSelected}
            show={open}
          />
        )}

        {add &&
          <>
            {props.account.data.unit.id && props.account.data.property.id ?
              <CreateWorkOrderForm
                new={create}
                item={item}
                updateItem={onSettingUpdate}
                onChange={onSettingSelected}
                show={add}
                existingUnit={{ property: props.account.data.property, unit: props.account.data.unit }}
              />
              :
              <CreateWorkOrderForm
                new={create}
                item={item}
                updateItem={onSettingUpdate}
                onChange={onSettingSelected}
                show={add}
              />}
          </>}
      </CardContent>
    </Card>
  );
}
// Component accountDisconnects
UnitWorkOrdersTable.propTypes = {
  // Store objects:
  user: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  workOrders: PropTypes.object.isRequired,
  updateWorkOrder: PropTypes.func.isRequired,
  getWorkOrdersByUnit: PropTypes.func.isRequired,
  getProperties: PropTypes.func.isRequired,
  getWorkOrderStatusTypes: PropTypes.func.isRequired,
  getActiveUsers: PropTypes.func.isRequired,
  deleteWorkOrder: PropTypes.func.isRequired,
  setUnitWorkOrders: PropTypes.func.isRequired,

  unitWorkOrderTablesIsOpen: PropTypes.bool.isRequired,
};

// Component State
function State(state) {
  return {
    user: state.user,
    account: state.account,
    apl: state.apl,
    workOrders: state.workOrders
  };
}
export default connect(State, { updateWorkOrder, getWorkOrdersByUnit, getActiveUsers, getProperties, getWorkOrderStatusTypes, deleteWorkOrder, setUnitWorkOrders })(
  UnitWorkOrdersTable
);
