import {
  Button as MuiButton,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  TextField as MuiTextField,
  Typography,
  List, ListItem,
  Fab,
  Button
} from "@material-ui/core";
import Dropzone from 'react-dropzone';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components/macro";
import { setSnackbar } from "../../snackbar/api/snackbar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import VacantUnitSearch from "../../unit/components/VacantUnitSearch";
import { submitUnitSwap } from "../../unit/api/actions";
import {
  hydrateAccount,
} from "../../account/api/actions";


import { logout } from "../../user/api/actions";

const TextField = styled(MuiTextField)(spacing);
const Select = styled(MuiSelect)(spacing);

const TransferUnitForm = (props) => {
  const [currentForm, setCurrentForm] = React.useState({
    description: "",
    fileName: "",
    bucketName: "",
    file: null
  });

  const useStyles = makeStyles((theme) => ({
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    loadingIcon: {
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
    },
  }));

  const dispatch = useDispatch();
  /*
    useEffect(() => {
  
    }, [currentFiles]);
  
    const onFieldChange = (event) => {
      var upload = currentUpload;
      upload[event.target.name] = event.target.value;
    };
  
    const dispatch = useDispatch();
    const classes = useStyles();
  
    const onDrop = (files) => {
      setCurrentFiles(files);
    }
    const onCancel = () => {
      setCurrentFiles([]);
      props.setUploadFiles([]);
    }
  
    const removeItem = (itemIndex) => {
      var files = currentFiles;
      files.splice(itemIndex, 1);
      setCurrentFiles(files);
      props.setUploadFiles(currentFiles);
    }
    */
  const sendUnitSwap = () => {
    dispatch(setSnackbar(true, "warning", "Transfering Account to new unit..."));
    props.submitUnitSwap(props.units.selectedUnitForTransfer.unitUuid, props.account.data.account.uuid).then(() => {
      if (props.units.unitTransferError == null) {
        dispatch(setSnackbar(true, "success", "Account transfered successfully!"));
        if (props.onClose) {
          props.onClose();
        }
        props.hydrateAccount(props.account.data.account.uuid, true);
      }
      else {
        dispatch(setSnackbar(true, "error", "Error transfering account to new unit"));
      }
    });
  };

  return (
    <Card>
      <CardContent>
        <Grid container spacing={4} xs={12} sm={12} md={12} lg={12}>
          {props.units.selectedUnitForTransfer == null ?
            <>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <Typography variant="h5" gutterBottom>
                  No Unit Selected: Please select a vacant unit to swap with.
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <Button
                  className="mb-3 mt-3 mr-3"
                  variant="contained"
                  color="secondary"
                  disabled
                  onClick={submitUnitSwap}
                >
                  Transfer Unit To Current Account
                </Button>
              </Grid>
            </>
            :
            <>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <Typography variant="h5" gutterBottom>
                  {props.units.selectedUnitForTransfer.propertyName}
                </Typography>
                <Typography variant="h5" gutterBottom>
                  {props.units.selectedUnitForTransfer.serviceAddress1}, {props.units.selectedUnitForTransfer.serviceAddress2}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <Button
                  className="mb-3 mt-3 mr-3"
                  variant="contained"
                  color="secondary"
                  onClick={sendUnitSwap}
                >
                  Transfer Unit To Current Account
                </Button>
              </Grid>
            </>
          }
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <VacantUnitSearch minAccountObj={true} isOpen={props.isOpen || false} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

TransferUnitForm.propTypes = {
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  billing: PropTypes.object.isRequired,
  property: PropTypes.object.isRequired,
  accountsSearch: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  units: PropTypes.object.isRequired,

  logout: PropTypes.func.isRequired,
  searchAccountsByFieldAndValue: PropTypes.func.isRequired,
  submitUnitSwap: PropTypes.func.isRequired,
  hydrateAccount: PropTypes.func.isRequired,
};

function TransferUnitFormState(state) {
  return {
    user: state.user,
    units: state.units,
    apl: state.apl,
    billing: state.billing,
    property: state.property,
    account: state.account,
    accountsSearch: state.accountsSearch,
  };
}

export default connect(TransferUnitFormState, { logout, submitUnitSwap, hydrateAccount })(TransferUnitForm);
