import Card from "@material-ui/core/Card";
import moment from "moment";
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { 
  Button, 
  CardContent, 
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions

} from "@material-ui/core";
import { EditableTable, newConditionalOnSelectButton } from "components/EditableTable";
import { connect, useDispatch } from "react-redux";
import { updateCurrentOccupant, getUnitAllOccupants } from "../api/actions";
import { setSnackbar } from "modules/snackbar/api/snackbar";

const OccupantsTable = (props) => {
  const [dialogOpen , setDialogOpen] = React.useState(false);
  const [occupantsArray, setOccupantsArray] = React.useState({ items: [], toUpdate: [], toDelete: [] });
  const [selectedOccupant, setSelectedOccupant] = React.useState(null);
  const [unitUuid, setUnitUuid] = React.useState(null);
  const dispatch = useDispatch()
 
  useEffect(() => {
    
    let rows = props.units.allOccupants;
    // Sort rows to get current occupant to front of array
    rows.items.sort((a, b) =>{
     return (a.isCurrentOccupant === b.isCurrentOccupant)? 0 : a.isCurrentOccupant? -1 : 1;
   })
   
   setOccupantsArray(rows)
   setUnitUuid(props.unitUuid)
  }, [props.units.allOccupants]);

  const handleDialogOpen = (item) => {

    setSelectedOccupant(item[0])
    setDialogOpen(true)

  }

  const occupantRowStylingMethod = (occupant) => {
    if(occupant.isCurrentOccupant){
      occupant.isCurrentOccupant = "Yes"
    }else{
      occupant.isCurrentOccupant = "";
    }
  }

  const handleDialogClose = () => {
    setDialogOpen(false);
    
  }
  const ConditionalOnSelectButtons = [
    newConditionalOnSelectButton(
      "Set New Current Occupant",
      () => {
        return (true);
      },
      handleDialogOpen
    ),
  ];
  
  const handleUpdateCurrentOccupant = () => {
    dispatch(setSnackbar(true, "warning", "Updating Current Unit Occupant"));
    props.updateCurrentOccupant(selectedOccupant.uuid, unitUuid).then(() => {
      props.getUnitAllOccupants(unitUuid, true);

    });
    handleDialogClose();
  }

  const fieldTitles = ["Account Number", "Created", "First Name", "Last Name", " Current Occupant"];
  const fieldTypes = ["label", "label", "label", "label", "label"];
  const fieldNames = ["accountNum", "created", "firstName", "lastName", "isCurrentOccupant"];

 
  const onSelected = (item) => {
    window.open("/accountmanager/" + item.accountNum);
  }

 
  return (
    <Card mb={6}>
      <CardContent xs={12}>
      <Dialog 
      open={dialogOpen} 
      onClose={handleDialogClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description">
        <DialogTitle>Update Current Occupant  </DialogTitle>
        <DialogContent>
          <DialogContentText className={'text-danger'}>
            Are you sure you want to set {selectedOccupant?.firstName} {selectedOccupant?.lastName} with Account Number: {selectedOccupant?.accountNum} as the Current Occupant
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleUpdateCurrentOccupant}>Submit</Button>
        </DialogActions>
      </Dialog>
        <EditableTable
          isLoading={props.units.isLoading}
          handleRowClickParentMethod={onSelected}
          tableTitle={" Unit Occupant(s)"}
          objectArray={occupantsArray}
          canSave={false}
          canDelete={false}
          canAddItems={false}
          fieldTitles={fieldTitles}
          fieldTypes={fieldTypes}
          fieldNames={fieldNames}
          conditionalStylingMethod={occupantRowStylingMethod}
          conditionalOnSelectButtons={ConditionalOnSelectButtons}
        />
      </CardContent>
    </Card>

  );
};
OccupantsTable.propTypes = {
  // Store objects:
  units: PropTypes.object.isRequired,
  newConditionalOnSelectButton: PropTypes.func.isRequired,
  updateCurrentOccupant: PropTypes.func.isRequired,
  getUnitAllOccupants: PropTypes.func.isRequired
};
function OccupantsTableState(state) {
  return {
    units: state.units,
  };
}
export default connect(OccupantsTableState,
   { newConditionalOnSelectButton,
   updateCurrentOccupant, 
   getUnitAllOccupants 
  })(OccupantsTable);
