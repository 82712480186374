import {
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  CircularProgress as MuiCircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Slide,
  TextField as MuiTextField,
  Grid,
  ButtonGroup
} from "@material-ui/core";
//Material UI Components and Functions
import { makeStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
// Redux Components
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import {
  EditableTable,
  newDropDownField,
  newFieldLinkUrl,
} from "../../../components/EditableTable";
import {
  getAccountInfoByAccountUUID,
  updateCurrentAccountInDB,
} from "../../account/api/actions";
import { getAllPropertyPreviews } from "../../apl/api/actions";
import StatusTag from "../../apl/components/StatusTag";
import { setSnackbar } from "../../snackbar/api/snackbar";
import {
  searchAccountsByFieldAndValue,
  searchForAccountsByFilters,
  updateAccountListData,
  updateAccountsSearchData,
  updateFilters,
} from "../api/actions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Button = styled(MuiButton)(spacing);
const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)(spacing);
const DashboardToolBarButton = styled(Button)`
  padding: 4px;
  min-width: 0;
  svg {
    width: 2em;
    height: 2em;
  }
`;

const CircularProgress = styled(MuiCircularProgress)(spacing);

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  searchBarGrid: {
    //paddingBottom: "10px",
    fontSize: "90%",
    height: "5%",
  },
  searchDropDownButton: {
    height: "90%",
  },
  percentDif: {
    textAlign: "right",
    color: "green",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  loadingIcon: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  active: {
    fontWeight: "bold",
    color: "#f9a825",
    borderColor: "#f9a825",
  },
  inactive: {
    fontWeight: "bold",
  },
}));

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const PropertyCommonAreaAccountsTable = (props) => {
  const [accountStatusQueryType, setAccountStatusQueryType] = React.useState(0);

  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  // Filters
  const [customerType, setCustomerType] = React.useState("Common");
  const [accountStatusType, setAccountStatusType] = React.useState(null);
  const [searchQuery, setSearchQuery] = React.useState(""); // should be all thats needed to reduce the disconnects shown to only that accounts
  const [property, setProperty] = React.useState(
    props.propertyUuid || "00000000000000000"
  );

  const canAddOrUpdate = (props.user.details.administration || props.user.details.billing || props.user.details.isSuperUser) ? true : false;
  // Table Data management
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [resetCache, setResetCachedData] = React.useState(true);

  const [display, setDisplay] = React.useState(false);

  const AccountRowStatusStyling = (account) => {
    account.combinedName = "";
    if (account.firstName != "" && account.firstName != null) {
      account.combinedName = account.firstName;
    }
    if (account.lastName != "" && account.lastName != null) {
      account.combinedName = account.combinedName + " - " + account.lastName;
    }

    account.accountStatus = (
      <>
        <StatusTag
          type={"accountStatus"}
          isLoading={props.accountsSearch.isLoading}
          typeId={account.accountStatusId}
          showTitle={false}
          condensed={true}
        />
        <StatusTag type={"customerType"} isLoading={props.accountsSearch.isLoading} typeId={account.customerTypeId} showTitle={false} condensed={true} />
      </>
    );
    return {}; // Returns an empty style as to not effect each row
  };

  const handleChangeRowsPerPage = (rowsPerPage) => {
    setPage(0);
    setResetCachedData(false);
    setRowsPerPage(rowsPerPage);
  };

  const handleChangePage = (page) => {
    setResetCachedData(false);
    setPage(page);
  };

  const getAccountsWithQuery = () => {
    const propertyUuid = property;
    const query = {
      page,
      rowsPerPage,
      customerType,
      searchQuery,
      propertyUuid,
      accountStatusType,
      resetCache,
    };
    props.searchForAccountsByFilters(query);
  };

  const onUpdate = () => {
    hide();
  };

  const show = () => {
    setDisplay(true);
  };

  const hide = () => {
    setDisplay(false);
  };
  const handleStatusChangeClick = (event) => {
    event.target.innerText == "All" ? setAccountStatusType(null) :
    setAccountStatusType(event.target.innerText);
  }


  const Filters = () => {
    const classes = useStyles();
    return (
      <Grid container lg={12} className="badge-wrapper m-3">
        <Grid item lg={12} md={12} sm={12}>
          <ButtonGroup
            color="primary"
            aria-label="outlined primary button group"
          >
            <Button
              onClick={handleStatusChangeClick}
              className={
                accountStatusType == null
                  ? classes.active
                  : classes.inactive
              }
            >
              All
            </Button>
            <Button
              onClick={handleStatusChangeClick}
              className={
                accountStatusType == "Active"
                  ? classes.active
                  : classes.inactive
              }
            >
              Active
            </Button>
            <Button
              onClick={handleStatusChangeClick}
              className={
                accountStatusType == "Inactive"
                  ? classes.active
                  : classes.inactive
              }
            >
              Inactive
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    );
  };
  const NewCommonAreaAccountDialog = () => {
    return (
      <Dialog
        open={display}
        TransitionComponent={Transition}
        fullWidth={"lg"}
        maxWidth={"lg"}
        keepMounted
        onClose={hide}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          New Common Area Account
        </DialogTitle>
        <DialogContent></DialogContent>
      </Dialog>
    );
  };

  useEffect(() => {
    if (
      (page + 1) * rowsPerPage >
      props.accountsSearch.data.accountPreviews.items.length ||
      resetCache
    ) {
      getAccountsWithQuery();
    }
  }, [
    page,
    property,
    rowsPerPage,
    searchQuery,
    customerType,
    accountStatusType,
  ]);

  // Table Stuff:

  const BillableStatusOptions = [
    { label: "Billable to PM", value: 0 },
    { label: "Common Line Item", value: 1 },
    { label: "Meter-less Common", value: 2 },
  ];

  const fieldTitles = [
    "Acct #",
    "Title",
    "Status",
    "Service Address",
    "Balance",
    "Billable",
  ];

  const fieldTypes = [
    "label",
    "label",
    "label",
    ["labelSmall", "labelSmall"],
    "moneyLabel",
    "dropDown",
  ];

  const fieldNames = [
    "accountNum",
    "combinedName",
    "accountStatus",
    ["serviceAddress1", "serviceAddress2"],
    "balance",
    "billableStatus",
  ];

  const getAccountURL = (item) => {
    window.open("/accountmanager/" + item.accountNum);
  };

  const updateLocalItemsValues = (accounts) => {
    props.updateAccountsSearchData(accounts);
  };

  const tableResourceFields = [
    newDropDownField("billableStatus", BillableStatusOptions, "label", "value"),
  ];
  const urlFields = [
    newFieldLinkUrl("accountNum", getAccountURL),
    newFieldLinkUrl("combinedName", getAccountURL),
    newFieldLinkUrl("accountStatus", getAccountURL),
    newFieldLinkUrl("balance", getAccountURL),
  ];
  const onAccountSelected = (item) => {
    window.open("/accountmanager/" + item.accountNum);
  };
  const saveUpdatedAccounts = (accounts) => {
    console.log("saveUpdatedAccounts");
    console.log(accounts);
    accounts.toUpdate.map((targetUuid) => {
      var targetItemIndex =
        props.accountsSearch.data.accountPreviews.items.findIndex(
          (x) => x.uuid == targetUuid
        );
      if (targetItemIndex != -1) {
        if (accounts.items[targetItemIndex].id != -1) {
          const newAccount = props.accountsSearch.data.accountPreviews.items[targetItemIndex];
          console.log(newAccount);
          dispatch(
            setSnackbar(true, "warning", "Saving Account Detail Changes")
          );
          props.updateCurrentAccountInDB({
            uuid: newAccount.uuid,
            billableStatus: newAccount.billableStatus,
          })
            .then(() => {
              dispatch(setSnackbar(true, "success", "Saving Successful"));
            }); // Sends account data to db
        }
      }
    });
  };

  return (
    <Card mb={6} elevation={3}>
      <Filters />
      <CardContent>
        <EditableTable
          readOnly={!canAddOrUpdate}
          canAddItems={false}
          canSave={canAddOrUpdate}
          canDelete={false}
          isLoading={props.accountsSearch.isLoading}
          customUrlFields={urlFields}
          // Functional methods of table
          //handleRowClickParentMethod={onAccountSelected}
          updateItemsLocalState={updateLocalItemsValues}
          saveCurrentTableData={saveUpdatedAccounts}
          // Properties of table
          tableTitle={"(INCORRECT WITH OLD DATA) Common Area Accounts"}
          objectArray={props.accountsSearch.data.accountPreviews}
          conditionalStylingMethod={AccountRowStatusStyling}
          fieldTitles={fieldTitles}
          fieldTypes={fieldTypes}
          fieldNames={fieldNames}
          dropDownFields={tableResourceFields}
          customChangePageRowsEvent={handleChangeRowsPerPage}
          customHandleChangePageEvent={handleChangePage}
          defaultItemCount={rowsPerPage}
          totalRowCount={
            props.accountsSearch.data.previewsSummary == null
              ? 0
              : props.accountsSearch.data.previewsSummary.totalRows
          }
        />
      </CardContent>
    </Card>
  );
};
// Component Properties
PropertyCommonAreaAccountsTable.propTypes = {
  // Store objects:
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  billing: PropTypes.object.isRequired,
  accountsSearch: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,

  searchAccountsByFieldAndValue: PropTypes.func.isRequired,
  getAllPropertyPreviews: PropTypes.func.isRequired,
  updateFilters: PropTypes.func.isRequired,
  searchForAccountsByFilters: PropTypes.func.isRequired,
  updateCurrentAccountInDB: PropTypes.func.isRequired,
  updateAccountListData: PropTypes.func.isRequired,
  getAccountInfoByAccountUUID: PropTypes.func.isRequired,
};

// Component State
function PropertyCommonAreaAccountsTableState(state) {
  return {
    user: state.user,
    apl: state.apl,
    billing: state.billing,
    accountsSearch: state.accountsSearch,
    account: state.account,
  };
}
export default connect(PropertyCommonAreaAccountsTableState, {
  searchAccountsByFieldAndValue,
  getAllPropertyPreviews,
  updateFilters,
  searchForAccountsByFilters,
  updateCurrentAccountInDB,
  updateAccountListData,
  updateAccountsSearchData,
  getAccountInfoByAccountUUID,
})(PropertyCommonAreaAccountsTable);
