import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Grid,
  Fab,
  Box,
  Typography,
  Tabs,
  Tab,
  LinearProgress,
  Card,
  CardContent,
  Button,
  List,
  ListItem
} from '@material-ui/core';

// Expects: onClickMethod, title, subtitle, iconName, status(new/old)
export function ActionButtonWithImage(props) {

  const handleButtonClick = () => {
    if (props.onClickMethod && !props.disabled) {
      props.onClickMethod();
    }
  };


  return (
    <a
      href="#/"
      onClick={(e) => {
        e.preventDefault();
        handleButtonClick();
      }}
      className="card card-box btn rounded text-left mb-4 p-3">
      <div className="d-flex align-items-center flex-column flex-sm-row">
        <div>
          {props.iconName &&
            (props.disabled || props.showError) ?
            <div className="text-center text-danger display-2 d-50 rounded-circle mb-3 mb-sm-2">
              <FontAwesomeIcon icon={['far', props.iconName]} />
            </div>
            :
            <div className="text-center text-first display-2 d-50 rounded-circle mb-3 mb-sm-2">
              <FontAwesomeIcon icon={['far', props.iconName]} />
            </div>
          }
        </div>
        <div className="pl-0 pl-sm-3">
          <div className="d-block text-center d-sm-flex align-items-center">
            <span className="font-size-md">{props.title ? props.title : "NO props.title"}</span>
            {props.status &&
              props.status == "new" &&
              <span className="ml-2 badge badge-success">New</span>
            }
            {props.status &&
              props.status == "old" &&
              <span className="text-warning ml-2 badge badge-neutral-warning">
                OLD
              </span>
            }
          </div>
          {props.disabled ?
            <p className="text-danger opacity-8 mb-0">
              <b>{props.disabled}</b>
            </p>
            :
            <p className="text-black-50 opacity-8 mb-0">
              <b>{props.subtitle && props.subtitle}</b>
            </p>
          }
        </div>
      </div>
    </a>
  );
}
