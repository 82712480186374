import {
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  CircularProgress as MuiCircularProgress,
  Divider as MuiDivider,
  Grid,
  Paper as MuiPaper,
  Snackbar,
  LinearProgress,
} from "@material-ui/core";
//Material UI Components and Functions
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { makeStyles } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import { spacing } from "@material-ui/system";
// Redux Components
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { EditableTable } from "../../../components/EditableTable";
import { logout } from "../../user/api/actions";
import UpdateWorkOrderForm from "./UpdateWorkOrderForm";
import CreateWorkOrderForm from "./CreateWorkOrderForm";
import { setSnackbar } from "../../snackbar/api/snackbar"
import SearchBar from "material-ui-search-bar";
import { SettingsRemoteSharp } from "@material-ui/icons";
import { updateWorkOrder } from "../api/actions";
import { withRouter } from "react-router-dom";
import axios from "axios";
import ButtonGroupSelect from "../../../components/ButtonGroupSelect"
import moment from "moment";

const Button = styled(MuiButton)(spacing);

const DashboardToolBarButton = styled(Button)`
  padding: 4px;
  min-width: 0;
  svg {
    width: 2em;
    height: 2em;
  }
`;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CircularProgress = styled(MuiCircularProgress)(spacing);

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  percentDif: {
    textAlign: "right",
    color: "green",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  loadingIcon: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Spacer = styled.div`
  flex: 1 1 100%;
`;



//Editable Table Object Expects: {objectArray, fieldTitles(Array), fieldTypes(Array), fieldNames(Array), handleRowClickParentMethod}
// Method can take line items if they exist initially or starts as an empty array of nothing passed
function WorkOrdersTable(props) {
  const classes = useStyles();
  const history = useHistory();
  const navTo = (page) => history.push(page);
  const fieldTitles = [
    "ID",
    "Title",
    "Assigned To",
    "Created By",
    "Property",
    "Address",
    "Status",
    "Due Date",
    "Created",
    "Updated",
  ];
  const fieldTypes = [
    "label",
    "label",
    "label",
    "label",
    "label",
    "label",
    "workOrderStatus",
    "dateLabelDueDate",
    "dateLabel",
    "dateLabel",
  ];
  const fieldNames = [
    "id",
    "workOrderTitle",
    "assignedTo",
    "creator",
    "name",
    "address1",
    "description",
    "dueDate",
    "created",
    "updated",
  ]; // Must be the exact name of the properties you wanna show

  const [open, setOpen] = useState(false);
  const [add, setAdd] = useState(false);

  const [item, setItem] = useState({});
  const [create, setNew] = useState(false);
  const [alert, setAlert] = useState({ open: false, message: "", type: "" });
  const [searched, setSearched] = React.useState("");
  const [rows, setRows] = useState(props.workOrders.workOrders);
  const dispatch = useDispatch();

  const query = new URLSearchParams(props.location.search);
  const id = query.get('id');
  const [alignment, setAlignment] = React.useState('web');

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
    let filtered = { items: [] };

    if (newAlignment != "All") {
      filtered.items = props.workOrders.workOrders.items.filter(item => {
        return item.description == newAlignment;
      });
      setRows(filtered)
    }
    else {
      setRows(props.workOrders.workOrders);
    }

  };

  const onSettingSelected = (item) => {
    setNew(false);
    if (item) {
      setItem(item);
    }
    setOpen(!open);
  };

  const onCreate = () => {
    setNew(true);
    setAdd(!add);
  };

  const sortByStatusAndDueDate = (a, b) => {
    // Define the custom order for workOrderStatusId
    const statusOrder = { 2: 1, 3: 2, 1: 3, 4: 4 };
    
    // Get the order for each workOrderStatusId
    const orderA = statusOrder[a.workOrderStatusId];
    const orderB = statusOrder[b.workOrderStatusId];
    
    // Compare the order of workOrderStatusId
    if (orderA !== orderB) {
      return orderA - orderB;
    }
    
    // If workOrderStatusId is the same, compare dueDate
    return new Date(b.dueDate) - new Date(a.dueDate);
  }

  const onNewSettingSelected = () => {
    setNew(true);
    setItem({});
    setOpen(!open);
  };

  const onSettingUpdate = (uuid, newItem) => {
    props.updateWorkOrder(uuid, newItem).then(() => {
    }).catch(error => {
    });
  };

  const requestSearch = (searchedVal) => {
    let stringValue;
    if (props?.workOrders.workOrders.items?.length > 0) {
      const filteredRows = props.workOrders.workOrders.items.filter((row) => {
        for (const [key, value] of Object.entries(row)) {
          stringValue = String(value);
          if (stringValue?.toLowerCase().includes(searchedVal.toLowerCase()))
            return true;
        }
        return false;
      });
      let newSearch = {};
      newSearch.items = filteredRows;
      setRows(newSearch)
    }
  };

  const cancelSearch = () => {
    setSearched("");
    setRows(props.workOrders.workOrders)
    requestSearch(searched);
  };


  const deleteWorkOrder = async (uuid) => {
    try {
      let response = await axios.delete(`/workorder/${uuid}`)
      if (response.status == 200) {
        dispatch(setSnackbar(true, "success", `Deleted work order.`))
      }
    } catch (e) {
      dispatch(setSnackbar(true, "error", `Failed to delete work order.`));
    }
  }

  const isAdmin = props.user.details.isSuperUser == 1 ? true : false;
  const handleClose = () => {
    setAlert({ open: false });
  };

  useEffect(() => {
    // sort by date and  status not closed 
    let sortedRows = { items: [] };
    sortedRows.items = props.workOrders?.workOrders?.items.sort(sortByStatusAndDueDate);
    console.log(sortedRows)
    setRows(sortedRows);
    if (id) {
      let loadedItem = props.workOrders?.workOrders?.items.find(workOrder => {
        return workOrder.id == id;
      });

      if (loadedItem) {
        setItem(loadedItem)
        setOpen(true)
      }
    }
  }, [props.workOrders?.workOrders])

  return (
    <div>
      <Grid item xs={12}>
        <Snackbar
          open={alert.open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={alert.type}>
            {alert.message}
          </Alert>
        </Snackbar>
        {props.workOrders.isLoading ? (<LinearProgress value={25} className="mb-3" />
        ) : (
          <div>
            <Grid item xs={6} p={3}>
              <ToggleButtonGroup
                color="secondary"
                value={alignment}
                exclusive
                onChange={handleChange}
              >
                <ToggleButton value="All">All</ToggleButton>
                <ToggleButton value="New">New</ToggleButton>
                <ToggleButton value="Pending">Pending</ToggleButton>
                <ToggleButton value="Closed">Closed</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <EditableTable
              // Functional methods of table
              // Properties of table
              tableTitle={"Work Orders"}
              canAddItems={true}
              canDelete={true}
              manualItemDeletionMethod={deleteWorkOrder}
              canExport={true}
              dense={true}
              canSave={false}
              canSearch={true}
              readOnly={false}
              newItemCreationMethod={onCreate}
              objectArray={rows}
              fieldTitles={fieldTitles}
              fieldTypes={fieldTypes}
              handleRowClickParentMethod={onSettingSelected}
              fieldNames={fieldNames}
              showButtons={false}
            />
          </div>
        )}
      </Grid>
      {open && (
        <UpdateWorkOrderForm
          new={create}
          item={item}
          updateItem={onSettingUpdate}
          onChange={onSettingSelected}
          show={open}
        />
      )}

      {add && (
        <CreateWorkOrderForm
          new={create}
          item={item}
          updateItem={onSettingUpdate}
          onChange={onSettingSelected}
          show={add}
        />
      )}
    </div>
  );
}
// Component Properties
WorkOrdersTable.propTypes = {
  // Store objects:
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  workOrders: PropTypes.object.isRequired,
  billing: PropTypes.object.isRequired,

};

// Component State
function WorkOrdersTableState(state) {
  return {
    user: state.user,
    workOrders: state.workOrders
  };
}

export default withRouter(connect(WorkOrdersTableState, { logout, updateWorkOrder })(
  WorkOrdersTable
));
