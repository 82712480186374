

import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { logout } from "../../user/api/actions";
import { withRouter } from "react-router-dom";

import { Grid, TextField, MenuItem, Button, Checkbox, FormControl, FormControlLabel } from '@material-ui/core';
import { getAllUnitTypes } from "modules/apl/api/actions";
import { get as getPropertyManager } from "modules/propertyManager/api/actions";
import Autocomplete from '@mui/material/Autocomplete';
import axios from "axios";
import { getByUuid as getPropertyByUuid } from "modules/property/api/actions";
import { getAllCustomerTypes, getAllAccountStatusCodes } from "modules/apl/api/actions"
import { setSnackbar } from "modules/snackbar/api/snackbar";


function UnitForm(props) {
  const dispatch = useDispatch();
  let propertyManagers = [];
  const [addressTypes, setAddressTypes] = useState(["APT", "STE", "UNIT"])
  const [startingLetter, setStartingLetter] = useState("A");
  const [isLoading, setIsLoading] = useState(true);
  const [property, setProperty] = useState({ name: "", owner: { name: "" }, developer: { name: "" } });
  const [state, setState] = useState({
    ownerId: "",
    unitType: "",
    address1: "",
    city: "",
    state: "",
    zip: "",
    addressType: "",
    numberOfUnits: "10",
    startUnit: "A",
    startERT: 100,
    startMeterNum: 100,
    propertyManager: "",
    developerId: "",
    developer: "",
    propertyManagerName: "",
    commonAccount: 0,
    propertyId: "",
    sqFt: "0",
    bed: "0",
    batch: "0",
    buildingNumber: "",
    heatingType: "Electric",
    moveInDate: "",
    bedrooms: "",
    baths: "",
    billingType: ""
  });
  let propertyUuid = props.match.params.propertyUuid;

  const [pm, setPM] = useState(props.propertyManagers.data.propertyManagersList.items || [{ id: "", label: "" }])
  const [owners, setOwners] = useState([])
  const [fullAddress, setFullAddress] = useState("");
  const handleChange = (evt) => {
    let value = evt.target.value;

    if (value === "ownerName") {
      setState((prevState) => ({
        ...prevState,
        owner: {
          ...prevState.owner,
          name: value
        }
      }));
    } else {
    if (evt.target.name == "isActive") value = evt.target.checked;

    setState({
      ...state,
      [evt.target.name]: value,
    });

    setFullAddress(`${state.address1} ${state.addressType} ${state.startUnit} ${state.city}, ${state.state} ${state.zip}`)

  };
  }
  const nextLetter = (l) => {
    if (l < 90) {
      return String.fromCharCode(l + 1);
    }
    else {
      return startingLetter;
    }
  }

  const same = (str, char) => {
    var i = str.length;
    while (i--) {
      if (str[i] !== char) {
        return false;
      }
    }
    return true;
  }


  const nextCharCustom = (c) => {
    var u = c.toUpperCase();
    if (same(u, 'Z')) {
      var txt = '';
      var i = u.length;
      while (i--) {
        txt += startingLetter;
      }
      return (txt + startingLetter);
    } else {
      var p = "";
      var q = "";
      if (u.length > 1) {
        p = u.substring(0, u.length - 1);
        q = String.fromCharCode(p.slice(-1).charCodeAt(0));
      }
      var l = u.slice(-1).charCodeAt(0);
      var z = nextLetter(l);
      if (z === startingLetter) {
        return p.slice(0, -1) + nextLetter(q.slice(-1).charCodeAt(0)) + z;
      } else {
        return p + z;
      }
    }
  }

  const generateUnitDataTable = () => {

    if (!formRef.current.reportValidity())
      return;

    let dataTable = [];
    let unitRecord = {};
    let unitIncrement = state.startUnit.toString();
    let meterNumberIncrement = state.startMeterNum;
    let ertNumberIncrement = state.startERT;

    let isChar = false;

    if (!isNaN(state.startUnit)) {
      unitIncrement = Number(state.startUnit);
    } else {
      isChar = true;
      unitIncrement = state.startUnit;
      setStartingLetter(state.startUnit);
    }

    for (let x = 0; x < Number(state.numberOfUnits); x++) {

      //Get new Unit

      unitRecord = {};
      unitRecord = {
        ...state,
        address2: state.startUnit ? `${state.addressType} ${unitIncrement}` : "",
        meterNumber: "",
        ertNumber: "",
        commonAccount: false
      }
      delete unitRecord.addressType //Not needed in
      dataTable.push(unitRecord);
      if (isChar) {
        // Set last char to incremented Char
        unitIncrement = nextCharCustom(unitIncrement);
      }
      else {
        unitIncrement++;
      }

      meterNumberIncrement++;
      ertNumberIncrement++;
    }
    let selectedPM = props.propertyManagers.data.propertyManagersList.items.find(v => v.id == state.propertyManager)
    let pmCustomerType = selectedPM.developerId ? props.apl.genericTypes.customerType.data.find(v => v.description == "Developer") :  props.apl.genericTypes.customerType.data.find(v => v.description == "Management Co")
    let activeAccountStatus = props.apl.genericTypes.accountStatus.data.find(v => v.description == "Active")
    props.onGenerateDataTable(dataTable, selectedPM, pmCustomerType, activeAccountStatus)
    dispatch(setSnackbar(true, "success", "New data generated."))
  }

  const nextChar = (c) => {
    c = String(c)
    return String.fromCharCode(c.charCodeAt() + 1);
  }

  useEffect(() => {
    async function fetchData() {
      await props.getAllUnitTypes();
      await props.getAllCustomerTypes()
      await props.getPropertyByUuid(propertyUuid);
      await props.getAllAccountStatusCodes();

      try {
        let headers = {
          "x-access-token": localStorage.getItem("token"),
            };       
        let response = await axios.get("/owner", { headers });
        setOwners(response.data)
        if (response.status != 200) throw new Error("Fail.");

      } catch(e) {
          console.log("Error getting owners", e)
      } 
      
      if (props.propertyManagers.data.propertyManagersList.items.length <= 0) {
        await props.getPropertyManager();
        propertyManagers = props.propertyManagers.data.propertyManagersList.items.map(pm => {
          return { label: `${pm.name}`, id: pm.id }
        })

        setPM(propertyManagers);
        setProperty(props.properties.data.property)
        setState({
          ...state,
          billingType: props.properties.data.property.propertyUtilityType?.billingType,
          address1: props.properties.data.property.address,
          state: props.properties.data.property.state,
          city: props.properties.data.property.city,
          zip: props.properties.data.property.zip,
          propertyManager: props.properties.data.property.propertyManagerId,
          propertyId: props.properties.data.property.id,
          developerId: props.properties.data.property.developerId,
          ownerId: props.properties.data.property.ownerId,
          developer: props.properties.data.property.developer.name,
          owner: props.properties.data.property.owner?.name,
          propertyManagerName: props.properties.data.property.propertyManager.name,
        })
        setIsLoading(false);

      }
    }
    fetchData();
  }, []);

  const formRef = React.useRef();

  return (
      <Grid
          container
          spacing={4}
      >
          {(!isLoading) &&
          <div>
              <form ref={formRef}>
                  <Grid
                      container
                      spacing={4}
                  >

                      <Grid
                          item
                          lg={6}
                          xs={12}
                      >
                          <div className="p-3">
                              <div className="card-header--title">
                                  <p>
                                      Proposed Address:
                                      {fullAddress}
                                  </p>

                                  <b>
                                      Property:
                                      {property?.name || ""}
                                  </b>
                                  <b>
                                      {`Owner: ${property.owner?.name || ""}`}
                                  </b>
                                  <b>
                                      {`Developer: ${property.developer.name || ""}`}
                                  </b>

                              </div>
                              <TextField
                                  className="m-2"
                                  defaultValue={property.moveInDate || ""}
                                  fullWidth
                                  helperText="Move In Date"
                                  id="standard-size-small"
                                  name="moveInDate"
                                  onChange={handleChange}
                                  required
                                  size="small"
                                  type="date"
                                  value={state.moveInDate}
                              />
                              <TextField
                                  className="m-2"
                                  fullWidth
                                  helperText="Please select unit type"
                                  id="unitType"
                                  label="Unit Type"
                                  name="unitType"
                                  onChange={handleChange}
                                  required
                                  select
                                  value={state.unitType}
                              >
                                  {props.apl.genericTypes.unitType.data.map(option => (
                                      <MenuItem
                                          key={option.id}
                                          value={option.id}
                                      >
                                          {option.description}
                                      </MenuItem>
                    ))}
                  </TextField>
                             
                              <Autocomplete
                                  className="m-2"
                                  defaultValue={owners.find(o => o?.id === props.properties.data.property.owner?.id)}
                                  disablePortal
                                  id="standard-size-small"
                                  getOptionLabel={(option) => option.name + "  " + "(" + option.customGLCode + ")"}
                                  name="owner"
                                  onChange={(event, value) => 
                                    setState({ ...state, ownerId: value?.id || "" })
                                  }
                                  options={owners}
                                  renderInput={(params) => (<TextField
                                      {...params}
                                      id="standard-size-small"
                                      label="Owner"
                                      size="small"
                                                            />)}
                                  required
                                  size="small"
                              />

                              <TextField
                                  className="m-2"
                                  defaultValue={property.address1 || ""}
                                  fullWidth
                                  id="standard-size-small"
                                  label="Address 1"
                                  name="address1"
                                  onChange={handleChange}
                                  required
                                  size="small"
                                  value={state.address1}
                              />
                              <TextField
                                  className="m-2"
                                  fullWidth
                                  helperText="Please select your address unit type such as APT, UNIT, STE, etc."
                                  id="standard-select-currency"
                                  label="Address Type"
                                  name="addressType"
                                  onChange={handleChange}
                                  value={state.addressType}
                              />
                              <TextField
                                  className="m-2"
                                  defaultValue={property.city || ""}
                                  fullWidth
                                  id="standard-size-small"
                                  label="City"
                                  name="city"
                                  onChange={handleChange}
                                  required
                                  size="small"
                                  value={state.city}
                              />
                              <TextField
                                  className="m-2"
                                  defaultValue={property.state || ""}
                                  fullWidth
                                  id="standard-size-small"
                                  label="State"
                                  name="state"
                                  onChange={handleChange}
                                  required
                                  size="small"
                                  value={state.state}
                              />
                              <TextField
                                  className="m-2"
                                  defaultValue={property.zip || ""}
                                  fullWidth
                                  id="standard-size-small"
                                  label="Zip"
                                  name="zip"
                                  onChange={handleChange}
                                  required
                                  size="small"
                                  value={state.zip}
                              />
                              <TextField
                                  className="m-2"
                                  defaultValue={property.heatingType || ""}
                                  fullWidth
                                  id="standard-size-small"
                                  label="Heating Type"
                                  name="heatingType"
                                  onChange={handleChange}
                                  required
                                  size="small"
                                  value={state.heatingType}
                              />
                              <TextField
                                  className="m-2"
                                  defaultValue={property.sqFt || ""}
                                  fullWidth
                                  id="standard-size-small"
                                  label="SQ Footage"
                                  name="sqFt"
                                  onChange={handleChange}
                                  required
                                  size="small"
                                  value={state.sqFt}
                              />
                              <TextField
                                  className="m-2"
                                  defaultValue={property.bedrooms || ""}
                                  fullWidth
                                  id="standard-size-small"
                                  label="Bedrooms"
                                  name="bedrooms"
                                  onChange={handleChange}
                                  required
                                  size="small"
                                  value={state.bedrooms}
                              />
                              <TextField
                                  className="m-2"
                                  defaultValue={property.baths || ""}
                                  fullWidth
                                  id="standard-size-small"
                                  label="Baths"
                                  name="baths"
                                  onChange={handleChange}
                                  required
                                  size="small"
                                  value={state.baths}
                              />
                              <TextField
                                  className="m-2"
                                  defaultValue={property.buildingNumber || ""}
                                  fullWidth
                                  id="standard-size-small"
                                  label="Building Number"
                                  name="buildingNumber"
                                  onChange={handleChange}
                                  required
                                  size="small"
                                  value={state.buildingNumber}
                              />
                              {(props.properties.data.property.propertyManagerId && pm.length > 0) &&
                              <Autocomplete
                                  className="m-2"
                                  defaultValue={pm.find(v => v.id == props.properties.data.property.propertyManagerId)}
                                  disablePortal
                                  id="standard-size-small"
                                  isOptionEqualToValue={(option, value) => option.id === value.id}
                                  name="propertyManager"
                                  onChange={(event, value) => setState({ ...state, propertyManagerName: value.label, propertyManager: value?.id || "" })}
                                  options={pm}
                                  renderInput={(params) => (<TextField
                                      {...params}
                                      id="standard-size-small"
                                      label="Property Manager"
                                      size="small"
                                                            />)}
                                  required
                                  size="small"
                              />}
                          </div>
                      </Grid>
                      <Grid
                          item
                          lg={6}
                          xs={12}
                      >
                          <div className="p-3">

                              <TextField
                                  className="m-2"
                                  fullWidth
                                  id="standard-size-small"
                                  label="Number of Units to Create"
                                  name="numberOfUnits"
                                  onChange={handleChange}
                                  required
                                  size="small"
                                  type="number"
                                  value={state.numberOfUnits}
                              />

                              <TextField
                                  className="m-2"
                                  defaultValue=""
                                  fullWidth
                                  helperText="Add either a standard number, or letter. We will automatically create unit addresses based on this. For example A, will continue with B, C. The number 100, will continue with 101, 102"
                                  id="standard-size-small"
                                  label="Start Unit Identifier"
                                  name="startUnit"
                                  onChange={handleChange}
                                  size="small"
                                  value={state.startUnit}
                              />
                              {/* <TextField
                    fullWidth
                    required
                    className="m-2"
                    label="Start ERT Identifier"
                    id="standard-size-small"
                    defaultValue=""
                    size="small"
                    helperText="Add either a standard number. We will automatically increment the amount by 1 for each created unit."
                    value={state.startERT}
                    onChange={handleChange}
                    name="startERT"
                  />
                  <TextField
                    fullWidth
                    required
                    className="m-2"
                    label="Start Meter Identifier"
                    id="standard-size-small"
                    defaultValue=""
                    type="number"
                    size="small"
                    helperText="Add either a standard number. We will automatically increment the amount by 1 for each created unit."
                    value={state.startMeterNum}
                    onChange={handleChange}
                    name="startMeterNum"
                  /> */}

                              <Button
                                  className="m-2"
                                  color="primary"
                                  onClick={generateUnitDataTable}
                                  variant="contained"
                              >
                                  Generate Table
                              </Button>
                          </div>
                      </Grid>
                  </Grid>
              </form>
          </div>}
      </Grid>
  );
}
// Component Properties
UnitForm.propTypes = {
  // Store objects:
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  getAllUnitTypes: PropTypes.func.isRequired,
  propertyManagers: PropTypes.object.isRequired,
  getPropertyManager: PropTypes.func.isRequired,
  property: PropTypes.object.isRequired,
  getPropertyByUuid: PropTypes.object.isRequired,
  getAllCustomerTypes: PropTypes.func.isRequired,
  getAllAccountStatusCodes: PropTypes.func.isRequired,
};

// Component State
function UnitFormState(state) {
  return {
    user: state.user,
    apl: state.apl,
    propertyManagers: state.propertyManagers,
    properties: state.properties,
    owners: state.owners
  };
}

export default withRouter(connect(UnitFormState, { logout, getAllUnitTypes, getPropertyManager, getPropertyByUuid, getAllCustomerTypes, getAllAccountStatusCodes })(
  UnitForm
));
