import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";

import Autocomplete from "@material-ui/lab/Autocomplete"
import Helmet from "react-helmet";
import { Chip } from "@material-ui/core";
import {
  Checkbox, Grid,
  ButtonGroup, IconButton, Link, Breadcrumbs as MuiBreadcrumbs, Divider as MuiDivider, Paper as MuiPaper, Table, TableBody, TableContainer, TableCell,
  TableHead, TablePagination, TableRow, TableSortLabel, Toolbar, Tooltip, Typography, FormControlLabel, Switch, Menu, MenuItem, TextField as MuiTextField, Button as MuiButton,
  FormControl, InputLabel, Select, Input,
  Card as MuiCard,
  CardContent,
  CircularProgress as MuiCircularProgress,
} from "@material-ui/core";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  Loop as LoopIcon,
} from "@material-ui/icons";

//import OutlinedInput from '@material-ui/core/OutlinedInput';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";
//Material UI Icons
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';


//Material UI Components and Functions
import { makeStyles } from '@material-ui/core/styles';


import { spacing } from "@material-ui/system";

import {
  EditableTable, newDropDownField,
  newConditionalOnSelectButton
} from "../../../components/EditableTable";

import SearchIcon from '@material-ui/icons/Search';
import OutlinedInput from '@material-ui/core/OutlinedInput';

// Redux Components
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { logout } from '../../user/api/actions';
import { searchAccountsByFieldAndValue, updateFilters, accountQuickSearch } from '../api/actions';
import { getAllPropertyPreviews } from '../../apl/api/actions';
import { addAccountsSelectedToCheck } from '../../payments/api/actions';
import StatusTag from "../../apl/components/StatusTag";
const Button = styled(MuiButton)(spacing);
const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)(spacing);
const DashboardToolBarButton = styled(Button)`
padding: 4px;
min-width: 0;
svg {
    width: 2em;
    height: 2em;
}
`;

const CircularProgress = styled(MuiCircularProgress)(spacing);

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  searchBarGrid: {
    //paddingBottom: "10px",
    fontSize: "90%",
    height: "5%"
  },
  searchDropDownButton: {
    height: "90%"
  },
  percentDif: {
    textAlign: 'right',
    color: 'green'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  loadingIcon: {
    position: 'absolute', left: '50%', top: '50%',
    transform: 'translate(-50%, -50%)'
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  active: {
    fontWeight: "bold",
    color: "#f9a825",
    borderColor: "#f9a825",
  },
  inactive: {
    fontWeight: "bold",
  },
  search: {
    width: "10em",
  },
  multilineColor: {
    color: "black",
    borderColor: "black",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "black"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "black"
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "black"
    }
  }
}));

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Spacer = styled.div`
  flex: 1 1 100%;
`;

//Editable Table Object Expects: {objectArray, fieldTitles(Array), fieldTypes(Array), fieldNames(Array), handleRowClickParentMethod}
// Method can take line items if they exist initially or starts as an empty array of nothing passed
function AccountPreviewsTable(props) {
  const dispatch = useDispatch();
  const [accountStatusQueryType, setAccountStatusQueryType] = React.useState(0);

  const classes = useStyles();
  const history = useHistory();

  // Filters
  const [customerType, setCustomerType] = React.useState(null);
  const [accountStatusType, setAccountStatusType] = React.useState("Active");
  const [searchQuery, setSearchQuery] = React.useState(""); // should be all thats needed to reduce the disconnects shown to only that accounts
  const [property, setProperty] = React.useState(null);
  const [propertyName, setPropertyName] = React.useState(null);
  const [accountBalanceOwedFilter, setAccountBalanceOwedFilter] = React.useState(false);
  const [focus, setFocus] = React.useState(false);

  // When the textbox changes
  const handleSearchTargetChange = (event) => {
    setSearchQuery(event.target.value);
  }


  const keyPress = (event) => {
    if(event.keyCode == 13){
       console.log('value', event.target.value);
       history.push({ pathname: "/accountmanager/" + searchQuery });
      }
    }

  const getAccountsWithQuery = () => {
    props.accountQuickSearch({ searchQuery });
  };

  const setSearchFocus = () => {
    // setFocus(true);
  }
  const unsetSearchFocus = () => {
    setFocus(false);
  }

  return (
    <>
      <div className={classes.search}>
              <TextField
                label="Go to Account"
                classes={{ inputRoot: classes.multilineColor }}
                onChange={handleSearchTargetChange}
                onKeyDown={keyPress}
                value={searchQuery}
                variant="outlined"
                sx={{ width: 300 }}
                InputLabelProps={{
                  shrink: true,
                  className: classes.multilineColor
                }}
                fullWidth
                my={2}
              />
      </div>
    </>
  );
}
// Component Properties
AccountPreviewsTable.propTypes = {
  // Store objects:
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  billing: PropTypes.object.isRequired,
  accountsSearch: PropTypes.object.isRequired,
  searchAccountsByFieldAndValue: PropTypes.func.isRequired,
  getAllPropertyPreviews: PropTypes.func.isRequired,
  updateFilters: PropTypes.func.isRequired,
  accountQuickSearch: PropTypes.func.isRequired,
}

// Component State
function AccountPreviewsTableState(state) {
  return {
    user: state.user,
    apl: state.apl,
    billing: state.billing,
    accountsSearch: state.accountsSearch
  }
}
export default connect(AccountPreviewsTableState, { searchAccountsByFieldAndValue, getAllPropertyPreviews, updateFilters, accountQuickSearch, addAccountsSelectedToCheck })(AccountPreviewsTable)


