// Main setup for the store object in our application. This is where all the context esq stuff is imported for centralized use in the application

// Imports
import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import account from "../modules/account/api/state";
import accounting from "../modules/accounting/api/state";
import accountsSearch from "../modules/accountsSearch/api/state";
import apl from "../modules/apl/api/state";
import billing from "../modules/billing/api/state";
import bills from "../modules/bills/api/state";
import delinquencies from "../modules/delinquencies/api/state";
import developers from "../modules/developer/api/state";
import meters from "../modules/meter/api/state";
import moves from "../modules/moves/api/state";
import notes from "../modules/notes/api/state";
import owners from "../modules/owner/api/state";
import payments from "../modules/payments/api/state";
import properties from "../modules/property/api/state";
import propertyManagers from "../modules/propertyManager/api/state";
import propertyManagerStatuses from "../modules/propertyManagerStatus/api/state";
import propertyStatuses from "../modules/propertyStatus/api/state";
import propertyUtilities from "../modules/propertyUtility/api/state";
import propertyGLCode from "../modules/propertyGLCode/api/state";
import meterReadingsUpload from "../modules/readings-uploads/api/state";
import lockBoxUpload from "../modules/lockbox-uploads/api/state";
import setting from "../modules/settings/api/state";
import snackbar from "../modules/snackbar/api/snackbar";
import units from "../modules/unit/api/state";
import unitRates from "../modules/unitRate/api/state";
import unitTypes from "../modules/unitType/api/state";
import workOrders from "../modules/workOrders/api/state";
import notification from "../modules/notification/api/state";
import files from "../modules/files/api/state";
import ownerStatements from '../modules/ownerStatement/api/state'
import ownerJournals from '../modules/ownerJournal/api/state'
import reducer from "../reducers";

// App Imports
import user from "../modules/user/api/state";
import userProfiles from "../modules/userProfiles/api/state";
import vendors from "../modules/vendor/api/state";

// App Reducer
const appReducer = combineReducers({
  user,
  billing,
  accounting,
  notes,
  delinquencies,
  apl,
  accountsSearch,
  setting,
  account,
  bills,
  payments,
  userProfiles,
  meterReadingsUpload,
  moves,
  properties,
  units,
  meters,
  propertyManagers,
  owners,
  developers,
  vendors,
  propertyStatuses,
  unitRates,
  unitTypes,
  propertyUtilities,
  propertyGLCode,
  propertyManagerStatuses,
  snackbar,
  workOrders,
  notification,
  lockBoxUpload,
  files,
  ownerStatements,
  ownerJournals,
  ...reducer
});

// Root Reducer
export const rootReducer = (state, action) => {
  if (action.type === "RESET") {
    state = undefined;
  }

  return appReducer(state, action);
};

// Load initial state from server side
let initialState;
if (typeof window !== "undefined") {
  initialState = window.__INITIAL_STATE__;
  delete window.__INITIAL_STATE__;
}

// Store
export const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(thunk))
);
