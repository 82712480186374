//React Components and Hooks
import Box from "@material-ui/core/Box";
//Material UI Components
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { passwordReset } from "../../modules/user/api/actions";
//Context
const queryString = require("query-string");

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="">
        Columbus& Central Ohio Systems LLC
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    //backgroundColor: '#fff'
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const ResetPassword = (props) => {
  const [open, setOpen] = useState(false);
  let [state, setState] = useState({
    isLoginError: false,
    loginErrorMessage: "",
    loginLoading: false,
    success: false,
  });
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const newPasswordRef = useRef();
  const confirmPasswordRef = useRef();

  const params = props.match.params;
  if (params.token == undefined) {
    //window.location = '/login'
  }

  const handleClickOpen = () => {
    setOpen(!open);
  };

  function authenticate() {
    if (newPasswordRef.current.value != confirmPasswordRef.current.value) {
      setState({
        loginErrorMessage: "Passwords must match",
        isLoginError: true,
      });
      return;
    }
    setState({ loginLoading: true });
    props
      .passwordReset(params.token, newPasswordRef.current.value)
      .then((response) => {
        setState({ loginLoading: false });
      })
      .catch((error) => {
        setState({ loginLoading: false });
      });
  }
  useEffect(() => {
    if (
      window.localStorage.getItem("token") &&
      window.localStorage.getItem("user")
    ) {
      props.history.push({ pathname: "/dashboard" });
    }
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        authenticate();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [props.user]);

  const classes = useStyles();
  return (
    <Container className={classes.container} component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Columbus& Central Ohio Systems
        </Typography>
        <Typography component="h1" variant="h6">
          Reset Password
        </Typography>
        <Typography component="h1" variant="h6">
          {params.email || ""}
        </Typography>
        {state.loginLoading ? (
          <CircularProgress color="inherit" />
        ) : (
          <div></div>
        )}
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="password"
            inputRef={newPasswordRef}
            label="New Password"
            name="newPassword"
            error={state.isLoginError}
            type="password"
            autoComplete="password"
            autoFocus
            required
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            inputRef={confirmPasswordRef}
            error={state.isLoginError}
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            id="confirmPassword"
            required
            autoComplete="current-password"
          />
          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.submit}
            onClick={authenticate}
            disabled={loading}
          >
            {state.loginLoading ? "Loading..." : "Reset Password"}{" "}
          </Button>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};

// Component Properties
ResetPassword.propTypes = {
  user: PropTypes.object.isRequired,
  login: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

// Component State
function ResetPasswordState(state) {
  return {
    user: state.user,
  };
}
export default connect(ResetPasswordState, { passwordReset })(ResetPassword);
//Local
//Dev
