import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import EditMoveInForm from "./EditMoveInForm";
import EditMoveOutForm from "./EditMoveOutForm";

function getModalStyle() {
  const top = 40;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "auto",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
    paddingTop: "5%",
    maxHeight: "100%",
    overflow: "scroll",
  },
}));

export default (props) => {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  const [editData, setEditData] = useState({}); // will hold the data edited

  const open = props.open;
  const handleClose = props.handleClose;
  const editObj = props.editObj;
  const action = props.action;
  const editType = props.editType;
  const properties = props.properties;

  const onFieldChange = (e) => {
    setEditData({ ...editData, [e.target.name]: e.target.value });
  };

  const onFieldCheckboxChange = (e) => {
    setEditData({
      ...editData,
      [e.target.name]: e.target.checked == true ? 1 : 0,
    });
  };

  const handleAction = () => {
    // editData will be the modified info, editObj is the original object
    action(editObj, editData, editType);
    setEditData({});
  };
  return (
    <Modal open={open} onClose={handleClose}>
      <div style={modalStyle} className={classes.paper}>
        <h1 style={{ textAlign: "left" }}>
          Edit {editType == "movein" ? "Move In" : "Move Out"}
        </h1>

        {editType == "movein" ? (
          <EditMoveInForm
            editData={editData}
            onFieldChange={onFieldChange}
            moveIn={editObj}
            action={handleAction}
            handleClose={handleClose}
            properties={properties}
            onFieldCheckboxChange={onFieldCheckboxChange}
          />
        ) : (
          <EditMoveOutForm
            editData={editData}
            onFieldChange={onFieldChange}
            moveOut={editObj}
            action={handleAction}
            handleClose={handleClose}
            properties={properties}
          />
        )}
      </div>
    </Modal>
  );
};
