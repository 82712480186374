import {
  Button,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
// Custom Componenets
//Material UI Components and Functions
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Alert from "@material-ui/lab/Alert";
import { spacing } from "@material-ui/system";
// Redux Components
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import {
  handleBillingProcessSubmission,
  updateBillingWizardBillInProgress,
  getAllUnsettledPropertyMeterReadingsByPropertyUUID
} from "../../../../modules/billing/api/actions";

const CenteredContent = styled.div`
  text-align: center;
`;

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const FormControl = styled(MuiFormControl)(spacing);
const TextField = styled(MuiTextField)(spacing);

// Custom Styles for property selection drop down

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  table: {
    width: "100%",
  },
  selectedPropertyLocationLabel: {
    fontSize: 50,
    backgroundColor: "#fffffff",
    borderColor: "#fffffff",
  },
  loadingIconContainer: {
    display: "flex",
    alignItems: "center",
  },
  loadingIcon: {
    position: "relative",
    padding: "50px",
  },
  meterReadingSuccessColor: {
    color: "#1e4620",
  },
  meterReadingFailColor: {
    color: "#611a15",
  },
  meterReadingValue: {
    fontWeight: "bolder",
  },
}));

const MeterReadCheckForm = (props) => {
  const [billInProgress, setbillInProgress] = React.useState(
    props.billing.data.billInProgress
  );
  const classes = useStyles();
  // On initial page load methods:
  useEffect(() => {
    // Needs to get all unsettled reads associated with that property
  }, []);

  const updateCurrentBillingObjAndRunTrialBillsGeneration = () => {
    props.billing.data.billInProgress.currentStep = props.billing.data.billInProgress.currentStep + 1; // Sets the state to the next phase before saving
    props.billing.data.billInProgress.generateTrialBilling = true;
    props.updateBillingWizardBillInProgress(props.billing.data.billInProgress);
    props.handleBillingProcessSubmission(props.billing.data.billInProgress, true).then(() => {
      props.getAllUnsettledPropertyMeterReadingsByPropertyUUID(props.properties.data.property.id);
    });
  };
  const GetMeterReadingsStatus = () => {
    // Where the evaluation logic goes for checking if theres meter readings for each unit:
    if (props.billing.data.selectedProperty.id) {
      // If a selected property exists:
      // If theres an issue loading in the property object
      if (!props.billing.data.selectedProperty.meterReadings) {
        return (
          <Typography variant="h5">
            ERROR, Unable to load meter readings from datbase. Property object
            may not be valid
          </Typography>
        );
      }
      // If theres enough actual meter readings to move forward
      else if (
        props.billing.data.selectedProperty.meterReadings.actualCount >=
        props.billing.data.selectedProperty.totalUnits
      ) {
        return (
          <Typography variant="h5" className={classes.meterReadingSuccessColor}>
            {props.billing.data.selectedProperty.meterReadings.actualCount}{" "}
            Actual Unbilled Readings -{" "}
            {props.billing.data.selectedProperty.meterReadings.estimateCount}{" "}
            Estimate Unbilled Readings
          </Typography>
        );
      } else {
        return (
          <Typography variant="h5" className={classes.meterReadingFailColor}>
            {props.billing.data.selectedProperty.meterReadings.actualCount}{" "}
            Actual Unbilled Readings -{" "}
            {props.billing.data.selectedProperty.meterReadings.estimateCount}{" "}
            Estimate Unbilled Readings
          </Typography>
        );
      }
    }
  };
  return (
    <Card mb={6} elevation={3}>
      <CardContent>
        {/* Main Content (Just imports the properties meter data manager) */}
        {props.billing.isLoading ||
          props.billing.selectedPropertyIsLoading ||
          props.billing.selectedPropertyMetersAreLoading ? (
          <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"
            spacing={4}
          >
            <Grid item xs={12} lg={12}>
              <div className={classes.loadingIcon}>
                <CircularProgress m={2} />
              </div>
            </Grid>
          </Grid>
        ) : props.billing.data.selectedProperty.id ? (
          <Grid container spacing={4}>
            <Grid item xs={12} lg={12}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="h5">
                          Current Property Meter Readings:
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h5">
                          <GetMeterReadingsStatus />
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"
            spacing={4}
          >
            <Grid item xs={12} lg={12}>
              <Typography variant="h5">No Property Data Loaded</Typography>
            </Grid>
          </Grid>
        )}
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12}>
            {props.billing.data.billInProgress.errors.length > 0 && (
              <Alert severity="error">
                Cannot generate trial billing items until property issues are
                resolved.
              </Alert>
            )}
          </Grid>
          {props.billing.data.billInProgress.errors.length == 0 &&
            !props.billing.isLoading && (
              <>
                {/*             Col 1            */}
                <Grid item lg={6} md={12} sm={12}>
                  <Button
                    mr={1}
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={updateCurrentBillingObjAndRunTrialBillsGeneration}
                    my={2}
                  >
                    Generate Trial Items For Billing Period
                  </Button>
                </Grid>
                {/*             Col 2            */}
                <Grid item lg={6} md={12} sm={12}>
                  <Button
                    mr={1}
                    variant="contained"
                    color="primary"
                    fullWidth
                    my={2}
                    onClick={() => {
                      window.open("/meter-readings-upload");
                    }}
                  >
                    Go To Uploads
                  </Button>
                </Grid>
              </>
            )}
        </Grid>
      </CardContent>
    </Card>
  );
};

// Component Properties
MeterReadCheckForm.propTypes = {
  // Store objects:
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  billing: PropTypes.object.isRequired,
  properties: PropTypes.object.isRequired,
  // Store object functions:
  updateBillingWizardBillInProgress: PropTypes.func.isRequired,
  handleBillingProcessSubmission: PropTypes.func.isRequired,
  getAllUnsettledPropertyMeterReadingsByPropertyUUID: PropTypes.func.isRequired,
};

// Component State
function MeterReadCheckFormState(state) {
  return {
    user: state.user,
    apl: state.apl,
    properties: state.properties,
    billing: state.billing,
  };
}
export default connect(MeterReadCheckFormState, {
  handleBillingProcessSubmission,
  updateBillingWizardBillInProgress, getAllUnsettledPropertyMeterReadingsByPropertyUUID
})(MeterReadCheckForm);
