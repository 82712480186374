import {
  Avatar,
  Badge,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card,
  Divider as MuiDivider,
  Fab,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Switch,
  TextField as MuiTextField,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  FormControl
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import HelpIcon from "@material-ui/icons/Help";
import SendIcon from "@material-ui/icons/Send";
import { spacing } from "@material-ui/system";
// Redux Components
import PropTypes from "prop-types";
import React from "react";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components/macro";
import {
  dateFormatter,
  dateTime,
  dateTimeLocal,
  dateTimeMediumLength,
  formatMoney
} from "../../../setup/helpers";
import { getAllNoteTopicTypes } from "../../apl/api/actions";
import {
  createNewNoteReply,
  deleteNoteOrReply,
  setNotes,
  updateNote,
  createNewNote
} from "../api/actions";
import UpdateNoteReplyForm from "./AccountNoteReplyEditForm";
import NewNoteForm from "./NewNoteForm";
import moment from "moment";
import { setSnackbar } from "../../snackbar/api/snackbar";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ChatWindow(props) {
  const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

  const Divider = styled(MuiDivider)(spacing);

  const TextField = styled(MuiTextField)(spacing);

  const ChatContainer = styled(Grid)`
    width: 100%;
    height: 65vh;
  `;
  const ChatSidebar = styled(Grid)`
    border-right: 1px solid;
    border-color: grey;
  `;

  const ChatMain = styled(Grid)``;

  const ChatMessages = styled.div`
    overflow-y: scroll;
    height: calc(65vh - 94px);
  `;

  const ChatMessage = styled.div`
    margin: 30px;
    text-align: ${(props) => props.position};
  `;

  const ChatMessageInner = styled.div`
    display: inline-block;
  `;

  const ChatMessageTime = styled(Typography)`
    text-align: ${(props) => props.position};
    opacity: 0.8;
  `;

  const ChatMessageAvatar = styled(Avatar)`
    position: relative;
    display: inline-block;
    text-align: center;
    padding-top: 10px;
    width: 40px;
    height: 40px;
    margin-right: 10px;
  `;

  const ChatMessageBubble = styled.div`
    text-align: left;
    display: inline-block;
    margin-right: auto;
    background: white;
    color: black;
    margin-bottom: 5px;
    width: 80%;
    border: 0.5px solid;
    border-color: black;
    border-radius: 5px;
    padding: 2%;
    overflow-x: hidden;
  `;

  const ChatMessageBubbleName = styled(Typography)`
    font-weight: bold;
  `;

  const ChatInput = styled(Grid)`
    margin-top: 10px;
    min-height: 94px;
    padding: 8px;
  `;

  const Online = styled(Badge)`
    margin-right: 5px;
    span {
      background-color: blue;
      border: 1.5px solid;
      height: 12px;
      width: 12px;
      border-radius: 50%;
    }
  `;

  const autoNoteTypes = [
    { type: "General Note", display: "Balance Inquiry" },
    { type: "Payment Only", display: "Payment Only" },
    { type: "General Note", display: "Payment Inquiry", message: "Explained payment options to customers" },
    { type: "General Note", display: "Past Due Inquiry" },
    { type: "Service Problem", display: "Outage Reported", message: "Customer has called to report outage" },
    { type: "Move In/Out Inquiry", display: "Move - Explained to customer stop service process", message: "Explained to customer stop service process" },
    { type: "Move In/Out Inquiry", display: "Move - Submitted stop service request for customer", message: "Submitted stop service request for customer" },
    { type: "Move In/Out Inquiry", display: "Move - Explained to Customer stop and start service request", message: "Explained to Customer stop and start service request" },
    { type: "Move In/Out Inquiry", display: "Move - Explained to Customer property will submit stop service request", message: "Explained to Customer property will submit stop service request" }
  ]

  // Styling:
  const useStyles = makeStyles((theme) => ({
    chatsListBox: {
      maxHeight: "calc(65vh - 94px)",
      height: "calc(65vh - 94px)",
      overflowY: "scroll",
    },
    selectedListItem: {
      fontWeight: "bold",
      fontSize: "105%",
      backgroundColor: "lightgrey",
    },
    rightSideHeaderSubMenuToolBar: {
      display: "flex",
      alignItems: "flex-end",
      flexDirection: "row",
      justifyContent: "flex-end",
      margin: "0px",
      padding: "0px",
    },
    rightSideHeader: {
      display: "flex",
      alignItems: "flex-end",
      flexDirection: "row",
      justifyContent: "flex-end",
    },
    topicNote: {
      padding: "5%",
      paddingBottom: "1%",
      paddingTop: "2%",
    },
  }));

  const classes = useStyles();

  const [currentChat, setChat] = React.useState(0);
  const [selectedReply, setSelectedReply] = React.useState(-1);
  const [currentChatIsComplete, setCurrentChatIsComplete] = React.useState(
    (props.notes.data[currentChat] &&
      props.notes.data[currentChat].completed) ||
    false
  );

  const [newNoteFormOpen, setNewNoteFormOpen] = React.useState(false);
  const [editNoteFormOpen, setEditNoteFormOpen] = React.useState(false);
  const [currentSearch, setCurrentSearch] = React.useState("");
  const [selectedAutoNote, setSelectedAutoNote] = React.useState(-1);
  const [focus, setFocus] = React.useState(false);

  const handleEditNoteFormClickOpen = (index) => {
    setSelectedReply(index);
    setEditNoteFormOpen(true);
  };

  const handleRemoveNoteClick = (index) => {
    if (index == -1) {
      // Deletes note and cuts from current stores memory
      props.deleteNoteOrReply(props.notes.data[currentChat]);
      if (currentChat >= 1) setChat(currentChat - 1);
      props.notes.data.splice(currentChat, 1);
    } else {
      // Deletes reply and cuts from current stores memory
      props.deleteNoteOrReply(props.notes.data[currentChat].replys[index]);
      props.notes.data[currentChat].replys.splice(index, 1);
    }
  };

  const handleChatsSearchedValueChanged = (event) => {
    setChat(0); // Resets current chat to top possible option
    setCurrentSearch(event.target.value);
  };

  const handleEditNoteFormClose = () => {
    setEditNoteFormOpen(false);
  };

  const handleNewNoteFormClickOpen = () => {
    setNewNoteFormOpen(true);
  };

  const handleNewNoteFormClose = (didGenerateNote = false) => {
    if (didGenerateNote) {
      setChat(0);
    }
    setNewNoteFormOpen(false);
  };
  const setSearchFocus = () => {
    setFocus(true);
  }
  const unsetSearchFocus = () => {
    setFocus(false);
  }
  const onReplyMessageChange = (event) => {
    props.notes.newNoteReply.reply = event.target.value;
  };

  const handleChatSelectionChange = (index) => {
    setCurrentChatIsComplete(props.notes.data[index].completed);
    setChat(index);
  };

  const makeNewReply = () => {
    props.notes.newNoteReply.noteId = props.notes.data[currentChat].id;
    props.createNewNoteReply(
      props.notes.newNoteReply,
      props.user.details,
      false
    );
  };

  const handleSelectedAutoNoteChange = (event) => {
    setSelectedAutoNote(event.target.value);
  };

  const handleAutoNoteGeneration = () => {
    // selected auto note is an index 
    var currentNote = autoNoteTypes[selectedAutoNote];
    var newNote = {
      note: "",
      dueDate: "",
      noteTopicId: 34, // Defaults to general note
      accountId: props.account.data.account.id,
      completed: 1
    };
    // Get the type Id
    if (props.apl.genericTypes.noteTopic.data && props.apl.genericTypes.noteTopic.data.length > 0) {
      props.apl.genericTypes.noteTopic.data.map((item, key) => {
        if (item.description == currentNote.type) {
          newNote.noteTopicId = item.id;
        }
      })
    }
    if (currentNote.message) {
      newNote.note = currentNote.message;
    }
    else {
      if (selectedAutoNote == 0) { //balance inquiry
        if (props.account.data.ongoingAccountBalance.balance > 0) {
          newNote.note = `Customer requested balance of ${formatMoney(props.account.data.ongoingAccountBalance.balance)} due ${moment(props.bills.data.items[0].dueDate).format("MM-DD-YY")}`
        }
        else {
          newNote.note = `Customer requested balance of ${formatMoney(props.account.data.ongoingAccountBalance.balance)}`
        }
      }
      else if (selectedAutoNote == 1) { // Payment Only
        if (props.payments.data.items.length > 0) {
          newNote.note = `Credit/Debit Card or E-Check payment #${props.payments.data.items[0].id} ${formatMoney(props.account.data.ongoingAccountBalance.lastPaymentAmount)} (including fee)`;
        }
        else {
          newNote.note = `Credit/Debit Card or E-Check payment # N/A ${formatMoney(props.account.data.ongoingAccountBalance.lastPaymentAmount)} (including fee)`
        }
      }
      else if (selectedAutoNote == 3) { //Past Due Inquiry
        if (props.account.data.ongoingAccountBalance.balance > 0) {
          newNote.note = `Customer requested past due balance of ${formatMoney(props.account.data.ongoingAccountBalance.pastDue)} due ${moment(props.bills.data.items[0].dueDate).format("MM-DD-YY")}`
        }
        else {
          newNote.note = `Customer requested past due balance of  ${formatMoney(props.account.data.ongoingAccountBalance.pastDue)}`
        }
      }
    }
    props.createNewNote(newNote, props.user.details);
  };

  // Returns a boolean and is used to hide messages in the event the search textbox rules them out
  const chatPertainsToSearch = (noteTopicType, note) => {
    // returns true if its an empty string or string containing spaces
    if (currentSearch.trim() == "") {
      return true;
    }
    // Else looks if current search is included in note or note topic
    return (
      noteTopicType.toUpperCase().includes(currentSearch.toUpperCase()) || note.toUpperCase().includes(currentSearch.toUpperCase())
    ); // plan to expand to search by creator
  };

  const onSelectedNoteCompletedToggleChange = (event) => {
    var note = props.notes.data[currentChat];
    if (!event.target.checked) {
      props.notes.data[currentChat].completed = 0;
      note.completed = 0;
    } else {
      props.notes.data[currentChat].completed = 1;
      note.completed = 1;
    }
    props.updateNote(props.notes.data[currentChat]);
    setCurrentChatIsComplete(props.notes.data[currentChat].completed);
  };

  const ChatMessageComponent = ({
    name,
    message,
    index = -1,
    time,
    avatar,
    position = "left",
  }) => {
    return (
      <ChatMessage position={position} id={"message_" + index}>
        <ChatMessageInner>
          <ChatMessageAvatar alt="CCOS User" src={avatar}>
            {name.includes("System Message") ? (
              <AnnouncementIcon fontSize="small" />
            ) : name.includes("Unknown User") ? (
              <HelpIcon fontSize="small" />
            ) : name.includes(",") && name[0] == "(" ? (
              name[1]
            ) : (
              name[0]
            )}
          </ChatMessageAvatar>
          <ChatMessageBubble>
            <Box>
              <ChatMessageBubbleName variant="body1">
                {name}
              </ChatMessageBubbleName>
            </Box>
            <Divider className="mb-2 mt-2" my={1} />
            <Typography variant="body2">{message}</Typography>
            <Divider className="mb-2 mt-2" my={1} />
            <Grid container spacing={1}>
              <Grid item lg={12} className={classes.rightSideHeader}>
                <ChatMessageTime variant="body2">{time}</ChatMessageTime>
              </Grid>
            </Grid>
          </ChatMessageBubble>
        </ChatMessageInner>
      </ChatMessage>
    );
  };
  //(props.notes.data[currentChat].creator.id != undefined  && props.notes.data[currentChat].creator.id == props.user.details.id)? "right" : "left"
  var selectedChatTitleMessagePosition = "left";
  if (
    !props.notes.isLoading &&
    props.notes.data.length > 0 &&
    props.notes.data[currentChat].creator.id &&
    props.notes.data[currentChat].creator.id == props.user.details.id
  ) {
    selectedChatTitleMessagePosition = "right";
  }
  return (
    <ChatContainer container component={Card}>
      <ChatSidebar container spacing={2} xs={12} md={4} lg={3}>
        <Grid item xs={12}>
          <Box p={2}>
            {
              focus ?
                <TextField
                  label="Search Notes"
                  /*variant="outlined"*/
                  fullWidth
                  autoFocus={"true"}
                  onBlur={unsetSearchFocus}
                  onChange={handleChatsSearchedValueChanged}
                  defaultValue={currentSearch}
                />
                :
                <TextField
                  label="Search Notes"
                  /*variant="outlined"*/
                  fullWidth
                  onClick={setSearchFocus}
                  onChange={handleChatsSearchedValueChanged}
                  defaultValue={currentSearch}
                />
            }
          </Box>
        </Grid>
        <Grid item xs={12} lg={12} md={12} sm={12}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleNewNoteFormClickOpen}
            my={2}
          >
            New Note
          </Button> 
        </Grid>
        <Grid item xs={4} lg={4} md={4} sm={4}>
          {selectedAutoNote == -1 ?
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={handleAutoNoteGeneration}
              my={2}
              disabled
            >
              Generate
            </Button>
            :
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={handleAutoNoteGeneration}
              my={2}
            >
              Generate
            </Button>
          }
        </Grid>
        {/* 
        DB need to add: Balance Inquiry 
        DB need to add: Past Due Inquiry 
        DB need to add: Payment Inquiry 
        */}
        <Grid item xs={8} lg={8} md={8} sm={8}>
          <FormControl variant="outlined" fullWidth my={2}>
            <InputLabel id="selectedTemplateDropDownLabel">
              Create Auto-Generated Note
            </InputLabel>
            <Select
              labelId="selectedTemplateDropDownLabel"
              id="selectedTemplateDropDown"
              onChange={handleSelectedAutoNoteChange}
              value={selectedAutoNote}
              label="Preset Note Generation"
              name="template"
              fullWidth
              my={2}
            >{autoNoteTypes && autoNoteTypes.length > 0 ?
              [
                autoNoteTypes.map((note, index) => {
                  return (
                    <MenuItem key={`autoNote_${index}`} value={index}>{note.display}</MenuItem>
                  )
                })
              ]
              :
              [
                <MenuItem value={-1}>No Auto Notes Found</MenuItem>
              ]
              }
            </Select>
          </FormControl>
        </Grid>
        <Dialog
          open={newNoteFormOpen}
          TransitionComponent={Transition}
          fullWidth={"sm"}
          maxWidth={"sm"}
          keepMounted
          onClose={handleNewNoteFormClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          {props.account.data.account ? (
            <DialogTitle id="alert-dialog-slide-title">
              New Note for Account #{props.account.data.account.accountNum}{" "}
            </DialogTitle>
          ) : (
            <DialogTitle id="alert-dialog-slide-title">
              New Note for Account: LOADING.....{" "}
            </DialogTitle>
          )}
          <DialogContent>
            <NewNoteForm
              onUpdate={handleNewNoteFormClose}
              onCancel={handleNewNoteFormClose}
            />
          </DialogContent>
        </Dialog>
        <Divider />

        <Grid item xs={12} lg={12} md={12} sm={12}>
          <List className={classes.chatsListBox}>
            {!props.notes.isLoading &&
              !props.notes.error &&
              props.notes.data[0] &&
              props.notes.data.map((currentNote, index) => {
                var avatar = "ERROR";
                if (currentNote.creatorName.includes("System Message")) {
                  avatar = <AnnouncementIcon fontSize="small" />;
                } else if (currentNote.creatorName.includes("Unknown User")) {
                  avatar = <HelpIcon fontSize="small" />;
                } else {
                  avatar = currentNote.creatorName[0];
                  if (currentNote.creatorName[0] == "(") {
                    avatar = currentNote.creatorName[1];
                  }
                }

                if (
                  !chatPertainsToSearch(
                    currentNote.noteTopicType,
                    currentNote.note
                  )
                ) {
                  return <></>;
                }
                if (index == currentChat) {
                  return (
                    <ListItem
                      button
                      className={classes.selectedListItem}
                      autofocus
                      onClick={() => {
                        handleChatSelectionChange(index);
                      }}
                    >
                      <ListItemIcon>
                        <Avatar alt={currentNote.creatorName}>{avatar}</Avatar>
                      </ListItemIcon>
                      <ListItemText
                        primary={currentNote.noteTopicType}
                        secondary={currentNote.creatorName}
                      />
                    </ListItem>
                  );
                }
                return (
                  <ListItem
                    button
                    onClick={() => {
                      handleChatSelectionChange(index);
                    }}
                  >
                    <ListItemIcon>
                      <Avatar alt={currentNote.creatorName}>{avatar}</Avatar>
                    </ListItemIcon>
                    <ListItemText
                      primary={currentNote.noteTopicType}
                      secondary={
        
                          dateTimeLocal(currentNote.created)
                        
                      }
                    />
                  </ListItem>
                );
              })}
          </List>
        </Grid>
      </ChatSidebar>
      <ChatMain item xs={12} md={8} lg={9}>
        <ChatMessages id="messagesBox">
          {props.notes.data[0] && (
            <>
              {/*
            <ChatMessageComponent
              name={"(" + props.notes.data[currentChat].creatorName + ") Topic: " +  props.notes.data[currentChat].noteTopicType}
              message={props.notes.data[currentChat].note}
              time={dateFormatter(props.notes.data[currentChat].created)}
              position={selectedChatTitleMessagePosition}
              item={props.notes.data[currentChat]}
              index={-1}
              isMainTopic={true}
          />*/}
              <Grid container spacing={1} className={classes.topicNote}>
                <Grid item xs={12}>
                  <Box>
                    <ChatMessageBubbleName variant="h5">
                      {"(" +
                        props.notes.data[currentChat].creatorName +
                        ") Topic: " +
                        props.notes.data[currentChat].noteTopicType}
                    </ChatMessageBubbleName>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Divider className="mb-2 mt-2" my={1} />
                  <Typography variant="body1">
                    {props.notes.data[currentChat].note}
                  </Typography>
                  <Divider className="mb-2 mt-2" my={1} />
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={7}>
                    <ChatMessageTime variant="body1">
                      Created: {dateTimeLocal(props.notes.data[currentChat].created)}
                    </ChatMessageTime>
                  </Grid>
                  {props.notes.data[currentChat].dueDate != null && (
                    <>
                      <Grid item xs={2} className={classes.rightSideHeader}>
                        <Typography variant="body1">
                          Due:{" "}
                          {dateFormatter(props.notes.data[currentChat].dueDate)}
                        </Typography>
                      </Grid>
                      <Grid item xs={2} className={classes.rightSideHeader}>
                        <Typography variant="body1">Completed</Typography>
                      </Grid>
                      <Grid item xs={1} className={classes.rightSideHeader}>
                        <Switch
                          name="printedBills"
                          checked={currentChatIsComplete}
                          onChange={onSelectedNoteCompletedToggleChange}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
              <Divider className="mb-2 mt-2" my={1} />

              {props.notes.data[currentChat].replys.map(
                (currentNoteReply, index) => {
                  var messageAlign = "left";
                  if (
                    typeof currentNoteReply.creator != "string" &&
                    currentNoteReply.creator.id == props.user.details.id
                  ) {
                    messageAlign = "right";
                  }
                  return (
                    <ChatMessageComponent
                      name={currentNoteReply.creatorName}
                      message={currentNoteReply.reply}
                      time={dateTimeMediumLength(currentNoteReply.created)}
                      position={messageAlign}
                      item={currentNoteReply}
                      index={index}
                    />
                  );
                }
              )}
              <Dialog
                open={editNoteFormOpen}
                TransitionComponent={Transition}
                fullWidth={"sm"}
                maxWidth={"sm"}
                keepMounted
                onClose={handleEditNoteFormClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogContent>
                  <UpdateNoteReplyForm
                    noteIndex={currentChat}
                    noteReplyIndex={selectedReply}
                    onUpdate={handleEditNoteFormClose}
                    onCancel={handleEditNoteFormClose}
                  />
                </DialogContent>
              </Dialog>
            </>
          )}
        </ChatMessages>
        <Divider />
        <ChatInput container>
          <Grid item style={{ flexGrow: 1 }}>
            <TextField
              variant="outlined"
              label="Reply to note"
              fullWidth
              onChange={onReplyMessageChange}
            />
          </Grid>
          <Grid item>
            <Box ml={2} onClick={makeNewReply}>
              {props.notes.isLoading ? (
                <CircularProgress color="inherit" />
              ) : (
                <Fab color="primary" aria-label="add" size="medium">
                  <SendIcon />
                </Fab>
              )}
            </Box>
          </Grid>
        </ChatInput>
      </ChatMain>
    </ChatContainer>
  );
}

// Component Properties
ChatWindow.propTypes = {
  // Store objects:
  account: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  bills: PropTypes.object.isRequired,
  payments: PropTypes.object.isRequired,
  setNotes: PropTypes.func.isRequired,
  createNewNoteReply: PropTypes.func.isRequired,
  getAllNoteTopicTypes: PropTypes.func.isRequired,
  deleteNoteOrReply: PropTypes.func.isRequired,
  updateNote: PropTypes.func.isRequired,
  createNewNote: PropTypes.func.isRequired,
};

// Component State
function ChatWindowState(state) {
  return {
    account: state.account,
    apl: state.apl,
    notes: state.notes,
    user: state.user,
    bills: state.bills,
    payments: state.payments,
  };
}
export default connect(ChatWindowState, {
  setNotes,
  createNewNoteReply,
  getAllNoteTopicTypes,
  createNewNote,
  deleteNoteOrReply,
  updateNote,
})(ChatWindow);
