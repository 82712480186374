//React Components and Hooks
import { Divider as MuiDivider } from "@material-ui/core";
//Material UI Components and Functions
import { makeStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components/macro";
import StandardHeader from "../../components/global/StandardHeader";
//Custom Components
import { LeftSidebar as NavFrame } from "layout-blueprints";
//Context
import LockboxUpload from "./LockboxUpload";
import { connect } from "react-redux";
import PropTypes from "prop-types";


const Divider = styled(MuiDivider)(spacing);
const useStyles = makeStyles((theme) => ({}));

const LockboxUploadPage = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const [currentDate, setCurrentDate] = useState(new Date());
  const [error, setError] = useState("");
  const [showError, setShowError] = useState(false);
  const { reportName } = useParams();

  const asyncEffect = async () => { };

  useEffect(() => {
    asyncEffect();
  }, []);

  var pageTitle = "Lockbox Uploads";
  document.title = "Ubil+ Lockbox";
  return (
    <NavFrame page={"Lockbox Upload"}>
      <StandardHeader
        title={pageTitle}
        appPath={"Management /Lockbox Uploads"}
      />
      <Divider className="mb-3 mt-3" />
      {
        (props.user.details.administration || props.user.details.accounting || props.user.details.isSuperUser) ?
      <LockboxUpload /> : <h3>You do not have lockbox privileges</h3>
      }
    </NavFrame>
  );
};
LockboxUploadPage.propTypes = {
  user: PropTypes.object.isRequired
};

// Component State
function LockboxUploadPageState(state) {
  return {
    user: state.user,
  };
}
export default connect(LockboxUploadPageState)(LockboxUploadPage);
