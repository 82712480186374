// App Imports
import { isEmpty } from "../../../setup/helpers";
import {
  GET_USER_CURRENT_LOCATION_REQUEST,
  GET_USER_CURRENT_LOCATION_RESPONSE,
  LOGIN_REQUEST,
  LOGIN_RESPONSE,
  LOGOUT,
  REFRESH_REQUEST,
  REFRESH_RESPONSE,
  REGISTER_NEW_USER_REQUEST,
  REGISTER_NEW_USER_RESPONSE,
  RESET_REQUEST,
  RESET_RESPONSE,
  SET_USER,
  updateUserLocalStorage,
  UPDATE_USER,
} from "./actions";

// Initial State
export const userInitialState = {
  error: null,
  isLoading: false,
  isAuthenticated: false,
  details: null,

  // New User location calls:
  userLocationIsLoading: false,
  userLocation: null,
};

// State
export default (state = userInitialState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.user),
        details: action.user,
        error: action.error,
      };

    case UPDATE_USER:
      const newUser = Object.assign({}, state.details, action.newData);
      updateUserLocalStorage(newUser);
      return {
        ...state,
        isAuthenticated: !isEmpty(state.details),
        details: newUser,
      };

    case LOGIN_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };

    case LOGIN_RESPONSE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };

    case RESET_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };

    case RESET_RESPONSE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };

    case GET_USER_CURRENT_LOCATION_REQUEST:
      return {
        ...state,
        userLocationIsLoading: action.userLocationIsLoading,
      };

    case GET_USER_CURRENT_LOCATION_RESPONSE:
      return {
        ...state,
        userLocation: action.userLocation,
        userLocationIsLoading: action.userLocationIsLoading,
      };

    case REFRESH_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };

    case REFRESH_RESPONSE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };

    case LOGOUT:
      return {
        ...state,
        error: null,
        isLoading: false,
        isAuthenticated: false,
        details: null,
      };

    case REGISTER_NEW_USER_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };

    case REGISTER_NEW_USER_RESPONSE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };

    default:
      return state;
  }
};
