import React, { useEffect,Fragment,useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Grid,
  Avatar,
  Badge,
  Card,
  CardContent,
  CircularProgress,
  Button,
  TextField,
  Divider,
  Typography,
  LinearProgress
} from '@material-ui/core';
import axios from 'axios';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'modules/snackbar/api/snackbar';
import { responsiveProperty } from '@mui/material/styles/cssUtils';

export default class WorkOrderNotes extends React.Component {
  constructor(props) {
    super(props);
    //any initialization here or in other react class methods
  }


  render() {
    let props = this.props;
    console.log(props);
    let notes = props.notes || [];

    return (
      <Fragment>
      <Grid container spacing={4}>
        <Grid item xs={12} lg={12}>
        </Grid>
        <Grid item xs={12} lg={12}>
          <Card className="card-box mb-4">
            <div className="card-header">
              <div className="card-header--title">
                <small>Work Order <strong>{props.workOrder.id}</strong></small>
                <b>{`Notes: ${props.workOrder.workOrderTitle}`}</b>
                <b>{`Submitted By: ${props.workOrder.creator || "N/A"}`}</b>
                <b>{`Property: ${props.workOrder.name}`}</b>
                <b>{`Address: ${props.workOrder.address1|| ""}`}</b>
                <b>{`Meter: ${props.workOrder.meter|| ""}`}</b>

              </div>
            </div>
            {notes.length <= 0 && <LinearProgress value={25} className="mb-3" /> }
            <div className="shadow-overflow">
              <PerfectScrollbar>
              {notes.map(note => (
                  <div>
                <CardContent className="p-3" key={note?.id}>
                  <div className="d-flex justify-content-between mb-3">
                    <div className="d-flex align-items-center">
                      <div className="avatar-icon-wrapper avatar-icon-lg mr-3">
                        {/* <div className="badge badge-primary badge-circle">
                          Offline
                        </div> */}
                        <Avatar
                                // className={classes.AvatarIcon}
                                direction="column"
                                alignItems="center"
                                justify="center"
                            >
                                <Typography variant="h2">
                                {note.submittedBy?.split(" ")[0][0] || ''}
                                </Typography>
                            </Avatar>
                      </div>
                      <div>
                        <a
                          href="#/"
                          onClick={e => e.preventDefault()}
                          className="font-weight-bold"
                          title="...">
                          {note.submittedBy}
                        </a>
                        <span className="text-danger-50 d-block">
                        {`Created: ${ moment(note.created).format("L LT")}`}
                        </span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <span className="rounded-pill bg-neutral-info text-info px-3 py-1 h-auto font-size-sm font-weight-bold">
                        {`Last update: ${ moment(note.updated).format("L LT")}`}
                      </span>
                    </div>
                  </div>
                  <p className="text-black-50 mb-0">
                        {note.note}
                  </p>
                </CardContent>
                <Divider />
                </div>
              ))}
                </PerfectScrollbar>
                    </div>
                <Divider />
            {/* <div className="card-footer d-block text-center bg-light p-3">
              <Button
                color="primary"
                className="m-2 p-0 d-inline-block text-center font-size-xxl d-50 rounded">
                <FontAwesomeIcon
                  icon={['far', 'building']}
                  className="font-size-xl text-white"
                />
              </Button>
              <Button
                color="secondary"
                className="m-2 p-0 d-inline-block text-center font-size-xxl d-50 rounded">
                <FontAwesomeIcon
                  icon={['far', 'question-circle']}
                  className="font-size-xl text-white"
                />
              </Button>
              <Button
                color="primary"
                className="m-2 p-0 d-inline-block text-center font-size-xxl d-50 rounded">
                <FontAwesomeIcon
                  icon={['far', 'user-circle']}
                  className="font-size-xl text-white"
                />
              </Button>
            </div> */}
          </Card>
        </Grid>
      </Grid>

    </Fragment>
    )
  } 
  
}
